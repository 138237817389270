/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import DocLink from '@/components/DocLink'
import { getConfigForKey } from '@/store'

const messages = defineMessages({
  userConsoleTitle: {
    id: `deployment.deployment-template.title.ess`,
    defaultMessage: `Hardware profile`,
  },
  userConsoleDescription: {
    id: `deployment.deployment-template.description.ess`,
    defaultMessage: `You can change the hardware configuration of the hot tier and, if required, of other components as well.`,
  },
  userConsoleTooltip: {
    id: `deployment.deployment-template.tooltip.ess`,
    defaultMessage: `A hardware profile deploys the Elastic Stack on virtual hardware. Each profile has a different blend of storage, RAM, and vCPU. {docLink}`,
  },
  adminConsoleTitle: {
    id: `deployment.deployment-template.title.ece`,
    defaultMessage: `Template`,
  },
  adminConsoleDescription: {
    id: `deployment.deployment-template.description.ece`,
    defaultMessage: `You can change the hardware configuration of the hot tier and other components as well.`,
  },
  adminConsoleTooltip: {
    id: `deployment.deployment-template.tooltip.ece`,
    defaultMessage: `Hardware templates deploy the Elastic Stack on virtual hardware. Each template has a different blend of RAM, storage, and vCPU. You can also customize them to suit your needs. {docLink}`,
  },
  edit: {
    id: `deployment.deployment-template.edit`,
    defaultMessage: `Edit`,
  },
  newVersion: {
    id: `deployment.deployment-template.new-version`,
    defaultMessage: `New version`,
  },
  newVersionAvailable: {
    id: `deployment.deployment-template.new-version-available`,
    defaultMessage: `Newer version available`,
  },
  previewNewVersionTitle: {
    id: `deployment.deployment-template.preview-new-version-title`,
    defaultMessage: `A newer version of the {title} hardware profile is available`,
  },
  previewNewVersionDescription1: {
    id: `deployment.deployment-template.preview-new-version-description-1`,
    defaultMessage: `Your deployment currently uses the {currentTitle} hardware profile.`,
  },
  previewNewVersionDescription2: {
    id: `deployment.deployment-template.preview-new-version-description-2`,
    defaultMessage: `Select the latest version of the profile to stay up to date.`,
  },
  previewNewVersionButton: {
    id: `deployment.deployment-template.preview-new-version-button`,
    defaultMessage: `Preview changes`,
  },
  learnMore: {
    id: `deployment.deployment-template.help`,
    defaultMessage: `Learn more`,
  },
  unknownTemplate: {
    id: `deployment.deployment-template.unknown-title`,
    defaultMessage: `Unknown`,
  },
  currentTemplate: {
    id: `deployment.deployment-template.current-title`,
    defaultMessage: `Current {isUserConsole, select, true {hardware profile} other {template}}`,
  },
  newTemplate: {
    id: `deployment.deployment-template.new-title`,
    defaultMessage: `New {isUserConsole, select, true {hardware profile} other {template}}`,
  },
  editTemplate: {
    id: `deployment.deployment-template.edit-title`,
    defaultMessage: `Edit {isUserConsole, select, true {hardware profile} other {template}}`,
  },
  componentTitle: {
    id: `deployment.deployment-template.component-title`,
    defaultMessage: `Component`,
  },
  currentConfiguration: {
    id: `deployment.deployment-template.current-configuration`,
    defaultMessage: `Current configuration`,
  },
  newConfiguration: {
    id: `deployment.deployment-template.new-configuration`,
    defaultMessage: `New configuration`,
  },
  update: {
    id: `deployment.deployment-template.update`,
    defaultMessage: `Update`,
  },
  cancel: {
    id: `deployment.deployment-template.cancel`,
    defaultMessage: `Cancel`,
  },
  noChanges: {
    id: `deployment.deployment-template.summary.no-changes`,
    defaultMessage: `No changes`,
  },
  removedInstance: {
    id: `deployment.deployment-template.summary.removed`,
    defaultMessage: `Removed`,
  },
  placeholderOptionDisplay: {
    id: `deployment.deployment-template.migrate.placeholder`,
    defaultMessage: `Select a hardware profile`,
  },
  monthlyCostLabel: {
    id: `deployment.deployment-template.migrate.monthly-price`,
    defaultMessage: `Monthly`,
  },
  hourlyCostLabel: {
    id: `deployment.deployment-template.migrate.hourly-price`,
    defaultMessage: `Hourly`,
  },
  priceButtonGroupLegend: {
    id: `deployment.deployment-template.migrate.monthly-price`,
    defaultMessage: `Hourly or Monthly Cost`,
  },
})

export default messages

export function getTooltipText(): JSX.Element {
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`
  const docLink = (
    <DocLink link='templatesDocLink'>
      <FormattedMessage {...messages.learnMore} />
    </DocLink>
  )

  if (isUserConsole) {
    return <FormattedMessage {...messages.userConsoleTooltip} values={{ docLink }} />
  }

  return <FormattedMessage {...messages.adminConsoleTooltip} values={{ docLink }} />
}

export function getTitleText(): JSX.Element {
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  if (isUserConsole) {
    return <FormattedMessage {...messages.userConsoleTitle} />
  }

  return <FormattedMessage {...messages.adminConsoleTitle} />
}

export function getDescriptionText(): JSX.Element {
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  if (isUserConsole) {
    return <FormattedMessage {...messages.userConsoleDescription} />
  }

  return <FormattedMessage {...messages.adminConsoleDescription} />
}
