/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchInstanceConfigurations } from '../../../../actions/topology/instanceConfigurations'
import {
  deleteDeploymentTemplate,
  fetchDeploymentTemplate,
  resetDeleteDeploymentTemplateRequest,
} from '../../../../actions/topology/deploymentTemplates'
import {
  deleteDeploymentTemplateRequest,
  fetchDeploymentTemplateRequest,
  getDeploymentTemplate,
  getInstanceConfigurations,
} from '../../../../reducers'

import DeploymentTemplateView from './DeploymentTemplateView'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, templateId },
    },
  },
) => ({
  regionId,
  templateId,
  instanceConfigurations: getInstanceConfigurations(state, regionId),
  template: getDeploymentTemplate(state, regionId, templateId, null),
  fetchDeploymentTemplateRequest: fetchDeploymentTemplateRequest(state, regionId, templateId),
  deleteDeploymentTemplateRequest: deleteDeploymentTemplateRequest(state, regionId, templateId),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId, templateId },
    },
  },
) => ({
  deleteDeploymentTemplate: () => dispatch(deleteDeploymentTemplate(regionId, templateId)),
  fetchInstanceConfigurations: () =>
    dispatch(fetchInstanceConfigurations(regionId, { showMaxZones: true })),
  fetchDeploymentTemplate: () =>
    dispatch(fetchDeploymentTemplate(regionId, templateId, null, { showMaxZones: true })),
  resetDeleteDeploymentTemplateRequest: () =>
    dispatch(resetDeleteDeploymentTemplateRequest(regionId, templateId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTemplateView)
