/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { pickBy, sortBy } from 'lodash'

import { getDaysUntilEol } from '@/lib/eolStatus/time'
import { gte, satisfies } from '@/lib/semver'
import { isWellKnownSliderInstanceType } from '@/lib/sliders/sliders'
import { getSliderVersion } from '@/lib/stackDeployments'
import { getConfigForKey } from '@/store'

import type { AsyncRequestState, StackDeployment, WellKnownSliderInstanceType } from '@/types'
import type {
  DeploymentEolStatus,
  EolStatusResponse,
  StackResourceEolStatus,
} from '@/types/eolStatus'
import type { SeverityLevel } from '@/lib/healthProblems'

enum SUPPORTED_PRODUCTS {
  // Resources
  ELASTICSEARCH = 'elasticsearch',
  KIBANA = 'kibana',
  APM_SERVER = 'apm_server',
  ENTERPRISE_SEARCH = 'elastic_enterprise_search',
  APP_SEARCH = 'elastic_app_search',

  // Platforms
  ELASTIC_CLOUD_ENTERPRISE = 'elastic_cloud_enterprise',
  // ELASTIC_CLOUD_ON_KUBERNETES = 'elastic_cloud_on_kubernetes', Not currently supported

  // Catch for resources that don't have a corresponding product with an EOL
  UNKNOWN = '',
}

const RESOURCE_TO_SLIDER_MAPPINGS: Record<WellKnownSliderInstanceType, SUPPORTED_PRODUCTS> = {
  elasticsearch: SUPPORTED_PRODUCTS.ELASTICSEARCH,
  kibana: SUPPORTED_PRODUCTS.KIBANA,
  apm: SUPPORTED_PRODUCTS.APM_SERVER,
  enterprise_search: SUPPORTED_PRODUCTS.ENTERPRISE_SEARCH,
  appsearch: SUPPORTED_PRODUCTS.APP_SEARCH,
  integrations_server: SUPPORTED_PRODUCTS.UNKNOWN,
}

const DAYS_UNTIL_EOL_WARNING = 90
// Any versions less than this will be considered EOL when there's no EolStatusResponse available
const ECE_EOL_CUTTOFF = '6.0.0'

export const convertSliderInstanceTypeToEolResourceType = (
  sliderInstanceType: WellKnownSliderInstanceType,
): SUPPORTED_PRODUCTS => RESOURCE_TO_SLIDER_MAPPINGS[sliderInstanceType]

export const filterUnsupportedStackResorces = (payload: EolStatusResponse): EolStatusResponse =>
  pickBy(payload, (_, stackResourceName) =>
    Object.values(SUPPORTED_PRODUCTS).includes(stackResourceName as SUPPORTED_PRODUCTS),
  )

export function requiresPlatformEndOfLifeVersionWarning(version: string | null): boolean {
  if (!version) {
    return false
  }

  const isEce = getConfigForKey(`CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`

  if (!isEce) {
    return false
  }

  if (gte(version, ECE_EOL_CUTTOFF)) {
    return false
  }

  return true
}

const isEolStatus = (
  resourceEolStatus: StackResourceEolStatus | null,
): resourceEolStatus is StackResourceEolStatus => resourceEolStatus !== null

export interface EolStatusCheck {
  (resourceEolStatus: StackResourceEolStatus): boolean
}

const requiresEolMessage: EolStatusCheck = (resourceEolStatus) => {
  const checks: EolStatusCheck[] = [isApproachingEol, hasReachedEol]

  return checks.some((checkFn) => checkFn(resourceEolStatus))
}

export const isApproachingEol: EolStatusCheck = (resourceEolStatus) => {
  const daysLeft = getDaysUntilEol(resourceEolStatus)
  return daysLeft > 0 && daysLeft <= DAYS_UNTIL_EOL_WARNING
}

export const hasReachedEol: EolStatusCheck = (resourceEolStatus) => {
  const daysLeft = getDaysUntilEol(resourceEolStatus)
  return daysLeft === 0
}

export function getSeverityLevel(resourceEolStatus: StackResourceEolStatus): SeverityLevel {
  if (isApproachingEol(resourceEolStatus)) {
    return 'info'
  }

  return 'warning'
}

export function getFirstEolResourceForDeployment({
  deployment,
  eolStatus,
}: {
  deployment: StackDeployment
  eolStatus: EolStatusResponse
}): StackResourceEolStatus | null {
  const deploymentEolStatus = getDeploymentEolStatus({ deployment, eolStatus })
  const eolResource = getEolResourceWithLowestVersion(deploymentEolStatus)

  return eolResource || null
}

function getEolResourceWithLowestVersion(
  deploymentEolStatus: DeploymentEolStatus,
): StackResourceEolStatus | null {
  const eolResources = Object.values(deploymentEolStatus).filter(isEolStatus)
  const [lowestVersionEolResource] = sortByLowerstVersion(eolResources)

  return lowestVersionEolResource || null
}

function getDeploymentEolStatus({
  deployment,
  eolStatus,
}: {
  deployment: StackDeployment
  eolStatus: EolStatusResponse
}): DeploymentEolStatus {
  return Object.keys(deployment.resources).reduce((deploymentEolStatus, sliderInstanceType) => {
    // Enforcing supported types for EOL
    if (!isWellKnownSliderInstanceType(sliderInstanceType)) {
      return deploymentEolStatus
    }

    const version = getSliderVersion({ deployment, sliderInstanceType })

    // No resource version to determine EOL
    if (!version) {
      return deploymentEolStatus
    }

    const resourceEolStatus = getResourceEolStatus({ eolStatus, sliderInstanceType, version })

    deploymentEolStatus[sliderInstanceType] = resourceEolStatus

    return deploymentEolStatus
  }, {})
}

function getResourceEolStatus({
  eolStatus,
  sliderInstanceType,
  version,
}: {
  eolStatus: EolStatusResponse
  sliderInstanceType: WellKnownSliderInstanceType
  version: string
}) {
  const resourceType = convertSliderInstanceTypeToEolResourceType(sliderInstanceType)
  const resourceEolStatusList = eolStatus[resourceType]

  // No EOL versions for this resource
  if (!resourceEolStatusList) {
    return null
  }

  return findResourceEolStatusByVersion(resourceEolStatusList, version)
}

export function findResourceEolStatusByVersion(
  resourceEolStatusList: StackResourceEolStatus[],
  version: string,
): StackResourceEolStatus | null {
  const resourceEolStatus = resourceEolStatusList.find((_resourceEolStatus) => {
    const range = `~${_resourceEolStatus.version}`

    if (satisfies(version, range)) {
      return requiresEolMessage(_resourceEolStatus)
    }

    return false
  })

  return resourceEolStatus || null
}

function sortByLowerstVersion(
  resourceEolStatusList: StackResourceEolStatus[],
): StackResourceEolStatus[] {
  // EOL versions are only "major.minor", so a standard sort works with the number coercion
  return sortBy(resourceEolStatusList, 'version')
}

export function hasEolWarningMessage({
  resourceEolStatusList,
  version,
  fetchEolStatusRequest,
}: {
  resourceEolStatusList: StackResourceEolStatus[] | null
  version: string
  fetchEolStatusRequest: AsyncRequestState
}): boolean {
  if (!resourceEolStatusList) {
    const showFallbackMessage =
      fetchEolStatusRequest.isDone && requiresPlatformEndOfLifeVersionWarning(version)

    return showFallbackMessage
  }

  const resourceEolStatus = findResourceEolStatusByVersion(resourceEolStatusList, version)

  return isEolStatus(resourceEolStatus)
}
