/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  login: {
    id: 'cloud-signup.login-link',
    defaultMessage: 'Log in',
  },
  loginDescription: {
    id: 'cloud-landing-page.login-link-description',
    defaultMessage: 'Already have an account?',
  },
  signup: {
    id: 'login-form.sign-up-link',
    defaultMessage: 'Sign up',
  },
  logout: {
    id: 'cloud-landing-page.log-out-link',
    defaultMessage: 'Log out',
  },
  signupDescription: {
    id: 'login-form.sign-up-link-description',
    defaultMessage: `Don't have an account?`,
  },
})

export default messages
