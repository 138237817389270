/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { CuiFilterContext, createQueryExecutor, toggleFieldValue } from '../../../cui'

import { getQueryModel, schema, defaultFields } from './schema'
import { getFilters } from './filters'

import type { RegionProxy } from '../../../types'
import type { ControlledFilterQuery, OnFilterChange } from '../../../cui'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  query: ControlledFilterQuery
  onChange: OnFilterChange<RegionProxy>
  proxies?: RegionProxy[]
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `proxy-filter-context.placeholder`,
    defaultMessage: `e.g.: healthy:y`,
  },
  emptyMessage: {
    id: `proxy-filter-context.no-matches`,
    defaultMessage: `No proxies match your query`,
  },
})

export const executeQuery = createQueryExecutor({
  defaultFields,
  getQueryModel,
})

const ProxyFilterContext: FunctionComponent<Props> = ({ intl, query, onChange, proxies }) => {
  const { formatMessage } = intl
  const filters = getFilters({
    intl,
    proxies,
  })
  const placeholder = formatMessage(messages.searchBarPlaceholder)
  const emptyMessage = formatMessage(messages.emptyMessage)

  return (
    <CuiFilterContext
      query={query}
      onChange={onChange}
      records={proxies}
      schema={schema}
      filters={filters}
      executeQuery={executeQuery}
      placeholder={placeholder}
      emptyMessage={emptyMessage}
    />
  )
}

export default injectIntl(ProxyFilterContext)

export function toggleField({
  query,
  field,
  value,
}: {
  query: ControlledFilterQuery
  field: string
  value: string
}) {
  return toggleFieldValue({
    query,
    schema,
    field,
    value,
  })
}
