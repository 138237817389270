/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { values, every } from 'lodash'

import { EuiFormLabel, EuiSpacer, EuiFormHelpText } from '@elastic/eui'

import { CuiAlert } from '../../../cui'
import SpinButton from '../../SpinButton'
import ensureHashedPasswords from '../../../lib/ensureHashedPasswords'
import { replaceIn } from '../../../lib/immutability-helpers'
import { isValidYaml, yamlToJson, jsonToYaml } from '../../../lib/yaml'

import EditRoles from './EditRoles'
import EditUsersPerRole from './EditUsersPerRole'
import EditUsers from './EditUsers'

import type { ElasticsearchCluster } from '../../../types'

type SecurityConfig = ElasticsearchCluster['security']['config']

type Props = {
  config: SecurityConfig
  saveSecurityConfig: (securityConfig: SecurityConfig) => void
  saveClusterAclInformation: {
    isSaving: boolean
    isSaved: boolean
    error: Error | null
  }
}

type State = {
  users: string
  usersPerRole: string
  roles: string
  isValid: {
    users: boolean
    usersPerRole: boolean
    roles: boolean
  }
  isHashingPasswords: boolean
  hasChanged: boolean
}

class DeploymentSecurityEditorBefore5x extends Component<Props, State> {
  state: State = {
    users: jsonToYaml(this.props.config.users) || ``,
    usersPerRole: jsonToYaml(this.props.config.usersPerRole) || ``,
    roles: jsonToYaml(this.props.config.roles) || ``,
    isValid: {
      users: true,
      usersPerRole: true,
      roles: true,
    },
    isHashingPasswords: false,
    hasChanged: false,
  }

  render() {
    const { saveClusterAclInformation } = this.props
    const { users, usersPerRole, roles, isHashingPasswords, isValid, hasChanged } = this.state

    const { isSaving, isSaved, error } = saveClusterAclInformation

    const allFieldsValid = every(values(isValid))

    return (
      <div data-test-id='deploymentSecurity-SecurityEditor'>
        <div>
          <EuiFormLabel>
            <FormattedMessage id='deployment-security-editor.users' defaultMessage='Users' />
          </EuiFormLabel>

          <EditUsers users={users} onChange={this.updateField.bind(this, `users`)} />
        </div>

        <EuiSpacer size='m' />

        <div>
          <EuiFormLabel>
            <FormattedMessage
              id='deployment-security-editor.users-per-role'
              defaultMessage='Users per role'
            />
          </EuiFormLabel>

          <EditUsersPerRole
            usersPerRole={usersPerRole}
            onChange={this.updateField.bind(this, `usersPerRole`)}
          />
        </div>

        <EuiSpacer size='m' />

        <div>
          <EuiFormLabel>
            <FormattedMessage id='deployment-security-editor.roles' defaultMessage='Roles' />
          </EuiFormLabel>

          <EditRoles roles={roles} onChange={this.updateField.bind(this, `roles`)} />
        </div>

        <EuiSpacer size='m' />

        <SpinButton
          type='submit'
          fill={true}
          spin={isSaving}
          disabled={isHashingPasswords || !allFieldsValid}
          onClick={() => this.save()}
        >
          <FormattedMessage
            id='deployment-security-editor.save-security-config'
            defaultMessage='Save security configuration'
          />
        </SpinButton>

        {!allFieldsValid && (
          <EuiFormHelpText>
            <FormattedMessage
              id='deployment-security-editor.invalid-changes'
              defaultMessage='Your changes are invalid.'
            />
          </EuiFormHelpText>
        )}

        {!hasChanged && error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{error}</CuiAlert>
          </Fragment>
        )}

        {!hasChanged && isSaved && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='info'>
              <FormattedMessage
                id='deployment-security-editor.security-config-saved'
                defaultMessage='Security config saved'
              />
            </CuiAlert>
          </Fragment>
        )}
      </div>
    )
  }

  updateField(fieldName: 'users' | 'usersPerRole' | 'roles', value) {
    // @ts-ignore 🤷
    this.setState({
      [fieldName]: value,
      isValid: replaceIn(this.state.isValid, [fieldName], isValidYaml(value)),
      hasChanged: true,
    })
  }

  save() {
    const { saveSecurityConfig } = this.props
    const { users, usersPerRole, roles } = this.state

    // As hashing might take some time, we want to be able
    // to show an update to the user, e.g. disabling submit
    this.setState({
      isHashingPasswords: true,
    })

    return ensureHashedPasswords(yamlToJson(users)).then((usersWithPasswords) => {
      this.setState({
        users: jsonToYaml(usersWithPasswords) || ``,
        isHashingPasswords: false,
        hasChanged: false,
      })

      return saveSecurityConfig({
        users: usersWithPasswords,
        usersPerRole: yamlToJson(usersPerRole),
        roles: yamlToJson(roles),
      } as SecurityConfig)
    })
  }
}

export default DeploymentSecurityEditorBefore5x
