/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  migrateDeploymentTemplate,
  resetMigrateDeploymentTemplate,
  updateDeployment,
  resetUpdateDeployment,
} from '@/actions/stackDeployments'
import {
  getDeploymentTemplates,
  getMigratedDeploymentPayload,
  migrateDeploymentTemplateRequest,
  updateStackDeploymentRequest,
} from '@/reducers'
import { getProfile } from '@/apps/userconsole/reducers'
import { inTrial } from '@/lib/trial'
import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors'

import DeploymentTemplate from './DeploymentTemplate'

import type { ReduxState, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const regionId = getRegionId({ deployment })!
  const stackVersion = getVersion({ deployment })
  const profile = getProfile(state)

  return {
    deploymentTemplates: getDeploymentTemplates(state, regionId, stackVersion) || [],
    getMigratedDeploymentPayload: (templateId) =>
      getMigratedDeploymentPayload(state, deployment.id, templateId),
    getMigrateDeploymentTemplateRequest: (templateId) =>
      migrateDeploymentTemplateRequest(state, deployment.id, templateId),
    updateDeploymentRequest: updateStackDeploymentRequest(state, deployment.id),
    inTrial: inTrial({ profile }),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => ({
  migrateDeploymentTemplate: (id) =>
    dispatch(migrateDeploymentTemplate({ deploymentId: deployment.id, templateId: id })),
  updateDeployment: (payload) =>
    dispatch(
      updateDeployment({ deploymentId: deployment.id, deployment: payload, redirect: true }),
    ),
  resetMigrateDeploymentTemplate: (templateId) =>
    dispatch(resetMigrateDeploymentTemplate(deployment.id, templateId)),
  resetUpdateDeployment: () => dispatch(resetUpdateDeployment(deployment.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTemplate)
