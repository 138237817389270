/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiLink } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'

import ExtendTrialModal from './ExtendTrialModal'
import messages from './messages'

import type { UserProfile } from '@/types'

interface Props {
  profile: UserProfile
  onTrialExtensionSuccess: () => void
}

type State = {
  openExtendTrialModal: boolean
}

class ExtendTrialSection extends Component<Props, State> {
  state: State = {
    openExtendTrialModal: false,
  }

  render() {
    const { onTrialExtensionSuccess } = this.props
    const { openExtendTrialModal } = this.state

    return (
      <Fragment>
        <EuiText size='xs' color='subdued'>
          <FormattedMessage
            {...messages.needMoreTime}
            values={{
              requestTrialExtensiom: this.getRequestTrialExtensionLink(),
            }}
          />
        </EuiText>

        {openExtendTrialModal && (
          <ExtendTrialModal
            onClose={this.closeExtendTrialModal}
            onTrialExtensionSuccess={onTrialExtensionSuccess}
          />
        )}
      </Fragment>
    )
  }

  getRequestTrialExtensionLink = (): JSX.Element => {
    const { profile } = this.props

    if (profile?.canExtendTrial) {
      return (
        <EuiLink onClick={this.openExtendTrialModal} data-test-id='extend-trial.request-trial-link'>
          <FormattedMessage {...messages.requestTrialExtension} />
        </EuiLink>
      )
    }

    return (
      <ExternalLink
        href='https://info.elastic.co/cloud-trial-extension.html'
        data-test-id='extend-trial.request-trial-external-link'
      >
        <FormattedMessage {...messages.requestTrialExtension} />
      </ExternalLink>
    )
  }

  openExtendTrialModal = (): void => {
    this.setState({ openExtendTrialModal: true })
  }

  closeExtendTrialModal = (): void => {
    this.setState({ openExtendTrialModal: false })
  }
}

export default ExtendTrialSection
