/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiCode,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiLink,
  EuiSpacer,
} from '@elastic/eui'

import { CuiPermissibleControl } from '@/cui'
import Permission from '@/lib/api/v1/permissions'
import { ApmMode, getApmMode } from '@/lib/apmClusters/getApmMode'

import DangerButton from '../../../DangerButton'
import CopyButton from '../../../CopyButton'
import {
  getDeploymentResourceEndpoint,
  getPlanInfo,
  isDeploymentAvailable,
  isResourceAvailable,
} from '../../../../lib/stackDeployments/selectors'

import type { FunctionComponent } from 'react'
import type { ApmPlanInfo, ApmResourceInfo } from '../../../../lib/api/v1/types'
import type { StackDeployment } from '../../../../types'

import './apmServerToken.scss'

export type DispatchProps = {
  resetApmToken: (args: { deploymentId: string; refId: string }) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
  resource: ApmResourceInfo
}

type Props = DispatchProps & ConsumerProps

const ManagedModeLink: FunctionComponent<Props> = ({ deployment }) => {
  const policyHref = getDeploymentResourceEndpoint({
    deployment,
    sliderInstanceType: 'kibana',
    getDeepLink: () => `/app/fleet/policies/policy-elastic-agent-on-cloud`,
  })
  return (
    <EuiCallOut>
      <FormattedMessage
        id='managed-mode-token'
        defaultMessage='The secret token can be found in the authentication configuration for the APM integration policy. <a>View policies</a>'
        values={{
          a: (children) => <EuiLink href={policyHref}>{children}</EuiLink>,
        }}
      />
    </EuiCallOut>
  )
}

const StandaloneServerToken: FunctionComponent<Props> = ({
  deployment,
  resource,
  resetApmToken,
}) => {
  const deploymentIsAvailable = isDeploymentAvailable(deployment)

  const plan = getPlanInfo({ resource }) as ApmPlanInfo | null
  const secretToken = plan?.plan?.apm.system_settings?.secret_token || `-`

  const { id: deploymentId } = deployment
  const refId = resource.ref_id

  return (
    <Fragment>
      <EuiFormLabel className='apmServerToken-label'>
        <FormattedMessage id='apm.security-token.title' defaultMessage='APM Server secret token' />
      </EuiFormLabel>

      <EuiSpacer size='xs' />

      <CuiPermissibleControl permissions={Permission.deploymentApmResetSecretToken}>
        <EuiFlexGroup
          justifyContent='flexStart'
          alignItems='center'
          gutterSize='none'
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiCode transparentBackground={true} className='apmServerToken-token'>
              {secretToken}
            </EuiCode>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CopyButton value={secretToken} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <span className='apmServerToken-divider'>|</span>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <DangerButton
              size='s'
              color='primary'
              isEmpty={true}
              disabled={!deploymentIsAvailable}
              onConfirm={() => resetApmToken({ deploymentId, refId })}
              modal={{
                body: (
                  <FormattedMessage
                    id='apm.security-token.reset-modal.body'
                    defaultMessage='Resetting your token requires the APM servers to be restarted, which will render it unavailable until the restart is complete. This will also invalidate the existing token, and requests from all agents will be rejected until you update their configuration with the new token.'
                  />
                ),
                confirmButtonText: (
                  <FormattedMessage
                    id='apm.security-token.reset-modal.confirm-button'
                    defaultMessage='Reset token'
                  />
                ),
                title: (
                  <FormattedMessage
                    id='apm.security-token.reset-modal.title'
                    defaultMessage='Reset your APM server token?'
                  />
                ),
              }}
            >
              <FormattedMessage id='apm.security-token.reset-button' defaultMessage='Reset token' />
            </DangerButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </CuiPermissibleControl>
    </Fragment>
  )
}

const ApmServerToken: FunctionComponent<Props> = ({ deployment, resource, resetApmToken }) => {
  const apmIsAvailable = isResourceAvailable(resource)

  if (!apmIsAvailable) {
    return null
  }

  const Component =
    getApmMode({ deployment }) === ApmMode.Managed ? ManagedModeLink : StandaloneServerToken

  return <Component deployment={deployment} resource={resource} resetApmToken={resetApmToken} />
}

export default ApmServerToken
