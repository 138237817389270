/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import { createTopologyClusterTemplateUrl } from '@/lib/urlBuilder'
import history from '@/lib/history'

import { CuiAlert, CuiEmptyPrompt, CuiPermissibleControl } from '../../../../cui'
import Permission from '../../../../lib/api/v1/permissions'
import IlmTemplateMigrationCallout from '../../../IlmMigration/IlmTemplateMigrationCallout'

import DeploymentTemplatesTable from './DeploymentTemplatesTable'

import type { DeploymentTemplateInfoV2 } from '../../../../lib/api/v1/types'
import type { AsyncRequestState, RegionId } from '../../../../types'
import type { FunctionComponent } from 'react'

const AddButton: FunctionComponent<{ regionId: string }> = ({ regionId }) => (
  <CuiPermissibleControl permissions={Permission.createDeploymentTemplateV2}>
    <EuiButton
      fill={true}
      onClick={() => history.push(createTopologyClusterTemplateUrl(regionId))}
      data-test-id='create-deployment-template-button'
    >
      <FormattedMessage
        id='deployment-template-list.create-button'
        defaultMessage='Create template'
      />
    </EuiButton>
  </CuiPermissibleControl>
)

export type Props = {
  regionId: RegionId
  deploymentTemplates?: DeploymentTemplateInfoV2[]
  deploymentTemplatesRequest: AsyncRequestState
  updatePendingTemplateName: (name: string) => void
  updatePendingTemplateDescription: (description: string) => void
  showIlmMigrationCallout: boolean
}

const DeploymentTemplateList: React.FunctionComponent<Props> = ({
  deploymentTemplates,
  deploymentTemplatesRequest,
  regionId,
  showIlmMigrationCallout,
}) => {
  if (deploymentTemplatesRequest.error) {
    return (
      <CuiAlert data-test-id='deployment-template-request-error' type='error'>
        {deploymentTemplatesRequest.error}
      </CuiAlert>
    )
  }

  if (deploymentTemplates && deploymentTemplates.length === 0) {
    return (
      <Fragment>
        <CuiEmptyPrompt
          title={
            <h2>
              <FormattedMessage
                id='deployment-template-list.empty-prompt'
                defaultMessage="You haven't created any deployment templates yet"
              />
            </h2>
          }
          data-test-id='deployment-template-nodeployment-templates-message'
          addButton={<AddButton regionId={regionId} />}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem>
          <EuiTitle data-test-id='deployment-template-title'>
            <h2>
              <FormattedMessage
                id='deployment-template-list.title'
                defaultMessage='Deployment templates'
              />
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AddButton data-test-id='deployment-template-add-button' regionId={regionId} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='l' />

      {showIlmMigrationCallout && <IlmTemplateMigrationCallout />}

      <DeploymentTemplatesTable
        data-test-id='deployment-templates-table'
        regionId={regionId}
        deploymentTemplates={deploymentTemplates}
      />
    </Fragment>
  )
}

export default DeploymentTemplateList
