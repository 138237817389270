/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { WellKnownSliderInstanceType } from '@/types'
import { FETCH_EOL_STATUS } from '@/constants/actions'
import {
  convertSliderInstanceTypeToEolResourceType,
  filterUnsupportedStackResorces,
} from '@/lib/eolStatus'

import type { EolStatusResponse, StackResourceEolStatus } from '@/types/eolStatus'

type FetchAction = {
  type: 'FETCH_EOL_STATUS'
  error: Error | null
  payload: EolStatusResponse
}

export type State = EolStatusResponse | null

export default function eolStatusReducer(state: State = null, action: FetchAction): State {
  if (action.type === FETCH_EOL_STATUS) {
    if (action.payload && !action.error) {
      // Lets only pick out the supported fields here, instead of storing the entire json structure
      return filterUnsupportedStackResorces(action.payload)
    }
  }

  return state
}

export const getEolStatus = (state: State): State => state

export const getResourceEolStatus = (
  state: State,
  sliderInstanceType: WellKnownSliderInstanceType,
): StackResourceEolStatus[] | null => {
  if (!state) {
    return null
  }

  return state[convertSliderInstanceTypeToEolResourceType(sliderInstanceType)] || null
}
