/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiLoadingContent } from '@elastic/eui'

import { getDeploymentsByClusterIdsQuery } from '@/lib/deploymentQuery'
import { deploymentUrl } from '@/lib/urlBuilder'
import history from '@/lib/history'

import { CuiAlert } from '../../cui'

import type { WrappedComponentProps } from 'react-intl'
import type { AsyncRequestState, SliderInstanceType } from '../../types'
import type { DeploymentsSearchResponse, SearchRequest } from '@/lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

export type StateProps = {
  searchRequest: AsyncRequestState
  searchResults: DeploymentsSearchResponse | null
  regionId: string
  resourceId: string
  resourceType: string
  splat: string
}

export type DispatchProps = {
  searchDeployments: (query: SearchRequest) => void
}

type RouteParams = {
  regionId: string
  resourceId: string
  resourceType: string
}

export type ConsumerProps = RouteComponentProps<RouteParams>

type Props = StateProps & DispatchProps & ConsumerProps & WrappedComponentProps

const routeResourceTypeToSliderInstanceType: Record<
  string,
  { sliderInstanceType: SliderInstanceType; appendRedirectSuffix?: boolean }
> = {
  cluster: { sliderInstanceType: 'elasticsearch' },
  kibana: { sliderInstanceType: 'kibana', appendRedirectSuffix: true },
  apm: { sliderInstanceType: 'apm', appendRedirectSuffix: true },
}

class RedirectToDeploymentResource extends Component<Props> {
  componentDidMount() {
    const { searchDeployments, searchResults, resourceType, resourceId, regionId } = this.props
    const sliderInstanceType =
      routeResourceTypeToSliderInstanceType[resourceType]?.sliderInstanceType

    if (searchResults) {
      this.redirectFromSearchResults()
    } else {
      const query = getDeploymentsByClusterIdsQuery({
        regionId,
        sliderInstanceType,
        clusterIds: [resourceId],
      })
      searchDeployments(query)
    }
  }

  componentDidUpdate() {
    const { searchResults } = this.props

    if (searchResults) {
      this.redirectFromSearchResults()
    }
  }

  render() {
    const { searchRequest, searchResults } = this.props

    if (searchRequest.error) {
      return <CuiAlert type='error'>{searchRequest.error}</CuiAlert>
    } else if (searchResults?.return_count === 0) {
      return (
        <CuiAlert type='warning'>
          <FormattedMessage
            id='redirect-to-resource.id-not-found'
            defaultMessage='Deployment not found'
          />
        </CuiAlert>
      )
    }

    return <EuiLoadingContent />
  }

  redirectFromSearchResults() {
    const { searchResults, resourceType, splat } = this.props
    const { sliderInstanceType, appendRedirectSuffix } =
      routeResourceTypeToSliderInstanceType[resourceType] || {}

    const deploymentId = searchResults?.deployments[0]?.id

    if (deploymentId) {
      const baseUrl = deploymentUrl(deploymentId)
      const redirectUrl = appendRedirectSuffix ? `${baseUrl}/${sliderInstanceType}` : baseUrl

      history.replace(`${redirectUrl}/${splat}`)
    }
  }
}

export default injectIntl(RedirectToDeploymentResource)
