/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import { EuiSpacer, EuiText } from '@elastic/eui'

import { getFirstEsClusterFromGet, getPlanInfo } from '@/lib/stackDeployments/selectors'

import { hasPermission } from '../../lib/requiresPermission'
import Permission from '../../lib/api/v1/permissions'
import DocLink from '../DocLink'

import Request from './Request'
import Output from './Output'

import type { ConsoleRequestState } from '../../reducers/apiConsole'
import type { AsyncRequestState, EsProxyResponseConsole, StackDeployment } from '../../types'

import './apiConsole.scss'

type Props = {
  deployment: StackDeployment | null
  consoleRequest?: ConsoleRequestState | null
  consoleRequestHistory: ConsoleRequestState[]
  consoleResponse: EsProxyResponseConsole | null
  queryDeploymentProxyRequest: AsyncRequestState
  clearAPIConsoleHistory: () => void
  clearDeploymentProxyResponse: (deploymentId: string) => void
  queryDeploymentProxyForConsole: (
    deployment: StackDeployment,
    consoleRequest: ConsoleRequestState,
  ) => void
  setAPIConsoleRequest: (request: ConsoleRequestState, deployment: StackDeployment) => void
}

class APIConsole extends Component<Props> {
  componentDidMount() {
    this.setInitialRequest()
  }

  render() {
    const {
      clearDeploymentProxyResponse,
      clearAPIConsoleHistory,
      deployment,
      consoleRequest,
      consoleRequestHistory,
      consoleResponse,
      queryDeploymentProxyRequest,
      queryDeploymentProxyForConsole,
      setAPIConsoleRequest,
    } = this.props

    if (!deployment) {
      return null
    }

    const firstEs = getFirstEsClusterFromGet({ deployment })

    if (firstEs && !getPlanInfo({ resource: firstEs })) {
      return null
    }

    if (consoleRequest == null) {
      return null
    }

    return (
      <div className='apiConsole'>
        <EuiText>
          <FormattedMessage
            id='api-console.overview'
            defaultMessage='Perform operations-related tasks from this console. You can run search queries, review the list of snapshots, check the health of your clusters, and { link }.'
            values={{
              link: (
                <DocLink link='apiConsoleDocLink'>
                  <FormattedMessage id='api-console.overview.more' defaultMessage='more' />
                </DocLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='m' />

        <Request
          deployment={deployment}
          request={consoleRequest}
          requestHistory={consoleRequestHistory}
          clearDeploymentProxyResponse={clearDeploymentProxyResponse}
          clearAPIConsoleHistory={clearAPIConsoleHistory}
          onChange={setAPIConsoleRequest}
          inProgress={queryDeploymentProxyRequest.inProgress}
          submitQueryToDeploymentProxy={(request) => {
            queryDeploymentProxyForConsole(deployment, request)
          }}
        />

        <EuiSpacer size='m' />

        <Output
          requestSettings={consoleRequest}
          request={queryDeploymentProxyRequest}
          response={consoleResponse}
        />
      </div>
    )
  }

  setInitialRequest() {
    const { setAPIConsoleRequest, deployment } = this.props

    if (!deployment) {
      return
    }

    setAPIConsoleRequest(this.getInitialRequest(), deployment)
  }

  getInitialRequest() {
    const { consoleRequestHistory } = this.props

    if (!isEmpty(consoleRequestHistory)) {
      return consoleRequestHistory[0]
    }

    // Use this permission as a proxy for the user's access level to the console.
    // `*_viewer` roles can only call a restricted set of API endpoints, so we
    // default to an endpoint that they can actually call.
    const perm = hasPermission(Permission.postDeploymentResourceProxyRequests)

    const initialRequest: ConsoleRequestState = {
      method: `GET`,
      path: perm ? `/_search` : `/_cat`,
      body: ``,
      advancedMode: false,
      filterBy: `regex`,
      filterRegex: ``,
      filterJq: ``,
      invertFilter: false,
    }

    return initialRequest
  }
}

export default APIConsole
