/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { serializeTag } from './helpers'

import type { FilterSchema } from '../../../cui'

export const schema: FilterSchema = {
  strict: true,
  fields: {
    id: {
      type: `string`,
    },
    zone: {
      type: `string`,
    },
    healthy: {
      type: `string`,
      validate: validateHumanBool,
    },
    maintenance: {
      type: `string`,
      validate: validateHumanBool,
    },
    connected: {
      type: `string`,
      validate: validateHumanBool,
    },
    feature: {
      type: `string`,
    },
    tag: {
      type: `string`,
    },
    fill: {
      type: `number`,
    },
    available: {
      type: `number`,
    },
  },
}

export const defaultFields = [`id`, `zone`, `tag`, `feature`]

export function getQueryModel(allocator) {
  const { used, total } = allocator.memoryCapacity
  const available = (total - used) / 1024
  const fill = getFill()

  return {
    id: allocator.id,
    zone: allocator.zoneId,
    healthy: toHumanBool(allocator.healthy),
    maintenance: toHumanBool(allocator.isInMaintenanceMode),
    connected: toHumanBool(allocator.connected),
    tag: allocator.tags.map(serializeTag),
    feature: allocator.enabledFeatures,
    fill,
    available,
  }

  function getFill() {
    if (total === 0) {
      return 100
    }

    const roundedFill = Math.round((used / total) * 100)

    if (roundedFill === 0 && used > 0) {
      return 1
    }

    if (roundedFill === 100 && available > 0) {
      return 99
    }

    return roundedFill
  }
}

function toHumanBool(value) {
  return value ? `y` : `n`
}

function validateHumanBool(value) {
  if (value !== `y` && value !== `n`) {
    throw new Error(`Expected "y" or "n"`)
  }
}
