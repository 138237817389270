/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual } from 'lodash'

import { premiumInstanceCapacities } from '../../constants/capacities'

export type SizingUnit = 'memory' | 'storage'

export const SIZING_UNIT_MEMORY: SizingUnit = `memory`
export const SIZING_UNIT_STORAGE: SizingUnit = `storage`

interface GetIncrementsArgs {
  sizingUnit: SizingUnit
  memoryIncrements: number[]
  storageIncrements: number[]
}

export const getIncrementsForSizingUnit = ({
  sizingUnit,
  memoryIncrements,
  storageIncrements,
}: GetIncrementsArgs) => {
  switch (sizingUnit) {
    case SIZING_UNIT_MEMORY:
      return memoryIncrements

    case SIZING_UNIT_STORAGE:
      return storageIncrements

    default:
      throw new Error('Unknown sizing unit: ' + sizingUnit)
  }
}

interface GetDefaultValueArgs {
  sizingUnit: SizingUnit
  memoryDefaultValue: number
  storageDefaultValue: number
}

export const getDefaultValueForSizingUnit = ({
  sizingUnit,
  memoryDefaultValue,
  storageDefaultValue,
}: GetDefaultValueArgs) => {
  switch (sizingUnit) {
    case SIZING_UNIT_MEMORY:
      return memoryDefaultValue

    case SIZING_UNIT_STORAGE:
      return storageDefaultValue

    default:
      throw new Error('Unknown sizing unit: ' + sizingUnit)
  }
}

interface GetDefaultIncrementsArgs {
  sizingUnit: SizingUnit
  min: number
  max: number
  ratio: number
}

const getDefaultIncrementsForSizingUnit = ({
  sizingUnit,
  min,
  max,
  ratio,
}: GetDefaultIncrementsArgs) => {
  const sizes = premiumInstanceCapacities.filter((cap) => cap >= min && cap <= max)

  switch (sizingUnit) {
    case SIZING_UNIT_MEMORY:
      return sizes

    case SIZING_UNIT_STORAGE:
      return sizes.map((size) => size * ratio)

    default:
      throw new Error('Unknown sizing unit: ' + sizingUnit)
  }
}

interface GetDefaultValueAndIncrementsArgs {
  bounds?: {
    min: number
    max: number
  }
  memoryDefaultValue: number
  memoryIncrements: number[]
  prevRatio: number
  prevSizingUnit: SizingUnit
  ratio: number
  sizingUnit: SizingUnit
  storageDefaultValue: number
  storageIncrements: number[]
}

export function getDefaultValueAndIncrementsForSizingUnit({
  bounds = { min: 0, max: Infinity },
  memoryDefaultValue,
  memoryIncrements,
  prevRatio,
  prevSizingUnit,
  ratio,
  sizingUnit,
  storageDefaultValue,
  storageIncrements,
}: GetDefaultValueAndIncrementsArgs) {
  const { min, max } = bounds

  const nextDefaultIncrements = getDefaultIncrementsForSizingUnit({
    sizingUnit,
    min,
    max,
    ratio,
  })

  const increments =
    getIncrementsForSizingUnit({
      sizingUnit,
      memoryIncrements,
      storageIncrements,
    }) || nextDefaultIncrements

  const previousDefaultIncrements = getDefaultIncrementsForSizingUnit({
    sizingUnit,
    min,
    max,
    ratio: prevRatio,
  })

  // If the memory:storage ratio has changed, or we've switched between memory
  // or storage, we may need to recalculate the increments and default values.

  // If the user hasn't changed the increments from what we initially generate, change
  // them in line with the ratio. Note that we don't actually have a 'dirty' flag, so
  // if a user adds an increment then deletes it, this case will still execute.
  const changing = prevRatio !== ratio || prevSizingUnit !== sizingUnit
  const sameIncrements = isEqual(previousDefaultIncrements, increments)
  const dirty = changing && sameIncrements

  const finalIncrements = dirty ? nextDefaultIncrements : increments

  if (sizingUnit === SIZING_UNIT_STORAGE) {
    return getStorageIncrements()
  }

  return getMemoryIncrements()

  function getStorageIncrements() {
    const useMin = dirty || storageDefaultValue === undefined

    return {
      storageDefaultValue: useMin ? min * ratio : storageDefaultValue,
      storageIncrements: finalIncrements,
      memoryIncrements: null, // UX is better if we reset memory
    }
  }

  function getMemoryIncrements() {
    const useMin = dirty || memoryDefaultValue === undefined

    return {
      memoryDefaultValue: useMin ? min : memoryDefaultValue,
      memoryIncrements: finalIncrements,
      storageIncrements: null, // UX is better if we reset storage
    }
  }
}
