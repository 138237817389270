/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import AdminconsoleOverview from './AdminconsoleOverview'

import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  adminconsoleId: string
}

interface DispatchProps {}

type QueryParams = {
  regionId: string
  adminconsoleId: string
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  _state,
  {
    match: {
      params: { regionId, adminconsoleId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  adminconsoleId,
})

const mapDispatchToProps: DispatchProps = {}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminconsoleOverview),
)
