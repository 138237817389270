/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchBasePricesIfNeeded } from '../../../../apps/userconsole/actions/pricing'
import {
  fetchBasePricesRequest,
  getBasePrices,
  getProfile,
} from '../../../../apps/userconsole/reducers'
import { getConfigForKey } from '../../../../selectors'

import DeploymentTemplateFlyout from './DeploymentTemplateFlyout'

import type { ThunkDispatch } from '../../../../types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { BillingSubscriptionLevel } from '@/types'

const mapStateToProps = (state, { regionId }): StateProps => {
  const profile = getProfile(state)!
  const isUserConsole = getConfigForKey(state, `APP_NAME`) === `userconsole`
  const level = isUserConsole ? profile.level : null
  const basePrices = level ? getBasePrices(state, regionId, level) : undefined

  return {
    basePrices,
    fetchBasePricesRequest: fetchBasePricesRequest(state, regionId, level),
    profile,
    isUserConsole,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch, { regionId }): DispatchProps => ({
  fetchBasePrices: ({ level }: { regionId: string; level: BillingSubscriptionLevel }) =>
    dispatch(fetchBasePricesIfNeeded({ regionId, level })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentTemplateFlyout)
