/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiText } from '@elastic/eui'

import Permission from '../../../lib/api/v1/permissions'
import { isPermitted } from '../../../lib/requiresPermission'
import { getVersion } from '../../../lib/stackDeployments/selectors'

import UpgradableDeploymentVersion from './UpgradableDeploymentVersion'

import type { StackDeployment } from '../../../types'

function DeploymentVersion({ deployment }: { deployment: StackDeployment }) {
  if (isPermitted(Permission.updateDeployment)) {
    return <UpgradableDeploymentVersion deployment={deployment} />
  }

  const version = getVersion({ deployment })

  // The <UpgradableDeploymentVersion> component requires permissions for
  // fetching various things, so instead just render a plain version.
  return <EuiText size='s'>{`v${version}`}</EuiText>
}

export default DeploymentVersion
