/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiButtonEmpty,
  EuiLoadingContent,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert } from '../../../cui'
import DangerButton from '../../DangerButton'

import type { IntlShape } from 'react-intl'
import type { AsyncRequestState } from '../../../types'
import type { SaasAuthMfaDeviceResponse } from '../../../lib/api/v1/types'

type Props = {
  intl: IntlShape
  userId: string
  email: string
  fetchMfaDevices: () => void
  fetchMfaDevicesRequest: AsyncRequestState
  resetMfa: () => void
  resetMfaRequest: AsyncRequestState
  mfaDevices?: SaasAuthMfaDeviceResponse[]
}

const messages = defineMessages({
  label: {
    id: `reset-mfa-button.label`,
    defaultMessage: `Multi-factor authentication`,
  },
  resetMfa: {
    id: `reset-mfa-button.reset-mfa`,
    defaultMessage: `Reset MFA`,
  },
  confirmationTitle: {
    id: `reset-mfa-button.are-you-sure`,
    defaultMessage: `Reset MFA?`,
  },
  confirmationBody: {
    id: `reset-mfa-button.confirmation-body`,
    defaultMessage: `You're about to reset MFA settings for the account that's associated with {email}. This action cannot be undone.`,
  },
  confirmAction: {
    id: `reset-mfa-button.confirm-action`,
    defaultMessage: `Yes, reset MFA`,
  },
  mfaNotEnabled: {
    id: `reset-mfa-button.disabled-mfa`,
    defaultMessage: `Not enabled`,
  },
  mfaEnabled: {
    id: `reset-mfa-button.enabled-mfa`,
    defaultMessage: `{devices} {devices, plural, one {device} other {devices}}`,
  },
})

class MfaManagement extends Component<Props> {
  componentDidMount() {
    this.props.fetchMfaDevices()
  }

  render() {
    const {
      intl: { formatMessage },
      mfaDevices,
      fetchMfaDevicesRequest,
      resetMfa,
      resetMfaRequest,
      email,
    } = this.props

    if (fetchMfaDevicesRequest.error) {
      return (
        <CuiAlert data-test-id='mfa-devices-error' type='error'>
          {fetchMfaDevicesRequest.error}
        </CuiAlert>
      )
    }

    if (!mfaDevices) {
      return <EuiLoadingContent data-test-id='mfa-devices-loading' lines={1} />
    }

    if (mfaDevices.length === 0) {
      return <FormattedMessage data-test-id='mfa-not-enabled' {...messages.mfaNotEnabled} />
    }

    const actionText = formatMessage(messages.resetMfa)

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <FormattedMessage
              data-test-id='mfa-enabled'
              {...messages.mfaEnabled}
              values={{
                devices: mfaDevices.length,
              }}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <DangerButton
              buttonType={EuiButtonEmpty}
              buttonProps={{ size: 'xs' }}
              data-test-id='reset-mfa-button'
              onConfirm={() => resetMfa()}
              spin={resetMfaRequest.inProgress}
              modal={{
                title: formatMessage(messages.confirmationTitle),
                body: (
                  <FormattedMessage
                    {...messages.confirmationBody}
                    values={{
                      email: <strong>{email}</strong>,
                    }}
                  />
                ),
                confirmButtonText: formatMessage(messages.confirmAction),
              }}
            >
              {actionText}
            </DangerButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {resetMfaRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert data-test-id='mfa-request-error' type='error'>
              {resetMfaRequest.error}
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default injectIntl(MfaManagement)
