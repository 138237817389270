/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiLoadingContent,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'
import type { EuiDescriptionListProps } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'
import withBillingDetails from '@/lib/withBillingDetails'

import PageSection from '../../PageSection'

import type { FunctionComponent } from 'react'
import type { WithBillingDetailsProps } from '@/lib/withBillingDetails/types'

type GetListItems<Info> = (info: Info) => Required<EuiDescriptionListProps>['listItems']

const notAvailableMsg = (
  <FormattedMessage id='marketplace-information.not-available.text' defaultMessage='N/A' />
)

const infoListConfiguration = [
  {
    key: 'channel',
    title: <FormattedMessage id={`billing-details.channel.title`} defaultMessage='Channel' />,
  },
  {
    key: 'billing_model',
    title: (
      <FormattedMessage id={`billing-details.billing_model.title`} defaultMessage='Billing model' />
    ),
  },
  {
    key: 'billing_processing',
    title: (
      <FormattedMessage
        id={`billing-details.billing_processing.title`}
        defaultMessage='Billing processing'
      />
    ),
  },
  {
    key: 'trial_state',
    title: (
      <FormattedMessage id={`billing-details.trial_state.title`} defaultMessage='Trial state' />
    ),
  },
  {
    key: 'payment_method',
    title: (
      <FormattedMessage
        id={`billing-details.payment_method.title`}
        defaultMessage='Payment method'
      />
    ),
  },
  {
    key: 'partner_selling',
    title: (
      <FormattedMessage
        id={`billing-details.partner_selling.title`}
        defaultMessage='Partner selling'
      />
    ),
  },
  {
    key: 'billing_status',
    title: (
      <FormattedMessage
        id={`billing-details.billing_status.title`}
        defaultMessage='Billing status'
      />
    ),
  },
]

const marketplaceIntegrationConfiguration = [
  {
    key: 'provider',
    title: (
      <FormattedMessage
        id={`billing-details.provider.title`}
        defaultMessage='Marketplace integration - Provider'
      />
    ),
  },
  {
    key: 'version',
    title: (
      <FormattedMessage
        id={`billing-details.version.title`}
        defaultMessage='Marketplace integration - Version'
      />
    ),
  },
  {
    key: 'codename',
    title: (
      <FormattedMessage
        id={`billing-details.codename.title`}
        defaultMessage='Marketplace integration - Codename'
      />
    ),
  },
]

const getBillingDetailsInfoList: GetListItems<WithBillingDetailsProps['billingDetails']['data']> = (
  billingDetails,
) => {
  const billingDetailsInfoList = infoListConfiguration.map(({ key, title }) => ({
    title,
    description: billingDetails?.[key] || notAvailableMsg,
  }))

  if (billingDetails?.marketplace_integration) {
    const marketplaceIntegrationInfoList = marketplaceIntegrationConfiguration.map(
      ({ key, title }) => ({
        title,
        description: billingDetails?.marketplace_integration?.[key] || notAvailableMsg,
      }),
    )

    billingDetailsInfoList.push(...marketplaceIntegrationInfoList)
  }

  return billingDetailsInfoList
}

const BillingDetails: FunctionComponent<WithBillingDetailsProps> = (props): JSX.Element => {
  const {
    billingDetails: { data, loading },
  } = props

  return (
    <PageSection
      iconType='documents'
      title={<FormattedMessage id='billing-details.title' defaultMessage='Billing Details' />}
      flexItems={
        <EuiFlexGroup justifyContent='flexEnd' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiText size='s' data-test-id='billing-details.more-info-link'>
              <ExternalLink
                showExternalLinkIcon={true}
                href='https://wiki.elastic.co/display/PM/Admin+Console+for+Billing'
              >
                <FormattedMessage id='billing-details.more-info-link' defaultMessage='More info' />
              </ExternalLink>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
    >
      {loading && <EuiLoadingContent lines={7} />}

      {!loading && (
        <EuiDescriptionList
          compressed={true}
          type='responsiveColumn'
          listItems={getBillingDetailsInfoList(data)}
        />
      )}
    </PageSection>
  )
}

export default withBillingDetails(BillingDetails)
