/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiLoadingSpinner, EuiSwitch } from '@elastic/eui'

import { CuiAlert } from '../../../../../cui'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

const messages = defineMessages({
  labelAllowed: {
    id: `allow-extra-versions.status.checkbox-label-allowed`,
    defaultMessage: `Allowed`,
  },
  labelNotAllowed: {
    id: `allow-extra-versions.status.checkbox-label-not-allowed`,
    defaultMessage: `Not Allowed`,
  },
})

class Status extends Component<AllProps & WrappedComponentProps> {
  componentDidMount() {
    const { regionId, fetchAllowExtraVersionsStatus } = this.props

    if (regionId !== ``) {
      fetchAllowExtraVersionsStatus()
    }
  }

  componentDidUpdate(prevProps) {
    const { regionId } = this.props

    if (prevProps.regionId !== regionId && regionId !== ``) {
      // regionId has changed, so go and fetch the new region's status
      this.props.fetchAllowExtraVersionsStatus()
    }
  }

  render() {
    const {
      intl: { formatMessage },
      allowExtraVersionsStatus,
      fetchExtraVersionsStatusRequest,
      updateAllowExtraVersionsStatusRequest,
      regionId,
    } = this.props

    if (regionId === ``) {
      return null
    }

    if (fetchExtraVersionsStatusRequest.error || updateAllowExtraVersionsStatusRequest.error) {
      return (
        <CuiAlert data-test-id='allow-extra-versions-error' type='error'>
          {fetchExtraVersionsStatusRequest.error}
        </CuiAlert>
      )
    }

    if (
      fetchExtraVersionsStatusRequest.inProgress ||
      updateAllowExtraVersionsStatusRequest.inProgress ||
      allowExtraVersionsStatus == null
    ) {
      return <EuiLoadingSpinner data-test-id='allow-extra-versions-loading' />
    }

    return (
      <EuiSwitch
        label={formatMessage(
          allowExtraVersionsStatus.bypass_version_allowlist
            ? messages.labelAllowed
            : messages.labelNotAllowed,
        )}
        data-test-id='allow-extra-versions-switch'
        checked={allowExtraVersionsStatus.bypass_version_allowlist}
        onChange={this.onChange}
      />
    )
  }

  onChange = (e) => {
    if (e.target.checked) {
      return this.enableBypass()
    }

    return this.disableBypass()
  }

  enableBypass() {
    this.props.updateAllowExtraVersionsStatus(true)
  }

  disableBypass() {
    this.props.updateAllowExtraVersionsStatus(false)
  }
}

export default injectIntl(Status)
