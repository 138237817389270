/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiBadge, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../../cui'
import StoredProcedureResult from '../StoredProcedureResult'
import DangerButton from '../../DangerButton'

import type { IntlShape } from 'react-intl'
import type { AsyncRequestState } from '../../../types'

type Props = {
  intl: IntlShape
  userId: string
  email: string
  requestDeletion: () => void
  requestDeletionRequest: AsyncRequestState
  resetRequestDeletion: () => void
}

const messages = defineMessages({
  accountDeletionLabel: {
    id: `request-deletion-button.account-deletion-label`,
    defaultMessage: `Request account deletion`,
  },
  requestDeletion: {
    id: `request-deletion-button.request-deletion`,
    defaultMessage: `Request deletion`,
  },
  requestDeletionModalTitle: {
    id: `request-deletion-button.are-you-sure`,
    defaultMessage: `Request deletion?`,
  },
  requestDeletionModalBody: {
    id: `request-deletion-button.request-deletion-account`,
    defaultMessage: `You're about to submit a request to delete the account that's associated with { email }. This action cannot be undone.`,
  },
  confirmDeletion: {
    id: `request-deletion-button.request-deletion-confirm`,
    defaultMessage: `Yes, request account deletion`,
  },
  deletionRequested: {
    id: `request-deletion-button.deletion-requested`,
    defaultMessage: `Account slated for deletion.`,
  },
})

class RequestDeletionButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetRequestDeletion()
  }

  render() {
    const {
      intl: { formatMessage },
      requestDeletion,
      requestDeletionRequest,
      email,
    } = this.props

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          buttonProps={{ flush: 'left' }}
          size='s'
          onConfirm={() => requestDeletion()}
          spin={requestDeletionRequest.inProgress}
          modal={{
            title: formatMessage(messages.requestDeletionModalTitle),
            body: (
              <FormattedMessage
                {...messages.requestDeletionModalBody}
                values={{
                  email: <EuiBadge color='accent'>{email}</EuiBadge>,
                }}
              />
            ),
            confirmButtonText: formatMessage(messages.confirmDeletion),
          }}
        >
          <FormattedMessage {...messages.accountDeletionLabel} />
        </DangerButton>

        {requestDeletionRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{requestDeletionRequest.error}</CuiAlert>
          </Fragment>
        )}

        <StoredProcedureResult
          procedureId='set_delete_request'
          successColor='warning'
          successMessage={formatMessage(messages.deletionRequested)}
        />
      </Fragment>
    )
  }
}

export default injectIntl(RequestDeletionButton)
