/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { FunctionComponent } from 'react'

import { EuiText } from '@elastic/eui'

import { formatCenticent } from '@/lib/money'

import { TotalPriceProps } from './types'

const TotalPrice: FunctionComponent<TotalPriceProps> = ({ priceViewSelected, hourlyRate }) => {
  const getTotalPrice = () => {
    if (priceViewSelected === `monthly`) {
      const monthlyRate = hourlyRate * 24 * 30
      return formatCenticent(monthlyRate, 2)
    }

    return formatCenticent(hourlyRate, 4)
  }

  return (
    <EuiText>
      <h5 data-test-id='total-price'>{getTotalPrice()}</h5>
    </EuiText>
  )
}

export default TotalPrice
