/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { deleteEmailSuppressions } from '../../../actions/emailSuppressions'
import { deleteEmailSuppressionsRequest } from '../../../reducers'

import ClearTransactionalEmailSuppressionsButton from './ClearSuppressions'

import type { AsyncRequestState } from '../../../types'

type StateProps = {
  deleteEmailSuppressionsRequest: AsyncRequestState
}

type DispatchProps = {
  deleteEmailSuppressions: () => void
}

type ConsumerProps = {
  userId: string
}

const mapStateToProps = (state, { userId }: ConsumerProps): StateProps => ({
  deleteEmailSuppressionsRequest: deleteEmailSuppressionsRequest(state, userId, `transactional`),
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps): DispatchProps => ({
  deleteEmailSuppressions: () => dispatch(deleteEmailSuppressions(userId, `transactional`)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ClearTransactionalEmailSuppressionsButton)
