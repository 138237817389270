/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'
import cx from 'classnames'

import { EuiFlexItem, EuiPanel } from '@elastic/eui'

import { FlipCardSide } from './types'

import type { FlipCardProps } from './types'
import type { ReactElement, ComponentType } from 'react'

import './styles.scss'

interface Props {
  frontSide: ComponentType<FlipCardProps>
  backSide: ComponentType<FlipCardProps>
}

interface State {
  flipCardSide: FlipCardSide
}

class FlipCard extends Component<Props, State> {
  state = {
    flipCardSide: FlipCardSide.Front,
  }

  render(): ReactElement {
    const { flipCardSide } = this.state

    return (
      <EuiFlexItem
        grow={true}
        className={cx('flip-card', { showBack: flipCardSide === FlipCardSide.Back })}
        onMouseEnter={this.handleHover}
      >
        {this.getFrontCard()}
        {this.getBackCard()}
      </EuiFlexItem>
    )
  }

  getFrontCard = (): ReactElement => {
    const { frontSide: FrontSide } = this.props

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        borderRadius='m'
        className='side front'
        grow={true}
      >
        <FrontSide flipCard={this.handleCardFlip} />
      </EuiPanel>
    )
  }

  getBackCard = (): ReactElement => {
    const { backSide: BackSide } = this.props

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        borderRadius='m'
        className='side back'
        grow={true}
      >
        <BackSide flipCard={this.handleCardFlip} />
      </EuiPanel>
    )
  }

  handleCardFlip = (): void => {
    this.setState((prevState) => ({
      flipCardSide:
        prevState.flipCardSide === FlipCardSide.Front ? FlipCardSide.Back : FlipCardSide.Front,
    }))
  }

  handleHover = (): void => {
    const { flipCardSide } = this.state

    if (flipCardSide === FlipCardSide.Front) {
      this.handleCardFlip()
    }
  }
}

export default FlipCard
