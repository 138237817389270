/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import { LocationDescriptor, Path, History } from 'history'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonEmptyProps,
  EuiButtonIcon,
  EuiButtonIconProps,
} from '@elastic/eui'
import type { Props as EuiButtonProps } from '@elastic/eui/src/components/button/button'

import type { FunctionComponent } from 'react'

const isModifiedEvent = (event) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = (event) => event.button === 0

const isTargetBlank = (event) => {
  const target = event.target.getAttribute('target')
  return target && target !== '_self'
}

type RouteHandler = Path | (() => Path) | LocationDescriptor<unknown>

interface RouterProps {
  to: RouteHandler
}

function buildComponentProps(to: RouteHandler, history: History) {
  const toVal = typeof to === 'function' ? to() : to

  function onClick(event) {
    if (event.defaultPrevented) {
      return
    }

    // Let the browser handle links that open new tabs/windows
    if (isModifiedEvent(event) || !isLeftClickEvent(event) || isTargetBlank(event)) {
      return
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault()

    // Push the route to the history.
    history.push(toVal)
  }

  // Generate the correct link href (with basename accounted for)
  const href = history.createHref(typeof toVal === 'object' ? toVal : { pathname: toVal })
  return [onClick, href]
}

export const CuiRouterLinkButton: FunctionComponent<EuiButtonProps & RouterProps> = ({
  to,
  ...rest
}) => {
  const history = useHistory()
  const [onClick, href] = buildComponentProps(to, history)

  const props = { ...rest, href, onClick } as EuiButtonProps
  return <EuiButton {...props} />
}

export const CuiRouterLinkButtonEmpty: FunctionComponent<EuiButtonEmptyProps & RouterProps> = ({
  to,
  ...rest
}) => {
  const history = useHistory()
  const [onClick, href] = buildComponentProps(to, history)

  const props = { ...rest, href, onClick } as EuiButtonEmptyProps
  return <EuiButtonEmpty {...props} />
}

export const CuiRouterLinkButtonIcon: FunctionComponent<EuiButtonIconProps & RouterProps> = ({
  to,
  ...rest
}) => {
  const history = useHistory()
  const [onClick, href] = buildComponentProps(to, history)

  const props = { size: 'xs' as const, ...rest, href, onClick } as EuiButtonIconProps
  return <EuiButtonIcon {...props} />
}
