/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { FunctionComponent } from 'react'
import { defineMessages, injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiPopover, EuiText } from '@elastic/eui'

import DocLink from '@/components/DocLink'

type Props = WrappedComponentProps & {
  onClick: () => void
  isOpen: boolean
  closePopover: () => void
  price?: string
}

const messages = defineMessages({
  tiebreaker: { id: 'tiebreaker-info-button', defaultMessage: 'show tiebreaker info message' },
})

const TiebreakerInfo: FunctionComponent<Props> = ({
  intl: { formatMessage },
  isOpen,
  closePopover,
  onClick,
}) => {
  const button = (
    <EuiButtonIcon
      aria-labelledby={formatMessage(messages.tiebreaker)}
      onClick={onClick}
      color='text'
      iconType='iInCircle'
    />
  )

  return (
    <EuiPopover isOpen={isOpen} closePopover={closePopover} button={button}>
      <EuiText style={{ maxWidth: '300px' }} size='s'>
        <FormattedMessage
          id='tiebreaker.tiebreaker-icon-info'
          defaultMessage='We automatically include a single tiebreaker node for your deployment to help prevent a split-brain situation during master elections. {learnMore}'
          values={{
            learnMore: (
              <DocLink link='resilientClusters'>
                <FormattedMessage
                  id='tiebreaker.tiebreaker-icon-info.learn-more'
                  defaultMessage='Learn more'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>
    </EuiPopover>
  )
}

export default injectIntl(TiebreakerInfo)
