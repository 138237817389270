/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { addWhitelistedVersionUrl } from '../../lib/api/v1/urls'
import asyncRequest from '../asyncRequests'
import { PUT_WHITELISTED_VERSION } from '../../constants/actions'

import type { Region } from '../../types'
import type { StackVersionConfig } from '../../lib/api/v1/types'

export function addWhitelistedVersion({
  versionInfo,
  region,
}: {
  versionInfo: StackVersionConfig
  region: Region
}) {
  if (!versionInfo.version) {
    return
  }

  const regionId = region.id

  const url = addWhitelistedVersionUrl({ regionId, version: versionInfo.version })

  return asyncRequest({
    type: PUT_WHITELISTED_VERSION,
    method: `PUT`,
    url,
    meta: { regionId },
    crumbs: [regionId],
    abortIfInProgress: true,
  })
}
