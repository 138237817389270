/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE } from '../../constants/actions'

import type { AsyncAction } from '../../types'
import type { SliderInstanceType } from '@/types/sliders'
export interface State {
  [descriptor: string]: any
}

interface DownloadAction extends AsyncAction<typeof DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE, string> {
  meta: {
    deploymentId: string
    resourceKind: SliderInstanceType
  }
}

export default function clustersDiagnosticBundlesReducer(
  state: State = {},
  action: DownloadAction,
): State {
  if (action.type === DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE) {
    if (!action.error && action.payload) {
      const { deploymentId, resourceKind } = action.meta
      const descriptor = createDescriptor(deploymentId, resourceKind)

      return {
        ...state,
        [descriptor]: action.payload,
      }
    }
  }

  return state
}

export function getClusterDiagnosticBundle(
  state: State,
  deploymentId: string,
  resourceKind: SliderInstanceType,
) {
  return state[createDescriptor(deploymentId, resourceKind)]
}

function createDescriptor(deploymentId: string, resourceKind: SliderInstanceType) {
  return `${deploymentId}/${resourceKind}`
}
