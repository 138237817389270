/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  AsyncRequestState,
  StackDeployment,
  BasePrice,
  BillingSubscriptionLevel,
} from '@/types'
import type { DeploymentTemplateInfoV2 } from '@/lib/api/v1/types'

export interface StateProps {
  basePrices: BasePrice[] | undefined
  fetchBasePricesRequest: AsyncRequestState
}

export interface DispatchProps {
  fetchBasePrices: () => Promise<void>
}

export interface ConsumerProps {
  deployment: StackDeployment
  deploymentTemplate: DeploymentTemplateInfoV2
  level: BillingSubscriptionLevel
}

export enum PriceView {
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
}

export type AllProps = StateProps & DispatchProps & ConsumerProps
