/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import {
  FETCH_RUNNER_LOGGING_SETTINGS,
  PATCH_RUNNER_LOGGING_SETTINGS,
} from '../../constants/actions'
import { getRunnerLoggingSettingsUrl, updateRunnerLoggingSettingsUrl } from '../../lib/api/v1/urls'

import type { LoggerLevel } from '../../types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

export function fetchRunnerLoggingSettings({
  regionId,
  runnerId,
}: {
  regionId: string
  runnerId: string
}) {
  return asyncRequest({
    type: FETCH_RUNNER_LOGGING_SETTINGS,
    method: `GET`,
    url: getRunnerLoggingSettingsUrl({ regionId, runnerId }),
    meta: { regionId, runnerId },
    crumbs: [regionId, runnerId],
  })
}

export function patchRunnerLoggingSettings({
  regionId,
  runnerId,
  loggingLevels,
}: {
  regionId: string
  runnerId: string
  loggingLevels: PatchLoggingLevels
}) {
  return asyncRequest({
    type: PATCH_RUNNER_LOGGING_SETTINGS,
    method: `PATCH`,
    url: updateRunnerLoggingSettingsUrl({ regionId, runnerId }),
    meta: { regionId, runnerId },
    crumbs: [regionId, runnerId],
    payload: { logging_levels: loggingLevels },
  })
}
