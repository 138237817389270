/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSelect } from '@elastic/eui'

import type { FunctionComponent } from 'react'

import './languageSwitcher.scss'

type Props = {
  lang: string
  onChange: (nextLang: string) => void
}

const LanguageSwitcher: FunctionComponent<Props> = ({ lang, onChange }) => (
  <div className='language-switcher'>
    <EuiSelect
      data-test-id='language-switcher'
      value={lang}
      onChange={(ev) => onChange(ev.target.value)}
      options={[
        { value: `en`, text: `English` },
        { value: `de`, text: `Deutsch` },
        { value: `fr`, text: `Français` },
        { value: `ja`, text: `日本語` },
      ]}
    />
  </div>
)

export default LanguageSwitcher
