/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { CuiLink } from '../../../cui'
import { resolveDeploymentUrlForAnyCluster } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { AllocatorInstance } from '../../../types'

type Props = {
  regionId: string
  instance: AllocatorInstance
}

const InstanceDeploymentLink: FunctionComponent<Props> = ({
  regionId,
  instance: { kind, clusterId, clusterDisplayName, stackDeploymentId },
}) => (
  <CuiLink
    to={resolveDeploymentUrlForAnyCluster({
      regionId,
      stackDeploymentId,
      clusterId,
      sliderInstanceType: kind,
    })}
  >
    {clusterDisplayName}
  </CuiLink>
)

export default InstanceDeploymentLink
