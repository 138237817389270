/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiTableRow,
  EuiTableRowCell,
  EuiButtonIcon,
  EuiHealth,
  EuiNotificationBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
} from '@elastic/eui'

import CuiElasticConsumptionUnits from '@/cui/formatters/CuiElasticConsumptionUnits'
import { colorForDeployment } from '@/lib/theme'

import { ColorKeyForOtherDeployments } from '../../constants'

import type { DeploymentCostsAggregation } from '@/types/billing'
import type { FunctionComponent } from 'react'

interface DeploymentValuesProp {
  totals: DeploymentCostsAggregation
}

const CostsRowCells: FunctionComponent<DeploymentValuesProp> = ({
  totals: { capacity, dataTransfer, storage, total },
}) => (
  <Fragment>
    <EuiTableRowCell>
      <CuiElasticConsumptionUnits value={capacity} unit='none' />
    </EuiTableRowCell>
    <EuiTableRowCell>
      <CuiElasticConsumptionUnits value={dataTransfer} unit='none' />
    </EuiTableRowCell>
    <EuiTableRowCell>
      <CuiElasticConsumptionUnits value={storage} unit='none' />
    </EuiTableRowCell>
    <EuiTableRowCell>
      <CuiElasticConsumptionUnits value={total} unit='none' />
    </EuiTableRowCell>
  </Fragment>
)

export interface AggregationTableRowProps {
  totals: DeploymentCostsAggregation
  aggregations: DeploymentCostsAggregation[]
  showDetails: boolean
  onToggle: () => void
  onDeploymentSelected: (args: { selectedDeploymentId: string }) => void
}

const AggregationTableRow: FunctionComponent<AggregationTableRowProps> = ({
  totals,
  aggregations,
  showDetails,
  onToggle,
  onDeploymentSelected,
}) => {
  if (!aggregations.length) {
    return null
  }

  return (
    <Fragment>
      <EuiTableRow isExpandable={true}>
        <EuiTableRowCell isExpander={true}>
          <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiHealth color={colorForDeployment.get(ColorKeyForOtherDeployments)}>
                <FormattedMessage
                  id='billing-aggregation-row.all-deployments'
                  defaultMessage='All other deployments'
                />
              </EuiHealth>
            </EuiFlexItem>
            <EuiFlexItem grow={false} data-test-id='aggregation-table-row.badge'>
              <EuiNotificationBadge color='subdued' style={{ minWidth: 30 }}>
                {aggregations.length}
              </EuiNotificationBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                data-test-id='aggregation-table-row.toggleDetailsBtn'
                onClick={onToggle}
                aria-label={showDetails ? 'Collapse' : 'Expand'}
                iconType={showDetails ? 'arrowUp' : 'arrowDown'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTableRowCell>
        <CostsRowCells data-test-id='aggregation-table-row.totals' totals={totals} />
      </EuiTableRow>

      {showDetails &&
        aggregations.map((agg) => (
          <EuiTableRow
            data-test-id='aggregation-table-row.expandedRow'
            isExpandedRow={true}
            key={agg.id}
          >
            <EuiTableRowCell>
              <EuiLink
                data-test-id='aggregation-table-row.expandedRowLink'
                onClick={() => onDeploymentSelected({ selectedDeploymentId: agg.id })}
                style={{ paddingLeft: 20 }}
              >
                {agg.name}
              </EuiLink>
            </EuiTableRowCell>
            <CostsRowCells totals={agg} />
          </EuiTableRow>
        ))}
    </Fragment>
  )
}

export default AggregationTableRow
