/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { stringify, parse } from 'querystring'

import moment from 'moment'

import { ViewBy, ChartType } from '../types'
import { MinimalStartDate } from '../constants'

import type { Filters } from '../types'

export const defaultFilters = {
  selectedDeploymentId: '',
  viewBy: ViewBy.DAY,
  chartType: ChartType.BAR,
  startDate: moment().startOf('month'), // currentMonth by default
  endDate: moment(), // currentMonth by default
}

const validViewByOptions = Object.values(ViewBy)
const validChartTypeOptions = Object.values(ChartType)

const clearInvalidFilters = (filters: Partial<Filters>): Partial<Filters> => {
  const { viewBy, chartType, startDate, endDate } = filters
  const cleanOptions = { ...filters }

  if (viewBy && !validViewByOptions.includes(viewBy)) {
    delete cleanOptions.viewBy
  }

  if (chartType && !validChartTypeOptions.includes(chartType)) {
    delete cleanOptions.chartType
  }

  const parsedStartDate = moment(startDate)
  const parsedEndDate = moment(endDate)
  const minStartDate = MinimalStartDate

  // if any of the dates is invalid or
  // or the end date is not after the start date
  // or startDate is before min startDate
  // or endDate is in a future
  // -> let's just start on a clean state.

  if (
    !parsedStartDate.isValid() ||
    !parsedEndDate.isValid() ||
    !parsedEndDate.isSameOrAfter(parsedStartDate) ||
    parsedStartDate.isBefore(minStartDate) ||
    parsedEndDate.isAfter(moment())
  ) {
    delete cleanOptions.startDate
    delete cleanOptions.endDate
  }

  return cleanOptions
}

export const parseFiltersQuery = (rawQueryString: string): Filters => {
  const filtersQuery = parse(rawQueryString)
  const sanitizedFiltersQuery = clearInvalidFilters(filtersQuery)
  const filters = { ...defaultFilters, ...sanitizedFiltersQuery }
  filters.startDate = moment(filters.startDate)
  filters.endDate = moment(filters.endDate)
  return filters
}

export const stringifyFilters = (filters: Filters): string =>
  stringify({
    ...filters,
    startDate: filters.startDate.format('YYYY-MM-DD'),
    endDate: filters.endDate.format('YYYY-MM-DD'),
  })
