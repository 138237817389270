/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { camelCase } from 'lodash'

import {
  EuiText,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiBetaBadge,
  EuiPopover,
} from '@elastic/eui'

import TopologyPanel from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/TopologyPanel'
import { getInstanceConfigurationById } from '@/lib/instanceConfigurations/instanceConfiguration'

import ScrollIntoView from '../../../../../ScrollIntoView'
import { getSliderDefinition } from '../../../../../../lib/sliders'
import {
  getFirstSliderCluster,
  getNodeRoles,
  getSliderPlan,
  getSliderUserSettings,
  hasOnlyPlanLevelSettings,
  isAutoscaleableTier,
  isData,
  isDedicatedMaster,
  isDedicatedML,
  isFrozen,
} from '../../../../../../lib/stackDeployments/selectors'
import { isAutoscalingSupportedInTemplate } from '../../../../../../lib/stackDeployments/selectors/autoscaling'
import { getDefaultUserSettings } from '../../../../../../lib/stackDeployments/userSettings'
import { hashMatchesNodeConfiguration } from '../../../../../../lib/stackDeployments/topology'
import Summary from '../TopologyElement/Summary'
import NormalizeSizing from '../TopologyElement/NormalizeSizing'
import SizePicker from '../TopologyElement/SizePicker'
import ZoneCount from '../TopologyElement/ZoneCount'
import UserSettings from '../TopologyElement/UserSettings'
import TopologyPanelRow from '../TopologyElement/TopologyPanelRow'
import Heading from '../TopologyElement/Heading'

import SelectInstanceConfiguration from './SelectInstanceConfiguration'
import AutoscalingInfo from './AutoscalingInfo'

import type { NormalizeSizingProps } from '../TopologyElement/NormalizeSizing'
import type { AnyTopologyElement, SliderInstanceType } from '../../../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type {
  DeploymentCreateRequest,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '../../../../../../lib/api/v1/types'

export type AllProps = {
  id: string
  deployment: DeploymentCreateRequest
  sliderInstanceType: SliderInstanceType
  topologyElement: AnyTopologyElement
  instanceConfigurations: InstanceConfiguration[]
  onChange: undefined | ((path: string[], value: any) => void) // path is scoped to the topology element
  onPlanChange: undefined | ((path: string[], value: any) => void) // path is scoped to the plan (above topology element)
  onResourceChange: undefined | ((path: string[], value: any) => void) // path is scoped to the resource (above plan)
  maxZoneCount: number
  maxInstanceCountForEnvironment: number
  maxInstanceCount?: number
  isReadOnly?: boolean
  // optionally supply additional fields consisting of
  // TopologyElement-compatible components
  extraFields?: Array<React.ComponentType<Props>>
}

type Props = AllProps & WrappedComponentProps

type State = {
  isTitlePopoverOpen: boolean
  isDeprecatedPopoverOpen: boolean
}

class TemplateTopologyElement extends Component<Props, State> {
  state = {
    isTitlePopoverOpen: false,
    isDeprecatedPopoverOpen: false,
  }

  render(): JSX.Element {
    const { topologyElement } = this.props

    return (
      <ScrollIntoView
        whenLocationMatches={(hash) => hashMatchesNodeConfiguration(hash, topologyElement)}
      >
        <div data-test-subj='topologyElement' data-text-topologyelementid={topologyElement.id}>
          <TopologyPanel headerContent={this.renderHeader()} footerContent={this.renderSummary()}>
            {this.renderInstanceConfiguration()}
            {this.renderInitialSize()}
            {this.renderMinSize()}
            {this.renderMaxSize()}
            {this.renderAvailabilityZones()}
            {this.renderExtraFields()}
            {this.renderUserSettings()}
          </TopologyPanel>
        </div>
      </ScrollIntoView>
    )
  }

  renderHeader(): JSX.Element | null {
    const {
      intl: { formatMessage },
      topologyElement,
      sliderInstanceType,
    } = this.props

    const { isDeprecatedPopoverOpen } = this.state

    const messages = defineMessages({
      autoscaling: {
        id: 'instance-template-section-popover.autoscaling',
        defaultMessage: 'Supports autoscaling',
      },
      soonDeprecated: {
        id: 'instance-template-section-popover.deprecated-soon',
        defaultMessage: 'Soon to be deprecated',
      },
    })

    const betaBadges: JSX.Element[] = []

    if (isAutoscaleableTier({ topologyElement, version: undefined })) {
      betaBadges.push(
        <EuiBetaBadge key='autoscaling' label={formatMessage(messages.autoscaling)} />,
      )
    }

    if (sliderInstanceType === `apm`) {
      betaBadges.push(
        <EuiPopover
          key='apm'
          anchorPosition='upCenter'
          ownFocus={true}
          button={
            <EuiBetaBadge
              label={formatMessage(messages.soonDeprecated)}
              data-test-id='instance-deprecating-badge'
              onClick={() => this.setState({ isDeprecatedPopoverOpen: true })}
            />
          }
          isOpen={isDeprecatedPopoverOpen}
          closePopover={() => this.setState({ isDeprecatedPopoverOpen: false })}
        >
          <FormattedMessage
            id='deployment-template-apm.deprecating'
            defaultMessage='APM will be replaced with Integrations Server in version 8.1.0'
          />
        </EuiPopover>,
      )
    }

    // The onChange props is overridden to undefined here because the only
    // reason <Heading /> needs it is to disable -- but you can't do that in a
    // template, only set the starting size to 0. Similarly, autoscaling is
    // always "enabled" in a template context.
    return (
      <Heading
        {...this.props}
        instanceConfiguration={this.getCurrentInstanceConfiguration()}
        onChange={undefined}
        betaBadges={betaBadges}
      />
    )
  }

  renderInstanceConfiguration() {
    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-instanceConfigLabel'
        defaultMessage='Instance configuration'
      />
    )

    return (
      <TopologyPanelRow wideLabel={true} alignItems='center' label={label}>
        <div style={{ maxWidth: `90%` }}>
          <EuiFlexGroup gutterSize='xs'>
            <EuiFlexItem grow={8}>
              <SelectInstanceConfiguration
                {...this.props}
                instanceConfiguration={this.getCurrentInstanceConfiguration()}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </TopologyPanelRow>
    )
  }

  renderInitialSize(): JSX.Element | null {
    const { maxInstanceCountForEnvironment, topologyElement, maxInstanceCount } = this.props

    if ((topologyElement as ElasticsearchClusterTopologyElement).autoscaling_min) {
      return null // if we have min, we don't need initial
    }

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-initialSizeLabel'
        defaultMessage='Initial size <nowrap>per zone</nowrap>'
        values={{
          nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
        }}
      />
    )

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <SizePicker
        {...normalizeSizingProps}
        maxInstanceCount={
          maxInstanceCount
            ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
            : maxInstanceCountForEnvironment
        }
        topologyElement={topologyElement}
        sliderInstanceType={instanceConfiguration.instance_type}
        sliderNodeTypes={instanceConfiguration.node_types}
        inTrial={false}
        showTrialThreshold={false}
        isBlobStorage={false} // don't want blob popovers to show even for frozen
        // in a template we show a option for zero-sized instead of having a disable button
        sizes={[0, ...normalizeSizingProps.sizes]}
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow wideLabel={true} alignItems='center' label={label}>
          <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
            <EuiFlexItem grow={false}>
              <NormalizeSizing
                {...this.props}
                instanceConfiguration={instanceConfiguration}
                autoscalingSizeFilter='fixToMin'
              >
                {controlRenderer}
              </NormalizeSizing>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <AutoscalingInfo topologyElement={topologyElement} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderMinSize(): JSX.Element | null {
    const { maxInstanceCountForEnvironment, topologyElement, maxInstanceCount, deployment } =
      this.props

    if (!isAutoscalingSupportedInTemplate({ deploymentTemplate: deployment })) {
      return null
    }

    if (!(topologyElement as ElasticsearchClusterTopologyElement).autoscaling_min) {
      return null
    }

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-minSizeLabel'
        defaultMessage='Minimum size <nowrap>per zone</nowrap>'
        values={{
          nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
        }}
      />
    )

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <SizePicker
        {...normalizeSizingProps}
        maxInstanceCount={
          maxInstanceCount
            ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
            : maxInstanceCountForEnvironment
        }
        topologyElement={topologyElement}
        sliderInstanceType={instanceConfiguration.instance_type}
        sliderNodeTypes={instanceConfiguration.node_types}
        inTrial={false}
        showTrialThreshold={false}
        isBlobStorage={false}
        // in a template we show a option for zero-sized instead of having a disable button
        sizes={[0, ...normalizeSizingProps.sizes]}
        // overrides to have SizePicker get/set `autoscaling_min` instead of `size`
        size={normalizeSizingProps.autoscalingMinSize || 0}
        onChangeSize={
          normalizeSizingProps.onChangeAutoscaling &&
          ((minValue) => normalizeSizingProps.onChangeAutoscaling!({ minValue }))
        }
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow wideLabel={true} alignItems='center' label={label}>
          <EuiFlexGroup gutterSize='xs'>
            <EuiFlexItem grow={8}>
              <NormalizeSizing
                {...this.props}
                instanceConfiguration={instanceConfiguration}
                autoscalingSizeFilter='fixToMin'
              >
                {controlRenderer}
              </NormalizeSizing>
            </EuiFlexItem>
          </EuiFlexGroup>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderMaxSize(): JSX.Element | null {
    const { maxInstanceCountForEnvironment, topologyElement, maxInstanceCount, deployment } =
      this.props

    if (!isAutoscalingSupportedInTemplate({ deploymentTemplate: deployment })) {
      return null
    }

    if (!isData({ topologyElement }) && !isDedicatedML({ topologyElement })) {
      return null
    }

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-maxSizeLabel'
        defaultMessage='Maximum size <nowrap>per zone</nowrap>'
        values={{
          nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
        }}
      />
    )

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <SizePicker
        {...normalizeSizingProps}
        maxInstanceCount={
          maxInstanceCount
            ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
            : maxInstanceCountForEnvironment
        }
        topologyElement={topologyElement}
        sliderInstanceType={instanceConfiguration.instance_type}
        sliderNodeTypes={instanceConfiguration.node_types}
        inTrial={false}
        showTrialThreshold={false}
        isBlobStorage={false} // don't want blob popovers to show even for frozen
        // in a template we show a option for zero-sized instead of having a disable button
        sizes={[0, ...normalizeSizingProps.sizes]}
        // overrides to have SizePicker get/set `autoscaling_max` instead of `size`
        size={normalizeSizingProps.autoscalingMaxSize || 0}
        onChangeSize={
          normalizeSizingProps.onChangeAutoscaling &&
          ((maxValue) => normalizeSizingProps.onChangeAutoscaling!({ maxValue }))
        }
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow wideLabel={true} alignItems='center' label={label}>
          <EuiFlexGroup gutterSize='xs'>
            <EuiFlexItem grow={8}>
              <NormalizeSizing
                {...this.props}
                instanceConfiguration={instanceConfiguration}
                autoscalingSizeFilter='fixToMin'
              >
                {controlRenderer}
              </NormalizeSizing>
            </EuiFlexItem>
          </EuiFlexGroup>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderAvailabilityZones(): JSX.Element {
    const { id, topologyElement, onChange, maxZoneCount } = this.props

    // Sometimes the default set it outside what is available for certain instance configs
    const defaultZoneCount = Math.min(topologyElement.zone_count || 0, maxZoneCount)

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-availabilityZonesLabel'
        defaultMessage='Availability zones'
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow wideLabel={true} label={label}>
          <ZoneCount
            id={id}
            zoneCount={defaultZoneCount}
            maxZoneCount={maxZoneCount}
            onChange={onChange && ((value) => onChange([`zone_count`], value))}
            sliderInstanceType={instanceConfiguration.instance_type}
            sliderNodeTypes={getNodeRoles({
              topologyElement,
            })}
            isDedicatedMaster={isDedicatedMaster({ topologyElement })}
            inTrial={false}
            showTrialThreshold={false}
          />
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderExtraFields(): React.ReactNode {
    const { extraFields } = this.props

    if (!extraFields?.length) {
      return null
    }

    return extraFields.map((ExtraField, i) => (
      <Fragment key={`extraFields${i}`}>
        <EuiSpacer size='m' />
        <ExtraField {...this.props} />
      </Fragment>
    ))
  }

  renderSummary(): JSX.Element {
    const topologyElement = this.props.topologyElement as ElasticsearchClusterTopologyElement

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const label = (
      <strong>
        <FormattedMessage
          id='deploymentTemplateInfrastructure-templateTopologyElement-summaryLabel'
          defaultMessage='Total (size x zone)'
        />
      </strong>
    )

    return (
      <TopologyPanelRow wideLabel={true} label={label}>
        <NormalizeSizing
          {...this.props}
          instanceConfiguration={instanceConfiguration}
          autoscalingSizeFilter='fixToMin'
        >
          {(normalizeSizingProps) => (
            <Summary
              {...normalizeSizingProps}
              topologyElement={topologyElement}
              sliderInstanceType={instanceConfiguration.instance_type}
              instanceConfiguraton={instanceConfiguration}
              isMachineLearning={isDedicatedML({ topologyElement })}
              isFrozen={isFrozen({ topologyElement })}
              autoscalingMin={topologyElement.autoscaling_min?.value}
              zoneCount={topologyElement.zone_count || 1} // 1 as sane fallback, though "should" always be present
            />
          )}
        </NormalizeSizing>
      </TopologyPanelRow>
    )
  }

  renderUserSettings(): JSX.Element | undefined {
    const { deployment, topologyElement, sliderInstanceType, onChange } = this.props

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-userSettingsLabel'
        defaultMessage='User settings'
      />
    )

    const instanceConfiguration = this.getCurrentInstanceConfiguration()

    const plan = getSliderPlan({ deployment, sliderInstanceType })

    if (!plan) {
      return // sanity
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return // sanity
    }

    if (hasOnlyPlanLevelSettings(resource)) {
      return // default to plan-level settings
    }

    const settings = getSliderUserSettings({
      sliderInstanceType,
      plan,
      nodeConfiguration: topologyElement,
    })
    const defaultSettings = getDefaultUserSettings(sliderInstanceType)
    const setSettings =
      onChange &&
      ((value: string) =>
        onChange([instanceConfiguration.instance_type, `user_settings_yaml`], value))

    const {
      messages: { prettyName },
      userSettingsFileName,
    } = getSliderDefinition({ sliderInstanceType })
    const docLink = `${camelCase(instanceConfiguration.instance_type)}UserSettingsDocLink`

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow wideLabel={true} label={label}>
          <EuiText>
            <UserSettings
              settings={settings == null ? defaultSettings : settings}
              onChange={setSettings}
              isReadOnly={!onChange}
              prettyName={prettyName}
              fileName={userSettingsFileName}
              docLink={docLink}
            />
          </EuiText>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  getCurrentInstanceConfiguration(): InstanceConfiguration {
    const { topologyElement, instanceConfigurations } = this.props

    return getInstanceConfigurationById(
      instanceConfigurations,
      topologyElement.instance_configuration_id!,
    )!
  }

  onClickInfoPopover() {
    this.setState({ isTitlePopoverOpen: !this.state.isTitlePopoverOpen })
  }

  closeInfoPopOver() {
    this.setState({ isTitlePopoverOpen: false })
  }
}

export default injectIntl(TemplateTopologyElement)
