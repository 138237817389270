/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get } from 'lodash'

import {
  EuiSpacer,
  EuiTitle,
  EuiHealth,
  EuiText,
  EuiDescribedFormGroup,
  EuiFormRow,
} from '@elastic/eui'

import { CuiAlert, CuiPermissibleControl } from '../../../cui'
import SpinButton from '../../SpinButton'
import history from '../../../lib/history'
import { authenticationProvidersUrl } from '../../../lib/urlBuilder'
import Permission from '../../../lib/api/v1/permissions'

import type { SecurityDeployment } from '../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../types'

export interface StateProps {
  securityCluster?: SecurityDeployment
  createRequest: AsyncRequestState
  fetchRequest: AsyncRequestState
}

export interface DispatchProps {
  onCreate: () => Promise<any>
  onFetch: () => Promise<any>
}

export type ConsumerProps = {
  regionId: string
}

export type Props = StateProps & DispatchProps & ConsumerProps

export default class RbacStatus extends Component<Props> {
  componentDidMount() {
    this.props.onFetch()
  }

  render() {
    return (
      <EuiDescribedFormGroup
        title={
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage id='rbac-status.title' defaultMessage='Role-based access control' />
            </h3>
          </EuiTitle>
        }
        fullWidth={true}
      >
        {this.renderStatus()}
      </EuiDescribedFormGroup>
    )
  }

  renderStatus() {
    const { createRequest, fetchRequest } = this.props

    return (
      <EuiFormRow fullWidth={true}>
        <div>
          {this.renderContent()}

          {createRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='error'>{createRequest.error}</CuiAlert>
            </Fragment>
          )}

          {fetchRequest.error && get(fetchRequest.error, ['response', 'status']) !== 404 && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='error' data-test-id='security-cluster-fetch-error'>
                {fetchRequest.error}
              </CuiAlert>
            </Fragment>
          )}
        </div>
      </EuiFormRow>
    )
  }

  renderContent() {
    const { createRequest, securityCluster } = this.props

    if (securityCluster == null) {
      return (
        <Fragment>
          <EuiHealth color='danger'>
            <FormattedMessage id='rbac-status.is-disabled' defaultMessage='Disabled' />
          </EuiHealth>

          <EuiSpacer size='m' />

          <EuiText color='subdued'>
            <FormattedMessage
              id='rbac-status.not-configured'
              defaultMessage='Role-based access control is not configured. Click the button below to create a security cluster and enabled RBAC.'
            />
          </EuiText>

          <EuiSpacer size='m' />

          <CuiPermissibleControl permissions={Permission.createSecurityDeployment}>
            <SpinButton
              data-test-id='create-security-cluster'
              onClick={this.onCreate}
              fill={true}
              spin={createRequest.inProgress}
            >
              <FormattedMessage
                id='rbac-status.create-security-cluster'
                defaultMessage='Create security cluster'
              />
            </SpinButton>
          </CuiPermissibleControl>
        </Fragment>
      )
    }

    if (securityCluster.is_enabled) {
      return (
        <EuiHealth color='success' data-test-id='rbac-is-enabled'>
          <FormattedMessage id='rbac-status.is-enabled' defaultMessage='Enabled' />
        </EuiHealth>
      )
    }

    return (
      <Fragment>
        <EuiHealth color='danger'>
          <FormattedMessage id='rbac-status.is-disabled' defaultMessage='Disabled' />
        </EuiHealth>
      </Fragment>
    )
  }

  onCreate = () => {
    const { regionId, onCreate } = this.props

    return onCreate().then(() => history.push(authenticationProvidersUrl(regionId)))
  }
}
