/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import WarnOfPlanChange from '../../WarnOfPlanChange'
import SpinButton from '../../../../../../../components/SpinButton'
import { labels } from '../../authProviderMessages'
import { CuiPermissibleControl } from '../../../../../../../cui/PermissibleControl'
import Permission from '../../../../../../../lib/api/v1/permissions'

import type { FunctionComponent } from 'react'

interface Props {
  mode: 'create' | 'edit'
  onSubmit: () => void
  inProgress: boolean
  onCancel: () => void
  disabled: boolean
}

const SubmitButtons: FunctionComponent<Props> = ({
  mode,
  onSubmit,
  inProgress,
  onCancel,
  disabled,
}) => (
  <Fragment>
    <WarnOfPlanChange />

    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <CuiPermissibleControl
          permissions={
            mode === 'create'
              ? Permission.createActiveDirectoryConfiguration
              : Permission.updateActiveDirectoryConfiguration
          }
        >
          <SpinButton
            data-test-id='active-directory-submit-button'
            fill={true}
            disabled={disabled}
            type='submit'
            onClick={onSubmit}
            spin={inProgress}
          >
            <FormattedMessage
              {...(mode === 'create' ? labels.createProfile : labels.saveProfile)}
            />
          </SpinButton>
        </CuiPermissibleControl>
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiButtonEmpty
          data-test-id='active-directory-cancel-button'
          disabled={inProgress}
          onClick={onCancel}
        >
          <FormattedMessage {...labels.cancel} />
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

export default SubmitButtons
