/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { setNodeRole } from '../stackDeployments/nodeRoles'
import {
  isDedicatedMaster,
  isMaster,
  getUnhiddenNodeRoles,
  isDedicatedML,
} from '../stackDeployments/selectors'
import { getTier } from '../stackDeployments/selectors/nodeRoles'

import type { EsNodeType, AnyTopologyElement } from '../../types'
import type { ElasticsearchClusterTopologyElement, InstanceConfiguration } from '../api/v1/types'

export type TopologyCategorization = {
  data: ElasticsearchClusterTopologyElement[]
  master: ElasticsearchClusterTopologyElement[]
  ingest: ElasticsearchClusterTopologyElement[]
  ml: ElasticsearchClusterTopologyElement[]
}

export function removeNonDedicatedMasters(
  topology: ElasticsearchClusterTopologyElement[],
): ElasticsearchClusterTopologyElement[] {
  return topology.map((topologyElement) => {
    if (isMaster({ topologyElement }) && !isDedicatedMaster({ topologyElement })) {
      return setNodeRole({ topologyElement, role: `master`, value: false })
    }

    return topologyElement
  })
}

function getOnlyNodeType(topologyElement: ElasticsearchClusterTopologyElement): string | null {
  const nodeTypes = getUnhiddenNodeRoles({ topologyElement })
  return nodeTypes.length === 1 ? nodeTypes[0] : null
}

function isDedicatedNodeType(nodeConfig: ElasticsearchClusterTopologyElement): boolean {
  const dedicatedNodeTypes: EsNodeType[] = [`master`, `ingest`]
  return dedicatedNodeTypes.some((dedicatedNodeType) => {
    const onlyNodeType = getOnlyNodeType(nodeConfig)
    return onlyNodeType === dedicatedNodeType
  })
}

export function getNodeTypeForArchitectureSummary(
  nodeConfiguration: AnyTopologyElement | null,
  instance: InstanceConfiguration,
  hasTiebreaker: boolean,
): string {
  if (!instance) {
    return `data`
  }

  if (!nodeConfiguration) {
    return instance.instance_type // sanity
  }

  if (instance.instance_type !== `elasticsearch`) {
    return instance.instance_type
  }

  // Dedicated masters and coordinating nodes
  const isDedicated = isDedicatedNodeType(nodeConfiguration as ElasticsearchClusterTopologyElement)
  const onlyNodeType = getOnlyNodeType(nodeConfiguration as ElasticsearchClusterTopologyElement)

  if (isDedicated && onlyNodeType) {
    const dedicatedMaster = isDedicatedMaster({ topologyElement: nodeConfiguration })

    if (hasTiebreaker && dedicatedMaster) {
      return `tiebreaker`
    }

    return onlyNodeType
  }

  if (isDedicatedML({ topologyElement: nodeConfiguration })) {
    return 'ml'
  }

  return getTier(nodeConfiguration) || `data`
}
