/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTitle } from '@elastic/eui'

import { CuiAlert } from '../../../cui'

import PlatformVersion from './PlatformVersion'
import PlatformAdminServersStatus from './PlatformAdminServersStatus'

import type { AsyncRequestState } from '../../../types'
import type { PlatformInfo } from '../../../lib/api/v1/types'

import './platform.scss'

interface Props {
  fetchPlatform: () => Promise<any>
  regionId: string
  platform: PlatformInfo | null
  fetchPlatformRequest: AsyncRequestState
}

class Platform extends Component<Props> {
  render() {
    return (
      <Fragment>
        <EuiTitle size='s'>
          <h2>
            <FormattedMessage
              id='region-admin-platform.system-information'
              defaultMessage='System information'
            />
          </h2>
        </EuiTitle>

        <EuiSpacer size='m' />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { regionId, platform, fetchPlatformRequest } = this.props

    if (fetchPlatformRequest.error) {
      return <CuiAlert type='error'>{fetchPlatformRequest.error}</CuiAlert>
    }

    return (
      <Fragment>
        <PlatformVersion platform={platform} />

        <EuiSpacer size='l' />

        <PlatformAdminServersStatus regionId={regionId} platform={platform} />
      </Fragment>
    )
  }
}

export default Platform
