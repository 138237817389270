/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCodeBlock,
  EuiDescribedFormGroup,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiTable } from '../../../../cui'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

const messages = defineMessages({
  fingerprint: {
    id: 'certificateAuthority.table.fingerprint',
    defaultMessage: 'Fingerprint',
  },
  expires: {
    id: 'certificateAuthority.table.expires',
    defaultMessage: 'Expires',
  },
  status: {
    id: 'certificateAuthority.table.status',
    defaultMessage: 'Status',
  },
  valid: {
    id: 'certificateAuthority.table.valid',
    defaultMessage: 'Valid From',
  },
  download: {
    id: 'certificateAuthority.table.download',
    defaultMessage: 'Download',
  },
})

class CertificateAuthority extends React.Component<AllProps & WrappedComponentProps> {
  componentDidMount(): void {
    const { deployment, fetchCertificateAuthority } = this.props

    fetchCertificateAuthority(deployment.id)
  }

  render(): JSX.Element {
    const {
      certificateAuthority,
      fetchCertificateAuthorityRequest,
      intl: { formatMessage },
    } = this.props

    const certificateAuthorityTitle = (
      <h3>
        <FormattedMessage id='certificateAuthority.title' defaultMessage='CA certificates' />
      </h3>
    )

    const certificateAuthorityDescription = (
      <p>
        <FormattedMessage
          id='certificateAuthority.description'
          defaultMessage='Use these CA certificates to trust this deployment from an Elastic Cloud Enterprise or self-managed environment.'
        />
      </p>
    )

    const trustRestrictionsTitle = (
      <h3>
        <FormattedMessage id='trustRestrictions.title' defaultMessage='Trust restrictions' />
      </h3>
    )

    const trustRestrictionsDescription = (
      <p>
        <FormattedMessage
          id='trustRestrictions.description'
          defaultMessage='Restrict the trust granted by the region CA certificate to all or specific cluster nodes of this deployment. You must add this configuration to self-managed environments trusting this deployment.'
        />
      </p>
    )

    return (
      <Fragment>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={certificateAuthorityTitle}
          description={certificateAuthorityDescription}
          data-test-id='certificate-authority'
        >
          {renderCACertificateContent()}
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={trustRestrictionsTitle}
          description={trustRestrictionsDescription}
          data-test-id='trust-management'
        >
          {renderTrustRestrictionsContent()}
        </EuiDescribedFormGroup>
      </Fragment>
    )

    function renderCACertificateContent(): JSX.Element {
      if (fetchCertificateAuthorityRequest.inProgress) {
        return <EuiLoadingSpinner />
      }

      if (fetchCertificateAuthorityRequest.error) {
        return <CuiAlert type='error'>{fetchCertificateAuthorityRequest.error}</CuiAlert>
      }

      return (
        <div>
          <CuiTable
            rows={certificateAuthority?.public_certificates}
            columns={getColumns(formatMessage)}
          />
        </div>
      )
    }

    function renderTrustRestrictionsContent() {
      if (fetchCertificateAuthorityRequest.inProgress || fetchCertificateAuthorityRequest.error) {
        // the section above will display the errors and loading progress
        return null
      }

      const yamlToShow = `trust.subject_name:
  - "${certificateAuthority?.recommended_trust_restriction}"
  # - "insert pattern(s) matching the local nodes"
  # - "insert pattern(s) matching other remote clusters trusted by this or other certs"
      `
      return (
        <Fragment>
          <EuiSpacer />
          <EuiCodeBlock isCopyable={true} paddingSize='m' language='yaml' whiteSpace='pre'>
            {yamlToShow}
          </EuiCodeBlock>
        </Fragment>
      )
    }

    function getColumns(formatMessage) {
      return [
        {
          label: formatMessage(messages.fingerprint),
          render: (cert) => `${cert.metadata.fingerprint.substring(0, 10)}`,
        },
        {
          label: formatMessage(messages.status),
          render: (cert) => {
            if (cert.active) {
              return (
                <FormattedMessage
                  id='deploymentTrustManagement.status.active'
                  defaultMessage='Active'
                />
              )
            }

            return (
              <FormattedMessage
                id='deploymentTrustManagement.status.inactive'
                defaultMessage='Inactive'
              />
            )
          },
        },
        {
          label: formatMessage(messages.valid),
          render: (cert) => {
            const d = new Date(cert.metadata.valid_from)
            return (
              <EuiText size='s'>
                {d.toLocaleString(`defaut`, { month: `long`, year: `numeric`, day: `numeric` })}
              </EuiText>
            )
          },
        },
        {
          label: formatMessage(messages.expires),
          render: (cert) => {
            const d = new Date(cert.metadata.valid_to)
            const currentDate = new Date()
            // the difference between today and the day it expires in days
            const diff = (d.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
            const displayDate = d.toLocaleString(`defaut`, {
              month: `long`,
              year: `numeric`,
              day: `numeric`,
            })

            if (diff < 0) {
              return (
                <EuiText color='danger' size='s'>
                  <FormattedMessage
                    id='deploymentTrustManagement.status.expired'
                    defaultMessage='Expired {date}'
                    values={{
                      date: displayDate,
                    }}
                  />
                </EuiText>
              )
            }

            if (diff < 7) {
              return (
                <EuiText color='danger' size='s'>
                  {displayDate}
                </EuiText>
              )
            }

            return <EuiText size='s'>{displayDate}</EuiText>
          },
        },
        {
          label: formatMessage(messages.download),
          render: (cert) => {
            const blob = new window.Blob([cert.pem || ``], {
              type: `application/x-x509-ca-cert;charset=utf-8`,
            })
            const fileName = `${cert.metadata.fingerprint}.crt`
            const contents = window.URL.createObjectURL(blob)

            return <EuiButtonIcon download={fileName} href={contents} iconType='download' />
          },
        },
      ]
    }
  }
}

export default injectIntl(CertificateAuthority)
