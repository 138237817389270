/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiAlert } from '../../../cui'

import type { ReactNode } from 'react'
import type { AsyncRequestState, Region } from '../../../types'

export type Props = {
  children?: ReactNode
  fetchRegionIfNeeded: (regionId: string) => void
  region?: Region
  regionId: string
  regionRequest: AsyncRequestState
}

class RegionWrapper extends Component<Props> {
  componentDidMount() {
    const { regionId, fetchRegionIfNeeded } = this.props
    fetchRegionIfNeeded(regionId)
  }

  componentDidUpdate(prevProps: Props) {
    const { regionId, fetchRegionIfNeeded } = this.props

    if (regionId !== prevProps.regionId) {
      fetchRegionIfNeeded(regionId)
    }
  }

  render() {
    const { regionRequest, regionId, children } = this.props

    if (regionRequest.error) {
      return (
        <CuiAlert details={regionRequest.error} type='error'>
          <FormattedMessage
            id='region-region-wrapper.fetching-region-region-id-failed'
            defaultMessage='Fetching region {regionId} failed'
            values={{ regionId }}
          />
        </CuiAlert>
      )
    }

    return children
  }
}

export default RegionWrapper
