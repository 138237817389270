/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiFlexGroupProps } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  label: ReactNode
  alignItems?: EuiFlexGroupProps['alignItems']
  children: ReactNode
  labelColor?: 'subdued' | 'warning'
  wideLabel?: boolean
}

const TopologyPanelRow: FunctionComponent<Props> = ({
  label,
  alignItems = 'flexStart',
  children,
  labelColor = 'subdued',
  wideLabel,
}) => (
  <EuiFlexGroup
    className='topologyPanel-row'
    gutterSize='l'
    alignItems={alignItems}
    responsive={false}
  >
    <EuiFlexItem grow={1} style={wideLabel ? { minWidth: 255 } : undefined}>
      <EuiText size='s' color={labelColor} className='topologyPanel-row-label'>
        {label}
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={4}>{children}</EuiFlexItem>
  </EuiFlexGroup>
)

export default TopologyPanelRow
