/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, sortBy } from 'lodash'
import cx from 'classnames'

import { EuiBadge, EuiIcon } from '@elastic/eui'

import { CuiTable, CuiLink, CuiTimeAgo } from '../../../../cui'
import ProductIcons from '../components/ProductIcons'
import {
  topologyEditDeploymentTemplateUrl,
  topologyViewDeploymentTemplateUrl,
} from '../../../../lib/urlBuilder'
import { isPureIndexCurationTemplate } from '../../../../lib/stackDeployments'
import { isDeprecatedConfig } from '../../../../lib/instanceConfigurations/instanceConfiguration'

import type { DeploymentTemplateInfoV2 } from '../../../../lib/api/v1/types'
import type { RegionId } from '../../../../types'
import type { CuiTableColumn } from '../../../../cui'
import type { FunctionComponent } from 'react'

import './deploymentTemplatesTable.scss'

type Props = {
  deploymentTemplates?: DeploymentTemplateInfoV2[]
  regionId: RegionId
}

const DeploymentTemplatesTable: FunctionComponent<Props> = ({ deploymentTemplates, regionId }) => {
  const anyDeprecated =
    deploymentTemplates &&
    deploymentTemplates.some(({ instance_configurations = [] }) =>
      instance_configurations.some(isDeprecatedConfig),
    )

  const anyPureCurationTemplates =
    deploymentTemplates &&
    deploymentTemplates.some((deploymentTemplate) =>
      isPureIndexCurationTemplate({ deploymentTemplate }),
    )

  const columns: Array<CuiTableColumn<DeploymentTemplateInfoV2>> = [
    {
      verticalAlign: `top`,
      label: <FormattedMessage id='deployment-template-table.header-name' defaultMessage='Name' />,
      render: (template) => (
        <Fragment>
          <CuiLink to={topologyViewDeploymentTemplateUrl(regionId, template.id!)}>
            {template.name}
          </CuiLink>
          <div>{template.description}</div>
        </Fragment>
      ),
      sortKey: `name`,
    },

    {
      width: `12rem`,
      verticalAlign: `top`,
      label: (
        <FormattedMessage id='deployment-template-table.header-types' defaultMessage='Products' />
      ),
      render: (template) => <ProductIcons deploymentTemplateInfo={template} />,
    },

    {
      width: `9rem`,
      verticalAlign: `top`,
      label: (
        <FormattedMessage
          id='deployment-template-table.header-min-version'
          defaultMessage='Min. version'
        />
      ),
      render: (template) => <div>{template.min_version}</div>,
    },

    {
      width: `8rem`,
      verticalAlign: `top`,
      label: (
        <FormattedMessage
          id='deployment-template-table.header-createdBy'
          defaultMessage='Created by'
        />
      ),
      render: (template) => (
        <EuiBadge color='hollow'>
          {template.system_owned ? (
            <FormattedMessage id='deployment-template-table.table.system' defaultMessage='System' />
          ) : (
            <FormattedMessage id='deployment-template-table.table.user' defaultMessage='User' />
          )}
        </EuiBadge>
      ),
      sortKey: `system_owned`,
    },

    {
      width: `12rem`,
      verticalAlign: `top`,
      label: (
        <FormattedMessage
          id='deployment-template-table.header-lastUpdated'
          defaultMessage='Last updated'
        />
      ),
      render: (template) => template.source && <CuiTimeAgo date={template.source.date} />,
      sortKey: `source.date`,
    },
  ]

  if (anyDeprecated || anyPureCurationTemplates) {
    columns.push({
      width: `10rem`,
      verticalAlign: `top`,
      label: (
        <FormattedMessage
          id='topologyinstanceConfigurations.table.additionalInformationHeader'
          defaultMessage='Additional information'
        />
      ),
      render: (deploymentTemplate) => {
        const hasILM = isPureIndexCurationTemplate({ deploymentTemplate })
        const hasDeprecatedConfigs =
          deploymentTemplate.instance_configurations?.some(isDeprecatedConfig)

        return (
          <div style={{ maxWidth: `8rem` }}>
            {hasILM && (
              <CuiLink
                to={{
                  pathname: topologyEditDeploymentTemplateUrl(regionId, deploymentTemplate.id!),
                  state: { showStep: `indexManagement` },
                }}
              >
                <FormattedMessage
                  id='deployment-template-table.configure-ilm'
                  defaultMessage='Configure ILM'
                />
                <EuiIcon type='arrowRight' />
              </CuiLink>
            )}
            {hasDeprecatedConfigs && (
              <CuiLink
                to={{
                  pathname: topologyEditDeploymentTemplateUrl(regionId, deploymentTemplate.id!),
                  state: { showStep: `instances` },
                }}
              >
                <FormattedMessage
                  id='deployment-template-table.update-configuration'
                  defaultMessage='Update deprecated configuration'
                />
                <EuiIcon type='arrowRight' />
              </CuiLink>
            )}
          </div>
        )
      },
    })
  }

  return (
    <CuiTable<DeploymentTemplateInfoV2>
      rows={sortBy(deploymentTemplates, ['system_owned', 'name'])}
      columns={columns}
      getRowId={(deploymentTemplate) => deploymentTemplate.id!}
      rowClass={getDeploymentTemplateTableRowClasses}
    />
  )
}

export default DeploymentTemplatesTable

function getDeploymentTemplateTableRowClasses(deploymentTemplate: DeploymentTemplateInfoV2) {
  const hasDeprecatedConfigs = deploymentTemplate.instance_configurations?.some(isDeprecatedConfig)

  const classes = {
    'deploymentTemplatesTable-tableRow': true,
    'deploymentTemplate-curationOnly': isPureIndexCurationTemplate({ deploymentTemplate }),
    'background-warning': hasDeprecatedConfigs,
  }

  return isEmpty(classes) ? undefined : cx(classes)
}
