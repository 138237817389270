/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getConfigForKey } from '@/selectors'
import { fetchProfile } from '@/apps/userconsole/actions/profile'
import { getProfile } from '@/apps/userconsole/reducers'
import { refreshSaasCurrentUser } from '@/actions/organizations'
import { claimInstantStackDeploymentRequest } from '@/reducers/asyncRequests/registry'

import { redirectToStackGettingStarted } from '../../../../actions/clusters'
import {
  createDeployment,
  claimInstantStackDeployment,
  resetCreateDeployment,
  fetchDeployment,
} from '../../../../actions/stackDeployments'
import {
  createStackDeploymentRequest,
  getRegion,
  getActiveVersionStacks,
} from '../../../../reducers'

import CreateDeploymentButton from './CreateDeploymentButton'

import type { ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state, { editorState }: ConsumerProps): StateProps => {
  const regionId = editorState.regionId!

  return {
    profile: getProfile(state),
    region: getRegion(state, regionId),
    createStackDeploymentRequest: createStackDeploymentRequest(state),
    claimInstantStackDeploymentRequest: claimInstantStackDeploymentRequest(state),
    stackVersions: getActiveVersionStacks(state, regionId),
    isUserconsole: getConfigForKey(state, `APP_NAME`) === `userconsole`,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createDeployment: (params) => dispatch(createDeployment(params)),
  claimInstantStackDeployment: (params) => dispatch(claimInstantStackDeployment(params)),
  fetchDeployment: (deploymentId) =>
    dispatch(refreshSaasCurrentUser()).then(() => dispatch(fetchDeployment({ deploymentId }))),
  resetCreateDeployment: () => dispatch(resetCreateDeployment()),
  redirectToStackGettingStarted: (stackDeploymentId) =>
    dispatch(redirectToStackGettingStarted(stackDeploymentId)),
  fetchProfile: () => dispatch(fetchProfile()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDeploymentButton)
