/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getThemeColors } from '../../lib/theme'

const themeColors = getThemeColors()

const unknownColor = themeColors.euiColorDarkShade

const baseColors: Map<number, string> = new Map([
  [1024 * 64, themeColors.euiColorVis8],
  [1024 * 58, themeColors.euiColorVis8],
  [1024 * 32, themeColors.euiColorVis2],
  [1024 * 16, themeColors.euiColorVis7],
  [1024 * 8, themeColors.euiColorVis3],
  [1024 * 4, themeColors.euiColorVis5],
  [1024 * 2, themeColors.euiColorVis1],
  [1024, themeColors.euiColorVis0],
])

function getBaseColor(capacity?: number): string {
  return (capacity && baseColors.get(capacity)) || unknownColor
}

export function getCapacityColor(capacity?: number, alternate: boolean = false): string {
  const baseColor = getBaseColor(capacity)

  if (alternate) {
    return `${baseColor}80` // #abcabc80, where 80 is 50% opacity in hex colors
  }

  return baseColor
}
