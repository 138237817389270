/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiStat,
  EuiButtonGroup,
  EuiFlexGrid,
  EuiFlexItem,
} from '@elastic/eui'

import { CuiAlert } from '@/cui'
import CuiElasticConsumptionUnits from '@/cui/formatters/CuiElasticConsumptionUnits'

import UsageStat from '../UsageStat'
import messages from '../../messages'

import { Tab } from './types'
import { usageRates, options } from './constants'
import { getPreviousMonthDates, mapDimensions } from './utils'

import type { AllProps } from './types'
import type { ReactElement } from 'react'

interface State {
  selectedTab: Tab
}

class UsageCard extends Component<AllProps, State> {
  state = {
    selectedTab: Tab.Current,
  }

  componentDidMount() {
    this.fetchCostOverview()
  }

  componentDidUpdate(_prevProps, prevState) {
    const { selectedTab } = this.state

    if (prevState.selectedTab !== selectedTab) {
      this.fetchCostOverview()
    }
  }

  render(): ReactElement {
    const { selectedTab } = this.state

    return (
      <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} data-test-id='usage-card'>
        <EuiButtonGroup
          legend='Month Selection'
          name='monthSelection'
          options={options}
          idSelected={selectedTab}
          onChange={this.onSelectedTabChanged}
          buttonSize='compressed'
        />

        <EuiSpacer size='m' />

        <EuiText size='s'>
          <h2>{this.getTabTitle()}</h2>
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderUsageStats()}
      </EuiPanel>
    )
  }

  renderUsageStats = () => {
    const { selectedTab } = this.state
    const {
      costOverviewCurrentMonth,
      costOverviewPreviousMonth,
      costOverviewCurrentMonthRequest,
      costOverviewPreviousMonthRequest,
    } = this.props

    // nosemgrep
    const { costs, loading, error } = {
      [Tab.Current]: {
        costs: costOverviewCurrentMonth?.costs,
        loading: costOverviewCurrentMonthRequest.inProgress,
        error: costOverviewCurrentMonthRequest.error,
      },
      [Tab.Previous]: {
        costs: costOverviewPreviousMonth?.costs,
        loading: costOverviewPreviousMonthRequest.inProgress,
        error: costOverviewPreviousMonthRequest.error,
      },
    }[selectedTab]

    const dimensions = mapDimensions(costs?.dimensions)

    if (error) {
      return (
        <CuiAlert type='error' data-test-id='error-message'>
          <FormattedMessage {...messages.genericErrorMessage} />
        </CuiAlert>
      )
    }

    return (
      <div data-test-id={`${selectedTab}-month-content`}>
        <EuiStat
          isLoading={loading}
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits
                  value={costs?.total || 0}
                  unit='none'
                  withSymbol={false}
                />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
              data-test-id='usage-card.total'
            />
          }
          titleSize='l'
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.currentUsage} />
            </EuiText>
          }
          descriptionElement='div'
        />

        <EuiSpacer size='m' />

        <EuiFlexGrid columns={2} gutterSize='m' responsive={false}>
          {usageRates.map(({ key, description, dataTestId }) => (
            <EuiFlexItem key={key}>
              <EuiStat
                isLoading={loading}
                title={
                  <UsageStat
                    title={
                      <CuiElasticConsumptionUnits
                        value={dimensions[key] || 0}
                        unit='none'
                        withSymbol={false}
                      />
                    }
                    data-test-id={dataTestId}
                    subText={<FormattedMessage {...messages.ecuUnit} />}
                  />
                }
                description={<EuiText size='s'>{description}</EuiText>}
                descriptionElement='div'
                titleSize='xs'
              />
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      </div>
    )
  }

  fetchCostOverview = (): void => {
    const { selectedTab } = this.state
    const { fetchAccountCostsOverviewIfNeeded } = this.props

    if (selectedTab === Tab.Current) {
      fetchAccountCostsOverviewIfNeeded()
    }

    if (selectedTab === Tab.Previous) {
      fetchAccountCostsOverviewIfNeeded(getPreviousMonthDates())
    }
  }

  onSelectedTabChanged = (selectedTab: Tab): void => {
    this.setState({ selectedTab })
  }

  getTabTitle = (): string => {
    const { selectedTab } = this.state
    const isFirstDayOfMonth = moment().format('D') === '1'

    return {
      [Tab.Current]: isFirstDayOfMonth
        ? moment().format('MMMM D, YYYY')
        : `${moment().format('MMMM')} 1 - ${moment().format('D, YYYY')}`,
      [Tab.Previous]: `${moment().add(-1, 'months').format('MMMM YYYY')}`,
    }[selectedTab]
  }
}

export default UsageCard
