/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { saveSecurityConfig } from '../../actions/clusters'
import { getCluster, getSaveClusterAclInformation, getStackDeployment } from '../../reducers'
import { withStackDeploymentRouteParams } from '../StackDeploymentEditor'

import DeploymentSecurity from './DeploymentSecurity'

import type { WithStackDeploymentRouteParamsProps } from '../StackDeploymentEditor'
import type { ElasticsearchCluster, StackDeployment } from '../../types'

type SecurityConfig = ElasticsearchCluster['security']['config']

type StateProps = {
  cluster?: ElasticsearchCluster | null
  deployment: StackDeployment | null
  saveClusterAclInformation: {
    isSaving: boolean
    isSaved: boolean
    error: Error | null
  }
}

type DispatchProps = {
  saveSecurityConfig: (cluster: ElasticsearchCluster, securityConfig: SecurityConfig) => void
}

type ConsumerProps = WithStackDeploymentRouteParamsProps

const mapStateToProps = (
  state,
  { regionId, deploymentId, stackDeploymentId }: ConsumerProps,
): StateProps => ({
  cluster: getCluster(state, regionId, deploymentId!),
  deployment: getStackDeployment(state, stackDeploymentId),
  saveClusterAclInformation: getSaveClusterAclInformation(state, regionId, deploymentId!),
})

const mapDispatchToProps: DispatchProps = {
  saveSecurityConfig,
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Deployment security`, `component`)(DeploymentSecurity)),
)
