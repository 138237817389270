/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import { HealthStatusDetailsTroubleshootingDiagnosis } from './HealthStatusDetailsTroubleshootingDiagnosis'

import type { FunctionComponent } from 'react'
import type { Problem } from '@/lib/healthProblems'

export interface Props extends WithEuiThemeProps {
  problem?: Problem
}

export const HealthStatusDetailsTroubleshooting: FunctionComponent<Props> = ({
  problem,
  theme,
}) => (
  <React.Fragment>
    <EuiFlexGroup gutterSize='none' direction='column' responsive={false}>
      {problem?.diagnosis?.map((diagnosis, index) => (
        <HealthStatusDetailsTroubleshootingDiagnosis
          key={index}
          diagnosis={diagnosis}
          theme={theme}
        />
      ))}
    </EuiFlexGroup>
  </React.Fragment>
)
