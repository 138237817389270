/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { get } from 'lodash'

import ConfigureDeploymentSteps from './ConfigureDeploymentSteps'

import type { ReduxState } from '@/types'
import type { StateProps, DispatchProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => ({
  inTrial: get(state, [`profile`, `inTrial`], false),
})

const mapDispatchToProps: DispatchProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureDeploymentSteps)
