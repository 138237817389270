/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { round } from 'lodash'

import type { AccessorFn } from '@elastic/charts'
import {
  Chart,
  Axis,
  BarSeries,
  Position,
  ScaleType,
  Settings,
  LineSeries,
  DARK_THEME,
  LIGHT_THEME,
} from '@elastic/charts'
import { EuiLoadingChart } from '@elastic/eui'

import { withErrorBoundary } from '@/cui'

import { ChartType, ViewBy, Dimension } from '../../types'
import { MapDimensionNameToColor } from '../../constants'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type { Props } from './types'

const dimensions = [Dimension.CAPACITY, Dimension.DATA_TRANSFER, Dimension.STORAGE]

const getDataAccessors = () =>
  dimensions.map((dimension) => {
    const accessor: AccessorFn = ({ values }) => {
      const item = values.find(({ id }) => id === dimension)
      accessor.fieldName = item.name

      return item?.value || 0
    }

    return accessor
  })

// yAccessor is dimension name in this case
const colorAccessor = ({ yAccessor }) => MapDimensionNameToColor[yAccessor]

const chartComponent = {
  [ChartType.BAR]: BarSeries,
  [ChartType.LINE]: LineSeries,
}

const viewByFormat = {
  [ViewBy.DAY]: 'MMM DD',
  [ViewBy.MONTH]: 'MMM YYYY',
}

const chartTheme = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
}

const stackAccessors = {
  [ChartType.BAR]: ['timestamp'],
  [ChartType.LINE]: [],
}

const DeploymentsChart: FunctionComponent<Props & WrappedComponentProps> = ({
  data = [],
  chartType,
  theme,
  isLoading = false,
  viewBy,
}) => {
  // nosemgrep
  const ChartComponent = chartComponent[chartType]

  if (isLoading) {
    return <EuiLoadingChart size='xl' />
  }

  // Because the x-axis is ordinal, we need to define the domain.
  const xDomain = data.map(({ timestamp }) => timestamp)

  return (
    <Chart size={['100%', '100%']}>
      <Settings baseTheme={chartTheme[theme]} xDomain={xDomain} />
      <Axis
        id='amount'
        position={Position.Left}
        showGridLines={true}
        ticks={6}
        tickFormat={(d) => String(round(Math.abs(d), 2))} // the same format as in the table below the graph
      />
      <Axis
        id='timestamp'
        position={Position.Bottom}
        tickFormat={(d) => moment.utc(d).format(viewByFormat[viewBy])}
        showOverlappingTicks={false}
        showOverlappingLabels={false}
        ticks={4}
      />
      <ChartComponent
        id='usage'
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor='timestamp'
        yAccessors={getDataAccessors()}
        stackAccessors={stackAccessors[chartType]}
        splitSeriesAccessors={[]}
        data={data}
        color={colorAccessor}
      />
    </Chart>
  )
}

export default injectIntl(withErrorBoundary(DeploymentsChart))
