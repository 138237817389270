/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import LandingPage from '../../../../../components/LandingPage'

import { getTokenAndStateThenRedirect } from './lib'

import type { Props } from './lib'

class SsoReceiveToken extends Component<Props> {
  componentDidMount() {
    const { saveToken, redirect, location } = this.props
    getTokenAndStateThenRedirect({ saveToken, redirect, location })
  }

  render() {
    return (
      <LandingPage loading={true}>
        <div>
          <FormattedMessage
            id='sso-receive-token.authenticating'
            defaultMessage='Authenticating …'
          />
        </div>
      </LandingPage>
    )
  }
}

export default SsoReceiveToken
