/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import {
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiCode,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiLoadingContent,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert, CuiTable, withErrorBoundary } from '../../cui'

import EditZkLoggingSettings from './EditZkLoggingSettings'

import type { LoggerLevel, AsyncRequestState } from '../../types'
import type { LoggingSettings } from '../../lib/api/v1/types'
import type { CuiTableColumn } from '../../cui'
import type { WrappedComponentProps } from 'react-intl'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type EditModeOptions = 'new' | 'view'

type ConsumerProps = {
  tableHeader?: boolean
  loggingSettings: LoggingSettings | null
  fetchLoggingSettings: () => void
  fetchLoggingSettingsRequest: AsyncRequestState
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
  patchLoggingSettingsRequest: AsyncRequestState
  editMode?: EditModeOptions
  isFlyoutOpen?: boolean
  handleEditClose?: () => void
}

type Props = WrappedComponentProps & ConsumerProps

type PatternLevel = {
  pattern: string
  level: LoggerLevel
}

type PatternNullLevel = {
  pattern: string
  level: null
}

type State = {
  editingLoggingSettingIsNew: boolean
  editingLoggingSetting: PatternLevel | null
}

const messages = defineMessages({
  actions: {
    id: `zk-logging-settings.actions`,
    defaultMessage: `Actions`,
  },
  editLoggingSetting: {
    id: `zk-logging-settings.edit-logging-setting`,
    defaultMessage: `Edit logging setting`,
  },
  deleteLoggingSetting: {
    id: `zk-logging-settings.delete-logging-setting`,
    defaultMessage: `Delete logging setting`,
  },
})

class ZkLoggingContent extends Component<Props, State> {
  state: State = {
    editingLoggingSettingIsNew: false,
    editingLoggingSetting: null,
  }

  static defaultProps: Partial<Props> = {
    tableHeader: true,
    editMode: 'view',
  }

  componentDidMount() {
    const { fetchLoggingSettings, editMode } = this.props
    fetchLoggingSettings()

    if (editMode === 'new') {
      this.editNewSetting()
    }
  }

  render() {
    const {
      intl: { formatMessage },
      loggingSettings,
      fetchLoggingSettingsRequest,
      patchLoggingSettingsRequest,
      tableHeader,
    } = this.props

    const { editingLoggingSetting, editingLoggingSettingIsNew } = this.state

    if (fetchLoggingSettingsRequest.error) {
      return <CuiAlert type='error'>{fetchLoggingSettingsRequest.error}</CuiAlert>
    }

    if (!loggingSettings) {
      return null
    }

    const patternLevels: PatternLevel[] = Object.keys(loggingSettings?.logging_levels).map(
      (logger) => ({
        pattern: logger,
        level: loggingSettings?.logging_levels[logger] as LoggerLevel,
      }),
    )

    const columns: Array<CuiTableColumn<PatternLevel>> = [
      {
        label: <FormattedMessage id='zk-logging-settings.logger-level' defaultMessage='Level' />,
        render: (logger) => <EuiBadge>{logger.level}</EuiBadge>,
        sortKey: `level`,
        width: `150px`,
      },
      {
        label: <FormattedMessage id='zk-logging-settings.logger-pattern' defaultMessage='Logger' />,
        render: (logger) => <EuiCode>{logger.pattern}</EuiCode>,
        sortKey: `pattern`,
      },
      {
        mobile: {
          label: formatMessage(messages.actions),
        },
        width: '120px',
        actions: true,
        render: (logger) => (
          <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                aria-label={formatMessage(messages.editLoggingSetting)}
                iconType='pencil'
                onClick={() => this.editExistingSetting(logger)}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType='trash'
                color='danger'
                aria-label={formatMessage(messages.deleteLoggingSetting)}
                onClick={() => this.saveLoggingSetting({ ...logger, level: null })}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      },
    ]

    if (editingLoggingSetting !== null) {
      return (
        <EditZkLoggingSettings
          isNew={editingLoggingSettingIsNew}
          loggingSetting={editingLoggingSetting}
          saveLoggingSettingRequest={patchLoggingSettingsRequest}
          onSave={this.saveLoggingSetting}
          onClose={this.closeEditModal}
        />
      )
    }

    return (
      <EuiFlyout size='s' ownFocus={true} onClose={this.closeEditModal}>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage
                id='zk-logging-settings-button.modal-title'
                defaultMessage='Logging settings'
              />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          {this.renderManageList({
            fetchLoggingSettingsRequest,
            tableHeader,
            patternLevels,
            columns,
          })}
        </EuiFlyoutBody>
      </EuiFlyout>
    )
  }

  renderManageList = ({ fetchLoggingSettingsRequest, tableHeader, patternLevels, columns }) => {
    if (fetchLoggingSettingsRequest.inProgress) {
      return <EuiLoadingContent lines={3} />
    }

    return (
      <Fragment>
        {this.renderTableHeader({ tableHeader })}
        {this.renderPatterLevels({ patternLevels })}
        {this.renderTable({ patternLevels, columns })}
      </Fragment>
    )
  }

  renderTable = ({ patternLevels, columns }) => (
    <CuiTable<PatternLevel>
      initialLoading={!patternLevels}
      rows={patternLevels}
      columns={columns}
      getRowId={(logger) => logger.pattern}
      emptyMessage={this.renderEmptyMessage()}
    />
  )

  renderTableHeader = ({ tableHeader }) => {
    if (tableHeader !== false) {
      return (
        <Fragment>
          <EuiTitle size='s'>
            <h4>
              <FormattedMessage id='zk-logging-settings.title' defaultMessage='Logging settings' />
            </h4>
          </EuiTitle>

          <EuiSpacer size='m' />
        </Fragment>
      )
    }

    return null
  }

  renderPatterLevels = ({ patternLevels }) => {
    if (patternLevels.length > 0) {
      return (
        <Fragment>
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem grow={false}>{this.renderCreateSettingButton()}</EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='m' />
        </Fragment>
      )
    }

    return null
  }

  renderEmptyMessage = () => (
    <EuiEmptyPrompt
      title={
        <h3>
          <FormattedMessage
            id='zk-logging-settings.no-custom-settings'
            defaultMessage='No custom logging settings'
          />
        </h3>
      }
      titleSize='xs'
      body={
        <Fragment>
          <EuiText>
            <p>
              <FormattedMessage
                id='zk-logging-settings.looks-like-no-items'
                defaultMessage="Looks like there aren't any custom logging settings."
              />
            </p>
          </EuiText>

          <EuiSpacer size='m' />

          {this.renderCreateSettingButton()}
        </Fragment>
      }
    />
  )

  renderCreateSettingButton = () => (
    <EuiButton onClick={this.editNewSetting}>
      <FormattedMessage
        id='zk-logging-settings.add-setting'
        defaultMessage='Create logging setting'
      />
    </EuiButton>
  )

  editNewSetting = () => {
    this.setState({
      editingLoggingSettingIsNew: true,
      editingLoggingSetting: {
        pattern: '',
        level: 'INFO',
      },
    })
  }

  editExistingSetting = (loggingSetting: PatternLevel) => {
    this.setState({
      editingLoggingSettingIsNew: false,
      editingLoggingSetting: loggingSetting,
    })
  }

  closeEditModal = () => {
    const { handleEditClose } = this.props

    if (handleEditClose) {
      handleEditClose()
    }

    this.setState({
      editingLoggingSettingIsNew: false,
      editingLoggingSetting: null,
    })
  }

  saveLoggingSetting = (loggingSetting: PatternLevel | PatternNullLevel) => {
    const { patchLoggingSettings } = this.props
    const { editingLoggingSetting, editingLoggingSettingIsNew } = this.state

    const loggingLevels: PatchLoggingLevels = {}

    if (editingLoggingSetting && !editingLoggingSettingIsNew) {
      // remove logging setting for old pattern
      loggingLevels[editingLoggingSetting.pattern] = null
    }

    // add or update logging setting for current pattern
    loggingLevels[loggingSetting.pattern] = loggingSetting.level

    patchLoggingSettings({ loggingLevels }).then(() => this.closeEditModal())
  }
}

export default injectIntl(withErrorBoundary(ZkLoggingContent))
