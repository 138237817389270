/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchAllocatorsSimpleQuery } from '@/actions/allocatorSearch'
import {
  deleteInstanceConfiguration,
  fetchInstanceConfiguration,
} from '@/actions/topology/instanceConfigurations'
import {
  deleteInstanceConfigurationRequest,
  getInstanceConfiguration,
  fetchInstanceConfigurationRequest,
  searchAllocatorsRequest,
  getAllocatorSearchResults,
} from '@/reducers'

import InstanceConfigurationView from './InstanceConfigurationView'

import type { ReduxState, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps: (state: ReduxState, ownProps: ConsumerProps) => StateProps = (
  state,
  {
    match: {
      params: { regionId, instanceId },
    },
  },
) => {
  const instanceConfiguration = getInstanceConfiguration(state, regionId, instanceId)!

  return {
    regionId,
    instanceId,
    instanceConfiguration,
    instanceConfigurationRequest: fetchInstanceConfigurationRequest(state, regionId, instanceId),
    deleteInstanceConfigurationRequest: deleteInstanceConfigurationRequest(
      state,
      regionId,
      instanceId,
    ),
    searchResults: getAllocatorSearchResults(state, regionId, `topology-filter-allocators`),
    searchAllocatorsRequest: searchAllocatorsRequest(state, regionId, `topology-filter-allocators`),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId, instanceId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  deleteInstanceConfiguration: () => dispatch(deleteInstanceConfiguration(regionId, instanceId)),
  fetchInstanceConfiguration: () =>
    dispatch(fetchInstanceConfiguration(regionId, instanceId, { showMaxZones: true })),
  searchAllocatorsSimpleQuery: (queryId, _regionId, payload) =>
    dispatch(searchAllocatorsSimpleQuery(queryId, _regionId, payload)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(InstanceConfigurationView)
