/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

import './formSection.scss'

interface Props {
  children?: ReactNode
  formLabel?: boolean
  help: ReactNode
}

export const CuiFormSection: FunctionComponent<Props> = ({
  formLabel = true,
  help,
  children,
  ...rest
}) => {
  let helpColumn

  if (help) {
    helpColumn = (
      <EuiFlexItem grow={false} className={formLabel ? `cuiFormSection-help` : undefined}>
        <EuiText size='s'>{help}</EuiText>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup gutterSize='xl' {...rest}>
      <EuiFlexItem grow={false} className='cuiFormSection-content'>
        {children}
      </EuiFlexItem>

      {helpColumn}
    </EuiFlexGroup>
  )
}
