/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '@/actions/asyncRequests'
import { FETCH_DEPLOYMENT_HEALTH } from '@/constants/actions'

import { getDeploymentHealthUrl } from '../../lib/api/v1/urls'

import type { GetDeploymentHealthUrlParams } from '../../lib/api/v1/urls'
import type { ThunkAction, ThunkDispatch } from '@/types'

export function fetchDeploymentHealth({
  deploymentId,
}: GetDeploymentHealthUrlParams): ThunkAction<Promise<void>> {
  return (dispatch: ThunkDispatch) => {
    const url = getDeploymentHealthUrl({
      deploymentId,
    })

    return dispatch(
      asyncRequest({
        type: FETCH_DEPLOYMENT_HEALTH,
        meta: {
          deploymentId,
        },
        method: `GET`,
        url,
        crumbs: [deploymentId],
      }),
    )
  }
}
