/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiTable, CuiLink } from '../../../cui'
import AllocatorTags from '../../Allocators/AllocatorTags'
import Status from '../../Status'
import prettySize from '../../../lib/prettySize'
import { hostAllocatorUrl } from '../../../lib/urlBuilder'

import type { CuiTableColumn } from '../../../cui'
import type { FunctionComponent } from 'react'
import type { AllocatorSearchResult } from '../../../types'

interface Props {
  allocators: AllocatorSearchResult[]
}

const MatchingAllocatorsTable: FunctionComponent<Props> = ({ allocators }) => {
  const columns: Array<CuiTableColumn<AllocatorSearchResult>> = [
    {
      mobile: {
        label: (
          <FormattedMessage
            id='topologyFilterAllocators.matchingAllocatorsTable.statusHeader'
            defaultMessage='Status'
          />
        ),
      },
      render: (allocator) => <Status status={allocator.healthy} />,
      sortKey: `healthy`,
      width: '40px',
    },
    {
      label: (
        <FormattedMessage
          id='topologyFilterAllocators.matchingAllocatorsTable.nameHeader'
          defaultMessage='Allocator'
        />
      ),
      render: (allocator) => (
        <CuiLink to={hostAllocatorUrl(allocator.regionId, allocator.id)}>{allocator.id}</CuiLink>
      ),
      sortKey: `id`,
    },
    {
      label: (
        <FormattedMessage
          id='topologyFilterAllocators.matchingAllocatorsTable.memoryHeader'
          defaultMessage='RAM'
        />
      ),
      render: (allocator) => prettySize(allocator.memoryCapacity.total),
      sortKey: `memoryCapacity.total`,
    },
    {
      label: (
        <FormattedMessage
          id='topologyFilterAllocators.matchingAllocatorsTable.memoryFreeHeader'
          defaultMessage='Free RAM'
        />
      ),
      render: (allocator) =>
        prettySize(allocator.memoryCapacity.total - allocator.memoryCapacity.used),
      sortKey: ({ memoryCapacity }) => memoryCapacity.total - memoryCapacity.used,
    },
    {
      label: (
        <FormattedMessage
          id='topologyFilterAllocators.matchingAllocatorsTable.tagsHeader'
          defaultMessage='Tags'
        />
      ),
      render: (allocator) => <AllocatorTags tags={allocator.tags} />,
    },
  ]

  return <CuiTable rows={allocators} columns={columns} getRowId={(allocator) => allocator.id} />
}

export default MatchingAllocatorsTable
