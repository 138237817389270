/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import TopologyPanelRow from '../TopologyElement/TopologyPanelRow'

import TemplateTopologyElement from './TemplateTopologyElement'
import DedicatedMasterThresholdField from './DedicatedMasterThresholdField'

import type { AllProps as TemplateTopologyElementProps } from './TemplateTopologyElement'

const TemplateDedicatedMaster: React.FunctionComponent<TemplateTopologyElementProps> = (props) => {
  const label = (
    <FormattedMessage
      id='templateTopologyElement-dedicatedMasterThresholdLabel'
      defaultMessage='Node threshold for dedicated masters'
    />
  )

  const extraFields = [
    () => (
      <TopologyPanelRow wideLabel={true} label={label} alignItems='center'>
        <DedicatedMasterThresholdField {...props} isReadOnly={!props.onResourceChange} />
      </TopologyPanelRow>
    ),
  ]

  return <TemplateTopologyElement {...props} extraFields={extraFields} maxInstanceCount={1} />
}

export default TemplateDedicatedMaster
