/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { cloneDeep } from 'lodash'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import { EuiComboBox, EuiSpacer, EuiText } from '@elastic/eui'

import { getNodeTypeForNodeRoles } from '@/lib/deploymentTemplates/deploymentTemplate'
import { getNodeRoles } from '@/lib/stackDeployments/selectors'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'
import type { AnyTopologyElement } from '../../../../../../types'
import type {
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '../../../../../../lib/api/v1/types'

export interface Props extends WrappedComponentProps {
  emptyPrompt?: ReactNode
  topologyElement: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  instanceConfigurations: InstanceConfiguration[]
  onChange: ((path: string[], value: any) => void) | undefined
}

type State = {
  isTitlePopoverOpen: boolean
  isDeprecatedPopoverOpen: boolean
}

class SelectInstanceConfiguration extends Component<Props, State> {
  state = {
    isTitlePopoverOpen: false,
    isDeprecatedPopoverOpen: false,
  }

  render() {
    const { instanceConfiguration, instanceConfigurations, onChange } = this.props

    const empty = { label: ``, value: `` }

    const options: Array<EuiComboBoxOptionOption<string>> = instanceConfigurations.map(
      ({ id, name, description = `` }) => ({
        value: id || name,
        label: name,
        description,
      }),
    )
    const selectedValue = options.filter((option) => option.value === instanceConfiguration.id)

    if (!selectedValue) {
      options.unshift(empty)
    }

    return (
      <EuiComboBox
        fullWidth={true}
        style={{ width: `500px` }}
        isClearable={false}
        singleSelection={{ asPlainText: true }}
        isDisabled={!onChange}
        onChange={([selectedOption]) => this.onSelectInstanceConfiguration(selectedOption.value)}
        selectedOptions={selectedValue}
        options={options}
        renderOption={(option) => this.renderOption(option)}
        rowHeight={75}
        data-test-id='instance-configuration-select'
      />
    )
  }

  renderOption(option: any) {
    return (
      <div>
        <EuiText data-test-id='instance-config-label' size='s'>
          <strong>{option.label}</strong>
        </EuiText>

        <EuiSpacer size='xs' />

        <EuiText
          style={{
            maxHeight: '3rem',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: 'block',
          }}
          size='s'
          color='subdued'
        >
          {option.description}
        </EuiText>
      </div>
    )
  }

  onSelectInstanceConfiguration = (id: string | undefined) => {
    const { onChange, instanceConfigurations } = this.props

    if (!onChange) {
      return // read-only
    }

    if (!id) {
      return // sanity
    }

    const instanceConfiguration = instanceConfigurations.find((option) => option.id === id)

    if (instanceConfiguration == null) {
      return // sanity
    }

    const topologyElement = cloneDeep(this.props.topologyElement)

    topologyElement.instance_configuration_id = id
    topologyElement.size = {
      value: instanceConfiguration.discrete_sizes.default_size,
      resource: instanceConfiguration.discrete_sizes.resource,
    }
    topologyElement.zone_count = instanceConfiguration.max_zones || 1

    const nodeType = getNodeTypeForNodeRoles({
      nodeRoles: getNodeRoles({ topologyElement }),
      instanceConfiguration,
    })

    if (nodeType) {
      ;(topologyElement as ElasticsearchClusterTopologyElement).node_type = nodeType
    }

    // make atomic change
    onChange([], topologyElement)
  }
}

export default SelectInstanceConfiguration
