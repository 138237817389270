/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeploymentDomainName,
  updateDeploymentDomainName,
} from '../../actions/deploymentDomainName'
import {
  fetchDeploymentDomainNameRequest,
  updateDeploymentDomainNameRequest,
  getDeploymentDomainName,
} from '../../reducers'

import EditDeploymentDomainName from './EditDeploymentDomainName'

import type { AsyncRequestState } from '../../types'

type StateProps = {
  deploymentDomainName: string | null
  fetchDeploymentDomainNameRequest: AsyncRequestState
  updateDeploymentDomainNameRequest: AsyncRequestState
}

type DispatchProps = {
  fetchDeploymentDomainName: () => void
  updateDeploymentDomainName: (params: { deploymentDomainName: string }) => Promise<any>
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state, { regionId }: ConsumerProps): StateProps => ({
  deploymentDomainName: getDeploymentDomainName(state, regionId),
  fetchDeploymentDomainNameRequest: fetchDeploymentDomainNameRequest(state, regionId),
  updateDeploymentDomainNameRequest: updateDeploymentDomainNameRequest(state, regionId),
})

const mapDispatchToProps = (dispatch, { regionId }: ConsumerProps): DispatchProps => ({
  fetchDeploymentDomainName: () => dispatch(fetchDeploymentDomainName({ regionId })),
  updateDeploymentDomainName: ({ deploymentDomainName }: { deploymentDomainName: string }) =>
    dispatch(updateDeploymentDomainName({ regionId, deploymentDomainName })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeploymentDomainName)
