/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { map, isEmpty } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiSelect, EuiLoadingSpinner, EuiFormRow } from '@elastic/eui'

import { replaceIn, updateIn } from '@/lib/immutability-helpers'
import {
  getFirstEsClusterFromGet,
  getRegionId,
  getSnapshotRepositoryIdFromGet,
  hasEnabledSnapshots,
} from '@/lib/stackDeployments/selectors'

import { CuiAlert, CuiPermissibleControl } from '../../../../cui'
import SpinButton from '../../../SpinButton'
import DangerButton from '../../../DangerButton'
import { createSnapshotRepositoryUrl } from '../../../../lib/urlBuilder'
import schedule from '../../../../lib/schedule'
import Permission from '../../../../lib/api/v1/permissions'

import type { RepositoryConfig } from '@/lib/api/v1/types'
import type { AsyncRequestState, RegionId, StackDeployment } from '@/types'

import './updateSnapshotRepository.scss'

export type StateProps = {
  fetchSnapshotRepositoriesRequest: AsyncRequestState
  snapshotRepositories?: { [repoId: string]: RepositoryConfig }
  setDeploymentResourceMetadataRequest: AsyncRequestState
}

export type DispatchProps = {
  fetchSnapshotRepositories: (regionId: RegionId) => void
  resetSetDeploymentResourceMetadata: () => void
  updateMetadata: ({
    deployment,
    updater,
  }: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta?: { [key: string]: any }
  }) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
}

export type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  snapshotRepositoryId: string
}

const setSnapshotRepositoryType = `set-snapshot-repository`
const disableSnapshotRepositoryType = `disable-snapshot-repository`

class UpdateSnapshotRepository extends Component<Props, State> {
  state: State = {
    snapshotRepositoryId:
      getSnapshotRepositoryIdFromGet({ deployment: this.props.deployment }) || ``,
  }

  componentWillUnmount() {
    const { resetSetDeploymentResourceMetadata } = this.props
    resetSetDeploymentResourceMetadata()
  }

  render() {
    const {
      deployment,
      snapshotRepositories,
      setDeploymentResourceMetadataRequest,
      fetchSnapshotRepositoriesRequest,
    } = this.props

    const setSnapshotRepositoryRequest =
      setDeploymentResourceMetadataRequest.meta.type === setSnapshotRepositoryType
        ? setDeploymentResourceMetadataRequest
        : null

    const disableSnapshotsForClusterRequest =
      setDeploymentResourceMetadataRequest.meta.type === disableSnapshotRepositoryType
        ? setDeploymentResourceMetadataRequest
        : null

    const savedSnapshotRepositoryId = getSnapshotRepositoryIdFromGet({
      deployment: this.props.deployment,
    })

    const { snapshotRepositoryId } = this.state

    const enabledSnapshots = this.hasEnabledSnapshots()

    if (fetchSnapshotRepositoriesRequest.error) {
      return (
        <CuiAlert data-test-id='fetch-snapshot-error' type='error'>
          {fetchSnapshotRepositoriesRequest.error}
        </CuiAlert>
      )
    }

    if (!snapshotRepositories) {
      return <EuiLoadingSpinner />
    }

    const noSnapshotRepositoriesDefined = isEmpty(snapshotRepositories)

    if (noSnapshotRepositoriesDefined) {
      return (
        <Fragment>
          <CuiAlert iconType='faceSad' type='warning'>
            <FormattedMessage
              id='cluster-manage-update-snapshot-repository.no-repos'
              defaultMessage='You do not have any snapshot repositories set up. {addLink} to enable snapshots for your Elasticsearch clusters.'
              values={{
                addLink: (
                  <CuiPermissibleControl permissions={Permission.setSnapshotRepository}>
                    <Link to={createSnapshotRepositoryUrl(getRegionId({ deployment })!)}>
                      <FormattedMessage
                        id='cluster-manage-update-snapshot-repository.no-repos-link'
                        defaultMessage='Add a repository'
                      />
                    </Link>
                  </CuiPermissibleControl>
                ),
              }}
            />
          </CuiAlert>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <EuiFormRow
          label={
            <FormattedMessage
              id='cluster-manage-update-snapshot-repository.title'
              defaultMessage='Snapshot repository'
            />
          }
        >
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem className='updateSnapshotRepository-options'>
              <EuiSelect
                data-test-id='update-snapshot-repo-for-deployment'
                value={snapshotRepositoryId}
                onChange={(e) => this.setState({ snapshotRepositoryId: e.target.value })}
                options={[
                  ...(snapshotRepositoryId ? [] : [{ text: `` }]),
                  ...map(snapshotRepositories, (repo) => ({
                    value: repo.repository_name,
                    text: repo.config.type
                      ? `${repo.repository_name} (${repo.config.type})`
                      : repo.repository_name,
                  })),
                ]}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CuiPermissibleControl permissions={Permission.setDeploymentResourceRawMetadata}>
                <SpinButton
                  data-test-id='save-snapshot-repo-button'
                  color='primary'
                  onClick={() => this.setSnapshotRepository()}
                  disabled={
                    !snapshotRepositoryId ||
                    (enabledSnapshots && snapshotRepositoryId === savedSnapshotRepositoryId)
                  }
                  spin={setSnapshotRepositoryRequest?.inProgress}
                >
                  {enabledSnapshots ? (
                    <FormattedMessage
                      data-test-id='save-repo'
                      id='cluster-manage-update-snapshot-repository.save-repository-enabled'
                      defaultMessage='Save repository'
                    />
                  ) : (
                    <FormattedMessage
                      data-test-id='save-and-enable'
                      id='cluster-manage-update-snapshot-repository.save-repository-and-enable'
                      defaultMessage='Save repository and enable snapshots'
                    />
                  )}
                </SpinButton>
              </CuiPermissibleControl>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        {setSnapshotRepositoryRequest?.error && (
          <CuiAlert type='error' className='updateSnapshotRepository-error'>
            {setSnapshotRepositoryRequest.error}
          </CuiAlert>
        )}

        {setSnapshotRepositoryRequest?.isDone && (
          <CuiAlert type='info' className='updateSnapshotRepository-success'>
            <FormattedMessage
              id='cluster-manage-update-snapshot-repository.success-message'
              defaultMessage='This Elasticsearch cluster will now use the chosen snapshot repository.'
            />
          </CuiAlert>
        )}

        {enabledSnapshots && (
          <div className='disableSnapshots'>
            <CuiPermissibleControl permissions={Permission.setDeploymentResourceRawMetadata}>
              <DangerButton
                data-test-id='disable-snapshots-for-deployment'
                className='disableSnapshots-btn'
                onConfirm={() => this.disableSnapshotsForCluster()}
                isBusy={disableSnapshotsForClusterRequest?.inProgress}
                modal={{
                  title: (
                    <FormattedMessage
                      id='cluster-manage-disable-snapshot-repository.confirm-disable-snapshots'
                      defaultMessage='Confirm to disable snapshots'
                    />
                  ),
                  body: (
                    <FormattedMessage
                      id='cluster-manage-disable-snapshot-repository.explanation'
                      defaultMessage='Disabling snapshots can lead to data loss. Without a backup of your data, this Elasticsearch cluster will not be able to recover from failures.'
                    />
                  ),
                }}
              >
                <FormattedMessage
                  id='cluster-manage-disable-snapshot-repository.disable-snapshots'
                  defaultMessage='Disable snapshots'
                />
              </DangerButton>
            </CuiPermissibleControl>
          </div>
        )}

        {disableSnapshotsForClusterRequest?.error && (
          <CuiAlert type='error' className='updateSnapshotRepository-error'>
            {disableSnapshotsForClusterRequest.error}
          </CuiAlert>
        )}

        {disableSnapshotsForClusterRequest?.isDone && (
          <CuiAlert type='info' className='updateSnapshotRepository-success disabledSnapshots'>
            <FormattedMessage
              id='cluster-manage-disable-snapshot-repository.success-message'
              defaultMessage='This Elasticsearch cluster now has snapshots disabled.'
            />
          </CuiAlert>
        )}
      </Fragment>
    )
  }

  hasEnabledSnapshots() {
    const { deployment } = this.props
    const es = getFirstEsClusterFromGet({ deployment })!

    return hasEnabledSnapshots({ resource: es })
  }

  disableSnapshotsForCluster() {
    const { updateMetadata, deployment } = this.props

    updateMetadata({
      deployment,
      requestMeta: { type: disableSnapshotRepositoryType },
      updater: (metadata) => replaceIn(metadata, ['snapshot', 'enabled'], false),
    })
  }

  setSnapshotRepository() {
    const { snapshotRepositoryId } = this.state
    const { updateMetadata, deployment } = this.props

    updateMetadata({
      deployment,
      requestMeta: { type: setSnapshotRepositoryType },
      updater: (metadata) =>
        updateIn(metadata, ['snapshot'], (snapshot) => ({
          repository: {
            config: {
              snapshot_config_type: `reference`,
              repository_id: snapshotRepositoryId,
            },
          },
          enabled: true,
          slm: snapshot.slm,
        })),
    })
  }
}

export default schedule(
  UpdateSnapshotRepository,
  ({ deployment, fetchSnapshotRepositories }) =>
    fetchSnapshotRepositories(getRegionId({ deployment })!),
  [[`deployment`]],
)
