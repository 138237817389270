/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedNumber } from 'react-intl'

import type { FunctionComponent, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  value: number
  unit?: 'centicents' | 'cents' | 'none'
  dp?: number
  currency?: string
}

const CuiPrice: FunctionComponent<Props> = function ({
  value,
  unit = `centicents`,
  dp,
  currency = 'USD',
  ...rest
}) {
  const multiplier = {
    centicents: 10000,
    cents: 100,
    none: 1,
  }[unit]

  const rawValue = value / multiplier

  const displayValue = (
    <FormattedNumber
      style='currency'
      currency={currency}
      value={rawValue}
      minimumFractionDigits={dp}
    />
  )

  return <span {...rest}>{displayValue}</span>
}

export default CuiPrice
