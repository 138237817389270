/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  CONVERT_ORGANIZATION_TO_INVOICING,
  FETCH_SAAS_ORGANIZATION,
  CONVERT_RESELLER_TO_DIRECT,
} from '@/constants/actions'
import { getSaasOrganizationUrl, setPaymentMethodUrl, setToDirectUrl } from '@/lib/api/v1/urls'
import { fetchOrganizationBillingDetails } from '@/actions/billing'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'

import type { SaasOrganizationResponse, SetPaymentRequest } from '@/lib/api/v1/types'
import type { GetSaasOrganizationUrlParams } from '@/lib/api/v1/urls'
import type { ThunkAction } from '@/types/redux'

export function fetchSaasOrganization(
  params: GetSaasOrganizationUrlParams,
): ThunkAction<Promise<any>> {
  const url = getSaasOrganizationUrl(params)
  const { organizationId } = params

  return asyncRequest<SaasOrganizationResponse>({
    type: FETCH_SAAS_ORGANIZATION,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export function convertOrganizationToInvoicing(
  email: string,
  organizationId: string,
): ThunkAction<void> {
  const url = setPaymentMethodUrl({ organizationId })

  const payload: SetPaymentRequest = {
    payment_type: `po`,
    email,
    reason: 'Convert organization to invoicing via UI',
  }

  return asyncRequest<void>({
    method: `PUT`,
    type: CONVERT_ORGANIZATION_TO_INVOICING,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
    payload,
  })
}

export const resetConvertOrganizationToInvoicingRequest = (...crumbs: string[]) =>
  resetAsyncRequest(CONVERT_ORGANIZATION_TO_INVOICING, crumbs)

export function convertResellerToDirect(organizationId: string): ThunkAction<void> {
  const url = setToDirectUrl({ organizationId })

  return (dispatch) =>
    dispatch(
      asyncRequest<void>({
        method: `POST`,
        type: CONVERT_RESELLER_TO_DIRECT,
        url,
        meta: { organizationId },
        crumbs: [organizationId],
      }),
    ).then(() => dispatch(fetchOrganizationBillingDetails({ organizationId })))
}
export const resetConvertResellerToDirectRequest = (...crumbs: string[]) =>
  resetAsyncRequest(CONVERT_RESELLER_TO_DIRECT, crumbs)
