/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  addLicenseRequest,
  fetchLicenseRequest,
  getLicense,
  removeLicenseRequest,
} from '../../../reducers'
import { fetchLicense } from '../../../actions/licenses'

import License from './License'

import type { AsyncRequestState, License as LicenseType } from '../../../types'

type StateProps = {
  license?: LicenseType | null
  fetchLicenseRequest: AsyncRequestState
  addLicenseRequest: AsyncRequestState
  removeLicenseRequest: AsyncRequestState
}

type DispatchProps = {
  fetchLicense: (regionId: string) => void
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state, { regionId }: ConsumerProps): StateProps => ({
  fetchLicenseRequest: fetchLicenseRequest(state, regionId),
  addLicenseRequest: addLicenseRequest(state, regionId),
  removeLicenseRequest: removeLicenseRequest(state, regionId),
  license: getLicense(state, regionId),
})

const mapDispatchToProps: DispatchProps = { fetchLicense }

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(License)
