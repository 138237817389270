/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import ZkLoggingSettingsButton from '../../ZkLoggingSettingsButton'
import {
  fetchConstructorLoggingSettings,
  patchConstructorLoggingSettings,
} from '../../../actions/constructors'
import {
  getConstructorLoggingSettings,
  fetchConstructorLoggingSettingsRequest,
  patchConstructorLoggingSettingsRequest,
} from '../../../reducers'

import type { AsyncRequestState, LoggerLevel } from '../../../types'
import type { LoggingSettings } from '../../../lib/api/v1/types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type StateProps = {
  loggingSettings: LoggingSettings | null
  fetchLoggingSettingsRequest: AsyncRequestState
  patchLoggingSettingsRequest: AsyncRequestState
}

type DispatchProps = {
  fetchLoggingSettings: () => void
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
}

type ConsumerProps = {
  regionId: string
  constructorId: string
}

const mapStateToProps = (state, { regionId, constructorId }: ConsumerProps): StateProps => ({
  loggingSettings: getConstructorLoggingSettings(state, regionId, constructorId),
  fetchLoggingSettingsRequest: fetchConstructorLoggingSettingsRequest(
    state,
    regionId,
    constructorId,
  ),
  patchLoggingSettingsRequest: patchConstructorLoggingSettingsRequest(
    state,
    regionId,
    constructorId,
  ),
})

const mapDispatchToProps = (
  dispatch,
  { regionId, constructorId }: ConsumerProps,
): DispatchProps => ({
  fetchLoggingSettings: () =>
    dispatch(fetchConstructorLoggingSettings({ regionId, constructorId })),
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) =>
    dispatch(patchConstructorLoggingSettings({ regionId, constructorId, ...settings })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ZkLoggingSettingsButton)
