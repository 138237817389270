/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiHorizontalRule, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { getDataTopologies } from '@/lib/deploymentTemplates/getTopologiesFromTemplate'

import ILMErrors from '../components/Editor/ILMErrors'
import IndexCurationSettings from '../../IndexCuration/IndexCurationSettings'
import { couldHaveCuration, getCurationConfigurationOptions } from '../../../../lib/curation'
import { validateILM } from '../../../../lib/ilm'
import IndexLifecycleManagementSettings from '../../IndexLifecycleManagement/IndexLifecycleManagementSettings'

import type {
  DeploymentCreateRequest,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '../../../../lib/api/v1/types'
import type { StepDefinition, StepOwnProps, StepProps } from './deploymentTemplateWizardTypes'
import type { FunctionComponent } from 'react'

const messages = defineMessages({
  title: {
    id: `configure-template-index-curation-step.step-title`,
    defaultMessage: `Manage indices`,
  },
})

const ConfigureTemplateIndexCurationStep: FunctionComponent<StepProps> = ({
  pristine,
  instanceConfigurations,
  template,
  updateDeploymentTemplate,
}) => {
  const deploymentTemplate = template.deployment_template

  const errors = validateILM(deploymentTemplate)
  const curationConfigurationOptions = getCurationConfigurationOptions(
    getCurationSettings({
      instanceConfigurations,
      deploymentTemplate,
    }),
  )

  return (
    <div data-test-id='deploymentTemplateWizard-indexManagementStep'>
      <EuiTitle>
        <h3>
          <FormattedMessage id='index-management.title' defaultMessage='Index management' />
        </h3>
      </EuiTitle>

      <EuiSpacer />

      {shouldHaveIndexCuration(instanceConfigurations, deploymentTemplate) ? (
        <div data-test-id='deploymentTemplateWizard-indexManagementSetup'>
          <Fragment>
            <EuiText>
              <p>
                <FormattedMessage
                  id='index-management.description'
                  defaultMessage='Choose which methods of index management are available when creating deployments.'
                />
              </p>
            </EuiText>

            <EuiSpacer />

            <IndexLifecycleManagementSettings
              template={template}
              updateDeploymentTemplate={updateDeploymentTemplate}
            />
            {pristine || <ILMErrors errors={errors} />}
          </Fragment>

          <EuiHorizontalRule />

          <IndexCurationSettings
            template={template}
            updateDeploymentTemplate={updateDeploymentTemplate}
            curationConfigurationOptions={curationConfigurationOptions}
          />
        </div>
      ) : (
        <EuiCallOut
          iconType='alert'
          title={
            <FormattedMessage
              id='index-management.unavailable-callout.title'
              defaultMessage='Not enough Elasticsearch data instances'
            />
          }
        >
          <p>
            <FormattedMessage
              id='index-management.unavailable-callout.description'
              defaultMessage='Managing indices requires at least two Elasticsearch data nodes in the template.'
            />
          </p>
        </EuiCallOut>
      )}
    </div>
  )
}

function getCurationSettings({
  instanceConfigurations,
  deploymentTemplate,
}: {
  instanceConfigurations: InstanceConfiguration[]
  deploymentTemplate: DeploymentCreateRequest
}): {
  instanceConfigurations: InstanceConfiguration[]
  dataNodeConfigurations: ElasticsearchClusterTopologyElement[]
} {
  const dataInstanceConfigurations = instanceConfigurations.filter((config) =>
    (config.node_types || []).some((t) => t === 'data'),
  )

  const dataNodeConfigurations = getDataTopologies({ deployment: deploymentTemplate })

  return {
    instanceConfigurations: dataInstanceConfigurations,
    dataNodeConfigurations,
  }
}

function shouldHaveIndexCuration(
  instanceConfigurations: InstanceConfiguration[],
  deploymentTemplate: DeploymentCreateRequest,
): boolean {
  const curationSettings = getCurationSettings({
    instanceConfigurations,
    deploymentTemplate,
  })

  return couldHaveCuration(curationSettings)
}

function validateIndexManagementStep({ template, instanceConfigurations }: StepOwnProps) {
  if (shouldHaveIndexCuration(instanceConfigurations, template.deployment_template)) {
    return validateILM(template.deployment_template)
  }

  return []
}

export const indexManagementStepDefinition: StepDefinition = {
  title: messages.title,
  ConfigureStep: ConfigureTemplateIndexCurationStep,
  validateStep: validateIndexManagementStep,
}
