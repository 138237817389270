/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import { FETCH_API_BASE_URL, UPDATE_API_BASE_URL } from '../../constants/actions'
import { getApiBaseUrl } from '../../reducers/apiBaseUrl'
import { getApiBaseUrlUrl, setApiBaseUrlUrl } from '../../lib/api/v1/urls'

import type { ReduxState } from '../../types'
import type { ApiBaseUrlData } from '../../lib/api/v1/types'

export function fetchApiBaseUrl() {
  return asyncRequest({
    type: FETCH_API_BASE_URL,
    url: getApiBaseUrlUrl(),
  })
}

export function fetchApiBaseUrlIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(fetchApiBaseUrl())
    }

    return Promise.resolve()
  }
}

function shouldFetch({ apiBaseUrl }: ReduxState) {
  return getApiBaseUrl(apiBaseUrl) === undefined
}

export function updateApiBaseUrl({ apiBaseUrl }: { apiBaseUrl: string }) {
  const payload: ApiBaseUrlData = {
    value: apiBaseUrl,
  }

  return asyncRequest({
    type: UPDATE_API_BASE_URL,
    method: `PUT`,
    url: setApiBaseUrlUrl(),
    payload,
  })
}
