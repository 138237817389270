/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiButtonEmpty, EuiSpacer, EuiFlexGroup } from '@elastic/eui'

import cloudIcon from '@/files/rate_card_icon.svg'
import arrowIcon from '@/files/icon-long-arrow-right-24-sky-blue.svg'

import type { FlipCardProps } from '../FlipCard/types'
import type { FunctionComponent } from 'react'

import './styles.scss'

const FrontSide: FunctionComponent<FlipCardProps> = ({ flipCard }: FlipCardProps) => (
  <EuiFlexGroup justifyContent='center' alignItems='center' direction='column' gutterSize='none'>
    <img src={cloudIcon} />

    <EuiSpacer size='xxl' />

    <EuiText size='xs' className='frontCardTitle'>
      <h2>
        <FormattedMessage
          id='usage-flip-card.front.title'
          defaultMessage='View current usage rates'
        />
      </h2>
    </EuiText>

    <EuiSpacer size='m' />

    <EuiButtonEmpty
      className='flip-button'
      onClick={flipCard}
      iconSide='right'
      iconType={arrowIcon}
      data-test-id='usage-rates-card.show-button'
    >
      <FormattedMessage id='usage-flip-card.front.show' defaultMessage='Show' />
    </EuiButtonEmpty>
  </EuiFlexGroup>
)

export default FrontSide
