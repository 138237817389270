/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchDeployments } from '@/actions/stackDeployments'
import { searchStackDeploymentsRequest, getStackDeploymentsFromSearch } from '@/reducers'

import DeleteSnapshotRepositoryModalBody from './DeleteSnapshotRepositoryModalBody'

const queryId = `snapshot-repository-search`

const mapStateToProps = (state) => ({
  searchResults: getStackDeploymentsFromSearch(state, queryId),
  searchResultsRequest: searchStackDeploymentsRequest(state, queryId),
})

function fetchDeploymentsUsingThisSnapshotRepo(repoId) {
  const query = {
    query: {
      nested: {
        path: `resources.elasticsearch`,
        query: {
          match: {
            'resources.elasticsearch.info.settings.snapshot.repository.reference.repository_name': {
              query: repoId,
            },
          },
        },
      },
    },
    size: 150,
  }

  return searchDeployments({ queryId, query })
}

export default connect(mapStateToProps, {
  fetchDeploymentsUsingThisSnapshotRepo,
})(DeleteSnapshotRepositoryModalBody)
