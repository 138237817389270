/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { cloneDeep, find, get, map, size } from 'lodash'

import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert, CuiPermissibleControl } from '../../../cui'
import ApiRequestExample from '../../ApiRequestExample'
import Header from '../../Header'
import SpinButton from '../../SpinButton'
import { snapshotRepositoriesUrl } from '../../../lib/urlBuilder'
import { setSnapshotRepositoryUrl } from '../../../lib/api/v1/urls'
import {
  createSnapshotRepositoryCrumbs,
  editSnapshotRepositoryCrumbs,
} from '../../../lib/crumbBuilder'
import Permission from '../../../lib/api/v1/permissions'

import AdvancedEditor from './AdvancedEditor'
import SimpleStorageEditor from './SimpleStorageEditor'

import type {
  AsyncRequestState,
  RegionId,
  SnapshotRepository,
  SnapshotRepositoryConfig,
  WorkingSnapshotRepository,
} from '../../../types'
import type { IntlShape, WrappedComponentProps } from 'react-intl'

import './repositoryEditor.scss'

interface RepositoryTypeDefinition {
  isDefault?: boolean
  type: string
  name: string
  editor: typeof SimpleStorageEditor | typeof AdvancedEditor
}

export interface Errors {
  region?: RegionId
  bucket?: string
  access_key?: string
  secret_key?: string
  repository_name?: string
  advanced?: string
}

export interface OnChangeEventPropType {
  customEditorState?: string
  config: SnapshotRepositoryConfig
}

interface Props extends WrappedComponentProps {
  regionId: RegionId
  repository: WorkingSnapshotRepository
  upsertSnapshotRepository: (regionId: RegionId, repositoryName: string, config: any) => void
  upsertSnapshotRepositoryRequest: AsyncRequestState
  errors: Errors
  editMode: boolean
}

interface State {
  repository: WorkingSnapshotRepository
  repositoryType: string
  customEditorState: any
  errors: Errors
}

const messages = defineMessages({
  requireField: {
    id: `snapshot-repository-management.required-field`,
    defaultMessage: `This field is required.`,
  },
})

const advancedRepositoryType = `__advanced__`
const knownRepositoryTypes: RepositoryTypeDefinition[] = [
  {
    isDefault: true,
    type: `s3`,
    name: `S3`,
    editor: SimpleStorageEditor,
  },
  {
    type: advancedRepositoryType,
    name: `Advanced`,
    editor: AdvancedEditor,
  },
]

const defaultRepository = find(knownRepositoryTypes, { isDefault: true })!
const defaultRepositoryType = defaultRepository.type
const knownRepositoryTypeIds = map(knownRepositoryTypes, `type`)

class RepositoryEditor extends Component<Props, State> {
  state: State = {
    repository: cloneDeep(this.props.repository),
    repositoryType: getInitialRepositoryType(this.props.repository),
    customEditorState: undefined,
    errors: {},
  }

  render() {
    const {
      repository: { repository_name },
      editMode,
      regionId,
      upsertSnapshotRepositoryRequest,
    } = this.props

    const { repository, repositoryType, customEditorState, errors } = this.state

    const repoType = find(knownRepositoryTypes, { type: repositoryType })

    if (repoType == null) {
      throw new Error(`Couldn't find the right repository type`)
    }

    const SpecializedEditor = repoType.editor

    return (
      <Fragment>
        <Header
          name={
            editMode ? (
              <FormattedMessage
                id='snapshot-repository-management.edit-title'
                defaultMessage='Edit Snapshot Repository'
              />
            ) : (
              <FormattedMessage
                id='snapshot-repository-management.create-title'
                defaultMessage='New Snapshot Repository'
              />
            )
          }
          breadcrumbs={
            editMode
              ? editSnapshotRepositoryCrumbs({ regionId, repositoryId: repository_name! })
              : createSnapshotRepositoryCrumbs({ regionId })
          }
        />

        <EuiFormRow
          label={
            <FormattedMessage
              id='snapshot-repository-management.snapshots'
              defaultMessage='Repository Name'
            />
          }
        >
          {editMode ? (
            <span>{repository.repository_name}</span>
          ) : (
            <EuiFormRow
              error={errors.repository_name ? errors.repository_name : ''}
              isInvalid={!!errors.repository_name}
            >
              <EuiFieldText
                name='repository_name'
                value={repository.repository_name}
                onChange={(e) => this.onRepositoryNameChange(e.target.value)}
              />
            </EuiFormRow>
          )}
        </EuiFormRow>

        <EuiSpacer size='m' />

        <EuiFormRow
          label={
            <FormattedMessage
              id='snapshot-repository-management.repository-type'
              defaultMessage='Repository Type'
            />
          }
        >
          <EuiSelect
            name='repository_type'
            className='repositoryEditor-repositoryTypes'
            value={repositoryType}
            onChange={(e) => this.setState({ repositoryType: e.target.value })}
            options={knownRepositoryTypes.map(({ type, name }) => ({
              value: type,
              text: name,
            }))}
          />
        </EuiFormRow>

        <div className='repositoryEditor-repository'>
          <SpecializedEditor
            config={repository.config}
            customEditorState={customEditorState}
            errors={errors}
            onChange={(state) => this.onRepositoryConfigChange(state)}
          />
        </div>

        <EuiSpacer size='m' />

        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <CuiPermissibleControl permissions={Permission.setSnapshotRepository}>
              <SpinButton
                data-test-id='repositoryEditor-saveBtn'
                color='primary'
                onClick={this.onSaveClick}
                spin={upsertSnapshotRepositoryRequest.inProgress}
              >
                <FormattedMessage id='snapshot-repository-management.save' defaultMessage='Save' />
              </SpinButton>
            </CuiPermissibleControl>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <Link to={snapshotRepositoriesUrl(regionId)} className='repositoryEditor-cancel'>
              <FormattedMessage
                id='snapshot-repository-management.cancel'
                defaultMessage='Cancel'
              />
            </Link>
          </EuiFlexItem>
        </EuiFlexGroup>

        <div>
          <ApiRequestExample
            method='PUT'
            endpoint={setSnapshotRepositoryUrl({
              repositoryName: this.state.repository.repository_name || '',
              regionId,
            })}
            body={this.state}
          />
        </div>

        {upsertSnapshotRepositoryRequest.error && (
          <CuiAlert className='repositoryEditor-error' type='error'>
            {upsertSnapshotRepositoryRequest.error}
          </CuiAlert>
        )}
      </Fragment>
    )
  }

  onRepositoryNameChange(name: string) {
    const repository = {
      ...this.state.repository,
      repository_name: name,
    }
    this.setState({ repository })
  }

  onRepositoryConfigChange({ config, customEditorState }: OnChangeEventPropType) {
    const repository = {
      ...this.state.repository,
      config,
    }
    this.setState({ repository, customEditorState })
  }

  onSaveClick = () => {
    const { intl, upsertSnapshotRepository, regionId } = this.props
    const errors = makeErrors(intl, this.state.repositoryType, this.state.repository)
    const { repository } = this.state

    if (size(errors) === 0) {
      const finalRepository = repository as SnapshotRepository
      upsertSnapshotRepository(regionId, finalRepository.repository_name, finalRepository.config)
    } else {
      this.setState({ errors })
    }
  }
}

export default injectIntl(RepositoryEditor)

function getInitialRepositoryType(repository) {
  const typePath = [`config`, `type`]
  const type = get(repository, typePath, defaultRepositoryType)

  return knownRepositoryTypeIds.includes(type) ? type : advancedRepositoryType
}

function makeErrors(
  intl: IntlShape,
  repositoryType: string,
  repository: WorkingSnapshotRepository,
) {
  const required = intl.formatMessage(messages.requireField)

  const errors: Errors = {}

  if (size(repository.repository_name) === 0) {
    errors.repository_name = required
  }

  switch (repositoryType) {
    case `s3`:
      if (size(get(repository, [`config`, `settings`, `region`])) === 0) {
        errors.region = required
      }

      if (size(get(repository, [`config`, `settings`, `bucket`])) === 0) {
        errors.bucket = required
      }

      if (size(get(repository, [`config`, `settings`, `access_key`])) === 0) {
        errors.access_key = required
      }

      if (size(get(repository, [`config`, `settings`, `secret_key`])) === 0) {
        errors.secret_key = required
      }

      break

    case advancedRepositoryType:
      if (size(repository) === 0) {
        errors.advanced = required
      }

      break

    default:
      break
  }

  return errors
}
