/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import loadable from '@loadable/component'

import { EuiButton, EuiButtonEmpty } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

import './creditCardModalButton.scss'

// We use lazy loading here to prevent the Edit flow in AC from attempting to load recurly
const CreditCardModalButtonLoaded = loadable(() => import(`./CreditCardModalButton`))

const CreditCardModalButton: FunctionComponent<Props> = (props: Props) => {
  const { children, type, buttonComponent } = props

  const Button = type === `full` ? EuiButton : EuiButtonEmpty
  const defaultButton = (
    <Button
      data-test-id='open-credit-card-modal-loading-button'
      className='credit-card-modal-button'
      isDisabled={true}
    >
      {children}
    </Button>
  )
  const button = React.isValidElement(buttonComponent) ? buttonComponent : defaultButton

  return <CreditCardModalButtonLoaded {...props} fallback={button} />
}

export default CreditCardModalButton
