/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  findResourceEolStatusByVersion,
  requiresPlatformEndOfLifeVersionWarning,
} from '@/lib/eolStatus'

import EndOfLifeLink from '../EndOfLifeLink'
import messages from '../messages'

import EndOfLifeMessage from './EndOfLifeMessage'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const EOLVersionWarningMessage: FunctionComponent<Props> = ({
  version,
  resourceEolStatusList,
  fetchEolStatusRequest,
  showLink,
}) => {
  const eolLink = <EndOfLifeLink showLink={showLink} />

  if (!resourceEolStatusList) {
    const showFallbackMessage =
      fetchEolStatusRequest.isDone && requiresPlatformEndOfLifeVersionWarning(version)

    // Static check to fall back on in air-gapped environments, or when the request fails
    if (showFallbackMessage) {
      return (
        <FormattedMessage
          {...messages.platformVersionReachedEol}
          data-test-id='platform-version-reached-eol'
          values={{
            version,
            eolLink,
          }}
        />
      )
    }

    return null
  }

  const resourceEolStatus = findResourceEolStatusByVersion(resourceEolStatusList, version)

  if (!resourceEolStatus) {
    return null
  }

  return <EndOfLifeMessage resourceEolStatus={resourceEolStatus} showLink={showLink} />
}

export default EOLVersionWarningMessage
