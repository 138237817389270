/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import DangerButton from '../DangerButton'

import RadioPretendingItIsButton from './RadioPretendingItIsButton'

import type { Props as DangerButtonProps } from '../DangerButton'
import type { FunctionComponent } from 'react'

type Props = Omit<DangerButtonProps, 'intl'> & {
  id: string
  checked: boolean
}

const DangerRadioButton: FunctionComponent<Props> = ({ id, checked, disabled, spin, ...props }) => (
  <DangerButton
    {...props}
    disabled={spin || disabled}
    buttonType={(buttonProps) => (
      <RadioPretendingItIsButton id={id} checked={checked} {...buttonProps} />
    )}
  />
)

export default DangerRadioButton
