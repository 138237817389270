/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, set } from 'lodash'

import { doNodeAttributesExist, getInstancesWithoutNodeAttributes } from '../ilm'
import { lt, satisfies } from '../semver'
import { getIlmMigrationLsKey } from '../../constants/localStorageKeys'

import {
  getUpsertVersion,
  getEsPlanFromGet,
  getVersion,
  isSystemOwned,
  getEsNodeConfigurationsFromGet,
  getFirstEsCluster,
  getEsClusterConfigurationsFromDeploymentRequest,
  getEsPlan,
} from './selectors'

import type {
  ClusterCurationSpec,
  DeploymentTemplateInfoV2,
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  ElasticsearchClusterTopologyElement,
  ElasticsearchCuration,
} from '../api/v1/types'
import type { StackDeployment, PlainHashMap } from '../../types'

export function getCurationIndexPatterns({
  deployment,
}: {
  deployment: DeploymentCreateRequest
}): ClusterCurationSpec[] {
  return getFirstEsCluster({ deployment })?.settings?.curation?.specs || []
}

export function getCuration({
  deployment,
}: {
  deployment: DeploymentCreateRequest
}): ElasticsearchCuration | undefined {
  return getEsPlan({ deployment })?.elasticsearch.curation
}

function isIndexCurationTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate: DeploymentTemplateInfoV2
}): boolean {
  const curation = getCuration({ deployment: deploymentTemplate.deployment_template })
  return Boolean(curation?.from_instance_configuration_id && curation.to_instance_configuration_id)
}

const doNodeAttributesExistOnDeployment = (
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest,
): boolean => {
  const dataConfigurations = getEsClusterConfigurationsFromDeploymentRequest({ deployment })
  return dataConfigurations.some((node) => {
    if (!node.elasticsearch) {
      return false
    }

    return !isEmpty(node.elasticsearch.node_attributes)
  })
}

export const hasIlmNodeAttributes = (
  topologyElements: ElasticsearchClusterTopologyElement[],
): boolean => topologyElements.every(doNodeAttributesExistOnInstance)

function doNodeAttributesExistOnInstance(
  topologyElement: ElasticsearchClusterTopologyElement,
): boolean {
  return !isEmpty(topologyElement.elasticsearch?.node_attributes)
}

export function ensureCorrectIndexManagementSettings({
  deployment,
  deploymentTemplate,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentTemplate: DeploymentTemplateInfoV2
}): void {
  const esCluster = getFirstEsCluster({ deployment })

  if (!esCluster) {
    return
  }

  const ilmNodeAttributesExist = doNodeAttributesExist(deploymentTemplate)
  const ilmNodeAttributesExistOnDeployment = doNodeAttributesExistOnDeployment(deployment)

  if (!ilmNodeAttributesExist) {
    return
  }

  const version = getUpsertVersion({ deployment })
  const versionIlmCompatible = satisfies(version!, `>=6.7`)
  const ilmCompatible = versionIlmCompatible

  if (!ilmCompatible) {
    // If the selection is NOT ILM compatible, but ILM node_attributes exist
    // in the template, we need to remove the node_attributes from the
    // template plan to avoid having both ILM and index curation sent with
    // the plan
    const nodeConfigurations = getInstancesWithoutNodeAttributes(deploymentTemplate)

    esCluster.plan.cluster_topology = nodeConfigurations
    return
  }

  // If the deployment has node attributes, we need to remove index curation
  // settings so that ILM is the default index management option
  if (ilmNodeAttributesExistOnDeployment) {
    delete esCluster.plan.elasticsearch.curation
    set(esCluster, [`settings`, `curation`, `specs`], [])
  }
}

export function isPureIndexCurationTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate: DeploymentTemplateInfoV2
}) {
  if (doNodeAttributesExist(deploymentTemplate)) {
    return false
  }

  return isIndexCurationTemplate({ deploymentTemplate })
}

function hasIndexCuration({ deployment }: { deployment: StackDeployment }): boolean {
  const plan = getEsPlanFromGet({ deployment })

  if (!plan || !plan.elasticsearch) {
    return false
  }

  const {
    elasticsearch: { curation },
  } = plan

  return Boolean(curation)
}

export function shouldMigrateToIlm({ deployment }: { deployment: StackDeployment }) {
  const version = getVersion({ deployment })

  if (!version) {
    return false
  }

  if (lt(version, `6.7.0`)) {
    return false
  }

  if (isSystemOwned({ deployment })) {
    return false
  }

  if (!hasIndexCuration({ deployment })) {
    return false
  }

  if (localStorage.getItem(getIlmMigrationLsKey({ deploymentId: deployment!.id })) === `true`) {
    return false
  }

  return true
}

export function getNodeAttributesOnWarmNodes({
  deployment,
}: {
  deployment: StackDeployment
}): PlainHashMap[] {
  const plan = getEsPlanFromGet({ deployment })

  if (!plan || !plan.elasticsearch) {
    return []
  }

  const esClusterConfigs = getEsNodeConfigurationsFromGet({ deployment, nodeType: 'data' })
  const {
    elasticsearch: { curation },
  } = plan

  if (!curation) {
    return []
  }

  return esClusterConfigs
    .filter((config) => config.instance_configuration_id === curation.to_instance_configuration_id)
    .map((config) => config?.elasticsearch?.node_attributes || [])
}
