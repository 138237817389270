/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiCode, EuiDescribedFormGroup, EuiSelect } from '@elastic/eui'

import { labels, messages, sections } from '../../authProviderMessages'
import DocLink from '../../../../../../../components/DocLink'
import { CuiFormField } from '../../../../../../../cui/forms/CuiFormField'

import { scopeOptions } from './SearchModeSettings'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

const GroupSearchSettings: FunctionComponent<WrappedComponentProps> = ({
  intl: { formatMessage },
}) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.groupSearchSettings} />
      </h3>
    }
    description={
      <FormattedMessage
        {...sections.groupSearchSettingsDescription}
        values={{
          learnMore: (
            <DocLink link='ldapProviderGroupSearchDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <CuiFormField
      label={<FormattedMessage {...labels.baseDnForGroups} />}
      name='group_search.base_dn'
      helpText={
        <FormattedMessage
          {...labels.baseDnForGroupsHelpText}
          values={{
            exampleBaseDn: <EuiCode>cn=users,dc=example,dc=com</EuiCode>,
          }}
        />
      }
    />

    <CuiFormField
      label={<FormattedMessage {...labels.scope} />}
      name='group_search.scope'
      component={EuiSelect}
      options={scopeOptions(formatMessage)}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.filter} />}
      name='group_search.filter'
      helpText={
        <FormattedMessage
          {...labels.groupFilterHelpText}
          values={{
            exampleFilter: <EuiCode>{'(&(objectclass=groupOfNames)(member={0}))'}</EuiCode>,
          }}
        />
      }
    />

    <CuiFormField
      label={<FormattedMessage {...labels.groupUserAttribute} />}
      name='group_search.user_attribute'
      helpText={<FormattedMessage {...labels.groupHelpAttributeHelpText} />}
    />
  </EuiDescribedFormGroup>
)

export default injectIntl(GroupSearchSettings)
