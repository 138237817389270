/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import { FETCH_ALL_USERS } from '../../constants/actions'
import { getUsersUrl } from '../../lib/api/v1/urls'

import type { ThunkAction } from '../../types'
import type { UserList } from '../../lib/api/v1/types'

export function fetchAllUsers(): ThunkAction<Promise<UserList>> {
  return (dispatch) => {
    const url = getUsersUrl({ includeDisabled: true })

    return dispatch(
      asyncRequest({
        type: FETCH_ALL_USERS,
        url,
      }),
    )
  }
}
