/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui'

import {
  getFirstEsClusterFromGet,
  getLatestSnapshotSuccess,
  getScheduledSnapshotTime,
  hasRecentSnapshotSuccess,
} from '@/lib/stackDeployments/selectors'

import { CuiFlexItemSeparator } from '../../../../cui'

import SnapshotStat, { DateStat } from './SnapshotStat'
import SnapshotFrequency from './SnapshotFrequency'
import SnapshotRetentionTime from './SnapshotRetentionTime'

import type { StackDeployment } from '../../../../types'
import type { FunctionComponent } from 'react'

type Props = {
  stackDeployment: StackDeployment
  canEditSettings: boolean
  isUserConsole: boolean
}

const SnapshotStatus: FunctionComponent<Props> = ({
  canEditSettings,
  isUserConsole,
  stackDeployment,
}) => {
  const resource = getFirstEsClusterFromGet({ deployment: stackDeployment })!
  const hasRecentEnoughSuccess = hasRecentSnapshotSuccess({ resource })
  const latestSuccessAt = getLatestSnapshotSuccess({ resource })
  const nextSnapshotAt = getScheduledSnapshotTime({ resource })

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <EuiPanel>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <SnapshotStat
                label={
                  <FormattedMessage
                    id='deployment-snapshots-status.last-successful-snapshot'
                    defaultMessage='Last successful snapshot'
                  />
                }
                color={hasRecentEnoughSuccess ? undefined : `danger`}
              >
                <DateStat date={latestSuccessAt} />
              </SnapshotStat>
            </EuiFlexItem>

            {nextSnapshotAt && (
              <EuiFlexItem grow={false}>
                <SnapshotStat
                  label={
                    <FormattedMessage
                      id='deployment-snapshots-status.next-snapshot'
                      defaultMessage='Next snapshot'
                    />
                  }
                >
                  <DateStat date={nextSnapshotAt} />
                </SnapshotStat>
              </EuiFlexItem>
            )}

            <CuiFlexItemSeparator />

            <EuiFlexItem grow={false}>
              <SnapshotStat
                label={
                  <FormattedMessage
                    id='deployment-snapshots-status.snapshot-frequency'
                    defaultMessage='Snapshot frequency'
                  />
                }
              >
                <SnapshotFrequency deployment={stackDeployment} />
              </SnapshotStat>
            </EuiFlexItem>
          </EuiFlexGroup>

          <SnapshotRetentionTime
            isUserConsole={isUserConsole}
            canEditSettings={canEditSettings}
            stackDeployment={stackDeployment}
          />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default SnapshotStatus
