/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  convertOrganizationToInvoicing,
  resetConvertOrganizationToInvoicingRequest,
} from '@/actions/saasOrganizations'
import { convertOrganizationToInvoicingRequest } from '@/reducers/asyncRequests/registry'

import ConvertOrganizationToInvoicingButton from './ConvertOrganizationToInvoicingButton'

import type { AsyncRequestState, ReduxState } from '@/types'

interface StateProps {
  convertToInvoicingRequest: AsyncRequestState
  username: string
}

interface DispatchProps {
  convertToInvoicing: (username: string) => void
  resetConvertToInvoicing: () => void
}

interface ConsumerProps {
  organizationId: string
}

const mapStateToProps = (state: ReduxState, ownProps: ConsumerProps): StateProps => ({
  convertToInvoicingRequest: convertOrganizationToInvoicingRequest(state, ownProps.organizationId),
  // In a real SaaS deployment that's using Okta SSO, we'll always have a username.
  username: state.currentUser?.user.user_name || 'unknown',
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  convertToInvoicing: (email) => dispatch(convertOrganizationToInvoicing(email, organizationId)),
  resetConvertToInvoicing: () =>
    dispatch(resetConvertOrganizationToInvoicingRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ConvertOrganizationToInvoicingButton)
