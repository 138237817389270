/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniq, uniqBy, xor } from 'lodash'

import { getAllKnownSliderInstanceTypes } from '../sliders/sliders'

import {
  shouldMoveSettingsFromTopologyToPlan,
  getTopologyElementConfiguration,
  getUserSettingsFromConfiguration,
  getPlanConfiguration,
} from './selectors/configuration'
import { getFirstEsCluster, getResources } from './selectors'

import type { AnyPayload, AnyPlanConfiguration } from '../../types'
import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  ElasticsearchPayload,
  ElasticsearchUserBundle,
  ElasticsearchUserPlugin,
} from '../api/v1/types'

/**
 * Moves any eligible properties from the Configuration objects within topology elements to the plan level:
 *
 *   - user settings yaml
 *   - built-in plugin selections
 *   - user extension selections
 */
export function promoteConfigurationEntriesToPlan({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): void {
  const resources = getResources({ deployment })
  const esResource = getFirstEsCluster({ deployment })

  resources.forEach(promoteUserSettingsFromTopologyToPlan)

  if (!esResource) {
    return // sanity
  }

  promoteBuiltInPluginsFromTopologyToPlan(esResource)
  promoteUserBundlesFromTopologyToPlan(esResource)
  promoteUserPluginsFromTopologyToPlan(esResource)
}

function promoteUserSettingsFromTopologyToPlan(resource: AnyPayload): void {
  if (!shouldMoveSettingsFromTopologyToPlan(resource)) {
    return
  }

  const topologyElements = resource.plan.cluster_topology || []

  const userSettings = topologyElements
    .map((topologyElement) => {
      const configuration = getTopologyElementConfiguration(topologyElement)
      return getUserSettingsFromConfiguration(configuration)
    })
    .filter(Boolean)

  if (userSettings.length === 0) {
    return // sanity
  }

  // find the right sliderInstanceType, as this is a generic AnyPayload function
  for (const sliderInstanceType of getAllKnownSliderInstanceTypes()) {
    const planConfiguration = resource.plan[sliderInstanceType]

    if (planConfiguration) {
      // set plan-level user settings to the first topology-level user settings string
      planConfiguration.user_settings_yaml = userSettings[0]

      // remove all topology-level user settings
      topologyElements.forEach((topologyElement) => {
        const configuration = topologyElement[sliderInstanceType] as AnyPlanConfiguration

        if (!configuration) {
          return
        }

        delete configuration.user_settings_yaml
      })
    }
  }
}

function promoteBuiltInPluginsFromTopologyToPlan(resource: ElasticsearchPayload): void {
  const topologyElements = resource.plan.cluster_topology || []

  const topologyLevel = topologyElements
    .map(
      (topologyElement) =>
        getTopologyElementConfiguration(topologyElement)?.enabled_built_in_plugins,
    )
    .flat()
    .filter((x): x is string => Boolean(x))

  if (topologyLevel.length === 0) {
    // nothing to move
    return
  }

  const planLevel = getPlanConfiguration(resource)?.enabled_built_in_plugins || []

  const combined = uniq([...planLevel, ...topologyLevel])

  const anyDifferences =
    xor(combined, resource.plan.elasticsearch.enabled_built_in_plugins).length > 0

  if (anyDifferences) {
    // standardize on plan level
    resource.plan.elasticsearch.enabled_built_in_plugins = combined

    // clean up topology level
    topologyElements.forEach((topologyElement) => {
      delete topologyElement.elasticsearch?.enabled_built_in_plugins
    })
  }
}

function promoteUserBundlesFromTopologyToPlan(resource: ElasticsearchPayload): void {
  const topologyElements = resource.plan.cluster_topology || []

  const topologyLevel = topologyElements
    .map((topologyElement) => getTopologyElementConfiguration(topologyElement)?.user_bundles)
    .flat()
    .filter((x): x is ElasticsearchUserBundle => Boolean(x))

  if (topologyLevel.length === 0) {
    // nothing to move
    return
  }

  const planLevel = getPlanConfiguration(resource)?.user_bundles || []

  const combined = uniqBy([...planLevel, ...topologyLevel], 'url')

  const anyDifferences = xor(combined, resource.plan.elasticsearch.user_bundles).length > 0

  if (anyDifferences) {
    // standardize on plan level
    resource.plan.elasticsearch.user_bundles = combined

    // clean up topology level
    topologyElements.forEach((topologyElement) => {
      delete topologyElement.elasticsearch?.user_bundles
    })
  }
}

function promoteUserPluginsFromTopologyToPlan(resource: ElasticsearchPayload): void {
  const topologyElements = resource.plan.cluster_topology || []

  const topologyLevel = topologyElements
    .map((topologyElement) => getTopologyElementConfiguration(topologyElement)?.user_plugins)
    .flat()
    .filter((x): x is ElasticsearchUserPlugin => Boolean(x))

  if (topologyLevel.length === 0) {
    // nothing to move
    return
  }

  const planLevel = getPlanConfiguration(resource)?.user_plugins || []

  const combined = uniqBy([...planLevel, ...topologyLevel], 'url')

  const anyDifferences = xor(combined, resource.plan.elasticsearch.user_plugins).length > 0

  if (anyDifferences) {
    // standardize on plan level
    resource.plan.elasticsearch.user_plugins = combined

    // clean up topology level
    topologyElements.forEach((topologyElement) => {
      delete topologyElement.elasticsearch?.user_plugins
    })
  }
}
