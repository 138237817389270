/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert, withErrorBoundary } from '../../../../cui'
import DangerButton from '../../../DangerButton'
import StoredProcedureResult from '../../../User/StoredProcedureResult'

import type { AsyncRequestState } from '../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

export interface Props extends WrappedComponentProps {
  creditAccount: ({ amount, description, reason }) => Promise<any>
  creditAccountRequest: AsyncRequestState
}

const messages = defineMessages({
  creditAccountLabel: {
    id: `organization.organization-overview.credit-account-button.button-label`,
    defaultMessage: `Credit account`,
  },
  creditAccount: {
    id: `organization.organization-overview.credit-account-button.button-text`,
    defaultMessage: `Credit account`,
  },
  creditAccountModalTitle: {
    id: `organization.organization-overview.credit-account-button.modal-title`,
    defaultMessage: `Credit account`,
  },
  creditAccountModalBody: {
    id: `organization.organization-overview.credit-account-button.modal-message`,
    defaultMessage: `You are about to credit an account. The amount you add here will be deducted from this account’s next invoice. The credit will be manually reviewed before applied to the next invoice.`,
  },
  creditAccountModalConfirm: {
    id: `organization.organization-overview.credit-account-button.modal-confirm`,
    defaultMessage: `Credit account`,
  },
  creditAccountSuccess: {
    id: `organization.organization-overview.credit-account-button.text-success`,
    defaultMessage: `Added credit to account.`,
  },
  creditAccountAmount: {
    id: `organization.organization-overview.credit-account-button.amount-label`,
    defaultMessage: `Amount (USD)`,
  },
  creditAccountDescription: {
    id: `organization.organization-overview.credit-account-button.description-label`,
    defaultMessage: `Description (will appear on the invoice)`,
  },
  creditAccountReason: {
    id: `organization.organization-overview.credit-account-button.reason-label`,
    defaultMessage: `Reason`,
  },
})

const CreditAccountButton: FunctionComponent<Props> = ({
  intl: { formatMessage },
  creditAccount,
  creditAccountRequest,
}) => {
  const actionText = formatMessage(messages.creditAccount)

  const busy = creditAccountRequest.inProgress

  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [reason, setReason] = useState('')

  const modalBody = (
    <Fragment>
      <FormattedMessage {...messages.creditAccountModalBody} />

      <EuiSpacer size='m' />

      <EuiFlexGroup gutterSize='m' alignItems='center'>
        <EuiFlexItem>
          <EuiFormLabel>
            <FormattedMessage {...messages.creditAccountAmount} />
          </EuiFormLabel>

          <EuiFieldNumber
            max={500}
            value={amount}
            required={true}
            onChange={(e) => setAmount(e.target.value)}
            readOnly={busy}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFormLabel>
            <FormattedMessage {...messages.creditAccountDescription} />
          </EuiFormLabel>

          <EuiFieldText
            required={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            readOnly={busy}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFormLabel>
            <FormattedMessage {...messages.creditAccountReason} />
          </EuiFormLabel>

          <EuiFieldText
            required={true}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            readOnly={busy}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  )

  const onConfirm = () =>
    creditAccount({ amount, description, reason }).then(() => {
      setDescription('')
      setAmount('')
      setReason('')
    })

  return (
    <Fragment>
      <DangerButton
        buttonType={EuiButtonEmpty}
        size='s'
        color='primary'
        fill={false}
        onConfirm={onConfirm}
        spin={busy}
        modal={{
          title: formatMessage(messages.creditAccountModalTitle),
          body: modalBody,
          confirmButtonText: formatMessage(messages.creditAccountModalConfirm),
          confirmButtonDisabled: !(amount && description && reason),
        }}
      >
        {actionText}
      </DangerButton>

      {creditAccountRequest.error && (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='error'>{creditAccountRequest.error}</CuiAlert>
        </Fragment>
      )}

      <StoredProcedureResult
        procedureId='credit_organization'
        successMessage={formatMessage(messages.creditAccountSuccess)}
      />
    </Fragment>
  )
}

export default withErrorBoundary(injectIntl(CreditAccountButton))
