/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { forEach, groupBy, sortBy, map, values } from 'lodash'

import { getRunnerRoles } from './runnerRoles'

import type { AllocatorSearchResult, Region, RegionProxy, Runner } from '../../../types'
import type { CombinedPlatformData, PlatformDatum } from './infrastructureVisualizationTypes'

/**
  This function takes in the different data sources that make up the platform visualization.

  All hosts are runners. A runner can have an allocator role, or a proxy role, or a coordinator role. The data sources
  are disparate mostly because it makes it easier to search for them. An allocator with `id: 1` is the same host
  as the runner with `id: 1`. Proxies and coordinators also both tie back to the runner. That means that we can tie everything
  together using the id of the runner.

  We create an object that has its keys the id of the runner and each object has the list of roles that a runner could
  be. We iterate through all of the data sources and for each item we change the boolean value representing the role
  if it exists.

  There is a single healthy variable per node (hexagon) which is updated so that if any of the roles are unhealthy then
  the whole node is unhealthy.

  We return the data grouped by zone and sorted by health in each zone.
*/

function withType<T, V extends string>(array: T[], type: V) {
  return map(array, (each) => ({ ...each, type }))
}

export function combinePlatformData(
  runners: Runner[],
  proxies: RegionProxy[],
  allocators: AllocatorSearchResult[],
  coordinators: Region['coordinators']['coordinators'],
) {
  const combinedInputData: PlatformDatum[] = [
    ...withType(runners, 'runner'),
    ...withType(allocators, 'allocator'),
    ...withType(proxies, 'proxy'),
    ...withType(values(coordinators), 'coordinator'),
  ]

  const result: { [id: string]: CombinedPlatformData } = {}

  for (const node of combinedInputData) {
    const id = getNodeId(node)

    if (result[id] == null) {
      result[id] = { id, types: {}, healthy: true }
    }

    const data = result[id]

    data.types[node.type] = true

    if (node.type !== 'coordinator' && !node.healthy) {
      data.healthy = false
    }

    if (data.zone == null) {
      switch (node.type) {
        case 'allocator':
          data.zone = node.zoneId
          break

        case 'proxy':
          data.zone = node.availabilityZone
          break

        case 'runner':
          data.zone = node.zone
          break

        default:
          break
      }
    }

    if (node.type === 'allocator' && node.isInMaintenanceMode != null) {
      data.isInMaintenanceMode = node.isInMaintenanceMode
    }
  }

  return sortNodesByHealth(groupBy(result, `zone`))
}

function sortNodesByHealth(dataByZone: { [zone: string]: CombinedPlatformData[] }) {
  forEach(dataByZone, (data: CombinedPlatformData[], zone: string) => {
    dataByZone[zone] = sortBy(data, sortOnHealth, sortOnType, `id`)
  })
  return dataByZone
}

function sortOnHealth(node: CombinedPlatformData): number {
  const { healthy, isInMaintenanceMode } = node

  if (!healthy) {
    return 1
  }

  if (isInMaintenanceMode) {
    return 2
  }

  return 3
}

function sortOnType(node: CombinedPlatformData): string {
  const nodeTypes = getRunnerRoles(node).join(`, `)

  if (nodeTypes === `runner`) {
    return `z__runner`
  }

  return nodeTypes
}

function getNodeId(node: PlatformDatum): string {
  if (node.type === `proxy`) {
    return node.runnerId || node.id
  }

  if (node.type === `coordinator`) {
    return node.name
  }

  if (node.type === `runner`) {
    return node.runner_id
  }

  return node.id
}
