/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import Markdown from 'markdown-it'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import mila from 'markdown-it-link-attributes'
import { css, jsx } from '@emotion/react'

import {
  EuiAccordion,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
  useEuiBackgroundColor,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import sanitizeHtml from '@/lib/sanitizeHtml'

import HealthStatusDetailsTroubleshootingFlyout from './HealthStatusDetailsTroubleshootingFlyout'

import type { FunctionComponent } from 'react'
import type { Diagnosis } from '@/lib/api/v1/types'

export interface TroubleshootingSectionProps extends WithEuiThemeProps {
  diagnosis?: Diagnosis
}

const MAX_RESOURCES_PREVIEW = 6

const md = new Markdown({
  linkify: true, // auto-convert URL-like text to links
  html: false, // disable HTML tags in source
})

// use markdown-it plugin to add target attributes to external links
md.use(mila, {
  attrs: {
    target: '_blank',
    'data-test-subj': 'troubleshooting-action_url',
  },
})

export const HealthStatusDetailsTroubleshootingDiagnosis: FunctionComponent<
  TroubleshootingSectionProps
> = ({ diagnosis, theme }) => {
  const accordionId = useGeneratedHtmlId({
    prefix: 'health-status-details-accordion',
  })
  const [trigger, setTrigger] = useState<'open' | 'closed'>('closed')
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)

  // Compute which resources need to be previewed and which ones need to be hidden
  const affectedResources = diagnosis?.affected_resources ?? []
  const affectedResourcesPreview = affectedResources.slice(0, MAX_RESOURCES_PREVIEW)
  const affectedResourcesHidden = affectedResources.slice(
    MAX_RESOURCES_PREVIEW,
    affectedResources.length,
  )

  // Create emotion styles for our title container and accordion container
  const isOpen = trigger === 'open'
  const bgColorActive = useEuiBackgroundColor('primary')
  const bgColorInactive = useEuiBackgroundColor('subdued')

  const titleContainerCss = css({
    backgroundColor: isOpen ? bgColorActive : bgColorInactive,
    padding: theme.euiTheme.size.m,
    border: theme.euiTheme.border.thin,
    borderTopLeftRadius: theme.euiTheme.size.s,
    borderTopRightRadius: theme.euiTheme.size.s,
    borderBottomLeftRadius: isOpen ? 'none' : theme.euiTheme.size.s,
    borderBottomRightRadius: isOpen ? 'none' : theme.euiTheme.size.s,
    borderBottom: isOpen ? 'none' : theme.euiTheme.border.thin,
    marginBottom: isOpen ? 0 : theme.euiTheme.size.m,
    transition: 'background-color .25s ease-out',
  })

  const accordionContainerCss = css({
    border: isOpen ? theme.euiTheme.border.thin : 'none',
    borderTop: 'none',
    borderBottomLeftRadius: theme.euiTheme.size.s,
    borderBottomRightRadius: theme.euiTheme.size.s,
    marginBottom: isOpen ? theme.euiTheme.size.m : 0,
  })

  const actionText = md.render((diagnosis?.cause || '') + ' ' + (diagnosis?.action || ''))

  return (
    <React.Fragment>
      <EuiFlexItem css={titleContainerCss}>
        <EuiFlexGroup responsive={false} alignItems='center'>
          <EuiFlexItem>
            <EuiFlexGroup responsive={false} alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiIcon type='wrench' size='l' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiTitle size='xs'>
                  <h5>
                    <FormattedMessage
                      id='health-status-details.troubleshooting-sub-title'
                      defaultMessage='How to fix'
                    />
                  </h5>
                </EuiTitle>
                {affectedResources.length > 0 && (
                  <React.Fragment>
                    <EuiSpacer size='s' />
                    <EuiText
                      size='s'
                      data-test-subj='health-problem-diagnosis-affected-resources-preview'
                    >
                      {affectedResourcesPreview?.join(', ')}
                      {affectedResourcesHidden.length > 0 && (
                        <FormattedMessage
                          id='health-status-details.troubleshooting-more_affected_resources'
                          defaultMessage=', and {hiddenItemsCount} more'
                          values={{ hiddenItemsCount: affectedResourcesHidden.length }}
                        />
                      )}
                    </EuiText>
                  </React.Fragment>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              size='s'
              iconType={isOpen ? 'arrowUp' : 'arrowDown'}
              iconSide='right'
              onClick={() => setTrigger(isOpen ? 'closed' : 'open')}
            >
              {isOpen ? (
                <FormattedMessage
                  id='health-status-details.troubleshooting-collapse'
                  defaultMessage='Collapse'
                />
              ) : (
                <FormattedMessage
                  id='health-status-details.troubleshooting-expand'
                  defaultMessage='Expand'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem css={accordionContainerCss}>
        <EuiAccordion
          data-test-subj='health-problem-diagnosis-accordion'
          id={accordionId}
          paddingSize='l'
          arrowDisplay='none'
          forceState={trigger}
          css={css({ paddingLeft: 36, paddingRight: 36 })}
        >
          <React.Fragment>
            <EuiText size='s' data-test-subj='heath-problem-diagnosis-action-text'>
              {sanitizeHtml(actionText)}
            </EuiText>
            <EuiText size='s'>
              <EuiLink
                href={diagnosis?.help_url}
                target='_blank'
                data-test-subj='troubleshooting-help_url'
              >
                {diagnosis?.help_url}
              </EuiLink>
            </EuiText>
            {affectedResources.length > 0 && (
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='health-status-details.troubleshooting-affected_resources_label'
                    defaultMessage='This problem affects the following resources: {resourcesPreview} '
                    values={{ resourcesPreview: affectedResourcesPreview.join(', ') }}
                  />
                  {affectedResourcesHidden.length > 0 && (
                    <EuiLink
                      onClick={() => setIsFlyoutVisible(true)}
                      data-test-subj='health=problem-diagnosis-more_affected_resources-button'
                    >
                      <FormattedMessage
                        id='health-status-details.troubleshooting-more_affected_resources'
                        defaultMessage='and {hiddenItemsCount} more.'
                        values={{ hiddenItemsCount: affectedResourcesHidden.length }}
                      />
                    </EuiLink>
                  )}
                  {isFlyoutVisible && (
                    <HealthStatusDetailsTroubleshootingFlyout
                      onClose={() => setIsFlyoutVisible(false)}
                      affectedResources={affectedResources}
                      actionText={actionText}
                    />
                  )}
                </EuiText>
              </React.Fragment>
            )}
          </React.Fragment>
        </EuiAccordion>
      </EuiFlexItem>
    </React.Fragment>
  )
}
