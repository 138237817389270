/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '@/actions/asyncRequests'
import { GENERATE_RESET_PASSWORD_LINK } from '@/constants/actions'

import type { ThunkAction } from '@/types'

const getSaasResetPasswordParamsUrl = ({ userId }: { userId: string }): string =>
  `api/v1/saas/users/${userId}/_password_params`

export function generateResetPasswordLink(userId: string): ThunkAction {
  const url = getSaasResetPasswordParamsUrl({
    userId,
  })

  return asyncRequest({
    type: GENERATE_RESET_PASSWORD_LINK,
    url,
  })
}
