/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { isFeatureActivated } from '../../../../selectors'
import { takeSnapshotRequest } from '../../../../reducers/asyncRequests/registry'
import Feature from '../../../../lib/feature'

import SnapshotActions from './SnapshotActions'

import type { StateProps, ConsumerProps } from './SnapshotActions'

const mapStateToProps = (
  state,
  { stackDeployment, snapshotsEnabled }: ConsumerProps,
): StateProps => ({
  takeSnapshotRequest: takeSnapshotRequest(state, stackDeployment.id),
  showTakeSnapshotButton:
    snapshotsEnabled && isFeatureActivated(state, Feature.showTakeSnapshotButton),
})

export default connect<StateProps, unknown, ConsumerProps>(mapStateToProps)(SnapshotActions)
