/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeploymentsCostsRequest,
  getAccountDeploymentsCosts,
  getAccountCostsOverview,
} from '../../../../reducers'

import DeploymentCostsGrid from './DeploymentCostsGrid'

import type { ConsumerProps, StateProps } from './types'

const mapStateToProps = (state, ownProps: ConsumerProps): StateProps => {
  const { organizationId } = ownProps
  const accountCostOverview = getAccountCostsOverview({ state, organizationId })

  return {
    accountCosts: getAccountDeploymentsCosts(state, organizationId),
    fetchDeploymentsCostsRequest: fetchDeploymentsCostsRequest(state),
    isTrialConversionUser: Boolean(
      accountCostOverview && accountCostOverview.isTrialConversionUser,
    ),
  }
}

export default connect<StateProps, ConsumerProps>(mapStateToProps)(DeploymentCostsGrid)
