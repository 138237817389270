/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { has } from 'lodash'

import { EuiFieldText, EuiText } from '@elastic/eui'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { InnerClause, OuterClause } from '../../../../../types/instanceConfigurationTypes'

const messages = defineMessages({
  key: {
    id: `instance-configuration-filter-allocators.tag-key`,
    defaultMessage: `Key`,
  },
  value: {
    id: `instance-configuration-filter-allocators.tag-value`,
    defaultMessage: `Value`,
  },
})

export type Props = {
  intl: IntlShape
  errors
  outerClause: OuterClause
  innerClause: InnerClause
  innerIndex: number
  setTagKey: (outerClause: OuterClause, index: number, value: string) => void
  setTagValue: (outerClause: OuterClause, index: number, value: string) => void
}

const ClauseFields: FunctionComponent<Props> = ({
  intl: { formatMessage },
  errors,
  outerClause,
  innerClause,
  innerIndex,
  setTagKey,
  setTagValue,
}) => {
  const tagKeyPlaceholder = formatMessage(messages.key)
  const tagValuePlaceholder = formatMessage(messages.value)

  const hasKeyError = has(errors, [`key`])
  const hasValueError = has(errors, [`value`])

  return (
    <div
      className='topologyFilterAllocators-filterClause-fields'
      data-test-id='filter-clause-field'
    >
      <EuiFieldText
        isInvalid={hasKeyError}
        className='topologyFilterAllocators-tagKey'
        value={innerClause.key}
        placeholder={tagKeyPlaceholder}
        onChange={(e) => setTagKey(outerClause, innerIndex, e.target.value)}
      />
      <EuiText size='s' className='topologyFilterAllocators-filterClause-fields-equal-to'>
        <FormattedMessage
          id='instance-configuration-filter-allocators.equal-to'
          defaultMessage='equal to'
        />
      </EuiText>
      <EuiFieldText
        isInvalid={hasValueError}
        className='topologyFilterAllocators-tagValue'
        value={innerClause.value}
        placeholder={tagValuePlaceholder}
        onChange={(e) => setTagValue(outerClause, innerIndex, e.target.value)}
      />
    </div>
  )
}

export default injectIntl(ClauseFields)
