/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiHorizontalRule, EuiTitle } from '@elastic/eui'

import { getVersion, getEsPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'

import { gte } from '../../lib/semver'
import { hasPermission } from '../../lib/requiresPermission'
import { getConfigForKey } from '../../store'
import Permission from '../../lib/api/v1/permissions'

import TempShieldUsers from './TempShieldUsers'
import ProxyLogging from './ProxyLogging'
import CpuHardLimit from './CpuHardLimit'
import ClusterDiagnosticBundle from './ClusterDiagnosticBundle'
import RetryFailedShards from './RetryFailedShards'
import ClusterLogs from './ClusterLogs'
import DeploymentLock from './DeploymentLock'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

import './clusterManage.scss'

const DeploymentOperations: FunctionComponent<Props> = ({
  resource,
  deployment,
  tempShieldUsersActivated,
}) => {
  if (!deployment || !resource) {
    return null // sanity
  }

  const version = getVersion({ deployment })
  const pendingPlan = getEsPlanFromGet({ deployment, state: 'pending' })
  const currentPlan = getEsPlanFromGet({ deployment, state: 'current' })
  const pendingVersion = pendingPlan?.elasticsearch.version

  if (!currentPlan && pendingVersion != null) {
    return null // while creating, we already show a message
  }

  const canRetryFailedShards = version != null ? gte(version, `5.0.0`) : false

  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  return (
    <div data-test-id='deployment-operations'>
      {!isUserConsole && (
        <Fragment>
          <EuiTitle>
            <h3>
              <FormattedMessage id='cluster-manage-index.settings' defaultMessage='Settings' />
            </h3>
          </EuiTitle>

          <EuiSpacer size='s' />

          <ProxyLogging deployment={deployment} />
          <CpuHardLimit deployment={deployment} />
          <DeploymentLock deployment={deployment} />

          <EuiHorizontalRule />

          <EuiTitle>
            <h3>
              <FormattedMessage
                id='cluster-manage-index.help-actions'
                defaultMessage='Help Actions'
              />
            </h3>
          </EuiTitle>

          <EuiSpacer size='s' />

          {tempShieldUsersActivated && hasPermission(Permission.setDeploymentResourceRawMetadata) && (
            <Fragment>
              <EuiTitle size='xs'>
                <h3>
                  <FormattedMessage
                    id='cluster-manage-index.temporary-shield-users'
                    defaultMessage='Temporary shield users'
                  />
                </h3>
              </EuiTitle>

              <TempShieldUsers deployment={deployment} />

              <EuiSpacer size='m' />
            </Fragment>
          )}

          <RetryFailedShards deployment={deployment} canRetryFailedShards={canRetryFailedShards} />

          <EuiSpacer size='m' />

          <ClusterDiagnosticBundle deploymentId={deployment.id} />

          <EuiSpacer size='m' />

          <ClusterLogs resource={resource} deployment={deployment} />
        </Fragment>
      )}
    </div>
  )
}

export default DeploymentOperations
