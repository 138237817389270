/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get, noop } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  htmlIdGenerator,
  EuiLoadingContent,
} from '@elastic/eui'

import { CuiAlert } from '@/cui'
import { toNumberOrElse } from '@/lib/toNumber'
import { replaceIn } from '@/lib/immutability-helpers'
import {
  getCurrentInstanceCapacity,
  getDefaultCapacity,
  getSizeOptions,
  hasInstanceSizeOverride,
} from '@/lib/stackDeployments/clusterInstances'

import SpinButton from '../../../SpinButton'
import DiscreteSizePicker from '../../../DiscreteSizePicker'
import JvmMemoryPressure from '../JvmMemoryPressure'
import RatioLabel from '../../../Topology/DeploymentTemplates/components/RatioLabel'

import type { ReactNode } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type {
  Allocator,
  AnyResourceInfo,
  AsyncRequestState,
  SliderInstanceType,
  StackDeployment,
} from '@/types'
import type {
  ClusterInstanceConfigurationInfo,
  ClusterInstanceInfo,
  DeploymentTemplateInfoV2,
} from '@/lib/api/v1/types'

import './instanceCapacityOverrideModal.scss'

export type StateProps = {
  canApplyToAll: boolean
  setInstanceCapacityRequest: AsyncRequestState
  deploymentTemplate?: DeploymentTemplateInfoV2 | null
  allocator?: Allocator
  fetchAllocatorRequest: AsyncRequestState
}

export type DispatchProps = {
  setInstanceCapacity: (args: {
    instanceCapacity: number | null
    applyToAll: boolean
  }) => Promise<any>
  resetSetInstanceCapacityRequest: () => void
  fetchAllocator: () => Promise<any>
}

export type ConsumerProps = {
  deployment: StackDeployment
  resourceKind: SliderInstanceType
  resource: AnyResourceInfo
  instance: ClusterInstanceInfo
}

export interface Props extends WrappedComponentProps, StateProps, DispatchProps {
  close: () => void
  deployment: StackDeployment
  instance: ClusterInstanceInfo
  instanceConfiguration: ClusterInstanceConfigurationInfo
  deploymentTemplate: DeploymentTemplateInfoV2
}

type State = {
  size: number
  applyToAllLikeThis: boolean
}

const makeId = htmlIdGenerator()

const messages = defineMessages({
  alertTitle: {
    id: `instance-capacity-override-modal`,
    defaultMessage: `Heads up!`,
  },
})

class InstanceCapacityOverrideModal extends Component<Props, State> {
  state: State = {
    size: getCurrentInstanceCapacity({
      instance: this.props.instance,
      instanceConfigMeta: this.props.instanceConfiguration,
      deploymentTemplate: this.props.deploymentTemplate,
    }),
    applyToAllLikeThis: false,
  }

  componentDidMount() {
    const { allocator, fetchAllocator } = this.props

    if (!allocator) {
      fetchAllocator()
    }
  }

  render(): JSX.Element {
    const { size } = this.state

    const { instance, instanceConfiguration, setInstanceCapacityRequest } = this.props

    return (
      <EuiOverlayMask>
        <EuiModal
          className='instanceCapacityOverride-modal'
          onClose={this.onClose}
          style={{ width: `48rem` }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='instance-capacity-override-modal.title'
                defaultMessage='Instance Size Override'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{this.renderModalContent()}</EuiModalBody>

          <EuiModalFooter>
            <div>
              <EuiFlexGroup gutterSize='m' justifyContent='flexEnd' alignItems='center'>
                {hasInstanceSizeOverride({
                  instance,
                  instanceConfigMeta: instanceConfiguration,
                  size,
                }) && (
                  <EuiFlexItem grow={false}>
                    <EuiLink color='warning' onClick={this.resetDefaultCapacity}>
                      <FormattedMessage
                        id='instance-capacity-override-modal.reset-system-default'
                        defaultMessage='Reset system default'
                      />
                    </EuiLink>
                  </EuiFlexItem>
                )}

                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={this.onClose}>
                    <FormattedMessage
                      id='instance-capacity-override-modal.cancel'
                      defaultMessage='Cancel'
                    />
                  </EuiButtonEmpty>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <div>
                    <SpinButton
                      data-test-id='instance-capacity-save-btn'
                      onClick={this.onSave}
                      spin={setInstanceCapacityRequest.inProgress}
                      fill={true}
                    >
                      <FormattedMessage
                        id='instance-capacity-override-modal.save'
                        defaultMessage='Save'
                      />
                    </SpinButton>
                  </div>
                </EuiFlexItem>
              </EuiFlexGroup>

              {setInstanceCapacityRequest.error && (
                <Fragment>
                  <EuiSpacer size='m' />

                  <CuiAlert type='error' data-test-id='set-instance-capacity-error'>
                    {setInstanceCapacityRequest.error}
                  </CuiAlert>
                </Fragment>
              )}
            </div>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderModalContent() {
    const { size, applyToAllLikeThis } = this.state
    const {
      fetchAllocatorRequest,
      canApplyToAll,
      instance,
      instanceConfiguration,
      deploymentTemplate,
      allocator,
      intl: { formatMessage },
    } = this.props

    const { resource, name: instanceConfigurationName } = instanceConfiguration

    const storage = get(instance, [`disk`, `disk_space_available`], 0)
    const memory = get(instance, [`memory`, `instance_capacity`], 0)

    const isStorage = resource === `storage`

    const originalSize = isStorage ? storage : memory
    const originalSecondarySize = isStorage ? memory : storage
    const secondaryRatio = originalSecondarySize / originalSize
    const secondarySize = size * secondaryRatio

    const projectedStorage = isStorage ? size : secondarySize
    const projectedMemory = isStorage ? secondarySize : size

    const projectedInstanceDisk = replaceIn(
      instance,
      [`disk`, `disk_space_available`],
      projectedStorage,
    )
    const projectedInstance = replaceIn(
      projectedInstanceDisk,
      [`memory`, `instance_capacity`],
      projectedMemory,
    )

    const instanceDiffRatio = size / originalSize
    const sizes = getSizeOptionsWithFormatting({
      instance,
      instanceConfigMeta: instanceConfiguration,
      deploymentTemplate,
    })

    if (fetchAllocatorRequest.inProgress || allocator === undefined) {
      return <EuiLoadingContent />
    }

    if (fetchAllocatorRequest.error) {
      return (
        <Fragment>
          <EuiSpacer size='m' />
          <CuiAlert type='error'>{fetchAllocatorRequest.error}</CuiAlert>
        </Fragment>
      )
    }

    const remainingMemoryCapacityOnAllocator = allocator?.capacity.total - allocator?.capacity.used
    // the difference between the current memory and the new selected memory cannot be greater than the amount of room left on the allocator
    const exceedingAllocatorCapacity = projectedMemory - memory > remainingMemoryCapacityOnAllocator

    return (
      <Fragment>
        <EuiText>
          <FormattedMessage
            id='instance-capacity-override-modal.description'
            defaultMessage='When an instance has reached its size allocation and temporarily needs a bit more room to be able to administrate, you can override its size.'
          />

          <EuiSpacer size='m' />

          <FormattedMessage
            id='instance-capacity-override-modal.current-size'
            defaultMessage='This instance has { memorySize } and { storageSize }.'
            values={{
              memorySize: (
                <strong>
                  <RatioLabel resource='memory' size={memory} />
                </strong>
              ),
              storageSize: (
                <strong>
                  <RatioLabel resource='storage' size={storage} />
                </strong>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='m' />

        {/* This fixes the scrollbar appearing from the dynamic negative margins used in the discreet slider */}
        <div style={{ overflowX: `hidden` }}>
          <EuiFormLabel>
            <FormattedMessage
              id='instance-capacity-override-modal.instance-capacity-label'
              defaultMessage='Instance size'
            />
          </EuiFormLabel>

          <EuiSpacer size='s' />

          <EuiFlexGroup gutterSize='m' alignItems='center'>
            <EuiFlexItem grow={false}>
              <DiscreteSizePicker
                instanceCapacityOverrideModal={true}
                data-test-id='instance-capacity-slider'
                value={String(size)}
                options={sizes}
                // We should never fail to parse the string
                onChange={(value) => this.setState({ size: toNumberOrElse(value, -1) })}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='m' />

          {canApplyToAll && (
            <EuiSwitch
              id={makeId()}
              label={
                <FormattedMessage
                  id='instance-capacity-override-modal.apply-to-all-like-this'
                  defaultMessage='Apply to all { instanceConfiguration } instances in this deployment'
                  values={{
                    instanceConfiguration: <EuiBadge>{instanceConfigurationName}</EuiBadge>,
                  }}
                />
              }
              checked={applyToAllLikeThis}
              onChange={this.toggleApplyToAll}
            />
          )}

          {applyToAllLikeThis && (
            <Fragment>
              <EuiSpacer size='m' />

              <EuiCallOut color='warning'>
                <FormattedMessage
                  id='instance-capacity-override-modal.restart-warning'
                  defaultMessage='Note that affected nodes will be restarted automatically, and this might result in temporarily downgraded performance or downtime.'
                />
              </EuiCallOut>
            </Fragment>
          )}

          <EuiSpacer size='m' />

          <JvmMemoryPressure
            label={
              <FormattedMessage
                id='instance-capacity-override-modal.current-pressure-label'
                defaultMessage='Current memory pressure'
              />
            }
            instance={instance}
          />

          <EuiSpacer size='m' />

          <JvmMemoryPressure
            label={
              <FormattedMessage
                id='instance-capacity-override-modal.projected-pressure-label'
                defaultMessage='Projected memory pressure'
              />
            }
            instance={projectedInstance}
            ratio={instanceDiffRatio}
          />
        </div>

        <EuiSpacer />

        <EuiText>
          <FormattedMessage
            id='instance-capacity-override-modal.save-explained'
            defaultMessage='Upon save, this instance will have a size of { nextSize }.'
            values={{
              nextSize: (
                <strong>
                  <RatioLabel resource={resource} size={size} />
                </strong>
              ),
            }}
          />
        </EuiText>

        {exceedingAllocatorCapacity && (
          <EuiCallOut
            color='danger'
            iconType='alert'
            title={formatMessage(messages.alertTitle)}
            data-test-subj='instance-capacity-override-modal-not-enough-allocator-capacity'
          >
            <EuiText>
              <FormattedMessage
                id='instance-capacity-override-modal.not-enough-allocator-capacity'
                defaultMessage='There is not enough capacity on the allocator to make this change. If you go ahead, the change will likely fail. To make room on the allocator, move instances from a different, <bold>healthy and HA</bold> deployment.'
                values={{
                  bold: (content) => <b>{content}</b>,
                }}
              />
            </EuiText>
          </EuiCallOut>
        )}
      </Fragment>
    )
  }

  toggleApplyToAll = () => {
    const { applyToAllLikeThis } = this.state

    this.setState({ applyToAllLikeThis: !applyToAllLikeThis })
  }

  onSave = () => {
    const { size, applyToAllLikeThis: applyToAll } = this.state
    const { setInstanceCapacity } = this.props

    setInstanceCapacity({
      instanceCapacity: size,
      applyToAll,
    })
      .then(() => this.onClose())
      .catch(noop) // don't close when there were errors
  }

  resetDefaultCapacity = () => {
    const { instance, instanceConfiguration } = this.props

    this.setState({
      size: getSize(),
    })

    function getSize() {
      const size = getDefaultCapacity({ instance, instanceConfigMeta: instanceConfiguration })

      if (size) {
        return size
      }

      const { resource } = instanceConfiguration
      const storage = get(instance, [`disk`, `disk_space_available`], 0)
      const memory = get(instance, [`memory`, `instance_capacity`], 0)
      const resourceCapacity = resource === `storage` ? storage : memory
      return resourceCapacity
    }
  }

  onClose = () => {
    const { resetSetInstanceCapacityRequest, close } = this.props

    resetSetInstanceCapacityRequest()
    close()
  }
}

function getSizeOptionsWithFormatting({
  instance,
  instanceConfigMeta,
  deploymentTemplate,
}: {
  instance: ClusterInstanceInfo
  instanceConfigMeta: ClusterInstanceConfigurationInfo
  deploymentTemplate: DeploymentTemplateInfoV2
}): Array<{ children: ReactNode; text: ReactNode; value: string }> {
  const defaultCapacity = getDefaultCapacity({
    instance,
    instanceConfigMeta,
  })

  const { resource, sizes } = getSizeOptions({ instance, instanceConfigMeta, deploymentTemplate })

  const storage: number = get(instance, [`disk`, `disk_space_available`], 0)
  const memory: number = get(instance, [`memory`, `instance_capacity`], 0)

  const resourceCapacity = resource === `storage` ? storage : memory
  const secondaryResource = resource === `memory` ? `storage` : `memory`
  const secondaryResourceCapacity = secondaryResource === `storage` ? storage : memory
  const ratio = secondaryResourceCapacity / resourceCapacity

  return sizes.map((size) => ({
    value: String(size),
    text: (
      <Fragment>
        <RatioLabel resource={resource} size={size} />

        <EuiSpacer size='xs' />

        <RatioLabel resource={secondaryResource} size={size * ratio} />
      </Fragment>
    ),
    children: getFancyLabel(size),
  }))

  function getFancyLabel(size: number): ReactNode {
    if (size === resourceCapacity) {
      return (
        <Fragment>
          <EuiSpacer size='s' />

          <EuiBadge>
            <FormattedMessage
              id='instance-capacity-override-modal.current'
              defaultMessage='Current'
            />
          </EuiBadge>
        </Fragment>
      )
    }

    if (size === defaultCapacity) {
      return (
        <Fragment>
          <EuiSpacer size='s' />

          <EuiBadge color='warning'>
            <FormattedMessage
              id='instance-capacity-override-modal.system-default'
              defaultMessage='Default'
            />
          </EuiBadge>
        </Fragment>
      )
    }

    return null
  }
}

export default injectIntl(InstanceCapacityOverrideModal)
