/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiCode, EuiDescribedFormGroup, EuiSelect, htmlIdGenerator } from '@elastic/eui'

import { labels, messages, scopeValues, sections } from '../../authProviderMessages'
import { CuiFormField } from '../../../../../../../cui/forms'
import DocLink from '../../../../../../../components/DocLink'

import type { ActiveDirectoryProviderFormShape } from '../ActiveDirectoryProviderForm'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  values: ActiveDirectoryProviderFormShape
}

const idPrefix = htmlIdGenerator()()

export const scopeOptions = (formatMessage) => [
  {
    id: `${idPrefix}_scope_sub_tree`,
    value: 'sub_tree',
    text: formatMessage(scopeValues.subTree),
  },
  {
    id: `${idPrefix}_scope_one_level`,
    value: 'one_level',
    text: formatMessage(scopeValues.oneLevel),
  },
  {
    id: `${idPrefix}_scope_base`,
    value: 'base',
    text: formatMessage(scopeValues.base),
  },
]

const UserSearchSettings: FunctionComponent<Props> = ({ intl: { formatMessage } }) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.userSearchSettings} />
      </h3>
    }
    description={
      <FormattedMessage
        {...sections.userSearchSettingsDescription}
        values={{
          learnMore: (
            <DocLink link='activeDirectoryProviderUserSearchDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <CuiFormField
      label={<FormattedMessage {...labels.baseDnForUsers} />}
      name='user_search.base_dn'
      helpText={
        <FormattedMessage
          {...labels.baseDnForUsersHelpText}
          values={{
            exampleBaseDn: <EuiCode>cn=users,dc=example,dc=com</EuiCode>,
          }}
        />
      }
    />

    <CuiFormField
      label={<FormattedMessage {...labels.scope} />}
      name='user_search.scope'
      component={EuiSelect}
      options={scopeOptions(formatMessage)}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.filter} />}
      name='user_search.filter'
      helpText={
        <FormattedMessage
          {...labels.activeDirectoryUserFilterHelpText}
          values={{
            sAMAccountName: <EuiCode>userPrincipalName</EuiCode>,
            userPrincipalName: <EuiCode>userPrincipalName</EuiCode>,
          }}
        />
      }
    />
  </EuiDescribedFormGroup>
)

export default injectIntl(UserSearchSettings)
