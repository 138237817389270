/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

type SerializedTag = string

export type DeserializedTag = {
  key: string
  value: string
}

export function serializeTag({ key, value }: DeserializedTag): SerializedTag {
  if (value) {
    const serializedValue = value.replace(/:/g, `\:`)

    return `${key}:${serializedValue}`
  }

  return key
}

export function deserializeTag(tag: SerializedTag): DeserializedTag {
  const index = tag.indexOf(`:`) // split only by first index
  const key = tag.slice(0, index)
  const value = tag.slice(index + 1)
  const deserializedValue = value.replace(/\/\/:/g, `:`)

  return { key, value: deserializedValue }
}
