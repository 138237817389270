/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiText } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { ClauseConnector } from '../../../../../types/instanceConfigurationTypes'

type Props = {
  level: 'inner' | 'outer'
  appendClause: (connector: ClauseConnector) => void
}

const ClauseActions: FunctionComponent<Props> = ({ appendClause, level }) => {
  const classes = [
    `topologyFilterAllocators-filterClause-actions`,
    `topologyFilterAllocators-filterClause-actions-${level}`,
  ].join(` `)

  return (
    <div className={classes}>
      <EuiText size='s' color='subdued'>
        {level === `inner` ? (
          <FormattedMessage
            id='instance-configuration-filter-allocators.add-inner-clause'
            defaultMessage='Add inner clause:'
          />
        ) : (
          <FormattedMessage
            id='instance-configuration-filter-allocators.add-outer-clause'
            defaultMessage='Add outer clause:'
          />
        )}
      </EuiText>

      <EuiText size='s'>
        <EuiLink onClick={() => appendClause(`and`)}>
          <FormattedMessage
            id='instance-configuration-filter-allocators.and'
            defaultMessage='And'
          />
        </EuiLink>
      </EuiText>

      <EuiText size='s' color='subdued'>
        <FormattedMessage
          id='instance-configuration-filter-allocators.separator'
          defaultMessage='|'
        />
      </EuiText>

      <EuiText size='s'>
        <EuiLink onClick={() => appendClause(`or`)}>
          <FormattedMessage id='instance-configuration-filter-allocators.or' defaultMessage='Or' />
        </EuiLink>
      </EuiText>
    </div>
  )
}

export default ClauseActions
