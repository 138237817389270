/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { isDeprecatedConfig } from '../../../lib/instanceConfigurations/instanceConfiguration'

import { messages } from './messages'

import type { InstanceConfiguration } from '../../../lib/api/v1/types'
import type { MessageDescriptor } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  instanceConfiguration?: InstanceConfiguration
}

export function getDeprecationWarningTitle({
  instanceConfiguration,
}: {
  instanceConfiguration: InstanceConfiguration
}): MessageDescriptor {
  const deprecated = instanceConfiguration.metadata!.deprecated! as string

  const match = deprecated.match(/^replaced:/)

  if (match) {
    return messages.deprecatedReplacedSummary
  }

  return messages.deprecatedInstanceTypesSummary
}

export function getDeprecationWarningBody({
  instanceConfiguration,
}: {
  instanceConfiguration: InstanceConfiguration
}): MessageDescriptor & { values?: Record<string, any> } {
  const deprecated = instanceConfiguration.metadata!.deprecated! as string

  const match = deprecated.match(/^replaced:(\w+)/)

  if (match) {
    return {
      ...messages.deprecatedReplacedDetails,
      values: {
        oldConfigId: <code>{instanceConfiguration.id}</code>,
        newConfigId: <code>{match[1]}</code>,
      },
    }
  }

  return messages.deprecatedInstanceTypesDetails
}

const DeprecationWarning: FunctionComponent<Props> = ({ instanceConfiguration }) => {
  if (instanceConfiguration == null) {
    return null
  }

  if (!isDeprecatedConfig(instanceConfiguration)) {
    return null
  }

  const title = <FormattedMessage {...getDeprecationWarningTitle({ instanceConfiguration })} />

  return (
    <Fragment>
      <EuiCallOut color='warning' title={title}>
        <FormattedMessage {...getDeprecationWarningBody({ instanceConfiguration })} />
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )
}

export default DeprecationWarning
