/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiLoadingSpinner } from '@elastic/eui'

import { CuiAlert } from '../../../../cui'
import StoredProcedureResult from '../../../User/StoredProcedureResult'

import EnsurePremiumButton from './EnsurePremiumButton'

import type { AsyncRequestState, SaasLevel } from '../../../../types'
import type { FunctionComponent } from 'react'

export type Props = {
  organizationId: string
  level: SaasLevel
  ensurePremiumRequest: AsyncRequestState
}

const ensureLevels: SaasLevel[] = [`standard`, `gold`, `platinum`, `enterprise`]

const EnsurePremium: FunctionComponent<Props> = ({
  organizationId,
  level,
  ensurePremiumRequest,
}) => (
  <Fragment>
    <EuiFlexGroup gutterSize='m' alignItems='center'>
      {ensureLevels.map((ensureLevel) => (
        <EuiFlexItem key={ensureLevel} grow={false}>
          <EnsurePremiumButton
            organizationId={organizationId}
            level={level}
            ensureLevel={ensureLevel}
            spin={ensurePremiumRequest.inProgress}
          />
        </EuiFlexItem>
      ))}

      {ensurePremiumRequest.inProgress && (
        <EuiFlexItem grow={false}>
          <EuiLoadingSpinner size='m' />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>

    {ensurePremiumRequest.error && (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='error'>{ensurePremiumRequest.error}</CuiAlert>
      </Fragment>
    )}

    <StoredProcedureResult procedureId='ensure_paying_level' />
  </Fragment>
)

export default EnsurePremium
