/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sumBy, groupBy, size, map } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiFormHelpText, EuiTextColor, EuiFlexGrid, EuiFlexItem } from '@elastic/eui'

import AllocatorZonesSummary from './AllocatorZonesSummary'
import AllocatorZoneStats from './AllocatorZoneStats'

import type { AllocatorSearchResult } from '../../types'
import type { FunctionComponent } from 'react'

type Props = {
  allocators?: AllocatorSearchResult[]
  isFiltered?: boolean
  isLoading?: boolean
}

const AllocatorCapacityOverviewPanel: FunctionComponent<Props> = ({
  allocators,
  isFiltered = false,
  isLoading = false,
}) => {
  const totalCapacity = sumBy(allocators, `memoryCapacity.total`)
  const usedCapacity = sumBy(allocators, `memoryCapacity.used`)

  const zones = groupBy(allocators, `zoneId`)
  const zoneCount = size(zones)

  const zoneStats = map(zones, (zoneAllocators, zoneId) => {
    const zoneTotalCapacity = sumBy(zoneAllocators, `memoryCapacity.total`)
    const zoneUsedCapacity = sumBy(zoneAllocators, `memoryCapacity.used`)

    return (
      <EuiFlexItem key={zoneId}>
        <AllocatorZoneStats
          zoneId={zoneId}
          totalCapacity={zoneTotalCapacity}
          availableCapacity={zoneTotalCapacity - zoneUsedCapacity}
          hasFilters={isFiltered}
        />
      </EuiFlexItem>
    )
  })

  return (
    <EuiPanel>
      {zoneCount === 1 ? (
        zoneStats
      ) : (
        <Fragment>
          <AllocatorZonesSummary
            totalCapacity={totalCapacity}
            availableCapacity={totalCapacity - usedCapacity}
            hasFilters={isFiltered}
            isLoading={isLoading}
          />

          {isLoading ? null : (
            <EuiFlexGrid columns={zoneCount % 2 === 0 ? 2 : 3}>{zoneStats}</EuiFlexGrid>
          )}
        </Fragment>
      )}

      {isFiltered && (
        <EuiFormHelpText style={{ textAlign: `right` }}>
          <EuiTextColor color='warning'>
            <FormattedMessage
              id='allocators-overview.filter-context-tip'
              defaultMessage='* Based on your current filters.'
            />
          </EuiTextColor>
        </EuiFormHelpText>
      )}
    </EuiPanel>
  )
}

export default AllocatorCapacityOverviewPanel
