/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { fetchVersion } from '@/actions/elasticStack'
import { getLowestSliderVersion } from '@/lib/stackDeployments'
import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors'

import {
  getEolStatus,
  getStackDeployment,
  getDeploymentHealth,
  fetchDeploymentHealthRequest,
  getVersionStack,
} from '../../../reducers'
import { getDeploymentHealthProblems } from '../../../lib/healthProblems'
import { withStackDeploymentRouteParams } from '../../StackDeploymentEditor'

import HealthStatus from './HealthStatus'

import type { Region, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, OwnProps } from './HealthStatus'

const mapStateToProps = (state: any, { stackDeploymentId }: OwnProps): StateProps => {
  const stackDeployment = getStackDeployment(state, stackDeploymentId)
  const eolStatus = getEolStatus(state)
  const health = getDeploymentHealth(state, stackDeploymentId!)
  const version = getVersion({ deployment: stackDeployment! })
  const deploymentHealthRequest = fetchDeploymentHealthRequest(state, stackDeploymentId)
  const lowestVersion = getLowestSliderVersion({ deployment: stackDeployment! })!
  const regionId = getRegionId({ deployment: stackDeployment! })!
  const versionStack = getVersionStack(state, regionId, lowestVersion)

  const [problems] = getDeploymentHealthProblems({
    deployment: stackDeployment!,
    eolStatus,
    health,
    deploymentHealthRequest,
  })

  return {
    stackDeployment,
    versionStack,
    lowestVersion,
    version,
    problems,
    deploymentHealthRequest,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchVersionStack: (version: string, region: Region | string) =>
    dispatch(fetchVersion(version, region)),
})

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Health status`, `component`)(HealthStatus)),
)
