/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  getSnapshotSettings,
  hasEnabledSnapshots,
} from '@/lib/stackDeployments/selectors/snapshots'
import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors'

import { CuiDuration } from '../../../../cui'

import type { FunctionComponent } from 'react'
import type { StackDeployment } from '../../../../types'

type Props = {
  deployment?: StackDeployment | null
}

const SnapshotFrequency: FunctionComponent<Props> = ({ deployment }) => {
  const emptyContent = <span>—</span>

  if (!deployment) {
    return emptyContent
  }

  const resource = getFirstEsClusterFromGet({ deployment })

  if (!resource) {
    return emptyContent
  }

  const snapshotSettings = getSnapshotSettings({ deployment })!

  if (!hasEnabledSnapshots({ resource })) {
    return (
      <FormattedMessage
        id='deployment-snapshots-status.snapshots-disabled'
        defaultMessage='Disabled'
      />
    )
  }

  const { ms } = snapshotSettings.interval

  return <CuiDuration milliseconds={ms} showTooltip={false} />
}

export default SnapshotFrequency
