/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText } from '@elastic/eui'

import { CuiAlert, CuiLink } from '../../../../../cui'
import Header from '../../../../../components/Header'
import { authenticationProvidersUrl } from '../../../../../lib/urlBuilder'
import { nativeRealmUsersCrumbs } from '../../../../../lib/crumbBuilder'

import UsersTable from './UsersTable'

import type { SecurityDeployment, User } from '../../../../../lib/api/v1/types'
import type { AsyncRequestState, RegionId } from '../../../../../types'
import type { FunctionComponent } from 'react'

export interface Props {
  fetchAllUsers: () => Promise<any>
  fetchAllUsersRequest: AsyncRequestState
  regionId: RegionId
  users: User[] | null
  currentUser: User
  securityCluster: SecurityDeployment
  match: {
    params: {
      regionId: RegionId
    }
  }
}

const Users: FunctionComponent<Props> = ({
  currentUser,
  fetchAllUsersRequest,
  regionId,
  users,
  securityCluster,
}) => {
  return (
    <Fragment>
      <Header
        name={<FormattedMessage id='management.title' defaultMessage='Native users' />}
        breadcrumbs={nativeRealmUsersCrumbs({ regionId })}
      />

      <EuiText>
        <p>
          <FormattedMessage
            id='management.description'
            defaultMessage='Create and manage native-realm users. For users with third-party authorization, configure an {link}.'
            values={{
              link: (
                <CuiLink to={authenticationProvidersUrl(regionId)}>
                  <FormattedMessage
                    id='management.description-link'
                    defaultMessage='identity provider'
                  />
                </CuiLink>
              ),
            }}
          />
        </p>
      </EuiText>

      <EuiSpacer />

      {renderContent()}
    </Fragment>
  )

  function renderContent() {
    const disabled =
      (securityCluster.status !== 'started' && securityCluster.status !== 'reconfiguring') ||
      !securityCluster.is_enabled

    if (!disabled && fetchAllUsersRequest.error) {
      return <CuiAlert details={fetchAllUsersRequest.error} type='error' />
    }

    return (
      <UsersTable disabled={disabled} regionId={regionId} users={users} currentUser={currentUser} />
    )
  }
}

export default Users
