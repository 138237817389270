/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import ConstructorLoggingSettingsButton from './ConstructorLoggingSettingsButton'
import ConstructorMaintenanceModeButton from './ConstructorMaintenanceModeButton'

import type { AsyncRequestState, Constructor } from '../../../types'
import type { FunctionComponent } from 'react'

type Props = {
  isElasticCloudEnterprise: boolean
  ctor: Constructor
  startMaintenanceMode: () => void
  startMaintenanceModeRequest: AsyncRequestState
  stopMaintenanceMode: () => void
  stopMaintenanceModeRequest: AsyncRequestState
}

const ConstructorTableRowActions: FunctionComponent<Props> = ({
  isElasticCloudEnterprise,
  ctor,
  ctor: { regionId, id },
  startMaintenanceMode,
  startMaintenanceModeRequest,
  stopMaintenanceMode,
  stopMaintenanceModeRequest,
}) => (
  <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
    <EuiFlexItem grow={false}>
      <ConstructorMaintenanceModeButton
        ctor={ctor}
        startMaintenanceMode={startMaintenanceMode}
        startMaintenanceModeRequest={startMaintenanceModeRequest}
        stopMaintenanceMode={stopMaintenanceMode}
        stopMaintenanceModeRequest={stopMaintenanceModeRequest}
        isElasticCloudEnterprise={isElasticCloudEnterprise}
      />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <ConstructorLoggingSettingsButton regionId={regionId} constructorId={id} />
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default ConstructorTableRowActions
