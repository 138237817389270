/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import CreateFirstDeploymentButton from '@/components/StackDeploymentSearch/CreateStackDeploymentButton/CreateFirstDeploymentButton'
import CreateDeploymentButton from '@/components/StackDeploymentSearch/CreateStackDeploymentButton/CreateDeploymentButton'

import type { FunctionComponent } from 'react'

interface Props {
  hideCreateClusterButton: boolean
  showCreateFirstDeployment?: boolean | null
  createDisabled: boolean
  disabled: boolean
  restartTrial: boolean
}

const CreateStackDeploymentButton: FunctionComponent<Props> = ({
  createDisabled,
  disabled,
  hideCreateClusterButton,
  restartTrial,
  showCreateFirstDeployment,
}) => {
  if (hideCreateClusterButton) {
    return null
  }

  if (showCreateFirstDeployment) {
    return (
      <CreateFirstDeploymentButton
        createDisabled={createDisabled}
        disabled={disabled}
        restartTrial={restartTrial}
      />
    )
  }

  if (!hideCreateClusterButton) {
    return (
      <CreateDeploymentButton
        data-test-id='create-deployment-button'
        key='create-deployment'
        disabled={disabled}
        createDisabled={createDisabled}
        restartTrial={restartTrial}
      />
    )
  }

  return null
}

export default CreateStackDeploymentButton
