/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'formik'

import { EuiAccordion, EuiCallOut, EuiHorizontalRule, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../../../../../cui'
import {
  createSamlConfigurationUrl,
  updateSamlConfigurationUrl,
} from '../../../../../../lib/api/v1/urls'
import { messages } from '../authProviderMessages'
import Permission from '../../../../../../lib/api/v1/permissions'
import { hasPermission } from '../../../../../../lib/requiresPermission'
import ApiRequestExample from '../../../../../../components/ApiRequestExample'

import GeneralSettings from './components/GeneralSettings'
import RoleMapping from './components/RoleMapping'
import SubmitButtons from './components/SubmitButtons'
import AdvancedConfiguration from './components/AdvancedConfiguration'
import IdentityProviderMetadataFile from './components/IdentityProviderMetadataFile'
import AttributeMappings from './components/AttributeMappings'
import SigningConfiguration from './components/SigningConfiguration'
import EncryptionConfiguration from './components/EncryptionConfiguration'
import SSLConfiguration from './components/SSLConfiguration'

import type {
  SamlAttributeSettings,
  SamlSecurityRealmRoleMappingRule,
} from '../../../../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../../../../types'
import type { FormikProps } from 'formik'
import type { FunctionComponent } from 'react'

import '../authProviders.scss'

export interface RoleMappingRule extends SamlSecurityRealmRoleMappingRule {
  index: number
}

export interface SamlProviderFormShape {
  form_mode: 'create' | 'edit'
  id: string
  order?: number
  name: string
  enabled?: boolean
  assertion_consumer_service_url: string
  logout_url: string
  identity_provider_entity_id: string
  service_provider_entity_id: string
  metadata_url: string
  hide_optional_attributes: boolean
  attribute_mappings: SamlAttributeSettings
  role_mappings: {
    default_roles: string[]
    rules: RoleMappingRule[]
  }
  advanced_settings_yaml: string
  signing_certificate_url?: string
  signing_certificate_url_password?: string
  signing_saml_messages?: string[]
  encryption_certificate_url?: string
  encryption_certificate_url_password?: string
  use_single_logout: boolean
  force_authn: boolean
  ssl_certificate_url: string
  ssl_certificate_url_truststore_type: 'jks' | 'PKCS12'
  ssl_certificate_url_truststore_password: string
}

type Props = FormikProps<SamlProviderFormShape> & {
  onCancel: () => void
  submitRequest: AsyncRequestState
  disabled: boolean
  regionId: string
  realmId?: string
}

function renderValidationCallOut(isValid, submitCount) {
  if (isValid || submitCount === 0) {
    return null
  }

  return (
    <Fragment>
      <EuiSpacer />
      <EuiCallOut
        data-test-id='saml-provider-validation-problems'
        color='warning'
        title={<FormattedMessage {...messages.thereAreProblems} />}
      >
        <FormattedMessage {...messages.pleaseCorrectProblems} />
      </EuiCallOut>
    </Fragment>
  )
}

const SamlProviderForm: FunctionComponent<Props> = ({
  handleSubmit,
  values,
  values: { form_mode, hide_optional_attributes },
  setFieldValue,
  onCancel,
  submitRequest,
  submitCount,
  isValid,
  disabled,
  regionId,
  realmId = '',
}) => {
  const requiredPermission =
    form_mode === 'create' ? Permission.createSamlConfiguration : Permission.updateSamlConfiguration

  return (
    <Form className='defaultLineHeight externalAuthProviderForm'>
      <GeneralSettings />

      <EuiHorizontalRule />

      <IdentityProviderMetadataFile />

      <EuiHorizontalRule />

      <AttributeMappings
        hideOptionalAttributes={hide_optional_attributes}
        setFieldValue={setFieldValue}
      />

      <EuiHorizontalRule />

      <RoleMapping />

      <EuiSpacer size='xl' />

      <EuiAccordion
        id='ldap-provider-form-advanced-settings-accordion'
        className='authProvider-advancedSettingsAccordion'
        buttonContent={<FormattedMessage {...messages.advancedSettings} />}
      >
        <div
          // This styling works around an issue where the ruler's margin throws
          // off the accordion's height measurements
          style={{ paddingTop: 16, marginBottom: -16 }}
        >
          <EuiHorizontalRule />

          <AdvancedConfiguration
            // We have to use setFieldValue because CuiCodeEditor doesn't call onChange with an event
            onChange={(value) => {
              setFieldValue('advanced_settings_yaml', value)
            }}
          />

          <EuiHorizontalRule />

          <SSLConfiguration />

          <EuiHorizontalRule />

          <SigningConfiguration />

          <EuiHorizontalRule />

          <EncryptionConfiguration />
        </div>
      </EuiAccordion>

      <EuiSpacer size='xl' />

      <SubmitButtons
        mode={form_mode}
        onSubmit={handleSubmit}
        inProgress={submitRequest && submitRequest.inProgress}
        onCancel={onCancel}
        disabled={!hasPermission(requiredPermission) || disabled}
      />

      {form_mode === 'create' ? (
        <ApiRequestExample
          method='POST'
          endpoint={createSamlConfigurationUrl({ regionId })}
          body={values}
        />
      ) : (
        <ApiRequestExample
          method='PUT'
          endpoint={updateSamlConfigurationUrl({ regionId, realmId })}
          body={values}
        />
      )}

      {renderValidationCallOut(isValid, submitCount)}

      {submitRequest && submitRequest.error && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='danger'>{submitRequest.error}</CuiAlert>
        </Fragment>
      )}
    </Form>
  )
}

export default SamlProviderForm
