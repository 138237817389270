/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getRegion } from '@/reducers'
import { getConfigForKey } from '@/selectors'
import { getNumberOfAvailableZones } from '@/lib/deployments/availabilityZones'
import { getRegionIdForCreate } from '@/lib/stackDeployments/selectors'

import TemplateTopologyElementRouter from './TemplateTopologyElementRouter'

import type { ReduxState } from '@/types'
import type { AllProps } from './TemplateTopologyElement'
import type { DeploymentCreateRequest } from '@/lib/api/v1/types'

type ConnectedPropKeys = 'maxInstanceCountForEnvironment' | 'maxZoneCount'
type StateProps = Pick<AllProps, ConnectedPropKeys>

type ConsumerProps = Omit<AllProps, ConnectedPropKeys>

const mapStateToProps = (
  state: ReduxState,
  { topologyElement, deployment, instanceConfigurations }: ConsumerProps,
): StateProps => {
  const regionId = getRegionIdForCreate({ deployment: deployment as DeploymentCreateRequest })
  const region = getRegion(state, regionId!)
  const maxZoneCount = getNumberOfAvailableZones({
    region,
    instanceConfiguration: instanceConfigurations.find(
      ({ id }) => id === topologyElement.instance_configuration_id,
    ),
  })

  return {
    maxZoneCount,
    maxInstanceCountForEnvironment: getConfigForKey(state, `MAX_INSTANCE_COUNT`),
  }
}

export default connect<StateProps, null, ConsumerProps>(mapStateToProps)(
  TemplateTopologyElementRouter,
)
