/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { chunk } from 'lodash'

function chunkFingerprint(fingerprint: string = '', chunkSize: number = 2) {
  return chunk(fingerprint, chunkSize)
}

function wrapWithSpacers(chunks) {
  return (
    <span style={{ marginLeft: `-2px`, marginRight: `-2px` }}>
      {chunks.map((x) => (
        <span style={{ paddingLeft: `2px`, paddingRight: `2px` }}>{x}</span>
      ))}
    </span>
  )
}

export function getFullDisplayFingerprint(fingerprint: string | undefined) {
  return wrapWithSpacers(chunkFingerprint(fingerprint))
}

export function getShortDisplayFingerprint(fingerprint: string | undefined) {
  return wrapWithSpacers(chunkFingerprint(fingerprint).slice(0, 3))
}
