/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual } from 'lodash'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { getShieldUsers } from '@/lib/stackDeployments/selectors/metadata'

import { CuiTimeAgo, CuiTable } from '../../../cui'
import { atPath } from '../../../lib/objects'

import DeleteTempUser from './DeleteTempUser'
import AddTempUser from './AddTempUser'

import type { CuiTableColumn } from '../../../cui'
import type {
  AsyncRequestState,
  RootConfig,
  UserState,
  FoundUser,
  StackDeployment,
  Action,
} from '../../../types'

export type StateProps = {
  newTempShieldUser: FoundUser | null
  root: RootConfig
  user: UserState
  setDeploymentResourceMetadataRequest: AsyncRequestState
}

export type DispatchProps = {
  fetchUser: (root: RootConfig) => void
  resetSetDeploymentResourceMetadata: (deployment: StackDeployment) => void
  resetTempShieldUser: () => void
  updateMetadata: (params: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta: { [key: string]: any }
    then?: () => Action
  }) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
}

type Props = StateProps & DispatchProps & ConsumerProps

class TempShieldUsers extends Component<Props> {
  componentDidMount() {
    const { root, fetchUser } = this.props
    fetchUser(root)
  }

  componentDidUpdate({ deployment: prevDeployment }: Props) {
    const { setDeploymentResourceMetadataRequest, resetSetDeploymentResourceMetadata, deployment } =
      this.props

    const prevUsers = getShieldUsers({ deployment: prevDeployment })
    const users = getShieldUsers({ deployment })

    if (!isEqual(prevUsers, users) && !setDeploymentResourceMetadataRequest.error) {
      resetSetDeploymentResourceMetadata(deployment)
    }
  }

  componentWillUnmount() {
    const { resetTempShieldUser } = this.props
    resetTempShieldUser()
  }

  render() {
    const {
      deployment,
      updateMetadata,
      setDeploymentResourceMetadataRequest,
      newTempShieldUser,
      user,
    } = this.props
    const users = getShieldUsers({ deployment })

    const columns: Array<CuiTableColumn<FoundUser>> = [
      {
        label: (
          <FormattedMessage id='cluster-manage-temp-shield-users.name' defaultMessage='Name' />
        ),
        render: atPath(`username`),
        sortKey: `username`,
      },
      {
        label: (
          <FormattedMessage
            id='cluster-manage-temp-shield-users.expiration'
            defaultMessage='Expiration'
          />
        ),
        className: 'tempShieldUsers-expiration',
        render: (row) =>
          row.valid_until ? (
            <CuiTimeAgo date={row.valid_until} longTime={true} />
          ) : (
            <FormattedMessage
              id='cluster-manage-temp-shield-users.expiration-never'
              defaultMessage='Never'
            />
          ),
        sortKey: `valid_until`,
      },
      {
        label: (
          <FormattedMessage
            id='cluster-manage-temp-shield-users.actions'
            defaultMessage='Actions'
          />
        ),
        render: this.renderDelete.bind(this),
      },
    ]

    return (
      <div className='tempShieldUsers-table'>
        <CuiTable<FoundUser>
          fullWidth={false}
          rows={users}
          getRowId={atPath(`username`)}
          columns={columns}
        />

        <AddTempUser
          deployment={deployment}
          user={user}
          newTempShieldUser={newTempShieldUser}
          updateMetadata={updateMetadata}
          setDeploymentResourceMetadataRequest={setDeploymentResourceMetadataRequest}
        />
      </div>
    )
  }

  renderDelete(user: FoundUser) {
    const { deployment, setDeploymentResourceMetadataRequest, updateMetadata } = this.props

    return (
      <DeleteTempUser
        user={user}
        deployment={deployment}
        setDeploymentResourceMetadataRequest={setDeploymentResourceMetadataRequest}
        updateMetadata={updateMetadata}
      />
    )
  }
}

export default TempShieldUsers
