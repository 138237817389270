/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiSpacer,
  EuiTitle,
  EuiSwitch,
} from '@elastic/eui'

import { getPlatform } from '@/lib/platform'
import { CuiAccordion } from '@/cui'

import SetupSnapshots from '../SetupDeployment/SetupSnapshots'

import SettingsSummary from './SettingsSummary'
import AdvancedSettingsContent from './AdvancedSettingsContent'

import type { PlatformId } from '@/lib/platform'
import type { Region, VersionNumber, StackDeploymentCreateRequest, ClusterSnapshot } from '@/types'
import type {
  Region as AvailableRegion,
  ElasticsearchClusterSettings,
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
  DeploymentSearchResponse,
} from '@/lib/api/v1/types'
import type { DeepPartial } from '@/lib/ts-essentials'
import type { RegionState } from '@/reducers/providers'

export interface Props {
  region: Region
  version: string | null
  getRegionsByProvider: (provider: string) => RegionState[] | null
  availablePlatforms: PlatformId[]
  restoreFromSnapshot: boolean
  trialMaxedOut?: boolean
  onChangePlatform: (platform: string) => void
  showRegion: boolean
  onChangeRegion: (regionId: string) => void
  availableRegions: AvailableRegion[] | null
  availableVersions: string[]
  whitelistedVersions: string[]
  setVersion: (version: VersionNumber) => void
  editorState: StackDeploymentCreateRequest
  onChangeSnapshot: (value?: ClusterSnapshot | null) => void
  setEsSettings: (settings: DeepPartial<ElasticsearchClusterSettings> | null) => void
  showPayingCustomerSections: boolean
  globalDeploymentTemplates: GlobalDeploymentTemplateInfo[]
  deploymentTemplate: DeploymentTemplateInfoV2
  onChangeTemplate: (template: DeploymentTemplateInfoV2) => void
  isUserconsole: boolean
  hasDefaultSnapshotRepository: boolean
  name?: string
  setDeploymentName: (name: string) => void
  disabled?: boolean
  onUpdateSnapshotSource: (source?: DeploymentSearchResponse | null) => void
  deploymentTemplates?: DeploymentTemplateInfoV2[] | null
  selectedSnapshotDeployment: DeploymentSearchResponse | null
  showSettings: boolean
}

type State = {
  showRestoreFromSnapshotDetails: boolean
  showSelectSnapshotRepo: boolean
  isAdvancedSettingsOpen: boolean
  initialIsOpen: boolean
}

class AdvancedSettings extends Component<Props, State> {
  state = {
    showRestoreFromSnapshotDetails: this.props.restoreFromSnapshot || false,
    showSelectSnapshotRepo: false,
    initialIsOpen: false,
    isAdvancedSettingsOpen: false,
  }

  render() {
    const { region, version, isUserconsole, showSettings } = this.props
    const { showRestoreFromSnapshotDetails } = this.state

    if (!version || !region) {
      return <EuiLoadingContent />
    }

    if (isUserconsole && !showSettings) {
      return this.renderAdvancedSettingsAccordion()
    }

    return (
      <Fragment>
        {this.renderTitle()}
        {showRestoreFromSnapshotDetails
          ? this.renderRestoreFromSnapshot()
          : this.renderAdvancedSettings()}
      </Fragment>
    )
  }

  renderTitle = () => {
    const { showRestoreFromSnapshotDetails } = this.state
    const { showPayingCustomerSections } = this.props
    return (
      <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h2>
              {showRestoreFromSnapshotDetails ? (
                <FormattedMessage
                  defaultMessage='Restore data from snapshot'
                  id='stack-deployment-configure.restore-from-snapshot'
                />
              ) : (
                <FormattedMessage id='deployment-settings.title' defaultMessage='Settings' />
              )}
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        {showPayingCustomerSections && (
          <EuiFlexItem grow={false}>
            <EuiSwitch
              label={
                <FormattedMessage
                  defaultMessage='Restore snapshot data'
                  id='restore-from-snapshot'
                />
              }
              checked={showRestoreFromSnapshotDetails}
              onChange={({ target: { checked } }) => {
                if (checked) {
                  this.setState({ showRestoreFromSnapshotDetails: true })
                } else {
                  this.onCancelPickingSnapshot()
                }
              }}
              data-test-id='restore-from-snapshot'
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  renderAdvancedSettingsAccordion = () => {
    // this accordion is rendered only for a trial user's 1st deployment
    const { initialIsOpen } = this.state

    const buttonContent = this.renderAdvancedSettingsHeaderAction()

    const extraAction = this.renderAdvancedSettingsHeaderTitle()

    return (
      <div data-test-id='advanced-settings-accordion'>
        <CuiAccordion
          id='advancedSettings'
          data-test-id='advancedSettings-accordion'
          className='advancedSettings-accordion'
          onToggle={() =>
            this.setState((prevState) => ({
              ...prevState,
              isAdvancedSettingsOpen: !prevState.isAdvancedSettingsOpen,
            }))
          }
          initialIsOpen={initialIsOpen}
          buttonContent={buttonContent}
          arrowDisplay='none'
          extraAction={extraAction}
        >
          {this.renderAdvancedSettings()}
        </CuiAccordion>
      </div>
    )
  }

  renderRestoreFromSnapshot = () => {
    const {
      region,
      version,
      trialMaxedOut,
      showRegion,
      editorState,
      onUpdateSnapshotSource,
      onChangeSnapshot,
      setEsSettings,
      isUserconsole,
      availableVersions,
      whitelistedVersions,
      setVersion,
      restoreFromSnapshot,
      selectedSnapshotDeployment,
    } = this.props

    return (
      <div data-test-id='restore-snapshot-section'>
        <SetupSnapshots
          selectedSnapshotDeployment={selectedSnapshotDeployment}
          cancelRestoreFromSnapshot={() => this.setState({ showRestoreFromSnapshotDetails: false })}
          editorState={editorState}
          version={version!}
          showRegion={showRegion}
          onUpdateSnapshotSource={onUpdateSnapshotSource}
          onChangeSnapshot={onChangeSnapshot}
          setEsSettings={setEsSettings}
          regionId={region.id}
          disabled={Boolean(trialMaxedOut)}
          availableVersions={availableVersions}
          whitelistedVersions={whitelistedVersions}
          setVersion={setVersion}
          restoreFromSnapshot={restoreFromSnapshot}
          isUserconsole={isUserconsole}
        />
        {!isUserconsole && restoreFromSnapshot ? (
          <Fragment>
            <EuiSpacer />
            <EuiTitle size='s'>
              <h2>
                <FormattedMessage id='deployment-settings.title' defaultMessage='Settings' />
              </h2>
            </EuiTitle>
            {this.renderAdvancedSettings()}
          </Fragment>
        ) : null}
      </div>
    )
  }

  renderAdvancedSettings = () => {
    const {
      region,
      version,
      availablePlatforms,
      trialMaxedOut,
      onChangePlatform,
      showRegion,
      onChangeRegion,
      availableRegions,
      availableVersions,
      whitelistedVersions,
      setVersion,
      editorState,
      setEsSettings,
      showPayingCustomerSections,
      globalDeploymentTemplates,
      onChangeTemplate,
      name,
      setDeploymentName,
      hasDefaultSnapshotRepository,
      restoreFromSnapshot,
      isUserconsole,
      disabled,
      deploymentTemplates,
    } = this.props
    const { showRestoreFromSnapshotDetails } = this.state
    const platform = getPlatform(region.id)

    return (
      <AdvancedSettingsContent
        disabledControls={disabled}
        showRegion={showRegion}
        regionId={region.id}
        version={version}
        availablePlatforms={availablePlatforms}
        restoreFromSnapshot={restoreFromSnapshot}
        trialMaxedOut={trialMaxedOut}
        onChangePlatform={onChangePlatform}
        onChangeRegion={onChangeRegion}
        availableRegions={availableRegions}
        availableVersions={availableVersions}
        whitelistedVersions={whitelistedVersions}
        setVersion={setVersion}
        editorState={editorState}
        setEsSettings={setEsSettings}
        showPayingCustomerSections={showPayingCustomerSections}
        globalDeploymentTemplates={globalDeploymentTemplates}
        onChangeTemplate={onChangeTemplate}
        platform={platform}
        name={name}
        setDeploymentName={setDeploymentName}
        hasDefaultSnapshotRepository={hasDefaultSnapshotRepository}
        showRestoreFromSnapshotDetails={showRestoreFromSnapshotDetails}
        isAdminconsole={!isUserconsole}
        deploymentTemplates={deploymentTemplates}
      />
    )
  }

  renderAdvancedSettingsHeaderAction = () => {
    const { isAdvancedSettingsOpen } = this.state

    if (isAdvancedSettingsOpen) {
      return <FormattedMessage id='accordion-button.collapse' defaultMessage='Hide' />
    }

    return <FormattedMessage id='accordion-button.expand' defaultMessage='Edit settings' />
  }

  renderAdvancedSettingsHeaderTitle = () => {
    const { region, version, getRegionsByProvider, availablePlatforms, editorState } = this.props
    const { deploymentTemplate } = editorState
    const { isAdvancedSettingsOpen } = this.state

    if (isAdvancedSettingsOpen) {
      return (
        <EuiTitle size='s'>
          <h2>
            <FormattedMessage id='deployment-settings.title' defaultMessage='Settings' />
          </h2>
        </EuiTitle>
      )
    }

    return (
      <SettingsSummary
        deploymentTemplate={deploymentTemplate}
        region={region}
        version={version}
        getRegionsByProvider={getRegionsByProvider}
        availablePlatforms={availablePlatforms}
      />
    )
  }

  onCancelPickingSnapshot = () => {
    const { onUpdateSnapshotSource } = this.props

    onUpdateSnapshotSource(null)

    this.setState({
      showRestoreFromSnapshotDetails: false,
      initialIsOpen: true,
      isAdvancedSettingsOpen: true,
    })
  }
}

export default AdvancedSettings
