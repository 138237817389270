/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiHealth, EuiLink } from '@elastic/eui'

import { colorForDeployment } from '@/lib/theme'

import type { FunctionComponent } from 'react'

export interface Props {
  id: string | null
  name: string
  isDeleted: boolean
  onClick(): void
}

const DeploymentListNameCell: FunctionComponent<Props> = ({ id, name, isDeleted, onClick }) => (
  <EuiHealth color={colorForDeployment.get(`${id}_${name}`)}>
    <EuiLink disabled={!id} onClick={onClick}>
      <span data-test-id='name'>{name.replace('(deleted)', '')}</span>{' '}
      {isDeleted && (
        <EuiBadge color='danger' style={{ marginLeft: 5 }}>
          <FormattedMessage id='billing-usage.deleted-deployment-label' defaultMessage='Deleted' />
        </EuiBadge>
      )}
    </EuiLink>
  </EuiHealth>
)

export default DeploymentListNameCell
