/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTitle } from '@elastic/eui'

import SnapshotRepository from '../components/Editor/SnapshotRepository'
import Monitoring from '../components/Editor/Monitoring'

import type { StepDefinition, StepProps } from './deploymentTemplateWizardTypes'
import type { FunctionComponent } from 'react'

const messages = defineMessages({
  title: {
    id: `configure-template-stack-features-step.step-title`,
    defaultMessage: `Stack features`,
  },
})

const ConfigureTemplateStackFeaturesStep: FunctionComponent<StepProps> = ({
  template,
  updateDeploymentTemplate,
  regionId,
}) => (
  <div data-test-id='deploymentTemplateWizard-stackFeaturesStep'>
    <EuiTitle>
      <h3>
        <FormattedMessage
          id='configure-template-stack-features-step.title'
          defaultMessage='Stack features'
        />
      </h3>
    </EuiTitle>

    <EuiSpacer size='m' />

    <SnapshotRepository
      updateDeploymentTemplate={updateDeploymentTemplate}
      template={template}
      regionId={regionId}
    />

    <EuiSpacer size='xxl' />

    <Monitoring
      updateDeploymentTemplate={updateDeploymentTemplate}
      template={template}
      regionId={regionId}
    />

    <EuiSpacer size='l' />
  </div>
)

export const stackFeaturesStepDefinition: StepDefinition = {
  title: messages.title,
  ConfigureStep: ConfigureTemplateStackFeaturesStep,
}
