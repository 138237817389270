/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'

import { CuiLink, CuiTable } from '../../cui'
import ContainerSetCounts from '../ContainerSetCounts'
import { containerSetUrl } from '../../lib/urlBuilder'

import type { CuiTableColumn } from '../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { ContainerSetDetails } from '../../lib/api/v1/types'

type Props = {
  intl: IntlShape
  regionId: string
  containerSets: ContainerSetDetails[]
  totalCount: number
  initialLoading: boolean
}

const messages = defineMessages({
  containerSetId: {
    id: `container-sets-table.container-set-id`,
    defaultMessage: `Container set`,
  },
  createdCount: {
    id: `container-sets-table.containers-created-count`,
    defaultMessage: `Containers`,
  },
  startedCount: {
    id: `container-sets-table.containers-started-count`,
    defaultMessage: `Containers started`,
  },
  runningCount: {
    id: `container-sets-table.container-instances-running-count`,
    defaultMessage: `Running container instances`,
  },
})

const ContainerSetsTable: FunctionComponent<Props> = ({
  intl,
  regionId,
  containerSets,
  totalCount,
  initialLoading,
}) => {
  const { formatMessage } = intl

  const idColumn: CuiTableColumn<ContainerSetDetails> = {
    label: formatMessage(messages.containerSetId),
    render: (containerSet) => (
      <CuiLink to={containerSetUrl(regionId, containerSet.container_set_id)}>
        {containerSet.container_set_id}
      </CuiLink>
    ),
    sortKey: `container_set_id`,
  }

  const columns: Array<CuiTableColumn<ContainerSetDetails>> = [
    idColumn,

    {
      label: formatMessage(messages.createdCount),
      render: (containerSet) => (
        <div style={{ width: `100%` }}>
          <ContainerSetCounts containerSet={containerSet} />
        </div>
      ),
      sortKey: `container_instances_running_count`,
      textOnly: false,
      width: `250px`,
    },
  ]

  return (
    <CuiTable<ContainerSetDetails>
      rows={containerSets}
      columns={columns}
      initialLoading={initialLoading}
      getRowId={(containerSet) => containerSet.container_set_id}
      totalCount={totalCount}
      pageSize={50}
      initialSort={idColumn}
    />
  )
}

export default injectIntl(ContainerSetsTable)
