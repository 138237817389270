/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import cx from 'classnames'

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverTitle,
  EuiLink,
  EuiIcon,
  EuiText,
} from '@elastic/eui'

import { CuiTable, CuiSliderLogo } from '../../../../cui'
import { topologyViewInstanceConfigurationUrl } from '../../../../lib/urlBuilder'
import { isDeprecatedConfig } from '../../../../lib/instanceConfigurations/instanceConfiguration'
import { getDeprecationWarningTitle, getDeprecationWarningBody } from '../DeprecationWarning'
import {
  getPrimarySliderTypeForInstanceConfiguration,
  getSliderPrettyName,
  getSliderTypesForInstanceConfiguration,
} from '../../../../lib/sliders'

import type { CuiTableColumn } from '../../../../cui'
import type { IntlShape } from 'react-intl'
import type { InstanceConfiguration } from '../../../../lib/api/v1/types'

export type Props = {
  intl: IntlShape
  instanceConfigurations?: InstanceConfiguration[]
  regionId: string
  initialLoading: boolean
}

type State = {
  [popoverId: string]: boolean
}

class InstanceConfigurationsTable extends React.Component<Props, State> {
  state: State = {}

  render() {
    const { intl, instanceConfigurations, regionId, initialLoading } = this.props

    const anyDeprecated = instanceConfigurations && instanceConfigurations.some(isDeprecatedConfig)

    const columns: Array<CuiTableColumn<InstanceConfiguration>> = [
      {
        verticalAlign: `top`,
        label: (
          <FormattedMessage
            id='topologyinstanceConfigurations.table.nameHeader'
            defaultMessage='Name'
          />
        ),
        render: (instanceConfiguration) => (
          <Fragment>
            <Link to={topologyViewInstanceConfigurationUrl(regionId, instanceConfiguration.id!)}>
              {instanceConfiguration.name}
            </Link>
            <div>{instanceConfiguration.description}</div>
          </Fragment>
        ),
        sortKey: `name`,
      },
      {
        verticalAlign: `top`,
        label: (
          <FormattedMessage
            id='topologyinstanceConfigurations.table.nodeTypesHeader'
            defaultMessage='Instance types'
          />
        ),
        render: (instanceConfiguration) => {
          const sliderTypes = getSliderTypesForInstanceConfiguration(instanceConfiguration)
            .map((sliderType) => intl.formatMessage(getSliderPrettyName(sliderType)))
            .join(`, `)

          return (
            <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
              <EuiFlexItem grow={false}>
                <CuiSliderLogo
                  {...getPrimarySliderTypeForInstanceConfiguration(instanceConfiguration)}
                />
              </EuiFlexItem>

              <EuiFlexItem>{sliderTypes}</EuiFlexItem>
            </EuiFlexGroup>
          )
        },
      },
      {
        width: `8rem`,
        verticalAlign: `top`,
        label: (
          <FormattedMessage
            id='topologyinstanceConfigurations.table.createdByHeader'
            defaultMessage='Created by'
          />
        ),
        render: (instanceConfiguration) => (
          <EuiBadge color='hollow'>
            {instanceConfiguration.system_owned ? (
              <FormattedMessage
                id='topologyinstanceConfigurations.table.system'
                defaultMessage='System'
              />
            ) : (
              <FormattedMessage
                id='topologyinstanceConfigurations.table.user'
                defaultMessage='User'
              />
            )}
          </EuiBadge>
        ),
        sortKey: `system_owned`,
      },
    ]

    if (anyDeprecated) {
      columns.push({
        width: `10rem`,
        verticalAlign: `top`,
        label: (
          <FormattedMessage
            id='topologyinstanceConfigurations.table.additionalInformationHeader'
            defaultMessage='Additional information'
          />
        ),
        render: (instanceConfiguration) => {
          const isDeprecated = isDeprecatedConfig(instanceConfiguration)

          if (!isDeprecated) {
            return null
          }

          const popoverId = `${instanceConfiguration.id}-deprecatedPopover`

          return (
            <EuiPopover
              id={popoverId}
              button={
                <div style={{ maxWidth: `8rem` }}>
                  <EuiLink onClick={() => this.setState({ [popoverId]: !this.state[popoverId] })}>
                    <FormattedMessage
                      id='topologyinstanceConfigurations.table.deprecatedTrigger'
                      defaultMessage='Deprecated configuration'
                    />
                    {` `}
                    <EuiIcon type='iInCircle' />
                  </EuiLink>
                </div>
              }
              isOpen={this.state[popoverId]}
              closePopover={() => this.setState({ [popoverId]: false })}
              anchorPosition='downRight'
            >
              <EuiPopoverTitle>
                <FormattedMessage {...getDeprecationWarningTitle({ instanceConfiguration })} />
              </EuiPopoverTitle>
              <div style={{ maxWidth: `22rem` }}>
                <EuiText>
                  <FormattedMessage {...getDeprecationWarningBody({ instanceConfiguration })} />
                </EuiText>
              </div>
            </EuiPopover>
          )
        },
      })
    }

    return (
      <CuiTable
        rows={instanceConfigurations}
        columns={columns}
        getRowId={(instanceConfiguration) => instanceConfiguration.id!}
        rowClass={makeRowClass}
        initialLoading={initialLoading}
      />
    )
  }
}

export default injectIntl(InstanceConfigurationsTable)

function makeRowClass(instanceConfiguration: InstanceConfiguration): string | undefined {
  const classes = {
    'instanceConfigurationList-tableRow': true,
    'background-warning': isDeprecatedConfig(instanceConfiguration),
  }

  return isEmpty(classes) ? undefined : cx(classes)
}
