/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiDescribedFormGroup, EuiSpacer } from '@elastic/eui'

import history from '@/lib/history'
import { createDeploymentTrustRelationshipUrl } from '@/lib/urlBuilder'

import DocLink from '../../../../components/DocLink'
import EnableCrossClusterReplicationCallout from '../../../../components/CrossClusterReplication/EnableCrossClusterReplicationCallout'
import DeploymentTrustRelationshipTable from '../../../../components/DeploymentTrustRelationships/DeploymentTrustRelationshipTable'

import type { AllProps } from './types'

class DeploymentTrustManagement extends React.Component<AllProps> {
  componentDidMount(): void {
    this.props.fetchCurrentAccount()
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
  }

  render(): JSX.Element {
    const { deployment } = this.props

    const title = (
      <h3>
        <FormattedMessage
          id='deploymentTrustManagement.ess.title'
          defaultMessage='Trusted environments'
        />
      </h3>
    )

    const description = (
      <Fragment>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.ess.description'
            defaultMessage='You can configure trust between deployments in separate organizations or within the same organization. Bi-directional trust is required for cross-cluster search and cross-cluster replication. {docLink}'
            values={{
              docLink: (
                <DocLink link='trustManagement'>
                  <FormattedMessage
                    id='deploymentTrustManagement.description-doclink'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        </p>
      </Fragment>
    )
    return (
      <Fragment>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={title}
          description={description}
          data-test-id='trust-management'
        >
          <div>
            <EnableCrossClusterReplicationCallout deployment={deployment} />
            {this.renderAddButton()}
            <EuiSpacer />
            {this.renderDetails()}
          </div>
        </EuiDescribedFormGroup>
      </Fragment>
    )
  }

  renderAddButton(): JSX.Element | null {
    const { deployment } = this.props

    return (
      <EuiButton
        size='s'
        onClick={() => history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))}
        data-test-id='add-deployment-trust-button'
      >
        <FormattedMessage
          id='deploymentTrustManagement.ess.addTrustButton'
          defaultMessage='Add trusted environment'
        />
      </EuiButton>
    )
  }

  renderDetails(): JSX.Element {
    const { deployment } = this.props

    return <DeploymentTrustRelationshipTable deployment={deployment} />
  }
}

export default DeploymentTrustManagement
