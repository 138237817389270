/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useCallback, useState } from 'react'

import euiVars from '@elastic/eui/dist/eui_theme_light.json'

const ChatIframe = ({ chatUrl, handleWindowMessage }) => {
  const initialStyle = {
    position: 'fixed' as const,
    bottom: '30px',
    right: '30px',
    display: 'block',
    zIndex: euiVars.euiZMaskBelowHeader,
  }
  const [chatRef, setChatRef] = useState(null)
  const onRefChange = useCallback((node) => {
    setChatRef(node)
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      if (!chatRef) {
        return
      }

      handleWindowMessage(event, chatRef)
    }

    if (chatRef) {
      window.addEventListener('message', handleMessage)
      return () => window.removeEventListener('message', handleMessage)
    }
  }, [chatRef, handleWindowMessage, chatUrl])

  return <iframe data-test-id='drift-chat' style={initialStyle} ref={onRefChange} src={chatUrl} />
}

export default ChatIframe
