/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { searchRunnersUrl } from '../../../../lib/api/v1/urls'
import asyncRequest from '../../../../actions/asyncRequests'
import { SEARCH_RUNNERS } from '../../../../constants/actions'

import type { RunnerOverview, SearchRequest } from '../../../../lib/api/v1/types'
import type { RegionId } from '../../../../types'

export function searchRunners(
  queryId: string,
  regionId: RegionId,
  payload: SearchRequest = getQueryPayload(),
) {
  const url = searchRunnersUrl({ regionId })

  return asyncRequest<RunnerOverview>({
    type: SEARCH_RUNNERS,
    method: `POST`,
    url,
    payload,
    meta: { regionId, queryId },
    crumbs: [regionId, queryId],
  })
}

function getQueryPayload(): SearchRequest {
  return {
    size: 5000,
  }
}
