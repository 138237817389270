/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFormLabel,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiCallOut,
} from '@elastic/eui'

import { getObservabilityDeploymentId } from '../../../../../lib/stackDeployments/selectors'

import type {
  DeploymentObservabilitySettings,
  DeploymentSearchResponse,
  DeploymentsSearchResponse,
} from '@/lib/api/v1/types'
import type { AsyncRequestState, RegionId } from '@/types'
import type { ReactNode } from 'react'

export type OwnProps = {
  observability: DeploymentObservabilitySettings
  regionId: RegionId
}

export type DispatchProps = {
  searchMonitoringDeployment: (deploymentId: string, region: RegionId) => void
}

export type StateProps = {
  searchMonitoringDeploymentRequest: AsyncRequestState
  searchResults?: DeploymentsSearchResponse | null
}

export type Props = OwnProps & DispatchProps & StateProps

export default class MonitoringDeployment extends React.Component<Props> {
  componentDidMount() {
    const { searchMonitoringDeployment, observability, regionId } = this.props

    const monitoringDeploymentId = getObservabilityDeploymentId({ observability })

    if (monitoringDeploymentId) {
      searchMonitoringDeployment(monitoringDeploymentId, regionId)
    }
  }

  render() {
    const { searchResults, searchMonitoringDeploymentRequest } = this.props

    if (searchMonitoringDeploymentRequest.inProgress || !searchResults) {
      return <EuiLoadingSpinner />
    }

    const monitoringDeployment = searchResults.deployments[0]

    return (
      <Fragment>
        <EuiSpacer />
        <EuiTitle size='s'>
          <h4>
            <FormattedMessage
              id='deployment-template-view.stack-features.monitoring-title'
              defaultMessage='Monitoring'
            />
          </h4>
        </EuiTitle>
        <EuiSpacer size='s' />
        {monitoringDeployment && this.renderMonitoringDeployment(monitoringDeployment)}
        {!monitoringDeployment && this.renderUnknownMonitoringDeployment()}
      </Fragment>
    )
  }

  renderUnknownMonitoringDeployment(): ReactNode {
    const { observability } = this.props

    const monitoringDeploymentId = getObservabilityDeploymentId({ observability })

    return (
      <EuiCallOut
        color='warning'
        data-test-subj='deployment-template-view.stack-features-unknown-monitoring'
      >
        <FormattedMessage
          id='deployment-template-view.stack-features.unknown-monitoring-deployment'
          defaultMessage='Could not find monitoring deployment with ID: {id}'
          values={{ id: monitoringDeploymentId }}
        />
      </EuiCallOut>
    )
  }

  renderMonitoringDeployment(deployment: DeploymentSearchResponse): ReactNode {
    return (
      <Fragment>
        <EuiFormLabel>
          <FormattedMessage
            id='deployment-template-view.stack-features.monitoring-deployment'
            defaultMessage='Monitoring deployment'
          />
        </EuiFormLabel>
        <EuiText data-test-subj='deployment-template-view.stack-features.monitoring.display-name'>
          {deployment.name}
        </EuiText>
      </Fragment>
    )
  }
}
