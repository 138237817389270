/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { EXECUTE_SLM_POLICY } from '../../constants/actions'
import { putDeploymentResourceProxyRequestsUrl } from '../../lib/api/v1/urls'
import { getFirstEsRefId } from '../../lib/stackDeployments/selectors'

import type { Action, StackDeployment, ThunkAction } from '../../types'

const POLICY_NAME = `cloud-snapshot-policy`
const headers = {
  'X-Management-Request': true,
  Accept: `*/*`,
}

export function executeSlmPolicy({ deployment }: { deployment: StackDeployment }): ThunkAction {
  return (dispatch) => {
    const { id: deploymentId } = deployment
    const refId = getFirstEsRefId({ deployment })

    const url = putDeploymentResourceProxyRequestsUrl({
      deploymentId,
      resourceKind: 'elasticsearch',
      refId: refId!,
      proxyPath: `_slm/policy/${POLICY_NAME}/_execute`,
    })

    const req = dispatch(
      asyncRequest({
        type: EXECUTE_SLM_POLICY,
        method: `PUT`,
        url,
        meta: { deployment },
        crumbs: [deploymentId],
        requestSettings: {
          request: {
            headers,
          },
        },
      }),
    )

    return req
  }
}

export function resetExecuteSlmPolicyRequest(deploymentId: string): Action<'RESET_ASYNC_REQUEST'> {
  return resetAsyncRequest(EXECUTE_SLM_POLICY, [deploymentId])
}
