/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { RunnerBuildInfo } from '../lib/api/v1/types'

interface Props {
  build?: RunnerBuildInfo
}

const RunnerBuild: FunctionComponent<Props> = ({ build = { commit_hash: '-', version: '-' } }) => (
  <EuiFlexGroup gutterSize='m' responsive={false}>
    <EuiFlexItem grow={false}>
      <EuiBadge>{build.commit_hash ? build.commit_hash.slice(0, 6) : `-`}</EuiBadge>
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <EuiBadge>{build.version || `-`}</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default RunnerBuild
