/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import { CuiAlert, CuiButtonEmpty, CuiPermissibleControl } from '../../../cui'
import Permission from '../../../lib/api/v1/permissions'

import type { Allocator, AsyncRequestState } from '../../../types'

type Props = {
  allocator: Allocator
  setAllocatorMaintenanceMode: (enabled: boolean) => void
  setAllocatorMaintenanceModeRequest: AsyncRequestState
  resetSetAllocatorMaintenanceModeRequest: () => void
}

class AllocatorMaintenanceModeButton extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { resetSetAllocatorMaintenanceModeRequest } = this.props
    const maintenancePath = [`allocator`, `isInMaintenanceMode`]

    if (get(this.props, maintenancePath) !== get(prevProps, maintenancePath)) {
      resetSetAllocatorMaintenanceModeRequest()
    }
  }

  render() {
    const { allocator } = this.props

    const { isInMaintenanceMode } = allocator

    if (isInMaintenanceMode) {
      return (
        <Fragment>
          {this.renderDisableMaintenance()}
          {this.renderErrors()}
        </Fragment>
      )
    }

    return (
      <Fragment>
        {this.renderEnableMaintenance()}
        {this.renderErrors()}
      </Fragment>
    )
  }

  renderDisableMaintenance() {
    const { setAllocatorMaintenanceModeRequest } = this.props

    return (
      <Fragment>
        <CuiPermissibleControl permissions={Permission.stopAllocatorMaintenanceMode}>
          <CuiButtonEmpty
            iconType='wrench'
            color='warning'
            onClick={this.disableMaintenanceMode}
            spin={
              setAllocatorMaintenanceModeRequest.inProgress ||
              setAllocatorMaintenanceModeRequest.isDone
            }
            confirm={true}
            confirmModalProps={{
              title: (
                <FormattedMessage
                  id='allocator-maintenance-mode-button.confirm-to-leave'
                  defaultMessage='Disable allocator maintenance?'
                />
              ),
              body: (
                <FormattedMessage
                  id='allocator-maintenance-mode-button.disable-description'
                  defaultMessage='Turn off maintenance mode for this allocator. The allocator will be able to start Elasticsearch nodes and Kibana instances again. Existing nodes will continue to work as expected.'
                />
              ),
            }}
          >
            <FormattedMessage
              id='allocator-maintenance-mode-button.disable'
              defaultMessage='Disable allocator maintenance'
            />
          </CuiButtonEmpty>
        </CuiPermissibleControl>

        {this.renderErrors()}
      </Fragment>
    )
  }

  renderEnableMaintenance() {
    const { setAllocatorMaintenanceModeRequest } = this.props

    return (
      <Fragment>
        <CuiPermissibleControl permissions={Permission.startAllocatorMaintenanceMode}>
          <CuiButtonEmpty
            iconType='wrench'
            color='warning'
            onClick={this.enableMaintenanceMode}
            spin={
              setAllocatorMaintenanceModeRequest.inProgress ||
              setAllocatorMaintenanceModeRequest.isDone
            }
            confirm={true}
            confirmModalProps={{
              title: (
                <FormattedMessage
                  id='allocator-maintenance-mode-button.confirm-to-enable'
                  defaultMessage='Enable allocator maintenance?'
                />
              ),
              body: (
                <FormattedMessage
                  id='allocator-maintenance-mode-button.enable-description'
                  defaultMessage='While in maintenance mode, no new instances will be started on the allocator. Existing instances will continue to work as expected.'
                />
              ),
            }}
          >
            <FormattedMessage
              id='allocator-maintenance-mode-button.enable'
              defaultMessage='Enable allocator maintenance'
            />
          </CuiButtonEmpty>
        </CuiPermissibleControl>
      </Fragment>
    )
  }

  renderErrors() {
    const { setAllocatorMaintenanceModeRequest } = this.props

    if (!setAllocatorMaintenanceModeRequest.error) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='error' data-test-id='set-request-error'>
          {setAllocatorMaintenanceModeRequest.error}
        </CuiAlert>
      </Fragment>
    )
  }

  disableMaintenanceMode = () => {
    const { setAllocatorMaintenanceMode } = this.props
    setAllocatorMaintenanceMode(false)
  }

  enableMaintenanceMode = () => {
    const { setAllocatorMaintenanceMode } = this.props
    setAllocatorMaintenanceMode(true)
  }
}

export default AllocatorMaintenanceModeButton
