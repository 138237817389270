/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { stringify } from 'query-string'

import { EuiButtonEmpty } from '@elastic/eui'

import DangerButton from '@/components/DangerButton'
import PasswordLinkModal from '@/components/User/GenerateResetPasswordLinkButton/PasswordLinkModal'
import { resetPasswordUrl } from '@/lib/urlBuilder'

import type { AllProps as Props, ResetPasswordParamsResponse } from './types'

interface State {
  linkModalOpen: boolean
  resetLink: string
}

class GenerateResetPasswordLinkButton extends PureComponent<Props, State> {
  state: State = {
    linkModalOpen: false,
    resetLink: '',
  }

  render(): JSX.Element | null {
    const { generateResetPasswordLinkRequest, isFeatureHidden } = this.props
    const { linkModalOpen, resetLink } = this.state

    if (isFeatureHidden) {
      return null
    }

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          buttonProps={{ flush: 'left' }}
          size='s'
          color='primary'
          onConfirm={this.generateResetPasswordLink}
          modal={{
            style: { width: 475 },
            title: (
              <FormattedMessage
                id='generate-reset-password-link.modal-title'
                defaultMessage='Reset password'
              />
            ),
            body: (
              <FormattedMessage
                id='generate-reset-password-link.modal-content-details'
                defaultMessage='A temporary reset password link will be created for the account and shown to you. The link expires in 2 hours.'
              />
            ),
          }}
        >
          <FormattedMessage
            id='generate-reset-password-link.text'
            defaultMessage='Reset password'
          />
        </DangerButton>

        {linkModalOpen && (
          <PasswordLinkModal
            linkRequest={generateResetPasswordLinkRequest}
            onClose={this.onClosePasswordLinkModal}
            link={resetLink}
          />
        )}
      </Fragment>
    )
  }

  generateResetPasswordLink = (): void => {
    const { generateResetPasswordLink } = this.props

    this.setState(
      {
        linkModalOpen: true,
      },
      () => {
        generateResetPasswordLink().then((res) => {
          const { email, expiration, hash } = res.payload

          this.setState({
            resetLink: this.getResetLink({ email, expiration, hash }),
          })
        })
      },
    )
  }

  onClosePasswordLinkModal = (): void => {
    this.setState({ linkModalOpen: false })
  }

  getResetLink({ email, expiration, hash }: ResetPasswordParamsResponse): string {
    const { hostname } = this.props
    const query = stringify({ email, e: expiration, ah: hash })

    return `${hostname}${resetPasswordUrl()}?${query}`
  }
}

export default GenerateResetPasswordLinkButton
