/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import {
  FETCH_ALLOW_EXTRA_VERSIONS_STATUS,
  UPDATE_ALLOW_EXTRA_VERSIONS_STATUS,
} from '../../constants/actions'

export function fetchAllowExtraVersionsStatus(regionId: string, organizationId: string) {
  const url = `api/v1/regions/${regionId}/stack/versions/access/organizations/${organizationId}`

  return asyncRequest({
    type: FETCH_ALLOW_EXTRA_VERSIONS_STATUS,
    method: `GET`,
    url,
    meta: { regionId, organizationId },
    crumbs: [regionId, organizationId],
  })
}

export function updateAllowExtraVersionsStatus(
  regionId: string,
  organizationId: string,
  newValue: boolean,
) {
  const url = `api/v1/regions/${regionId}/stack/versions/access/organizations/${organizationId}`

  const payload = {
    bypass_version_allowlist: newValue,
  }

  return asyncRequest({
    type: UPDATE_ALLOW_EXTRA_VERSIONS_STATUS,
    method: `PUT`,
    url,
    payload,
    meta: { regionId, organizationId },
    crumbs: [regionId, organizationId],
  })
}
