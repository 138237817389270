/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { noop } from 'lodash'

import {
  EuiButtonEmpty,
  EuiFormLabel,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiFlyoutHeader,
  EuiFlyout,
  EuiFlyoutBody,
  EuiTitle,
  EuiFlyoutFooter,
} from '@elastic/eui'

import { CuiAlert } from '../../../cui'
import DangerButton from '../../DangerButton'

import type { ReactNode } from 'react'
import type { AllProps as Props } from './types'

import './userEmailForm.scss'

type State = {
  updatedEmail: string | null
}

class UserEmailForm extends Component<Props, State> {
  state: State = {
    updatedEmail: null,
  }

  componentWillUnmount(): void {
    this.props.resetSaasUserProfileRequest()
  }

  render(): ReactNode {
    const { email } = this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false} className='userEmailForm-email'>
            {email}
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButtonEmpty size='xs' onClick={this.startChanging}>
              <FormattedMessage id='user-email-form.change' defaultMessage='Change' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>

        {this.renderEditFlyout()}
      </Fragment>
    )
  }

  renderEditFlyout(): ReactNode {
    const { email, updateSaasUserProfileRequest } = this.props
    const { updatedEmail } = this.state

    const busy = updateSaasUserProfileRequest.inProgress

    if (updatedEmail === null) {
      return null
    }

    return (
      <EuiFlyout
        ownFocus={true}
        onClose={this.cancelChanges}
        size='s'
        aria-labelledby='editAccountEmailFlyoutTitle'
      >
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='s'>
            <h2 id='editAccountEmailFlyoutTitle'>
              <FormattedMessage
                id='user-email-form.flyout-title'
                defaultMessage='Account email change'
              />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <div>
            <EuiFormLabel>
              <FormattedMessage id='user-email-form.flyout-email-label' defaultMessage='Email' />
            </EuiFormLabel>

            <EuiFieldText
              value={updatedEmail!}
              onChange={(e) => this.setEmail((e.target as HTMLInputElement).value)}
              disabled={busy}
            />
          </div>
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize='m' justifyContent='spaceBetween' responsive={false}>
            <EuiFlexItem grow={false}>
              <DangerButton
                size='s'
                onConfirm={this.saveEmail}
                spin={busy}
                disabled={email === updatedEmail}
                modal={{
                  title: (
                    <FormattedMessage
                      id='user-email-form.update-modal-title'
                      defaultMessage='Update user email?'
                    />
                  ),
                  body: (
                    <FormattedMessage
                      id='user-email-form.update-modal-body'
                      defaultMessage="Updating a user's email address is a sensitive process. Only take this step at the users's request. Are you sure you want to change their email to {updatedEmailAddress}?"
                      values={{ updatedEmailAddress: updatedEmail }}
                    />
                  ),
                }}
              >
                <FormattedMessage id='user-email-form.update' defaultMessage='Update' />
              </DangerButton>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonEmpty disabled={busy} onClick={() => this.cancelChanges()}>
                <FormattedMessage id='user-email-form.cancel' defaultMessage='Cancel' />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>

          {updateSaasUserProfileRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />

              <CuiAlert type='error'>{updateSaasUserProfileRequest.error}</CuiAlert>
            </Fragment>
          )}
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  startChanging = (): void => {
    const { email } = this.props
    this.setState({ updatedEmail: email })
  }

  cancelChanges = (): void => {
    this.setState({ updatedEmail: null })
    this.props.resetSaasUserProfileRequest()
  }

  setEmail = (updatedEmail: string): void => {
    this.setState({ updatedEmail })
  }

  saveEmail = (): void => {
    const { updateSaasUserProfile } = this.props
    const { updatedEmail } = this.state

    if (updatedEmail === null) {
      return
    }

    updateSaasUserProfile(updatedEmail)
      .then(() => this.cancelChanges())
      .catch(noop)
  }
}

export default UserEmailForm
