/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { resetScrubSaasUserRequest, scrubSaasUser } from '@/actions/saasUsers'
import { scrubSaasUserRequest } from '@/reducers/asyncRequests/registry'

import ClearPrivatelyIdentifiableInformationButton from './ClearPrivatelyIdentifiableInformationButton'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ReduxState, ThunkDispatch } from '@/types'

const mapStateToProps = (state: ReduxState, { userId }: ConsumerProps) => ({
  scrubRequest: scrubSaasUserRequest(state, userId),
})

const mapDispatchToProps = (dispatch: ThunkDispatch, { userId }: ConsumerProps): DispatchProps => ({
  scrub: () =>
    dispatch(
      scrubSaasUser({
        userId,
      }),
    ),
  resetScrubRequest: () => dispatch(resetScrubSaasUserRequest(userId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ClearPrivatelyIdentifiableInformationButton)
