/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiEmptyPrompt } from '@elastic/eui'

import { supportUrl } from '@/lib/urlBuilder'

import type { FunctionComponent } from 'react'

export const UnavailableHealthApi: FunctionComponent = () => (
  <EuiEmptyPrompt
    data-test-subj='health-api-unavailable-banner'
    iconType='alert'
    color='danger'
    hasBorder={true}
    hasShadow={true}
    title={
      <h2>
        <FormattedMessage
          id='deployment-health-status.unavailable_api.title'
          defaultMessage={`Cannot determine the deployment's health status`}
        />
      </h2>
    }
    body={
      <p>
        <FormattedMessage
          id='deployment-health-status.unavailable_api.subtitle'
          defaultMessage={`Please try again. If the problem persists, {contactSupportLink}.`}
          values={{
            contactSupportLink: (
              <Link to={supportUrl()}>
                <FormattedMessage
                  id='deployment-health-status.unavailable_api.support_link'
                  defaultMessage='contact support'
                />
              </Link>
            ),
          }}
        />
      </p>
    }
  />
)
