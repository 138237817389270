/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { FilterSchema } from '../../../cui'
import type { Runner } from '../../../types'

export const schema: FilterSchema = {
  strict: true,
  fields: {
    id: {
      type: `string`,
    },
    zone: {
      type: `string`,
    },
    healthy: {
      type: `string`,
      validate: validateHumanBool,
    },
    connected: {
      type: `string`,
      validate: validateHumanBool,
    },
    role: {
      type: `string`,
    },
    container: {
      type: `string`,
    },
    containerset: {
      type: `string`,
    },
  },
}

export const defaultFields = [`id`, `zone`, `role`, `container`, `containerset`]

export function getQueryModel(runner: Runner) {
  return {
    id: runner.runner_id,
    zone: runner.zone,
    healthy: toHumanBool(runner.healthy),
    connected: toHumanBool(runner.connected),
    role: runner.roles.map((r) => r.role_name),
    container: runner.containers.map((c) => c.container_name),
    containerset: runner.containers.map((c) => c.container_set_name),
  }
}

function toHumanBool(value: boolean) {
  return value ? `y` : `n`
}

function validateHumanBool(value: string) {
  if (value !== `y` && value !== `n`) {
    throw new Error(`Expected "y" or "n"`)
  }
}
