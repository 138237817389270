/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText } from '@elastic/eui'

import DocLink from '../../DocLink'
import Header from '../../Header'
import {
  topologyDeploymentTemplatesCrumbs,
  topologyInstanceConfigurationsCrumbs,
} from '../../../lib/crumbBuilder'

import TopologySectionPicker from './TopologySectionPicker'

import type { RegionId } from '../../../types'
import type { FunctionComponent, ReactElement } from 'react'

interface Props {
  regionId: RegionId
  activeUrl: string
  children: ReactElement
}

const Topology: FunctionComponent<Props> = ({ regionId, activeUrl, children }) => {
  const lastPathComponent = activeUrl.split(`/`).pop()
  const isDeploymentTemplates = lastPathComponent === `deployments`
  const isInstanceConfigurations = lastPathComponent === `instance-configurations`

  if (!isDeploymentTemplates && !isInstanceConfigurations) {
    return children
  }

  return (
    <div>
      <Header
        name={
          isDeploymentTemplates ? (
            <FormattedMessage id='topology-templates.title' defaultMessage='Deployment templates' />
          ) : (
            <FormattedMessage
              id='topology-instance-configurations.title'
              defaultMessage='Instance configurations'
            />
          )
        }
        breadcrumbs={
          isDeploymentTemplates
            ? topologyDeploymentTemplatesCrumbs({ regionId })
            : topologyInstanceConfigurationsCrumbs({ regionId })
        }
      />

      <EuiText>
        <p>
          <FormattedMessage
            id='topology-templates.description'
            defaultMessage='Create once, deploy many times. Templates pre-configure the Elastic Stack for different use cases, giving your deployments the resources they need.'
          />
        </p>
        <p>
          <FormattedMessage
            id='topology-templates.description-2'
            defaultMessage='Templates rely on instance configurations to assign resources to a product or feature of the Elastic Stack. Create your own instance configurations as building blocks to tell your templates what kind of hardware you have and how you want to use it. { learnMore }'
            values={{
              learnMore: (
                <DocLink link='templatesDocLink'>
                  <FormattedMessage
                    id='topology-templates.description-link'
                    defaultMessage='Learn more …'
                  />
                </DocLink>
              ),
            }}
          />
        </p>
      </EuiText>

      <EuiSpacer />

      <TopologySectionPicker regionId={regionId} activeUrl={activeUrl} />

      <EuiSpacer />

      {children}
    </div>
  )
}

export default Topology
