/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { ILMValidationErrors } from '../../../../../lib/ilm'

import type { ReactNode } from 'react'

type Props = {
  errors: ILMValidationErrors[]
}

export default function ILMErrors({ errors }: Props) {
  const errorMessages = [] as ReactNode[]

  if (errors.includes(ILMValidationErrors.MISSING_NODE_ATTRIBUTES)) {
    errorMessages.push(
      <Fragment key='missing-node-attributes'>
        <EuiSpacer size='m' />
        <EuiCallOut
          title={
            <FormattedMessage
              id='ilm-errors.missing-node-attributes'
              defaultMessage='You must add attributes for each node.'
            />
          }
          color='danger'
          iconType='cross'
        />
      </Fragment>,
    )
  }

  return <Fragment>{errorMessages}</Fragment>
}
