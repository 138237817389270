/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { Field } from 'formik'

import { EuiSwitch } from '@elastic/eui'

import type { FormattedMessage } from 'react-intl'
import type { FunctionComponent, ReactElement } from 'react'

interface Props {
  label: string | ReactElement<typeof FormattedMessage>
  name: string
  setFieldValue: (key: string, value: any) => void
}

export const CuiFormSwitch: FunctionComponent<Props> = ({ label, name, setFieldValue }) => (
  <Field name={name}>
    {({ field: { onBlur, value } }) => (
      // We have to use setFieldValue because EuiSwitch
      // doesn't call onChange with an event
      <EuiSwitch
        name={name}
        label={label}
        checked={value}
        onChange={() => setFieldValue(name, !value)}
        onBlur={onBlur}
      />
    )}
  </Field>
)
