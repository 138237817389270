/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { DriftChatButton } from './DriftChatButton'
import { DriftChatIframe } from './DriftChatIframe'

import type { RootProps } from './types'

const checkIsChatButtonAvailable = (props: RootProps): boolean =>
  Boolean(
    props.trialPlaybookId ||
      props.marketplacePlaybookId ||
      props.directPayingPlaybookId ||
      props.oldMarketplacePlaybookId,
  )

export const DriftChat: React.FC<RootProps> = (props) => {
  const { mode, profile, ...restProps } = props

  if (!profile) {
    return null
  }

  if (mode === 'iframe') {
    return <DriftChatIframe {...restProps} profile={profile} />
  }

  if (checkIsChatButtonAvailable(props)) {
    return <DriftChatButton {...props} profile={profile} />
  }

  return null
}
