/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiToolTip } from '@elastic/eui'

import { CuiTable, CuiDate } from '../../../../cui'
import PageSection from '../../../PageSection'

import ExtendTrial from './ExtendTrial'

import type { SaasOrganizationResponse, SaasUserTrial } from '../../../../lib/api/v1/types'
import type { CuiTableColumn } from '../../../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  saasOrganization: SaasOrganizationResponse
}

const messages = defineMessages({
  trialIsRestartable: {
    id: `organization.organization-overview.organization-trials.trial-is-restartable`,
    defaultMessage: `Deployments can be restarted after the trial ends.`,
  },
  trialIsNotRestartable: {
    id: `organization.organization-overview.organization-trials.trial-is-not-restartable`,
    defaultMessage: `Deployments cannot be restarted after the trial ends.`,
  },
})

const OrganizationTrials: FunctionComponent<Props> = ({
  intl: { formatMessage },
  saasOrganization: {
    organization: { organization_id: organizationId },
    trials,
  },
}) => {
  if (isEmpty(trials)) {
    return null
  }

  const columns: Array<CuiTableColumn<SaasUserTrial>> = [
    {
      label: (
        <FormattedMessage
          id='organization.organization-overview.organization-trials.trial-label'
          defaultMessage='Trial'
        />
      ),
      render: (trial) => String(trials.indexOf(trial) + 1),
      width: `45px`,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-overview.organization-trials.status-label'
          defaultMessage='Status'
        />
      ),
      render: (trial) =>
        didTrialExpire(trial) ? (
          <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type='dot' color='subdued' />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <FormattedMessage
                id='organization.organization-overview.organization-trials.trial-expired'
                defaultMessage='Expired'
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type='dot' color='success' />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <FormattedMessage
                id='organization.organization-overview.organization-trials.trial-active'
                defaultMessage='Active'
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      width: `100px`,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-overview.organization-trials.period-label'
          defaultMessage='Period'
        />
      ),
      render: (trial) => (
        <FormattedMessage
          id='organization.organization-overview.organization-trials.trial-trial-start-end'
          defaultMessage='{start} — {end}'
          values={{
            start: <CuiDate short={true} date={moment(trial.start)} />,
            end: <CuiDate short={true} date={moment(trial.end)} />,
          }}
        />
      ),
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-overview.organization-trials.trial-restartable-label'
          defaultMessage='Restarts?'
        />
      ),
      render: (trial) => (
        <EuiToolTip
          content={
            trial.restartable
              ? formatMessage(messages.trialIsRestartable)
              : formatMessage(messages.trialIsNotRestartable)
          }
        >
          <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              {trial.restartable ? (
                <FormattedMessage
                  id='organization.organization-overview.organization-trials.trial-restartable-yes'
                  defaultMessage='Yes'
                />
              ) : (
                <FormattedMessage
                  id='organization.organization-overview.organization-trials.trial-restartable-no'
                  defaultMessage='No'
                />
              )}
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiIcon type='iInCircle' />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiToolTip>
      ),
      width: `80px`,
    },
  ]

  return (
    <PageSection
      iconType='training'
      title={
        <FormattedMessage
          id='organization.organization-overview.organization-trials.trials-label'
          defaultMessage='Trials'
        />
      }
      flexItems={
        <EuiFlexItem grow={false}>
          <ExtendTrial
            organizationId={organizationId}
            expirationDate={moment(trials[trials.length - 1].end).toDate()}
          />
        </EuiFlexItem>
      }
    >
      <CuiTable<SaasUserTrial>
        columns={columns}
        rows={trials}
        getRowId={(trial) => String(trial.txid || trials.indexOf(trial))}
      />
    </PageSection>
  )
}

export default injectIntl(OrganizationTrials)

function didTrialExpire(trial: SaasUserTrial): boolean {
  return moment(trial.end).isBefore(moment())
}
