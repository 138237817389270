/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useMemo } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import { CuiAlert, withErrorBoundary } from '../../../cui'
import DangerButton from '../../DangerButton'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { AsyncRequestState } from '../../../types'

interface Props extends WrappedComponentProps {
  deleteEmailSuppressions: () => void
  deleteEmailSuppressionsRequest: AsyncRequestState
}

const messages = defineMessages({
  clearTransactionalEmailSuppressions: {
    id: `clear-transactional-email-suppressions-button.button-text`,
    defaultMessage: `Clear transactional email suppressions`,
  },
  confirmModalTitle: {
    id: `clear-transactional-email-suppressions-button.confirm-title-text`,
    defaultMessage: `Clear transactional email from suppression list?`,
  },
  confirmModalOkButton: {
    id: `clear-transactional-email-suppressions-button.confirm-ok-button-text`,
    defaultMessage: `Clear transactional email`,
  },
})

const ClearTransactionalEmailSuppressionsButton: FunctionComponent<Props> = ({
  intl: { formatMessage },
  deleteEmailSuppressions,
  deleteEmailSuppressionsRequest,
}) => {
  const actionText = formatMessage(messages.clearTransactionalEmailSuppressions)
  const confirmModalTitleText = formatMessage(messages.confirmModalTitle)
  const confirmModalOkButtonText = formatMessage(messages.confirmModalOkButton)

  const modalProps = useMemo(
    () => ({
      title: confirmModalTitleText,
      confirmButtonText: confirmModalOkButtonText,
    }),
    [confirmModalTitleText, confirmModalOkButtonText],
  )

  return (
    <Fragment>
      <DangerButton
        buttonType={EuiButtonEmpty}
        buttonProps={{ flush: 'left' }}
        size='s'
        color='primary'
        fill={false}
        onConfirm={deleteEmailSuppressions}
        spin={deleteEmailSuppressionsRequest.inProgress}
        modal={modalProps}
      >
        {actionText}
      </DangerButton>

      {deleteEmailSuppressionsRequest.error && (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='error'>{deleteEmailSuppressionsRequest.error}</CuiAlert>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withErrorBoundary(injectIntl(ClearTransactionalEmailSuppressionsButton))
