/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiAlert } from '../../cui'
import Header from '../Header'
import { platformCrumbs } from '../../lib/crumbBuilder'

import PlatformRegionsTable from './PlatformRegionsTable'

import type { AsyncRequestState } from '../../types'
import type { PlatformInfo } from '../../lib/api/v1/types'
import type { ReactNode } from 'react'

type Props = {
  platform: PlatformInfo | null
  fetchPlatformRequest: AsyncRequestState
  fetchPlatformOverview: () => void
  children?: ReactNode
}

class PlatformRegionsRoute extends Component<Props> {
  componentDidMount() {
    const { fetchPlatformOverview } = this.props
    fetchPlatformOverview()
  }

  render() {
    return (
      <div>
        <Header
          name={<FormattedMessage id='platform-regions-route.header' defaultMessage='Platform' />}
          breadcrumbs={platformCrumbs()}
        />

        <div>{this.renderRegions()}</div>
      </div>
    )
  }

  renderRegions() {
    const { fetchPlatformRequest, platform } = this.props

    if (fetchPlatformRequest.error) {
      return <CuiAlert type='error'>{fetchPlatformRequest.error}</CuiAlert>
    }

    return <PlatformRegionsTable platform={platform} />
  }
}

export default PlatformRegionsRoute
