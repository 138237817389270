/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchRegionList } from '@/actions/regionEqualizer'
import { fetchVersions } from '@/actions/elasticStack'
import { fetchInstanceConfigurations } from '@/actions/topology/instanceConfigurations'

import DeploymentFilterContext from './DeploymentFilterContext'

import type { ReduxState, ThunkDispatch, Region } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (_state: ReduxState): StateProps => ({})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchRegionList: () => dispatch(fetchRegionList()),
  fetchVersions: (region: Region | string) => dispatch(fetchVersions(region)),
  fetchInstanceConfigurations: (regionId: string) =>
    dispatch(fetchInstanceConfigurations(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentFilterContext)

export { getEsQuery } from './DeploymentFilterContext'

export { getFilterQuery, transformIdQuery } from './queryParser'
