/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get } from 'lodash'

import { addToast, parseError } from '@/cui'

import { formatMessageWithValues, errors } from '../messages'

import type { OrganizationMemberRow } from '../types'
import type { MessageValues } from '../messages'
import type { IntlShape, MessageDescriptor } from 'react-intl'
import type { OrganizationMembership } from '@/lib/api/v1/types'
import type { UserProfile } from '@/types'

function parseMemberError({
  intl,
  error,
  values,
}: {
  intl: IntlShape
  error: Error
  values: MessageValues
}): string {
  const errorCode = get(error, [`body`, `errors`, `0`, `code`])

  if (errorCode === `organization.has_deployments`) {
    return formatMessageWithValues({ intl, message: errors.hasDeployments, values })
  }

  return parseError(error)
}

export const hasNoMember = ({
  userId,
  members,
}: {
  userId: string | undefined
  members: OrganizationMembership[]
}): boolean => !members.some((member) => member.user_id === userId)

export const getMemberEmail = ({
  userId,
  members,
}: {
  userId: string | undefined
  members: OrganizationMembership[]
}): string => {
  if (!userId) {
    return ''
  }

  if (userId.startsWith('saml:okta-saml:')) {
    return userId.slice(15)
  }

  const member = members.find(({ user_id }) => user_id === userId)

  if (member) {
    return member.email || member.name || userId
  }

  return userId
}

export const isCurrentUserLastOrganizationMember = (
  profile: UserProfile | null,
  activeMemberRows: OrganizationMemberRow[],
  invitationRows: OrganizationMemberRow[],
): boolean =>
  invitationRows.length === 0 &&
  activeMemberRows.length === 1 &&
  hasSameEmail(profile, activeMemberRows[0])

export const hasSameEmail = (
  profile: UserProfile | null,
  organizationMemberRow: OrganizationMemberRow,
): boolean => profile?.email === organizationMemberRow.email

export const removeMember = ({
  intl,
  deleteOrganizationMember,
  successMessage,
  failureMessage,
  values,
}: {
  intl: IntlShape
  deleteOrganizationMember: Promise<any>
  successMessage: MessageDescriptor
  failureMessage: MessageDescriptor
  values: MessageValues
}): void => {
  deleteOrganizationMember
    .then(() =>
      addToast({
        family: 'organization.organization-members.remove-member-button',
        color: 'success',
        iconType: 'check',
        title: formatMessageWithValues({ intl, message: successMessage, values }),
      }),
    )
    .catch((error) =>
      addToast({
        family: 'organization.organization-members.remove-member-button',
        color: 'danger',
        title: formatMessageWithValues({ intl, message: failureMessage, values }),
        text: parseMemberError({ intl, error, values }),
      }),
    )
}
