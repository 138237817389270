/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchAllocator } from '../../../actions/allocators'
import { resetUpdateRoles, updateRoles } from '../../../actions/runners'
import { fetchBlueprintRoles } from '../../../actions/blueprint'
import {
  fetchBlueprintRolesRequest,
  getAllocator,
  getAllocatorRequest,
  getRoles,
  getRunner,
  updateRolesRequest,
} from '../../../reducers'

import ManageHostRoles from './ManageHostRoles'

import type {
  Allocator,
  AsyncRequestState,
  ReduxState,
  RegionRolesState,
  Runner,
  ThunkDispatch,
} from '../../../types'
import type { RunnerRoleInfo } from '../../../lib/api/v1/types'

interface StateProps {
  allocator?: Allocator
  allocatorRequest: AsyncRequestState
  fetchBlueprintRolesRequest: AsyncRequestState
  roles: RegionRolesState[]
  runner?: Runner
  updateRolesRequest: AsyncRequestState
}

interface DispatchProps {
  fetchAllocator: (allocatorId: string) => Promise<any>
  fetchBlueprintRoles: () => Promise<any>
  resetUpdateRoles: () => void
  updateRoles: (roles: RunnerRoleInfo[]) => Promise<any>
}

interface ConsumerProps {
  regionId: string
  hostId: string
  onClose: () => void
}

const mapStateToProps = (state: ReduxState, { regionId, hostId }: ConsumerProps): StateProps => {
  const runner = getRunner(state, regionId, hostId)

  return {
    allocator: getAllocator(state, regionId, hostId),
    allocatorRequest: getAllocatorRequest(state, regionId, hostId),
    fetchBlueprintRolesRequest: fetchBlueprintRolesRequest(state, regionId),
    roles: getRoles(state, regionId),
    runner,
    updateRolesRequest: updateRolesRequest(state, regionId, hostId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId, hostId }: ConsumerProps,
): DispatchProps => ({
  fetchAllocator: (allocatorId: string) => dispatch(fetchAllocator(regionId, allocatorId)),
  fetchBlueprintRoles: () => dispatch(fetchBlueprintRoles(regionId)),
  resetUpdateRoles: () => dispatch(resetUpdateRoles(regionId, hostId)),
  updateRoles: (roles: RunnerRoleInfo[]) => dispatch(updateRoles(regionId, hostId, roles)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ManageHostRoles)
