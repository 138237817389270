/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { EuiLinkAnchorProps } from '@elastic/eui'
import { EuiLink } from '@elastic/eui'

import type { FunctionComponent } from 'react'

interface Props extends EuiLinkAnchorProps {
  showExternalLinkIcon?: boolean
  target?: '_target' | '_self' | '_parent' | '_top'
}

const ExternalLink: FunctionComponent<Props> = ({
  children,
  showExternalLinkIcon = false,
  target = '_blank',
  ...rest
}) => (
  <EuiLink {...rest} external={showExternalLinkIcon} target={target} rel='noopener noreferrer'>
    {children}
  </EuiLink>
)

export default ExternalLink
