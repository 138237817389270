/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiPanel, EuiText } from '@elastic/eui'

// @ts-ignore: whatever, these modules resolve
import en from './ece-eula.en.txt'
// @ts-ignore: whatever, these modules resolve
import fr from './ece-eula.fr.txt'
// @ts-ignore: whatever, these modules resolve
import de from './ece-eula.de.txt'
// @ts-ignore: whatever, these modules resolve
import ja from './ece-eula.ja.txt'

import type { FunctionComponent } from 'react'

type Props = {
  lang: string
}

const eulas = { en, de, fr, ja }

const EulaAgreement: FunctionComponent<Props> = ({ lang }) => (
  <EuiPanel className='eula-agreement' data-test-id='eula-agreement-wrapper'>
    <EuiText data-test-id='eula-body'>{eulas[lang]}</EuiText>
  </EuiPanel>
)

export default EulaAgreement
