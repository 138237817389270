/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import cx from 'classnames'
import { noop } from 'lodash'

import { EuiRadioGroup, EuiTextColor, EuiToolTip, EuiText } from '@elastic/eui'

import { getSliderTrialLimit } from '../../../../../../../lib/sliders'
import { isHeroku } from '../../../../../../../lib/heroku'

import type { SliderInstanceType, SliderNodeType } from '../../../../../../../types'
import type { ReactNode } from 'react'

const messages = defineMessages({
  zonesLabel: {
    id: `deploymentInfrastructure-topologyElement-zoneCount-optionLabel`,
    defaultMessage: `{ zones } { zones, plural, one {zone} other {zones} }`,
  },
  requiresPaid: {
    id: `deploymentInfrastructure-topologyElement-zoneCount-requiresPaid`,
    defaultMessage: `Requires subscription`,
  },
})

export type Props = {
  id: string
  zoneCount: number
  maxZoneCount: number
  onChange: undefined | ((value: number) => void)
  inTrial: boolean
  showTrialThreshold: boolean
  disabled?: boolean
  sliderInstanceType: SliderInstanceType
  sliderNodeTypes?: SliderNodeType[]
  isDedicatedMaster: boolean
  dedicatedMastersFixedZoneCount: number | null
}

const ZoneCount: React.FunctionComponent<Props> = ({
  id,
  zoneCount = 1,
  maxZoneCount,
  onChange,
  inTrial,
  showTrialThreshold,
  disabled,
  sliderInstanceType,
  sliderNodeTypes,
  isDedicatedMaster,
  dedicatedMastersFixedZoneCount,
}) => {
  const options: Array<{
    id: string
    value: string
    label: ReactNode
    disabled: boolean
  }> = []

  const trialThreshold = getSliderTrialLimit({
    inTrial,
    sliderInstanceType,
    sliderNodeTypes,
  })

  for (let i = 1; i <= maxZoneCount; i++) {
    const zone = buildZoneOption(i)
    options.push(zone)
  }

  const noZones = options.length === 0

  if (noZones) {
    const zone = buildZoneOption(0)
    options.push(zone)
  }

  return (
    <EuiRadioGroup
      className='topologyPanel-zoneCount'
      data-test-subj='topologyElement-zoneCount'
      name={`fault-tolerance-for-${id}`}
      idSelected={`fault-tolerance-for-${id}:${zoneCount}`}
      options={options}
      onChange={onChange ? (_, value) => onChange(parseInt(value!, 10)) : noop}
      disabled={noZones || disabled || !onChange || isHeroku()}
    />
  )

  function buildZoneOption(i: number) {
    const showTrialConversion = shouldShowTrialConversion(i)

    const label = showTrialConversion ? (
      <EuiTextColor color='success'>
        <EuiToolTip
          position='top'
          content={<FormattedMessage {...messages.requiresPaid} values={{ zones: i }} />}
        >
          <EuiText size='s'>
            <FormattedMessage {...messages.zonesLabel} values={{ zones: i }} />
          </EuiText>
        </EuiToolTip>
      </EuiTextColor>
    ) : (
      <EuiText size='s'>
        <FormattedMessage {...messages.zonesLabel} values={{ zones: i }} />
      </EuiText>
    )

    return {
      id: `fault-tolerance-for-${id}:${i}`,
      value: i.toString(),
      label,
      disabled: Boolean(
        disabled ||
          (trialThreshold && i > trialThreshold.zoneCount && !showTrialThreshold) ||
          (isDedicatedMaster && typeof dedicatedMastersFixedZoneCount === `number`),
      ),
      className: cx({
        'zoneCountOption--overTrialThreshold': trialThreshold && i > trialThreshold.zoneCount,
      }),
    }
  }

  function shouldShowTrialConversion(i: number) {
    if (!showTrialThreshold) {
      return false
    }

    if (!trialThreshold) {
      return false
    }

    return i > trialThreshold.zoneCount
  }
}

export default ZoneCount
