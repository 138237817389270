/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiRadioGroupOption } from '@elastic/eui'
import { EuiText, EuiFormLabel, EuiRadioGroup, EuiSpacer } from '@elastic/eui'

import StrategyExplained from '../StrategyExplained'
import {
  isAutodetect,
  isGrowAndShrink,
  isRollingByName,
  isRollingGrowAndShrink,
  strategies,
} from '../../lib/clusterStrategies'

import type { Strategy } from '../../lib/clusterStrategies'

type Props = {
  strategy: Strategy
  onUpdate: (strategy: Strategy) => void
}

function Strategies({ strategy, onUpdate }: Props) {
  const options: EuiRadioGroupOption[] = [
    {
      id: `strategy-autodetect`,
      label: <StrategyExplained strategy={strategies.autodetect} />,
    },

    {
      id: `strategy-rolling`,
      label: <StrategyExplained strategy={strategies.rollingByName} />,
    },

    {
      id: `strategy-create-new`,
      label: <StrategyExplained strategy={strategies.growAndShrink} />,
    },

    {
      id: `strategy-rolling-create-new`,
      label: <StrategyExplained strategy={strategies.rollingGrowAndShrink} />,
    },
  ]

  const idSelected = getIdSelected()

  return (
    <Fragment>
      <EuiFormLabel>
        <FormattedMessage
          id='deployment-configure-strategies.strategy-label'
          defaultMessage='Configuration Strategy'
        />
      </EuiFormLabel>
      <EuiText size='s'>
        <FormattedMessage
          id='deployment-configure-strategies.strategy-description'
          defaultMessage='Control how configuration changes are applied to your deployment:'
        />
      </EuiText>
      <EuiSpacer size='s' />

      <EuiRadioGroup options={options} idSelected={idSelected} onChange={onChange} />
    </Fragment>
  )

  function getIdSelected() {
    if (isAutodetect(strategy)) {
      return `strategy-autodetect`
    }

    if (isRollingByName(strategy)) {
      return `strategy-rolling`
    }

    if (isGrowAndShrink(strategy)) {
      return `strategy-create-new`
    }

    if (isRollingGrowAndShrink(strategy)) {
      return `strategy-rolling-create-new`
    }
  }

  function getStrategyForId(id: string) {
    if (id === `strategy-autodetect`) {
      return strategies.autodetect
    }

    if (id === `strategy-rolling`) {
      return strategies.rollingByName
    }

    if (id === `strategy-create-new`) {
      return strategies.growAndShrink
    }

    if (id === `strategy-rolling-create-new`) {
      return strategies.rollingGrowAndShrink
    }
  }

  function onChange(id: string) {
    const newStrategy = getStrategyForId(id)

    if (!newStrategy) {
      return // sanity
    }

    onUpdate(newStrategy)
  }
}

export default Strategies
