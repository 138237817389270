/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { EuiFlexItem } from '@elastic/eui'

import { getCreatePayload } from '@/lib/stackDeployments'
import { getClaimDeploymentRequestPayload } from '@/lib/stackDeployments/crud'
import { createDeploymentUrl } from '@/lib/api/v1/urls'
import { isTrialUser } from '@/lib/billing'
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors'
import { gte } from '@/lib/semver'

import ApiRequestExample from '../../../ApiRequestExample'
import SpinButton from '../../../SpinButton'

import type { ReactElement } from 'react'
import type {
  DeploymentClaimResponse,
  DeploymentCreateRequest,
  DeploymentCreateResponse,
} from '@/lib/api/v1/types'
import type { AllProps as Props } from './types'

class CreateDeploymentButton extends Component<Props> {
  static defaultProps = {
    filterIngestPlugins: false,
  }

  componentWillUnmount(): void {
    const { resetCreateDeployment } = this.props
    resetCreateDeployment()
  }

  render(): ReactElement {
    const {
      disabled,
      createStackDeploymentRequest,
      claimInstantStackDeploymentRequest,
      showApiRequest,
      profile,
    } = this.props
    const displayApiRequestLink = !disabled && showApiRequest
    const isTrial = profile && isTrialUser(profile)

    return (
      <EuiFlexItem style={{ textAlign: 'center' }} grow={false}>
        <div>
          <SpinButton
            data-test-id='submit-create-deployment'
            className='create-deployment-action-button'
            fill={true}
            disabled={disabled}
            onClick={this.onClickCreate}
            spin={
              createStackDeploymentRequest.inProgress ||
              claimInstantStackDeploymentRequest.inProgress ||
              claimInstantStackDeploymentRequest.isDone
            }
          >
            <FormattedMessage
              id='create-deployment-from-template.create-deployment'
              defaultMessage='Create deployment'
            />
          </SpinButton>

          {displayApiRequestLink && !isTrial && (
            <ApiRequestExample
              method='POST'
              endpoint={createDeploymentUrl()}
              body={this.getCreatePayload()}
            />
          )}
        </div>
      </EuiFlexItem>
    )
  }

  onClickCreate = (): void => {
    const { profile, flags } = this.props
    const isTrial = profile && isTrialUser(profile)

    if (isTrial && flags?.claimInstantDeployment) {
      this.claimInstantDeployment()
      return
    }

    this.createDeployment()
  }

  getCreatePayload(): DeploymentCreateRequest {
    const { editorState, filterIngestPlugins, stackVersions } = this.props
    const region = this.props.region!

    const payload = getCreatePayload({
      region,
      editorState,
      filterIngestPlugins,
      stackVersions,
    })

    return payload!
  }

  claimInstantDeployment = (): void => {
    const { claimInstantStackDeployment, fetchDeployment, flags } = this.props
    const createDeploymentPayload = this.getCreatePayload()
    const claimDeploymentRequestPayload = getClaimDeploymentRequestPayload({
      deployment: createDeploymentPayload,
    })

    if (claimDeploymentRequestPayload) {
      claimInstantStackDeployment({ payload: claimDeploymentRequestPayload })
        .then((res) => {
          const claimedDeploymentResponse: DeploymentClaimResponse = res.payload
          const { deployment_id } = claimedDeploymentResponse
          return fetchDeployment(deployment_id)
        })
        .then((res) => {
          const deployment = res.payload
          const kibanaLinkUrl = getDeploymentResourceEndpoint({
            deployment,
            sliderInstanceType: `kibana`,
            getDeepLink: () => {
              const deploymentVersion = claimDeploymentRequestPayload.version

              if (
                flags?.guidedOnboarding === true &&
                deploymentVersion &&
                gte(deploymentVersion, `8.4.0`)
              ) {
                return `/app/home#/getting_started`
              }

              return null // Fall back to default behavior
            },
          })

          if (kibanaLinkUrl) {
            const { origin } = window.location
            window.location.replace(`${origin}${kibanaLinkUrl}`)
          }
        })
        .catch((err) => {
          // NO standby deployment is available, so create a deployment
          if (err.response.status === 404) {
            this.createDeployment()
          }

          // we rely on the error being rendered elsewhere
          return
        })
    }
  }

  createDeployment = (): void => {
    const {
      createDeployment,
      redirectToStackGettingStarted,
      profile,
      fetchProfile,
      isUserconsole,
    } = this.props

    createDeployment({
      deployment: this.getCreatePayload(),
      profile,
    }).then(createdDeployment)

    function createdDeployment(actionResult) {
      if (actionResult.error || !actionResult.payload) {
        return // we rely on the error being rendered elsewhere
      }

      if (isUserconsole) {
        fetchProfile()
      }

      const response: DeploymentCreateResponse = actionResult.payload
      const { id } = response

      redirectToStackGettingStarted(id)
    }
  }
}

export default withLDConsumer()(CreateDeploymentButton)
