/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiText,
  EuiButton,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import type { FunctionComponent } from 'react'

import '../../styles.scss'

interface Props {
  retry: () => void
}

const ErrorCallout: FunctionComponent<Props> = ({ retry }) => (
  <EuiPanel hasShadow={false} paddingSize='m' hasBorder={true} className='error-panel'>
    <EuiFlexGroup direction='column' gutterSize='s' justifyContent='center' alignItems='center'>
      <EuiFlexItem>
        <EuiTitle size='xs' data-test-id='billing-usage.page-title'>
          <h3>
            <FormattedMessage
              id='billing-usage.error-title'
              defaultMessage='An error happened when trying to fetch data.'
            />
          </h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size='s' data-test-id='billing-usage.page-title' color='subdued'>
          <FormattedMessage
            id='billing-usage.error-temporary-message'
            defaultMessage='This may be a temporary problem. Please change the above filter criteria or try again.'
          />
        </EuiText>
      </EuiFlexItem>
      <EuiSpacer size='m' />
      <EuiFlexItem grow={false}>
        <EuiButton onClick={retry} iconType='refresh' data-test-id='error-panel-retry-button'>
          <FormattedMessage id='billing-usage.error-try-again' defaultMessage='Try again' />
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
)

export default ErrorCallout
