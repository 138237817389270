/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import { CuiLink } from '../../../cui'
import { hostsAllocatorsUrl } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { RegionInfo } from '../../../lib/api/v1/types'

type Props = {
  region: RegionInfo
}

const UnhealthyAllocatorsRow: FunctionComponent<Props> = ({ region }) => (
  <EuiCallOut
    color='danger'
    iconType='alert'
    title={
      <FormattedMessage
        id='unhealthy-allocators-row.unhealthy-region-allocators'
        defaultMessage='Some allocators in {regionId} are unhealthy. { investigate }.'
        values={{
          regionId: region.region_id,
          investigate: (
            <CuiLink to={`${hostsAllocatorsUrl(region.region_id)}?q=healthy:n`}>
              <FormattedMessage
                id='unhealthy-allocators-row.investigate-the-problem'
                defaultMessage='Investigate the problem'
              />
            </CuiLink>
          ),
        }}
      />
    }
  />
)

export default UnhealthyAllocatorsRow
