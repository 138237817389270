/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { CuiSliderLogo } from '../../../../../cui'
import intersperse from '../../../../../lib/intersperse'
import { isSliderInstanceTypeSupportedInPlatform } from '../../../../../lib/sliders'
import { getProductSliderTypesForTemplate } from '../../../../../lib/stackDeployments/selectors'

import type { DeploymentTemplateInfoV2 } from '../../../../../lib/api/v1/types'
import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  deploymentTemplateInfo: DeploymentTemplateInfoV2
  hideUnsupported?: boolean
}

const ProductIcons: FunctionComponent<Props> = ({ deploymentTemplateInfo, hideUnsupported }) => {
  const { deployment_template } = deploymentTemplateInfo

  if (!deployment_template) {
    return null
  }

  const icons = getProductSliderTypesForTemplate(deployment_template)
    .filter((sliderType) =>
      hideUnsupported
        ? isSliderInstanceTypeSupportedInPlatform(sliderType.sliderInstanceType)
        : true,
    )
    .map((sliderType, i) => (
      <Fragment key={`${sliderType.sliderInstanceType}_${i}`}>
        <CuiSliderLogo {...sliderType} showTooltip={true} />
      </Fragment>
    ))

  return (
    <div>
      {
        // Add spaces between the icons, otherwise they look cramped.
        intersperse<ReactNode>(icons, ` `)
      }
    </div>
  )
}

export default ProductIcons
