/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiBadge, withEuiTheme, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import { getHealthBadgeColor } from '../../lib/healthProblems/problems'
import { getHighestSeverity } from '../../lib/healthProblems'
import { deploymentHealthStatusUrl } from '../../lib/urlBuilder'

import { DeploymentHealthProblemsSummary } from './StackDeploymentHealthProblemsTitle'

import type { FunctionComponent } from 'react'
import type { Problem } from '../../lib/healthProblems/problems'

interface Props extends WithEuiThemeProps {
  problems: Problem[]
  deploymentId: string
  showViewIssuesLink: boolean
}

const HealthBadge: FunctionComponent<Props> = ({
  problems,
  deploymentId,
  showViewIssuesLink,
  theme,
}) => {
  const highestSeverity = getHighestSeverity(problems)
  const shouldShowViewIssuesLink = highestSeverity === 'danger' || highestSeverity === 'warning'

  const badgeStyle = css({
    '&&': {
      padding: `0 ${theme.euiTheme.size.m}`,
      borderRadius: theme.euiTheme.size.m,
      lineHeight: theme.euiTheme.size.l,
      fontWeight: theme.euiTheme.font.weight.bold,
      textTransform: 'uppercase',
    },
  })

  return (
    <EuiFlexGroup alignItems='center' gutterSize='m'>
      <EuiFlexItem grow={false}>
        <EuiBadge
          color={getHealthBadgeColor({ problems })}
          css={badgeStyle}
          data-test-subj='deployment-health-badge'
        >
          <DeploymentHealthProblemsSummary problems={problems} />
        </EuiBadge>
      </EuiFlexItem>
      {showViewIssuesLink && shouldShowViewIssuesLink && (
        <EuiFlexItem grow={false}>
          <Link to={deploymentHealthStatusUrl(deploymentId)}>
            <FormattedMessage
              id='deployment-health-problems.health-badge.view-issues'
              defaultMessage='View issues'
            />
          </Link>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export const StackDeploymentHealthBadge = withEuiTheme(HealthBadge)
