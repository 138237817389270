/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { countInstances, getVersion, hasHealthyInstances } from '@/lib/stackDeployments/selectors'
import { getSliderPrettyName } from '@/lib/sliders'
import { shouldUpgradeSliderVersion } from '@/lib/stackDeployments'

import type { AnyResourceInfo, SliderInstanceType, StackDeployment } from '@/types'
import type { Problem } from '../../problems'
import type { ReactElement } from 'react'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles instance-level health issues.
 */
export function getInstanceProblems(
  deployment: StackDeployment,
  resource: AnyResourceInfo,
  sliderInstanceType: SliderInstanceType,
): Problem[] {
  const problems: Problem[] = []
  const shouldUpgrade = shouldUpgradeSliderVersion({ deployment, sliderInstanceType })

  if (shouldUpgrade) {
    const version = getVersion({ deployment })
    const prettyName = (
      <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />
    )
    const instanceProblems = getProblems({
      kind: sliderInstanceType,
      kindName: prettyName,
      resource,
    })

    problems.push(...instanceProblems)
  }

  if (sliderInstanceType === `elasticsearch`) {
    const instanceProblems = getProblems({
      kind: `elasticsearch`,
      kindName: (
        <FormattedMessage
          id='deployment-health-problems.es-instance-kind'
          defaultMessage='Elasticsearch'
        />
      ),
      resource,
    })
    problems.push(...instanceProblems)
  }

  return problems
}

function getProblems({
  resource,
  kind,
  kindName,
}: {
  resource: AnyResourceInfo
  kind: SliderInstanceType
  kindName: ReactElement
}): Problem[] {
  const problems: Problem[] = []

  if (!hasHealthyInstances({ resource })) {
    const { notRunning, totalReported } = countInstances({ resource })

    if (notRunning > 0) {
      const noneRunning = totalReported - notRunning <= 0

      if (noneRunning) {
        problems.push({
          'data-test-id': `instances-unhealthy`,
          kind,
          id: `${kind}-all-instances-unhealthy`,
          level: `danger`,
          message: (
            <FormattedMessage
              id='deployment-health-problems.all-instances-unhealthy'
              defaultMessage='No {kindName} instances are running'
              values={{ kindName }}
            />
          ),
        })
      } else {
        problems.push({
          'data-test-id': `instances-unhealthy`,
          kind,
          id: `${kind}-precise-amount-instances-unhealthy`,
          level: `danger`,
          message: (
            <FormattedMessage
              id='deployment-health-problems.precise-amount-instances-unhealthy'
              defaultMessage='{instances, plural, one {One} other {{instances}}} {kindName} {instances, plural, one {instance is} other {instances are}} not running'
              values={{ kindName, instances: notRunning }}
            />
          ),
        })
      }
    } else {
      problems.push({
        'data-test-id': `instances-unhealthy`,
        kind,
        id: `${kind}-some-instances-unhealthy`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.some-instances-unhealthy'
            defaultMessage='Some {kindName} instances are unhealthy'
            values={{ kindName }}
          />
        ),
      })
    }
  }

  return problems
}
