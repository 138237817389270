/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt, EuiButton, EuiImage } from '@elastic/eui'

import noResultsLight from '@/files/noResults--light.svg'
import noResultsDark from '@/files/noResults--dark.svg'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const ErrorCallout: FunctionComponent<Props> = ({ onReload = () => location.reload(), theme }) => (
  <EuiEmptyPrompt
    title={
      <h2>
        <FormattedMessage id='billing-error-box.title' defaultMessage='Unable to load page' />
      </h2>
    }
    icon={
      <EuiImage
        size='fullWidth'
        src={{ dark: noResultsDark, light: noResultsLight }[theme]}
        alt='No Results'
      />
    }
    color='transparent'
    layout='horizontal'
    body={
      <Fragment>
        <p>
          <FormattedMessage
            id='billing-error-box.temporary-message'
            defaultMessage='This might be a temporary problem. Please wait and try again.'
          />
        </p>
        <p>
          <FormattedMessage
            id='billing-error-box.contact'
            defaultMessage={`If you're still having trouble, contact us at support@elastic.co.`}
          />
        </p>
      </Fragment>
    }
    actions={
      <EuiButton color='primary' fill={true} onClick={onReload}>
        <FormattedMessage id='billing-error-box.reload-button' defaultMessage='Reload' />
      </EuiButton>
    }
  />
)

export default ErrorCallout
