/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { BillingDetailsContext } from '../BillingDetailsContext'

import type { AllProps } from './types'
import type { BillingDetailsContextProps } from '../types'

class BillingDetailsProvider extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { fetchOrganizationBillingDetailsIfNeeded, organizationId } = this.props

    if (organizationId) {
      fetchOrganizationBillingDetailsIfNeeded(organizationId)
    }
  }

  componentDidUpdate(prevProps) {
    const { organizationId, fetchOrganizationBillingDetailsIfNeeded } = this.props

    // we need to refetch BillingDetails every time we switch account context
    if (organizationId && prevProps.organizationId !== organizationId) {
      fetchOrganizationBillingDetailsIfNeeded(organizationId!)
    }
  }

  render() {
    const { children } = this.props

    return (
      <BillingDetailsContext.Provider value={this.getBillingDetailsData()}>
        {children}
      </BillingDetailsContext.Provider>
    )
  }

  getBillingDetailsData = (): BillingDetailsContextProps => {
    const {
      organizationId,
      billingDetails,
      fetchOrganizationBillingDetailsRequest: { error, inProgress, isDone },
    } = this.props
    const loading = !isDone || inProgress
    const hasError = !organizationId || !!error

    return {
      loading,
      error: hasError,
      data: billingDetails,
      refetch: this.refetchBillingDetails,
    }
  }

  refetchBillingDetails = () => {
    const { organizationId, fetchOrganizationBillingDetails } = this.props

    if (organizationId) {
      fetchOrganizationBillingDetails(organizationId)
    }
  }
}

export default BillingDetailsProvider
