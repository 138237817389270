/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { setAllocatorMaintenanceModeRequest } from '../../../reducers'
import {
  setAllocatorMaintenanceMode,
  resetSetAllocatorMaintenanceModeRequest,
} from '../../../actions/allocators'

import MaintenanceModeButton from './MaintenanceModeButton'

import type { Allocator, AsyncRequestState } from '../../../types'

type StateProps = {
  setAllocatorMaintenanceModeRequest: AsyncRequestState
}

type DispatchProps = {
  setAllocatorMaintenanceMode: (enabled: boolean) => void
  resetSetAllocatorMaintenanceModeRequest: () => void
}

type ConsumerProps = {
  allocator: Allocator
}

const mapStateToProps = (state, { allocator: { regionId, id } }: ConsumerProps): StateProps => ({
  setAllocatorMaintenanceModeRequest: setAllocatorMaintenanceModeRequest(state, regionId, id),
})

const mapDispatchToProps = (dispatch, { allocator }: ConsumerProps): DispatchProps => {
  const { regionId, id } = allocator

  return {
    setAllocatorMaintenanceMode: (enabled: boolean) =>
      dispatch(setAllocatorMaintenanceMode(allocator, enabled)),
    resetSetAllocatorMaintenanceModeRequest: () =>
      dispatch(resetSetAllocatorMaintenanceModeRequest(regionId, id)),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceModeButton)
