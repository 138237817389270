/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { Component } from 'react'

import { shouldRefreshAuthToken } from '@/lib/auth'

import type { AllProps as Props } from './types'

const TIMER_INTERVAL = 1000 * 15 // check every 15 seconds

class RefreshApiToken extends Component<Props> {
  timer: number | null = null

  unmounted: boolean = false

  componentDidMount() {
    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.unmounted = true
  }

  render() {
    return null
  }

  start() {
    this.timer = window.setInterval(this.refreshTokenTimer, TIMER_INTERVAL)
  }

  refreshTokenTimer = () => {
    if (this.unmounted) {
      return
    }

    if (!shouldRefreshAuthToken()) {
      return
    }

    this.props.refreshToken()
  }

  stop() {
    if (this.timer) {
      window.clearInterval(this.timer)
    }

    this.timer = null
  }
}

export default RefreshApiToken
