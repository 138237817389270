/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'
import { get } from 'lodash'

import { EuiDescribedFormGroup, EuiFieldText, EuiFormRow } from '@elastic/eui'

import DocLink from '../../../../../../../components/DocLink'
import { CuiFormField } from '../../../../../../../cui/forms'
import { labels, messages, sections } from '../../authProviderMessages'
import { convertNameToRealmId } from '../../convertNameToRealmId'

import type { FunctionComponent } from 'react'

const GeneralSettings: FunctionComponent<any> = () => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.generalSamlSettings} />
      </h3>
    }
    description={
      <FormattedMessage
        {...sections.generalSamlSettingsDescription}
        values={{
          learnMore: (
            <DocLink link='samlProviderGeneralSettingsDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <Field name='name'>
      {({
        field,
        form: {
          values: { form_mode },
          errors,
          touched,
          setFieldValue,
        },
      }) => {
        const error = get(touched, field.name) && errors[field.name]

        return (
          <EuiFormRow
            label={<FormattedMessage {...labels.profileName} />}
            helpText={<FormattedMessage {...labels.profileNameHelpText} />}
            isInvalid={error != null}
            error={error}
          >
            <EuiFieldText
              {...field}
              onChange={(e) => {
                field.onChange(e)

                if (form_mode === 'create') {
                  setFieldValue('id', convertNameToRealmId(e.target.value))
                }
              }}
            />
          </EuiFormRow>
        )
      }}
    </Field>

    <CuiFormField label={<FormattedMessage {...labels.realmId} />} name='id' isDisabled={true} />

    <CuiFormField
      label={<FormattedMessage {...labels.assertionConsumerServiceUrl} />}
      name='assertion_consumer_service_url'
      helpText={<FormattedMessage {...labels.assertionConsumerServiceUrlHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.logoutUrl} />}
      name='logout_url'
      helpText={<FormattedMessage {...labels.logoutUrlHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.identityProviderEntityId} />}
      name='identity_provider_entity_id'
      helpText={<FormattedMessage {...labels.identityProviderEntityIdHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.serviceProviderEntityId} />}
      name='service_provider_entity_id'
      helpText={<FormattedMessage {...labels.serviceProviderEntityIdHelpText} />}
    />
  </EuiDescribedFormGroup>
)

export default GeneralSettings
