/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { camelCase, cloneDeep, set, sortBy } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiSpacer, EuiLink, EuiToolTip } from '@elastic/eui'

import { getApmMode, ApmMode } from '@/lib/apmClusters/getApmMode'
import { filterToRelevantInstanceConfigs } from '@/lib/instanceConfigurations/instanceConfiguration'

import { CuiSliderLogo } from '../../../../../cui'
import {
  getSupportedSliderInstanceTypes,
  getSliderPrettyName,
  getSliderWeight,
  getSliderDefinition,
} from '../../../../../lib/sliders'
import {
  getPlugins,
  getEsPlan,
  getSliderNodeTypeForTopologyElement,
  getFirstSliderCluster,
  getPlanConfiguration,
  getUserSettingsFromConfiguration,
  hasOnlyPlanLevelSettings,
  getSliderPlan,
} from '../../../../../lib/stackDeployments/selectors'
import { getTopologiesFromTemplate } from '../../../../../lib/deploymentTemplates/getTopologiesFromTemplate'
import { getCustomPluginsFromPlan } from '../../../../../lib/plugins'
import { getDefaultUserSettings } from '../../../../../lib/stackDeployments/userSettings'
import { getConfigForKey } from '../../../../../store'
import { lt } from '../../../../../lib/semver'

import Flyout from './Flyout'
import Plugins from './Plugins'
import Extensions from './Extensions'
import Settings from './Settings'
import UserSettings from './UserSettings'
import TemplateTopologyElement from './TemplateTopologyElement'

import type { Props as DeploymentInfrastructureProps } from './DeploymentInfrastructure'
import type { Props as FlyoutProps } from './Flyout'
import type { AnyTopologyElement, SliderInstanceType } from '../../../../../types'
import type {
  DeploymentCreateRequest,
  DeploymentTemplateInfoV2,
} from '../../../../../lib/api/v1/types'

export interface Props {
  deployment: DeploymentCreateRequest
  deploymentUnderEdit?: DeploymentInfrastructureProps['deploymentUnderEdit']
  instanceConfigurations: DeploymentTemplateInfoV2['instance_configurations']
  updateDeploymentTemplate: undefined | ((deploymentTemplate: DeploymentCreateRequest) => void)
}

interface State {
  showFlyout: null | SliderInstanceType
}

export default class DeploymentTemplateInfrastructure extends Component<Props, State> {
  state = {
    showFlyout: null,
  }

  render(): JSX.Element {
    return <Fragment>{this.renderResources()}</Fragment>
  }

  renderResources(): JSX.Element {
    const sliderInstanceTypes = getSupportedSliderInstanceTypes().filter(
      (sliderInstanceType) => this.getSliderNodeConfigurations(sliderInstanceType)?.length,
    )

    return (
      <Fragment>
        {sliderInstanceTypes.map((sliderInstanceType) => (
          <div
            key={sliderInstanceType}
            data-test-id={`type-${sliderInstanceType === `elasticsearch` ? `es` : `slider`}`}
            data-id={sliderInstanceType}
          >
            <EuiFlexGroup gutterSize='m' alignItems='center' wrap={true} responsive={false}>
              <EuiFlexItem grow={false}>
                <CuiSliderLogo sliderInstanceType={sliderInstanceType} size='l' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiTitle size='s'>
                  <div>
                    <FormattedMessage
                      {...getSliderPrettyName({
                        sliderInstanceType,
                      })}
                    />
                  </div>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
              <EuiFlexItem grow={false}>
                {this.renderFlyoutButton({ sliderInstanceType })}
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='m' />
            {this.renderTopologyElements(sliderInstanceType)}
            {sliderInstanceType === `elasticsearch`
              ? this.renderElasticsearchFlyout()
              : this.renderSliderFlyout({ sliderInstanceType })}
          </div>
        ))}
      </Fragment>
    )
  }

  renderFlyoutButton({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment, deploymentUnderEdit } = this.props

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    if (sliderInstanceType === 'integrations_server') {
      return null
    }

    if (sliderInstanceType === `apm`) {
      if (getApmMode({ deployment: deploymentUnderEdit }) === ApmMode.Managed) {
        return null
      }
    }

    if (sliderInstanceType === `elasticsearch`) {
      const plan = getEsPlan({ deployment })

      const pluginCount = getPlugins({ deployment }).length
      const extensionCount = plan ? getCustomPluginsFromPlan(plan).length : 0
      const totalCount = pluginCount + extensionCount
      const extensionsTooltipMsg = (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyoutButton-tooltip'
          defaultMessage='Extensions are plugins or bundles that you can add to customize Elasticsearch.'
        />
      )

      return (
        <EuiLink
          data-test-subj='deploymentInfrastructure-elasticsearchFlyoutButton'
          onClick={() => this.setState({ showFlyout: `elasticsearch` })}
        >
          <EuiToolTip content={extensionsTooltipMsg}>
            {this.getLinkText(resource, totalCount)}
          </EuiToolTip>
        </EuiLink>
      )
    }

    if (!hasOnlyPlanLevelSettings(resource)) {
      return null
    }

    return (
      <EuiLink
        data-test-subj='deploymentInfrastructure-sliderFlyoutButton'
        onClick={() => this.setState({ showFlyout: sliderInstanceType })}
      >
        {this.isEditable() ? (
          <FormattedMessage
            id='deploymentInfrastructure-sliderFlyoutButton'
            defaultMessage='Edit user settings'
          />
        ) : (
          <FormattedMessage
            id='deploymentInfrastructure-sliderFlyoutButton-readOnly'
            defaultMessage='View user settings'
          />
        )}
      </EuiLink>
    )
  }

  renderTopologyElements(sliderInstanceType: SliderInstanceType): JSX.Element[] {
    const { deployment, instanceConfigurations } = this.props

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })
    const topologyElements = this.getSliderNodeConfigurations(sliderInstanceType)

    if (!resource) {
      return [] // sanity
    }

    return topologyElements.map((topologyElement, i) => {
      const key = `${sliderInstanceType}-${i}`

      const relevantInstanceConfigs = filterToRelevantInstanceConfigs({
        sliderInstanceType,
        topologyElement,
        instanceConfigurations,
      })

      return (
        <TemplateTopologyElement
          key={key}
          id={key}
          deployment={deployment}
          sliderInstanceType={sliderInstanceType}
          topologyElement={topologyElement}
          instanceConfigurations={relevantInstanceConfigs}
          onChange={
            this.isEditable() &&
            ((path, value) => this.onTopologyElementChange(topologyElement, path, value))
          }
          onPlanChange={
            this.isEditable() &&
            ((path, value) => this.onPlanChange(sliderInstanceType, path, value))
          }
          onResourceChange={
            this.isEditable() &&
            ((path, value) => this.onResourceChange(sliderInstanceType, path, value))
          }
        />
      )
    })
  }

  renderElasticsearchFlyout(): JSX.Element | null {
    const { deployment } = this.props
    const { showFlyout } = this.state

    if (showFlyout !== `elasticsearch`) {
      return null
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType: `elasticsearch` })

    if (!resource) {
      return null // sanity
    }

    const title = (
      <FormattedMessage
        id='deploymentInfrastructure-elasticsearchFlyout-title'
        defaultMessage='Elasticsearch user settings and extensions'
      />
    )

    const tabs: FlyoutProps['tabs'] = []

    if (hasOnlyPlanLevelSettings(resource)) {
      tabs.push({
        id: `userSettings`,
        name: (
          <FormattedMessage
            id='deploymentInfrastructure-elasticsearchFlyout-userSettings-title'
            defaultMessage='User Settings'
          />
        ),
      })
    }

    tabs.push({
      id: `plugins`,
      name: (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyout-plugins-title'
          defaultMessage='Extensions'
        />
      ),
    })

    const plan = getEsPlan({ deployment })!
    const { version } = plan.elasticsearch

    // v6+ scripting is not configurable via the UI
    if (version != null && lt(version, `6.0.0`)) {
      tabs.push({
        id: `settings`,
        name: (
          <FormattedMessage
            id='deploymentInfrastructure-elasticsearchFlyout-settings-title'
            defaultMessage='System Settings'
          />
        ),
      })
    }

    return (
      <Flyout title={title} tabs={tabs} onClose={() => this.setState({ showFlyout: null })}>
        {(activeTabId) => {
          switch (activeTabId) {
            case `userSettings`:
              return this.renderUserSettings({ sliderInstanceType: `elasticsearch` })
            case `plugins`:
              return (
                <Fragment>
                  {getConfigForKey(`APP_NAME`) === `userconsole` && (
                    <Extensions
                      deployment={deployment}
                      onPluginsChange={this.isEditable() && this.onPluginsChange}
                    />
                  )}
                  <Plugins
                    deployment={deployment}
                    versionConfig={undefined}
                    onPluginsChange={this.isEditable() && this.onPluginsChange}
                  />
                </Fragment>
              )
            case `settings`:
              return (
                <Settings
                  deployment={deployment}
                  onChange={
                    this.isEditable() &&
                    ((topologyElement: AnyTopologyElement, path: string[], value: any) =>
                      this.onTopologyElementChange(topologyElement, path, value))
                  }
                />
              )
            default:
              return null
          }
        }}
      </Flyout>
    )
  }

  renderSliderFlyout({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment } = this.props

    if (this.state.showFlyout !== sliderInstanceType) {
      return null
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    if (!hasOnlyPlanLevelSettings(resource)) {
      return null
    }

    const title = (
      <FormattedMessage
        id='deploymentInfrastructure-sliderFlyout-title'
        defaultMessage='User settings'
      />
    )

    return (
      <Flyout title={title} onClose={() => this.setState({ showFlyout: null })}>
        {() => this.renderUserSettings({ sliderInstanceType })}
      </Flyout>
    )
  }

  renderUserSettings({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment } = this.props

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    const settings = getUserSettingsFromConfiguration(getPlanConfiguration(resource))
    const defaultSettings = getDefaultUserSettings(sliderInstanceType)

    const {
      messages: { prettyName },
      userSettingsFileName,
    } = getSliderDefinition({ sliderInstanceType })
    const docLink = `${camelCase(sliderInstanceType)}UserSettingsDocLink`

    return (
      <UserSettings
        settings={settings == null ? defaultSettings : settings}
        isReadOnly={!this.isEditable()}
        onChange={
          this.isEditable() &&
          ((value: any) => {
            this.onPlanChange(sliderInstanceType, [sliderInstanceType, `user_settings_yaml`], value)
          })
        }
        prettyName={prettyName}
        fileName={userSettingsFileName}
        docLink={docLink}
      />
    )
  }

  getLinkText(resource, totalCount): JSX.Element {
    if (this.isEditable()) {
      if (hasOnlyPlanLevelSettings(resource)) {
        return (
          <FormattedMessage
            id='deploymentInfrastructure-elasticsearchFlyoutButton-with-userSettings'
            defaultMessage='Manage user settings and extensions ({totalCount})'
            values={{ totalCount }}
          />
        )
      }

      return (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyoutButton-without-userSettings'
          defaultMessage='Manage settings and extensions ({totalCount})'
          values={{ totalCount }}
        />
      )
    }

    if (hasOnlyPlanLevelSettings(resource)) {
      return (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyoutButton-with-userSettings-readOnly'
          defaultMessage='View user settings and extensions ({totalCount})'
          values={{ totalCount }}
        />
      )
    }

    return (
      <FormattedMessage
        id='deploymentInfrastructure-elasticsearchFlyoutButton-without-userSettings-readOnly'
        defaultMessage='View settings and extensions ({totalCount})'
        values={{ totalCount }}
      />
    )
  }

  isEditable = (): true | undefined => Boolean(this.props.updateDeploymentTemplate) || undefined

  getSliderNodeConfigurations = (sliderInstanceType: SliderInstanceType): AnyTopologyElement[] => {
    const { deployment } = this.props

    const topologyElements = getTopologiesFromTemplate({
      deploymentTemplate: deployment,
      sliderInstanceType,
    })

    return sortBy(topologyElements, (topologyElement) => {
      const sliderNodeType = getSliderNodeTypeForTopologyElement({
        topologyElement,
      })

      return getSliderWeight(sliderInstanceType, sliderNodeType)
    })
  }

  // These change methods all call `applyRootRelativeChange`, which clones the
  // DeploymentCreateRequest and applies a single change. The
  // `updateDeploymentTemplate` state change will clobber successive calls, so
  // any change needs to be atomic. If you need to set multiple fields do it by
  // cloning the appropriate object and making a single change that encompasses
  // the full delta.

  onTopologyElementChange = (topologyElement: AnyTopologyElement, path: string[], value: any) => {
    const { deployment } = this.props

    // Unfortunately topology elements don't *strictly* know what resource they
    // belong to in isolation, so we have to go with a janky "loop through all
    // the resources till you find an object match" approach to make a topology
    // element change.
    getSupportedSliderInstanceTypes().forEach((sliderInstanceType) => {
      const plan = getSliderPlan({ deployment, sliderInstanceType })
      const configs: AnyTopologyElement[] = plan?.cluster_topology || []
      const index = configs.indexOf(topologyElement)

      if (index !== -1) {
        this.applyRootRelativeChange(
          sliderInstanceType,
          [`plan`, `cluster_topology`, index, ...path],
          value,
        )
      }
    })
  }

  onPlanChange = (sliderInstanceType: SliderInstanceType, path: string[], value: any) => {
    this.applyRootRelativeChange(sliderInstanceType, [`plan`, ...path], value)
  }

  onResourceChange = (sliderInstanceType: SliderInstanceType, path: string[], value: any) => {
    this.applyRootRelativeChange(sliderInstanceType, path, value)
  }

  onPluginsChange: DeploymentInfrastructureProps['onPluginsChange'] = ({
    path = [`elasticsearch`, `enabled_built_in_plugins`], // user bundles/plugins paths passed in to override this as appropriate
    plugins,
  }) => {
    this.onPlanChange(`elasticsearch`, path, plugins)
  }

  applyRootRelativeChange = (
    sliderInstanceType: SliderInstanceType,
    path: Array<string | number>,
    value: any,
  ) => {
    const { updateDeploymentTemplate } = this.props

    if (!updateDeploymentTemplate) {
      return // sanity
    }

    // we're about to mutate this in place, so make a copy
    const deployment = cloneDeep(this.props.deployment)
    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return // sanity
    }

    set(resource, path, value)

    updateDeploymentTemplate(deployment)
  }
}
