/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiTable } from '@/cui'

import type { FunctionComponent } from 'react'
import type { Subscription } from '@/lib/api/v1/types'
import type { CuiTableColumn } from '@/cui'

interface Props {
  prepaidSubscriptions: Subscription[] | undefined
  initialLoading: boolean
}

const OrganizationPrepaidSubscriptionsTable: FunctionComponent<Props> = ({
  prepaidSubscriptions,
  initialLoading,
}) => {
  const columns: Array<CuiTableColumn<Subscription>> = [
    {
      label: (
        <FormattedMessage id='organization.organization-prepaids-table.id' defaultMessage='ID' />
      ),
      render: ({ subscription_id }) => subscription_id,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-prepaids-table.type'
          defaultMessage='Type'
        />
      ),
      render: ({ subscription_type }) => subscription_type,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-prepaids-table.range'
          defaultMessage='Range'
        />
      ),
      render: ({ subscription_range: { start, end } }) => `${start} - ${end}`,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-prepaids-table.created'
          defaultMessage='Created'
        />
      ),
      render: ({ created }) => created,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-prepaids-table.last-modified'
          defaultMessage='Last modified'
        />
      ),
      render: ({ last_modified }) => last_modified,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-prepaids-table.data'
          defaultMessage='Data'
        />
      ),
      render: ({ data }) => JSON.stringify(data),
    },
  ]

  return (
    <CuiTable<Subscription>
      rows={prepaidSubscriptions}
      columns={columns}
      initialLoading={initialLoading}
    />
  )
}

export default OrganizationPrepaidSubscriptionsTable
