/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiTourStep, EuiText } from '@elastic/eui'

import LocalStorageKey from '../../constants/localStorageKeys'

import type { FunctionComponent } from 'react'

const NavbarHealthTourStep: FunctionComponent = () => {
  // Get intial localstorage state for the tour and initialize the isActive flag accordingly
  const localStorageState = localStorage.getItem(LocalStorageKey.deploymentHealthStatusTour)
  const lsTourState = localStorageState === null ? true : JSON.parse(localStorageState)
  const [isActive, setIsActive] = useState(lsTourState)

  const onFinishTour = useCallback(
    (event: React.MouseEvent) => {
      // Since the tour step is rendered in the navigation bar, we need to stop the event
      // from propagating so that it doesnt trigger a redirect to the health page.
      event.stopPropagation()

      setIsActive(false)

      localStorage.setItem(LocalStorageKey.deploymentHealthStatusTour, JSON.stringify(false))
    },
    [setIsActive],
  )

  return (
    <EuiTourStep
      content={
        <EuiText>
          <FormattedMessage
            id='navigation.deployment.health.tour-content'
            defaultMessage='Check your deployment health issues centralized in one place.'
          />
        </EuiText>
      }
      isStepOpen={isActive}
      offset={30}
      maxWidth={400}
      // @ts-ignore - We need to capture the event so that we can stop the propagation
      onFinish={onFinishTour}
      step={1}
      stepsTotal={1}
      subtitle={
        <FormattedMessage id='navigation.deployment.health.tour-subtitle' defaultMessage='New' />
      }
      title={
        <FormattedMessage
          id='navigation.deployment.health.tour-title'
          defaultMessage='Dedicated health page'
        />
      }
      anchorPosition='rightUp'
    >
      <React.Fragment>
        <FormattedMessage id='navigation.deployment.healthStatus' defaultMessage='Health' />
      </React.Fragment>
    </EuiTourStep>
  )
}

export default NavbarHealthTourStep
