/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchPlatformOverview } from '../../actions/platform'
import { fetchPlatformRequest, getPlatformOverview } from '../../reducers'

import PlatformRegionsRoute from './PlatformRegionsRoute'

import type { AsyncRequestState } from '../../types'
import type { PlatformInfo } from '../../lib/api/v1/types'

type StateProps = {
  platform: PlatformInfo | null
  fetchPlatformRequest: AsyncRequestState
}

type DispatchProps = {
  fetchPlatformOverview: () => void
}

interface ConsumerProps {}

const mapStateToProps = (state): StateProps => ({
  platform: getPlatformOverview(state),
  fetchPlatformRequest: fetchPlatformRequest(state),
})

const mapDispatchToProps: DispatchProps = {
  fetchPlatformOverview,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PlatformRegionsRoute)
