/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { postDeploymentResourceProxyRequestsUrl } from '../../lib/api/v1/urls'
import { RESTORE_SNAPSHOT } from '../../constants/actions'
import { getFirstEsRefId } from '../../lib/stackDeployments/selectors'

import { REPOSITORY_NAME } from './constants'

import type { Action, ClusterSnapshotAction, StackDeployment, ThunkAction } from '../../types'

export function restoreSnapshot({
  deployment,
  snapshotName,
  payload,
}: {
  deployment: StackDeployment
  snapshotName: string
  payload: any
}): ThunkAction<Promise<ClusterSnapshotAction>> {
  return (dispatch) => {
    const { id: deploymentId } = deployment
    const refId = getFirstEsRefId({ deployment })

    const url = postDeploymentResourceProxyRequestsUrl({
      deploymentId,
      resourceKind: 'elasticsearch',
      refId: refId!,
      proxyPath: `_snapshot/${REPOSITORY_NAME}/${snapshotName}/_restore`,
    })

    return dispatch(
      asyncRequest({
        type: RESTORE_SNAPSHOT,
        url,
        method: `post`,
        payload,
        meta: { deployment },
        crumbs: [deploymentId],
        requestSettings: {
          request: {
            headers: {
              'X-Management-Request': true,
            },
          },
        },
      }),
    )
  }
}

export function resetRestoreSnapshot(deploymentId: string): Action<'RESET_ASYNC_REQUEST'> {
  return resetAsyncRequest(RESTORE_SNAPSHOT, [deploymentId])
}
