/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { getCostsDeploymentsUrl, getCostsItemsByDeploymentUrl } from '@/lib/api/v1/urls'

import type { Moment } from 'moment'

const dateFormat = 'YYYY-MM-DD'

interface DownloadCSVArgs {
  organizationId: string
  startDate: Moment
  endDate: Moment
  selectedDeploymentId?: string
}

const downloadCSV = async ({
  organizationId,
  startDate,
  endDate,
  selectedDeploymentId,
}: DownloadCSVArgs): Promise<void> => {
  let url

  if (selectedDeploymentId) {
    url = getCostsItemsByDeploymentUrl({
      from: startDate?.format(dateFormat),
      to: endDate?.format(dateFormat),
      organizationId,
      deploymentId: selectedDeploymentId,
    })
  } else {
    url = getCostsDeploymentsUrl({
      organizationId,
      from: startDate?.format(dateFormat),
      to: endDate?.format(dateFormat),
    })
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: { Accept: 'text/csv' },
  })

  if (response.status < 200 || response.status >= 300) {
    throw new Error('Could not download CSV')
  }

  const blob = await response.blob()
  const link = document.createElement('a')
  const fileName = getFileName({ startDate, endDate, selectedDeploymentId })

  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName}.csv`
  link.click()
  link.remove()
}

const getFileName = ({
  startDate,
  endDate,
  selectedDeploymentId,
}: Omit<DownloadCSVArgs, 'organizationId'>): string => {
  const formattedStartDate = startDate.format(dateFormat)
  const formattedEndDate = endDate.format(dateFormat)

  if (selectedDeploymentId) {
    return `costs_${selectedDeploymentId}_${formattedStartDate}_${formattedEndDate}`
  }

  return `costs_${formattedStartDate}_${formattedEndDate}`
}

export default downloadCSV
