/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import { FETCH_MFA_DEVICES } from '../../constants/actions'

export function fetchMfaDevices(userId: string) {
  const url = `api/v1/saas/users/${userId}/auth/mfa/devices`

  return asyncRequest({
    type: FETCH_MFA_DEVICES,
    url,
    meta: { userId },
    crumbs: [userId],
    handleUnauthorized: true,
  })
}
