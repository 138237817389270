/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiContextMenuItem, EuiContextMenuPanel, EuiPopover } from '@elastic/eui'

import history from '../../../../../../lib/history'
import {
  addActiveDirectoryAuthenticationProviderUrl,
  addLdapAuthenticationProviderUrl,
  addSamlAuthenticationProviderUrl,
} from '../../../../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { RegionId } from '../../../../../../types'

interface Props {
  regionId: RegionId
  disabled?: boolean
}

const AddProviderButton: FunctionComponent<Props> = ({ disabled, regionId }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const button = (
    <EuiButton
      data-test-id='add-auth-provider-button'
      isDisabled={disabled}
      iconType='arrowDown'
      fill={true}
      iconSide='left'
      onClick={() => setPopoverOpen(!isPopoverOpen)}
    >
      <FormattedMessage id='add-auth-provider-button.button-label' defaultMessage='Add provider' />
    </EuiButton>
  )

  const items = [
    <EuiContextMenuItem
      data-test-id='add-active-directory-auth-provider-button'
      key='active-directory'
      onClick={() => history.push(addActiveDirectoryAuthenticationProviderUrl(regionId))}
    >
      <FormattedMessage
        id='add-auth-provider-button.active-directory'
        defaultMessage='Active Directory'
      />
    </EuiContextMenuItem>,

    <EuiContextMenuItem
      data-test-id='add-ldap-auth-provider-button'
      key='ldap'
      onClick={() => history.push(addLdapAuthenticationProviderUrl(regionId))}
    >
      <FormattedMessage id='add-auth-provider-button.ldap' defaultMessage='LDAP' />
    </EuiContextMenuItem>,

    <EuiContextMenuItem
      data-test-id='add-saml-auth-provider-button'
      key='saml'
      onClick={() => history.push(addSamlAuthenticationProviderUrl(regionId))}
    >
      <FormattedMessage id='add-auth-provider-button.saml' defaultMessage='SAML' />
    </EuiContextMenuItem>,
  ]

  return (
    <EuiPopover
      id='add-auth-provider-button-popover'
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
      panelPaddingSize='s'
      anchorPosition='downLeft'
    >
      <EuiContextMenuPanel items={items} />
    </EuiPopover>
  )
}

export default AddProviderButton
