/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeploymentAliasEditAccess,
  updateDeploymentAliasEditAccess,
} from '../../../actions/deploymentAlias'
import {
  deploymentDomainAliasEditAccessRequest,
  fetchDeploymentDomainAliasEditAccessRequest,
  updateDeploymentDomainAliasEditAccessRequest,
  deploymentAliasEditAccess,
} from '../../../reducers'

import DeploymentAliasConfig from './DeploymentAliasConfig'

import type { AsyncRequestState, ReduxState } from '../../../types'

type ConsumerProps = {
  regionId: string
}

type StateProps = {
  deploymentAliasEditAccess: boolean
  deploymentDomainAliasEditAccessRequest: AsyncRequestState
  getDeploymentDomainAliasEditAccessRequest: AsyncRequestState
  updateDeploymentDomainAliasEditAccessRequest: AsyncRequestState
}

type DispatchProps = {
  updateDeploymentAliasEditAccess: (params: {
    regionId: string
    editAccess: boolean
  }) => Promise<any>
  fetchDeploymentAliasEditAccess: (params: { regionId: string }) => Promise<any>
}

const mapStateToProps = (state: ReduxState, { regionId }): StateProps => ({
  deploymentAliasEditAccess: deploymentAliasEditAccess(state),
  deploymentDomainAliasEditAccessRequest: deploymentDomainAliasEditAccessRequest(state, regionId),
  getDeploymentDomainAliasEditAccessRequest: fetchDeploymentDomainAliasEditAccessRequest(
    state,
    regionId,
  ),

  updateDeploymentDomainAliasEditAccessRequest: updateDeploymentDomainAliasEditAccessRequest(
    state,
    regionId,
  ),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  updateDeploymentAliasEditAccess: ({
    regionId,
    editAccess,
  }: {
    regionId: string
    editAccess: boolean
  }) => dispatch(updateDeploymentAliasEditAccess({ regionId, editAccess })),
  fetchDeploymentAliasEditAccess: ({ regionId }: { regionId: string }) =>
    dispatch(fetchDeploymentAliasEditAccess({ regionId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentAliasConfig)
