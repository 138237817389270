/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../../reducers'

import CreditAccountButton from './CreditAccountButton'

import type { AsyncRequestState, ReduxState } from '../../../../types'

type StateProps = {
  creditAccountRequest: AsyncRequestState
}

type DispatchProps = {
  creditAccount: ({ amount, description, reason }) => Promise<any>
  resetCreditAccount: () => void
}

type ConsumerProps = {
  organizationId: string
  currentUserUsername: string | null
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  creditAccountRequest: callStoredProcedureRequest(state, `credit_organization`),
})

const mapDispatchToProps = (
  dispatch,
  { organizationId, currentUserUsername }: ConsumerProps,
): DispatchProps => {
  const username = currentUserUsername || 'admin'
  const requestedByEmail = username.includes('@') ? username : `${username}@elastic.co`

  return {
    creditAccount: ({ amount, description, reason }) =>
      dispatch(
        callStoredProcedure({
          procedureName: `credit_organization`,
          parameters: [organizationId, amount, description, reason, requestedByEmail],
          organizationId,
        }),
      ),
    resetCreditAccount: () => {
      dispatch(resetCallStoredProcedureRequest(`credit_organization`))
      return dispatch(clearProcedure(`credit_organization`))
    },
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreditAccountButton)
