/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiLoadingContent, EuiSpacer, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiAlert } from '@/cui'
import Header from '@/components/Header'
import { viewSaasUserCrumbs } from '@/lib/crumbBuilder'
import UserOverview from '@/components/User/UserOverview'

import type { ReactNode } from 'react'
import type { AllProps as Props } from './types'

class User extends Component<Props> {
  componentDidMount(): void {
    const { lookupSaasUsers, fetchSaasUser, userId } = this.props

    if (!lookupSaasUsers) {
      return
    }

    fetchSaasUser(userId)
  }

  render(): ReactNode {
    const { profile, fetchSaasUserRequest, lookupSaasUsers, userId } = this.props

    if (!lookupSaasUsers) {
      return null
    }

    if (fetchSaasUserRequest.error) {
      return <CuiAlert type='error'>{fetchSaasUserRequest.error}</CuiAlert>
    }

    if (!profile) {
      return <EuiLoadingContent />
    }

    return (
      <Fragment>
        <Header
          name={this.renderHeader()}
          breadcrumbs={viewSaasUserCrumbs(userId)}
          bottomBorder={true}
        />

        <UserOverview profile={profile} />

        <EuiSpacer size='xl' />
      </Fragment>
    )
  }

  renderHeader(): ReactNode {
    const {
      profile: {
        user: { email, email_verified },
      },
    } = this.props

    const isEmailVerified = email_verified !== undefined

    return (
      <EuiFlexGroup alignItems='center' responsive={false}>
        <EuiFlexItem data-test-id='header'>{email}</EuiFlexItem>

        <EuiFlexItem grow={false}>
          {isEmailVerified ? (
            <EuiBadge color='success' iconType='check'>
              <FormattedMessage id='user-overview.email-verified' defaultMessage='Verified email' />
            </EuiBadge>
          ) : (
            <EuiBadge color='warning' iconType='cross'>
              <FormattedMessage
                id='user-overview.email-unverified'
                defaultMessage='Email not verified'
              />
            </EuiBadge>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }
}

export default User
