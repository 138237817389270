/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'

import { EuiSpacer, EuiCard, EuiLoadingSpinner, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { deploymentVersionWithESHealthAPI } from '@/constants/esVersion'
import { DEPLOYMENT_HEALTH_API_ID } from '@/constants/health'
import {
  getHighestSeverity,
  shouldShowProblemInHealthPage,
  shouldShowProblemInHealthAndHasHealthAPI,
} from '@/lib/healthProblems'

import DeploymentHealthStatus from '../DeploymentHealthStatus'
import { isActivityPage } from '../DeploymentWrapper'
import { hasOngoingConfigurationChange } from '../../../lib/stackDeployments/selectors'
import { gte } from '../../../lib/semver'
import DeploymentVersionUpgradeModal from '../DeploymentVersion/UpgradableDeploymentVersion/DeploymentVersionUpgradeModal'

import { HealthyDeployment } from './HealthyDeployment'
import { HealthProblemsTable } from './HealthProblemsTable'
import { UnavailableHealthApi } from './UnavailableHealthApi'
import { UpgradeDeploymentPrompt } from './UpgradeDeployment'

import type { Problem } from '../../../lib/healthProblems/problems'
import type { StackVersionConfig } from '@/lib/api/v1/types'
import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { StackDeployment, AsyncRequestState } from '../../../types'
import type { FunctionComponent } from 'react'

export type StateProps = {
  stackDeployment: StackDeployment | null
  problems: Problem[]
  version: string | null
  versionStack?: StackVersionConfig
  lowestVersion: string
  deploymentHealthRequest: AsyncRequestState
}

export type DispatchProps = {
  fetchVersionStack: (version: string, regionId: string) => Promise<{ payload: StackVersionConfig }>
}

export type OwnProps = WithStackDeploymentRouteParamsProps

export type Props = StateProps & DispatchProps & OwnProps

const HealthStatus: FunctionComponent<Props> = ({
  stackDeployment,
  problems,
  location,
  version,
  regionId,
  versionStack,
  fetchVersionStack,
  lowestVersion,
  deploymentHealthRequest,
  stackDeploymentId,
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const onActivityPage = isActivityPage(location)
  const currentClusterHasHealthApi =
    version !== null && gte(version, deploymentVersionWithESHealthAPI)
  const healthSeverity = getHighestSeverity(problems)

  // We should only show the upgrade prompt to users that are on a cluster version that
  // doesnt support the health api.
  const hasOngoingConfiguration = hasOngoingConfigurationChange({ deployment: stackDeployment! })
  const showUpgradeDeploymentPrompt =
    !currentClusterHasHealthApi && !hasOngoingConfiguration && healthSeverity !== 'danger'

  const onUpgradeClick = async () => {
    // This API call uses asyncRequest and errors for it are handled by the wrapper of
    // this page.
    await fetchVersionStackIfNeeded()
    setShowUpgradeModal(true)
  }

  const fetchVersionStackIfNeeded = async () => {
    if (!versionStack) {
      await fetchVersionStack(lowestVersion, regionId)
    }
  }

  // Select all the problems that should be shown in the health table
  const problemsForHealthTable = problems.filter(
    (problem) => problem.kind === DEPLOYMENT_HEALTH_API_ID,
  )

  // This `problems` array is used to show problems in the legacy banner. If the current stack version that
  // we are on supports the health api, then we want to remove the es health problems from this array since
  // they are gonna be shown separately from the banner in the Health Problems Table.
  const bannerProblems = problems.filter(
    (problem) =>
      shouldShowProblemInHealthPage(problem) && problem.kind !== DEPLOYMENT_HEALTH_API_ID,
  )

  if (deploymentHealthRequest.inProgress && problems.length === 0 && !showUpgradeDeploymentPrompt) {
    return (
      <React.Fragment>
        <EuiSpacer size='xxl' />
        <EuiFlexGroup justifyContent='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner data-test-subj='health-status-loading-spinner' size='xl' />
          </EuiFlexItem>
        </EuiFlexGroup>
      </React.Fragment>
    )
  }

  if (deploymentHealthRequest.error) {
    return (
      <React.Fragment>
        {bannerProblems.length > 0 && (
          <DeploymentHealthStatus
            stackDeployment={stackDeployment}
            hideActivityBits={onActivityPage}
            filterProblems={shouldShowProblemInHealthPage}
          />
        )}
        <EuiSpacer size='xxl' />
        <UnavailableHealthApi />
      </React.Fragment>
    )
  }

  if (
    bannerProblems.length === 0 &&
    problemsForHealthTable.length === 0 &&
    currentClusterHasHealthApi
  ) {
    return (
      <React.Fragment>
        <EuiSpacer size='xxl' />
        {!showUpgradeDeploymentPrompt && <HealthyDeployment />}

        {showUpgradeDeploymentPrompt && (
          <React.Fragment>
            <EuiSpacer size='xxl' />
            <UpgradeDeploymentPrompt onUpgradeClick={onUpgradeClick} />
          </React.Fragment>
        )}
        {showUpgradeModal && (
          <DeploymentVersionUpgradeModal
            deployment={stackDeployment!}
            isLoadingRegion={!regionId}
            redirectOnUpgrade={false}
            onCancel={() => setShowUpgradeModal(false)}
          />
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {(bannerProblems.length > 0 ||
        (!currentClusterHasHealthApi && problemsForHealthTable.length > 0)) && (
        <React.Fragment>
          <DeploymentHealthStatus
            stackDeployment={stackDeployment}
            hideActivityBits={onActivityPage}
            filterProblems={
              currentClusterHasHealthApi
                ? shouldShowProblemInHealthAndHasHealthAPI
                : shouldShowProblemInHealthPage
            }
          />
          <EuiSpacer size='m' />
        </React.Fragment>
      )}
      {currentClusterHasHealthApi && problemsForHealthTable.length > 0 && (
        <React.Fragment>
          <EuiCard title='Issues' textAlign='left' paddingSize='none' display='transparent'>
            <EuiSpacer size='l' />
            <HealthProblemsTable
              problems={problemsForHealthTable}
              deploymentId={stackDeploymentId}
            />
          </EuiCard>
        </React.Fragment>
      )}

      {showUpgradeDeploymentPrompt && (
        <React.Fragment>
          <EuiSpacer size='xxl' />
          <UpgradeDeploymentPrompt onUpgradeClick={onUpgradeClick} />
        </React.Fragment>
      )}
      {showUpgradeModal && (
        <DeploymentVersionUpgradeModal
          deployment={stackDeployment!}
          isLoadingRegion={!regionId}
          redirectOnUpgrade={false}
          onCancel={() => setShowUpgradeModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default HealthStatus
