/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'

import { getSliderPrettyName } from '@/lib/sliders'

import type { FunctionComponent } from 'react'
import type { HardwareSummaryProps as Props } from './types'

const HardwareSummary: FunctionComponent<Props> = ({
  instance: { name, size, storagePerInstance, sliderInstanceType, sliderNodeType: sliderNodeTypes },
}): JSX.Element => (
  <Fragment>
    <EuiText size='s'>{name}</EuiText>

    <EuiSpacer size='xs' />

    {sliderNodeTypes && sliderNodeTypes.length > 0 && (
      <Fragment>
        <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false} wrap={true}>
          {sliderNodeTypes.map((sliderNodeType, index) => (
            <Fragment key={`${sliderInstanceType}-${sliderNodeType}-${index}`}>
              {index === 0 || (
                <EuiFlexItem grow={false}>
                  <EuiText size='xs' color='subdued'>{`\u00b7`}</EuiText>
                </EuiFlexItem>
              )}

              <EuiFlexItem grow={false}>
                <EuiText size='xs' color='subdued'>
                  <FormattedMessage
                    {...getSliderPrettyName({
                      sliderInstanceType,
                      sliderNodeType,
                    })}
                  />
                </EuiText>
              </EuiFlexItem>
            </Fragment>
          ))}
        </EuiFlexGroup>

        <EuiSpacer size='xs' />
      </Fragment>
    )}

    <EuiText size='xs' color='subdued'>
      {storagePerInstance ? storagePerInstance : size}
    </EuiText>

    <EuiSpacer size='xs' />
  </Fragment>
)

export default HardwareSummary
