/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { filter, get, isEmpty } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlyout,
  EuiTitle,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiLoadingContent,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import { CuiButton, CuiButtonEmpty, CuiAlert, CuiPermissibleControl } from '../../../cui'
import DocLink from '../../DocLink'
import toggle from '../../../lib/arrayToggle'
import sortOn from '../../../lib/sortOn'
import { getConfigForKey } from '../../../store'
import Permission from '../../../lib/api/v1/permissions'

import ManageHostRolesForm from './ManageHostRolesForm'

import type { RunnerRoleInfo } from '../../../lib/api/v1/types'
import type { Allocator, AsyncRequestState, RegionRolesState, Runner } from '../../../types'

export interface Props {
  allocator?: Allocator
  allocatorRequest: AsyncRequestState
  fetchAllocator: (allocatorId: string) => Promise<any>
  fetchBlueprintRoles: () => Promise<any>
  fetchBlueprintRolesRequest: AsyncRequestState
  onClose: () => void
  regionId: string
  hostId: string
  resetUpdateRoles: () => void
  roles?: RegionRolesState[]
  runner: Runner
  updateRoles: (roles: RunnerRoleInfo[]) => Promise<any>
  updateRolesRequest: AsyncRequestState
}

interface State {
  roleIds: string[]
}

class ManageHostRoles extends Component<Props, State> {
  state: State = {
    roleIds: pluckRoleNames(this.props.runner.roles),
  }

  componentDidMount() {
    const { fetchAllocator, fetchBlueprintRoles, runner } = this.props

    if (isAllocator(runner)) {
      fetchAllocator(runner.runner_id)
    }

    fetchBlueprintRoles()
  }

  componentDidUpdate(prevProps: Props) {
    const { fetchAllocator, runner } = this.props

    if (isAllocator(prevProps.runner)) {
      return
    }

    // if we add the allocator role to a runner then we want to make sure we fetch the allocator
    if (isAllocator(runner)) {
      fetchAllocator(runner.runner_id)
    }
  }

  componentWillUnmount() {
    this.props.resetUpdateRoles()
  }

  render() {
    const { onClose } = this.props

    return (
      <EuiFlyout
        size='s'
        ownFocus={true}
        onClose={onClose}
        aria-labelledby='manageHostRolesFlyoutTitle'
      >
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='m'>
            <h2 id='manageHostRolesFlyoutTitle'>
              <FormattedMessage id='manage-host-roles-flyout.title' defaultMessage='Host roles' />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>{this.renderFlyoutBody()}</EuiFlyoutBody>

        <EuiFlyoutFooter>{this.renderFlyoutFooter()}</EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderFlyoutBody() {
    const platform = getConfigForKey(`APP_PLATFORM`)

    const description = (
      <FormattedMessage
        id='manage-host-roles.node-roles-docker-containers'
        defaultMessage='A host can take on several roles. Each role is associated with a set of Docker containers that provide a different functionality. We strongly recommend separating control plane roles from allocators and proxies.'
      />
    )

    return (
      <Fragment>
        <EuiText>
          {platform === `ece` ? (
            <FormattedMessage
              id='manage-host-roles.description-ece-join'
              defaultMessage='{description} {learnMoreLink}.'
              values={{
                description,
                learnMoreLink: (
                  <DocLink link='separateRunnerRolesLink'>
                    <FormattedMessage
                      id='roles-form.separate-roles-text'
                      defaultMessage='Learn more about assigning roles to hosts'
                    />
                  </DocLink>
                ),
              }}
            />
          ) : (
            description
          )}
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderRolesForm()}
      </Fragment>
    )
  }

  renderRolesForm() {
    const {
      roles,
      allocator,
      allocatorRequest,
      fetchBlueprintRolesRequest,
      runner,
      regionId,
      hostId,
    } = this.props

    const { roleIds } = this.state

    const shouldSpin =
      fetchBlueprintRolesRequest.inProgress ||
      roles == null ||
      (isAllocator(runner) && allocator == null && !allocatorRequest.error)

    if (shouldSpin) {
      return (
        <div data-test-id='loading-runner-roles'>
          <EuiLoadingContent />
        </div>
      )
    }

    if (allocatorRequest.error && get(allocatorRequest, [`error`, `response`, `status`]) !== 404) {
      return (
        <div data-test-id='runner-roles-error-loading-allocator'>
          <CuiAlert details={allocatorRequest.error} type='error'>
            <FormattedMessage
              id='runner-roles.fetching-allocator-failed'
              defaultMessage='Fetching the associated allocator failed'
            />
          </CuiAlert>
        </div>
      )
    }

    if (fetchBlueprintRolesRequest.error) {
      return (
        <div data-test-id='runner-roles-error-loading-roles'>
          <CuiAlert details={fetchBlueprintRolesRequest.error} type='error'>
            <FormattedMessage
              id='runner-roles.fetching-roles-failed'
              defaultMessage='Fetching roles failed'
            />
          </CuiAlert>
        </div>
      )
    }

    const sortedRoles = filter(roles, (role) => role.isRemovable).sort(sortOn(`id`))

    if (sortedRoles.length === 0) {
      return (
        <div data-test-id='runner-roles-error-no-roles'>
          <CuiAlert type='warning'>
            <FormattedMessage
              id='runner-roles.no-roles-received'
              defaultMessage='No roles received'
            />
          </CuiAlert>
        </div>
      )
    }

    return (
      <ManageHostRolesForm
        regionId={regionId}
        hostId={hostId}
        allocatorHoldsInstances={Boolean(allocator && !isEmpty(allocator.instances))}
        roles={sortedRoles}
        currentRoles={roleIds}
        toggleRole={this.toggleRole}
      />
    )
  }

  renderFlyoutFooter() {
    const { updateRolesRequest, onClose } = this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <CuiButtonEmpty onClick={() => onClose()}>
              <FormattedMessage id='manage-host-roles.close' defaultMessage='Close' />
            </CuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiPermissibleControl permissions={[Permission.setRunnerRoles]}>
              <CuiButton
                data-test-id='submitRoles'
                onClick={this.updateRoles}
                spin={updateRolesRequest.inProgress}
                fill={true}
              >
                <FormattedMessage
                  id='manage-host-roles.update-roles'
                  defaultMessage='Update roles'
                />
              </CuiButton>
            </CuiPermissibleControl>
          </EuiFlexItem>
        </EuiFlexGroup>

        {this.renderUpdateRolesRequestStatus()}
      </Fragment>
    )
  }

  renderUpdateRolesRequestStatus() {
    const { updateRolesRequest } = this.props

    if (!updateRolesRequest) {
      return null
    }

    if (updateRolesRequest.error) {
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='error'>{updateRolesRequest.error}</CuiAlert>
        </Fragment>
      )
    }

    if (updateRolesRequest.isDone) {
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='info'>
            <FormattedMessage id='manage-host-roles.roles-updated' defaultMessage='Roles updated' />
          </CuiAlert>
        </Fragment>
      )
    }

    return null
  }

  toggleRole = (...roleIdsToToggle: string[]) => {
    const { roleIds } = this.state

    this.setState({
      roleIds: toggle(roleIds, ...roleIdsToToggle),
    })
  }

  updateRoles = () => {
    const { updateRoles, runner } = this.props
    const { roleIds } = this.state

    if (runner) {
      updateRoles(formatRoles(roleIds))
    }
  }
}

export default ManageHostRoles

function pluckRoleNames(roles: RunnerRoleInfo[]): string[] {
  return roles.map(({ role_name }) => role_name)
}

function isAllocator(runner: Runner): boolean {
  return pluckRoleNames(runner.roles).includes(`allocator`)
}

// Formats ids to a "roles object" before sending to the API
function formatRoles(rolesIds: string[]) {
  return rolesIds.map((id) => ({ role_name: id }))
}
