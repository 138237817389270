/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiTabs, EuiTab } from '@elastic/eui'

import {
  topologyInstanceConfigurationsUrl,
  topologyDeploymentTemplatesUrl,
} from '../../../lib/urlBuilder'
import history from '../../../lib/history'

import type { FunctionComponent } from 'react'
import type { RegionId } from '../../../types'

interface Props {
  regionId: RegionId
  activeUrl: string
}

const TopologySectionPicker: FunctionComponent<Props> = ({ regionId, activeUrl }) => {
  const tabs = [
    {
      href: topologyDeploymentTemplatesUrl(regionId),
      message: (
        <FormattedMessage
          id='topology-section-picker.deployments'
          defaultMessage='Deployment templates'
        />
      ),
    },
    {
      href: topologyInstanceConfigurationsUrl(regionId),
      message: (
        <FormattedMessage
          id='topology-section-picker.instance-configurations'
          defaultMessage='Instance configurations'
        />
      ),
    },
  ]

  return (
    <EuiTabs>
      {tabs.map(({ href, message }) => (
        <EuiTab
          key={href}
          isSelected={activeUrl.startsWith(href)}
          onClick={() => history.push(href)}
        >
          {message}
        </EuiTab>
      ))}
    </EuiTabs>
  )
}

export default TopologySectionPicker
