/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getRegion, getProxies, fetchProxiesRequest } from '../../reducers'
import { fetchProxies } from '../../actions/proxies'

import Proxies from './Proxies'

import type { Region, RegionProxies, AsyncRequestState, ReduxState } from '../../types'

type StateProps = {
  region?: Region
  proxies?: RegionProxies
  proxiesRequest: AsyncRequestState
}

type DispatchProps = {
  fetchProxies: (regionId: string) => void
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  region: getRegion(state, regionId),
  proxies: getProxies(state, regionId),
  proxiesRequest: fetchProxiesRequest(state, regionId),
})

const mapDispatchToProps: DispatchProps = { fetchProxies }

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Proxies)
