/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchDeployment, updateDeployment } from '../../../../actions/stackDeployments'
import { resetExecuteSlmPolicyRequest } from '../../../../actions/snapshots'
import { fetchSnapshotRepositories } from '../../../../actions/snapshotRepositories'
import {
  executeSlmPolicyRequest,
  fetchSnapshotRepositoriesRequest,
  getSnapshotRepositories,
  getCluster,
} from '../../../../reducers'
import Feature from '../../../../lib/feature'
import { isFeatureActivated } from '../../../../selectors'

import SlmSnapshotActions from './SlmSnapshotActions'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ReduxState } from '../../../../types'

const mapStateToProps = (
  state: ReduxState,
  { regionId, deploymentId, snapshotsEnabled }: ConsumerProps,
): StateProps => {
  const deployment = getCluster(state, regionId, deploymentId)!

  return {
    deployment,
    showTakeSnapshotButton:
      snapshotsEnabled && isFeatureActivated(state, Feature.showTakeSnapshotButton),
    snapshotRepositories: getSnapshotRepositories(state, regionId),
    fetchSnapshotRepositoriesRequest: fetchSnapshotRepositoriesRequest(state, regionId),
    executeSlmPolicyRequest: executeSlmPolicyRequest(state, deploymentId, regionId),
  }
}

const mapDispatchToProps: DispatchProps = {
  resetExecuteSlmPolicyRequest,
  fetchSnapshotRepositories,
  fetchDeployment,
  updateDeployment,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SlmSnapshotActions)
