/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { identity } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { CuiTable } from '../../../../../cui'
import { manageRegionUrl } from '../../../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { CuiTableColumn } from '../../../../../cui'

interface Props {
  regionIds: string[]
}

const ManageRegionsTable: FunctionComponent<Props> = ({ regionIds }) => {
  const nameColumn: CuiTableColumn<string> = {
    label: <FormattedMessage id='regions-table.name-label' defaultMessage='Name' />,
    render: (regionId) => <Link to={manageRegionUrl(regionId)}>{regionId}</Link>,
    sortKey: identity,
  }

  const columns: Array<CuiTableColumn<string>> = [nameColumn]

  return (
    <CuiTable<string>
      rows={regionIds}
      columns={columns}
      getRowId={identity}
      initialSort={nameColumn}
    />
  )
}

export default ManageRegionsTable
