/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import PrivacySensitiveContainer from '@/components/PrivacySensitiveContainer'

import { CuiButtonIcon } from '../../../../cui'
import messages, { formatMessageWithValues } from '../messages'
import { removeMember } from '../lib'

import type { AllProps as Props } from './types'
import type { FunctionComponent } from 'react'

const RemoveInvitationButton: FunctionComponent<Props> = ({
  intl,
  organizationMemberRow: { id, email },
  organizationName,
  deleteOrganizationInvitation,
  deleteOrganizationInvitationRequest,
}) => {
  const {
    modal: {
      invitation: { title, body, confirm, cancel },
    },
    toast: {
      invitation: { success, failure },
    },
  } = messages

  const values = {
    organizationName,
    email,
  }

  return (
    <CuiButtonIcon
      aria-label='remove member'
      spin={deleteOrganizationInvitationRequest({ id }).inProgress}
      onClick={() =>
        removeMember({
          intl,
          deleteOrganizationMember: deleteOrganizationInvitation({ id }),
          successMessage: success,
          failureMessage: failure,
          values,
        })
      }
      confirm={true}
      confirmModalProps={{
        title: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({ intl, message: title, values })}
          </PrivacySensitiveContainer>
        ),
        body: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({ intl, message: body, values })}
          </PrivacySensitiveContainer>
        ),
        confirm: formatMessageWithValues({ intl, message: confirm, values }),
        cancel: formatMessageWithValues({ intl, message: cancel, values }),
      }}
      iconType='cross'
      data-test-id='remove-member-button'
    />
  )
}

export default injectIntl(RemoveInvitationButton)
