/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import PrivacySensitiveContainer from '@/components/PrivacySensitiveContainer'

import { CuiButtonEmpty } from '../../../../cui'
import messages, { formatMessageWithValues } from '../messages'
import { removeMember } from '../lib'

import type { AllProps as Props } from './types'
import type { FunctionComponent } from 'react'

const LeaveOrganizationButton: FunctionComponent<Props> = ({
  intl,
  profile,
  isLastMember,
  organizationName,
  deleteOrganizationMembership,
  deleteOrganizationMembershipRequest,
}) => {
  if (!profile) {
    return null
  }

  const { user_id: userId, email } = profile

  const {
    modal: {
      currentMember: { title, body, confirm },
      lastMember: { body: lastMemberBody },
    },
    toast: {
      currentMember: { success, failure },
    },
  } = messages

  const values = {
    organizationName,
    email,
  }

  return (
    <CuiButtonEmpty
      aria-label='remove member'
      spin={deleteOrganizationMembershipRequest({ id: String(userId) }).inProgress}
      onClick={() =>
        removeMember({
          intl,
          deleteOrganizationMember: deleteOrganizationMembership({ id: String(userId) }),
          successMessage: success,
          failureMessage: failure,
          values,
        })
      }
      confirm={true}
      confirmModalProps={{
        title: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({ intl, message: title, values })}
          </PrivacySensitiveContainer>
        ),
        body: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({
              intl,
              message: isLastMember ? lastMemberBody : body,
              values,
            })}
          </PrivacySensitiveContainer>
        ),
        confirm: formatMessageWithValues({ intl, message: confirm, values }),
      }}
      color='danger'
      data-test-id='leave-organization-button'
    >
      {formatMessageWithValues({ intl, message: confirm, values })}
    </CuiButtonEmpty>
  )
}

export default injectIntl(LeaveOrganizationButton)
