/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import { CuiTable, CuiTimeAgo, CuiPermissibleControl } from '../../cui'
import schedule from '../../lib/schedule'
import { getSliderDefinition } from '../../lib/sliders'
import { getInstanceNumber } from '../../reducers/clusters/createCluster'
import Permission from '../../lib/api/v1/permissions'

import HeapDumpStatus from './HeapDumpStatus'

import type { HeapDump } from '../../types/heapDump'
import type { CuiTableColumn } from '../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

export interface Props {
  intl: IntlShape
  heapDumps?: HeapDump[]
  fetchHeapDumps: () => void
  getHeapDumpDownloadUrl: (resourceKind: string, refId: string, instanceId: string) => string
}

const messages = defineMessages({
  oom: {
    id: 'heap-dump-type.heap-dump-oom',
    defaultMessage: 'Out-of-memory',
  },
  'on-demand': {
    id: 'heap-dump-type.heap-dump-on-demand',
    defaultMessage: 'On-demand',
  },
})

const HeapDumpsTable: FunctionComponent<Props> = ({
  heapDumps,
  getHeapDumpDownloadUrl,
  intl: { formatMessage },
}) => {
  const columns: Array<CuiTableColumn<HeapDump>> = [
    {
      label: <FormattedMessage id='heap-dumps-table.instance-id' defaultMessage='Instance' />,
      render: ({ instanceId, resourceKind }) => {
        const { messages: sliderMessages, iconType } = getSliderDefinition({
          sliderInstanceType: resourceKind,
        })
        const instanceLabel = ` - #${getInstanceNumber(instanceId)}`

        return (
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false}>
              <EuiIcon type={iconType} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <span>
                <FormattedMessage {...sliderMessages.prettyName} />
                {instanceLabel}
              </span>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
    },

    {
      label: <FormattedMessage id='heap-dumps-table.instance-type' defaultMessage='Type' />,
      render: ({ type }) => formatMessage(messages[type]),
    },

    {
      label: <FormattedMessage id='heap-dumps-table.instance-status' defaultMessage='Status' />,
      render: (heapDump) => <HeapDumpStatus heapDump={heapDump} />,
    },

    {
      label: (
        <FormattedMessage id='heap-dumps-table.heap-dump-timestamp' defaultMessage='Timestamp' />
      ),
      render: ({ captured }) => <CuiTimeAgo date={captured} longTime={true} />,
    },

    {
      label: <FormattedMessage id='heap-dumps-table.download' defaultMessage='Download' />,
      align: 'center' as const,
      width: '150px',
      actions: true,
      render: (heapDump) => {
        if (heapDump.status === 'complete') {
          return (
            <CuiPermissibleControl permissions={Permission.downloadDeploymentInstanceHeapDump}>
              <EuiButtonIcon
                href={getHeapDumpDownloadUrl(
                  heapDump.resourceKind,
                  heapDump.refId,
                  heapDump.instanceId,
                )}
                iconType='download'
                aria-label='Download'
              />
            </CuiPermissibleControl>
          )
        }

        return null
      },
    },
  ]

  return (
    <CuiTable<HeapDump>
      columns={columns}
      rows={heapDumps}
      getRowId={({ instanceId }) => instanceId}
      pageSize={10}
      initialLoading={!heapDumps}
    />
  )
}

export default injectIntl(schedule(HeapDumpsTable, ({ fetchHeapDumps }) => fetchHeapDumps()))
