/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchSaasUser } from '@/actions/saasUsers'
import { getSaasUser, fetchSaasUserRequest } from '@/reducers'
import { isFeatureActivated } from '@/selectors'
import Feature from '@/lib/feature'
import { getOrganizationDeploymentsSearchResults } from '@/components/Organization/OrganizationDeployments/lib'

import User from './User'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state,
  {
    match: {
      params: { userId, selectedTabId },
    },
  }: ConsumerProps,
): StateProps => ({
  userId,
  selectedTabId,
  profile: getSaasUser(state, userId),
  fetchSaasUserRequest: fetchSaasUserRequest(state, userId),
  getOrganizationDeploymentsSearchCount: (organizationId) =>
    getOrganizationDeploymentsSearchResults(state, organizationId)?.match_count,
  lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
})

const mapDispatchToProps: DispatchProps = {
  fetchSaasUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
