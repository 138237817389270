/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { allocatorFilterToExpression } from '../../../../../lib/allocatorFilters'
import FilterQueryExpression from '../../../components/FilterQueryExpression'
import MatchingAllocatorsTable from '../../../components/MatchingAllocatorsTable'

import type { WrappedComponentProps } from 'react-intl'
import type { AllocatorSearchResult, AsyncRequestState, RegionId } from '../../../../../types'
import type { QueryContainer } from '../../../../../lib/api/v1/types'

export interface Props extends WrappedComponentProps {
  clauses: QueryContainer | undefined
  regionId: string
  searchAllocatorsQuery: (key: string, regionId: RegionId, clauses?: QueryContainer) => void
  searchAllocatorsRequest: AsyncRequestState
  searchResults?: AllocatorSearchResult[]
}

class Allocators extends Component<Props> {
  componentDidMount() {
    const { clauses, regionId, searchAllocatorsQuery } = this.props

    // Kick off initial search. If there are no clauses (i.e. no allocator filter), then we'll
    // get back *all* allocators.
    searchAllocatorsQuery(`topology-filter-allocators`, regionId, clauses)
  }

  render() {
    const { clauses } = this.props
    const query = allocatorFilterToExpression(clauses)

    return (
      <Fragment>
        <EuiTitle size='m' data-test-id='allocators-title'>
          <h2>
            <FormattedMessage
              id='instance-configuration-allocators.title'
              defaultMessage='Allocators'
            />
          </h2>
        </EuiTitle>

        <EuiFormLabel data-test-id='allocators-query'>
          <FormattedMessage
            id='instance-configuration-allocators.query-label'
            defaultMessage='Query'
          />
        </EuiFormLabel>

        <EuiSpacer size='xs' />

        <EuiTitle size='xs'>
          <FilterQueryExpression query={query} />
        </EuiTitle>

        <EuiSpacer size='m' />

        {this.renderSearchResults()}
      </Fragment>
    )
  }

  renderSearchResults() {
    const {
      searchResults,
      searchAllocatorsRequest: { inProgress },
    } = this.props

    // old search results aren't cleared from the store
    // thus we need to check both of these conditions
    if (inProgress || !searchResults) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='l' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <FormattedMessage
                id='instance-configuration-allocators.searching'
                defaultMessage='Searching'
              />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    const allocators = searchResults
    const count = allocators.length.toString()

    return (
      <Fragment>
        <EuiText>
          <FormattedMessage
            id='instance-configuration-allocators.table-matches'
            defaultMessage='{matches} {count, plural, one {has} other {have}} matched your query.'
            values={{
              count,
              matches: (
                <strong>
                  <FormattedMessage
                    id='instance-configuration-allocators.table-count'
                    defaultMessage='{count} {count, plural, one {allocator} other {allocators}}'
                    values={{ count }}
                  />
                </strong>
              ),
            }}
          />
        </EuiText>

        {Boolean(allocators.length) && (
          <Fragment>
            <EuiSpacer size='s' />
            <MatchingAllocatorsTable allocators={allocators} />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default injectIntl(Allocators)
