/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// Return an array with the separator interspersed between
// each element of the input array.
//
// intersperse([1,2,3], 0)
// -> [1,0,2,0,3]

export default function intersperse<T>(array: T[], glue: T) {
  return array.reduce((acc, elem) => acc.concat(glue, elem), [] as T[]).slice(1)
}
