/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { sortBy, isEmpty, maxBy } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink } from '@elastic/eui'

import { CuiLink, CuiTable } from '../../cui'
import AllocatorHealth from '../AllocatorHealth'
import { createNonBlockingRenderLoop } from '../NonBlockingRenderLoop'
import DiskUsage from '../DiskUsage'
import { hostAllocatorUrl, hostAllocatorMoveNodesUrl } from '../../lib/urlBuilder'
import { getThemeColors } from '../../lib/theme'

import InstanceCapacitiesViz from './InstanceCapacitiesViz'
import AllocatorLoggingSettingsButton from './AllocatorLoggingSettingsButton'
import AllocatorTags from './AllocatorTags'

import type { MetadataItem } from '../../lib/api/v1/types'
import type { AllocatorSearchResult } from '../../types'
import type { CuiTableColumn } from '../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  allocators?: AllocatorSearchResult[]
  fullWidth?: boolean
  filterTags?: MetadataItem[]
  renderKey?: string
  renderZoneColumn?: boolean
  onZoneClick: (zoneId: string) => void
  onTagClick: (tag: MetadataItem) => void
  regionId: string
  totalCount: number
  initialLoading: boolean
}

const messages = defineMessages({
  actions: {
    id: `allocators-table.actions`,
    defaultMessage: `Actions`,
  },
})

const AllocatorsTable: FunctionComponent<Props> = ({
  intl: { formatMessage },
  allocators = [],
  fullWidth,
  filterTags = [],
  renderKey = `shared`,
  onZoneClick,
  onTagClick,
  regionId,
  totalCount,
  initialLoading,
}) => {
  const largestCapacity = maxBy(allocators.map((allocator) => allocator.memoryCapacity.total))!

  const rows = sortBy(allocators.slice(), [
    ({ memoryCapacity }) => memoryCapacity.used - memoryCapacity.total, // available descending
    ({ memoryCapacity }) => -memoryCapacity.total, // total descending
    ({ id }) => id, // stable sort
  ])

  const NonBlockingRenderLoop = createNonBlockingRenderLoop({
    key: `allocators-table-capacities-viz:${renderKey}`,
    concurrencyLevel: 2,
  })

  const columns: Array<CuiTableColumn<AllocatorSearchResult>> = [
    {
      id: 'id',
      label: <FormattedMessage id='allocators-table.allocator' defaultMessage='Host' />,
      render: ({ regionId, id }) => (
        <CuiLink to={hostAllocatorUrl(regionId, id)} data-test-id='allocator-link'>
          {id}
        </CuiLink>
      ),
      sortKey: `id`,
      width: `230px`,
    },

    {
      id: 'health',
      label: <FormattedMessage id='allocators-table.health' defaultMessage='Allocator status' />,
      render: (allocator) => <AllocatorHealth allocator={allocator} />,
      width: `130px`,
    },

    {
      id: 'zone',
      label: <FormattedMessage id='allocators-table.zone' defaultMessage='Zone' />,
      render: renderAllocatorZone(onZoneClick),
      sortKey: `zoneId`,
      width: `130px`,
    },

    {
      id: 'ram-capacity',
      label: (
        <FormattedMessage
          id='allocators-table.allocated-ram-capacity'
          defaultMessage='Allocated RAM capacity'
        />
      ),
      render: renderInstanceCapacities(NonBlockingRenderLoop, largestCapacity),
      sortKey: ({ memoryCapacity }) => memoryCapacity.total - memoryCapacity.used,
      textOnly: false,
      width: '200px',
    },

    {
      id: 'tags',
      label: <FormattedMessage id='allocators-table.tags' defaultMessage='Tags' />,
      render: renderAllocatorTags(filterTags, onTagClick),
      textOnly: false,
      width: `280px`,
    },

    {
      mobile: {
        label: formatMessage(messages.actions),
      },
      render: (allocator) => (
        <AllocatorLoggingSettingsButton regionId={regionId} allocatorId={allocator.id} />
      ),
      width: `40px`,
    },
  ]

  return (
    <CuiTable<AllocatorSearchResult>
      rows={rows}
      getRowId={(allocator) => allocator.id}
      fullWidth={fullWidth}
      pageSize={25}
      showMatchCount={true}
      totalCount={totalCount}
      matchType={<FormattedMessage id='allocators-table.match-type' defaultMessage='allocator' />}
      matchTypePlural={
        <FormattedMessage id='allocators-table.match-type-plural' defaultMessage='allocators' />
      }
      columns={columns}
      initialLoading={initialLoading}
      hasExpandedDetailRow={(allocator) => !allocator.healthy && !isEmpty(allocator.instances)}
      renderDetailButton={false}
      renderDetailRow={UnhealthyAllocatorDetailRow}
    />
  )
}

function UnhealthyAllocatorDetailRow(allocator: AllocatorSearchResult) {
  return (
    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon type='alert' className='isUnhealthy' />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <div>
          <FormattedMessage
            id='allocators-table.unhealthy-allocator-move-instancess'
            defaultMessage='This allocator has problems. Please { moveAllInstances }.'
            values={{
              moveAllInstances: (
                <CuiLink to={hostAllocatorMoveNodesUrl(allocator.regionId, allocator.id)}>
                  <FormattedMessage
                    id='allocators-table.move-all-instances-to-another-allocator'
                    defaultMessage='move all instances to another allocator'
                  />
                </CuiLink>
              ),
            }}
          />
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

function renderAllocatorZone(onZoneClick: Props['onZoneClick']) {
  return ({ zoneId }) => (
    <EuiLink onClick={() => onZoneClick(zoneId)} data-test-id='allocator-zone'>
      {zoneId}
    </EuiLink>
  )
}

function renderInstanceCapacities(
  NonBlockingRenderLoop: React.ComponentClass,
  largestCapacity: number,
) {
  const { euiColorDarkestShade, euiColorSecondary } = getThemeColors()

  return ({ regionId, memoryCapacity, instances }) => {
    const { total, used } = memoryCapacity
    const available = total - used
    const critical = available === 0

    return (
      <div className='allocatorRow-capacityViz'>
        <NonBlockingRenderLoop>
          <InstanceCapacitiesViz
            hideInstanceKindsHairline={true}
            instances={instances}
            largestCapacity={largestCapacity}
            regionId={regionId}
            totalCapacity={total}
            usedCapacity={used}
            usedCapacityColor={critical ? euiColorDarkestShade : euiColorSecondary}
          />
        </NonBlockingRenderLoop>

        <DiskUsage available={available} total={total} withProgress={false} />
      </div>
    )
  }
}

function renderAllocatorTags(filterTags: Props['filterTags'], onTagClick: Props['onTagClick']) {
  return ({ tags }) => <AllocatorTags tags={tags} active={filterTags} onTagClick={onTagClick} />
}

export default injectIntl(AllocatorsTable)
