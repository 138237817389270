/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'

import type { FunctionComponent } from 'react'

type Props = {
  organizationId: string
}

const OrganizationTrafficLogLink: FunctionComponent<Props> = ({ organizationId }) => (
  <ExternalLink href={getDashboardHref(organizationId)}>
    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <FormattedMessage
          id='organization.organization-overview.organization-traffic-log-link.dashboard-label'
          defaultMessage='Traffic Logs'
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiIcon type='popout' />
      </EuiFlexItem>
    </EuiFlexGroup>
  </ExternalLink>
)

function getDashboardHref(organizationId: string) {
  return `https://us-east-1-logging-production-cloud.elastic.co/app/discover#/?_g=(time:(from:now-24h,to:now))&_a=(columns:!(request_method,request_url,status_code,request_length,response_length,response_time),filters:!(),index:'service-adminconsole-*',query:(language:lucene,query:'organization_id:${organizationId}'),sort:!(!('@timestamp',desc)))`
}

export default OrganizationTrafficLogLink
