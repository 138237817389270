/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getBillingHistory } from '@/reducers'
import { fetchBillingHistoryRequest } from '@/reducers/billing'
import { fetchBillingHistory } from '@/actions/billing'

import BillingHistory from './BillingHistory'

import type { ThunkDispatch } from '@/types'
import type { ConsumerProps, DispatchProps } from './types'

const mapStateToProps = (state, { profile }: ConsumerProps) => ({
  billingHistory: profile && getBillingHistory(state, profile.organization_id!),
  fetchBillingHistoryRequest: fetchBillingHistoryRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchBillingHistory: (params) => dispatch(fetchBillingHistory(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory)
