/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import Header from '../Header'
import ManageTrafficFilters from '../ManageTrafficFilters'
import { regionSecurityCrumbs } from '../../lib/crumbBuilder'

import type { FunctionComponent } from 'react'

export type Props = {
  regionId: string
}

const RegionSecurity: FunctionComponent<Props> = ({ regionId }) => (
  <Fragment>
    <Header
      breadcrumbs={regionSecurityCrumbs({ regionId })}
      name={<FormattedMessage id='region-security.header' defaultMessage='Security' />}
    />

    <ManageTrafficFilters regionId={regionId} renderOwnHeader={false} />
  </Fragment>
)

export default RegionSecurity
