/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import SnapshotRestoreFlyout from '../SnapshotRestoreFlyout'
import DeploymentLockGate from '../../../DeploymentLockGate'

import type { FunctionComponent } from 'react'
import type { ClusterSnapshot, StackDeployment } from '../../../../types'

type Props = {
  deployment: StackDeployment
  snapshot: ClusterSnapshot
  readonly?: boolean
}

const SnapshotTableRowActions: FunctionComponent<Props> = ({ deployment, snapshot, readonly }) => {
  const { state } = snapshot

  const canRestore = state === `PARTIAL` || state === `SUCCESS`

  if (!canRestore || readonly) {
    return null
  }

  const snapshotName = snapshot.snapshot

  return (
    <DeploymentLockGate>
      <SnapshotRestoreFlyout deployment={deployment} snapshotName={snapshotName} />
    </DeploymentLockGate>
  )
}

export default SnapshotTableRowActions
