/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

type State = {
  display: 'static' | 'none'
}

class AutofillBuster extends React.Component<Props, State> {
  state: State = { display: `static` }

  componentDidMount() {
    // needs a delay for chrome to catch the decoy fields
    setTimeout(() => this.setState({ display: `none` }), 400)
  }

  render() {
    const { children, ...props } = this.props
    return (
      <form {...props} autoComplete='off'>
        <div style={{ maxHeight: 0, overflow: `hidden` }}>
          <input type='text' style={this.state} />
          <input type='password' style={this.state} />
        </div>
        {children}
      </form>
    )
  }
}

export default AutofillBuster
