/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Formik } from 'formik'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiPortal,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { roleMappingMessages } from '../../authProviderMessages'
import { CuiFormField } from '../../../../../../../cui/forms'
import UserRoleComboBox from '../../../Users/UserRoleCombobox'
import { validateRoleMappingRule } from '../../validateRoleMappingRule'

import type { RoleMappingRule } from '../SamlProviderForm'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  isNew: boolean
  rule: RoleMappingRule
  onSave: (rule: RoleMappingRule) => void
  onClose: () => void
}

const EditRoleMappingFlyout: FunctionComponent<Props> = (props) => {
  const {
    intl: { formatMessage },
    isNew,
    rule,
    onClose,
    onSave,
  } = props

  // The form is rendered in a portal so that we don't have nested forms.

  return (
    <EuiPortal>
      <Formik<RoleMappingRule>
        initialValues={{ ...rule }}
        validate={validateRoleMappingRule(formatMessage)}
        onSubmit={onSave}
      >
        {({ submitForm }) => (
          <Form>
            <EuiFlyout
              ownFocus={true}
              onClose={onClose}
              size='s'
              aria-labelledby='editRoleMappingFlyoutTitle'
            >
              <EuiFlyoutHeader hasBorder={true}>
                <EuiTitle size='s'>
                  <h2 id='editRoleMappingFlyoutTitle'>
                    {formatMessage(
                      isNew
                        ? roleMappingMessages.addRoleMapping
                        : roleMappingMessages.editRoleMapping,
                    )}
                  </h2>
                </EuiTitle>
              </EuiFlyoutHeader>

              <EuiFlyoutBody>
                <EuiText>
                  {formatMessage(roleMappingMessages.editSamlRoleMappingDescription)}
                </EuiText>

                <EuiSpacer />

                <CuiFormField
                  name='type'
                  label={formatMessage(roleMappingMessages.attributeLabel)}
                  component={EuiSelect}
                  options={[{ text: 'username' }, { text: 'groups' }, { text: 'dn' }]}
                  data-test-id='saml_role_mapping_type'
                />

                <CuiFormField name='value' label='Value' data-test-id='role_mapping_rule_value' />

                <UserRoleComboBox
                  useFormik={true}
                  name='roles'
                  label={formatMessage(roleMappingMessages.roleLabel)}
                  data-test-id='role_mapping_rule_roles'
                />
              </EuiFlyoutBody>

              <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty iconType='cross' onClick={onClose} flush='left'>
                      {formatMessage(roleMappingMessages.cancelEditRule)}
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      data-test-id={`${isNew ? 'add' : 'edit'}-role-mapping-button`}
                      type='button'
                      onClick={submitForm}
                      fill={true}
                    >
                      {formatMessage(
                        isNew
                          ? roleMappingMessages.addRoleMapping
                          : roleMappingMessages.saveRoleMapping,
                      )}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlyoutFooter>
            </EuiFlyout>
          </Form>
        )}
      </Formik>
    </EuiPortal>
  )
}

export default injectIntl(EditRoleMappingFlyout)
