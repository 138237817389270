/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import EssDocLink from '../DocLink/EssDocLink'
import { getDocLink } from '../DocLink'

import type { FunctionComponent } from 'react'

const EcuHelpText: FunctionComponent = () => {
  // NOTE: we explicitly use `<EssDocLink>` because the ECUs documentation is
  // exclusive of ESS but this component is also displayed on the Admin Console
  const docLink = getDocLink('billingECU', 'ess')
  const { href, hash } = docLink

  return (
    <FormattedMessage
      id='ecu-tooltip.help-message'
      defaultMessage='All values are shown in {ecu}'
      values={{
        ecu: (
          <EssDocLink href={href} hash={hash}>
            <FormattedMessage
              id='ecu-tooltip.page-ecu-unit'
              defaultMessage='Elastic Consumption Units (ECU)'
            />
          </EssDocLink>
        ),
      }}
    />
  )
}

export default EcuHelpText
