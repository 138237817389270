/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiRouterLinkButton } from '../../../../cui'
import Header from '../../../Header'
import InstanceConfigurationWizard from '../InstanceConfigurationWizard'
import { topologyViewInstanceConfigurationUrl } from '../../../../lib/urlBuilder'
import { topologyInstanceConfigurationEditCrumbs } from '../../../../lib/crumbBuilder'

import type { AsyncRequestState } from '../../../../types'
import type { InstanceConfiguration } from '../../../../lib/api/v1/types'

type Props = {
  regionId: string
  instanceId: string
  instanceConfiguration: InstanceConfiguration
  instanceConfigurationRequest: AsyncRequestState
  updateInstanceConfigurationRequest: AsyncRequestState
  fetchInstanceConfiguration: () => unknown
  updateInstanceConfiguration: (instanaceConfig: InstanceConfiguration) => void
}

export default class InstanceConfigurationEdit extends Component<Props> {
  componentDidMount() {
    const { fetchInstanceConfiguration } = this.props
    fetchInstanceConfiguration()
  }

  render() {
    return (
      <Fragment>
        {this.renderTitle()}

        {this.renderContent()}
      </Fragment>
    )
  }

  renderTitle() {
    const { regionId, instanceId, instanceConfiguration, instanceConfigurationRequest } = this.props

    const actions = (
      <CuiRouterLinkButton
        disabled={instanceConfigurationRequest.inProgress}
        to={topologyViewInstanceConfigurationUrl(regionId, instanceId)}
      >
        <FormattedMessage id='instance-configuration-edit.cancel' defaultMessage='Cancel' />
      </CuiRouterLinkButton>
    )

    if (!instanceConfiguration) {
      return (
        <Header
          breadcrumbs={topologyInstanceConfigurationEditCrumbs({ regionId, instanceId })}
          name={
            <FormattedMessage
              id='instance-configuration-view.title-placeholder'
              defaultMessage='Loading instance configuration …'
            />
          }
        />
      )
    }

    const { name, system_owned } = instanceConfiguration

    return (
      <Header
        breadcrumbs={topologyInstanceConfigurationEditCrumbs({ regionId, instanceId })}
        name={
          <EuiFlexGroup gutterSize='m' alignItems='center'>
            <EuiFlexItem grow={false}>
              <div>{name}</div>
            </EuiFlexItem>

            {system_owned && (
              <EuiFlexItem grow={false}>
                <EuiBadge color='primary'>
                  <FormattedMessage
                    id='instance-configuration-summary.system-owned-label'
                    defaultMessage='System owned'
                  />
                </EuiBadge>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        }
      >
        {actions}
      </Header>
    )
  }

  renderContent() {
    const {
      regionId,
      instanceConfiguration,
      instanceConfigurationRequest,
      updateInstanceConfigurationRequest,
    } = this.props

    if (instanceConfigurationRequest.error) {
      return <CuiAlert type='error'>{instanceConfigurationRequest.error}</CuiAlert>
    }

    if (instanceConfigurationRequest.inProgress || instanceConfiguration == null) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='l' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <FormattedMessage
                id='instance-configuration-edit.loading'
                defaultMessage='Loading …'
              />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return (
      <Fragment>
        <EuiCallOut
          iconType='help'
          color='warning'
          size='s'
          title={
            <FormattedMessage
              id='instance-configuration-edit.notice'
              defaultMessage='Editing this instance configuration will affect all deployments which depend on it'
            />
          }
        />

        <EuiSpacer />

        <InstanceConfigurationWizard
          regionId={regionId}
          isSaving={updateInstanceConfigurationRequest.inProgress}
          error={updateInstanceConfigurationRequest.error}
          onSave={this.onSave}
          saveButtonLabel={
            <FormattedMessage
              id='instance-configuration-edit.save-changes'
              defaultMessage='Save changes'
            />
          }
          instanceConfiguration={instanceConfiguration}
        />
      </Fragment>
    )
  }

  onSave = (updatedInstanceConfiguration: InstanceConfiguration) => {
    const { instanceConfiguration, updateInstanceConfiguration } = this.props
    const { allocator_filter = {} } = updatedInstanceConfiguration
    const newInstanceConfiguration = {
      ...instanceConfiguration,
      ...updatedInstanceConfiguration,
      allocator_filter,
    }

    updateInstanceConfiguration(newInstanceConfiguration)
  }
}
