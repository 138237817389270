/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormLabel,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiTimeAgo } from '../../../cui'

import UpdateLicense from './UpdateLicense'
import DeleteLicense from './DeleteLicense'

import type { AsyncRequestState, License } from '../../../types'
import type { FunctionComponent } from 'react'

interface Props {
  license: License
  regionId: string
  addLicenseRequest: AsyncRequestState
  removeLicenseRequest: AsyncRequestState
}

const RESOURCE_UNIT_SIZE = 64
const ECE_INSTANCE_SIZE = 128

const LicenseDetails: FunctionComponent<Props> = ({
  license,
  regionId,
  addLicenseRequest,
  removeLicenseRequest,
}) => {
  // Default the total in order to avoid NaN
  const totalConnectedMemoryTotalGB = (license.total_connected_memory_total || 0) / 1024

  const items = [
    {
      title: (
        <FormattedMessage id='region-admin-license-details.expires' defaultMessage='Expires' />
      ),
      description: (
        <EuiText>
          <CuiTimeAgo date={license.expires} longTime={true} />
        </EuiText>
      ),
    },
    {
      title: <FormattedMessage id='region-admin-license-details.issued' defaultMessage='Issued' />,
      description: (
        <EuiText>
          <CuiTimeAgo date={license.issued} longTime={true} />
        </EuiText>
      ),
    },
    {
      title: (
        <FormattedMessage id='region-admin-license-details.issued-to' defaultMessage='Issued to' />
      ),
      description: <EuiText>{license.issuedTo}</EuiText>,
    },
    {
      title: <FormattedMessage id='region-admin-license-details.issuer' defaultMessage='Issuer' />,
      description: <EuiText>{license.issuer}</EuiText>,
    },
    ...(license.maxResourceUnits
      ? [
          {
            title: (
              <FormattedMessage
                id='region-admin-license-details.current-ece-resource-units'
                defaultMessage='Number of Enterprise resource units'
              />
            ),
            description: (
              <EuiText>{Math.ceil(totalConnectedMemoryTotalGB / RESOURCE_UNIT_SIZE)}</EuiText>
            ),
          },
          {
            title: (
              <FormattedMessage
                id='region-admin-license-details.max-ece-resource-units'
                defaultMessage='Max Enterprise resource units'
              />
            ),
            description: <EuiText>{license.maxResourceUnits}</EuiText>,
          },
        ]
      : [
          {
            title: (
              <FormattedMessage
                id='region-admin-license-details.current-ece-instances'
                defaultMessage='Number of instances'
              />
            ),
            description: (
              <EuiText>
                {String(Math.ceil(totalConnectedMemoryTotalGB / ECE_INSTANCE_SIZE))}
              </EuiText>
            ),
          },
          {
            title: (
              <FormattedMessage
                id='region-admin-license-details.max-ece-instances'
                defaultMessage='Max instances'
              />
            ),
            description: <EuiText>{String(license.maxInstances)}</EuiText>,
          },
        ]),
    {
      title: (
        <FormattedMessage
          id='region-admin-license-details.current-ece-memory'
          defaultMessage='Total memory'
        />
      ),
      description: (
        <EuiText>
          <FormattedMessage
            id='region-admin-license-details.current-ece-memory-size-in-gb'
            defaultMessage='{sizeInGb} GB'
            values={{ sizeInGb: Math.ceil(totalConnectedMemoryTotalGB) }}
          />
        </EuiText>
      ),
    },
    {
      title: <FormattedMessage id='region-admin-license-details.type' defaultMessage='Type' />,
      description: <EuiBadge color='hollow'>{license.licenseType}</EuiBadge>,
    },
  ]

  return (
    <div>
      <EuiFormLabel>
        <FormattedMessage
          id='region-admin-license-details.current-license'
          defaultMessage='Current license'
        />
      </EuiFormLabel>

      <EuiSpacer size='s' />

      <EuiPanel color='subdued'>
        <EuiFlexGrid columns={2} direction='column'>
          {items.map(({ title, description }, index) => (
            <EuiFlexItem key={index}>
              <div>
                <EuiFormLabel>{title}</EuiFormLabel>

                <EuiSpacer size='s' />

                {description}
              </div>
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      </EuiPanel>

      <EuiSpacer size='l' />

      <EuiFormLabel>
        <FormattedMessage
          id='region-admin-license-details.license-actions'
          defaultMessage='License management'
        />
      </EuiFormLabel>

      <EuiSpacer size='s' />

      <UpdateLicense regionId={regionId} />

      <EuiSpacer size='m' />

      <DeleteLicense regionId={regionId} />

      {addLicenseRequest.error && (
        <Fragment>
          <EuiSpacer size='m' />
          <CuiAlert type='error'>{addLicenseRequest.error}</CuiAlert>
        </Fragment>
      )}

      {removeLicenseRequest.error && (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='error'>{removeLicenseRequest.error}</CuiAlert>
        </Fragment>
      )}
    </div>
  )
}

export default LicenseDetails
