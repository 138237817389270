/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiButton } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  onClick?: () => void
  disabled?: boolean
}

const SubscriptionDetailsButton: FunctionComponent<Props> = ({ onClick, children, disabled }) => (
  <EuiButton
    fill={true}
    disabled={disabled}
    color='ghost'
    onClick={onClick}
    data-test-id='subscription-details-button'
  >
    {children}
  </EuiButton>
)

export default SubscriptionDetailsButton
