/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FETCH_UPGRADE_ASSISTANT_STATUS } from '../constants/actions'

import type { DeploymentUpgradeAssistantStatusResponse } from '../lib/api/v1/types'
import type { UpgradeAssistantAction } from '../types'

export interface State {
  [deploymentId: string]: DeploymentUpgradeAssistantStatusResponse
}

export default function upgradeAssistantStatusReducer(
  state: State = {},
  action: UpgradeAssistantAction,
): State {
  if (FETCH_UPGRADE_ASSISTANT_STATUS === action.type) {
    if (!action.error && action.payload) {
      const {
        meta: { deploymentId },
        payload,
      } = action

      return {
        ...state,
        [deploymentId]: payload,
      }
    }
  }

  return state
}

export function getUpgradeAssistantStatus(
  state: State,
  deploymentId: string,
): DeploymentUpgradeAssistantStatusResponse | undefined {
  return state[deploymentId]
}
