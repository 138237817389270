/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexItem, EuiPanel, EuiLoadingContent } from '@elastic/eui'

import { CuiStat } from '../../../cui'

import type { FunctionComponent, ReactElement } from 'react'
import type { FormattedMessage } from 'react-intl'
import type { CuiLink } from '../../../cui'

import './platformStatsTile.scss'

interface Props {
  title: ReactElement<typeof FormattedMessage>
  value?: number | ReactElement<typeof CuiLink> | null
}

const PlatformStatsTile: FunctionComponent<Props> = ({ title, value }) => (
  <EuiFlexItem grow={false}>
    <EuiPanel>
      <CuiStat
        statElement='strong'
        title={
          <div className='platformStatsTile-value'>
            {value == null ? (
              <EuiLoadingContent lines={1} data-test-id='platform-stat-loading' />
            ) : (
              value
            )}
          </div>
        }
        description={title}
        data-test-id='platform-stat'
      />
    </EuiPanel>
  </EuiFlexItem>
)

export default PlatformStatsTile
