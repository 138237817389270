/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import { isDedicatedMaster } from '../../../../../../lib/stackDeployments/selectors'

import TemplateTopologyElement from './TemplateTopologyElement'
import TemplateDedicatedMaster from './TemplateDedicatedMaster'

import type { AllProps as TemplateTopologyElementProps } from './TemplateTopologyElement'

const TemplateTopologyElementRouter: React.FunctionComponent<TemplateTopologyElementProps> = (
  props,
) => {
  const { topologyElement, instanceConfigurations } = props

  const instanceConfiguration = instanceConfigurations.find(
    ({ id }) => id === topologyElement.instance_configuration_id,
  )

  if (!instanceConfiguration) {
    return (
      <EuiCallOut color='warning'>
        <FormattedMessage
          id='topologyElement-error'
          defaultMessage='Something went wrong with rendering this element. Please contact support.'
        />
      </EuiCallOut>
    )
  }

  if (isDedicatedMaster({ topologyElement })) {
    return <TemplateDedicatedMaster {...props} />
  }

  return <TemplateTopologyElement {...props} />
}

export default TemplateTopologyElementRouter
