/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchProfile } from '@/apps/userconsole/actions/profile'
import { getOrganizationMembers } from '@/reducers'

import {
  deleteOrganizationMemberships,
  refreshSaasCurrentUser,
} from '../../../../actions/organizations'
import { deleteOrganizationMembershipsRequest } from '../../../../reducers/asyncRequests/registry'
import { getProfile } from '../../../../apps/userconsole/reducers'
import { hasSameEmail } from '../lib'

import RemoveMemberButton from './RemoveMemberButton'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ReduxState, ThunkDispatch } from '../../../../types'

const mapStateToProps = (
  state: ReduxState,
  {
    organization: { id: organizationId, name: organizationName },
    organizationMemberRow,
  }: ConsumerProps,
): StateProps => {
  const members = getOrganizationMembers(state, organizationId)
  const isLastMember = members.length === 1

  return {
    isCurrentMember: hasSameEmail(getProfile(state), organizationMemberRow),
    isLastMember,
    organizationMemberRow,
    organizationName,
    deleteOrganizationMembershipRequest: ({ id }) =>
      deleteOrganizationMembershipsRequest(state, organizationId, id),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organization: { id: organizationId } }: ConsumerProps,
): DispatchProps => ({
  deleteOrganizationMembership: ({ id, isCurrentMember }) =>
    dispatch(deleteOrganizationMemberships({ organizationId, userIds: [id] })).then(() => {
      if (isCurrentMember) {
        dispatch(refreshSaasCurrentUser())
        dispatch(fetchProfile())
      }
    }),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveMemberButton)
