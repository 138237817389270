/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchLdapSecurityRealmRequest,
  getLdapAuthProvider,
  updateLdapSecurityRealmRequest,
} from '../../../../../reducers'
import {
  fetchLdapSecurityRealm,
  fetchSecurityCluster,
  updateLdapSecurityRealm,
} from '../../../../../actions'

import EditLdapProvider from './EditLdapProvider'

import type { LdapSettings } from '../../../../../../../lib/api/v1/types'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, realmId },
    },
  },
) => ({
  ldapSettings: getLdapAuthProvider(state, regionId, realmId),
  fetchLdapSecurityRealmRequest: fetchLdapSecurityRealmRequest(state, regionId, realmId),
  updateLdapSecurityRealmRequest: updateLdapSecurityRealmRequest(state, regionId, realmId),
  regionId,
  realmId,
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId, realmId },
    },
  },
) => ({
  fetchLdapSecurityRealm: () => dispatch(fetchLdapSecurityRealm(regionId, realmId)),
  onSubmit: (payload: LdapSettings) =>
    dispatch(updateLdapSecurityRealm(regionId, realmId, payload)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLdapProvider)
