/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { CuiAlert } from '../../cui'
import schedule from '../../lib/schedule'

import PlatformHealthProblems from './PlatformHealthProblems'

import type { ReactNode } from 'react'
import type { AsyncRequestState, License, RegionId } from '../../types'
import type { UpdatedTlsChain } from '../../lib/api/v1/types'

type Props = {
  children?: ReactNode
  fetchLicense: (regionId: string) => void
  fetchLicenseRequest: AsyncRequestState
  fetchRegion: () => void
  license?: License | null
  regionId: string
  regionRequest: AsyncRequestState
  fetchTlsCertificate: (regionId: RegionId, target: UpdatedTlsChain) => void
}

class Region extends Component<Props> {
  componentDidMount() {
    const { fetchLicense, regionId } = this.props

    fetchLicense(regionId)
  }

  render() {
    const { regionRequest, regionId, children } = this.props

    if (regionRequest.error) {
      return <CuiAlert type='error'>{regionRequest.error}</CuiAlert>
    }

    return (
      <section>
        <PlatformHealthProblems regionId={regionId} spacerAfter={true} />

        {children}
      </section>
    )
  }
}

export default schedule(
  Region,
  ({ fetchRegion, fetchTlsCertificate, regionId }) => {
    fetchRegion()
    fetchTlsCertificate(regionId, { service: 'ui' })
    fetchTlsCertificate(regionId, { service: 'proxy' })
    fetchTlsCertificate(regionId, { service: 'internalca' })
  },
  [`regionId`],
)
