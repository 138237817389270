/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'

import ClearOAuthLoginErrorOnRefresh from '@/components/Login/OAuthLoginError/ClearOAuthLoginErrorOnRefresh'

import type { ReactElement } from 'react'

type OAuthLoginErrorType = {
  error: string | null
  description: string | null
}

interface Props {
  oAuthLoginError: OAuthLoginErrorType
  hidden?: boolean
}

class OAuthLoginError extends PureComponent<Props> {
  render(): ReactElement {
    const { hidden } = this.props

    if (hidden) {
      return <ClearOAuthLoginErrorOnRefresh />
    }

    return (
      <Fragment>
        <EuiSpacer />

        <EuiText size='m' textAlign='center' color='subdued'>
          <EuiCallOut
            title={this.renderTitle()}
            color='danger'
            iconType='alert'
            className='eui-textLeft'
          >
            {this.renderDescription()}
          </EuiCallOut>
        </EuiText>

        <ClearOAuthLoginErrorOnRefresh />
      </Fragment>
    )
  }

  renderTitle(): ReactElement {
    const {
      oAuthLoginError: { error },
    } = this.props
    const errorMessages = {
      // Standard OAuth error codes
      access_denied: (
        <FormattedMessage id='okta.login.error.access-denied' defaultMessage='Access denied' />
      ),
      invalid_client: (
        <FormattedMessage id='okta.login.error.invalid-client' defaultMessage='Invalid client' />
      ),
      invalid_grant: (
        <FormattedMessage
          id='okta.login.error.access-invalid-grant'
          defaultMessage='Invalid grant'
        />
      ),
      invalid_request: (
        <FormattedMessage id='okta.login.error.invalid-request' defaultMessage='Invalid request' />
      ),
      invalid_scope: (
        <FormattedMessage id='okta.login.error.invalid-scope' defaultMessage='Invalid scope' />
      ),
      invalid_token: (
        <FormattedMessage id='okta.login.error.invalid-token' defaultMessage='Invalid token' />
      ),
      server_error: (
        <FormattedMessage id='okta.login.error.server-error' defaultMessage='Server error' />
      ),
      temporarily_unavailable: (
        <FormattedMessage
          id='okta.login.error.temporarily-unavailable'
          defaultMessage='Temporarily unavailable'
        />
      ),
      unsupported_response_type: (
        <FormattedMessage
          id='okta.login.error.unsupported-response-type'
          defaultMessage='Unsupported response type'
        />
      ),
      unsupported_response_mode: (
        <FormattedMessage
          id='okta.login.error.unsupported-response-mode'
          defaultMessage='Unsupported response mode'
        />
      ),
      // OpenID error codes
      insufficient_scope: (
        <FormattedMessage
          id='okta.login.error.insufficient-scope'
          defaultMessage='Insufficient scope'
        />
      ),
      login_required: (
        <FormattedMessage id='okta.login.error.login-required' defaultMessage='Login required' />
      ),
    }
    const errorMessagesTitle = error && errorMessages[error]

    if (errorMessagesTitle) {
      return errorMessagesTitle
    }

    return (
      <FormattedMessage id='okta.login.error.default.title' defaultMessage='An error occurred' />
    )
  }

  renderDescription(): ReactElement | string {
    const {
      oAuthLoginError: { description },
    } = this.props

    if (description) {
      return description
    }

    return (
      <FormattedMessage
        id='okta.login.error.default.description'
        defaultMessage='Authentication failed. Please try again, or email {support} if the failure persists.'
        values={{
          support: (
            <EuiLink href='mailto:support@elastic.co?subject=Elastic%20Cloud%20Login%20Query'>
              support@elastic.co
            </EuiLink>
          ),
        }}
      />
    )
  }
}

export default OAuthLoginError
