/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { find, isEqual, pickBy } from 'lodash'

import {
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRadioGroup,
} from '@elastic/eui'

import {
  getNonDeprecatedProductSliderTypes,
  getSliderDefinition,
  getSliderTypeId,
  getProductSliderTypeForInstanceConfiguration,
  getDefaultNodeTypesForProductType,
  sortSliderTypes,
} from '../../../../../lib/sliders'
import { CuiSliderLogo } from '../../../../../cui'

import ChooseInstanceTypesStepErrors from './ChooseInstanceTypesStepErrors'

import type { NodeType, SliderType } from '../../../../../types'
import type { StepProps } from '../instanceConfigurationWizardTypes'
import type { SliderNodeTypeSet } from '../../../../../lib/sliders/types'

import './chooseNodeTypesStep.scss'

class ChooseInstanceTypesStep extends React.Component<StepProps> {
  render() {
    const productTypes = sortSliderTypes(getNonDeprecatedProductSliderTypes())

    return (
      <Fragment>
        <EuiTitle>
          <h2>
            <FormattedMessage
              id='instance-configuration-choose-node-types.title'
              defaultMessage='Select instance types'
            />
          </h2>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText>
          <FormattedMessage
            id='instance-configuration-choose-node-types.description'
            defaultMessage='Pick the products and features of the Elastic Stack that can get deployed on the set of allocators from the previous step.'
          />
        </EuiText>

        <ChooseInstanceTypesStepErrors {...this.props} />

        <ul className='instance-configuration-choose-node-types-products'>
          {productTypes.map((productType) => {
            const id = getSliderTypeId(productType)

            return (
              <li key={id}>
                <EuiSpacer size='m' />
                {this.renderProduct(productType)}
              </li>
            )
          })}
        </ul>
      </Fragment>
    )
  }

  renderProduct(productType: SliderType) {
    const { instanceConfiguration } = this.props

    const definition = getSliderDefinition(productType)

    const label = (
      <div>
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <CuiSliderLogo size='xl' {...productType} />
          </EuiFlexItem>
          <EuiFlexItem>
            <div className='instance-configuration-choose-node-types-products-main-label'>
              <FormattedMessage {...definition.messages.prettyName} />
            </div>
            <div className='instance-configuration-choose-node-types-products-description'>
              {definition.messages.instanceConfigurationDescription && (
                <FormattedMessage {...definition.messages.instanceConfigurationDescription} />
              )}
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )

    const isChecked = isEqual(
      getProductSliderTypeForInstanceConfiguration(instanceConfiguration),
      productType,
    )

    return (
      <EuiCheckableCard
        id={`instance-configuration-choose-node-types.products.${getSliderTypeId(productType)}`}
        name='instance-configuration-choose-node-types.products'
        label={label}
        checked={isChecked}
        onChange={() => this.setProductType(productType)}
      >
        {isChecked && this.renderNodeTypeSets(productType)}
      </EuiCheckableCard>
    )
  }

  renderNodeTypeSets(productType: SliderType) {
    const { instanceConfiguration } = this.props

    const definition = getSliderDefinition(productType)

    if (definition.nodeTypeSets == null) {
      return null // skip if the product has no customizable node types
    }

    const options = definition.nodeTypeSets.map(({ id, messages }) => ({
      id,
      label: (
        <div>
          <div className='instance-configuration-choose-node-types-nodes-main-label'>
            <FormattedMessage {...messages.prettyName} />
          </div>
          <div className='instance-configuration-choose-node-types-nodes-description'>
            <div>
              <FormattedMessage {...messages.instanceConfigurationDescription} />
            </div>
          </div>
        </div>
      ),
    }))

    const selectedNodeTypeSet = find(definition.nodeTypeSets, ({ nodeTypes }) =>
      isEqual(Object.keys(pickBy(nodeTypes)), instanceConfiguration.node_types),
    )

    return (
      <EuiRadioGroup
        className='instance-configuration-choose-node-types-nodes'
        options={options}
        idSelected={selectedNodeTypeSet?.id}
        onChange={(id) => {
          this.setNodeTypes(find(definition.nodeTypeSets, { id })!.nodeTypes)
        }}
      />
    )
  }

  setProductType = (productType: SliderType) => {
    const { instanceTypes, updateInstanceConfiguration } = this.props

    const nextState = {
      instance_type: productType.sliderInstanceType,
      node_types: getNodeTypes(),
    }

    updateInstanceConfiguration(nextState)

    function getNodeTypes(): NodeType[] {
      const defaultNodeTypesForProductType = getDefaultNodeTypesForProductType(
        productType,
      ) as NodeType[]

      // if there's a hard-coded default for a product type, go with that
      if (defaultNodeTypesForProductType.length > 0) {
        return defaultNodeTypesForProductType
      }

      // otherwise consult the runtime instanceType for mandatory node types
      const instanceType = find(instanceTypes, { instance_type: productType.sliderInstanceType })

      if (!instanceType || !instanceType.node_types) {
        return [] // sanity
      }

      return instanceType.node_types
        .filter((nodeType) => nodeType.mandatory)
        .map((nodeType) => nodeType.node_type) as NodeType[]
    }
  }

  setNodeTypes = (nodeTypes: SliderNodeTypeSet['nodeTypes']) => {
    const { updateInstanceConfiguration } = this.props

    const nextState = {
      node_types: Object.keys(pickBy(nodeTypes)) as NodeType[],
    }

    updateInstanceConfiguration(nextState)
  }
}

export default ChooseInstanceTypesStep
