/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiFormRow,
  EuiHideFor,
  EuiShowFor,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutFooter,
} from '@elastic/eui'

import { addToast } from '@/cui'

import { ViewBy } from '../../types'
import { MinimalStartDate } from '../../constants'

import downloadCSV from './downloadCSV'
import DeploymentSelect from './DeploymentSelect'
import DateRangePicker from './DateRangePicker'
import ViewBySelect from './ViewBySelect'
import ChartTypeToggle from './ChartTypeToggle'

import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

interface State {
  isFlyoutVisible: boolean
  isDownloadingCSV: boolean
}

class Filters extends Component<Props & WrappedComponentProps, State> {
  state = {
    isFlyoutVisible: false,
    isDownloadingCSV: false,
  }

  render() {
    const { isFlyoutVisible, isDownloadingCSV } = this.state

    return (
      <Fragment>
        {/* Normal screen */}
        <EuiHideFor sizes={['xs', 's']}>
          <EuiFlexGroup data-test-id='billing-usage.normal-screen'>
            <EuiFlexItem grow={3}>{this.renderDeploymentSelect()}</EuiFlexItem>
            <EuiFlexItem grow={3}>{this.renderDateRangePicker()}</EuiFlexItem>
            <EuiFlexItem grow={4}>
              <EuiFlexGroup>
                <EuiFlexItem grow={4}>{this.renderViewBySelect()}</EuiFlexItem>
                <EuiFlexItem grow={3}>{this.renderChartTypeToggle()}</EuiFlexItem>
                <EuiFlexItem grow={2}>
                  <EuiFormRow fullWidth={true} hasEmptyLabelSpace={true}>
                    <Fragment>
                      <EuiButton
                        onClick={this.onDownloadCSV}
                        iconType='download'
                        isLoading={isDownloadingCSV}
                        data-test-id='billing-usage.download-csv-button'
                      >
                        <FormattedMessage
                          id='billing-usage.download-csv'
                          defaultMessage='Download CSV'
                        />
                      </EuiButton>
                    </Fragment>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiHideFor>
        {/* Mobile screen */}
        <EuiShowFor sizes={['xs', 's']}>
          <EuiFlexGroup responsive={false} data-test-id='billing-usage.mobile-screen'>
            <EuiFlexItem grow={3}>{this.renderChartTypeToggle()}</EuiFlexItem>
            <EuiFlexItem grow={3}>
              <EuiFormRow fullWidth={true} hasEmptyLabelSpace={true}>
                <EuiButtonEmpty
                  onClick={this.onDownloadCSV}
                  iconType='download'
                  isLoading={isDownloadingCSV}
                  data-test-id='billing-usage.download-csv-button'
                >
                  <FormattedMessage id='billing-usage.download-csv' defaultMessage='Download CSV' />
                </EuiButtonEmpty>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <EuiFormRow fullWidth={true} hasEmptyLabelSpace={true}>
                <EuiButton
                  onClick={this.toggleFlyout}
                  fullWidth={true}
                  data-test-id='billing-usage.show-filters-button'
                >
                  <FormattedMessage id='billing-usage.show-filters' defaultMessage='Show filters' />
                </EuiButton>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          {isFlyoutVisible && this.renderFlyout()}
        </EuiShowFor>
      </Fragment>
    )
  }

  renderFlyout = () => {
    const { resetFilters } = this.props

    return (
      <EuiFlyout ownFocus={true} onClose={this.toggleFlyout} data-test-id='billing-usage.flyout'>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage id='billing-usage.filters' defaultMessage='Filters' />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFlexGroup wrap={true}>
            <EuiFlexItem>{this.renderDeploymentSelect()}</EuiFlexItem>
            <EuiFlexItem>{this.renderDateRangePicker()}</EuiFlexItem>
            <EuiFlexItem>{this.renderViewBySelect()}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType='cross'
                onClick={resetFilters}
                flush='left'
                data-test-id='billing-usage.reset-filters-button'
              >
                <FormattedMessage
                  id='billing-usage.flyout.resetFilters'
                  defaultMessage='Reset filters'
                />
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={this.toggleFlyout}
                fill={true}
                data-test-id='billing-usage.close-filters-button'
              >
                <FormattedMessage id='billing-usage.flyout.close' defaultMessage='Close' />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderDeploymentSelect = () => {
    const {
      onFilterChange,
      isLoading = false,
      selectedDeploymentId,
      deployments = [],
      intl: { formatMessage },
    } = this.props
    const emptyOption = {
      label: formatMessage({
        id: 'billing-usage.filters.all-deployments',
        defaultMessage: 'Deployments (All)',
      }),
      value: '',
      key: '',
    }
    const deploymentOptions = [
      emptyOption,
      ...deployments.map(({ name, id }) => ({ label: name, value: id, key: id })),
    ]
    const selectedDeployment =
      deploymentOptions.find(({ value }) => value === selectedDeploymentId) || deploymentOptions[0]

    return (
      <DeploymentSelect
        options={deploymentOptions}
        selectedOption={selectedDeployment}
        onChange={onFilterChange}
        isLoading={isLoading}
      />
    )
  }

  renderDateRangePicker = () => {
    const { onFilterChange, isLoading = false, startDate, endDate } = this.props
    const defaultMinDate = MinimalStartDate
    const defaultMaxDate = moment()

    return (
      <DateRangePicker
        isDisabled={isLoading}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        minDate={defaultMinDate}
        maxDate={defaultMaxDate}
        onChange={onFilterChange}
      />
    )
  }

  renderViewBySelect = () => {
    const {
      onFilterChange,
      isLoading = false,
      intl: { formatMessage },
      viewBy,
    } = this.props

    const viewByOptions = [
      {
        label: formatMessage({
          id: 'billing-usage.filters.view-by.day',
          defaultMessage: 'Day',
        }),
        value: ViewBy.DAY,
      },
      {
        label: formatMessage({
          id: 'billing-usage.filters.view-by.month',
          defaultMessage: 'Month',
        }),
        value: ViewBy.MONTH,
      },
    ]
    const selectedViewBy = viewByOptions.find(({ value }) => value === viewBy) || viewByOptions[0]

    return (
      <ViewBySelect
        options={viewByOptions}
        selectedOption={selectedViewBy}
        onChange={onFilterChange}
        isLoading={isLoading}
      />
    )
  }

  renderChartTypeToggle = () => {
    const { onFilterChange, isLoading = false, chartType } = this.props

    return (
      <ChartTypeToggle
        onChange={onFilterChange}
        selectedChartType={chartType}
        isLoading={isLoading}
      />
    )
  }

  toggleFlyout = () => {
    const { isFlyoutVisible } = this.state

    this.setState({ isFlyoutVisible: !isFlyoutVisible })
  }

  onDownloadCSV = async () => {
    const {
      startDate,
      endDate,
      selectedDeploymentId,
      organizationId,
      intl: { formatMessage },
    } = this.props

    this.setState({ isDownloadingCSV: true })

    try {
      await downloadCSV({ startDate, endDate, selectedDeploymentId, organizationId })
    } catch {
      addToast({
        family: 'billing-usage.download-csv',
        color: 'danger',
        title: formatMessage({
          id: 'billing-usage.download-csv.failure',
          defaultMessage: 'Could not download CSV',
        }),
      })
    } finally {
      this.setState({ isDownloadingCSV: false })
    }
  }
}

export default injectIntl(Filters)
