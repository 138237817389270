/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  copyClipboardTag: {
    id: `deployment-tags.copy`,
    defaultMessage: `Copy tag to clipboard`,
  },
  confirmModalTitle: {
    id: `deployment-tags.confirm-modal-title`,
    defaultMessage: `Save pending changes?`,
  },
  confirmModalConfirmButtonText: {
    id: `deployment-tags.confirm-modal-save-button`,
    defaultMessage: `Save`,
  },
  confirmModalCancelButtonText: {
    id: `deployment-tags.confirm-modal-cancel-button`,
    defaultMessage: `Cancel`,
  },
  confirmModalDefaultFocusedButton: {
    id: `deployment-tags.confirm-modal-focused-button`,
    defaultMessage: `confirm`,
  },
  keyPlaceholder: {
    id: `deployment-tags.key-placeholder`,
    defaultMessage: `Key`,
  },
  valuePlaceholder: {
    id: `deployment-tags.value-placeholder`,
    defaultMessage: `Value`,
  },
})
