/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiFormHelpText,
  EuiSwitch,
} from '@elastic/eui'

import { CuiAlert } from '@/cui'

import type { AsyncRequestState } from '@/types'

export interface Props {
  organizationId: string
  enabled: boolean
  setDiskNotifications: (enabled: boolean) => void
  setDiskNotificationsRequest: AsyncRequestState
  resetSetDiskNotifications: () => void
}

class EnableDiskNotificationsButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetSetDiskNotifications()
  }

  render() {
    const { setDiskNotifications, setDiskNotificationsRequest, enabled } = this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiText size='s'>
              <strong>
                <FormattedMessage
                  id='enable-disk-notifications-button.label'
                  defaultMessage='Disk usage notifications'
                />
              </strong>
            </EuiText>

            <EuiFormHelpText>
              <FormattedMessage
                id='enable-disk-notifications-button.description'
                defaultMessage='Warning email when disk usage is above 85%'
              />
            </EuiFormHelpText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiSwitch
              checked={enabled}
              label={''}
              showLabel={false}
              onChange={() => setDiskNotifications(!enabled)}
              disabled={setDiskNotificationsRequest.inProgress}
            />
          </EuiFlexItem>

          {setDiskNotificationsRequest.inProgress && (
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size='m' />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        {setDiskNotificationsRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{setDiskNotificationsRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default EnableDiskNotificationsButton
