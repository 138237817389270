/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiCode,
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiSpacer,
  EuiText,
  htmlIdGenerator,
} from '@elastic/eui'

import { labels, messages, scopeValues, sections } from '../../authProviderMessages'
import DocLink from '../../../../../../../components/DocLink'
import { CuiFormArray } from '../../../../../../../cui/forms/CuiFormArray'
import { CuiFormField } from '../../../../../../../cui/forms/CuiFormField'
import { CuiFieldRadio } from '../../../../../../../cui/forms/CuiFormRadio'

import type { LdapProviderFormShape } from '../LdapProviderForm'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  values: LdapProviderFormShape
}

const idPrefix = htmlIdGenerator()()

export const scopeOptions = (formatMessage) => [
  {
    id: `${idPrefix}_scope_sub_tree`,
    value: 'sub_tree',
    text: formatMessage(scopeValues.subTree),
  },
  {
    id: `${idPrefix}_scope_one_level`,
    value: 'one_level',
    text: formatMessage(scopeValues.oneLevel),
  },
  {
    id: `${idPrefix}_scope_base`,
    value: 'base',
    text: formatMessage(scopeValues.base),
  },
]

const SearchModeSettings: FunctionComponent<Props> = ({ intl: { formatMessage }, values }) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.searchModeSettings} />
      </h3>
    }
    description={
      <Fragment>
        <EuiText size='s'>
          <FormattedMessage
            {...sections.searchModeSettingsDescription}
            values={{
              learnMore: (
                <DocLink link='ldapProviderSearchModeDocLink'>
                  <FormattedMessage {...messages.learnMore} />
                </DocLink>
              ),
            }}
          />
        </EuiText>
        <EuiSpacer size='m' />

        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <CuiFieldRadio
              id={`${idPrefix}_search_mode_user`}
              label={<FormattedMessage {...labels.user} />}
              name='search_mode'
              value='user'
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiFieldRadio
              id={`${idPrefix}_search_mode_template`}
              label={<FormattedMessage {...labels.template} />}
              name='search_mode'
              value='template'
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    }
  >
    {values.search_mode === 'user' && (
      <Fragment>
        <CuiFormField
          label={<FormattedMessage {...labels.baseDnForUsers} />}
          name='user_search.base_dn'
          helpText={
            <FormattedMessage
              {...labels.baseDnForUsersHelpText}
              values={{
                exampleBaseDn: <EuiCode>cn=users,dc=example,dc=com</EuiCode>,
              }}
            />
          }
        />

        <CuiFormField
          label={<FormattedMessage {...labels.scope} />}
          name='user_search.scope'
          component={EuiSelect}
          options={scopeOptions(formatMessage)}
        />

        <CuiFormField
          label={<FormattedMessage {...labels.filter} />}
          name='user_search.filter'
          helpText={
            <FormattedMessage
              {...labels.userFilterHelpText}
              values={{
                defaultValue: <EuiCode>{'(uid={0})'}</EuiCode>,
                placeholder: <EuiCode>{'{0}'}</EuiCode>,
              }}
            />
          }
        />

        <CuiFormField
          label={<FormattedMessage {...labels.userGroupAttribute} />}
          name='user_search.group_attr'
          helpText={
            <FormattedMessage
              {...labels.searchUserGroupAttributeHelpText}
              values={{
                defaultValue: <EuiCode>memberOf</EuiCode>,
              }}
            />
          }
        />
      </Fragment>
    )}

    {values.search_mode === `template` && (
      <CuiFormArray<string>
        name='user_dn_templates'
        label={<FormattedMessage {...labels.userDnTemplates} />}
        initialValue=''
        helpText={
          <FormattedMessage
            {...labels.templateHelpText}
            values={{
              example: <EuiCode>CN=Users,DC=example,DC=com</EuiCode>,
            }}
          />
        }
        addMessage={<FormattedMessage {...labels.addTemplate} />}
      />
    )}
  </EuiDescribedFormGroup>
)

export default injectIntl(SearchModeSettings)
