/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get } from 'lodash'
import { connect } from 'react-redux'

import {
  createTrustRelationship,
  fetchTrustRelationship,
  resetCreateTrustRelationship,
} from '../../../actions/trustManagement'
import { createTrustRelationshipRequest } from '../../../reducers'

import TrustRelationshipFlyout from './TrustRelationshipFlyout'

import type {
  TrustRelationshipCreateRequest,
  TrustRelationshipCreateResponse,
} from '../../../lib/api/v1/types'
import type { AsyncRequestState, ReduxState, ThunkDispatch } from '../../../types'

type StateProps = {
  onSaveRequest: AsyncRequestState
}

type DispatchProps = {
  onSave: (payload: TrustRelationshipCreateRequest) => Promise<TrustRelationshipCreateResponse>
  resetOnClose: () => void
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  onSaveRequest: createTrustRelationshipRequest(state, regionId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId }: ConsumerProps,
): DispatchProps => ({
  onSave: (payload) =>
    dispatch(createTrustRelationship({ regionId, payload })).then((response) => {
      // We fetch again after create, as the create payload doesn't include the certificate
      const newTrustRelationship = get(response, [`payload`], {}) as TrustRelationshipCreateResponse
      return dispatch(
        fetchTrustRelationship({
          regionId,
          trustRelationshipId: newTrustRelationship.id,
          includeCertificate: true,
        }),
      )
    }),
  resetOnClose: () => dispatch(resetCreateTrustRelationship(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrustRelationshipFlyout)
