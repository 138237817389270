/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { updatePhoneHomeIsEnabled, fetchPhoneHomeIsEnabled } from '../../../actions/phoneHome'
import { getRoot, isPhoneHomeEnabled, getPhoneHomeConfigRequest } from '../../../reducers'

import PhoneHome from './PhoneHomeConfig'

import type { RootConfig, AsyncRequestState } from '../../../types'

type StateProps = {
  isPhoneHomeEnabled: boolean
  phoneHomeConfigRequest: AsyncRequestState
  root: RootConfig
}

type DispatchProps = {
  fetchPhoneHomeIsEnabled: (root: RootConfig) => void
  updatePhoneHomeIsEnabled: (root: RootConfig, enabled: boolean) => void
}

interface ConsumerProps {}

const mapStateToProps = (state): StateProps => ({
  root: getRoot(state),
  isPhoneHomeEnabled: isPhoneHomeEnabled(state),
  phoneHomeConfigRequest: getPhoneHomeConfigRequest(state),
})

const mapDispatchToProps: DispatchProps = {
  updatePhoneHomeIsEnabled,
  fetchPhoneHomeIsEnabled,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneHome)
