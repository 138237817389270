/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flatMap } from 'lodash'
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import type { SearchFilterConfig } from '@elastic/eui'
import { EuiBadge } from '@elastic/eui'

import { excludeSchemalessFields } from '../../../cui'
import { StackConfigurationChangeSource } from '../../StackDeploymentConfigurationChange'
import { getRegionIds } from '../../../lib/regionEqualizer'
import { getSupportedSliderInstanceTypes } from '../../../lib/sliders'

import { getSchema } from './schema'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

type Params = WrappedComponentProps & {
  fetchRegionList: () => void
}

const messages = defineMessages({
  configurationChangeLabel: {
    id: `activity-feed-filter-context.configuration-label`,
    defaultMessage: `Configuration`,
  },
  successfulLabel: {
    id: `activity-feed-filter-context.successful-label`,
    defaultMessage: `Successful change`,
  },
  unsuccessfulLabel: {
    id: `activity-feed-filter-context.unsuccessful-label`,
    defaultMessage: `Unsuccessful change`,
  },
  pendingLabel: {
    id: `activity-feed-filter-context.pending-label`,
    defaultMessage: `Pending change`,
  },
  finishedLabel: {
    id: `activity-feed-filter-context.finished-label`,
    defaultMessage: `Finished change`,
  },
  healthLabel: {
    id: `activity-feed-filter-context.health-label`,
    defaultMessage: `Health`,
  },
  healthyLabel: {
    id: `activity-feed-filter-context.healthy-label`,
    defaultMessage: `Healthy`,
  },
  unhealthyLabel: {
    id: `activity-feed-filter-context.unhealthy-label`,
    defaultMessage: `Unhealthy`,
  },
  lockedLabel: {
    id: `activity-feed-filter-context.locked-label`,
    defaultMessage: `Locked`,
  },
  systemOwnedLabel: {
    id: `activity-feed-filter-context.system-owned-label`,
    defaultMessage: `System owned`,
  },
  healthyMastersLabel: {
    id: `activity-feed-filter-context.healthy-masters-label`,
    defaultMessage: `Healthy masters`,
  },
  unhealthyMastersLabel: {
    id: `activity-feed-filter-context.unhealthy-masters-label`,
    defaultMessage: `Unhealthy masters`,
  },
  healthySnapshotLabel: {
    id: `activity-feed-filter-context.healthy-snapshot-label`,
    defaultMessage: `Healthy snapshot (any)`,
  },
  unhealthySnapshotLabel: {
    id: `activity-feed-filter-context.unhealthy-snapshot-label`,
    defaultMessage: `Unhealthy snapshot (any)`,
  },
  healthySnapshotLatestLabel: {
    id: `activity-feed-filter-context.healthy-snapshot-latest-label`,
    defaultMessage: `Healthy snapshot (latest)`,
  },
  unhealthySnapshotLatestLabel: {
    id: `activity-feed-filter-context.unhealthy-snapshot-latest-label`,
    defaultMessage: `Unhealthy snapshot (latest)`,
  },
  enabledSnapshotLabel: {
    id: `activity-feed-filter-context.enabled-snapshot-label`,
    defaultMessage: `Enabled snapshots`,
  },
  disabledSnapshotLabel: {
    id: `activity-feed-filter-context.disabled-snapshot-label`,
    defaultMessage: `Disabled snapshots`,
  },
  maintenanceLabel: {
    id: `activity-feed-filter-context.maintenance-label`,
    defaultMessage: `Maintenance mode`,
  },
  noMaintenanceLabel: {
    id: `activity-feed-filter-context.not-maintenance-label`,
    defaultMessage: `Not maintenance mode`,
  },
  runningLabel: {
    id: `activity-feed-filter-context.running-label`,
    defaultMessage: `Running`,
  },
  stoppedLabel: {
    id: `activity-feed-filter-context.stopped-label`,
    defaultMessage: `Stopped`,
  },
  hiddenLabel: {
    id: `activity-feed-filter-context.hidden-label`,
    defaultMessage: `Hidden`,
  },
  notHiddenLabel: {
    id: `activity-feed-filter-context.not-hidden-label`,
    defaultMessage: `Not hidden`,
  },
  userInitiatedLabel: {
    id: `activity-feed-filter-context.user-initiated-label`,
    defaultMessage: `User initiated`,
  },
  systemInitiatedLabel: {
    id: `activity-feed-filter-context.system-initiated-label`,
    defaultMessage: `System initiated`,
  },
  sourceLabel: {
    id: `activity-feed-filter-context.source-label`,
    defaultMessage: `Source`,
  },
  sinceLabel: {
    id: `activity-feed-filter-context.since-label`,
    defaultMessage: `Since`,
  },
  untilLabel: {
    id: `activity-feed-filter-context.until-label`,
    defaultMessage: `Until`,
  },
  regionLabel: {
    id: `activity-feed-filter-context.region-label`,
    defaultMessage: `Region`,
  },
  loading: {
    id: `activity-feed-filter-context.loading`,
    defaultMessage: `Loading …`,
  },
  subscriptionLabel: {
    id: `activity-feed-filter-context.subscription-label`,
    defaultMessage: `Subscription`,
  },
  standardLabel: {
    id: `activity-feed-filter-context.standard-label`,
    defaultMessage: `Standard`,
  },
  goldLabel: {
    id: `activity-feed-filter-context.gold-label`,
    defaultMessage: `Gold`,
  },
  platinumLabel: {
    id: `activity-feed-filter-context.platinum-label`,
    defaultMessage: `Platinum`,
  },
  enterpriseLabel: {
    id: `activity-feed-filter-context.enterprise-label`,
    defaultMessage: `Enterprise`,
  },
  versionLabel: {
    id: `activity-feed-filter-context.version-label`,
    defaultMessage: `Version`,
  },
  stackLabel: {
    id: `activity-feed-filter-context.stack-label`,
    defaultMessage: `Stack`,
  },
  kibanaLabel: {
    id: `activity-feed-filter-context.kibana-label`,
    defaultMessage: `Kibana`,
  },
  mlLabel: {
    id: `activity-feed-filter-context.ml-label`,
    defaultMessage: `Machine learning`,
  },
  apmLabel: {
    id: `activity-feed-filter-context.apm-label`,
    defaultMessage: `APM`,
  },
})

type OptionList = {
  view: ReactElement
  value: string
}

let regions: OptionList[] | null = null

export function getFilters({ intl: { formatMessage }, fetchRegionList }: Params) {
  const filters: NonNullable<SearchFilterConfig[]> = [
    {
      name: formatMessage(messages.configurationChangeLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      multiSelect: false,
      autoClose: false,
      options: [
        {
          name: formatMessage(messages.successfulLabel),
          field: `healthy_configuration`,
          value: `y`,
        },
        {
          name: formatMessage(messages.unsuccessfulLabel),
          field: `healthy_configuration`,
          value: `n`,
        },
        {
          name: formatMessage(messages.pendingLabel),
          field: `pending`,
          value: `y`,
        },
        {
          name: formatMessage(messages.finishedLabel),
          field: `pending`,
          value: `n`,
        },
        {
          name: formatMessage(messages.userInitiatedLabel),
          field: `system_initiated`,
          value: `n`,
        },
        {
          name: formatMessage(messages.systemInitiatedLabel),
          field: `system_initiated`,
          value: `y`,
        },
      ],
    },
    {
      name: formatMessage(messages.sourceLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `source`,
      multiSelect: `or`,
      options: getSources(),
    },
    {
      name: formatMessage(messages.sinceLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `since`,
      multiSelect: false,
      options: getNamedDateRanges(),
    },
    {
      name: formatMessage(messages.untilLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `until`,
      multiSelect: false,
      options: getNamedDateRanges(),
    },
    {
      name: formatMessage(messages.healthLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      multiSelect: false,
      autoClose: false,
      options: [
        {
          name: formatMessage(messages.healthyLabel),
          field: `healthy`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthyMastersLabel),
          field: `healthy_masters`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthySnapshotLabel),
          field: `healthy_snapshot`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthySnapshotLatestLabel),
          field: `healthy_snapshot_latest`,
          value: `y`,
        },
        {
          name: formatMessage(messages.enabledSnapshotLabel),
          field: `enabled_snapshots`,
          value: `y`,
        },
        {
          name: formatMessage(messages.lockedLabel),
          field: `locked`,
          value: `y`,
        },
        {
          name: formatMessage(messages.noMaintenanceLabel),
          field: `maintenance`,
          value: `n`,
        },
        {
          name: formatMessage(messages.runningLabel),
          field: `stopped`,
          value: `n`,
        },
        {
          name: formatMessage(messages.notHiddenLabel),
          field: `hidden`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          field: `healthy`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyMastersLabel),
          field: `healthy_masters`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthySnapshotLabel),
          field: `healthy_snapshot`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthySnapshotLatestLabel),
          field: `healthy_snapshot_latest`,
          value: `n`,
        },
        {
          name: formatMessage(messages.disabledSnapshotLabel),
          field: `enabled_snapshots`,
          value: `n`,
        },
        {
          name: formatMessage(messages.maintenanceLabel),
          field: `maintenance`,
          value: `y`,
        },
        {
          name: formatMessage(messages.stoppedLabel),
          field: `stopped`,
          value: `y`,
        },
        {
          name: formatMessage(messages.hiddenLabel),
          field: `hidden`,
          value: `y`,
        },
        {
          name: formatMessage(messages.systemOwnedLabel),
          field: `system`,
          value: `y`,
        },
      ],
    },
    {
      name: formatMessage(messages.regionLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `region`,
      multiSelect: `or`,
      loadingMessage: formatMessage(messages.loading),
      options: () => loadRegions({ fetchRegionList }),
    },
    {
      name: formatMessage(messages.subscriptionLabel),
      type: `field_value_selection`,
      field: `subscription`,
      multiSelect: `or`,
      options: [
        {
          view: formatMessage(messages.standardLabel),
          value: `standard`,
        },
        {
          view: formatMessage(messages.goldLabel),
          value: `gold`,
        },
        {
          view: formatMessage(messages.platinumLabel),
          value: `platinum`,
        },
        {
          view: formatMessage(messages.enterpriseLabel),
          value: `enterprise`,
        },
      ],
    },
  ]

  const { schema } = getSchema()

  /* Remove fields that aren't in the schema declaration,
   * such as `system_initiated` or `subscription` in ECE
   */
  return excludeSchemalessFields({ filters, schema })
}

/* the `nakedSources` list is derived from Scala code at https://github.com/elastic/cloud/blob/f0b30e232ce3d1eff0c22bc070cce04b2247e4b2/scala-services/adminconsole/src/main/scala/no/found/adminconsole/util/ChangeSourceUtils.scala#L25
 * the good news is we don't perform any kind of strict checking, so this list is merely a convenience so that we can display list of actual source actions as defined (and used)
 * by the API.
 */
const nakedSources = [
  `cancel-pending-plan`,
  `create-cluster`,
  `create-deployment`,
  `create-template`,
  `delete-cluster`,
  `delete-deployment`,
  `enable-ilm-cluster`,
  `enable-slm-cluster`,
  `move-instances`,
  `reboot-cluster`,
  `restart-cluster`,
  `restore-cluster`,
  `set-pending-plan`,
  `shutdown-deployment`,
  `stop-cluster`,
  `update-cluster-acl`,
  `update-cluster-shield`,
  `update-cluster-plan`,
  `update-deployment`,
  `update-template`,
  `upgrade-cluster`,
]

function getSources() {
  const sources = flatMap(getSupportedSliderInstanceTypes(), (sliderInstanceType) =>
    nakedSources.map((source) => `${sliderInstanceType}.${source}`),
  )
    .sort()
    .map((action) => ({
      value: action,
      view: (
        <div className='activityFeedFilterContext-source'>
          <StackConfigurationChangeSource action={action} />
        </div>
      ),
    }))

  return sources
}

function getNamedDateRanges() {
  const ranges = {
    '15m': (
      <FormattedMessage
        id='activity-feed-filter-context.fifteen-minutes-ago'
        defaultMessage='Fifteen minutes ago'
      />
    ),
    '1h': (
      <FormattedMessage
        id='activity-feed-filter-context.an-hour-ago'
        defaultMessage='An hour ago'
      />
    ),
    '6h': (
      <FormattedMessage
        id='activity-feed-filter-context.six-hours-ago'
        defaultMessage='Six hours ago'
      />
    ),
    '12h': (
      <FormattedMessage
        id='activity-feed-filter-context.twelve-hours-ago'
        defaultMessage='Twelve hours ago'
      />
    ),
    '1d': (
      <FormattedMessage id='activity-feed-filter-context.a-day-ago' defaultMessage='A day ago' />
    ),
    '1w': (
      <FormattedMessage id='activity-feed-filter-context.a-week-ago' defaultMessage='A week ago' />
    ),
    '1M': (
      <FormattedMessage
        id='activity-feed-filter-context.a-month-ago'
        defaultMessage='A month ago'
      />
    ),
    '3M': (
      <FormattedMessage
        id='activity-feed-filter-context.three-months-ago'
        defaultMessage='Three months ago'
      />
    ),
    '1y': (
      <FormattedMessage id='activity-feed-filter-context.a-year-ago' defaultMessage='A year ago' />
    ),
  }

  return Object.keys(ranges).map((value) => ({
    value,
    view: <div className='activityFeedFilterContext-date'>{ranges[value]}</div>,
  }))
}

function loadRegions({ fetchRegionList }) {
  if (regions) {
    // the EUI cache mechanism doesn't work as expected
    return Promise.resolve(regions)
  }

  return fetchRegionList().then((actionResult) => {
    if (actionResult.error || !actionResult.payload) {
      return Promise.reject()
    }

    setRegions(getRegionIds(actionResult))

    return regions
  })
}

function setRegions(regionIds) {
  regions = regionIds.map((regionId) => ({
    view: renderBadge(regionId),
    value: regionId,
  }))
}

function renderBadge(value) {
  return (
    <div>
      <EuiBadge>{value}</EuiBadge>
    </div>
  )
}
