/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FETCH_STACK_DEPLOYMENT } from '../../constants/actions'
import { getRegionId } from '../../lib/stackDeployments/selectors'

import createApm from './createApm'

import type { ApmCluster, ApmId, ApmMetadata, FetchDeploymentAction, RegionId } from '../../types'
import type { DeploymentGetResponse } from '../../lib/api/v1/types'

export interface State {
  [apmId: string]: ApmCluster
}

type Action = FetchDeploymentAction

function createDescriptor(regionId, apmId) {
  return `${regionId}/${apmId}`
}

function apmReducer(apm: ApmCluster | null, action: Action): ApmCluster | null {
  if (action.type === FETCH_STACK_DEPLOYMENT) {
    const stackDeployment = action.payload as DeploymentGetResponse
    const apmCluster = stackDeployment.resources.apm[0]

    if (apmCluster) {
      const { id, info } = apmCluster
      const regionId = getRegion(action)
      const selfUrl = getSelfUrl(regionId, id)
      return createApm({
        regionId,
        apmId: id,
        selfUrl,
        source: info,
        stackDeployment: action.payload,
      })
    }
  }

  return apm
}

export default function apmsReducer(state: State = {}, action: Action) {
  if (action.type === FETCH_STACK_DEPLOYMENT && !action.error && action.payload) {
    const regionId = getRegion(action)
    const apmId = getClusterId()
    return {
      ...state,
      [createDescriptor(regionId, apmId)]: apmReducer(getApm(state, regionId, apmId), action),
    }
  }

  return state

  function getClusterId() {
    if (
      action.type === FETCH_STACK_DEPLOYMENT &&
      !action.error &&
      action.payload &&
      action.payload.resources.apm[0]
    ) {
      return action.payload.resources.apm[0].id
    }

    return '-'
  }
}

function getRegion(action: Action) {
  const { regionId } = action.meta

  if (regionId) {
    return regionId
  }

  // assume Stack deployments API action
  const stackDeploymentsAction = action as FetchDeploymentAction
  return getRegionId({ deployment: stackDeploymentsAction.payload! })!
}

export function getApm(state: State, regionId: RegionId, apmId: ApmId): ApmCluster | null {
  return state[createDescriptor(regionId, apmId)]
}

export function getApmPlanAttempts(
  state: State,
  regionId: RegionId,
  apmId: ApmId,
): ApmCluster | null {
  return state[createDescriptor(regionId, apmId)]
}

export function getApmMetadata(apm: ApmCluster): ApmMetadata {
  return apm._raw.data
}

function getSelfUrl(regionId: string, id: string): string {
  return `/api/v1/regions/${regionId}/clusters/apm/${id}`
}
