/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { connect } from 'react-redux'

import { fetchOrganizationMemberships } from '@/actions/organizations'
import { fetchOrganizationMembershipsRequest } from '@/reducers/asyncRequests/registry'
import { getProfile } from '@/apps/userconsole/reducers'

import {
  fetchApiKeys,
  generateApiKey,
  resetRevokeKeyRequest,
  revokeApiKey,
} from '../../actions/apiKeys'
import {
  fetchApiKeysRequest,
  generateApiKeyRequest,
  getApiKeys,
  revokeApiKeyRequest,
  getOrganizationMembers,
} from '../../reducers'
import { getConfigForKey } from '../../selectors'
import { showApiKeys } from '../UserSettings/helpers'
import { getMemberEmail, hasNoMember } from '../Organization/OrganizationMembers/lib/index'

import ApiKeys from './ApiKeys'

import type { ReduxState, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => {
  const apiKeysInitState = getApiKeys(state)

  // ESS AC, ESSP AC, GovCloud AC, etc.
  const isAnySaasAdminconsole =
    getConfigForKey(state, `APP_PLATFORM`) === `saas` &&
    getConfigForKey(state, `APP_NAME`) === `adminconsole`

  const profile = getProfile(state)
  const organizationId = profile?.organization_id
  const organizationMembers = getOrganizationMembers(state, organizationId)

  return {
    apiKeys: apiKeysInitState.map((apiKey) => ({
      ...apiKey,
      createdBy: getMemberEmail({ userId: apiKey.user_id, members: organizationMembers }),
      isRemoved: isAnySaasAdminconsole
        ? false
        : hasNoMember({ userId: apiKey.user_id, members: organizationMembers }),
    })),
    organizationId,
    fetchKeysRequest: fetchApiKeysRequest(state),
    fetchOrganizationMembersRequest: ({ organizationId: id }) =>
      fetchOrganizationMembershipsRequest(state, id),
    revokeApiKeyRequest: revokeApiKeyRequest(state),
    generateKeyRequest: generateApiKeyRequest(state),
    showApiKeys: showApiKeys(state) || isAnySaasAdminconsole,
  }
}

const mapDispatchToProps: (dispatch: ThunkDispatch, ownProps: ConsumerProps) => DispatchProps = (
  dispatch,
) => ({
  fetchApiKeys: () => dispatch(fetchApiKeys()),
  fetchOrganizationMembers: ({ organizationId }) =>
    dispatch(fetchOrganizationMemberships({ organizationId })),
  revokeApiKey: (keyId) => dispatch(revokeApiKey(keyId)),
  resetRevokeKeyRequest: () => dispatch(resetRevokeKeyRequest()),
  generateApiKey: (apiKey) => dispatch(generateApiKey(apiKey)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ApiKeys)
