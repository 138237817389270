/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiIcon,
  EuiRadioGroup,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiTable, CuiLink, CuiAlert } from '../../cui'
import Header from '../Header'
import SpinButton from '../SpinButton'
import { CustomerLevel } from '../CustomerProfile'
import OrganizationDomain from '../Organization/OrganizationOverview/OrganizationDomain'
import history from '../../lib/history'
import { userOverviewUrl, organizationOverviewUrl } from '../../lib/urlBuilder'
import { saasUsersCrumbs } from '../../lib/crumbBuilder'

import type { CuiTableColumn } from '../../cui'
import type { MessageDescriptor } from 'react-intl'
import type {
  AsyncRequestState,
  BillingSubscriptionLevel,
  ProcedureState,
  StackDeployment,
} from '../../types'
import type { Organization } from '../../lib/api/v1/types'

type SpArgs = {
  procedureName: string
  parameters: any[]
  userId?: string
}

type Props = {
  lookupSaasUsers: boolean
  fetchSaasUserRequest: (userId: string) => AsyncRequestState
  deploymentsRequest: AsyncRequestState
  callStoredProcedureRequest: AsyncRequestState
  saasUsers: any
  deployments: StackDeployment[]
  procedureState: ProcedureState
  isPrivate: boolean
  fetchSaasUser: (userId: string) => Promise<any>
  callStoredProcedure: (args: SpArgs) => Promise<any>
  resetCallStoredProcedureRequest: (name: string) => void
  searchDeployment: (deploymentId: string) => Promise<any>
}

type SearchTypeOption = 'id' | 'email' | 'deployment_id'

type SaasUserRecord = {
  user_id: string
  email: string
  source: string
  level: BillingSubscriptionLevel
  is_trial: string
  organization?: Organization
}

type State = {
  saasUserRequestId: string | null
  saasUserTableRows: SaasUserRecord[] | null
  searchError: MessageDescriptor | null
  searchingByDeploymentId: string | null
  searchingByEmail: string | null
  searchingById: string | null
  searchInput: string
  searchType: SearchTypeOption
}

const messages = defineMessages({
  userNotFound: {
    id: `users.user-not-found`,
    defaultMessage: `No users match your query.`,
  },
  deploymentNotFound: {
    id: `users.deployment-not-found`,
    defaultMessage: `No deployments that match the provided ID were found.`,
  },
  userNotFoundByEmail: {
    id: `users.user-not-found-by-email`,
    defaultMessage: `No users associated to the provided email address were found.`,
  },
})

const initialState: State = {
  searchType: `id`,
  searchInput: ``,
  searchingById: null,
  searchingByEmail: null,
  searchingByDeploymentId: null,
  saasUserRequestId: null,
  saasUserTableRows: null,
  searchError: null,
}

class Users extends Component<Props, State> {
  state: State = initialState

  componentDidMount() {
    const { lookupSaasUsers } = this.props

    if (!lookupSaasUsers) {
      return
    }
  }

  componentWillUnmount() {
    this.props.resetCallStoredProcedureRequest(`user_lookup`)
  }

  render() {
    const {
      lookupSaasUsers,
      fetchSaasUserRequest,
      deploymentsRequest,
      callStoredProcedureRequest,
      isPrivate,
    } = this.props

    if (!lookupSaasUsers) {
      return null
    }

    const {
      saasUserRequestId,
      saasUserTableRows,
      searchError,
      searchingByDeploymentId,
      searchingByEmail,
      searchingById,
      searchInput,
      searchType,
    } = this.state

    const saasUserRequest = saasUserRequestId ? fetchSaasUserRequest(saasUserRequestId) : null

    const saasUserTableColumns: Array<CuiTableColumn<SaasUserRecord>> = [
      {
        label: <FormattedMessage id='users.table-email' defaultMessage='Email' />,
        render: (saasUser) => (
          <CuiLink to={userOverviewUrl(saasUser.user_id)}>{saasUser.email}</CuiLink>
        ),
        sortKey: `email`,
      },
      {
        label: <FormattedMessage id='users.table-source-domain' defaultMessage='Domain' />,
        render: (saasUser) => <OrganizationDomain domain={saasUser.source} />,
        sortKey: `source`,
      },
      {
        label: (
          <FormattedMessage id='users.table-subscription-level' defaultMessage='Subscription' />
        ),
        render: (saasUser) => <CustomerLevel isPrivate={isPrivate} level={saasUser.level} />,
        sortKey: `level`,
      },
      {
        label: (
          <FormattedMessage id='users.table-subscription-attribbutes' defaultMessage='Attributes' />
        ),
        render: (saasUser) =>
          saasUser.is_trial !== `false` ? (
            <EuiText size='s' color='success'>
              <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='clock' size='m' />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <FormattedMessage id='users.in-trial' defaultMessage='Trial started' />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiText>
          ) : (
            <EuiText color='subdued'>
              <FormattedMessage id='users.no-attributes' defaultMessage='None' />
            </EuiText>
          ),
        sortKey: `is_trial`,
      },
    ]

    saasUserTableColumns.push({
      label: <FormattedMessage id='users.table-organization-id' defaultMessage='Organization ID' />,
      render: ({ organization }) =>
        organization ? (
          <CuiLink to={organizationOverviewUrl(organization.id)}>{organization.id}</CuiLink>
        ) : (
          '-'
        ),
      sortKey: `organization.id`,
    })

    const searchTypeOptions = [
      {
        id: `id`,
        label: <FormattedMessage id='users.search-by-id-label' defaultMessage='ID' />,
      },
      {
        id: `email`,
        label: <FormattedMessage id='users.search-by-email-label' defaultMessage='Email' />,
      },
      {
        id: `deployment_id`,
        label: (
          <FormattedMessage
            id='users.search-by-deployment-id-label'
            defaultMessage='Deployment ID'
          />
        ),
      },
    ]

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='users.title' defaultMessage='Users' />}
          breadcrumbs={saasUsersCrumbs()}
        />

        <EuiSpacer size='m' />

        <EuiFormLabel>
          <FormattedMessage id='users.find-label' defaultMessage='Find a user by:' />
        </EuiFormLabel>

        <EuiRadioGroup
          options={searchTypeOptions}
          idSelected={searchType}
          onChange={(searchType: SearchTypeOption) => this.setState({ searchType })}
        />

        <EuiSpacer size='m' />

        {searchType === `id` && (
          <form onSubmit={this.searchByIdSubmit}>
            <EuiFormLabel>
              <FormattedMessage id='users.user-id-label' defaultMessage='ID' />
            </EuiFormLabel>

            <EuiSpacer size='xs' />

            <EuiFieldText
              value={searchInput}
              onChange={(e) => this.setState({ searchInput: e.target.value })}
            />

            <EuiSpacer size='m' />

            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <SpinButton
                  fill={true}
                  spin={Boolean(searchingById)}
                  disabled={!this.canSearchById()}
                  onClick={this.searchById}
                >
                  <FormattedMessage id='users.search-button-text' defaultMessage='Search' />
                </SpinButton>
              </EuiFlexItem>

              {this.hasSearchResults() && (
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={() => this.resetSearchState()}>
                    <FormattedMessage id='users.reset-button-text' defaultMessage='Reset' />
                  </EuiButtonEmpty>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </form>
        )}

        {searchType === `email` && (
          <form onSubmit={this.searchByEmailSubmit}>
            <EuiFormLabel>
              <FormattedMessage id='users.email-label' defaultMessage='Email' />
            </EuiFormLabel>

            <EuiSpacer size='xs' />

            <EuiFieldText
              value={searchInput}
              onChange={(e) => this.setState({ searchInput: e.target.value })}
            />

            <EuiSpacer size='m' />

            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <SpinButton
                  fill={true}
                  spin={Boolean(searchingByEmail)}
                  disabled={!this.canSearchByEmail()}
                  onClick={this.searchByEmail}
                >
                  <FormattedMessage id='users.search-button-text' defaultMessage='Search' />
                </SpinButton>
              </EuiFlexItem>

              {this.hasSearchResults() && (
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={() => this.resetSearchState()}>
                    <FormattedMessage id='users.reset-button-text' defaultMessage='Reset' />
                  </EuiButtonEmpty>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </form>
        )}

        {searchType === `deployment_id` && (
          <form onSubmit={this.searchByDeploymentIdSubmit}>
            <EuiFormLabel>
              <FormattedMessage id='users.deployment-id-label' defaultMessage='Deployment ID' />
            </EuiFormLabel>

            <EuiSpacer size='xs' />

            <EuiFieldText
              value={searchInput}
              onChange={(e) => this.setState({ searchInput: e.target.value })}
            />

            <EuiSpacer size='m' />

            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <SpinButton
                  fill={true}
                  spin={Boolean(searchingByDeploymentId)}
                  disabled={!this.canSearchByDeploymentId()}
                  onClick={this.searchByDeploymentId}
                >
                  <FormattedMessage id='users.search-button-text' defaultMessage='Search' />
                </SpinButton>
              </EuiFlexItem>

              {this.hasSearchResults() && (
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={() => this.resetSearchState()}>
                    <FormattedMessage id='users.reset-button-text' defaultMessage='Reset' />
                  </EuiButtonEmpty>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </form>
        )}

        {saasUserTableRows && (
          <Fragment>
            <EuiSpacer size='l' />

            <CuiTable<SaasUserRecord> rows={saasUserTableRows} columns={saasUserTableColumns} />
          </Fragment>
        )}

        {searchError && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>
              <FormattedMessage {...searchError} />
            </CuiAlert>
          </Fragment>
        )}

        {callStoredProcedureRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{callStoredProcedureRequest.error}</CuiAlert>
          </Fragment>
        )}

        {deploymentsRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{deploymentsRequest.error}</CuiAlert>
          </Fragment>
        )}

        {saasUserRequest && saasUserRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{saasUserRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  searchById = () => {
    if (!this.canSearchById()) {
      return
    }

    const { fetchSaasUser } = this.props
    const { searchInput } = this.state
    const userId = searchInput.trim()

    this.resetSearchState()
    this.setState({
      searchingById: userId,
      saasUserRequestId: userId,
    })

    fetchSaasUser(userId)
      .then(() => this.navigateAwayWhenFoundById(userId))
      .catch(() => {
        this.setState({
          searchingById: null,
        })
      })
  }

  searchByEmail = () => {
    if (!this.canSearchByEmail()) {
      return
    }

    const { callStoredProcedure } = this.props
    const { searchInput } = this.state
    const email = searchInput.trim()

    this.resetSearchState()
    this.setState({ searchingByEmail: email })

    callStoredProcedure({
      procedureName: `user_lookup`,
      parameters: [email],
    })
      .then(() => this.searchByEmailAssociatedUserId())
      .catch(() => {
        this.setState({
          searchingByEmail: null,
        })
      })
  }

  searchByEmailAssociatedUserId() {
    const { searchingByEmail } = this.state

    if (!searchingByEmail) {
      return // sanity
    }

    const { procedureState, fetchSaasUser } = this.props

    if (!procedureState) {
      return
    }

    const results = procedureState.result

    if (isEmpty(results)) {
      this.resetSearchState({
        searchError: messages.userNotFoundByEmail,
      })
      return
    }

    if (results.length > 1) {
      this.resetSearchState({
        saasUserTableRows: results as any[],
      })
      return
    }

    const [firstRow] = results
    const { user_id: userId } = firstRow

    this.setState({
      saasUserRequestId: userId,
    })

    return fetchSaasUser(userId)
      .then(() => this.navigateAwayWhenFoundById(userId))
      .catch(() => {
        this.setState({
          searchingByEmail: null,
        })
      })
  }

  searchByDeploymentId = () => {
    if (!this.canSearchByDeploymentId()) {
      return
    }

    const { searchDeployment } = this.props
    const { searchInput } = this.state
    const deploymentId = searchInput.trim()

    this.resetSearchState()
    this.setState({ searchingByDeploymentId: deploymentId })

    searchDeployment(deploymentId)
      .then(() => this.searchByDeploymentOwnerId())
      .catch(() => {
        this.setState({
          searchingByDeploymentId: null,
        })
      })
  }

  searchByDeploymentOwnerId() {
    const { searchingByDeploymentId } = this.state

    if (!searchingByDeploymentId) {
      return // sanity
    }

    const { deployments, fetchSaasUser } = this.props

    if (!deployments) {
      return
    }

    if (isEmpty(deployments)) {
      this.resetSearchState({
        searchError: messages.deploymentNotFound,
      })
      return
    }

    const [deployment] = deployments
    const userId = deployment.metadata?.owner_id

    if (!userId) {
      return
    }

    this.setState({
      saasUserRequestId: userId,
    })

    return fetchSaasUser(userId)
      .then(() => this.navigateAwayWhenFoundById(userId))
      .catch(() => {
        this.setState({
          searchingByDeploymentId: null,
        })
      })
  }

  resetSearchState({
    saasUserTableRows = null,
    searchError = null,
  }: {
    searchError?: MessageDescriptor | null
    saasUserTableRows?: SaasUserRecord[] | null
  } = {}) {
    this.setState({
      searchingById: null,
      searchingByEmail: null,
      searchingByDeploymentId: null,
      saasUserRequestId: null,
      saasUserTableRows,
      searchError,
    })
  }

  canSearchById() {
    const { searchInput, searchingByEmail, searchingByDeploymentId } = this.state
    return !(!searchInput || searchingByEmail || searchingByDeploymentId)
  }

  canSearchByEmail() {
    const { searchInput, searchingById, searchingByDeploymentId } = this.state
    return !(!searchInput || searchingById || searchingByDeploymentId)
  }

  canSearchByDeploymentId() {
    const { searchInput, searchingById, searchingByEmail } = this.state
    return !(!searchInput || searchingById || searchingByEmail)
  }

  searchByIdSubmit = (e) => {
    e.preventDefault()
    this.searchById()
  }

  searchByEmailSubmit = (e) => {
    e.preventDefault()
    this.searchByEmail()
  }

  searchByDeploymentIdSubmit = (e) => {
    e.preventDefault()
    this.searchByDeploymentId()
  }

  navigateAwayWhenFoundById(userId) {
    if (!userId) {
      this.resetSearchState({
        searchError: messages.userNotFound,
      })
      return // sanity
    }

    this.resetSearchState()

    const { saasUsers } = this.props
    const profile = saasUsers[userId]

    if (!profile) {
      return
    }

    history.push(userOverviewUrl(userId))
  }

  hasSearchResults(): boolean {
    const { searchError, saasUserTableRows } = this.state

    return Boolean(searchError || saasUserTableRows)
  }
}

export default Users
