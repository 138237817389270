/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flow, isEmpty } from 'lodash'
import React, { Fragment } from 'react'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { withErrorBoundary } from '../../../cui'
import { useForceUpdate } from '../../../hooks'
import {
  dismissProblem,
  getEuiColor,
  getPlatformHealthProblems,
  HealthProblemList,
  HealthProblemListDismissed,
  resetProblemDismissions,
} from '../../../lib/healthProblems'

import PlatformHealthProblemsTitle from './PlatformHealthProblemsTitle'

import type { AsyncRequestState, License, Region, Runner } from '../../../types'
import type { TlsPublicCertChain } from '../../../lib/api/v1/types'
import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  region?: Region
  license: License
  fetchLicenseRequest: AsyncRequestState
  runners: Runner[]
  spacerBefore?: boolean
  spacerAfter?: boolean
  uiTlsChain?: TlsPublicCertChain
  proxyTlsChain?: TlsPublicCertChain
  internalCasTlsCerts?: TlsPublicCertChain
}

const PlatformHealthProblems: FunctionComponent<Props> = ({
  region,
  license,
  fetchLicenseRequest,
  runners,
  spacerBefore,
  spacerAfter,
  uiTlsChain,
  proxyTlsChain,
  internalCasTlsCerts,
}) => {
  const [problems, dismissedProblems] = getPlatformHealthProblems({
    region,
    license,
    fetchLicenseRequest,
    runners,
    uiTlsChain,
    proxyTlsChain,
    internalCasTlsCerts,
  })

  /* we need to force updates because that's the only way to invalidate our UI after
   * changes made against localStorage affect what problems we show (based on dismissals)
   */
  const forceUpdate = useForceUpdate()

  if (isEmpty(problems)) {
    if (isEmpty(dismissedProblems)) {
      return null
    }

    return (
      <Fragment>
        {spacerBefore && <EuiSpacer size='m' />}

        <HealthProblemListDismissed
          dismissedProblems={dismissedProblems}
          resetProblemDismissions={flow(resetProblemDismissions, forceUpdate)}
        />

        {spacerAfter && <EuiSpacer size='m' />}
      </Fragment>
    )
  }

  const euiColor = getEuiColor(problems)

  return (
    <Fragment>
      {spacerBefore && <EuiSpacer size='m' />}

      <EuiCallOut
        data-test-id='platform-health-problems'
        title={<PlatformHealthProblemsTitle problems={problems} />}
        color={euiColor}
      >
        <HealthProblemList
          problems={problems}
          dismissedProblems={dismissedProblems}
          dismissProblem={flow(dismissProblem, forceUpdate)}
          resetProblemDismissions={flow(resetProblemDismissions, forceUpdate)}
        />
      </EuiCallOut>

      {spacerAfter && <EuiSpacer size='m' />}
    </Fragment>
  )
}

export default withErrorBoundary(PlatformHealthProblems)
