/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export const startsWithVowel = (word: string): boolean =>
  [`a`, `e`, `i`, `o`, `u`].includes(word.charAt(0).toLowerCase())

// Keeping it simple for the moment, but we may want to
// eventually pull in formatjs.io/docs/polyfills/intl-listformat/
export const formatAsSentence = (words: string[]): string => {
  if (words.length <= 1) {
    return words.join(``)
  }

  return `${words.slice(0, words.length - 1).join(', ')} and ${words.slice(-1)}`
}

const UNDASHED_UUID_PATTERN = /^[0-9a-f]{32}$/i
const DASHED_UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const isFullUuid = (potentialUuid: string | null | undefined): boolean => {
  if (potentialUuid == null) {
    return false
  }

  if (UNDASHED_UUID_PATTERN.test(potentialUuid)) {
    return true
  }

  if (DASHED_UUID_PATTERN.test(potentialUuid)) {
    return true
  }

  return false
}

export const filterToHex = (value: string): string => value.replace(/[^0-9a-f]/gi, ``).toLowerCase()
