/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import {
  EUI_MODAL_CANCEL_BUTTON,
  EuiConfirmModal,
  EuiIcon,
  EuiOverlayMask,
  EuiProgress,
} from '@elastic/eui'

import Permission from '../../../lib/api/v1/permissions'
import { hasPermission } from '../../../lib/requiresPermission'
import { CuiPermissibleControl } from '../../../cui/PermissibleControl'

import RemovableAllocatorTagModalBody from './RemovableAllocatorTagModalBody/RemovableAllocatorTagModalBody'
import AllocatorTag from './AllocatorTag'

import type { RegionId } from '../../../types'
import type { InstanceConfiguration, MetadataItem } from '../../../lib/api/v1/types'
import type { WrappedComponentProps } from 'react-intl'

interface InstanceWithConfiguration {
  clusterId: string
  id: string
  instanceName: string
  capacity: number
  kind: string
  instanceConfiguration: InstanceConfiguration
}

interface Props extends WrappedComponentProps {
  modal?: any
  tag: MetadataItem
  spin: boolean
  regionId: RegionId
  instances: InstanceWithConfiguration[]
  onRemove: () => void
}

const messages = defineMessages({
  removeTag: {
    id: `allocator-tag-modal.modal-title`,
    defaultMessage: `Remove allocator tag?`,
  },
  cancel: {
    id: `allocator-tag-modal.modal-cancel`,
    defaultMessage: `Cancel`,
  },
  remove: {
    id: `allocator-tag-modal.modal-confirm`,
    defaultMessage: `Remove`,
  },
})

interface State {
  isModalOpen: boolean
}

class RemovableAllocatorTag extends Component<Props & WrappedComponentProps, State> {
  state: State = {
    isModalOpen: false,
  }

  render() {
    const { tag, spin } = this.props

    // The duplication here is annoying, but avoids rendering modals and such
    if (!hasPermission(Permission.deleteAllocatorMetadataItem)) {
      return (
        <CuiPermissibleControl permissions={Permission.deleteAllocatorMetadataItem}>
          <AllocatorTag className='allocatorTag-removable' tag={tag} color='hollow'>
            <EuiIcon type='cross' className='allocatorTag-remove' />
          </AllocatorTag>
        </CuiPermissibleControl>
      )
    }

    return (
      <div>
        <AllocatorTag
          className='allocatorTag-removable'
          tag={tag}
          onClick={() => this.showModal()}
          color='hollow'
        >
          <EuiIcon type='cross' className='allocatorTag-remove' />

          {spin && <EuiProgress color='danger' size='xs' position='absolute' />}
        </AllocatorTag>

        {this.renderModal()}
      </div>
    )
  }

  renderModal() {
    const { isModalOpen } = this.state
    const {
      tag,
      regionId,
      instances,
      intl: { formatMessage },
    } = this.props

    if (!isModalOpen) {
      return null
    }

    const defaultBody = (
      <RemovableAllocatorTagModalBody instances={instances} regionId={regionId} tag={tag} />
    )

    const {
      modal: {
        title = formatMessage(messages.removeTag),
        cancelButtonText = formatMessage(messages.cancel),
        confirmButtonText = formatMessage(messages.remove),
        defaultFocusedButton = EUI_MODAL_CANCEL_BUTTON,
        className = ``,
        body = defaultBody,
      } = {},
    } = this.props

    return (
      <EuiOverlayMask>
        <EuiConfirmModal
          className={className}
          title={title}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          defaultFocusedButton={defaultFocusedButton}
          onCancel={() => this.closeModal()}
          onConfirm={() => this.confirmAction()}
        >
          {body}
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  showModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  confirmAction() {
    this.closeModal()
    this.props.onRemove()
  }
}

export default injectIntl(RemovableAllocatorTag)
