/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { uniqBy } from 'lodash'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiRouterLinkButton } from '@/cui'
import { topologyViewDeploymentTemplateUrl } from '@/lib/urlBuilder'
import { topologyDeploymentTemplateEditCrumbs } from '@/lib/crumbBuilder'
import { withoutSoftDeleted } from '@/lib/instanceConfigurations'

import DeploymentTemplateWizard from '../DeploymentTemplateWizard'
import Header from '../../../Header'

import type { DeploymentTemplateRequestBody } from '@/lib/api/v1/types'
import type { AllProps, State } from './types'

export default class DeploymentTemplateEdit extends Component<AllProps, State> {
  state = {
    template: undefined,
  }

  static getDerivedStateFromProps(nextProps: AllProps, prevState: State): Partial<State> | null {
    if (prevState.template) {
      return null // initialization is done
    }

    const { instanceTypes, instanceConfigurations, template } = nextProps

    if (!instanceTypes || !instanceConfigurations || !template) {
      return null // pre-reqs
    }

    return {
      template,
    }
  }

  componentDidMount(): void {
    this.props.fetchInstanceTypes()
    this.props.fetchInstanceConfigurations()
    this.props.fetchDeploymentTemplate()
  }

  render(): JSX.Element {
    return (
      <Fragment>
        {this.renderTitle()}
        {this.renderContent()}
      </Fragment>
    )
  }

  renderTitle(): JSX.Element | undefined {
    const { regionId, template, fetchDeploymentTemplateRequest } = this.props

    if (!template) {
      return // sanity
    }

    const actions = (
      <CuiRouterLinkButton
        disabled={fetchDeploymentTemplateRequest.inProgress}
        to={topologyViewDeploymentTemplateUrl(regionId, template.id)}
      >
        <FormattedMessage id='deployment-template-edit.cancel' defaultMessage='Cancel' />
      </CuiRouterLinkButton>
    )

    return (
      <div>
        <Header
          breadcrumbs={topologyDeploymentTemplateEditCrumbs({ regionId, templateId: template.id })}
          name={template.name}
        >
          {actions}
        </Header>
      </div>
    )
  }

  renderContent(): JSX.Element {
    const {
      regionId,
      fetchInstanceConfigurationsRequest,
      fetchDeploymentTemplateRequest,
      updateDeploymentTemplateRequest,
      instanceConfigurations,
      location,
    } = this.props
    const { template } = this.state

    if (fetchInstanceConfigurationsRequest.error) {
      return <CuiAlert type='error'>{fetchInstanceConfigurationsRequest.error}</CuiAlert>
    }

    if (fetchDeploymentTemplateRequest.error) {
      return <CuiAlert type='error'>{fetchDeploymentTemplateRequest.error}</CuiAlert>
    }

    if (
      fetchDeploymentTemplateRequest.inProgress ||
      instanceConfigurations == null ||
      template === undefined
    ) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='l' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <FormattedMessage id='deployment-template-edit.loading' defaultMessage='Loading' />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    const availableInstanceConfigurations = uniqBy(
      [
        ...withoutSoftDeleted(instanceConfigurations), // those currently available, plus
        ...this.props.template!.instance_configurations, // those already in use in the template
      ],
      'id',
    )

    return (
      <Fragment>
        <EuiCallOut
          iconType='help'
          size='s'
          title={
            <FormattedMessage
              id='deployment-template-edit.notice'
              defaultMessage="Editing this deployment template won't affect any existing deployments"
            />
          }
        />

        <EuiSpacer />

        <DeploymentTemplateWizard
          regionId={regionId}
          template={template}
          instanceTypes={this.props.instanceTypes}
          instanceConfigurations={availableInstanceConfigurations}
          isLoading={fetchDeploymentTemplateRequest.inProgress}
          isSaving={updateDeploymentTemplateRequest.inProgress}
          updateDeploymentTemplate={this.updateDeploymentTemplate}
          onSave={this.onSave}
          initialStep={location.state ? location.state.showStep : null}
          saveButtonLabel={
            <FormattedMessage
              id='deployment-template-edit.save-changes'
              defaultMessage='Save changes'
            />
          }
        />

        {updateDeploymentTemplateRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{updateDeploymentTemplateRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  updateDeploymentTemplate = (template: DeploymentTemplateRequestBody): void => {
    this.setState({ template })
  }

  onSave = (): void => {
    const { template } = this.state

    if (!template) {
      return //sanity
    }

    this.props.updateDeploymentTemplate(template)
  }
}
