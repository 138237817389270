/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiBadge, EuiToolTip } from '@elastic/eui'

import { hasEolWarningMessage } from '@/lib/eolStatus'

import EndOfLifeVersionWarningMessage from '../EndOfLifeVersionWarningMessage'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const EOLVersionWarningBadge: FunctionComponent<Props> = ({
  resourceEolStatusList,
  fetchEolStatusRequest,
  version,
  sliderInstanceType = 'elasticsearch',
  style,
}) => {
  if (!hasEolWarningMessage({ resourceEolStatusList, version, fetchEolStatusRequest })) {
    return null
  }

  const WarningMessage = (
    <EndOfLifeVersionWarningMessage version={version} sliderInstanceType={sliderInstanceType} />
  )

  return (
    <EuiToolTip content={WarningMessage}>
      <EuiBadge color='warning' style={style}>
        EOL
      </EuiBadge>
    </EuiToolTip>
  )
}

export default EOLVersionWarningBadge
