/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, isEmpty } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiLink,
  EuiLoadingContent,
  EuiSpacer,
} from '@elastic/eui'

import {
  getPlanInfo,
  getPlanAttemptId,
  getVersion,
  hasHealthyResourcePlan,
} from '@/lib/stackDeployments/selectors'

import { CuiAlert, CuiTimeAgo } from '../../../cui'
import { StackConfigurationChangeId } from '../../StackDeploymentConfigurationChange'
import {
  createUpdateRequestFromGetResponse,
  enrichDeploymentFromTemplate,
  hasMismatchingEsVersions,
} from '../../../lib/stackDeployments'
import { promoteConfigurationEntriesToPlan } from '../../../lib/stackDeployments/configuration'
import { mergeDeep } from '../../../lib/immutability-helpers'
import { elasticStackVersionsUrl, supportUrl } from '../../../lib/urlBuilder'

import ClusterEditor from './ClusterEditor'
import AdvancedEditLink from './AdvancedEditLink'

import type { ElasticsearchClusterPlan } from '../../../lib/api/v1/types'
import type { StackDeploymentUpdateRequest } from '../../../types'
import type { DeepPartial } from '../../../lib/ts-essentials'
import type { AllProps, State } from './types'

class EditStackDeploymentEditor extends Component<AllProps, State> {
  state: State = {
    planState: `last_success`,
    editorState: this.getInitialEditorState(),
  }

  componentDidMount() {
    const { fetchRegionIfNeeded, regionId } = this.props

    fetchRegionIfNeeded(regionId)

    const { editorState } = this.state
    const { deploymentUnderEdit } = editorState
    const version = getVersion({ deployment: deploymentUnderEdit })

    if (version) {
      // ^ sanity
      this.props.fetchVersion(version, this.props.regionId)
    }
  }

  componentWillUnmount() {
    const { stackDeploymentId, resetUpdateDeployment } = this.props

    if (!stackDeploymentId) {
      return // sanity
    }

    resetUpdateDeployment(stackDeploymentId)
  }

  render() {
    return (
      <Fragment>
        {this.renderRecentFailureWarning()}
        {this.renderForm()}
      </Fragment>
    )
  }

  renderRecentFailureWarning() {
    const { editorState } = this.state
    const { deploymentUnderEdit } = editorState
    const [esResource] = deploymentUnderEdit.resources.elasticsearch

    const healthyEsPlan = hasHealthyResourcePlan({ resource: esResource })

    if (healthyEsPlan) {
      return null
    }

    const callOutProps = this.getRecentFailureWarningProps()

    if (callOutProps === null) {
      return null
    }

    if (hasMismatchingEsVersions({ deployment: deploymentUnderEdit })) {
      return null
    }

    return (
      <Fragment>
        <EuiCallOut {...callOutProps} />
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderForm() {
    const { regionId, esVersions, esVersionsRequest, region, regionRequest, architectureSummary } =
      this.props

    if (esVersionsRequest.error) {
      return (
        <CuiAlert
          data-test-id='edit-cluster-form-fetch-version-failed'
          details={esVersionsRequest.error}
          type='error'
        >
          <FormattedMessage
            id='edit-cluster-simple.fetching-elasticsearch-versions-failed'
            defaultMessage='Fetching Elasticsearch versions failed'
          />
        </CuiAlert>
      )
    }

    if (regionRequest.error) {
      return (
        <CuiAlert details={regionRequest.error} type='error'>
          <FormattedMessage
            id='region-region-wrapper.fetching-region-region-id-failed'
            defaultMessage='Fetching region {regionId} failed'
            values={{ regionId }}
          />
        </CuiAlert>
      )
    }

    if (esVersions == null || !region) {
      return (
        <div data-test-id='edit-cluster-form-loading'>
          <EuiLoadingContent lines={6} />
        </div>
      )
    }

    if (isEmpty(esVersions)) {
      return (
        <CuiAlert data-test-id='edit-cluster-form-fetch-version-empty' type='warning'>
          <FormattedMessage
            id='edit-cluster-simple.could-not-find-any-elasticsearch-versions-you-need-to-add-version-before-you-can-edit-the-cluster'
            defaultMessage='Could not find any Elasticsearch versions. You need to {addVersion} before you can edit the cluster.'
            values={{
              addVersion: (
                <Link to={elasticStackVersionsUrl(regionId)}>
                  <FormattedMessage
                    id='edit-cluster-simple.add-an-elasticsearch-version'
                    defaultMessage='add an Elasticsearch version'
                  />
                </Link>
              ),
            }}
          />
        </CuiAlert>
      )
    }

    const { editorState, planState } = this.state
    const { deploymentUnderEdit } = editorState

    if (hasMismatchingEsVersions({ deployment: deploymentUnderEdit })) {
      if (this.props.hideAdvancedEdit) {
        // use the absence of the advanced edit screen to know that this is in the UC and we have to hide the edit page
        return (
          <CuiAlert data-test-id='edit-cluster-form-fetch-version-mismatch-simple' type='warning'>
            <FormattedMessage
              id='edit-cluster-simple.version-mismatch-cannot-edit-simple'
              defaultMessage="Looks like there's a version mismatch in your deployment. This might be because of a failed version upgrade.
              Try to upgrade again, and if it's still a problem, <link>contact support</link>."
              values={{
                link: (content) => <Link to={supportUrl()}>{content}</Link>,
              }}
            />
          </CuiAlert>
        )
      }
    }

    return (
      <Fragment>
        {hasMismatchingEsVersions({ deployment: deploymentUnderEdit }) &&
          !this.props.hideAdvancedEdit && (
            <Fragment>
              <CuiAlert
                data-test-id='edit-cluster-form-fetch-version-mismatch-advanced'
                type='warning'
              >
                <FormattedMessage
                  id='edit-cluster-simple.version-mismatch-cannot-edit-advanced'
                  defaultMessage="Looks like there's a version mismatch in your deployment. This might be because of a failed version upgrade.
            You should retry the upgrade attempt, and escalate if that fails (e.g. if a simultaneous capacity increase is required)."
                />
              </CuiAlert>
              <EuiSpacer />
            </Fragment>
          )}

        <ClusterEditor
          architectureSummary={architectureSummary}
          editorState={editorState}
          region={region}
          esVersions={esVersions}
          instanceConfigurations={this.props.instanceConfigurations}
          basedOnAttempt={planState === `last_attempt`}
          onChange={this.onChange}
          hideConfigChangeStrategy={this.props.hideConfigChangeStrategy}
        />

        {this.props.hideAdvancedEdit || (
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiFormHelpText>
                <AdvancedEditLink editorState={editorState} />
              </EuiFormHelpText>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </Fragment>
    )
  }

  getRecentFailureWarningProps() {
    const { editorState, planState } = this.state
    const { deploymentUnderEdit } = editorState
    const [esResource] = deploymentUnderEdit.resources.elasticsearch

    const lastSuccess = getPlanInfo({ resource: esResource, state: `last_success` })
    const lastAttempt = getPlanInfo({ resource: esResource, state: `last_attempt` })

    if (!lastSuccess || !lastAttempt) {
      // Pointless to render a warning if there aren't two plans to switch between
      return null
    }

    const basedOnLastSuccess = planState === `last_success`

    if (basedOnLastSuccess) {
      const lastSuccessId = getPlanAttemptId({ resource: esResource, planAttempt: lastSuccess })

      const lastSuccessTime = (
        <CuiTimeAgo
          date={lastSuccess.attempt_end_time || lastSuccess.attempt_start_time}
          longTime={true}
          shouldCapitalize={false}
        />
      )

      return {
        color: `primary` as const,
        iconType: `check`,
        title: (
          <FormattedMessage
            id='edit-stack-deployment-editor.changes-based-on-last-success'
            defaultMessage='You are making changes based on the last successful configuration change — {lastSuccessId} — applied {lastSuccessTime}. {switchToLastAttempt}'
            values={{
              lastSuccessId: (
                <StackConfigurationChangeId
                  color='hollow'
                  kind='elasticsearch'
                  id={lastSuccessId}
                />
              ),
              lastSuccessTime,
              switchToLastAttempt: (
                <EuiLink onClick={() => this.resetBasedOnLastAttempt(lastAttempt)}>
                  <FormattedMessage
                    id='edit-stack-deployment-editor.switch-to-last-attempt'
                    defaultMessage='Switch to the last unsuccessful attempt?'
                  />
                </EuiLink>
              ),
            }}
          />
        ),
      }
    }

    const lastAttemptId = getPlanAttemptId({ resource: esResource, planAttempt: lastAttempt })

    const lastAttemptTime = (
      <CuiTimeAgo
        date={lastAttempt.attempt_end_time || lastAttempt.attempt_start_time}
        longTime={true}
        shouldCapitalize={false}
      />
    )

    return {
      color: `danger` as const,
      iconType: `alert`,
      title: (
        <FormattedMessage
          id='edit-stack-deployment-editor.changes-based-on-last-attempt'
          defaultMessage='Careful! You are making changes based on the last attempted configuration change — {lastAttemptId} — attempted {lastAttemptTime}. {switchToLastSuccess}'
          values={{
            lastAttemptId: (
              <StackConfigurationChangeId color='hollow' kind='elasticsearch' id={lastAttemptId} />
            ),
            lastAttemptTime,
            switchToLastSuccess: (
              <EuiLink onClick={() => this.resetBasedOnLastSuccess()}>
                <FormattedMessage
                  id='edit-stack-deployment-editor.switch-to-last-success'
                  defaultMessage='Switch to the last successful change?'
                />
              </EuiLink>
            ),
          }}
        />
      ),
    }
  }

  resetBasedOnLastAttempt = (lastAttempt) => {
    const editorState = this.getInitialEditorState(lastAttempt.plan)

    this.setState({ planState: `last_attempt`, editorState })
  }

  resetBasedOnLastSuccess = () => {
    const editorState = this.getInitialEditorState()

    this.setState({ planState: `last_success`, editorState })
  }

  getInitialEditorState(basedOnAttempt?: ElasticsearchClusterPlan): StackDeploymentUpdateRequest {
    const {
      stackDeployment,
      deploymentTemplate,
      regionId,
      stackDeploymentId,
      planAttemptUnderRetry,
    } = this.props

    /*
     * One or many underlying legacy components mutate the template object,
     * which is obviously wrong, but I failed to identify what the offender is.
     * Using a new clone each time this tree is mounted prevents various breakage
     * of things that depend on the deployment template response objects from coming
     * from just what what the API gives us.
     */
    const copiedDeploymentTemplate = cloneDeep(deploymentTemplate)

    const deployment = createUpdateRequestFromGetResponse({
      deployment: stackDeployment,
      deploymentTemplate: copiedDeploymentTemplate,
      planAttemptUnderRetry: basedOnAttempt || planAttemptUnderRetry,
    })

    // add template metadata into deployment to co-locate it for DeploymentInfrastructure
    if (copiedDeploymentTemplate?.deployment_template) {
      enrichDeploymentFromTemplate({
        deployment,
        deploymentTemplate: copiedDeploymentTemplate.deployment_template,
        forceNodeRoles: false,
      })
    }

    // relocate topology-level settings to plan-level as we arrive; we'll also
    // do it again on save in case it applies after editing
    promoteConfigurationEntriesToPlan({ deployment })

    return {
      regionId,
      id: stackDeploymentId!,
      deploymentTemplate: copiedDeploymentTemplate,
      deploymentUnderEdit: stackDeployment,
      planAttemptUnderRetry: basedOnAttempt || planAttemptUnderRetry,
      deployment,
    }
  }

  onChange = (
    changes: DeepPartial<StackDeploymentUpdateRequest>,
    { shallow = false }: { shallow?: boolean } = {},
  ) => {
    if (shallow) {
      // NOTE: when doing shallow changes, ensure you actually send us whole objects!
      return this.onChangeShallow(changes as Partial<StackDeploymentUpdateRequest>)
    }

    return this.onChangeDeep(changes)
  }

  onChangeShallow = (changes: Partial<StackDeploymentUpdateRequest>) => {
    const { editorState } = this.state

    const nextState: StackDeploymentUpdateRequest = {
      ...editorState,
      ...changes,
    }

    this.setState({ editorState: nextState })
  }

  onChangeDeep = (changes: DeepPartial<StackDeploymentUpdateRequest>) => {
    const { editorState } = this.state
    const nextState = mergeDeep(editorState, changes)
    this.setState({ editorState: nextState })
  }
}

export default EditStackDeploymentEditor
