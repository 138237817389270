/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getCluster, getStackDeployment } from '@/reducers'
import { withStackDeploymentRouteParams } from '@/components/StackDeploymentEditor'
import Feature from '@/lib/feature'
import { isFeatureActivated } from '@/selectors'

import ClusterOverview from './ClusterOverview'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ReduxState } from '@/types'

const mapStateToProps = (
  state: ReduxState,
  { regionId, deploymentId, stackDeploymentId }: ConsumerProps,
): StateProps => {
  const deployment = getStackDeployment(state, stackDeploymentId)
  const cluster = getCluster(state, regionId, deploymentId!)

  return {
    deployment,
    cluster,
    saasClusterMetrics: isFeatureActivated(state, Feature.saasClusterMetrics),
    showNativeMemoryPressure: isFeatureActivated(state, Feature.showNativeMemoryPressure),
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps)(ClusterOverview),
)
