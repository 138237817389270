/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { clone, flatMap, findIndex, uniqBy } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiIcon, EuiText } from '@elastic/eui'

import { CuiTable } from '@/cui'
import {
  findInstanceByExactMatch,
  findInstanceByPartialMatch,
  getArchitectureDescription,
  getInstancesByType,
  isMatchingInstance,
} from '@/lib/deployments/architecture'
import { getSupportedSliderInstanceTypes, getTopologyElementName } from '@/lib/sliders'
import {
  getDeploymentNodeConfigurations,
  getResources,
  getTopology,
  getVersion,
} from '@/lib/stackDeployments/selectors'

import messages from '../messages'

import ChangeSummary from './ChangeSummary'
import HardwareSummary from './HardwareSummary'

import type { CuiTableColumn } from '@/cui'
import type { NodeConfiguration, ZoneSummaryInstance } from '@/types'
import type { DeploymentTemplateInfoV2, InstanceConfiguration } from '@/lib/api/v1/types'
import type { HardwareComparisonProps as Props } from './types'

const makeLabel = (content: JSX.Element) => (
  <EuiText size='xs'>
    <strong>{content}</strong>
  </EuiText>
)

const HardwareComparison: React.FunctionComponent<Props> = ({
  deployment,
  deploymentTemplates,
  migratedTemplatePayload,
}): JSX.Element => {
  const version = getVersion({ deployment }) || undefined
  const instanceConfigurations = uniqBy(
    flatMap<DeploymentTemplateInfoV2, InstanceConfiguration>(
      deploymentTemplates,
      ({ instance_configurations }) => instance_configurations,
    ),
    'id',
  )

  const nodeConfigurations: NodeConfiguration[] = flatMap(
    getResources({ deployment }),
    (resource) => getTopology({ resource }),
  )
  const { keys: existingHardware } = getArchitectureDescription({
    nodeConfigurations,
    instanceConfigurations,
    version,
  })

  const migratedNodeConfigurations = getDeploymentNodeConfigurations({
    deployment: migratedTemplatePayload,
  })
  const { keys: migratedHardware } = getArchitectureDescription({
    nodeConfigurations: migratedNodeConfigurations,
    instanceConfigurations,
    version,
  })

  const columns: Array<CuiTableColumn<ZoneSummaryInstance & { isAddition?: boolean }>> = [
    {
      id: `component`,
      label: makeLabel(<FormattedMessage {...messages.componentTitle} />),
      render: ({ sliderInstanceType, topologyId }) => {
        const topologyElement = nodeConfigurations.find(({ id }) => id === topologyId)

        if (!topologyElement) {
          return null // sanity
        }

        return (
          <EuiText size='s'>
            <strong>
              {getTopologyElementName({
                topologyElement,
                sliderInstanceType,
                version,
              })}
            </strong>
          </EuiText>
        )
      },
      width: `220px`,
      truncateText: false,
      verticalAlign: `top`,
    },
    {
      id: `current`,
      label: makeLabel(<FormattedMessage {...messages.currentConfiguration} />),
      render: (instance) => (instance.isAddition ? null : <HardwareSummary instance={instance} />),
      truncateText: false,
    },
    {
      id: `icon`,
      render: ({ isAddition }) =>
        isAddition ? <EuiIcon type='plus' color='success' /> : <EuiIcon type='sortRight' />,
      width: `40px`,
    },
    {
      id: `new`,
      label: makeLabel(<FormattedMessage {...messages.newConfiguration} />),
      render: (instance) => {
        if (instance.isAddition) {
          return <HardwareSummary instance={instance} />
        }

        const newInstances = getInstancesByType(migratedHardware, instance.sliderInstanceType)
        const instanceExactMatch = findInstanceByExactMatch(newInstances, instance)
        const instancePartialMatch = findInstanceByPartialMatch(newInstances, instance)

        return <ChangeSummary exact={instanceExactMatch} partial={instancePartialMatch} />
      },
      truncateText: false,
    },
  ]

  const rows = getSupportedSliderInstanceTypes().reduce((prevInstances, sliderInstanceType) => {
    const existingSliderInstances = getInstancesByType(existingHardware, sliderInstanceType)

    const newInstances = getInstancesByType(migratedHardware, sliderInstanceType)
    const remainingNewInstances = clone(newInstances)

    for (const instance of existingSliderInstances) {
      const instanceExactMatch = findInstanceByExactMatch(newInstances, instance)
      const instancePartialMatch = findInstanceByPartialMatch(newInstances, instance)

      const matchedInstance = instanceExactMatch || instancePartialMatch
      const matchedIndex =
        matchedInstance &&
        findIndex(remainingNewInstances, (remainingInstance) =>
          isMatchingInstance(remainingInstance, matchedInstance),
        )

      if (typeof matchedIndex === `number` && matchedIndex > -1) {
        remainingNewInstances.splice(matchedIndex, 1)
      }
    }

    const newSliderInstances: Array<ZoneSummaryInstance & { isAddition: boolean }> =
      remainingNewInstances.map((instance) => ({
        ...instance,
        isAddition: true,
      }))

    return [...prevInstances, ...existingSliderInstances, ...newSliderInstances]
  }, [])

  return <CuiTable<ZoneSummaryInstance> columns={columns} rows={rows} />
}

export default HardwareComparison
