/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiRadio,
  EuiTextArea,
} from '@elastic/eui'

import type { DeploymentTemplateRequestBody } from '@/lib/api/v1/types'
import type { RegionId } from '../../../../types'

type Props = {
  regionId: RegionId
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (template: DeploymentTemplateRequestBody) => void
  closeModal: () => void
  onCancel: () => void
}

type State = {
  name: string
  description: string
  warnEmptyName: boolean
}

export default class CreateTemplateModal extends Component<Props, State> {
  state: State = {
    name: this.props.template.name,
    description: this.props.template.description || ``,
    warnEmptyName: false,
  }

  render() {
    const { onCancel } = this.props
    const { warnEmptyName } = this.state

    return (
      <EuiOverlayMask>
        <EuiModal onClose={onCancel} initialFocus='[name=name]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='deployment-template-create-modal.title'
                defaultMessage='Create template'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFormRow
              id='name-form-row'
              aria-label='name-text-field'
              label={
                <FormattedMessage
                  id='deployment-template-create-modal.name-label'
                  defaultMessage='Name'
                />
              }
              error={
                <span data-test-id='deployment-template-requires-name'>
                  <FormattedMessage
                    id='deployment-template-create-modal.required'
                    defaultMessage='Required'
                  />
                </span>
              }
              isInvalid={warnEmptyName}
            >
              <div data-test-id='deployment-template-name' id='deployment-template-name-wrapper'>
                <EuiFieldText
                  id='name-text-field'
                  aria-label='deployment-template-name'
                  isInvalid={warnEmptyName}
                  value={this.state.name}
                  name='name'
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </EuiFormRow>
            <EuiFormRow
              id='description-form-row'
              label={
                <FormattedMessage
                  id='deployment-template-create-modal.description-label'
                  defaultMessage='Description'
                />
              }
            >
              <EuiTextArea
                aria-label='deployment-template-description'
                id='deployment-template-description-textarea'
                data-test-id='deployment-template-description'
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </EuiFormRow>
            <EuiFormRow
              label={
                <FormattedMessage
                  id='deployment-template-create-modal.default-version-label'
                  defaultMessage='Default Elasticsearch version'
                />
              }
            >
              <EuiRadio
                id={`default-version`}
                checked={true}
                disabled={true}
                onChange={() => null}
                label={
                  <FormattedMessage
                    id='deployment-template-create-modal.default-version-description'
                    defaultMessage='Newest available'
                  />
                }
              />
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={onCancel}>
              <FormattedMessage
                id='deployment-template-create-modal.cancel'
                defaultMessage='Cancel'
              />
            </EuiButtonEmpty>

            <EuiButton onClick={this.save} fill={true} data-test-id='create-deployment-template'>
              <FormattedMessage id='deployment-template-list.save-button' defaultMessage='Save' />
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  save = () => {
    if (this.state.name.length === 0) {
      this.setState({ warnEmptyName: true })
      return
    }

    this.props.updateDeploymentTemplate({
      ...this.props.template,
      name: this.state.name,
      description: this.state.description,
    })

    this.props.closeModal()
  }
}
