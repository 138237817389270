/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiIcon,
  EuiLoadingContent,
  EuiOverlayMask,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
  EuiCallOut,
  EuiButtonGroup,
} from '@elastic/eui'
import type { EuiSuperSelectOption } from '@elastic/eui'

import { CuiAlert, CuiTable } from '@/cui'
import { isTrialEligibleTemplate } from '@/lib/deploymentTemplates/metadata'
import { isTemplateUpgradeAvailable } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getHourlyRate } from '@/lib/deployments/architecture'
import {
  getDeploymentNodeConfigurations,
  getNodeConfigurationsFromStackDeployment,
} from '@/lib/stackDeployments/selectors'
import TotalPrice from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/PriceButton/TotalPrice'

import messages, { getDescriptionText } from '../messages'

import HardwareComparison from './HardwareComparison'
import HardwareProfileDropdown from './HardwareProfileDropdown'
import NewVersionBadge from './NewVersionBadge'
import TemplateTitle from './TemplateTitle'

import type { AllProps } from './types'
import type { AnyTopologyElement } from '@/types'
import type { DeploymentTemplateInfoV2 } from '@/lib/api/v1/types'

interface State {
  priceViewSelected: 'hourly' | 'monthly'
}

class DeploymentTemplateFlyout extends Component<AllProps> {
  state: State = {
    priceViewSelected: `hourly`,
  }

  componentDidMount() {
    const { profile, fetchBasePrices, basePrices, isUserConsole } = this.props

    if (!basePrices && profile && isUserConsole) {
      fetchBasePrices({
        level: profile.level,
      })
    }
  }

  render() {
    const {
      deployment,
      deploymentTemplates,
      currentTemplate,
      newerTemplate,
      migratedTemplatePayload,
      migrateDeploymentTemplateRequest,
      inTrial,
      onSaveRequest,
      selectedId,
      onChange,
      onSave,
      onClose,
      updateDeploymentRequest,
      regionId,
      isUserConsole,
    } = this.props

    let options: Array<EuiSuperSelectOption<string>> = []

    // We need a placeholder item to tell people to choose a value
    const placeholderOptionValue = `placeholder`
    const placeholderOption = {
      value: placeholderOptionValue,
      disabled: true,
      inputDisplay: <FormattedMessage {...messages.placeholderOptionDisplay} />,
    }
    options.push(placeholderOption)

    const currentlySelectedTemplate = deploymentTemplates.find(
      ({ id }) => selectedId && selectedId === id,
    )

    const templateOptions: Array<EuiSuperSelectOption<string>> = deploymentTemplates.map(
      (template) => {
        const disabled = inTrial && !isTrialEligibleTemplate(template)
        const isUpgradeable =
          currentTemplate && isTemplateUpgradeAvailable(currentTemplate.id, template.id)

        return {
          value: template.id,
          disabled,
          inputDisplay: isUpgradeable ? (
            <Fragment>
              {template.name} <NewVersionBadge />
            </Fragment>
          ) : (
            template.name
          ),
          dropdownDisplay: (
            <HardwareProfileDropdown
              template={template}
              isDisabled={disabled}
              isUpgradeable={isUpgradeable}
            />
          ),
        }
      },
    )
    options = options.concat(templateOptions)

    const isDisabled =
      !selectedId ||
      migrateDeploymentTemplateRequest?.inProgress ||
      Boolean(migrateDeploymentTemplateRequest?.error)

    return (
      <EuiOverlayMask headerZindexLocation='below'>
        <EuiFlyout maxWidth='65rem' size='l' onClose={onClose}>
          <EuiFlyoutHeader hasBorder={true}>
            <EuiTitle size='m'>
              <h2>
                <FormattedMessage {...messages.editTemplate} values={{ isUserConsole }} />
              </h2>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiText color='subdued' size='s'>
              <p>{getDescriptionText()}</p>
            </EuiText>
          </EuiFlyoutHeader>

          <EuiFlyoutBody>
            {newerTemplate && (
              <Fragment>
                <EuiPanel hasBorder={true} hasShadow={false}>
                  <EuiTitle size='xxs'>
                    <h4>
                      <EuiIcon type='cheer' style={{ marginRight: `8px` }} />
                      <FormattedMessage
                        {...messages.previewNewVersionTitle}
                        values={{ title: currentTemplate?.name }}
                      />
                    </h4>
                  </EuiTitle>
                  <EuiSpacer size='m' />
                  <EuiText size='s'>
                    <FormattedMessage
                      {...messages.previewNewVersionDescription1}
                      values={{
                        currentTitle: currentTemplate?.name,
                      }}
                    />
                    <br />
                    <FormattedMessage {...messages.previewNewVersionDescription2} />
                  </EuiText>
                  <EuiSpacer />
                  <EuiButton onClick={() => onChange(newerTemplate.id)}>
                    <FormattedMessage {...messages.previewNewVersionButton} />
                  </EuiButton>
                </EuiPanel>
                <EuiSpacer />
              </Fragment>
            )}

            <EuiPanel color='subdued' borderRadius='none' hasShadow={false}>
              <EuiFlexGrid columns={2} responsive={false} gutterSize='none'>
                <EuiFlexItem
                  style={{ flexBasis: `215px`, maxWidth: `215px`, wordBreak: `break-word` }}
                >
                  <EuiText size='xs'>
                    <strong>
                      <FormattedMessage {...messages.currentTemplate} values={{ isUserConsole }} />
                    </strong>
                  </EuiText>

                  <EuiSpacer size='m' />

                  {currentTemplate && (
                    <TemplateTitle
                      template={currentTemplate}
                      style={{ display: `flex`, alignItems: `center`, minHeight: `40px` }}
                    />
                  )}
                </EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiText size='xs'>
                    <strong>
                      <FormattedMessage {...messages.newTemplate} values={{ isUserConsole }} />
                    </strong>
                  </EuiText>

                  <EuiSpacer size='m' />

                  <EuiSuperSelect
                    fullWidth={true}
                    hasDividers={true}
                    isLoading={migrateDeploymentTemplateRequest?.inProgress}
                    options={options}
                    valueOfSelected={selectedId || placeholderOptionValue}
                    onChange={(id) => onChange(id)}
                    data-test-id='migrate-deployment-template-id'
                  />
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiPanel>

            {currentTemplate?.id && (
              <Fragment>
                <EuiSpacer />

                {migrateDeploymentTemplateRequest?.inProgress && <EuiLoadingContent />}

                {migrateDeploymentTemplateRequest?.error && (
                  <Fragment>
                    <CuiAlert type='error' data-test-id='migrate-deployment-template-error'>
                      {migrateDeploymentTemplateRequest.error}
                    </CuiAlert>
                    <EuiSpacer />
                  </Fragment>
                )}

                {!isDisabled && migratedTemplatePayload && (
                  <Fragment>
                    <HardwareComparison
                      deployment={deployment}
                      deploymentTemplates={[...deploymentTemplates, currentTemplate]}
                      migratedTemplatePayload={migratedTemplatePayload}
                    />
                    {isUserConsole && this.renderPrices({ currentlySelectedTemplate, regionId })}
                  </Fragment>
                )}

                {updateDeploymentRequest.error && (
                  <Fragment>
                    <EuiSpacer size='s' />
                    <CuiAlert type='error' data-test-id='update-deployment-template-error'>
                      {updateDeploymentRequest.error}
                    </CuiAlert>
                    <EuiSpacer />
                  </Fragment>
                )}
              </Fragment>
            )}
          </EuiFlyoutBody>

          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={onClose} flush='left'>
                  <FormattedMessage {...messages.cancel} />
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  onClick={onSave}
                  isLoading={onSaveRequest.inProgress}
                  isDisabled={isDisabled}
                  type='button'
                  fill={true}
                  data-test-id='migrate-deployment-template-button'
                >
                  <FormattedMessage {...messages.update} />
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiOverlayMask>
    )
  }

  renderPrices({
    currentlySelectedTemplate,
    regionId,
  }: {
    currentlySelectedTemplate?: DeploymentTemplateInfoV2
    regionId: string
  }): JSX.Element {
    const {
      intl: { formatMessage },
      deployment,
      currentTemplate,
      migratedTemplatePayload,
      basePrices,
      fetchBasePricesRequest,
    } = this.props
    const { inProgress, error } = fetchBasePricesRequest

    if (!basePrices || !deployment || !migratedTemplatePayload) {
      return <EuiLoadingContent lines={1} />
    }

    if (isEmpty(basePrices) && inProgress && !error) {
      return <EuiLoadingContent lines={1} />
    }

    const currentPrice = this.getPrices({
      deploymentTemplate: currentTemplate,
      regionId,
      nodeConfigurations: getNodeConfigurationsFromStackDeployment(deployment),
    })
    const newPrice = this.getPrices({
      deploymentTemplate: currentlySelectedTemplate,
      regionId,
      nodeConfigurations: getDeploymentNodeConfigurations({
        deployment: migratedTemplatePayload,
      }),
    })

    const columns = [
      {
        id: `component`,
        label: ``,
        render: () => {
          const options = [
            {
              id: `hourly`,
              label: <FormattedMessage {...messages.hourlyCostLabel} />,
            },
            {
              id: `monthly`,
              label: <FormattedMessage {...messages.monthlyCostLabel} />,
            },
          ]

          return (
            <EuiButtonGroup
              options={options}
              idSelected={this.state.priceViewSelected}
              onChange={(e) => this.setState({ priceViewSelected: e })}
              legend={formatMessage(messages.priceButtonGroupLegend)}
            />
          )
        },
        width: `220px`,
        truncateText: false,
      },
      {
        id: `current`,
        label: ``,
        render: () => this.renderPrice(currentPrice),
        truncateText: false,
      },
      {
        id: `icon`,
        render: ({ isAddition }) =>
          isAddition ? <EuiIcon type='plus' color='success' /> : <EuiIcon type='sortRight' />,
        width: `40px`,
      },
      {
        id: `new`,
        label: ``,
        render: () => this.renderPrice(newPrice),
        truncateText: false,
      },
    ]

    // Bit of a hack here to get this table to work given that there's only ever 1 row. But I'm doing this because it then lines up nicely with the hardware
    // comparison table above. And I didn't want to mash the price info into that table as it's strongly typed to topology elements
    const rows = [{}]

    return <CuiTable columns={columns} rows={rows} hasHeaderRow={false} />
  }

  renderPrice(hourlyRate: number | null): JSX.Element {
    if (!hourlyRate || hourlyRate === 0) {
      return (
        <EuiCallOut color='warning'>
          <FormattedMessage
            id='deployment-template-migrate.price-unavailable'
            defaultMessage='Price unavailable'
          />
        </EuiCallOut>
      )
    }

    return <TotalPrice hourlyRate={hourlyRate} priceViewSelected={this.state.priceViewSelected} />
  }

  getPrices({
    deploymentTemplate,
    regionId,
    nodeConfigurations,
  }: {
    deploymentTemplate?: DeploymentTemplateInfoV2
    regionId: string
    nodeConfigurations: AnyTopologyElement[]
  }): number | null {
    const { basePrices, profile } = this.props

    if (!basePrices || !profile || !deploymentTemplate) {
      return null
    }

    const instanceConfigurations = deploymentTemplate.instance_configurations

    const { hourlyRate } = getHourlyRate({
      basePrices,
      regionId,
      instanceConfigurations,
      nodeConfigurations,
      level: profile.level,
    })

    return hourlyRate
  }
}

export default injectIntl(DeploymentTemplateFlyout)
