/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { updateNativeUser } from '../../../../../../actions/localUsers'
import { isCurrentUser, updateUserRequest } from '../../../../../../reducers'
import UserFlyout from '../UserFlyout'

import type { User } from '../../../../../../lib/api/v1/types'
import type { ThunkDispatch } from '../../../../../../types'

const mapStateToProps = (state, { regionId }) => ({
  isCurrentUser: (username: string) => isCurrentUser(state, username),
  onSaveRequestState: updateUserRequest(state, regionId),
})

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  onSave: (user: User) => dispatch(updateNativeUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFlyout)
