/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors'
import { LOCK_DEPLOYMENT } from '@/constants/actions'
import { lockDeploymentUrl } from '@/lib/api/v1/urls'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { fetchDeployment } from '../stackDeployments'

import type { DeploymentLockRequest } from '@/lib/api/v1/types'
import type { StackDeployment, ThunkAction } from '@/types'

export const lockDeployment =
  (deployment: StackDeployment, locked: boolean): ThunkAction =>
  (dispatch) => {
    const esResource = getFirstEsClusterFromGet({ deployment })
    const url = lockDeploymentUrl({ deploymentId: deployment.id, refId: esResource!.ref_id })
    const payload: DeploymentLockRequest = { locked }

    return dispatch(
      asyncRequest({
        type: LOCK_DEPLOYMENT,
        method: `POST`,
        url,
        crumbs: [deployment.id],
        payload,
      }),
    ).then(() => dispatch(fetchDeployment({ deploymentId: deployment.id })))
  }

export function resetLockDeploymentRequest(deploymentId: string) {
  return resetAsyncRequest(LOCK_DEPLOYMENT, [deploymentId])
}
