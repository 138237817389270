/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup, EuiFieldPassword, EuiSelect } from '@elastic/eui'

import { CuiTitleWithBadge } from '../../../../../../../cui'
import { CuiFormField } from '../../../../../../../cui/forms'
import DocLink from '../../../../../../../components/DocLink'
import { labels, messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

const TrustedCaCerts: FunctionComponent = () => (
  <EuiDescribedFormGroup
    className='euiDescribedFormGroup__fieldPadding--none'
    title={
      <CuiTitleWithBadge>
        <FormattedMessage {...sections.trustedCaCerts} />
      </CuiTitleWithBadge>
    }
    description={
      <FormattedMessage
        {...sections.trustedCaCertsDescription}
        values={{
          learnMore: (
            <DocLink link='activeDirectoryProviderCaCertsDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <CuiFormField
      label={<FormattedMessage {...labels.bundleUrl} />}
      name='trusted_ca_cert_url'
      helpText={<FormattedMessage {...labels.bundleUrlHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.certificateUrlTruststoreType} />}
      name='certificate_url_truststore_type'
      component={EuiSelect}
      options={[{ text: 'jks' }, { text: 'PKCS12' }]}
      helpText={<FormattedMessage {...labels.certificateUrlTruststoreTypeHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.certificateUrlTruststorePassword} />}
      name='certificate_url_truststore_password'
      component={EuiFieldPassword}
      helpText={<FormattedMessage {...labels.certificateUrlTruststorePasswordHelpText} />}
    />
  </EuiDescribedFormGroup>
)

export default TrustedCaCerts
