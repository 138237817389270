/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { defineMessages } from 'react-intl'

import { EuiLink } from '@elastic/eui'

import type { SliderNodeDefinition } from '../types'

const definition: SliderNodeDefinition = {
  messages: defineMessages({
    prettyName: {
      id: `sliders.elasticsearch.data_frozen.prettyName`,
      defaultMessage: `Frozen data`,
    },
    instanceConfigurationDescription: {
      id: `sliders.elasticsearch.data_frozen.instanceConfigurationDescription`,
      defaultMessage: `Maximize savings by archiving data on a frozen tier. Your storage size comes with a cached storage amount, which lets you query very large data sets with minimal compute resources. <cachedStorageLink>Learn more</cachedStorageLink>`,
      values: {
        cachedStorageLink: (content) => (
          <EuiLink
            target='_blank'
            href='https://www.elastic.co/guide/en/elasticsearch/reference/current/data-tiers.html#frozen-tier'
          >
            {content}
          </EuiLink>
        ),
      },
    },
  }),
  iconType: `logoElasticsearch`,
  trialLimit: {
    memorySize: 4096,
    zoneCount: 2,
  },
}

export default definition
