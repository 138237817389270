/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  revokeSelected: {
    id: `manage-keys.revoke`,
    defaultMessage: `Revoke selected`,
  },
  searchPlaceholder: {
    id: `manage-keys.search`,
    defaultMessage: `Search by API key name or user`,
  },
  ownedBy: {
    id: `manage-keys.owned-by`,
    defaultMessage: `Owner`,
  },
  apiKeyName: {
    id: `manage-keys.key-name`,
    defaultMessage: `API key`,
  },
  creationDate: {
    id: `manage-keys.creation-date`,
    defaultMessage: `Created`,
  },
  revoke: {
    id: `manage-keys.revoke-icon`,
    defaultMessage: `Revoke`,
  },
  revokeSuccess: {
    id: 'api-keys.revoke-key-success',
    defaultMessage: '{amount, plural, one {Key} other {Keys}} successfully revoked!',
  },
  disabledTooltip: {
    id: `manage-keys.revoke-disabled`,
    defaultMessage: `You do not have permission to revoke keys`,
  },
  revokeButton: {
    id: `manage-keys.revoke-icon-button`,
    defaultMessage: `Revoke button`,
  },
})
