/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { gte } from '../../lib/semver'

import DeploymentSecurityEditor from './DeploymentSecurityEditor'
import DeploymentSecurityEditorBefore5x from './DeploymentSecurityEditorBefore5x'

import type { FunctionComponent } from 'react'
import type { ElasticsearchCluster, StackDeployment } from '../../types'

type SecurityConfig = ElasticsearchCluster['security']['config']

export type Props = {
  deployment: StackDeployment | null
  cluster?: ElasticsearchCluster | null
  saveSecurityConfig: (cluster: ElasticsearchCluster, securityConfig: SecurityConfig) => void
  saveClusterAclInformation: {
    isSaving: boolean
    isSaved: boolean
    error: Error | null
  }
}

const DeploymentSecurity: FunctionComponent<Props> = ({
  deployment,
  cluster,
  saveSecurityConfig,
  saveClusterAclInformation,
}) => {
  if (deployment == null) {
    return null
  }

  if (cluster == null) {
    return null
  }

  const { plan } = cluster

  if (!plan.isActive) {
    return null
  }

  if (plan.version && gte(plan.version, `5.0.0`)) {
    return <DeploymentSecurityEditor deployment={deployment} cluster={cluster} />
  }

  return (
    <DeploymentSecurityEditorBefore5x
      config={cluster.security.config}
      saveSecurityConfig={(securityConfig: SecurityConfig) =>
        saveSecurityConfig(cluster, securityConfig)
      }
      saveClusterAclInformation={saveClusterAclInformation}
    />
  )
}

export default DeploymentSecurity
