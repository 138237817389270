/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiHealth, EuiFlexItem, EuiFlexGroup } from '@elastic/eui'

import { CuiLink, CuiTable, CuiTableLoadingCellContent } from '../../../cui'
import ControlPlaneTableRowActions from '../ControlPlaneTableRowActions'
import HostRoles from '../../Runners/RunnersTable/HostRoles'
import { hostControlPlaneUrl } from '../../../lib/urlBuilder'

import type { CuiTableColumn } from '../../../cui'
import type { FunctionComponent } from 'react'
import type { ControlPlane } from '../types'
import type { RegionHappySadClusters } from '../../../types'

type Props = {
  controlPlanes: ControlPlane[]
  happySadClusters?: RegionHappySadClusters
  fetchHappySadClusters: () => void
  regionId: string
  totalCount: number
  initialLoading: boolean
  isElasticCloudEnterprise: boolean
}

const ControlPlanesTable: FunctionComponent<Props> = ({
  controlPlanes,
  happySadClusters,
  totalCount,
  initialLoading,
  isElasticCloudEnterprise,
}) => {
  const columns: Array<CuiTableColumn<ControlPlane>> = [
    {
      label: <FormattedMessage id='control-planes-table.host' defaultMessage='Host' />,
      render: ({ regionId, hostId }) => (
        <CuiLink to={hostControlPlaneUrl(regionId, hostId)}>{hostId}</CuiLink>
      ),
      sortKey: `hostId`,
    },

    {
      label: <FormattedMessage id='control-planes-table.roles' defaultMessage='Roles' />,
      render: ({ runner }) => runner && <HostRoles runner={runner} maxInlineTags={5} />,
      sortKey: ({ runner }) => runner?.roles?.map((role) => role.role_name).join(' ') || '',
      textOnly: false,
    },

    {
      label: <FormattedMessage id='control-planes-table.status' defaultMessage='Status' />,
      render: ({ runner, ctor }) => {
        const unhealthyRunner = runner && !runner.healthy
        const disconnectedRunner = runner && !runner.connected

        return (
          <EuiFlexGroup gutterSize='s' alignItems='flexStart' direction='column'>
            {unhealthyRunner && (
              <EuiFlexItem grow={false}>
                <EuiHealth color='danger'>
                  <FormattedMessage
                    id='control-planes-table.unhealthy-host'
                    defaultMessage='Unhealthy host'
                  />
                </EuiHealth>
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <EuiHealth color={disconnectedRunner ? `danger` : `success`}>
                {disconnectedRunner ? (
                  <FormattedMessage
                    id='control-planes-table.disconnected'
                    defaultMessage='Disconnected'
                  />
                ) : (
                  <FormattedMessage
                    id='control-planes-table.connected'
                    defaultMessage='Connected'
                  />
                )}
              </EuiHealth>
            </EuiFlexItem>

            {ctor && ctor.maintenance && (
              <EuiFlexItem grow={false}>
                <EuiHealth color='warning'>
                  {isElasticCloudEnterprise ? (
                    <FormattedMessage
                      id='control-planes-table.controller-maintenance'
                      defaultMessage='Controller maintenance'
                    />
                  ) : (
                    <FormattedMessage
                      id='control-planes-table.ctor-maintenance'
                      defaultMessage='Constructor maintenance'
                    />
                  )}
                </EuiHealth>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )
      },
    },

    {
      label: <FormattedMessage id='control-planes-table.build' defaultMessage='Build' />,
      render: (controlPlane) => {
        const { loading, buildInfo } = getBuildInfo({ controlPlane, happySadClusters })

        if (loading) {
          return <CuiTableLoadingCellContent />
        }

        const missingDetails = (
          <EuiBadge color='hollow'>
            <FormattedMessage
              id='control-planes-table.missing-build-details'
              defaultMessage='Missing build details'
            />
          </EuiBadge>
        )

        if (!buildInfo) {
          return missingDetails
        }

        if (!buildInfo.shortCommitHash || !buildInfo.version) {
          return missingDetails
        }

        return (
          <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiBadge color='hollow'>{buildInfo.shortCommitHash}</EuiBadge>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiBadge color='hollow'>{buildInfo.version}</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
      sortKey: [
        (controlPlane) => {
          const { buildInfo } = getBuildInfo({ controlPlane, happySadClusters })
          return buildInfo?.shortCommitHash
        },
        (controlPlane) => {
          const { buildInfo } = getBuildInfo({ controlPlane, happySadClusters })
          return buildInfo?.version
        },
      ],
      textOnly: false,
    },

    {
      label: <FormattedMessage id='control-planes-table.actions' defaultMessage='Actions' />,
      render: (controlPlane) => <ControlPlaneTableRowActions controlPlane={controlPlane} />,
      width: `275px`,
    },
  ]

  return (
    <CuiTable<ControlPlane>
      columns={columns}
      rows={controlPlanes}
      getRowId={(controlPlane) => controlPlane.hostId}
      pageSize={25}
      showMatchCount={true}
      initialLoading={initialLoading}
      totalCount={totalCount}
      matchType={
        <FormattedMessage id='control-planes-table.match-type' defaultMessage='control plane' />
      }
      matchTypePlural={
        <FormattedMessage
          id='control-planes-table.match-type-plural'
          defaultMessage='control planes'
        />
      }
    />
  )
}

export default ControlPlanesTable

function getBuildInfo({
  controlPlane,
  happySadClusters,
}: {
  controlPlane: ControlPlane
  happySadClusters?: RegionHappySadClusters
}): {
  loading: boolean
  buildInfo?: {
    shortCommitHash?: string | null
    version?: string | null
  } | null
} {
  const { hostId, ctor, runner } = controlPlane

  if (ctor) {
    if (!happySadClusters) {
      return { loading: true }
    }

    const happySadCtor = getHappySadCtor(hostId)

    return {
      loading: false,
      buildInfo: happySadCtor,
    }
  }

  const commitHash = runner?.build_info?.commit_hash
  const shortCommitHash = commitHash && commitHash.slice(0, 6)

  return {
    loading: false,
    buildInfo: {
      shortCommitHash,
      version: runner?.build_info?.version,
    },
  }

  function getHappySadCtor(hostId) {
    if (!happySadClusters) {
      return null
    }

    const happySadCtor = happySadClusters.constructors.find((ctor) => ctor.id === hostId)

    if (!happySadCtor) {
      return null
    }

    return happySadCtor
  }
}
