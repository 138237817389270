/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { diffPlan } from '../../../../lib/stackDeployments/planDiffs'
import { formatDifferences } from '../formatters'
import { considerNoOpProductChanges, getClusterOperations } from '../explainChangesHelpers'

import type { ChangeSourceInfo, InstanceConfiguration } from '../../../../lib/api/v1/types'
import type { AnyPlan, AnyResourceInfo, SliderInstanceType } from '../../../../types'
import type { ExplainedChange } from '../types'

export default function explainChanges({
  sliderInstanceType,
  resource,
  source,
  oldPlan,
  newPlan,
  instanceConfigurations,
  pruneOrphans = true,
  isPastHistory,
}: {
  sliderInstanceType: SliderInstanceType
  resource: AnyResourceInfo
  source: ChangeSourceInfo | undefined
  newPlan: AnyPlan
  oldPlan: AnyPlan | null
  instanceConfigurations: InstanceConfiguration[]
  pruneOrphans?: boolean
  isPastHistory: boolean
}): ExplainedChange[] {
  const differences = diffPlan({
    instanceConfigurations,
    sliderInstanceType,
    pruneOrphans,
    current: oldPlan,
    next: newPlan,
  })

  const changes: ExplainedChange[] = [
    ...formatDifferences({
      differences,
      isPastHistory,
    }),
    ...getClusterOperations({
      type: sliderInstanceType,
      plan: newPlan,
      source,
      regionId: resource.region,
      esResourceId: sliderInstanceType === `elasticsearch` ? resource.id : null,
      isPastHistory,
    }),
  ]

  considerNoOpProductChanges({
    type: sliderInstanceType,
    changes,
    oldPlan,
    newPlan,
  })

  return changes
}
