/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedDate, FormattedMessage, injectIntl } from 'react-intl'
import { get, isEmpty, size } from 'lodash'

import {
  EuiAccordion,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiCode,
  EuiDescriptionList,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import DeleteUserButton from '../DeleteUserButton'
import SpinButton from '../../../../../../components/SpinButton'
import { numericDateTime, shortDate } from '../../../../../../config/dates'
import UserRoleComboBox from '../UserRoleCombobox'
import ApiRequestExample from '../../../../../../components/ApiRequestExample'
import { createUserUrl, updateUserUrl } from '../../../../../../lib/api/v1/urls'

import type { RoleMap } from '../UserRoleCombobox'
import type { AsyncRequestState, RegionId } from '../../../../../../types'
import type { User } from '../../../../../../lib/api/v1/types'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement, ReactNode } from 'react'

import './UserFlyout.scss'

interface Error {
  [key: string]: string | ReactElement<typeof FormattedMessage>
}

interface Props extends WrappedComponentProps {
  isCurrentUser?: (username: string) => boolean
  onSave: (data: User) => Promise<any>
  onSaveRequestState: AsyncRequestState
  regionId: RegionId
  open: boolean
  onClose: () => void
  user?: User
}

interface State {
  user_name: string
  full_name: string
  enabled: boolean
  email: string
  roles: string[]
  password1: string
  password2: string
  updatingPassword: boolean
  errors: Error
}

const messages = defineMessages({
  newUserTitle: {
    id: `manageUsers.newUserTitle`,
    defaultMessage: `Create User`,
  },
  username: {
    id: `manageUsers.userForm.usernameLabel`,
    defaultMessage: `Username`,
  },
  fullname: {
    id: `manageUsers.userForm.fullnameLabel`,
    defaultMessage: `Full name`,
  },
  usernameNote: {
    id: `manageUsers.userForm.usernameNoteLabel`,
    defaultMessage: `Must be unique`,
  },
  email: {
    id: `manageUsers.userForm.emailLabel`,
    defaultMessage: `Contact email`,
  },
  optional: {
    id: `manageUsers.userForm.optional`,
    defaultMessage: `Optional`,
  },
  roles: {
    id: `manageUsers.userForm.rolesLabel`,
    defaultMessage: `Roles`,
  },
  rolesPlaceholder: {
    id: `manageUsers.userForm.rolePlaceholder`,
    defaultMessage: `Select`,
  },
  updatePassword: {
    id: `manageUsers.userForm.updatePasswordButton`,
    defaultMessage: `Update password`,
  },
  password1: {
    id: `manageUsers.userForm.passwordLabel`,
    defaultMessage: `Password`,
  },
  password2: {
    id: `manageUsers.userForm.password2Label`,
    defaultMessage: `Confirm password`,
  },
  passwordNote: {
    id: `manageUsers.userForm.passwordNote`,
    defaultMessage: `Use a minimum of {passwordMinLength} characters`,
  },
  statusHelpText: {
    id: `manageUsers.userForm.userStatusHelpText`,
    defaultMessage: `Disabling a user prevents them from accessing the platform or deployments. You can enable them again later`,
  },
  disable: {
    id: `manageUsers.userForm.disable`,
    defaultMessage: `Disable user`,
  },
  addNew: {
    id: `manageUsers.userForm.addNewUserButton`,
    defaultMessage: `Create`,
  },
  update: {
    id: `manageUsers.userForm.updateUserButton`,
    defaultMessage: `Save`,
  },
  close: {
    id: `manageUsers.userForm.closeButton`,
    defaultMessage: `Close`,
  },
})

const passwordMinLength = 8

function makeApiErrors(apiError) {
  const errors = get(apiError, [`body`, `errors`], [])
  return errors.length ? { api: errors.map(({ message }) => message) } : null
}

function makeClientErrors(state) {
  const { updatingPassword } = state
  const requiredFields = ['user_name', 'roles']
  const errors: Error = {}

  if (updatingPassword) {
    requiredFields.push('password1', 'password2')
  }

  requiredFields.forEach((fieldName) => {
    if (size(state[fieldName]) === 0) {
      errors[fieldName] = (
        <FormattedMessage id='manageUsers.userForm.requiredError' defaultMessage='Required' />
      )
    }
  })

  if (/[^a-zA-Z0-9-_@.]/.test(state.user_name)) {
    errors.user_name = (
      <FormattedMessage
        id='manageUsers.newUserForm.usernameError'
        defaultMessage='User names can only contain alphanumerics and the characters { chars }'
        values={{
          chars: <EuiCode>_-@.</EuiCode>,
        }}
      />
    )
  }

  if (state.email && !/\w@\w/.test(state.email)) {
    errors.email = (
      <FormattedMessage
        id='manageUsers.newUserForm.emailError'
        defaultMessage='Invalid email address'
      />
    )
  }

  if (!errors.password1 && !errors.password2) {
    if (state.password1 !== state.password2) {
      errors.password2 = (
        <FormattedMessage
          id='manageUsers.userForm.mismatchedPasswordsError'
          defaultMessage='Passwords must match'
        />
      )
    } else if (state.password2 && state.password2.length < passwordMinLength) {
      errors.password2 = (
        <FormattedMessage
          id='manageUsers.userForm.passwordLengthError'
          defaultMessage='Passwords must have a minimum of {passwordMinLength} characters'
          values={{ passwordMinLength }}
        />
      )
    }
  }

  return isEmpty(errors) ? null : errors
}

const defaultState: State = {
  user_name: ``,
  full_name: ``,
  roles: [],
  enabled: true,
  email: ``,
  password1: ``,
  password2: ``,
  updatingPassword: true,
  errors: {},
}

class ManageUser extends Component<Props, State> {
  constructor(props) {
    super(props)

    const { user } = props

    if (user) {
      const {
        user_name,
        full_name,
        email,
        security: { roles, enabled },
      } = user

      this.state = {
        user_name,
        full_name,
        enabled,
        email,
        roles,
        password1: ``,
        password2: ``,
        updatingPassword: false,
        errors: {},
      }
    } else {
      this.state = defaultState
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.onSaveRequestState &&
      prevProps.onSaveRequestState.error !== this.props.onSaveRequestState.error
    ) {
      const errors = makeApiErrors(this.props.onSaveRequestState.error)

      if (errors) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ errors })
      }
    }
  }

  render() {
    const {
      onSaveRequestState,
      open,
      regionId,
      user,
      intl: { formatMessage },
    } = this.props

    const isUpdatingUser = this.isUpdatingUser()

    if (!open) {
      return null
    }

    return (
      <EuiFlyout
        onClose={this.closeFlyout}
        hideCloseButton={false}
        ownFocus={true}
        size='s'
        className='userFlyoutForm'
      >
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h1>{isUpdatingUser ? user!.user_name : formatMessage(messages.newUserTitle)}</h1>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          {this.renderFlyoutContent()}
          {this.renderDisabledField()}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem>
              <div>
                <EuiButtonEmpty
                  className='userFlyoutForm--closeButton'
                  iconType='cross'
                  onClick={this.closeFlyout}
                  flush='left'
                >
                  {formatMessage(messages.close)}
                </EuiButtonEmpty>
              </div>
            </EuiFlexItem>

            {isUpdatingUser && (
              <EuiFlexItem grow={false}>
                <DeleteUserButton
                  enabled={true}
                  user={user!}
                  regionId={regionId}
                  onClose={this.closeFlyout}
                />
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <SpinButton fill={true} onClick={this.onSave} spin={onSaveRequestState.inProgress}>
                {isUpdatingUser ? formatMessage(messages.update) : formatMessage(messages.addNew)}
              </SpinButton>

              {isUpdatingUser ? (
                <ApiRequestExample
                  method='PUT'
                  endpoint={updateUserUrl({ userName: this.state.user_name })}
                  body={this.getUserDataPayload()}
                />
              ) : (
                <ApiRequestExample
                  method='POST'
                  endpoint={createUserUrl()}
                  body={this.getUserDataPayload()}
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderPasswordFields = () => {
    const {
      intl: { formatMessage },
    } = this.props
    const { password1, password2, errors } = this.state
    const passwordPlaceholder = this.isUpdatingUser() ? '**********' : undefined

    return (
      <Fragment>
        <EuiFormRow
          label={formatMessage(messages.password1)}
          isInvalid={!!errors.password1}
          error={errors.password1}
          helpText={formatMessage(messages.passwordNote, { passwordMinLength })}
        >
          <EuiFieldPassword
            type='password'
            autoComplete='new-password'
            isInvalid={!!errors.password1}
            value={password1}
            placeholder={passwordPlaceholder}
            onChange={(e) => this.onPasswordChange(e.target.value, false)}
          />
        </EuiFormRow>

        <EuiFormRow
          label={formatMessage(messages.password2)}
          isInvalid={!!errors.password2}
          error={errors.password2}
        >
          <EuiFieldPassword
            type='password'
            autoComplete='new-password'
            isInvalid={!!errors.password2}
            value={password2}
            placeholder={passwordPlaceholder}
            onChange={(e) => this.onPasswordChange(e.target.value, true)}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderDisabledField = () => {
    const {
      intl: { formatMessage },
      isCurrentUser,
      user,
    } = this.props
    const { enabled, errors } = this.state
    const isCurrent = user && isCurrentUser && isCurrentUser(user.user_name)
    return (
      this.isUpdatingUser() && (
        <EuiFormRow
          helpText={!isCurrent && formatMessage(messages.statusHelpText)}
          isInvalid={!!errors.enabled}
          error={errors.enabled}
          className='manageUser-disable'
        >
          <EuiCheckbox
            id='user-disabled'
            disabled={isCurrent}
            data-test-id='manageUser-disable'
            label={formatMessage(messages.disable)}
            checked={!enabled}
            onChange={() => this.setState({ enabled: !enabled })}
          />
        </EuiFormRow>
      )
    )
  }

  renderFlyoutContent = () => {
    const {
      user,
      intl: { formatMessage },
    } = this.props
    const { user_name, full_name, roles, email, errors } = this.state
    const isUpdatingUser = this.isUpdatingUser()

    return (
      <EuiForm isInvalid={!!errors.api} error={errors.api}>
        {user && !user.security.enabled && (
          <Fragment>
            <EuiCallOut
              color='warning'
              iconType='alert'
              title={
                <FormattedMessage
                  id='manageUsers.userForm.disabledUserMessage'
                  defaultMessage='This user is currently disabled.'
                />
              }
            />
            <EuiSpacer />
          </Fragment>
        )}

        <EuiFormRow
          label={formatMessage(messages.username)}
          isInvalid={!!errors.user_name}
          error={errors.user_name}
          helpText={formatMessage(messages.usernameNote)}
        >
          <EuiFieldText
            isInvalid={!!errors.user_name}
            value={user_name}
            disabled={isUpdatingUser}
            // The API is case-sensitive for usernames, but that can cause confusion
            // so force usernames to be lowercase.
            onChange={(e) => this.setState({ user_name: e.target.value.toLowerCase() })}
          />
        </EuiFormRow>

        <EuiFormRow
          label={formatMessage(messages.fullname)}
          isInvalid={!!errors.full_name}
          error={errors.full_name}
          helpText={formatMessage(messages.optional)}
        >
          <EuiFieldText
            isInvalid={!!errors.full_name}
            value={full_name}
            onChange={(e) => this.setState({ full_name: e.target.value })}
          />
        </EuiFormRow>

        <UserRoleComboBox
          label={formatMessage(messages.roles)}
          error={errors.roles}
          selectedRoles={roles}
          onChange={this.onRoleChange}
          placeholder={formatMessage(messages.rolesPlaceholder)}
        />

        <EuiFormRow
          label={formatMessage(messages.email)}
          isInvalid={!!errors.email}
          error={errors.email}
          helpText={formatMessage(messages.optional)}
        >
          <EuiFieldText
            type='email'
            isInvalid={!!errors.email}
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </EuiFormRow>
        <EuiSpacer size='m' />

        {isUpdatingUser ? (
          <Fragment>
            <EuiAccordion
              id='userFlyoutForm-passwordAccordion'
              paddingSize='m'
              buttonContent={formatMessage(messages.updatePassword)}
            >
              {this.renderPasswordFields()}
            </EuiAccordion>
          </Fragment>
        ) : (
          this.renderPasswordFields()
        )}

        {this.renderMetadata()}

        <EuiSpacer />
      </EuiForm>
    )
  }

  renderMetadata() {
    const { user } = this.props

    if (user == null || user.metadata == null) {
      return null
    }

    const items: Array<{ title: ReactNode; description: ReactNode }> = []

    if (user.metadata.created_at) {
      items.push({
        title: <FormattedMessage id='manageUsers.userForm.userSince' defaultMessage='User since' />,
        description: (
          <EuiText color='subdued' size='s'>
            <FormattedDate value={user.metadata.created_at} {...shortDate} />
          </EuiText>
        ),
      })
    }

    if (user.metadata.last_login_at) {
      items.push({
        title: <FormattedMessage id='manageUsers.userForm.lastSeen' defaultMessage='Last seen' />,
        description: (
          <EuiText color='subdued' size='s'>
            <FormattedDate value={user.metadata.last_login_at} {...numericDateTime} />
          </EuiText>
        ),
      })
    }

    if (items.length === 0) {
      return null
    }

    // @ts-ignore
    const list = <EuiDescriptionList listItems={items} />

    return (
      <Fragment>
        <EuiHorizontalRule />
        {list}
      </Fragment>
    )
  }

  getUserDataPayload = () => {
    const { user_name, full_name, email, roles, enabled, password2: password } = this.state
    const userData: User = { user_name, full_name, email, security: { password, roles, enabled } }

    return userData
  }

  isUpdatingUser = () => {
    const { user } = this.props
    return !!user
  }

  onSave = () => {
    const { onSave } = this.props
    const errors = makeClientErrors(this.state)

    if (errors) {
      this.setState({ errors })
    } else {
      const { updatingPassword } = this.state

      const userData: User = this.getUserDataPayload()

      if (!updatingPassword) {
        delete userData.security.password
      }

      return onSave(userData).then(this.closeFlyout)
    }
  }

  onRoleChange = (selectedRoles: RoleMap[]) => {
    this.setState({ roles: selectedRoles.map(({ value }) => value) })
  }

  closeFlyout = () => {
    this.props.onClose()
  }

  updatePasswordEditingState = () => {
    if (this.isUpdatingUser()) {
      this.setState({
        updatingPassword: !!(this.state.password1 || this.state.password2),
      })
    }
  }

  onPasswordChange = (value: string, isPasswordConfirmation: boolean) => {
    if (isPasswordConfirmation) {
      this.setState({ password2: value }, this.updatePasswordEditingState)
    } else {
      this.setState({ password1: value }, this.updatePasswordEditingState)
    }
  }
}

export default injectIntl(ManageUser)
