/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
  EuiTextArea,
  EuiModalFooter,
  EuiText,
} from '@elastic/eui'

import messages from '../messages'
import SubscribeButton from '../../SubscribeButton'

import TrialExtensionError from './TrialExtensionError'

import type { AllProps } from './types'
import type { WrappedComponentProps } from 'react-intl'

enum Answer {
  IMMEDIATELY = `immediately`,
  WITHIN_A_MONTH = `within_a_month`,
  LONGER_THAN_MONTH = `longer_than_month`,
  EXPLORING = `exploring`,
}

type State = {
  selectedAnswer: Answer
  textAreaValue: string
  showTextError: boolean
  showSelectError: boolean
}

const defaultState = {
  selectedAnswer: Answer.IMMEDIATELY,
  textAreaValue: ``,
  showTextError: false,
  showSelectError: false,
}

class ExtendTrialModal extends Component<AllProps & WrappedComponentProps, State> {
  state: State = defaultState

  render() {
    const {
      intl: { formatMessage },
      extendTrial,
      extendTrialRequest,
      onTrialExtensionSuccess,
    } = this.props
    const { showSelectError, showTextError, textAreaValue, selectedAnswer } = this.state
    const options = [
      {
        value: Answer.IMMEDIATELY,
        text: formatMessage(messages.immediately),
      },
      {
        value: Answer.WITHIN_A_MONTH,
        text: formatMessage(messages.withinMonth),
      },
      {
        value: Answer.LONGER_THAN_MONTH,
        text: formatMessage(messages.longerThanMonth),
      },
      {
        value: Answer.EXPLORING,
        text: formatMessage(messages.exploring),
      },
    ]

    return (
      <EuiModal onClose={this.closeExtendTrialModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <EuiText size='s'>
              <h3>
                <FormattedMessage {...messages.title} />
              </h3>
            </EuiText>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody css={css({ minHeight: 280, maxWidth: 420 })}>
          <EuiFlexGroup direction='column'>
            <EuiFlexItem>
              <EuiForm>
                <EuiFormRow
                  fullWidth={true}
                  isInvalid={showSelectError}
                  error={formatMessage(messages.selectError)}
                  label={formatMessage(messages.questionOne)}
                >
                  <EuiSelect
                    value={selectedAnswer}
                    onChange={(e) =>
                      this.setState({
                        selectedAnswer: e.target.value as Answer,
                        showSelectError: false,
                      })
                    }
                    options={options}
                  />
                </EuiFormRow>

                <EuiFormRow
                  fullWidth={true}
                  isInvalid={showTextError}
                  error={formatMessage(messages.textError)}
                  label={formatMessage(messages.questionTwo)}
                >
                  <EuiTextArea
                    onChange={(e) =>
                      this.setState({ textAreaValue: e.target.value, showTextError: false })
                    }
                    value={textAreaValue}
                    rows={2}
                  />
                </EuiFormRow>
                <EuiSpacer size='m' />
                <EuiText color='subdued' textAlign='left' size='xs'>
                  <FormattedMessage {...messages.extendTrialNotice} />
                </EuiText>
              </EuiForm>
            </EuiFlexItem>

            {extendTrialRequest.error && (
              <EuiFlexItem>
                <TrialExtensionError
                  extendTrial={extendTrial}
                  extendTrialRequest={extendTrialRequest}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup gutterSize='m' justifyContent='center'>
            <EuiFlexItem grow={2}>
              <EuiButton
                fill={true}
                onClick={this.onSubmit}
                isLoading={extendTrialRequest.inProgress}
                data-test-id='extend-trial-modal.submit-button'
              >
                <FormattedMessage {...messages.extendTrial} />
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <SubscribeButton
                onSendBillingDetailsSuccess={() => {
                  this.closeExtendTrialModal()
                  onTrialExtensionSuccess()
                }}
                fill={false}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  onSubmit = async () => {
    const { onTrialExtensionSuccess } = this.props

    const { selectedAnswer, textAreaValue } = this.state
    const { extendTrial } = this.props
    const emptySelect = !selectedAnswer
    const emptyTextArea = !textAreaValue.length

    if (emptySelect || emptyTextArea) {
      this.setState({ showTextError: emptyTextArea, showSelectError: emptySelect })
    } else {
      extendTrial(selectedAnswer, textAreaValue).then(() => {
        this.closeExtendTrialModal()
        onTrialExtensionSuccess()
      })
    }
  }

  closeExtendTrialModal = () => {
    const { onClose, resetExtendTrial } = this.props

    onClose()
    resetExtendTrial()
    this.setState({ ...defaultState })
  }
}

export default injectIntl(ExtendTrialModal)
