/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import { CuiAlert, CuiFileUploadButton } from '../../../../cui'
import SpinButton from '../../../SpinButton'
import { readJson } from '../../../../lib/fileHelpers'

import type { AsyncRequestState } from '../../../../types'
import type { LicenseObject } from '../../../../lib/api/v1/types'

interface Props {
  addLicenseRequest: AsyncRequestState
  setLicense: (regionId: string, licenseFromFile: LicenseObject) => void
  regionId: string
}

interface State {
  file: Blob | null
}

class AddLicense extends Component<Props, State> {
  state: State = {
    file: null,
  }

  render() {
    const { addLicenseRequest } = this.props

    return (
      <div>
        <CuiFileUploadButton disabled={addLicenseRequest.inProgress} onChange={this.uploadFile}>
          <SpinButton color='primary' spin={addLicenseRequest.inProgress}>
            <FormattedMessage id='upload-license.add-license' defaultMessage='Add license' />
          </SpinButton>
        </CuiFileUploadButton>

        {addLicenseRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error' data-test-id='add-license-error'>
              {addLicenseRequest.error}
            </CuiAlert>
          </Fragment>
        )}
      </div>
    )
  }

  uploadFile = (file: File) => readJson(file).then(this.save)

  save = (licenseFromFile) => {
    const { regionId, setLicense } = this.props

    return setLicense(regionId, licenseFromFile)
  }
}

export default AddLicense
