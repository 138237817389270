/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import DeploymentTemplateChange from '../ExplainChanges/DeploymentTemplateChange'

import type { Difference } from '../../../../lib/stackDeployments/planDiffs/types'
import type { DifferenceFormatter } from '../types'

export const deploymentTemplateChangeFormatter: DifferenceFormatter = {
  handles: `deployment-template-migrated`,
  formatter: ({ difference }) => {
    const { target, meta } = difference as Difference<{
      currentId: string
      nextId: string
    }>
    const { currentId, nextId } = meta!
    return {
      id: `deployment-template`,
      type: target,
      testParams: [currentId, nextId],
      message: <DeploymentTemplateChange oldId={currentId} currentId={nextId} />,
    }
  },
}
