/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getThemeColors } from '../../lib/theme'

import type { SliderInstanceType, EsNodeType } from '../../types'

const colors = getThemeColors()

export function getSliderColor(sliderInstanceType: SliderInstanceType): string {
  switch (sliderInstanceType) {
    case `elasticsearch`:
      return colors.euiColorPrimary
    case `kibana`:
      return colors.euiColorSecondary
    case `apm`:
      return colors.euiColorAccent
    case `appsearch`:
      return colors.euiColorWarning
    case `enterprise_search`:
      return colors.euiColorWarning
    default:
      return colors.euiColorMediumShade
  }
}

/* This function is only meant for when we explicitly need to discriminate
 * between slider node types, such as in the Deployment Architecture Viz */
export function getSliderInstanceColor(
  sliderInstanceType: SliderInstanceType | EsNodeType,
): string {
  switch (sliderInstanceType) {
    // Sliders
    case `hot`:
      return colors.euiColorVis9_behindText
    case `warm`:
      return colors.euiColorVis5_behindText
    case `cold`:
      return colors.euiColorVis1_behindText
    case `frozen`:
      return colors.euiColorLightShade
    case `elasticsearch`:
      return colors.euiColorVis0
    case `kibana`:
      return colors.euiColorVis2_behindText
    case `apm`:
    case `integrations_server`:
      return colors.euiColorVis3_behindText
    case `appsearch`:
      return colors.euiColorVis0_behindText
    case `enterprise_search`:
      return colors.euiColorVis0_behindText

    // ES Node types
    case `tiebreaker`:
      return colors.euiColorVis6_behindText
    case `master`:
      return colors.euiColorDarkShade
    case `ml`:
      return colors.euiColorVis8_behindText
    case `ingest`:
      return colors.euiColorVis4_behindText
    default:
      return colors.euiColorMediumShade
  }
}
