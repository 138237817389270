/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, isEmpty } from 'lodash'

import { undefineEmptyStrings } from '../undefineEmptyStrings'

import type { LdapProviderFormShape } from './LdapProviderForm'
import type { LdapSettings } from '../../../../../../lib/api/v1/types'

export function getInitialFormValues(): LdapProviderFormShape {
  return {
    form_mode: 'create',
    id: '',
    name: '',
    urls: [''],
    load_balancing: 'failover',
    trusted_ca_cert_url: '',
    certificate_url_truststore_type: 'jks',
    certificate_url_truststore_password: '',
    bind_anonymously: false,
    search_mode: 'user',
    bind_dn: '',
    bind_password: '',
    user_search: {
      base_dn: '',
      scope: 'sub_tree',
      filter: '',
      group_attr: '',
    },
    user_dn_templates: [''],
    group_search: {
      base_dn: '',
      scope: 'sub_tree',
      filter: '',
      user_attribute: '',
    },
    advanced_settings_yaml: '',
    test_credentials: {
      username: '',
      password: '',
    },
    role_mappings: {
      default_roles: [],
      rules: [],
    },
  }
}

export function formToSettings(form: LdapProviderFormShape): LdapSettings {
  const request: LdapSettings = {
    id: form.id,
    order: form.order,
    name: form.name,
    urls: form.urls,
    bind_anonymously: form.bind_anonymously,
    bind_type: 'user_search',
    load_balance: {
      type: form.load_balancing,
    },
    group_search: { ...form.group_search },
    role_mappings: {
      default_roles: form.role_mappings.default_roles,
      rules: form.role_mappings.rules.map((rule) => ({
        type: rule.type,
        value: rule.value,
        roles: rule.roles,
      })),
    },
    override_yaml: form.advanced_settings_yaml,
  }

  if (!isEmpty(form.trusted_ca_cert_url)) {
    request.certificate_url = form.trusted_ca_cert_url
    request.certificate_url_truststore_type = form.certificate_url_truststore_type
    request.certificate_url_truststore_password = form.certificate_url_truststore_password
  }

  if (!form.bind_anonymously) {
    request.bind_dn = form.bind_dn
    request.bind_password = form.bind_password
  }

  if (form.search_mode === 'user') {
    request.user_search = {
      base_dn: form.user_search.base_dn,
      filter: form.user_search.filter,
      scope: form.user_search.scope,
    }
    request.user_group_attribute = form.user_search.group_attr
  } else {
    request.bind_type = 'user_templates'
    request.user_dn_templates = form.user_dn_templates
  }

  undefineEmptyStrings(request)

  return request
}

export function settingsToForm(ldapSettings: LdapSettings): LdapProviderFormShape {
  const {
    id,
    order,
    name,
    urls,
    certificate_url = '',
    certificate_url_truststore_type = 'jks',
    certificate_url_truststore_password = '',
    bind_anonymously,
    bind_type,
    bind_dn = '',
    bind_password = '',
    load_balance,
    user_search,
    user_group_attribute = '',
    group_search,
    user_dn_templates,
    role_mappings = { default_roles: [], rules: [] },
    override_yaml,
  } = cloneDeep(ldapSettings)

  const form: LdapProviderFormShape = {
    form_mode: 'edit',
    id,
    order,
    name,
    urls,
    load_balancing: 'failover',
    trusted_ca_cert_url: certificate_url,
    certificate_url_truststore_type,
    certificate_url_truststore_password,
    bind_anonymously,
    search_mode: bind_type === 'user_search' ? 'user' : 'template',
    bind_dn,
    bind_password,
    user_search: {
      base_dn: '',
      scope: 'sub_tree',
      filter: '',
      group_attr: '',
    },
    user_dn_templates: [''],
    group_search: {
      base_dn: '',
      scope: 'sub_tree',
      filter: '',
      user_attribute: '',
    },
    advanced_settings_yaml: override_yaml || '',
    test_credentials: {
      username: '',
      password: '',
    },
    role_mappings: {
      default_roles: role_mappings.default_roles,
      rules: role_mappings.rules.map((rule, index) => ({ ...rule, index })),
    },
  }

  if (load_balance && load_balance.type) {
    form.load_balancing = load_balance.type
  }

  if (user_search) {
    form.user_search = {
      base_dn: user_search.base_dn || '',
      scope: user_search.scope || 'sub_tree',
      filter: user_search.filter || '',
      group_attr: user_group_attribute,
    }
  }

  if (group_search) {
    form.group_search = {
      base_dn: group_search.base_dn || '',
      scope: group_search.scope || 'sub_tree',
      filter: group_search.filter || '',
      user_attribute: group_search.user_attribute || '',
    }
  }

  if (user_dn_templates) {
    form.user_dn_templates = user_dn_templates
  }

  return form
}
