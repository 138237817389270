/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGrid,
  EuiFormLabel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiLink,
} from '@elastic/eui'

import { stringifyTag } from '@/lib/tags'
import { getDeploymentTags } from '@/lib/stackDeployments/selectors'
import { CuiPermissibleControl } from '@/cui'

import Permission from '../../../lib/api/v1/permissions'

import DeploymentTagsFlyout from './DeploymentTagsFlyout/'
import { messages } from './messages'
import DeploymentTag from './DeploymentTag'

import type { StackDeployment } from '@/types'
import type { MetadataItem } from '@/lib/api/v1/types'
import type { WrappedComponentProps } from 'react-intl'

import '../Overview/overview.scss'
import './deploymentTags.scss'

const MAX_TAGS_LENGTH = 3

type State = {
  isFlyoutOpen: boolean
  isExpanded: boolean
}

type Props = {
  deployment: StackDeployment
}

class DeploymentTags extends Component<Props & WrappedComponentProps, State> {
  state: State = {
    isFlyoutOpen: false,
    isExpanded: false,
  }

  render() {
    return (
      <Fragment>
        <EuiFormLabel data-test-id='deployment-tags.title'>
          <EuiFlexGroup
            alignItems='center'
            gutterSize='none'
            justifyContent='flexStart'
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <FormattedMessage id='deployment-tags.title' defaultMessage='Tags' />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormLabel>

        <EuiSpacer size='m' />

        {this.renderTagsContent()}

        {this.renderEditFlyout()}
      </Fragment>
    )
  }

  renderTagsContent() {
    const { deployment } = this.props
    const tags = getDeploymentTags({ deployment })

    return (
      <EuiFlexGrid
        gutterSize='m'
        className='deployment-tags'
        responsive={false}
        data-test-id='deployment-tags.tags-list'
      >
        {tags.length > 0 ? (
          this.renderTagsList(tags)
        ) : (
          <CuiPermissibleControl
            permissions={Permission.updateDeployment}
            className='deployment-tags__add--unauthorized'
          >
            <EuiFlexItem grow={10}>
              <EuiLink
                data-test-id='deployment-tags.add-tags'
                onClick={() => this.setState({ isFlyoutOpen: true })}
              >
                <FormattedMessage id='deployment-tags.add-tags-link' defaultMessage='Add tags' />
              </EuiLink>
            </EuiFlexItem>
          </CuiPermissibleControl>
        )}
      </EuiFlexGrid>
    )
  }

  renderTagsList(tags: MetadataItem[]) {
    const {
      intl: { formatMessage },
    } = this.props
    const { isExpanded } = this.state

    const remainingTags = tags.length > MAX_TAGS_LENGTH ? tags.length - MAX_TAGS_LENGTH : 0

    return (
      <Fragment>
        {tags.map((tag, index) => (
          <EuiFlexItem
            className={isExpanded || index < MAX_TAGS_LENGTH ? 'show' : 'hide'}
            key={index}
            grow={false}
          >
            <DeploymentTag tag={tag}>
              <EuiCopy textToCopy={stringifyTag(tag)}>
                {(copy) => (
                  <EuiButtonIcon
                    iconType='copyClipboard'
                    color='text'
                    aria-label={formatMessage(messages.copyClipboardTag)}
                    onClick={copy}
                  />
                )}
              </EuiCopy>
            </DeploymentTag>
          </EuiFlexItem>
        ))}
        {tags.length > MAX_TAGS_LENGTH && (
          <EuiFlexItem style={{ alignSelf: `center` }}>
            <EuiLink onClick={this.switchIsExpanded}>
              {isExpanded ? (
                <FormattedMessage id='deployment-tags.less-link' defaultMessage='show less' />
              ) : (
                <FormattedMessage
                  id='deployment-tags.more-link'
                  defaultMessage='{remainingTags} more'
                  values={{ remainingTags }}
                />
              )}
            </EuiLink>
          </EuiFlexItem>
        )}
        <CuiPermissibleControl
          permissions={Permission.updateDeployment}
          className='deployment-tags__edit--unauthorized'
        >
          <EuiFlexItem style={{ alignSelf: `center` }}>
            <EuiLink
              data-test-id='deployment-tags.add-tags'
              onClick={() => this.setState({ isFlyoutOpen: true })}
            >
              <FormattedMessage id='deployment-tags.edit-tags-link' defaultMessage='Edit' />
            </EuiLink>
          </EuiFlexItem>
        </CuiPermissibleControl>
      </Fragment>
    )
  }

  renderEditFlyout() {
    const {
      deployment,
      deployment: { id: deploymentId },
    } = this.props

    const tags = getDeploymentTags({ deployment })

    if (!this.state.isFlyoutOpen) {
      return null
    }

    return <DeploymentTagsFlyout deploymentId={deploymentId} tags={tags} onClose={this.onClose} />
  }

  onClose = () => {
    this.setState({ isFlyoutOpen: false })
  }

  switchIsExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }))
  }
}

export default injectIntl(DeploymentTags)
