/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchInstanceConfigurations } from '@/actions/topology/instanceConfigurations'
import { fetchInstanceTypes } from '@/actions/topology/fetchInstanceTypes'
import {
  getInstanceTypes,
  createDeploymentTemplateRequest,
  getInstanceConfigurations,
  fetchInstanceConfigurationsRequest,
} from '@/reducers'

import { createDeploymentTemplate } from '../../../../actions/topology/deploymentTemplates'

import DeploymentTemplateCreate from './DeploymentTemplateCreate'

import type { DeploymentTemplateRequestBody } from '@/lib/api/v1/types'
import type { ReduxState, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  regionId,
  instanceTypes: getInstanceTypes(state, regionId),
  instanceConfigurations: getInstanceConfigurations(state, regionId),
  fetchInstanceConfigurationsRequest: fetchInstanceConfigurationsRequest(state, regionId),
  createDeploymentTemplateRequest: createDeploymentTemplateRequest(state, regionId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  fetchInstanceTypes: () => dispatch(fetchInstanceTypes(regionId)),
  fetchInstanceConfigurations: () =>
    dispatch(fetchInstanceConfigurations(regionId, { showMaxZones: true })),
  createDeploymentTemplate: (deploymentTemplate: DeploymentTemplateRequestBody) =>
    dispatch(createDeploymentTemplate(regionId, deploymentTemplate)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentTemplateCreate)
