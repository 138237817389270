/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchAllocators } from '../../actions/allocatorSearch'
import { searchAllocatorsRequest, getAllocatorSearchResults } from '../../reducers'

import Allocators from './Allocators'

import type { AsyncRequestState, AllocatorSearchResult, RegionId } from '../../types'
import type { SearchRequest } from '../../lib/api/v1/types'

type StateProps = {
  searchResults: AllocatorSearchResult[]
  searchAllocatorsRequest: AsyncRequestState
}

type DispatchProps = {
  searchAllocators: ({
    queryId,
    regionId,
    payload,
  }: {
    queryId: string
    regionId?: RegionId
    payload?: SearchRequest
  }) => void
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state, { regionId }: ConsumerProps): StateProps => ({
  searchResults: getAllocatorSearchResults(state, regionId, `allocators`),
  searchAllocatorsRequest: searchAllocatorsRequest(state, regionId, `allocators`),
})

const mapDispatchToProps: DispatchProps = {
  searchAllocators,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Allocators)
