/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, createRef } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiDatePickerProps } from '@elastic/eui'
import { EuiDatePickerRange, EuiDatePicker, EuiFormRow } from '@elastic/eui'

import type { KeyboardEvent } from 'react'
import type { Moment } from 'moment'
import type { Props } from './types'

interface State {
  startDate: Moment
  endDate: Moment
}

class DateRangePicker extends Component<Props, State> {
  endDatePickerRef = createRef<Component<EuiDatePickerProps>>()

  state: State = {
    startDate: this.props.selectedStartDate,
    endDate: this.props.selectedEndDate,
  }

  render() {
    const { isDisabled, selectedStartDate, selectedEndDate, maxDate, minDate } = this.props
    const isInvalid = selectedStartDate > selectedEndDate
    const { startDate, endDate } = this.state

    return (
      <EuiFormRow
        fullWidth={true}
        label={
          <FormattedMessage
            id='cost-analysis.filter.date-time-picker.label'
            defaultMessage='Time range'
          />
        }
      >
        <EuiDatePickerRange
          fullWidth={true}
          startDateControl={
            <EuiDatePicker
              disabled={isDisabled}
              popoverPlacement='downRight'
              selected={startDate}
              isInvalid={isInvalid}
              showTimeSelect={false}
              maxDate={endDate || maxDate}
              minDate={minDate}
              adjustDateOnChange={false}
              onSelect={this.onSelectStartDate}
            />
          }
          endDateControl={
            <EuiDatePicker
              disabled={isDisabled}
              popoverPlacement='downRight'
              selected={endDate}
              onSelect={this.onSelectEndDate}
              isInvalid={isInvalid}
              showTimeSelect={false}
              maxDate={maxDate}
              minDate={startDate || minDate}
              adjustDateOnChange={false}
              inputRef={this.endDatePickerRef}
              onKeyDown={this.onKeyDownEndDate}
              onClickOutside={this.onClickOutsideEndDate}
              onBlur={this.onClickOutsideEndDate}
            />
          }
        />
      </EuiFormRow>
    )
  }

  onSelectStartDate = (startDate: Moment): void => {
    this.setState({ startDate })

    // @ts-ignore Property 'setFocus' does not exist on type 'Component<ReactDatePickerProps, {}, any>'. The reason for this error is that `EuiDatePicker` is an overlay on `ReactDatePicker` but returns a generic Component instead of a specific instance so not all methods are visible in TS definition
    this.endDatePickerRef.current?.setFocus?.()
  }

  onSelectEndDate = (endDate: Moment): void => {
    const { onChange } = this.props
    const { startDate } = this.state

    this.setState({ endDate })

    onChange({
      startDate,
      endDate,
    })
  }

  // We should replace KeyDown and ClickOutside event handlers by OnClose handler when EUI start support it
  onKeyDownEndDate = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    const { onChange } = this.props
    const { startDate, endDate } = this.state
    const allowedKeys = ['Escape', 'Enter']

    if (allowedKeys.includes(key)) {
      onChange({
        startDate,
        endDate,
      })
    }
  }

  onClickOutsideEndDate = () => {
    const { onChange } = this.props
    const { startDate, endDate } = this.state

    onChange({
      startDate,
      endDate,
    })
  }
}

export default DateRangePicker
