/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { resetUploadTlsCertificateRequest, uploadTlsCertificate } from '../../../../actions/tls'
import {
  fetchTlsCertificateRequest,
  getTlsCertificate,
  uploadTlsCertificateRequest,
} from '../../../../reducers'

import Certificate from './Certificate'

import type { AsyncRequestState, ReduxState } from '../../../../types'
import type { TlsPublicCertChain, UpdatedTlsChain } from '../../../../lib/api/v1/types'

type StateProps = {
  regionId: string
  target: UpdatedTlsChain
  certificate?: TlsPublicCertChain
  fetchTlsCertificateRequest: AsyncRequestState
  uploadTlsCertificateRequest: AsyncRequestState
}

type DispatchProps = {
  resetUploadTlsCertificateRequest: (regionId: string, target: UpdatedTlsChain) => void
  uploadTlsCertificate: (regionId: string, target: UpdatedTlsChain, certificate: string) => void
}

type ConsumerProps = {
  regionId: string
  target: UpdatedTlsChain
}

const mapStateToProps = (state: ReduxState, { regionId, target }: ConsumerProps): StateProps => ({
  regionId,
  target,
  fetchTlsCertificateRequest: fetchTlsCertificateRequest(state, regionId, target.service),
  uploadTlsCertificateRequest: uploadTlsCertificateRequest(state, regionId, target.service),
  certificate: getTlsCertificate(state, regionId, target.service),
})

const mapDispatchToProps: DispatchProps = {
  uploadTlsCertificate: (regionId: string, target: UpdatedTlsChain, certificate: string) =>
    uploadTlsCertificate(regionId, target.service, certificate),
  resetUploadTlsCertificateRequest: (regionId: string, target: UpdatedTlsChain) =>
    resetUploadTlsCertificateRequest(regionId, target.service),
}

export default connect(mapStateToProps, mapDispatchToProps)(Certificate)
