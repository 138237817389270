/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFieldNumber } from '@elastic/eui'

import type { FunctionComponent } from 'react'

type Props = {
  timeout: number | '__indeterminate__' | undefined
  setTimeout: (value: number) => void
}

const VacateTimeout: FunctionComponent<Props> = ({ timeout, setTimeout }) => (
  <EuiFieldNumber
    value={timeout}
    placeholder='Timeout'
    onChange={(e) => setTimeout(parseInt(e.target.value, 10))}
  />
)

export default VacateTimeout
