/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  deprecatedInstanceTypesSummary: {
    id: `instance-configurations.deprecated.instance-types.summary`,
    defaultMessage: `Deprecated instance type combination`,
  },
  deprecatedInstanceTypesDetails: {
    id: `instance-configurations.deprecated.instance-types.details`,
    defaultMessage: `This configuration contains a combination of instance types which are no longer supported. The configuration will still work, however it is recommended that you replace it with a newer version as soon as possible.`,
  },
  deprecatedReplacedSummary: {
    id: `instance-configurations.deprecated.replaced.summary`,
    defaultMessage: `Replace deprecated instance configuration`,
  },
  deprecatedReplacedDetails: {
    id: `instance-configurations.deprecated.replaced.details`,
    defaultMessage: `The instance configuration {oldConfigId} has been deprecated and should be replaced with the newer configuration, {newConfigId}. Deployments created with deprecated instance configurations still work, but cannot be upgraded to newer versions.`,
  },
})
