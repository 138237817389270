/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import { setInstanceConfigurationUrl } from '../../lib/api/v1/urls'
import { UPDATE_INSTANCE_CONFIGURATION } from '../../constants/actions'
import history from '../../lib/history'
import { topologyViewInstanceConfigurationUrl } from '../../lib/urlBuilder'

import type { InstanceConfiguration } from '../../lib/api/v1/types'
import type { RegionId, ThunkAction } from '../../types'

export function updateInstanceConfiguration(
  regionId: RegionId,
  instanceConfiguration: InstanceConfiguration,
): ThunkAction {
  const instanceId = instanceConfiguration.id || ``
  const url = setInstanceConfigurationUrl({ regionId, id: instanceId })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: UPDATE_INSTANCE_CONFIGURATION,
        method: `PUT`,
        url,
        payload: instanceConfiguration,
        meta: { regionId, instanceId },
        crumbs: [regionId, instanceId],
      }),
    ).then(() => history.push(topologyViewInstanceConfigurationUrl(regionId, instanceId)))
}
