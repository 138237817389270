/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import cx from 'classnames'

import type { EuiAccordionProps } from '@elastic/eui'
import { EuiAccordion } from '@elastic/eui'

import './accordion.scss'

type State = {
  isSmallScreen: boolean
}

export class CuiAccordion extends Component<EuiAccordionProps, State> {
  state = {
    isSmallScreen: false,
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener(`resize`, this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.handleResize)
  }

  render() {
    const { className, buttonClassName, children, ...props } = this.props

    return (
      <EuiAccordion
        className={cx('cuiAccordion', className)}
        {...props}
        buttonClassName={cx(
          'cuiAccordionButton',
          !this.state.isSmallScreen ? 'eui-textNoWrap' : undefined,
          buttonClassName,
        )}
      >
        {children}
      </EuiAccordion>
    )
  }

  handleResize = () => {
    const isSmallScreen = window.innerWidth <= 360

    if (isSmallScreen !== this.state.isSmallScreen) {
      this.setState({ isSmallScreen })
    }
  }
}
