/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { PropsOf } from '@elastic/eui'
import { EuiFormHelpText, EuiProgress } from '@elastic/eui'

import prettySize from '../../lib/prettySize'

import type { FunctionComponent } from 'react'

import './diskUsage.scss'

type EuiProgressProps = PropsOf<typeof EuiProgress>

type Props = {
  className?: string
  critical?: boolean
  color?: EuiProgressProps['color']
  available: number
  total: number
  withProgress?: boolean
  isLoading?: boolean
}

const DiskUsage: FunctionComponent<Props> = ({
  critical,
  color = critical ? `danger` : `success`,
  className,
  available,
  total,
  withProgress = true,
  isLoading = false,
}) => {
  const used = total - available

  return (
    <div className={className}>
      {withProgress && <EuiProgress color={color} value={used} max={total} />}

      <EuiFormHelpText className='diskUsage-label'>
        {isLoading ? (
          <FormattedMessage id='disk-usage.loading-label' defaultMessage='Loading …' />
        ) : (
          <FormattedMessage
            id='disk-usage.usage-label'
            defaultMessage='{ available } available of { total }'
            values={{
              available: prettySize(available),
              total: prettySize(total),
            }}
          />
        )}
      </EuiFormHelpText>
    </div>
  )
}

export default DiskUsage
