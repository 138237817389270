/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { CuiLink } from '../../../cui'
import { regionUrl } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { PlatformInfo } from '../../../lib/api/v1/types'

type Props = {
  platform?: PlatformInfo | null
}

const UnreachableRegionsCallOut: FunctionComponent<Props> = ({ platform }) => {
  if (!platform) {
    return null
  }

  const { unreachable_regions } = platform

  if (isEmpty(unreachable_regions)) {
    return null
  }

  const unreachableCount = unreachable_regions.length

  return (
    <Fragment>
      <EuiCallOut
        color='warning'
        title={
          <FormattedMessage
            id='platform-regions-table.unreachable-regions-title'
            defaultMessage='Unreachable {unreachableCount, plural, one {region} other {regions}}'
            values={{ unreachableCount }}
          />
        }
      >
        <FormattedMessage
          id='platform-regions-table.unreachable-regions-content'
          defaultMessage="{unreachableCount, plural, one {Region} other {Regions}} {unreachableRegions} can't be reached. Please try again later."
          values={{
            unreachableCount,
            unreachableRegions: unreachable_regions.map((region, index) => {
              const regionLink = (
                <CuiLink key={region.region_id} to={regionUrl(region.region_id)}>
                  {region.region_id}
                </CuiLink>
              )

              if (index === 0) {
                return regionLink
              }

              if (unreachable_regions.length - 1 === index) {
                return (
                  <FormattedMessage
                    key={region.region_id}
                    id='platform-regions-table.unreachable-region-and-last'
                    defaultMessage=', and {regionLink}'
                    values={{ regionLink }}
                  />
                )
              }

              return (
                <FormattedMessage
                  key={region.region_id}
                  id='platform-regions-table.unreachable-region-item'
                  defaultMessage=', {regionLink}'
                  values={{ regionLink }}
                />
              )
            }),
          }}
        />
      </EuiCallOut>

      <EuiSpacer size='m' />
    </Fragment>
  )
}

export default UnreachableRegionsCallOut
