/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { noop } from 'lodash'
import React from 'react'

import { EuiRadio } from '@elastic/eui'

import type { ReactNode, FunctionComponent } from 'react'

type Props = {
  id: string
  'aria-label'?: string
  'data-test-id'?: string
  checked: boolean
  onClick: () => void
  className?: string
  isDisabled?: boolean
  children?: ReactNode
}

const RadioPretendingItIsButton: FunctionComponent<Props> = ({
  id,
  checked,
  onClick,
  className,
  isDisabled,
  children,
  ['data-test-id']: dataTestSubj,
  ['aria-label']: ariaLabel,
}) => (
  <EuiRadio
    data-test-id={dataTestSubj}
    id={id}
    aria-label={ariaLabel}
    className={className}
    label={children}
    checked={checked}
    onChange={noop}
    onClick={onClick}
    disabled={isDisabled}
  />
)

export default RadioPretendingItIsButton
