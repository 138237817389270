/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { searchDeployments } from '@/actions/stackDeployments'
import { getDeploymentByIdQuery } from '@/lib/deploymentQuery'

import { fetchSaasUser } from '../../actions/saasUsers'
import {
  callStoredProcedure,
  resetCallStoredProcedureRequest,
} from '../../actions/storedProcedures'
import {
  getSaasUsers,
  getStoredProcedure,
  fetchSaasUserRequest,
  callStoredProcedureRequest,
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '../../reducers'
import { isFeatureActivated, getConfigForKey } from '../../selectors'
import Feature from '../../lib/feature'

import Users from './Users'

import type { ProcedureState } from '../../reducers/storedProcedures/storedProcedureTypes'
import type { AsyncRequestState, StackDeployment } from '../../types'

type SpArgs = {
  procedureName: string
  parameters: any[]
  userId?: string
}

type StateProps = {
  lookupSaasUsers: boolean
  fetchSaasUserRequest: (userId: string) => AsyncRequestState
  deploymentsRequest: AsyncRequestState
  callStoredProcedureRequest: AsyncRequestState
  saasUsers: any
  deployments: StackDeployment[]
  procedureState: ProcedureState
  isPrivate: boolean
}

type DispatchProps = {
  fetchSaasUser: (userId: string) => Promise<any>
  callStoredProcedure: (args: SpArgs) => Promise<any>
  resetCallStoredProcedureRequest: (name: string) => void
  searchDeployment: (deploymentId: string) => Promise<any>
}

interface ConsumerProps {}

const queryId = `user-lookup-by-cluster`

const mapStateToProps = (state): StateProps => ({
  lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
  isPrivate: getConfigForKey(state, `APP_FAMILY`) === `essp`,
  fetchSaasUserRequest: (userId: string) => fetchSaasUserRequest(state, userId),
  saasUsers: getSaasUsers(state),
  deployments: getStackDeploymentsFromSearch(state, queryId)?.deployments || [],
  deploymentsRequest: searchStackDeploymentsRequest(state, queryId),
  procedureState: getStoredProcedure(state, `user_lookup`),
  callStoredProcedureRequest: callStoredProcedureRequest(state, `user_lookup`),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchSaasUser: (userId: string) => dispatch(fetchSaasUser(userId)),
  callStoredProcedure: (args: SpArgs) => dispatch(callStoredProcedure(args)),
  searchDeployment: (deploymentId: string) =>
    dispatch(searchDeployments({ queryId, query: getDeploymentByIdQuery({ deploymentId }) })),
  resetCallStoredProcedureRequest,
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withTransaction(`Users`, `component`)(Users))
