/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'
import { isEmpty } from 'lodash'

import { getSliderPrettyName } from '../../../../lib/sliders'

import ConfigurationDetailsStep, { validateDetailsStep } from './ConfigurationDetailsStep'

import type { StepDefinition, StepOwnProps } from './instanceConfigurationWizardTypes'

const messages = defineMessages({
  title: {
    id: `configure-details-step.step-title`,
    defaultMessage: `Name`,
  },
})

function onBeforeEnter(props: StepOwnProps) {
  const { intl, instanceConfiguration, updateInstanceConfiguration } = props

  if (isEmpty(validateDetailsStep(props))) {
    return
  }

  if (isEmpty(instanceConfiguration.node_types)) {
    return
  }

  const nodeNames = instanceConfiguration.node_types!.map((sliderNodeType) => {
    const messageDescriptor = getSliderPrettyName({
      sliderInstanceType: instanceConfiguration.instance_type,
      sliderNodeType,
    })
    return intl.formatMessage(messageDescriptor)
  })

  const name = nodeNames.join(`, `)

  updateInstanceConfiguration({ name })
}

export const detailsStepDefinition: StepDefinition = {
  title: messages.title,
  ConfigureStep: ConfigurationDetailsStep,
  validateStep: validateDetailsStep,
  onBeforeEnter,
}
