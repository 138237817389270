/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiButtonEmpty, EuiContextMenu, EuiIcon, EuiPopover } from '@elastic/eui'

import ZkLoggingSettings from '../ZkLoggingSettings'

import type { IntlShape } from 'react-intl'
import type { AsyncRequestState, LoggerLevel } from '../../types'
import type { LoggingSettings } from '../../lib/api/v1/types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type Props = {
  intl: IntlShape
  fetchLoggingSettings: () => void
  fetchLoggingSettingsRequest: AsyncRequestState
  loggingSettings: LoggingSettings | null
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
  patchLoggingSettingsRequest: AsyncRequestState
  asGear?: boolean
}

type EditMode = 'new' | 'view'

type State = {
  isFlyoutOpen: boolean
  isPopoverOpen: boolean
  mode: EditMode
}

const messages = defineMessages({
  createLogging: {
    id: 'zk-logging-settings.add-setting',
    defaultMessage: `Create logging setting`,
  },
  manageLogging: {
    id: 'zk-logging-settings.manage-setting',
    defaultMessage: `Manage logging settings`,
  },
  manageButton: {
    id: 'zk-logging-settings.manage-button',
    defaultMessage: `Manage`,
  },
  ariaLabel: {
    id: 'zk-logging-settings-button.description',
    defaultMessage: `View or edit logging settings`,
  },
})

class ZkLoggingSettingsButton extends Component<Props, State> {
  state: State = {
    isFlyoutOpen: false,
    isPopoverOpen: false,
    mode: 'view',
  }

  render() {
    const {
      intl: { formatMessage },
      asGear,
    } = this.props

    const { isPopoverOpen } = this.state

    const menuOptions = [
      {
        id: 0,
        items: [
          {
            name: formatMessage(messages.createLogging),
            icon: <EuiIcon type='plusInCircle' size='m' />,
            onClick: () => {
              this.toggleFlyout({ mode: 'new' })
            },
          },
          {
            name: formatMessage(messages.manageLogging),
            icon: <EuiIcon type='pencil' size='m' />,
            onClick: () => {
              this.toggleFlyout({ mode: 'view' })
            },
          },
        ],
      },
    ]

    return (
      <Fragment>
        {asGear ? (
          <EuiButtonEmpty
            size='s'
            iconType='gear'
            onClick={() => {
              this.toggleFlyout({ mode: 'view' })
            }}
          >
            {formatMessage(messages.manageButton)}
          </EuiButtonEmpty>
        ) : (
          <EuiPopover
            id='loggingSettingsActions'
            button={
              <EuiButtonIcon
                iconType='boxesVertical'
                aria-label={formatMessage(messages.ariaLabel)}
                onClick={this.openPopover}
              />
            }
            isOpen={isPopoverOpen}
            closePopover={this.closePopover}
            ownFocus={true}
            anchorPosition='downCenter'
            panelPaddingSize='none'
          >
            <EuiContextMenu initialPanelId={0} panels={menuOptions} />
          </EuiPopover>
        )}

        {this.renderFlyout()}
      </Fragment>
    )
  }

  renderFlyout() {
    const {
      fetchLoggingSettings,
      fetchLoggingSettingsRequest,
      loggingSettings,
      patchLoggingSettings,
      patchLoggingSettingsRequest,
    } = this.props

    const { isFlyoutOpen } = this.state

    if (!isFlyoutOpen) {
      return null
    }

    return (
      <ZkLoggingSettings
        tableHeader={false}
        fetchLoggingSettings={fetchLoggingSettings}
        fetchLoggingSettingsRequest={fetchLoggingSettingsRequest}
        loggingSettings={loggingSettings}
        patchLoggingSettings={patchLoggingSettings}
        patchLoggingSettingsRequest={patchLoggingSettingsRequest}
        editMode={this.state.mode}
        isFlyoutOpen={isFlyoutOpen}
        closeFlyout={this.closeFlyout}
        toggleToView={this.toggleToView}
      />
    )
  }

  toggleToView = () => {
    this.setState({
      mode: 'view',
    })
  }

  toggleFlyout = ({ mode }) => {
    const { isFlyoutOpen } = this.state

    this.setState({ isFlyoutOpen: !isFlyoutOpen, mode })
  }

  closeFlyout = () => {
    this.setState({ isFlyoutOpen: false, isPopoverOpen: false })
  }

  openPopover = () => {
    this.setState({ isPopoverOpen: true })
  }

  closePopover = () => {
    this.setState({ isPopoverOpen: false })
  }
}

export default injectIntl(ZkLoggingSettingsButton)
