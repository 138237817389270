/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiRadioGroup,
  EuiSpacer,
} from '@elastic/eui'

import SpinButton from '@/components/SpinButton'

import type { WrappedComponentProps } from 'react-intl'
import type { SearchTypeOption } from './types'
import type { ReactElement } from 'react'

export type Props = {
  inProgress: boolean
  onSearch: (searchType: SearchTypeOption, searchInput: string) => void
  onReset: () => void
}

type State = {
  searchType: SearchTypeOption
  searchInput: string
}

const initialState: State = {
  searchType: `id`,
  searchInput: ``,
}

const messages = {
  id: {
    id: 'organization.organizations.search-by-id-label',
    defaultMessage: 'ID',
  },
  name: {
    id: 'organization.organizations.search-by-name-label',
    defaultMessage: 'Name',
  },
  deploymentId: {
    id: 'organization.organizations.search-by-deployment-id-label',
    defaultMessage: 'Deployment ID',
  },
}

class OrganizationSearchForm extends Component<Props & WrappedComponentProps, State> {
  state: State = initialState

  render(): ReactElement {
    const {
      intl: { formatMessage },
      inProgress,
      onReset,
    } = this.props
    const { searchType, searchInput } = this.state

    const isDisabled = searchInput.trim() === `` || inProgress

    const searchTypeOptions = [
      {
        id: `id`,
        label: formatMessage(messages.id),
        'data-test-id': `organization.organizations.search-by-id-label`,
      },
      {
        id: `name`,
        label: formatMessage(messages.name),
        'data-test-id': `organization.organizations.search-by-name-label`,
      },
      {
        id: `deploymentId`,
        label: formatMessage(messages.deploymentId),
        'data-test-id': `organization.organizations.search-by-deployment-id-label`,
      },
    ]

    return (
      <form onSubmit={this.onSubmit}>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiFormLabel>
              <FormattedMessage
                id='organization.organizations.find-label'
                defaultMessage='Find an organization by:'
              />
            </EuiFormLabel>

            <EuiRadioGroup
              options={searchTypeOptions}
              idSelected={searchType}
              onChange={(selectedSearchType: SearchTypeOption) => {
                this.setState({ searchType: selectedSearchType }, () => onReset())
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <EuiFormLabel>{formatMessage(messages[searchType])}</EuiFormLabel>

        <EuiSpacer size='xs' />

        <EuiFlexGroup gutterSize='m' responsive={false} style={{ maxWidth: '400px' }}>
          <EuiFlexItem>
            <EuiFieldSearch
              fullWidth={true}
              value={searchInput}
              onChange={this.onChange}
              isLoading={inProgress}
              data-test-id='organization.organizations.search-input'
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <SpinButton
              fill={true}
              spin={inProgress}
              disabled={isDisabled}
              onClick={this.onClick}
              data-test-id='organization.organizations.search-button'
            >
              <FormattedMessage
                id='organization.organizations.search-button-text'
                defaultMessage='Search'
              />
            </SpinButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </form>
    )
  }

  onSubmit = (e): void => {
    e.preventDefault()
    this.onClick()
  }

  onChange = (e): void => {
    const searchInput = e.target.value

    if (searchInput === ``) {
      this.onReset()
    }

    this.setState({ searchInput })
  }

  onClick = (): void => {
    const { inProgress, onSearch } = this.props

    if (inProgress) {
      return
    }

    const { searchType, searchInput } = this.state

    onSearch(searchType, searchInput)
  }

  onReset = (): void => {
    this.props.onReset()
  }
}

export default injectIntl(OrganizationSearchForm)
