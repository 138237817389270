/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { WithEuiThemeProps } from '@elastic/eui'

export function themedBoxShadow({ theme }: { theme: WithEuiThemeProps['theme'] }): string {
  return theme.colorMode === 'DARK'
    ? boxShadow([0.2, 0.15, 0.125, 0.1])
    : boxShadow([0.08, 0.06, 0.05, 0.04])
}

function boxShadow(alphaChannels: [number, number, number, number]) {
  return [
    `0 0.9px 4px -1px rgba(0,0,0,${alphaChannels[0]})`,
    `0 2.6px 8px -1px rgba(0,0,0,${alphaChannels[1]})`,
    `0 5.7px 12px -1px rgba(0,0,0,${alphaChannels[2]})`,
    `0 15px 15px -1px rgba(0,0,0,${alphaChannels[3]})`,
  ].join()
}
