/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiConfirmModal,
  EuiLink,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { hasOngoingConfigurationChange } from '@/lib/stackDeployments/selectors'

import { CuiAlert, CuiLink } from '../../../cui'
import PureJsonEditor from '../../PureJsonEditor'
import StackDeploymentUpdateDryRunWarnings from '../../StackDeploymentUpdateDryRunWarnings'
import StackDeploymentUpdateDryRunWarningCheck from '../../StackDeploymentUpdateDryRunWarningCheck'
import {
  createUpdateRequestFromGetResponse,
  explainChangeDiff,
  sanitizeUpdateRequestBeforeSend,
} from '../../../lib/stackDeployments'
import { promoteConfigurationEntriesToPlan } from '../../../lib/stackDeployments/configuration'
import { deploymentActivityUrl, deploymentEditUrl } from '../../../lib/urlBuilder'
import DeploymentConfigurationChangeExplain from '../../StackDeploymentConfigurationChange/StackConfigurationChangeExplain/DeploymentConfigurationChangeExplain'

import type { WithStackDeploymentRouteParamsProps } from '../../StackDeploymentEditor'
import type { AsyncRequestState } from '../../../types'
import type { DeploymentGetResponse, DeploymentUpdateRequest } from '../../../lib/api/v1/types'

type StateProps = {
  deploymentId: string
  deployment: DeploymentGetResponse | null
  fetchStackDeploymentRequest: AsyncRequestState
  updateStackDeploymentRequest: AsyncRequestState
}

type DispatchProps = {
  fetchDeployment: (params: { deploymentId: string }) => void
  updateDeployment: (params: { deploymentId: string; deployment: DeploymentUpdateRequest }) => void
  resetUpdateDeployment: (deploymentId: string) => void
}

type ConsumerProps = WithStackDeploymentRouteParamsProps & {
  getInitialEditorState?: (regionId: string, deploymentId: string) => DeploymentUpdateRequest | null
}

export type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  editorKey: number
  editorState: DeploymentUpdateRequest | null
  initialEditorState: DeploymentUpdateRequest | null
  displayPlanValidityWarning: boolean
  isConfirmModalOpen: boolean
}

class EditStackDeploymentAdvancedPlanEditor extends Component<Props, State> {
  state: State = this.getInitialState()

  getInitialState(): State {
    const initialEditorState = this.getInitialEditorState()

    return {
      editorKey: 0,
      editorState: initialEditorState,
      initialEditorState: cloneDeep(initialEditorState),
      displayPlanValidityWarning: false,
      isConfirmModalOpen: false,
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    if (prevState.initialEditorState === null && nextProps.deployment) {
      const rawUpdateRequest = createUpdateRequestFromGetResponse({
        deployment: nextProps.deployment,
      })

      const sanitizedUpdateRequest = sanitizeUpdateRequestBeforeSend({
        deployment: rawUpdateRequest,
      })

      // relocate topology-level settings to plan-level as we arrive; we'll also
      // do it again on save in case it applies after editing
      promoteConfigurationEntriesToPlan({ deployment: sanitizedUpdateRequest })

      return {
        editorState: sanitizedUpdateRequest,
        initialEditorState: cloneDeep(sanitizedUpdateRequest),
      }
    }

    return null
  }

  componentDidMount() {
    const { resetUpdateDeployment, fetchDeployment, stackDeploymentId } = this.props
    fetchDeployment({ deploymentId: stackDeploymentId! })
    resetUpdateDeployment(stackDeploymentId!)
  }

  render() {
    const { stackDeploymentId, fetchStackDeploymentRequest } = this.props
    const { editorState } = this.state

    if (editorState === null) {
      if (fetchStackDeploymentRequest.error) {
        return <CuiAlert type='error'>{fetchStackDeploymentRequest.error}</CuiAlert>
      }

      if (fetchStackDeploymentRequest.inProgress) {
        return <EuiLoadingSpinner />
      }
    }

    return (
      <div>
        <CuiAlert type='warning'>
          <FormattedMessage
            id='edit-stack-deployment-advanced-plan-editor.only-make-changes-if-you-know-what-you-are-doing'
            defaultMessage='Only make changes if you know what you are doing. {simpleLink}.'
            values={{
              simpleLink: (
                <CuiLink to={deploymentEditUrl(stackDeploymentId!)}>
                  <FormattedMessage
                    id='edit-stack-deployment-advanced-plan-editor.go-to-simple-cluster-configuration'
                    defaultMessage='Discard changes and go back to the Edit page'
                  />
                </CuiLink>
              ),
            }}
          />
        </CuiAlert>

        <EuiSpacer size='m' />

        <EuiText>
          <h3>
            <FormattedMessage
              id='edit-stack-deployment-advanced-plan-editor.plan'
              defaultMessage='Deployment configuration'
            />
          </h3>
        </EuiText>

        <EuiSpacer size='s' />

        {this.renderEditor()}

        {this.renderConfirmSaveModal()}
      </div>
    )
  }

  renderEditor() {
    const { deployment, stackDeploymentId, updateStackDeploymentRequest } = this.props
    const { editorState, displayPlanValidityWarning, editorKey } = this.state

    const changingAnyPlan = hasOngoingConfigurationChange({ deployment: deployment! })
    const message = this.renderSaveMessage()

    return (
      <Fragment>
        <PureJsonEditor
          key={editorKey}
          data-test-id='edit-plan-json'
          initialValue={editorState}
          save={this.startSave}
          message={message}
          isSaving={updateStackDeploymentRequest.inProgress}
          readonly={changingAnyPlan}
          readonlyHelpText={
            <FormattedMessage
              id='edit-stack-deployment-advanced-plan-editor.ongoing-changes'
              defaultMessage='The deployment cannot be configured while another change is in progress. {seeActivity}.'
              values={{
                seeActivity: (
                  <CuiLink to={deploymentActivityUrl(stackDeploymentId!)}>
                    <FormattedMessage
                      id='edit-stack-deployment-advanced-plan-editor.see-activity'
                      defaultMessage='Go to Activity'
                    />
                  </CuiLink>
                ),
              }}
            />
          }
        />

        {displayPlanValidityWarning && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut
              iconType='bug'
              color='danger'
              title={
                <FormattedMessage
                  id='edit-stack-deployment-advanced-plan-editor.validity-warning-title'
                  defaultMessage="The configuration you tried to save doesn't look right"
                />
              }
            >
              <FormattedMessage
                id='edit-stack-deployment-advanced-plan-editor.validity-warning'
                defaultMessage="When copying configuration, make sure you're pasting it inside the right resource. Would you like to {startOver}?"
                values={{
                  startOver: (
                    <EuiLink onClick={this.resetEditorState}>
                      <FormattedMessage
                        id='edit-stack-deployment-advanced-plan-editor.validity-start-over'
                        defaultMessage='start over'
                      />
                    </EuiLink>
                  ),
                }}
              />
            </EuiCallOut>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderConfirmSaveModal() {
    const { deployment } = this.props
    const { isConfirmModalOpen } = this.state
    const deploymentId = deployment!.id

    if (!isConfirmModalOpen) {
      return null
    }

    return (
      <StackDeploymentUpdateDryRunWarningCheck deploymentId={deploymentId}>
        {({ dryRunCheckPassed }) => (
          <EuiOverlayMask>
            <EuiConfirmModal
              buttonColor='warning'
              defaultFocusedButton='confirm'
              title={
                <FormattedMessage
                  id='edit-stack-deployment-advanced-plan-editor.modal.title'
                  defaultMessage='Save configuration settings?'
                />
              }
              cancelButtonText={
                <FormattedMessage
                  id='edit-stack-deployment-advanced-plan-editor.modal.cancel'
                  defaultMessage='Cancel'
                />
              }
              confirmButtonText={
                <FormattedMessage
                  id='edit-stack-deployment-advanced-plan-editor.modal.save'
                  defaultMessage='Save'
                />
              }
              onCancel={this.closeConfirmModal}
              onConfirm={this.confirmSave}
              confirmButtonDisabled={!dryRunCheckPassed}
              className='fs-unmask'
            >
              {this.renderChangeSummary()}
            </EuiConfirmModal>
          </EuiOverlayMask>
        )}
      </StackDeploymentUpdateDryRunWarningCheck>
    )
  }

  renderChangeSummary() {
    const { deployment } = this.props
    const deploymentId = this.props.deployment!.id

    const editorState = this.getUpdatePayload()

    if (!deployment || !editorState) {
      return // sanity
    }

    return (
      <Fragment>
        <StackDeploymentUpdateDryRunWarnings
          deploymentId={deploymentId}
          deployment={editorState}
          spacerAfter={true}
        />

        <DeploymentConfigurationChangeExplain
          deployment={editorState}
          deploymentUnderEdit={deployment}
          pruneOrphans={editorState.prune_orphans}
        />
      </Fragment>
    )
  }

  renderSaveMessage() {
    const { stackDeploymentId, updateStackDeploymentRequest } = this.props

    if (updateStackDeploymentRequest.error) {
      return <CuiAlert type='error'>{updateStackDeploymentRequest.error}</CuiAlert>
    }

    if (updateStackDeploymentRequest.isDone) {
      return (
        <CuiAlert type='info'>
          <FormattedMessage
            id='edit-stack-deployment-advanced-plan-editor.cluster-plan-successfully-saved'
            defaultMessage='Applying configuration changes. {seeActivity}'
            values={{
              seeActivity: (
                <CuiLink to={deploymentActivityUrl(stackDeploymentId!)}>
                  <FormattedMessage
                    id='edit-stack-deployment-advanced-plan-editor.go-to-cluster-plans'
                    defaultMessage='Go to Activity'
                  />
                </CuiLink>
              ),
            }}
          />
        </CuiAlert>
      )
    }

    return null
  }

  getInitialEditorState(): DeploymentUpdateRequest | null {
    const { getInitialEditorState, regionId, stackDeploymentId } = this.props

    if (getInitialEditorState) {
      return getInitialEditorState(regionId, stackDeploymentId!)
    }

    return null
  }

  startSave = (plan: DeploymentUpdateRequest) => {
    const planSeemsValid = this.doesPlanSeemValid(plan)

    if (planSeemsValid) {
      this.setState({
        displayPlanValidityWarning: false,
        isConfirmModalOpen: true,
        editorState: plan,
      })
    } else {
      this.setState({ displayPlanValidityWarning: true })
    }
  }

  doesPlanSeemValid(plan: DeploymentUpdateRequest): boolean {
    const { deployment } = this.props

    try {
      /* This isn't the best way ever to enforce a schema, but this is also a very complex object
       * with lots of "holes", and we don't want to get overly strict. If `explainChangeDiff` doesn't
       * blow up, then that signals the user-provided object is probably good enough.
       * We might want to revisit and use a strict (Swagger?) schema-based parser.
       */
      explainChangeDiff({
        deployment,
        currentState: plan,
      })

      return true
    } catch (err) {
      return false
    }
  }

  closeConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false })
  }

  confirmSave = () => {
    const { updateDeployment, stackDeploymentId } = this.props
    const deployment = this.getUpdatePayload()

    updateDeployment({ deploymentId: stackDeploymentId!, deployment })
    this.closeConfirmModal()
  }

  resetEditorState = () => {
    const { editorKey, initialEditorState } = this.state
    const editorState = cloneDeep(initialEditorState)

    this.setState({
      editorKey: editorKey + 1,
      editorState,
      displayPlanValidityWarning: false,
    })
  }

  getUpdatePayload = () => {
    const deployment = cloneDeep(this.state.editorState!)

    // relocate topology-level settings to plan-level as we leave in case they
    // weren't moved at the start of editing but can be moved now
    promoteConfigurationEntriesToPlan({ deployment })

    return deployment
  }
}

export default EditStackDeploymentAdvancedPlanEditor
