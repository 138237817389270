/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { EuiLoadingSpinner } from '@elastic/eui'

import { CuiAlert } from '../../../cui'
import RepositoryEditor from '../RepositoryEditor'

import type { AsyncRequestState, RegionId, SnapshotRepository } from '../../../types'

type Props = {
  regionId: RegionId
  repositoryId: string
  repository: SnapshotRepository
  fetchSnapshotRepositoryRequest: AsyncRequestState
  fetchSnapshotRepository: (regionId: RegionId, repositoryId: string) => void
}

export default class EditSnapshotRepository extends Component<Props> {
  componentDidMount() {
    const { fetchSnapshotRepository, regionId, repositoryId, repository } = this.props

    if (!repository) {
      fetchSnapshotRepository(regionId, repositoryId)
    }
  }

  render() {
    const { regionId, repository, fetchSnapshotRepositoryRequest } = this.props

    if (fetchSnapshotRepositoryRequest.error) {
      return <CuiAlert type='error'>{fetchSnapshotRepositoryRequest.error}</CuiAlert>
    }

    if (repository == null) {
      return <EuiLoadingSpinner />
    }

    return (
      <RepositoryEditor regionId={regionId} repository={repository} editMode={true} errors={{}} />
    )
  }
}
