/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { Component } from 'react'
import { off, on } from 'local-storage'

import { SAD_authTokenExpirationLocalStorageKey, expiresInTheFuture } from '../../lib/auth'

import { fullPageReload } from './fullPageReload'

interface Props {}

type NullableNumber = number | null

const featureDetectedSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

/* There are a few scenarios that can lead to `CrossTabAuthReplication` event listeners:
 * [1] User logs in
 * [2] User logs out
 * [3] Token gets refreshed via `RefreshApiToken`
 *
 * In all of the above scenarios — except for [3] — we want to reload the page.
 * If the user had a valid token, and they now have a different valid token of the same kind,
 * nothing changes, and we avoid eagerly doing a full page reload, which is annoying to users
 *
 * [1] [2] are governed by changes to the expiration between the previous token and the next
 */
export default class CrossTabAuthReplication extends Component<Props> {
  componentDidMount(): void {
    // Safari fires storage events in the same tab where changes originate, causing issues
    // https://bugs.webkit.org/show_bug.cgi?id=210512
    if (featureDetectedSafari) {
      return
    }

    on<NullableNumber>(SAD_authTokenExpirationLocalStorageKey, this.onExpirationChange)
  }

  componentWillUnmount(): void {
    off<NullableNumber>(SAD_authTokenExpirationLocalStorageKey, this.onExpirationChange)
  }

  render(): null {
    return null
  }

  onExpirationChange = (nextExpiration: number | null, prevExpiration: number | null): void => {
    const expirationStateChanged = this.hasExpirationStateChange(nextExpiration, prevExpiration)

    if (expirationStateChanged) {
      fullPageReload() // [1] [2]
    }
  }

  hasExpirationStateChange(nextExpiration: number | null, prevExpiration: number | null): boolean {
    if (nextExpiration === prevExpiration) {
      return false
    }

    const nextDate = nextExpiration ? new Date(nextExpiration) : null
    const prevDate = prevExpiration ? new Date(prevExpiration) : null

    // Token was expired and now is not, or token was unexpired and now is not
    const stateChanged = expiresInTheFuture(nextDate) !== expiresInTheFuture(prevDate)

    return stateChanged
  }
}
