/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import { EuiDescribedFormGroup } from '@elastic/eui'

import DocLink from '../../../../../../../components/DocLink'
import { CuiCodeEditor, CuiTitleWithBadge } from '../../../../../../../cui'
import { messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

interface Props {
  setFieldValue: (key: string, value: any) => void
}

const YamlConfiguration: FunctionComponent<Props> = ({ setFieldValue }) => (
  <EuiDescribedFormGroup
    title={
      <CuiTitleWithBadge>
        <FormattedMessage {...sections.advancedConfiguration} />
      </CuiTitleWithBadge>
    }
    description={
      <FormattedMessage
        {...sections.advancedConfigurationHelpText}
        values={{
          learnMore: (
            <DocLink link='activeDirectoryProviderAdvancedDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <Field name='advanced_settings_yaml'>
      {({ field: { value } }) => (
        <CuiCodeEditor
          language='yaml'
          height='160px' /* 10 lines worth @ 16px */
          value={value}
          // We have to use setFieldValue because CuiCodeEditor
          // doesn't call onChange with an event
          onChange={(newValue) => setFieldValue('advanced_settings_yaml', newValue)}
        />
      )}
    </Field>
  </EuiDescribedFormGroup>
)

export default YamlConfiguration
