/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchInstanceConfiguration } from '../../../../actions/topology/instanceConfigurations'
import { updateInstanceConfiguration } from '../../../../actions/topology/updateInstanceConfiguration'
import {
  fetchInstanceConfigurationRequest,
  getInstanceConfiguration,
  updateInstanceConfigurationRequest,
} from '../../../../reducers'

import InstanceConfigurationEdit from './InstanceConfigurationEdit'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, instanceId },
    },
  },
) => {
  const instanceConfiguration = getInstanceConfiguration(state, regionId, instanceId)
  return {
    regionId,
    instanceId,
    instanceConfiguration,
    instanceConfigurationRequest: fetchInstanceConfigurationRequest(state, regionId, instanceId),
    updateInstanceConfigurationRequest: updateInstanceConfigurationRequest(
      state,
      regionId,
      instanceId,
    ),
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId, instanceId },
    },
  },
) => ({
  fetchInstanceConfiguration: () => dispatch(fetchInstanceConfiguration(regionId, instanceId)),
  updateInstanceConfiguration: (template) =>
    dispatch(updateInstanceConfiguration(regionId, template)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InstanceConfigurationEdit)
