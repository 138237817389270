/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../../../actions/asyncRequests'
import {
  getSecurityRealmConfigurationsUrl,
  reorderSecurityRealmsUrl,
} from '../../../lib/api/v1/urls'
import { FETCH_SECURITY_REALMS, REORDER_SECURITY_REALMS } from '../constants/actions'

import { fetchSecurityCluster } from './securityCluster'

import type { SecurityRealmsReorderRequest } from '../../../lib/api/v1/types'
import type { RegionId } from '../../../types'

export function fetchSecurityRealms(regionId: RegionId) {
  const url = getSecurityRealmConfigurationsUrl({ regionId })

  return asyncRequest({
    type: FETCH_SECURITY_REALMS,
    url,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export function reorderSecurityRealms(regionId: RegionId, payload: SecurityRealmsReorderRequest) {
  const url = reorderSecurityRealmsUrl({ regionId })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: REORDER_SECURITY_REALMS,
        url,
        method: 'POST',
        payload,
        meta: { regionId },
        crumbs: [regionId],
      }),
    ).then(dispatch(fetchSecurityCluster(regionId)))
}
