/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormLabel, EuiSpacer, EuiCode } from '@elastic/eui'

import { CuiCodeBlock, withErrorBoundary } from '../../cui'

import type { FunctionComponent } from 'react'
import type { ContainerInstance } from '../../lib/api/v1/types'

type Props = {
  instance: ContainerInstance
}

type ContainerInstanceInspect = {
  [key: string]: any
}

const ContainerInstanceDetailRow: FunctionComponent<Props> = ({ instance }) =>
  withInspect((inspect) => (
    <div>
      <EuiFormLabel>
        <FormattedMessage id='container-overview.image' defaultMessage='Image' />
      </EuiFormLabel>

      <EuiSpacer size='xs' />

      <EuiCode>{inspect.Config?.Image}</EuiCode>

      <EuiSpacer size='m' />

      <EuiFormLabel>
        <FormattedMessage id='container-overview.env' defaultMessage='Environment configuration' />
      </EuiFormLabel>

      <EuiSpacer size='xs' />

      <CuiCodeBlock language='bash' paddingSize='s'>
        {inspect.Config?.Env?.join(`\n`)}
      </CuiCodeBlock>

      {Object.keys(inspect.Config?.Labels || {}).map((labelKey) => (
        <Fragment key={labelKey}>
          <EuiSpacer size='m' />

          <EuiFormLabel>{labelKey}</EuiFormLabel>

          <EuiSpacer size='xs' />

          <EuiCode>{inspect.Config?.Labels?.[labelKey]}</EuiCode>
        </Fragment>
      ))}
    </div>
  ))(instance)

export default withErrorBoundary(ContainerInstanceDetailRow)

function withInspect(getResult) {
  return (instance: ContainerInstance) => {
    if (isEmpty(instance.inspect)) {
      return null
    }

    return getResult(instance.inspect as ContainerInstanceInspect, instance)
  }
}
