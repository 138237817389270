/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import { CuiDuration, CuiLink, CuiTimeAgo, CuiTable } from '../../../../cui'
import SnapshotHealth from '../SnapshotHealth'
import SnapshotInProgress from '../SnapshotInProgress'
import { atPath } from '../../../../lib/objects'
import { clusterSnapshotUrl } from '../../../../lib/urlBuilder'

import SnapshotTableRowActions from './SnapshotTableRowActions'

import type { ClusterSnapshot, StackDeployment } from '../../../../types'
import type { CuiTableColumn } from '../../../../cui'
import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  snapshots: ClusterSnapshot[]
  readonly?: boolean
}

type SnapshotNameProps = {
  deployment: StackDeployment
  snapshot: ClusterSnapshot
  readonly?: boolean
}

type SnapshotProps = {
  snapshot: ClusterSnapshot
}

const SnapshotName: FunctionComponent<SnapshotNameProps> = ({
  deployment,
  snapshot: { snapshot },
  readonly,
}) => {
  const isScheduled = snapshot.includes(`scheduled-`)
  const type = isScheduled ? `scheduled` : `unscheduled`
  const testId = `type-${type}`

  if (readonly) {
    return <span data-test-id={testId}>{snapshot}</span>
  }

  return (
    <CuiLink to={clusterSnapshotUrl(deployment.id, snapshot)} data-test-id={testId}>
      {snapshot}
    </CuiLink>
  )
}

const SnapshotEndTime: FunctionComponent<SnapshotProps> = ({ snapshot: { state, end_time } }) =>
  state === `IN_PROGRESS` ? null : <CuiTimeAgo date={end_time} longTime={true} />

const SnapshotDuration: FunctionComponent<SnapshotProps> = ({
  snapshot: { state, duration_in_millis },
}) => (state === `IN_PROGRESS` ? null : <CuiDuration milliseconds={duration_in_millis} />)

const SnapshotsTable: FunctionComponent<Props> = ({ deployment, snapshots, readonly }) => {
  const columns: Array<CuiTableColumn<ClusterSnapshot>> = [
    {
      label: <FormattedMessage id='cluster-snapshots-table.name' defaultMessage='Snapshot' />,
      render: (snapshot) => (
        <SnapshotName deployment={deployment} snapshot={snapshot} readonly={readonly} />
      ),
      sortKey: `snapshot`,
    },

    {
      label: <FormattedMessage id='cluster-snapshots-table.status' defaultMessage='Status' />,
      render: (snapshot) => <SnapshotHealth state={snapshot.state} />,
      sortKey: `state`,
    },

    {
      label: <FormattedMessage id='cluster-snapshots-table.completed' defaultMessage='Completed' />,
      render: (snapshot) => <SnapshotEndTime snapshot={snapshot} />,
      sortKey: `end_time`,
    },

    {
      label: <FormattedMessage id='cluster-snapshots-table.duration' defaultMessage='Duration' />,
      render: (snapshot) => <SnapshotDuration snapshot={snapshot} />,
      sortKey: `duration_in_millis`,
    },
  ]

  if (!readonly) {
    columns.push({
      id: 'snapshotTableRowActions',
      render: (snapshot) => (
        <SnapshotTableRowActions deployment={deployment} snapshot={snapshot} readonly={readonly} />
      ),
      width: '150px',
      actions: true,
      mobile: {
        label: <FormattedMessage id='cluster-snapshots-table.actions' defaultMessage='Actions' />,
      },
    })
  }

  return (
    <CuiTable<ClusterSnapshot>
      pageSize={10}
      fullWidth={false}
      rows={snapshots}
      getRowId={atPath(`uuid`)}
      hasDetailRow={(row) => row.state === `IN_PROGRESS`}
      renderDetailRow={(row) => (
        <Fragment>
          <SnapshotInProgress deployment={deployment} snapshot={row} snapshotName={row.snapshot} />
          <EuiSpacer size='m' />
        </Fragment>
      )}
      className='snapshotsTable snapshotsHistory'
      columns={columns}
    />
  )
}

export default SnapshotsTable
