/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiIconTip, EuiLoadingContent, EuiSpacer, EuiText, EuiSelect } from '@elastic/eui'

import { CuiAlert } from '@/cui'

import Status from './Status'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './'

const messages = defineMessages({
  label: {
    id: `allow-extra-versions.label`,
    defaultMessage: `Region Id`,
  },
  tooltipText: {
    id: `allow-extra-versions.description`,
    defaultMessage: `Some customers will ask to create a deployment on an older version that is not offered by default. We allow this only in exceptional circumstances and must be approved by Janitors. Approval is on a per region and per organization level.`,
  },
})

interface State {
  userInputRegionId: string
}

class AllowExtraVersions extends Component<AllProps & WrappedComponentProps> {
  state: State = {
    userInputRegionId: ``,
  }

  componentDidMount() {
    const { fetchPlatformOverviewIfNeeded } = this.props
    fetchPlatformOverviewIfNeeded()
  }

  render() {
    const {
      intl: { formatMessage },
      organizationId,
      platform,
      fetchPlatformRequest,
    } = this.props

    if (fetchPlatformRequest.error) {
      return <CuiAlert type='danger'>{fetchPlatformRequest.error}</CuiAlert>
    }

    if (fetchPlatformRequest.inProgress || platform === null || !organizationId) {
      return <EuiLoadingContent data-test-id='allow-extra-versions-loading' lines={1} />
    }

    const selectOptions = [{ value: ``, text: `` }].concat(
      platform.regions.map((region) => ({ value: region.region_id, text: region.region_id })),
    )

    return (
      <Fragment>
        <EuiText size='s'>
          <FormattedMessage
            id='allow-extra-versions.title'
            defaultMessage='Allow Customer to create deployments on older versions'
          />
          {` `}
          <EuiIconTip
            color='primary'
            content={formatMessage(messages.tooltipText)}
            position='top'
            type='iInCircle'
          />
        </EuiText>

        <EuiSpacer size='s' />

        <EuiSelect
          options={selectOptions}
          onChange={(e) => this.setState({ userInputRegionId: e.target.value })}
          data-test-subj='allow-extra-versions-region-id-input'
        />

        <EuiSpacer />

        <Status regionId={this.state.userInputRegionId} organizationId={organizationId!} />
      </Fragment>
    )
  }
}

export default injectIntl(AllowExtraVersions)
