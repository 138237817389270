/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormLabel, EuiSpacer, EuiTextColor } from '@elastic/eui'

import DiskUsage from '../DiskUsage'
import { getThemeColors } from '../../lib/theme'

import type { FunctionComponent } from 'react'

type Props = {
  totalCapacity: number
  availableCapacity: number
  hasFilters: boolean
  isLoading?: boolean
}

const AllocatorZonesSummary: FunctionComponent<Props> = ({
  totalCapacity,
  availableCapacity,
  hasFilters,
  isLoading = false,
}) => {
  const { euiColorDarkestShade } = getThemeColors()

  return (
    <div>
      <EuiFormLabel>
        <FormattedMessage
          id='allocators-info.capacity-across-all-zones'
          defaultMessage='Capacity across all zones{ filterTip }'
          values={{
            filterTip: hasFilters ? <EuiTextColor color='warning'>*</EuiTextColor> : null,
          }}
        />
      </EuiFormLabel>

      <EuiSpacer size='xs' />

      <DiskUsage
        total={totalCapacity}
        available={availableCapacity}
        color={availableCapacity === 0 ? euiColorDarkestShade : 'success'}
        isLoading={isLoading}
      />
    </div>
  )
}

export default AllocatorZonesSummary
