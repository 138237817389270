/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import { CuiAlert, CuiPermissibleControl, CuiFileUploadButton } from '../../../../cui'
import SpinButton from '../../../SpinButton'
import Permission from '../../../../lib/api/v1/permissions'

import type { AsyncRequestState } from '../../../../types'

import './uploadStackPackButton.scss'

type Props = {
  fetchVersions: (regionId: string) => void
  regionId: string
  resetUploadStackPackRequest: (regionId: string) => void
  uploadStackPack: (regionId: string, stackPack: File) => Promise<any>
  uploadStackPackRequest: AsyncRequestState
}

class UploadStackPackButton extends Component<Props> {
  componentWillUnmount() {
    const { regionId, resetUploadStackPackRequest } = this.props
    resetUploadStackPackRequest(regionId)
  }

  render() {
    const { uploadStackPackRequest } = this.props

    return (
      <div>
        <CuiPermissibleControl permissions={Permission.updateStackPacks}>
          <CuiFileUploadButton
            className='uploadStackPackButton-upload'
            disabled={uploadStackPackRequest.inProgress}
            onChange={(file) => this.onFileReceived(file)}
          >
            <CuiPermissibleControl permissions={Permission.updateStackPacks}>
              <SpinButton
                color='primary'
                fill={false}
                size='s'
                spin={uploadStackPackRequest.inProgress}
              >
                <FormattedMessage
                  id='upload-stack-pack-button.upload-stack-pack'
                  defaultMessage='Upload Elastic Stack pack'
                />
              </SpinButton>
            </CuiPermissibleControl>
          </CuiFileUploadButton>
        </CuiPermissibleControl>

        {uploadStackPackRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert className='uploadStackPackButton-uploadResult' type='error'>
              {uploadStackPackRequest.error}
            </CuiAlert>
          </Fragment>
        )}

        {!uploadStackPackRequest.error && uploadStackPackRequest.isDone && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert className='uploadStackPackButton-uploadResult' type='info'>
              <FormattedMessage
                id='upload-stack-pack-button.upload-successful'
                defaultMessage='The Elastic Stack pack was uploaded successfully.'
              />
            </CuiAlert>
          </Fragment>
        )}
      </div>
    )
  }

  onFileReceived(file) {
    const { uploadStackPack, regionId, fetchVersions } = this.props
    return uploadStackPack(regionId, file).then(() => fetchVersions(regionId))
  }
}

export default UploadStackPackButton
