/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { EuiPageHeader, EuiIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { deploymentHealthStatusUrl } from '@/lib/urlBuilder'

import type { Props } from '../../DeploymentHeader/DeploymentHeader'
import type { FunctionComponent } from 'react'

export const HealthStatusDetailsHeader: FunctionComponent<Props> = ({
  stackDeployment,
  problems,
  match,
}) => {
  const { issue } = match.params
  const problem = problems.find(({ id }) => id === issue)

  return (
    <EuiPageHeader
      pageTitle={problem?.message ?? ''}
      bottomBorder={Boolean(problem)}
      breadcrumbs={[
        {
          text: (
            <Link to={deploymentHealthStatusUrl(stackDeployment!.id)}>
              <EuiFlexGroup alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <EuiIcon size='s' type='arrowLeft' />
                </EuiFlexItem>
                <EuiFlexItem>
                  <FormattedMessage
                    id='health-status-details-header.nav-text'
                    defaultMessage='Health issues'
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          ),
          color: 'primary',
          'aria-current': false,
          href: '#',
          onClick: (e) => e.preventDefault(),
        },
      ]}
    />
  )
}
