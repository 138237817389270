/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import { CuiRouterLinkButton } from '../../../../cui'
import Header from '../../../Header'
import InstanceConfigurationWizard from '../InstanceConfigurationWizard'
import { topologyInstanceConfigurationsUrl } from '../../../../lib/urlBuilder'
import { topologyInstanceConfigurationCreateCrumbs } from '../../../../lib/crumbBuilder'

import type { InstanceConfiguration } from '../../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../../types'

export type Props = {
  regionId: string
  createInstanceConfigurationRequest: AsyncRequestState
  createInstanceConfiguration: (instanceConfiguration: InstanceConfiguration) => void
}

export default class InstanceConfigurationEdit extends Component<Props> {
  render() {
    return (
      <Fragment>
        {this.renderTitle()}

        {this.renderContent()}
      </Fragment>
    )
  }

  renderTitle() {
    const { regionId, createInstanceConfigurationRequest } = this.props

    return (
      <Fragment>
        <Header
          breadcrumbs={topologyInstanceConfigurationCreateCrumbs({ regionId })}
          name={
            <FormattedMessage
              id='instance-configuration-create.title'
              defaultMessage='Create instance configuration'
            />
          }
        >
          <CuiRouterLinkButton
            data-test-id='create-instance-config-button'
            disabled={createInstanceConfigurationRequest.inProgress}
            to={topologyInstanceConfigurationsUrl(regionId)}
          >
            <FormattedMessage id='instance-configuration-create.cancel' defaultMessage='Cancel' />
          </CuiRouterLinkButton>
        </Header>
      </Fragment>
    )
  }

  renderContent() {
    const { regionId, createInstanceConfigurationRequest } = this.props

    return (
      <InstanceConfigurationWizard
        data-test-id='instance-configuration-wizard'
        regionId={regionId}
        isSaving={createInstanceConfigurationRequest.inProgress}
        error={createInstanceConfigurationRequest.error}
        onSave={this.onSave}
        saveButtonLabel={
          <FormattedMessage
            id='instance-configuration-create.save-configuration'
            defaultMessage='Save and create configuration'
          />
        }
        prompt={
          <EuiText>
            <FormattedMessage
              id='instance-configuration-create.description'
              defaultMessage='Find allocators based on the hardware characteristics you tagged them with and then indicate how products or features of the Elastic Stack should run on them.'
            />
          </EuiText>
        }
      />
    )
  }

  onSave = (newInstanceConfiguration) => {
    const { createInstanceConfiguration } = this.props
    createInstanceConfiguration(newInstanceConfiguration)
  }
}
