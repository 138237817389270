/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeploymentCostItemsRequest,
  getDeploymentItemsCostsByDeployment,
} from '../../../../reducers'
import { fetchDeploymentCostItems } from '../../../../actions/billing'

import DeploymentCostDetails from './DeploymentCostDetails'

import type { AsyncRequestState, ThunkDispatch, TimePeriod } from '../../../../types'
import type { ItemsCosts } from '../../../../lib/api/v1/types'

interface StateProps {
  deploymentItemsCostsByDeployment?: ItemsCosts
  fetchDeploymentCostItemsRequest: AsyncRequestState
}

interface DispatchProps {
  fetchDeploymentCostItems: (args: {
    timePeriod: TimePeriod
    deploymentId: string
    organizationId: string
  }) => Promise<any>
}

interface ConsumerProps {
  timePeriod?: TimePeriod
}

const mapStateToProps = (state, ownProps): StateProps => {
  const {
    profile: { organization_id },
  } = ownProps

  return {
    deploymentItemsCostsByDeployment: getDeploymentItemsCostsByDeployment(state, organization_id),
    fetchDeploymentCostItemsRequest: fetchDeploymentCostItemsRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchDeploymentCostItems: (args: {
    timePeriod: TimePeriod
    deploymentId: string
    organizationId: string
  }) => dispatch(fetchDeploymentCostItems(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentCostDetails)
