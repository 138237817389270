/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { createSecurityCluster, fetchSecurityCluster } from '../../../actions'
import {
  createSecurityClusterRequest,
  fetchSecurityClusterRequest,
  getSecurityCluster,
} from '../../../reducers'
import withPolling from '../../../../../lib/withPolling'

import SecurityClusterWrapper from './SecurityClusterWrapper'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId },
    },
  },
) => {
  const securityCluster = getSecurityCluster(state, regionId)

  return {
    regionId,
    securityCluster,
    createSecurityClusterRequest: createSecurityClusterRequest(state, regionId),
    fetchSecurityClusterRequest: fetchSecurityClusterRequest(state, regionId),
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  createSecurityCluster: () => dispatch(createSecurityCluster(regionId)),
  fetchSecurityCluster: () => dispatch(fetchSecurityCluster(regionId)),
})

const pollingComponent = withPolling(
  SecurityClusterWrapper,
  ({ fetchSecurityCluster: onPoll, securityCluster }) => ({
    interval: securityCluster && securityCluster.has_pending_plan ? 10 : 30,
    onPoll,
    pollImmediately: [`securityCluster`],
  }),
)

export default connect(mapStateToProps, mapDispatchToProps)(pollingComponent)
