/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiLoadingContent } from '@elastic/eui'

import RatePanel from '..'
import { messages } from '../../messages'

import type { Rate } from '..'
import type { Info } from '../../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  isLoading?: boolean
  rate?: number
  rates?: Rate[]
  info?: Info[]
  isPrepaidConsumptionUser: boolean
}

const MonthToDateUsage: FunctionComponent<Props> = ({
  intl: { formatMessage },
  isLoading,
  info,
  rate,
  rates,
  isPrepaidConsumptionUser,
}) => {
  if (isLoading) {
    return <EuiLoadingContent lines={3} />
  }

  return (
    <RatePanel
      isPrepaidConsumptionUser={isPrepaidConsumptionUser}
      description={{
        text: formatMessage(messages.monthToDateUsage),
      }}
      info={info}
      rate={rate}
      rates={rates}
    />
  )
}

export default injectIntl(MonthToDateUsage)
