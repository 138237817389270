/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sumBy } from 'lodash'

import type { RegionInfo, ZookeeperNodeInfo } from '../../../../lib/api/v1/types'

export function getAllocatorCounts({ region }: { region: RegionInfo }) {
  const { allocators } = region
  const totalCount = sumBy(allocators.zone_summaries, `total_allocators`)
  const connectedCount = sumBy(allocators.zone_summaries, `connected_allocators`)
  const disconnectedCount = totalCount - connectedCount

  return {
    total: totalCount,
    connected: connectedCount,
    disconnected: disconnectedCount,
  }
}

export function getOverallStatusColor({ region }: { region: RegionInfo }) {
  const zkNodes = region.zookeeper_states.states

  if (!region.allocators.healthy) {
    return `danger`
  }

  if (zkNodes.some((zkNode) => getZkStatusColor({ zkNode }) === `danger`)) {
    return `danger`
  }

  if (zkNodes.some((zkNode) => getZkStatusColor({ zkNode }) === `warning`)) {
    return `warning`
  }

  const allocatorCounts = getAllocatorCounts({ region })

  if (allocatorCounts.disconnected > 0) {
    return `warning`
  }

  if (!region.proxies.healthy) {
    return `warning`
  }

  return `success`
}

export function getZkStatusColor({ zkNode }: { zkNode: ZookeeperNodeInfo }) {
  const { state } = zkNode

  if (state === `lost`) {
    return `danger`
  }

  if (state === `read_only` || state === `suspended`) {
    return `warning`
  }

  return `success`
}
