/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'

import { getActiveCustomerDashboard, getInactiveCustomerDashboard } from './kpiDashboardLinks'

import type { FunctionComponent } from 'react'

type Props = {
  organizationId: string
  isActive: boolean
}

const OrganizationKpiDashboardLink: FunctionComponent<Props> = ({ organizationId, isActive }) => {
  const dashboardHref = isActive
    ? getActiveCustomerDashboard(organizationId)
    : getInactiveCustomerDashboard(organizationId)

  return (
    <ExternalLink href={dashboardHref}>
      <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <FormattedMessage
            id='organization.organization-overview.organization-kpi-dashboard-link.dashboard-label'
            defaultMessage='Cloud analytics'
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiIcon type='popout' />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ExternalLink>
  )
}

export default OrganizationKpiDashboardLink
