/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import {
  EuiButtonEmpty,
  EuiCode,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormHelpText,
  EuiFormLabel,
  EuiLoadingContent,
  EuiSelect,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert, withErrorBoundary } from '../../cui'
import SpinButton from '../SpinButton'

import type { AsyncRequestState } from '../../types'
import type { PatternLevel } from './types'

type Props = {
  isNew: boolean
  loggingSetting: PatternLevel
  saveLoggingSettingRequest: AsyncRequestState
  onClose: () => void
  onSave: (loggingSetting: PatternLevel) => void
}

type State = {
  loggingSetting: PatternLevel
}

const loggerLevels = [`OFF`, `ERROR`, `WARN`, `INFO`, `DEBUG`, `TRACE`, `ALL`]

class EditZkLoggingSettings extends Component<Props, State> {
  state: State = {
    loggingSetting: { ...this.props.loggingSetting },
  }

  render() {
    const { isNew, onClose } = this.props

    return (
      <EuiFlyout ownFocus={true} onClose={onClose} size='s'>
        <EuiFlyoutHeader>
          <EuiTitle>
            <h3>
              {isNew ? (
                <FormattedMessage
                  id='edit-zk-logging-setting-modal.create-title'
                  defaultMessage='New logging setting'
                />
              ) : (
                <FormattedMessage
                  id='edit-zk-logging-setting-modal.edit-title'
                  defaultMessage='Update logging setting'
                />
              )}
            </h3>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>{this.renderContent()}</EuiFlyoutBody>

        <EuiFlyoutFooter>{this.renderActions()}</EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderContent() {
    const { loggingSetting } = this.state

    const loggerLevelOptions = loggerLevels.map((level) => ({
      text: capitalize(level),
      value: level,
    }))

    if (!loggingSetting) {
      return <EuiLoadingContent lines={3} />
    }

    return (
      <Fragment>
        <EuiSpacer size='l' />
        <EuiFormLabel>
          <FormattedMessage id='edit-zk-logging-setting-modal.logger' defaultMessage='Logger' />
        </EuiFormLabel>

        <EuiFieldText value={loggingSetting.pattern} onChange={this.onChange('pattern')} />

        <EuiFormHelpText>
          <FormattedMessage
            id='edit-zk-logging-setting-modal.logger-help'
            defaultMessage='Type the Scala class name you want to log messages for. You can use {wildcard} as a wildcard, too.'
            values={{ wildcard: <EuiCode>*</EuiCode> }}
          />
        </EuiFormHelpText>

        <EuiSpacer size='l' />

        <EuiFormLabel>
          <FormattedMessage id='edit-zk-logging-setting-modal.level' defaultMessage='Level' />
        </EuiFormLabel>

        <EuiSelect
          options={loggerLevelOptions}
          value={loggingSetting.level}
          onChange={this.onChange('level')}
        />

        <EuiFormHelpText>
          <FormattedMessage
            id='edit-zk-logging-setting-modal.level-help'
            defaultMessage='Choose the level of verbosity you want to configure for this logger.'
          />
        </EuiFormHelpText>
      </Fragment>
    )
  }

  renderActions() {
    const { isNew, onClose, onSave, saveLoggingSettingRequest } = this.props
    const { loggingSetting } = this.state

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={onClose}>
              <FormattedMessage id='edit-zk-logging-setting-modal.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <SpinButton
              spin={saveLoggingSettingRequest.inProgress}
              onClick={() => onSave(loggingSetting)}
            >
              {isNew ? (
                <FormattedMessage
                  id='edit-zk-logging-setting-modal.create'
                  defaultMessage='Create'
                />
              ) : (
                <FormattedMessage
                  id='edit-zk-logging-setting-modal.update'
                  defaultMessage='Update'
                />
              )}
            </SpinButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {saveLoggingSettingRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{saveLoggingSettingRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  onChange = (fieldKey) => (e) => {
    const { loggingSetting } = this.state

    this.setState({
      loggingSetting: {
        ...loggingSetting,
        [fieldKey]: e.target.value,
      },
    })
  }
}

export default withErrorBoundary(EditZkLoggingSettings)
