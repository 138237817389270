/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { stringify } from 'querystring'

import asyncRequest from '../asyncRequests'
import { FETCH_SNAPSHOTS } from '../../constants/actions'
import { getDeploymentResourceProxyRequestsUrl } from '../../lib/api/v1/urls'
import {
  getFirstEsClusterFromGet,
  getFirstEsRefId,
  hasSlm,
} from '../../lib/stackDeployments/selectors'

import { REPOSITORY_NAME } from './constants'

import type { ClusterSnapshotAction, StackDeployment, ThunkAction } from '../../types'

export function fetchSnapshots({
  deployment,
}: {
  deployment: StackDeployment
}): ThunkAction<Promise<ClusterSnapshotAction>> {
  return (dispatch) => {
    const { id: deploymentId } = deployment
    const refId = getFirstEsRefId({ deployment })

    // Non-SLM snapshots are de-facto capped by Cloud policies to ~100 in normal
    // circumstances. SLM snapshots may number *much* higher, especially with
    // searchable snapshots, so we avoid verbose listings for SLM-enabled
    // clusters.
    const resource = getFirstEsClusterFromGet({ deployment })
    const verbose = Boolean(resource && !hasSlm({ resource }))

    const query = {
      ignore_unavailable: true,
      verbose,
    }
    const queryString = stringify(query)

    const url = getDeploymentResourceProxyRequestsUrl({
      deploymentId,
      resourceKind: 'elasticsearch',
      refId: refId!,
      proxyPath: `_snapshot/${REPOSITORY_NAME}/_all?${queryString}`,
    })

    return dispatch(
      asyncRequest({
        type: FETCH_SNAPSHOTS,
        url,
        meta: { deployment },
        crumbs: [deploymentId],
        requestSettings: {
          request: {
            headers: {
              'X-Management-Request': true,
            },
          },
        },
      }),
    )
  }
}
