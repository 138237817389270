/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { capitalize } from 'lodash'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiTitle,
  EuiDescriptionList,
} from '@elastic/eui'

import NotFound from '@/components/ErrorPages/NotFound'
import { getSeverityColorMapping } from '@/lib/healthProblems/problems'

import HealthStatusDetailsTroubleshooting from './HealthStatusDetailsTroubleshooting'

import type { Problem } from '@/lib/healthProblems/problems'
import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { FunctionComponent } from 'react'

export type StateProps = {
  problems: Problem[]
}

export interface DispatchProps {}

export type OwnProps = WithStackDeploymentRouteParamsProps

export type Props = StateProps & DispatchProps & OwnProps

const HealthStatusDetails: FunctionComponent<Props> = ({ problems, match }) => {
  const problem = problems.find(({ id }) => id === match.params.issue)

  if (!problem) {
    return <NotFound withHttpErrorPage={false} />
  }

  const hasImpacts = problem.impacts && problem.impacts?.length > 0
  const hasDiagnosis = problem.diagnosis && problem.diagnosis?.length > 0

  return (
    <React.Fragment>
      <EuiSpacer size='xxl' />
      <EuiCard
        data-test-subj='health-problem-details'
        title=''
        layout='horizontal'
        display='transparent'
        paddingSize='none'
      >
        <EuiFlexGroup direction='column' gutterSize='xs'>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={1}>
                <EuiTitle size='xs'>
                  <h4>
                    <FormattedMessage
                      id='health-status-details.severity'
                      defaultMessage='Severity'
                    />
                  </h4>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={3}>
                <span>
                  <EuiBadge color={getSeverityColorMapping(problem.severity!)}>
                    {capitalize(problem.severity)}
                  </EuiBadge>
                </span>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiHorizontalRule />
          </EuiFlexItem>

          {hasImpacts && (
            <React.Fragment>
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem grow={1}>
                    <EuiTitle size='xs'>
                      <h4>
                        <FormattedMessage
                          id='health-status-details.impacts'
                          defaultMessage='Affected capabilities'
                        />
                      </h4>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={3}>
                    <EuiDescriptionList
                      listItems={problem.impacts?.map((impact) => ({
                        title: impact.impact_areas.map(capitalize).join(', '),
                        description: impact.description,
                      }))}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>

              {hasDiagnosis && (
                <EuiFlexItem>
                  <EuiHorizontalRule />
                </EuiFlexItem>
              )}
            </React.Fragment>
          )}

          {hasDiagnosis && (
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem grow={1}>
                  <EuiTitle size='xs'>
                    <h4>
                      <FormattedMessage
                        id='health-status-details.troubleshooting'
                        defaultMessage='Troubleshooting'
                      />
                    </h4>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={3}>
                  <HealthStatusDetailsTroubleshooting problem={problem} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiCard>
    </React.Fragment>
  )
}

export default HealthStatusDetails
