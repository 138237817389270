/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert } from '../../../cui'

import type { AsyncRequestState } from '../../../types'

export type AliasConfigProps = {
  deploymentDomainAliasEditAccessRequest: AsyncRequestState
  deploymentAliasEditAccess: boolean
  fetchDeploymentAliasEditAccess: (params: { regionId: string }) => Promise<any>
  getDeploymentDomainAliasEditAccessRequest: AsyncRequestState
  regionId: string
  updateDeploymentAliasEditAccess: (params: {
    regionId: string
    editAccess: boolean
  }) => Promise<any>
  updateDeploymentDomainAliasEditAccessRequest: AsyncRequestState
}

type Props = AliasConfigProps

class DeploymentAliasConfig extends Component<Props> {
  componentDidMount() {
    const { fetchDeploymentAliasEditAccess, regionId } = this.props

    fetchDeploymentAliasEditAccess({ regionId })
  }

  render() {
    const {
      updateDeploymentDomainAliasEditAccessRequest,
      getDeploymentDomainAliasEditAccessRequest,
      deploymentAliasEditAccess,
    } = this.props

    return (
      <EuiDescribedFormGroup
        id='deployment-alias-section-group'
        data-test-id='deployment-alias-section-label'
        title={
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage
                id='deployment-manage-name.alias-edit-access'
                defaultMessage='Enable custom endpoint alias naming'
              />
            </h3>
          </EuiTitle>
        }
        description={
          <FormattedMessage
            id='deployment-alias-manage-name.enable-aliases'
            defaultMessage='Allow platform administrators and deployment managers to choose a simplified, unique URL for the endpoint.'
          />
        }
        fullWidth={true}
      >
        <EuiFormRow id='deployment-alias-form-row' fullWidth={true}>
          <div>
            <EuiSpacer size='s' />
            <EuiSwitch
              data-test-id='deployment-alias-enable-switch'
              id='enable-deployment-aliases'
              label={
                <Fragment>
                  <EuiFlexGroup
                    alignItems='center'
                    gutterSize='m'
                    justifyContent='flexStart'
                    responsive={false}
                  >
                    <EuiFlexItem>
                      <EuiFlexGroup responsive={false}>
                        <EuiFlexItem grow={false}>
                          <EuiText size='s'>
                            {deploymentAliasEditAccess ? (
                              <FormattedMessage
                                id='deployment-alias-manage-name.enable-aliases-yes'
                                defaultMessage='Yes'
                              />
                            ) : (
                              <FormattedMessage
                                id='deployment-alias-manage-name.enable-aliases-no'
                                defaultMessage='No'
                              />
                            )}
                          </EuiText>
                        </EuiFlexItem>
                        {updateDeploymentDomainAliasEditAccessRequest.inProgress ||
                          (getDeploymentDomainAliasEditAccessRequest.inProgress && (
                            <EuiFlexItem grow={false}>
                              <EuiLoadingSpinner size='m' />
                            </EuiFlexItem>
                          ))}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              }
              checked={deploymentAliasEditAccess}
              onChange={() => this.toggleEnableAlias()}
            />

            {getDeploymentDomainAliasEditAccessRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />
                <CuiAlert type='error'>{getDeploymentDomainAliasEditAccessRequest.error}</CuiAlert>
              </Fragment>
            )}

            {updateDeploymentDomainAliasEditAccessRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />
                <CuiAlert type='error'>
                  {updateDeploymentDomainAliasEditAccessRequest.error}
                </CuiAlert>
              </Fragment>
            )}
          </div>
        </EuiFormRow>
      </EuiDescribedFormGroup>
    )
  }

  toggleEnableAlias = () => {
    const { deploymentAliasEditAccess, fetchDeploymentAliasEditAccess } = this.props
    const { updateDeploymentAliasEditAccess, regionId } = this.props

    updateDeploymentAliasEditAccess({
      regionId,
      editAccess: !deploymentAliasEditAccess,
    }).then(() => {
      fetchDeploymentAliasEditAccess({ regionId })
    })
  }
}

export default DeploymentAliasConfig
