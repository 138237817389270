/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import { flatMap, orderBy } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiIcon,
  EuiText,
} from '@elastic/eui'

import { CuiLink, CuiTable, withErrorBoundary } from '../../cui'
import AutoscalingBadge from '../Autoscaling/AutoscalingBadge'
import {
  StackConfigurationChangeAttribution,
  StackConfigurationChangeRawPlanJsonCopyButton,
  StackConfigurationChangeSource,
  StackConfigurationChangeStatus,
  StackConfigurationChangeTime,
  getSourceAttribution,
} from '../StackDeploymentConfigurationChange'
import StackDeploymentStatus from '../StackDeploymentStatus'
import {
  getPlanInfo,
  getDisplayName,
  getRegionId,
  isAutoscalingGeneratedPlanAttempt,
} from '../../lib/stackDeployments/selectors'
import { getSupportedSliderInstanceTypes, getSliderIconType } from '../../lib/sliders'
import { deploymentUrl } from '../../lib/urlBuilder'

import StatefulStackDeploymentActivityTabs from './StatefulStackDeploymentActivityTabs'

import type { SliderInstanceType, AnyResourceInfo, AnyClusterPlanInfo } from '../../types'
import type { DeploymentSearchResponse } from '../../lib/api/v1/types'
import type { CuiTableColumn } from '../../cui'
import type { FunctionComponent, ReactElement } from 'react'

export type Props = {
  deployments: DeploymentSearchResponse[] | null
  scope?: SliderInstanceType[]
  emptyText: ReactElement
}

type DeploymentResource = {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planInfo: AnyClusterPlanInfo | null
}

type ActivityFeedTableRow = {
  deployment: DeploymentSearchResponse
  relevantResource: AnyResourceInfo | null
  relevantResourceType: SliderInstanceType | null
  relevantPlanInfo: AnyClusterPlanInfo | null
  failedGenesisPlan: boolean
}

const ActivityFeedTable: FunctionComponent<Props> = (props) => {
  const { deployments, scope, emptyText } = props

  if (deployments && deployments.length === 0) {
    return emptyText
  }

  const columns: Array<CuiTableColumn<ActivityFeedTableRow>> = [
    {
      label: (
        <FormattedMessage id='deployment-activity-table.deployment' defaultMessage='Deployment' />
      ),
      render: ({ deployment }: ActivityFeedTableRow) => (
        <div data-test-id='activityFeedTable-identity'>
          <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <StackDeploymentStatus deployment={deployment} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <DeploymentLink deployment={deployment} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      ),
      sortKey: [({ deployment }: ActivityFeedTableRow) => getDisplayName({ deployment })],
      textOnly: false,
    },

    {
      label: (
        <FormattedMessage
          id='deployment-activity-table.latest-changes'
          defaultMessage='Latest changes'
        />
      ),
      render: ({
        deployment,
        relevantResource,
        relevantResourceType,
        relevantPlanInfo,
        failedGenesisPlan,
      }: ActivityFeedTableRow) => {
        if (failedGenesisPlan) {
          return (
            <EuiFormHelpText>
              <FormattedMessage
                id='deployment-activity-table.failed-genesis-plan'
                defaultMessage='No active configuration yet'
              />
            </EuiFormHelpText>
          )
        }

        if (!relevantResource || !relevantPlanInfo || !relevantResourceType) {
          return null
        }

        return (
          <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type={getSliderIconType({ sliderInstanceType: relevantResourceType })} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiText size='xs' color='subdued'>
                <StackConfigurationChangeTime
                  deployment={deployment}
                  resourceType={relevantResourceType}
                  resource={relevantResource}
                  planAttempt={relevantPlanInfo}
                  hideTimeSpent={true}
                />
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <StackConfigurationChangeStatus
                resourceType={relevantResourceType}
                resource={relevantResource}
                planAttempt={relevantPlanInfo}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
      sortKey: `relevantPlanInfo.attempt_start_time`,
      width: '250px',
    },

    {
      label: <FormattedMessage id='deployment-activity-table.source' defaultMessage='Source' />,
      render: ({ relevantPlanInfo }: ActivityFeedTableRow) =>
        relevantPlanInfo ? (
          <EuiFlexGroup gutterSize='s' alignItems='flexStart' direction='column'>
            {isAutoscalingGeneratedPlanAttempt({ planAttempt: relevantPlanInfo }) && (
              <EuiFlexItem grow={false}>
                <AutoscalingBadge />
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <StackConfigurationChangeSource
                action={getSourceAttribution({ planAttempt: relevantPlanInfo })}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : null,
      sortKey: `relevantPlanInfo.source.action`,
      width: '240px',
    },

    {
      label: (
        <FormattedMessage id='deployment-activity-table.applied-by' defaultMessage='Applied by' />
      ),
      render: ({ relevantPlanInfo }: ActivityFeedTableRow) =>
        relevantPlanInfo ? (
          <StackConfigurationChangeAttribution
            kind='elasticsearch'
            planAttempt={relevantPlanInfo}
            hideEye={true}
          />
        ) : null,
      align: 'right' as const,
      width: '110px',
    },

    {
      label: <FormattedMessage id='deployment-activity-table.actions' defaultMessage='Actions' />,
      render: ({ relevantPlanInfo }: ActivityFeedTableRow) =>
        relevantPlanInfo ? (
          <StackConfigurationChangeRawPlanJsonCopyButton planAttempt={relevantPlanInfo} />
        ) : null,
      align: 'right' as const,
      width: '200px',
      actions: true,
      mobile: ({ relevantPlanInfo }: ActivityFeedTableRow) =>
        relevantPlanInfo
          ? {
              label: (
                <FormattedMessage id='deployment-activity-table.actions' defaultMessage='Actions' />
              ),
            }
          : null,
    },
  ]

  const rows = getTableRows({ deployments, scope })

  return (
    <CuiTable<ActivityFeedTableRow>
      columns={columns}
      rows={rows}
      getRowId={({ deployment }) => deployment.id}
      hasDetailRow={({ failedGenesisPlan }) => !failedGenesisPlan}
      renderDetailButton={CustomExpandButton}
      renderDetailRow={DetailRow}
      pageSize={10}
    />
  )
}

function getTableRows({
  deployments,
  scope,
}: {
  deployments: DeploymentSearchResponse[] | null
  scope?: SliderInstanceType[]
}): ActivityFeedTableRow[] | undefined {
  if (!deployments) {
    return undefined
  }

  return deployments.map(getTableRow)

  function getTableRow(deployment: DeploymentSearchResponse): ActivityFeedTableRow {
    const sliderInstanceTypes = getSupportedSliderInstanceTypes()
    const relevantTypes = scope !== undefined ? scope : sliderInstanceTypes
    const scopedResources = getDeploymentResources({ deployment, resourceTypes: relevantTypes })
    const sortedResources = sortDeploymentResources(scopedResources)

    const [mostRelevantResource] = sortedResources
    const { resource = null, resourceType = null, planInfo = null } = mostRelevantResource || {}

    const deploymentResources = getDeploymentResources({
      deployment,
      resourceTypes: sliderInstanceTypes,
    })
    const failedGenesisPlan = deploymentResources.every(({ planInfo }) => !planInfo)

    return {
      deployment,
      relevantResource: resource,
      relevantResourceType: resourceType,
      relevantPlanInfo: planInfo,
      failedGenesisPlan,
    }
  }
}

function getDeploymentResources({
  deployment,
  resourceTypes,
}: {
  deployment: DeploymentSearchResponse
  resourceTypes: SliderInstanceType[]
}): DeploymentResource[] {
  return flatMap<SliderInstanceType, DeploymentResource>(resourceTypes, (resourceType) =>
    (deployment.resources[resourceType] || []).map((resource) => {
      const planInfo = getPlanInfo({
        resource,
        state: `most_recent`,
      })

      return {
        resource,
        resourceType,
        planInfo,
      }
    }),
  )
}

function sortDeploymentResources(resources: DeploymentResource[]): DeploymentResource[] {
  // use a single "default" now moment in time so that
  // we always fall back to breaking the sorting tie against start time
  const now = moment()

  return orderBy(
    resources,
    [
      ({ planInfo }) => planInfo && moment(planInfo.attempt_end_time || now).valueOf(),
      ({ planInfo }) => planInfo && moment(planInfo.attempt_start_time).valueOf(),
    ],
    [`desc`, `desc`],
  )
}

function DetailRow({ deployment }: ActivityFeedTableRow) {
  return (
    <EuiErrorBoundary>
      <StatefulStackDeploymentActivityTabs deployment={deployment} />
    </EuiErrorBoundary>
  )
}

function CustomExpandButton({ isExpanded, toggleExpanded }) {
  return (
    <EuiButtonEmpty
      data-test-id='planAttemptMeta-showPlanDetails'
      size='s'
      iconSide='right'
      iconType={isExpanded ? `arrowUp` : `arrowDown`}
      onClick={toggleExpanded}
    >
      <FormattedMessage id='deployment-activity-table.show-changes' defaultMessage='Show changes' />
    </EuiButtonEmpty>
  )
}

const DeploymentLink: FunctionComponent<{ deployment: DeploymentSearchResponse }> = ({
  deployment,
}) => {
  const regionId = getRegionId({ deployment })

  if (!regionId) {
    return null
  }

  const { id } = deployment
  const name = getDisplayName({ deployment })

  return <CuiLink to={deploymentUrl(id)}>{name}</CuiLink>
}

export default withErrorBoundary(ActivityFeedTable)
