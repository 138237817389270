/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiSpacer, EuiTitle, EuiText } from '@elastic/eui'

import { CuiAlert, CuiLink } from '../../cui'
import Header from '../Header'
import AllocatorCapacityOverviewPanel from '../AllocatorCapacityOverviewPanel'
import {
  deploymentsUrl,
  hostsAllocatorsUrl,
  hostsControlPlanesUrl,
  hostsProxiesUrl,
  hostsUrl,
} from '../../lib/urlBuilder'
import { regionCrumbs } from '../../lib/crumbBuilder'
import { countControlPlanes } from '../../lib/hostRoles'

import InfrastructureVisualization from './InfrastructureVisualization'
import PlatformHealthStatsTile from './PlatformStatsTile/PlatformHealthStatsTile'
import PlatformStatsTile from './PlatformStatsTile'

import type { AllocatorSearchResult, AsyncRequestState, Region, RegionId } from '../../types'
import type { DeploymentsSearchResponse, RegionInfo } from '../../lib/api/v1/types'

import './platform.scss'

export interface Props {
  allocatorSearchResults: AllocatorSearchResult[] | undefined
  defaultRegionId?: string
  fetchAllocators: () => void
  region?: Region
  regionId: RegionId
  regionInfo: RegionInfo | null
  searchAllocatorsRequest: AsyncRequestState
  searchHealthyDeployments: () => void
  searchHealthyDeploymentsRequest: AsyncRequestState
  searchHealthyDeploymentsResult: DeploymentsSearchResponse | null
  searchUnhealthyDeployments: () => void
  searchUnhealthyDeploymentsRequest: AsyncRequestState
  searchUnhealthyDeploymentsResult: DeploymentsSearchResponse | null
}

class Platform extends Component<Props> {
  render() {
    const { defaultRegionId, regionId } = this.props

    const name = defaultRegionId ? (
      <FormattedMessage id='platform-stats.platform-summary' defaultMessage='Platform summary' />
    ) : (
      regionId
    )

    return (
      <Fragment>
        <Header name={name} breadcrumbs={regionCrumbs({ regionId })} />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const {
      allocatorSearchResults,
      defaultRegionId,
      region,
      regionId,
      regionInfo,
      searchAllocatorsRequest,
      searchHealthyDeploymentsRequest,
      searchHealthyDeploymentsResult,
      searchUnhealthyDeploymentsRequest,
      searchUnhealthyDeploymentsResult,
    } = this.props

    if (!allocatorSearchResults && searchAllocatorsRequest.error) {
      return (
        <CuiAlert data-test-id='allocatorSearchResults-error' type='error'>
          {searchAllocatorsRequest.error}
        </CuiAlert>
      )
    }

    if (!searchHealthyDeploymentsResult && searchHealthyDeploymentsRequest.error) {
      return <CuiAlert type='error'>{searchHealthyDeploymentsRequest.error}</CuiAlert>
    }

    if (!searchUnhealthyDeploymentsResult && searchUnhealthyDeploymentsRequest.error) {
      return <CuiAlert type='error'>{searchUnhealthyDeploymentsRequest.error}</CuiAlert>
    }

    const zonesCount = countZones(region)
    const isLoadedWithNoZones = region && zonesCount === undefined

    const hostsHref = hostsUrl(regionId)

    const allocatorsHref = hostsAllocatorsUrl(regionId)
    const proxiesHref = hostsProxiesUrl(regionId)
    const controlPlanesHref = hostsControlPlanesUrl(regionId)

    return (
      <Fragment>
        <EuiSpacer size='s' />

        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='platform-stats.infrastructure-summary-title'
              defaultMessage='Infrastructure'
            />
          </h3>
        </EuiTitle>

        <EuiSpacer size='l' />

        <EuiFlexGroup gutterSize='m'>
          <PlatformStatsTile
            title={<FormattedMessage id='platform-stats.zones-tile' defaultMessage='Zones' />}
            value={
              isLoadedWithNoZones ? (
                // We shouldn't get here, as the specific request made for this page contains
                // the zones. However it can't be guaranteed in some of the other responses.
                <span data-test-id='zones-count-unavailable'>-</span>
              ) : (
                zonesCount
              )
            }
          />

          <PlatformHealthStatsTile
            title={
              <CuiLink to={hostsHref}>
                <FormattedMessage id='platform-stats.hosts-tile' defaultMessage='Hosts' />
              </CuiLink>
            }
            values={countHosts(regionInfo)}
            happyWrapper={({ children }) => (
              <CuiLink to={`${hostsHref}?q=connected:y+healthy:y`} color='success'>
                {children}
              </CuiLink>
            )}
            sadWrapper={({ children }) => (
              <CuiLink to={`${hostsHref}?q=(connected:n+OR+healthy:n)`} color='danger'>
                {children}
              </CuiLink>
            )}
          />

          <PlatformHealthStatsTile
            title={
              <CuiLink to={allocatorsHref}>
                <FormattedMessage id='platform-stats.allocators-tile' defaultMessage='Allocators' />
              </CuiLink>
            }
            values={countAllocators(allocatorSearchResults)}
            happyWrapper={({ children }) => (
              <CuiLink
                to={`${allocatorsHref}?q=connected:y+healthy:y+maintenance:n`}
                color='success'
              >
                {children}
              </CuiLink>
            )}
            sadWrapper={({ children }) => (
              <CuiLink
                to={`${allocatorsHref}?q=(connected:n+OR+healthy:n+OR+maintenance:y)`}
                color='danger'
              >
                {children}
              </CuiLink>
            )}
          />

          <PlatformHealthStatsTile
            title={
              <CuiLink to={proxiesHref}>
                <FormattedMessage id='platform-stats.proxies-tile' defaultMessage='Proxies' />
              </CuiLink>
            }
            values={countProxies(region)}
            happyWrapper={({ children }) => (
              <CuiLink to={`${proxiesHref}?q=healthy:y`} color='success'>
                {children}
              </CuiLink>
            )}
            sadWrapper={({ children }) => (
              <CuiLink to={`${proxiesHref}?q=healthy:n`} color='danger'>
                {children}
              </CuiLink>
            )}
          />

          <PlatformHealthStatsTile
            title={
              <CuiLink to={controlPlanesHref}>
                <FormattedMessage
                  id='platform-stats.control-planes-tile'
                  defaultMessage='Control planes'
                />
              </CuiLink>
            }
            values={regionInfo && countControlPlanes(regionInfo)}
            happyWrapper={({ children }) => (
              <CuiLink to={`${controlPlanesHref}?q=connected:y`} color='success'>
                {children}
              </CuiLink>
            )}
            sadWrapper={({ children }) => (
              <CuiLink to={`${controlPlanesHref}?q=connected:n`} color='danger'>
                {children}
              </CuiLink>
            )}
          />

          <PlatformHealthStatsTile
            title={
              <CuiLink to={getDeploymentsHref()}>
                <FormattedMessage
                  id='platform-stats.deployments-tile'
                  defaultMessage='Deployments'
                />
              </CuiLink>
            }
            values={countDeployments({
              searchHealthyDeploymentsResult,
              searchUnhealthyDeploymentsResult,
            })}
            happyWrapper={({ children }) => (
              <CuiLink to={getDeploymentsHref('healthy:y+stopped:n')} color='success'>
                {children}
              </CuiLink>
            )}
            sadWrapper={({ children }) => (
              <CuiLink to={getDeploymentsHref('healthy:n+stopped:n')} color='danger'>
                {children}
              </CuiLink>
            )}
          />
        </EuiFlexGroup>

        <EuiSpacer size='l' />

        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='platform-stats.capacity-summary-title'
              defaultMessage='Capacity'
            />
          </h3>
        </EuiTitle>

        <EuiText color='subdued' size='s'>
          <FormattedMessage
            id='platform-stats.capacity-summary-description'
            defaultMessage='Total RAM capacity and distribution by zones'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <AllocatorCapacityOverviewPanel
          allocators={allocatorSearchResults}
          isLoading={!allocatorSearchResults}
        />

        <EuiSpacer size='l' />

        <InfrastructureVisualization regionId={regionId} />
      </Fragment>
    )

    function getDeploymentsHref(...queryParts) {
      if (regionId !== defaultRegionId) {
        queryParts.unshift(`region:${encodeURIComponent(regionId)}`)
      }

      const searchQuery = queryParts.length ? `?q=${queryParts.join('+')}` : ''
      return `${deploymentsUrl()}${searchQuery}`
    }
  }
}

export default Platform

function countHosts(regionInfo: RegionInfo | null) {
  if (!regionInfo) {
    return null
  }

  const total = regionInfo.runners.total_runners
  const happy = regionInfo.runners.connected_runners
  const sad = total - happy

  return {
    happy,
    sad,
  }
}

function countAllocators(allocators: AllocatorSearchResult[] | undefined) {
  if (!allocators) {
    return null
  }

  const healthyAllocators = allocators.filter(
    (each) => each.healthy && each.connected && !each.isInMaintenanceMode,
  )

  const unhealthyAllocators = allocators.filter(
    (each) => !each.healthy || !each.connected || each.isInMaintenanceMode,
  )

  return {
    happy: healthyAllocators.length,
    sad: unhealthyAllocators.length,
  }
}

function countProxies(region: Region | undefined) {
  if (!region) {
    return null
  }

  return region.proxies.count
}

function countZones(region: Region | undefined) {
  if (!region) {
    return null
  }

  return region.allocators.zones.count?.total
}

function countDeployments({
  searchHealthyDeploymentsResult,
  searchUnhealthyDeploymentsResult,
}: {
  searchHealthyDeploymentsResult: DeploymentsSearchResponse | null
  searchUnhealthyDeploymentsResult: DeploymentsSearchResponse | null
}) {
  if (!searchHealthyDeploymentsResult || !searchUnhealthyDeploymentsResult) {
    return null
  }

  return {
    happy: searchHealthyDeploymentsResult.match_count,
    sad: searchUnhealthyDeploymentsResult.match_count,
  }
}
