/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '@/actions/asyncRequests'
import { getLink } from '@/lib/links'
import { ACCEPT_EULA } from '@/constants/actions'

import type { RootConfig, ThunkAction } from '@/types'

export const acceptEula = (root: RootConfig): ThunkAction<Promise<void>> =>
  asyncRequest({
    type: ACCEPT_EULA,
    method: `POST`,
    url: getLink(root, `accept-eula`),
  })
