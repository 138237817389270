/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isNumber } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { getResourceLabel } from '../../../DeploymentTemplates/components/RatioLabel'
import prettySize from '../../../../../lib/prettySize'

import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'

export type Props = {
  intl: IntlShape
  storageMultiplier?: number
  resource: 'memory' | 'storage'
  sizes: number[]
  defaultSize: number
  maxZones?: number
}

const Multipliers: FunctionComponent<Props> = ({
  storageMultiplier,
  resource,
  sizes,
  defaultSize,
  maxZones,
}) => (
  <Fragment>
    <EuiTitle size='m'>
      <h2>
        <FormattedMessage
          id='instance-configuration-multipliers.current-sizing'
          defaultMessage='Sizing'
        />
      </h2>
    </EuiTitle>

    <EuiSpacer size='s' />

    <EuiFlexGroup wrap={true}>
      <EuiFlexItem className='instanceConfiguration-gridItem'>
        <EuiFormLabel>
          <FormattedMessage
            id='instance-configuration-multipliers.primary-unit-label'
            defaultMessage='Primary sizing unit'
          />
        </EuiFormLabel>

        <EuiSpacer size='s' />

        <EuiText>
          <p>{getResourceLabel(resource)}</p>
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem className='instanceConfiguration-gridItem'>
        <EuiFormLabel>
          <FormattedMessage
            id='instance-configuration-multipliers.mem-storage-multiplier'
            defaultMessage='Memory to storage multiplier'
          />
        </EuiFormLabel>

        <EuiSpacer size='s' />

        <EuiText>
          <p>{storageMultiplier}</p>
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem className='instanceConfiguration-gridItem'>
        <EuiFormLabel>
          <FormattedMessage
            id='instance-configuration-multipliers.increments-label'
            defaultMessage='Available increments'
          />
        </EuiFormLabel>

        <EuiSpacer size='s' />

        <div>
          {sizes.map((size) => (
            <EuiBadge
              data-test-subj={`multiplier-size-${size}`}
              key={size}
              color={defaultSize === size ? `success` : `hollow`}
            >
              {prettySize(size)}
            </EuiBadge>
          ))}
        </div>
      </EuiFlexItem>

      {isNumber(maxZones) && (
        <EuiFlexItem className='instanceConfiguration-gridItem'>
          <EuiFormLabel>
            <FormattedMessage
              id='instance-configuration-multipliers.max-zones'
              defaultMessage='Max Zones'
            />
          </EuiFormLabel>

          <EuiSpacer size='s' />

          <EuiText>
            <p>{maxZones}</p>
          </EuiText>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  </Fragment>
)

export default injectIntl(Multipliers)
