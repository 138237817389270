/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import { EuiHorizontalRule, EuiPanel, EuiSpacer, withEuiTheme } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

import './topologyPanel.scss'

interface Props extends WithEuiThemeProps {
  headerContent: ReactNode
  footerContent?: ReactNode
  children: ReactNode
}

const TopologyPanel: FunctionComponent<Props> = ({
  headerContent,
  footerContent,
  children,
  theme,
}) => {
  const shadedAreaStyle = css({
    background: theme.euiTheme.colors.lightestShade,
    borderColor: theme.euiTheme.colors.lightShade,
  })
  return (
    <EuiPanel className='topologyPanel' paddingSize='none'>
      <EuiSpacer size='s' />
      <div className='topologyPanel-title'>{headerContent}</div>
      <EuiHorizontalRule className='instance-heading-rule' margin='xs' size='quarter' />

      <div className='topologyPanel-unshadedArea'>{children}</div>
      {footerContent && (
        <div className='topologyPanel-shadedArea' css={shadedAreaStyle}>
          {footerContent}
        </div>
      )}
    </EuiPanel>
  )
}

export default withEuiTheme(TopologyPanel)
