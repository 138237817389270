/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'formik'

import { EuiAccordion, EuiCallOut, EuiHorizontalRule, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../../../../../cui'
import ApiRequestExample from '../../../../../../components/ApiRequestExample'
import { messages } from '../authProviderMessages'
import {
  createActiveDirectoryConfigurationUrl,
  updateActiveDirectoryConfigurationUrl,
} from '../../../../../../lib/api/v1/urls'
import { hasPermission } from '../../../../../../lib/requiresPermission'
import Permission from '../../../../../../lib/api/v1/permissions'
import Feature from '../../../../../../lib/feature'
import { isFeatureActivated } from '../../../../../../store'

import {
  GeneralSettings,
  BindCredentials,
  GroupSearchSettings,
  RoleMapping,
  SubmitButtons,
  TestConfiguration,
  TrustedCaCerts,
  UserSearchSettings,
  YamlConfiguration,
} from './components'

import type { ActiveDirectorySecurityRealmRoleMappingRule } from '../../../../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../../../../types'
import type { FormikProps } from 'formik'
import type { FunctionComponent } from 'react'

import '../authProviders.scss'

export interface RoleMappingRule extends ActiveDirectorySecurityRealmRoleMappingRule {
  index: number
}

export interface ActiveDirectoryProviderFormShape {
  form_mode: 'create' | 'edit'
  id: string
  order?: number
  name: string
  urls: string[]
  load_balancing: 'failover' | 'dns_failover' | 'round_robin' | 'dns_round_robin'
  domain_name: string
  trusted_ca_cert_url: string
  certificate_url_truststore_type: 'jks' | 'PKCS12'
  certificate_url_truststore_password: string
  bind_anonymously: boolean
  bind_dn: string
  bind_password: string
  user_search: {
    base_dn: string
    scope: 'sub_tree' | 'one_level' | 'base'
    filter: string
  }
  user_dn_templates: string[]
  group_search: {
    base_dn: string
    scope: 'sub_tree' | 'one_level' | 'base'
  }
  advanced_settings_yaml: string
  test_credentials: {
    username: string
    password: string
  }
  role_mappings: {
    default_roles: string[]
    rules: RoleMappingRule[]
  }
}

interface Props extends FormikProps<ActiveDirectoryProviderFormShape> {
  onCancel: () => void
  submitRequest: AsyncRequestState
  disabled: boolean
  regionId: string
  realmId?: string
}

function renderValidationCallOut(isValid, submitCount) {
  if (isValid || submitCount === 0) {
    return null
  }

  return (
    <Fragment>
      <EuiSpacer />
      <EuiCallOut
        data-test-id='active-directory-provider-validation-problems'
        color='warning'
        title={<FormattedMessage {...messages.thereAreProblems} />}
      >
        <FormattedMessage {...messages.pleaseCorrectProblems} />
      </EuiCallOut>
    </Fragment>
  )
}

const ActiveDirectoryProviderForm: FunctionComponent<Props> = ({
  handleSubmit,
  values,
  values: { form_mode },
  setFieldValue,
  onCancel,
  submitRequest,
  isValid,
  submitCount,
  disabled,
  regionId,
  realmId,
}) => {
  const requiredPermission =
    form_mode === 'create'
      ? Permission.createActiveDirectoryConfiguration
      : Permission.updateActiveDirectoryConfiguration

  return (
    <Form className='defaultLineHeight externalAuthProviderForm'>
      <GeneralSettings values={values} />

      <EuiHorizontalRule />

      <TrustedCaCerts />

      <EuiHorizontalRule />

      <BindCredentials setFieldValue={setFieldValue} />

      <EuiHorizontalRule />

      <UserSearchSettings values={values} />

      <EuiHorizontalRule />

      <GroupSearchSettings />

      <EuiHorizontalRule />

      <RoleMapping />

      <EuiAccordion
        id='active-directory-provider-form-advanced-settings-accordion'
        className='authProvider-advancedSettingsAccordion'
        buttonContent={<FormattedMessage {...messages.advancedSettings} />}
      >
        <EuiHorizontalRule />

        <YamlConfiguration setFieldValue={setFieldValue} />
      </EuiAccordion>

      {isFeatureActivated(Feature.testActiveDirectoryConfiguration) && (
        <Fragment>
          <EuiHorizontalRule />
          <TestConfiguration />
        </Fragment>
      )}

      <EuiSpacer size='xl' />

      <SubmitButtons
        mode={form_mode}
        onSubmit={handleSubmit}
        inProgress={submitRequest.inProgress}
        onCancel={onCancel}
        disabled={!hasPermission(requiredPermission) || disabled}
      />

      {form_mode === 'create' ? (
        <ApiRequestExample
          method='POST'
          endpoint={createActiveDirectoryConfigurationUrl({ regionId })}
          body={values}
        />
      ) : (
        <ApiRequestExample
          method='PUT'
          endpoint={updateActiveDirectoryConfigurationUrl({ regionId, realmId: realmId! })}
          body={values}
        />
      )}

      {renderValidationCallOut(isValid, submitCount)}

      {submitRequest.error && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='danger'>{submitRequest.error}</CuiAlert>
        </Fragment>
      )}
    </Form>
  )
}

export default ActiveDirectoryProviderForm
