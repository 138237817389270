/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { replaceIn } from '@/lib/immutability-helpers'
import { MIGRATE_DEPLOYMENT_TEMPLATE } from '@/constants/actions'

import type { DeploymentUpdateRequest } from '@/lib/api/v1/types'
import type { AsyncAction } from '@/types'

export interface State {
  [deploymentId: string]: DeploymentUpdateRequest
}

interface Action extends AsyncAction<typeof MIGRATE_DEPLOYMENT_TEMPLATE> {
  meta: { deploymentId: string; templateId: string }
}

const initialState: State = {}

export default function stackDeploymentTemplateMigrationReducer(
  state: State = initialState,
  action: Action,
): State {
  if (action.type === MIGRATE_DEPLOYMENT_TEMPLATE) {
    if (!action.error && action.payload) {
      const { deploymentId, templateId } = action.meta
      return replaceIn(state, [createDescriptor(deploymentId, templateId)], action.payload)
    }
  }

  return state
}

export function getMigratedDeploymentPayload(
  state: State,
  deploymentId: string,
  templateId: string,
): DeploymentUpdateRequest | null {
  return state[createDescriptor(deploymentId, templateId)] || null
}

function createDescriptor(deploymentId: string, templateId: string): string {
  return `${deploymentId}/${templateId}`
}
