/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { getConfigForKey } from '@/store'
import HideDeploymentInUserconsole from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/HideDeploymentInUserconsole'
import HideDeployment from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/HideDeployment'
import DeleteDeployment from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/DeleteDeployment'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

const DeleteDeploymentButton: FunctionComponent<Props> = ({
  deployment,
  hideClusterInsteadOfDelete,
  hideClusterInsteadOfStop,
  onHideDeploymentInUserconsole,
}) => {
  const heroku = getConfigForKey(`APP_FAMILY`) === `heroku`

  if (heroku) {
    return null
  }

  if (hideClusterInsteadOfStop) {
    // userconsole
    return (
      <HideDeploymentInUserconsole
        key='HideDeploymentInUserconsole'
        deployment={deployment}
        onDelete={onHideDeploymentInUserconsole}
      />
    )
  }

  if (hideClusterInsteadOfDelete) {
    // adminconsole
    return <HideDeployment key='HideDeployment' deployment={deployment} />
  }

  // ECE
  return <DeleteDeployment key='DeleteDeployment' deployment={deployment} />
}

export default DeleteDeploymentButton
