/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiFieldText,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
} from '@elastic/eui'

import type { StepOwnProps, StepProps } from '../instanceConfigurationWizardTypes'

enum DetailsValidationErrors {
  MISSING_NAME = 'MISSING_NAME',
}

export default class ConfigurationDetailsStep extends Component<StepProps> {
  render() {
    const { instanceConfiguration, updateInstanceConfiguration, pristine } = this.props

    const { name, description } = instanceConfiguration
    const errors = validateDetailsStep({ instanceConfiguration } as StepOwnProps)

    return (
      <Fragment>
        <EuiTitle>
          <h3>
            <FormattedMessage
              id='instance-configuration-details.title'
              defaultMessage='Name your instance configuration'
            />
          </h3>
        </EuiTitle>

        <EuiText>
          <FormattedMessage
            id='instance-configuration-details.description'
            defaultMessage='Give your instance configuration a name and a description that will help you identify it later on.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderErrors()}

        <EuiFormRow
          label={
            <FormattedMessage
              id='instance-configuration-details.name-label'
              defaultMessage='Name'
            />
          }
        >
          <EuiFieldText
            isInvalid={!pristine && errors.includes(DetailsValidationErrors.MISSING_NAME)}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <FormattedMessage
              id='instance-configuration-details.description-label'
              defaultMessage='Description'
            />
          }
        >
          <EuiTextArea value={description} onChange={(e) => setDescription(e.target.value)} />
        </EuiFormRow>
      </Fragment>
    )

    function setName(newName) {
      updateInstanceConfiguration({ name: newName })
    }

    function setDescription(newDescription) {
      updateInstanceConfiguration({ description: newDescription })
    }
  }

  renderErrors = () => {
    const { pristine, instanceConfiguration } = this.props

    if (pristine) {
      return null
    }

    const errors = validateDetailsStep({ instanceConfiguration } as StepOwnProps)

    if (errors.length === 0) {
      return null
    }

    return (
      <Fragment>
        {errors.includes(DetailsValidationErrors.MISSING_NAME) && (
          <Fragment>
            <EuiCallOut
              title={
                <FormattedMessage
                  id='instance-configuration-details.requires-name'
                  defaultMessage='You must name your instance configuration before saving it'
                />
              }
              color='danger'
              iconType='cross'
            />
            <EuiSpacer size='m' />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export function validateDetailsStep({
  instanceConfiguration,
}: StepOwnProps): DetailsValidationErrors[] {
  const errors = [] as DetailsValidationErrors[]

  const { name } = instanceConfiguration

  if (!name || !name.trim()) {
    errors.push(DetailsValidationErrors.MISSING_NAME)
  }

  return errors
}
