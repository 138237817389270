/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { without } from 'lodash'

import type { EuiRadioGroupOption } from '@elastic/eui'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiPopover,
  EuiRadioGroup,
} from '@elastic/eui'

import { trustLevels as allTrustLevels } from '../../../lib/stackDeployments/selectors/crossClusterReplication'
import { trustLevelShortLabels } from '../../../lib/stackDeployments/trustRelationships'

import type { TrustLevel } from '../../../lib/stackDeployments/selectors/crossClusterReplication'

class TrustLevelSelectorLabel extends React.Component<
  { trustLevel: TrustLevel },
  { isPopoverOpen: boolean }
> {
  state = {
    isPopoverOpen: false,
  }

  render() {
    const { trustLevel } = this.props

    const text = <FormattedMessage {...trustLevelShortLabels[trustLevel]} />

    if (trustLevel === `all`) {
      const button = (
        <EuiButtonIcon
          color='primary'
          onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
          iconType='iInCircle'
          aria-label='more information'
        />
      )

      return (
        <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
          <EuiFlexItem style={{ whiteSpace: `nowrap` }}>{text}</EuiFlexItem>
          <EuiFlexItem>
            <EuiPopover
              anchorPosition='rightCenter'
              panelPaddingSize='s'
              button={button}
              isOpen={this.state.isPopoverOpen}
              closePopover={() => this.setState({ isPopoverOpen: false })}
            >
              <EuiText size='s' style={{ maxWidth: `18em` }}>
                <FormattedMessage
                  id='trustLevelSelector.allHelpText'
                  defaultMessage='This will include future deployments created within the organization you establish trust with.'
                />
              </EuiText>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return text
  }
}

interface Props {
  trustLevel: TrustLevel | null
  onChange: (value: TrustLevel) => void
  showNone?: boolean
}

const TrustLevelSelector: React.FunctionComponent<Props> = ({
  trustLevel,
  onChange,
  showNone = false,
}) => {
  const trustLevels = showNone ? allTrustLevels : without(allTrustLevels, `none`)

  const options: EuiRadioGroupOption[] = trustLevels.map((_trustLevel) => ({
    id: _trustLevel,
    value: _trustLevel,
    label: <TrustLevelSelectorLabel trustLevel={_trustLevel} />,
  }))

  return (
    <EuiRadioGroup
      data-test-id='select-trust-level'
      options={options}
      idSelected={trustLevel || undefined}
      onChange={onChange}
    />
  )
}

export default TrustLevelSelector
