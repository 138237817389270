/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiIconTip, EuiPanel } from '@elastic/eui'
import type { EuiPanelProps } from '@elastic/eui'

import type { FunctionComponent } from 'react'

import './styles.scss'

const AdminOnlyPanel: FunctionComponent<EuiPanelProps> = ({
  children,
  className,
  ...restProps
}) => (
  <EuiPanel
    className={`admin-panel ${className}`}
    hasBorder={true}
    hasShadow={false}
    {...restProps}
  >
    <div className='content-wrapper'>
      <div className='admin-icon'>
        <EuiIconTip
          type='eyeClosed'
          color='primary'
          content={
            <FormattedMessage
              id='admin-only-panel.message'
              defaultMessage='Only visible in admin console'
            />
          }
        />
      </div>

      {children}
    </div>
  </EuiPanel>
)

export default AdminOnlyPanel
