/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  standard: {
    id: `pricing-subscription-option.standard`,
    defaultMessage: `Standard`,
  },
  gold: {
    id: `pricing-subscription-option.gold`,
    defaultMessage: `Gold`,
  },
  platinum: {
    id: `pricing-subscription-option.platinum`,
    defaultMessage: `Platinum`,
  },
  enterprise: {
    id: `pricing-subscription-option.enterprise`,
    defaultMessage: `Enterprise`,
  },
})
