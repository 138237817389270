/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import history from '../../lib/history'
import { DELETE_RUNNER } from '../../constants/actions'
import { hostsUrl } from '../../lib/urlBuilder'
import { deleteRunnerUrl } from '../../lib/api/v1/urls'

import type { RegionId } from '../../types'

export function deleteRunnerAndRedirect(regionId: RegionId, runnerId: string) {
  return (dispatch) =>
    dispatch(deleteRunner(regionId, runnerId)).then(() => history.push(hostsUrl(regionId)))
}

function deleteRunner(regionId: RegionId, runnerId: string) {
  const url = deleteRunnerUrl({ regionId, runnerId })

  return asyncRequest({
    type: DELETE_RUNNER,
    method: `DELETE`,
    url,
    meta: { regionId, id: runnerId },
    crumbs: [regionId, runnerId],
  })
}
