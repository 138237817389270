/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import classNames from 'classnames'
import AceEditor from 'react-ace'

import { htmlIdGenerator, keys, EuiI18n } from '@elastic/eui'

import type { AriaAttributes, ReactElement } from 'react'
import type { IAceEditorProps } from 'react-ace'

import './legacyEuiCodeEditor.scss'

const DEFAULT_MODE = 'text'
const DEFAULT_THEME = 'textmate'

function setOrRemoveAttribute(
  element: HTMLTextAreaElement,
  attributeName: SupportedAriaAttribute,
  value: SupportedAriaAttributes[SupportedAriaAttribute],
) {
  if (value === null || value === undefined) {
    element.removeAttribute(attributeName)
  } else {
    element.setAttribute(attributeName, value)
  }
}

type SupportedAriaAttribute = 'aria-label' | 'aria-labelledby' | 'aria-describedby'
type SupportedAriaAttributes = Pick<AriaAttributes, SupportedAriaAttribute>

interface LegacyEuiCodeEditorProps extends SupportedAriaAttributes, Omit<IAceEditorProps, 'mode'> {
  width?: string
  height?: string
  onBlur?: IAceEditorProps['onBlur']
  onFocus?: IAceEditorProps['onFocus']
  isReadOnly?: boolean
  setOptions: IAceEditorProps['setOptions']
  cursorStart?: number
  'data-test-subj'?: string
  /**
   * Select the `brace` theme
   * The matching theme file must also be imported from `brace` (e.g., `import 'brace/theme/github';`)
   */
  theme?: IAceEditorProps['theme']

  /**
   * Use string for a built-in mode or object for a custom mode
   */
  mode?: IAceEditorProps['mode'] | unknown
  id?: string
}

interface LegacyEuiCodeEditorState {
  isHintActive: boolean
  isEditing: boolean
  name: string
}

// NOTE: this component was copied almost as-is with a few minor changes to make
// our linter happy from Eui.
class LegacyEuiCodeEditor extends Component<LegacyEuiCodeEditorProps, LegacyEuiCodeEditorState> {
  state: LegacyEuiCodeEditorState = {
    isHintActive: true,
    isEditing: false,
    name: htmlIdGenerator()(),
  }

  idGenerator = htmlIdGenerator()

  aceEditor: AceEditor | null = null

  editorHint: HTMLButtonElement | null = null

  static defaultProps = {
    setOptions: {},
  }

  componentDidMount(): void {
    if (this.isCustomMode()) {
      this.setCustomMode()
    }

    const { isReadOnly, id } = this.props

    const textareaProps: {
      id?: string
      readOnly?: boolean
    } = { id, readOnly: isReadOnly }

    const el = document.getElementById(this.state.name)

    if (el) {
      const textarea = el.querySelector('textarea')

      if (textarea) {
        Object.keys(textareaProps).forEach((key) => {
          if (textareaProps[key]) {
            textarea.setAttribute(`${key}`, textareaProps[key]!.toString())
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps: LegacyEuiCodeEditorProps): void {
    if (this.props.mode !== prevProps.mode && this.isCustomMode()) {
      this.setCustomMode()
    }
  }

  render(): ReactElement {
    const {
      width,
      height,
      isReadOnly,
      setOptions,
      cursorStart,
      mode = DEFAULT_MODE,
      'data-test-subj': dataTestSubj = 'codeEditorContainer',
      theme = DEFAULT_THEME,
      commands = [],
      ...rest
    } = this.props

    const classes = classNames('euiCodeEditorWrapper', {
      'euiCodeEditorWrapper-isEditing': this.state.isEditing,
    })

    const promptClasses = classNames('euiCodeEditorKeyboardHint', {
      'euiCodeEditorKeyboardHint-isInactive': !this.state.isHintActive,
    })

    let filteredCursorStart

    const options: IAceEditorProps['setOptions'] = { ...setOptions }

    if (isReadOnly) {
      // Put the cursor at the beginning of the editor, so that it doesn't look like
      // a prompt to begin typing.
      filteredCursorStart = -1

      Object.assign(options, {
        readOnly: true,
        highlightActiveLine: false,
        highlightGutterLine: false,
      })
    } else {
      filteredCursorStart = cursorStart
    }

    const prompt = (
      <button
        className={promptClasses}
        id={this.idGenerator('codeEditor')}
        ref={(hint) => {
          this.editorHint = hint
        }}
        onClick={this.startEditing}
        data-test-subj='codeEditorHint'
      >
        <p className='euiText'>
          {isReadOnly ? (
            <EuiI18n
              token='euiCodeEditor.startInteracting'
              default='Press Enter to start interacting with the code.'
            />
          ) : (
            <EuiI18n token='euiCodeEditor.startEditing' default='Press Enter to start editing.' />
          )}
        </p>

        <p className='euiText'>
          {isReadOnly ? (
            <EuiI18n
              token='euiCodeEditor.stopInteracting'
              default="When you're done, press Escape to stop interacting with the code."
            />
          ) : (
            <EuiI18n
              token='euiCodeEditor.stopEditing'
              default="When you're done, press Escape to stop editing."
            />
          )}
        </p>
      </button>
    )

    return (
      <div className={classes} style={{ width, height }} data-test-subj={dataTestSubj}>
        {prompt}

        <AceEditor
          // Setting a default, existing `mode` is necessary to properly initialize the editor
          // prior to dynamically setting a custom mode (https://github.com/elastic/eui/pull/2616)
          mode={this.isCustomMode() ? DEFAULT_MODE : (mode as string)} // https://github.com/securingsincity/react-ace/pull/771
          name={this.state.name}
          theme={theme}
          ref={this.aceEditorRef}
          width={width}
          height={height}
          onFocus={this.onFocusAce}
          onBlur={this.onBlurAce}
          setOptions={options}
          editorProps={{
            $blockScrolling: Infinity,
          }}
          cursorStart={filteredCursorStart}
          commands={[
            // Handles exiting edit mode in all cases except `isReadOnly`
            // Runs before `onKeydownAce`.
            {
              name: 'stopEditingOnEsc',
              bindKey: { win: 'Esc', mac: 'Esc' },
              exec: this.onEscToExit,
            },
            ...commands,
          ]}
          {...rest}
        />
      </div>
    )
  }

  aceEditorRef = (aceEditor: AceEditor | null): void => {
    if (aceEditor) {
      this.aceEditor = aceEditor
      const textbox = aceEditor.editor.textInput.getElement() as HTMLTextAreaElement
      textbox.tabIndex = -1
      textbox.addEventListener('keydown', this.onKeydownAce)
      setOrRemoveAttribute(textbox, 'aria-label', this.props['aria-label'])
      setOrRemoveAttribute(textbox, 'aria-labelledby', this.props['aria-labelledby'])
      setOrRemoveAttribute(textbox, 'aria-describedby', this.props['aria-describedby'])
    }
  }

  onEscToExit = (): void => {
    this.stopEditing()

    if (this.editorHint) {
      this.editorHint.focus()
    }
  }

  onKeydownAce = (event: KeyboardEvent): void => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault()
      event.stopPropagation()

      // Handles exiting edit mode when `isReadOnly` is set.
      // Other 'esc' cases handled by `stopEditingOnEsc` command.
      // Would run after `stopEditingOnEsc`.
      if (this.aceEditor !== null && !this.aceEditor.editor.completer && this.state.isEditing) {
        this.onEscToExit()
      }
    }
  }

  onFocusAce: IAceEditorProps['onFocus'] = (event, editor) => {
    this.setState({
      isEditing: true,
    })

    if (this.props.onFocus) {
      this.props.onFocus(event, editor)
    }
  }

  onBlurAce: IAceEditorProps['onBlur'] = (event, editor) => {
    this.stopEditing()

    if (this.props.onBlur) {
      this.props.onBlur(event, editor)
    }
  }

  startEditing = (): void => {
    this.setState({
      isHintActive: false,
    })

    if (this.aceEditor !== null) {
      this.aceEditor.editor.textInput.focus()
    }
  }

  stopEditing(): void {
    this.setState({
      isHintActive: true,
      isEditing: false,
    })
  }

  isCustomMode(): boolean {
    return typeof this.props.mode === 'object'
  }

  setCustomMode(): void {
    if (this.aceEditor !== null) {
      this.aceEditor.editor.getSession().setMode(this.props.mode)
    }
  }
}

export default LegacyEuiCodeEditor
