/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'

import { EuiPanel, EuiSpacer } from '@elastic/eui'

import CostsChart from '../components/CostsChart'
import DeploymentListUsageTable from '../components/DeploymentListUsageTable'
import ErrorCallout from '../components/ErrorCallout'
import { ViewByToBucketingStrategy } from '../constants'

import type { AllProps } from './types'

import '../styles.scss'

class BillingUsageOverview extends Component<AllProps> {
  componentDidMount() {
    this.fetchChartData()
    this.fetchUsageTableData()
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, viewBy } = this.props
    const { startDate: prevStartDate, endDate: prevEndDate, viewBy: prevViewBy } = prevProps

    if (!startDate.isSame(prevStartDate) || !endDate.isSame(prevEndDate)) {
      this.fetchChartData()
      this.fetchUsageTableData()
    }

    if (viewBy !== prevViewBy) {
      this.fetchChartData()
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderChart()}

        <EuiSpacer size='l' />

        {this.renderUsageTable()}
      </Fragment>
    )
  }

  renderChart = () => {
    const { costsCharts, costsChartsRequest, chartType, viewBy, isLoading } = this.props

    if (costsChartsRequest.error) {
      return <ErrorCallout retry={this.fetchChartData} data-test-id='costs-chart-error' />
    }

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='m'
        hasBorder={true}
        className='costs-chart-panel'
        data-test-id='costs-chart-panel'
      >
        <CostsChart
          data={costsCharts?.data || []}
          chartType={chartType}
          viewBy={viewBy}
          isLoading={costsChartsRequest.inProgress || isLoading}
        />
      </EuiPanel>
    )
  }

  renderUsageTable = () => {
    const {
      deployments,
      startDate,
      endDate,
      organizationId,
      onDeploymentSelected,
      deploymentsCosts,
      deploymentsCostsRequest,
      isLoading,
    } = this.props

    if (deploymentsCostsRequest.error) {
      return <ErrorCallout retry={this.fetchUsageTableData} data-test-id='usage-table-error' />
    }

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        className='billing-usage-responsive-panel'
        data-test-id='deployment-list-usage-table'
      >
        <DeploymentListUsageTable
          deploymentsCosts={deploymentsCosts}
          onDeploymentSelected={onDeploymentSelected}
          deployments={deployments}
          organizationId={organizationId}
          startDate={startDate}
          endDate={endDate}
          isLoading={deploymentsCostsRequest.inProgress || !deploymentsCosts || isLoading}
        />
      </EuiPanel>
    )
  }

  fetchUsageTableData = () => {
    const { startDate, endDate, fetchDeploymentsCosts } = this.props

    fetchDeploymentsCosts({
      from: startDate,
      to: endDate,
      id: 'customRange',
    })
  }

  fetchChartData = () => {
    const { fetchCostsCharts, startDate, endDate, viewBy } = this.props

    fetchCostsCharts({
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      bucketingStrategy: ViewByToBucketingStrategy[viewBy],
    })
  }
}

export default BillingUsageOverview
