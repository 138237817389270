/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import {
  fetchSnapshots,
  fetchSnapshotStatus,
  restoreSnapshot,
  resetRestoreSnapshot,
} from '../../../../actions/snapshots'
import {
  fetchSnapshotsRequest,
  getClusterSnapshotByName,
  getSnapshotRestore,
  getSnapshotStatus,
  getStackDeployment,
  restoreSnapshotRequest,
} from '../../../../reducers'
import schedule from '../../../../lib/schedule'
import { withStackDeploymentRouteParams } from '../../../StackDeploymentEditor'

import SnapshotDetails from './SnapshotDetails'

import type { ReduxState } from '../../../../types'
import type {
  StateProps,
  ScheduledStateProps,
  DispatchProps,
  ConsumerProps,
  ScheduledAllProps,
} from './types'

const terminalSnapshotStates = [`ABORTED`, `SUCCESS`, `FAILED`, `PARTIAL`]

const mapStateToProps = (
  state: ReduxState,
  {
    stackDeploymentId,
    match: {
      params: { snapshotName },
    },
  },
): ScheduledStateProps => {
  const deployment = getStackDeployment(state, stackDeploymentId)
  const snapshotStatus = getSnapshotStatus(state, stackDeploymentId, snapshotName)
  const isInProgress = snapshotStatus && !terminalSnapshotStates.includes(snapshotStatus.state)

  return {
    deployment,
    snapshotName,
    snapshot: getClusterSnapshotByName(state, stackDeploymentId, snapshotName),
    snapshotStatus,
    restore: getSnapshotRestore(state, stackDeploymentId),
    fetchSnapshotsRequest: fetchSnapshotsRequest(state, stackDeploymentId),
    restoreSnapshotRequest: restoreSnapshotRequest(state, stackDeploymentId),
    shouldRefresh: !snapshotStatus || isInProgress,
    isInProgress,
  }
}

const mapDispatchToProps = {
  fetchSnapshots,
  fetchSnapshotStatus,
  restoreSnapshot,
  resetRestoreSnapshot,
}

const scheduledComponent = schedule<ScheduledAllProps>(
  SnapshotDetails,
  ({ deployment, fetchSnapshotStatus: refreshSnapshot, snapshotName, shouldRefresh }) => {
    if (shouldRefresh && deployment) {
      refreshSnapshot({ deployment, snapshotName })
    }
  },
  [`canRefresh`, [`snapshotStatus`, `state`]],
)

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Elasticsearch snapshot details`, `component`)(scheduledComponent)),
)
