/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import type { Dimension } from '@/lib/api/v1/types'

interface GetPreviousMonthDates {
  from: string
  to: string
}

export const getPreviousMonthDates = (): GetPreviousMonthDates => ({
  from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
  to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
})

export const mapDimensions = (
  dimensions: Dimension[] = [],
): Partial<Record<Dimension['type'], Dimension['cost']>> =>
  dimensions.reduce((curr, { cost, type }) => {
    curr[type] = cost
    return curr
  }, {})
