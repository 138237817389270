/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { MessageDescriptor } from 'react-intl'
import type { UpdatedTlsChain } from './api/v1/types'

type CertificateServiceMessages = { [service in UpdatedTlsChain['service']]: MessageDescriptor }

const messages: CertificateServiceMessages = defineMessages({
  ui: {
    id: 'region-settings.tls-settings.cloud-ui',
    defaultMessage: 'Cloud UI and API',
  },
  proxy: {
    id: 'region-settings.tls-settings.proxy',
    defaultMessage: 'Proxy',
  },
  adminconsole: {
    id: 'region-settings.tls-settings.adminconsole',
    defaultMessage: 'Adminconsole',
  },
  internalca: {
    id: 'region-settings.tls-settings.internalca',
    defaultMessage: 'Internal CA',
  },
})

export function getCertificateServicePrettyName(tlsChain: UpdatedTlsChain): MessageDescriptor {
  return messages[tlsChain.service]
}
