/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'

import { EuiCallOut, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import {
  SIZING_UNIT_MEMORY,
  SIZING_UNIT_STORAGE,
} from '../../../../../lib/instanceConfigurations/multipliers'
import {
  MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB,
  MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB,
} from '../../../../../constants/fsMultiplier'

import SelectIncrementsSubstep from './SelectIncrementsSubstep'
import SelectMultipliersSubstep from './SelectMultipliersSubstep'
import SelectPrimaryUnitSubstep from './SelectPrimaryUnitSubstep'

import type { StepOwnProps, StepProps } from '../instanceConfigurationWizardTypes'

import './chooseMultipliersStep.scss'

enum MultiplierValidationErrors {
  OUT_OF_BOUNDS = 'OUT_OF_BOUNDS',
  EMPTY_INCREMENTS = 'EMPTY_INCREMENTS',
  MISSING_DEFAULT_VALUE = 'MISSING_DEFAULT_VALUE',
}

const messages = defineMessages({
  chooseInstanceTypes: {
    id: `instance-configuration-choose-multipliers.choose-node-types-first`,
    defaultMessage: `You need to choose the instance types in the previous step, first`,
  },
  noCompatibleTypes: {
    id: `instance-configuration-choose-multipliers.no-compatible-types`,
    defaultMessage: `Please choose a different set of instance and node types on the previous page`,
  },
  primaryUnit: {
    id: `instance-configuration-choose-multipliers.primary-unit-label`,
    defaultMessage: `Set the resource`,
  },
  selectMultiplier: {
    id: `instance-configuration-choose-multipliers.select-multipliers-label`,
    defaultMessage: `Select the resource multiplier`,
  },
  configureIncrements: {
    id: `instance-configuration-choose-multipliers.select-increments-label`,
    defaultMessage: `Set the available sizes`,
  },
})

export type Props = StepProps & {
  bounds: {
    min: number
    max: number
  } | null
  increments: number[]
  defaultValue: number
}

class ChooseMultipliersStep extends Component<Props> {
  render() {
    return (
      <Fragment>
        <EuiTitle>
          <h2>
            <FormattedMessage
              id='instance-configuration-choose-multipliers.title'
              defaultMessage='Set resources sizes'
            />
          </h2>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText>
          <FormattedMessage
            id='instance-configuration-choose-multipliers.description'
            defaultMessage='Adjust how memory and storage resources get sized relative to each other and set the available sizes. Size your instance configuration so that it will use the available memory and storage on your allocators efficiently. The size of an instance configuration also determines performance, as CPU resources get sized in lockstep. For example: A 32 GB instance configuration receives double the CPU resources of a 16 GB one. Keep in mind that very small sizes might not provide adequate performance for a use case.'
          />
        </EuiText>

        <EuiSpacer />

        {this.renderContent()}

        {this.renderErrors()}

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderContent() {
    const {
      intl: { formatMessage },
      bounds,
      instanceConfiguration,
      increments,
      defaultValue,
    } = this.props

    const missingInstanceType = !instanceConfiguration.instance_type

    if (missingInstanceType) {
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut
            color='danger'
            title={<FormattedMessage {...messages.chooseInstanceTypes} />}
            iconType='help'
          />
        </Fragment>
      )
    }

    if (bounds == null) {
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut data-test-id='no-bounds'>
            <FormattedMessage {...messages.noCompatibleTypes} />
          </EuiCallOut>
        </Fragment>
      )
    }

    const { sizingUnit, ratio } = instanceConfiguration

    return (
      <Fragment>
        <EuiTitle size='s'>
          <h3>{formatMessage(messages.primaryUnit)}</h3>
        </EuiTitle>

        <SelectPrimaryUnitSubstep
          sizingUnit={sizingUnit!}
          setSizingUnit={this.onChangeSizingUnit}
        />

        <EuiSpacer size='l' />

        <EuiTitle size='s'>
          <h3>{formatMessage(messages.selectMultiplier)}</h3>
        </EuiTitle>

        <SelectMultipliersSubstep ratio={ratio} onChange={this.onChangeRatio} />

        <EuiSpacer size='xl' />

        <EuiTitle size='s'>
          <h3>{formatMessage(messages.configureIncrements)}</h3>
        </EuiTitle>

        <SelectIncrementsSubstep
          increments={increments}
          defaultValue={defaultValue}
          onChangeDefaultValue={this.onChangeDefaultValue}
          bounds={bounds}
          onChangeIncrements={this.onChangeIncrements}
          ratio={ratio}
          sizingUnit={sizingUnit!}
        />
      </Fragment>
    )
  }

  renderErrors() {
    const { pristine, instanceConfiguration } = this.props

    if (pristine) {
      return null
    }

    const errors = validateMultipliers({ instanceConfiguration } as StepOwnProps)

    if (isEmpty(errors)) {
      return null
    }

    return (
      <Fragment>
        {errors.includes(MultiplierValidationErrors.OUT_OF_BOUNDS) && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='instance-configuration-choose-multipliers.out-of-bounds'
                  defaultMessage='Available sizes are out of bounds'
                />
              }
            />
          </Fragment>
        )}

        {errors.includes(MultiplierValidationErrors.EMPTY_INCREMENTS) && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='instance-configuration-choose-multipliers.empty-increments'
                  defaultMessage='You must specify at least one sizing option'
                />
              }
              data-test-id='no-sizing-options'
            />
          </Fragment>
        )}

        {errors.includes(MultiplierValidationErrors.MISSING_DEFAULT_VALUE) && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='instance-configuration-choose-multipliers.missing-default-value'
                  defaultMessage='You must choose the default sizing option'
                />
              }
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  onChangeSizingUnit = (sizingUnit) => this.props.updateInstanceConfiguration({ sizingUnit })

  onChangeRatio = (ratio: number) => this.props.updateInstanceConfiguration({ ratio })

  onChangeIncrements = (incrementValues) => {
    const {
      instanceConfiguration: { sizingUnit },
      updateInstanceConfiguration,
      defaultValue,
    } = this.props

    const unit = this.getUnit(sizingUnit)

    const changes = {
      [`${unit}Increments`]: incrementValues,
    }

    const changeDefaultValue = !incrementValues.includes(defaultValue)

    if (changeDefaultValue) {
      const [newDefaultValue = null] = incrementValues
      changes[`${unit}DefaultValue`] = newDefaultValue
    }

    updateInstanceConfiguration(changes)
  }

  onChangeDefaultValue = (defaultValue: number) => {
    const {
      instanceConfiguration: { sizingUnit },
      updateInstanceConfiguration,
    } = this.props

    if (sizingUnit === SIZING_UNIT_MEMORY) {
      updateInstanceConfiguration({ memoryDefaultValue: defaultValue })
    } else if (sizingUnit === SIZING_UNIT_STORAGE) {
      updateInstanceConfiguration({ storageDefaultValue: defaultValue })
    }
  }

  getUnit = (sizingUnit) => {
    if (sizingUnit === SIZING_UNIT_STORAGE) {
      return `storage`
    }

    return `memory`
  }
}

export default injectIntl(ChooseMultipliersStep)

export function validateMultipliers({
  instanceConfiguration,
}: StepOwnProps): MultiplierValidationErrors[] {
  const errors = [] as MultiplierValidationErrors[]

  const { memoryDefaultValue, memoryIncrements, ratio, storageDefaultValue, storageIncrements } =
    instanceConfiguration

  if (ratio > MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB || ratio < MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB) {
    errors.push(MultiplierValidationErrors.OUT_OF_BOUNDS)
  }

  if (isEmpty(memoryIncrements) && isEmpty(storageIncrements)) {
    errors.push(MultiplierValidationErrors.EMPTY_INCREMENTS)
  }

  if (!memoryDefaultValue && !storageDefaultValue) {
    errors.push(MultiplierValidationErrors.MISSING_DEFAULT_VALUE)
  }

  return errors
}
