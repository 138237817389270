/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get } from 'lodash'
import { connect } from 'react-redux'

import { fetchSnapshotRepository } from '../../../actions/snapshotRepositories'
import { fetchSnapshotRepositoryRequest, getSnapshotRepository } from '../../../reducers'
import { getConfigForKey } from '../../../selectors'

import EditSnapshotRepository from './EditSnapshotRepository'

const mapStateToProps = (state, { match: { params } }) => {
  const { repositoryId } = params
  const regionIdParam = get(params, [`regionId`], null)
  const defaultRegionId = getConfigForKey(state, `DEFAULT_REGION`)
  const regionId = regionIdParam || defaultRegionId

  return {
    regionId,
    repositoryId,
    repository: getSnapshotRepository(state, regionId, repositoryId),
    fetchSnapshotRepositoryRequest: fetchSnapshotRepositoryRequest(state, regionId, repositoryId),
  }
}

export default connect(mapStateToProps, {
  fetchSnapshotRepository,
})(EditSnapshotRepository)
