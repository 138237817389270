/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiText,
  EuiTextAlign,
  EuiTitle,
} from '@elastic/eui'

import { CuiRouterLinkButtonEmpty } from '../../../../../../cui'
import CuiElasticConsumptionUnits from '../../../../../../cui/formatters/CuiElasticConsumptionUnits'
import { numericDateTime } from '../../../../../../config/dates'

import type { FunctionComponent } from 'react'
import type { SimplifiedLineItem } from '../../../../../../lib/api/v1/types'

export interface Props {
  items: SimplifiedLineItem[]
}

const BalanceLineItems: FunctionComponent<Props> = ({ items }) => {
  const listItems = items.slice(0, 2)

  return (
    <div style={{ maxWidth: 420 }}>
      {listItems.map(({ ecu_balance, ecu_quantity, end }, index) => {
        const lineNumber = index + 1

        return (
          <EuiDescriptionList
            data-test-id='list-items-descriptions'
            key={lineNumber}
            className='balance-line-items'
          >
            <EuiDescriptionListTitle>
              <EuiTitle size='xxs'>
                <h3>
                  <FormattedMessage
                    id='balance-line-item.title'
                    defaultMessage='Order line {lineNumber}'
                    values={{ lineNumber }}
                  />
                </h3>
              </EuiTitle>
            </EuiDescriptionListTitle>

            <EuiDescriptionListDescription>
              <EuiDescriptionList
                type='column'
                listItems={[
                  {
                    title: (
                      <EuiText size='s'>
                        <FormattedMessage
                          id='balance-line-item.initial-balance'
                          defaultMessage='Credits'
                        />
                      </EuiText>
                    ),
                    description: (
                      <CuiElasticConsumptionUnits
                        unit='none'
                        value={ecu_quantity}
                        data-test-id='order-description-item-startBalance'
                      />
                    ),
                  },
                  {
                    title: (
                      <EuiText size='s'>
                        <FormattedMessage
                          id='balance-line-item.remaining-balance'
                          defaultMessage='Remaining balance'
                        />
                      </EuiText>
                    ),
                    description: (
                      <CuiElasticConsumptionUnits
                        unit='none'
                        value={ecu_balance}
                        data-test-id='order-description-item-endBalance'
                      />
                    ),
                  },
                  {
                    title: (
                      <EuiText size='s'>
                        <FormattedMessage
                          id='balance-line-item.expiration-date'
                          defaultMessage='Expiration date'
                        />
                      </EuiText>
                    ),
                    description: (
                      <FormattedDate
                        value={end}
                        data-test-id='order-description-item-expirationDate'
                        {...numericDateTime}
                      />
                    ),
                  },
                ]}
              />
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        )
      })}

      <EuiTextAlign textAlign='right' data-test-id='show-all-items-link'>
        <CuiRouterLinkButtonEmpty to='/billing/overview'>
          <FormattedMessage
            id='balance-line-items.show-all'
            defaultMessage='Show all order lines'
          />
        </CuiRouterLinkButtonEmpty>
      </EuiTextAlign>
    </div>
  )
}

export default BalanceLineItems
