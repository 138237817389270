/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchOrganizationPrepaidsRequest, getOrganizationPrepaids } from '@/reducers'
import { fetchOrganizationPrepaids } from '@/actions/billing'

import OrganizationPrepaids from './OrganizationPrepaids'

import type { StateProps, DispatchProps } from './types'

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchOrganizationPrepaids: (organizationId) =>
    dispatch(fetchOrganizationPrepaids({ organizationId })),
})

const mapStateToProps = (state): StateProps => ({
  getOrganizationPrepaids: (organizationId) => getOrganizationPrepaids(state, organizationId),
  fetchOrganizationPrepaidsRequest: fetchOrganizationPrepaidsRequest(state),
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationPrepaids)
