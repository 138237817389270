/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { capitalize } from 'lodash'
import { flow, compact, flatMap, sortBy, uniq } from 'lodash/fp'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import type { Search } from '@elastic/eui'
import { EuiBadge, EuiInMemoryTable } from '@elastic/eui'

import { deploymentHealthProblemDetailsUrl } from '@/lib/urlBuilder'

import { severityMappings, getSeverityColorMapping } from '../../../../lib/healthProblems/problems'

import type { ReactNode } from 'react'
import type { Problem } from '../../../../lib/healthProblems/problems'
import type { IntlShape } from 'react-intl'

const paginationConfig = {
  initialPageSize: 5,
  pageSizeOptions: [3, 5, 8],
}

const messages = defineMessages({
  severityFilterLabel: {
    id: `health-problems-table.severity`,
    defaultMessage: `Severity`,
  },
  impactsFilterLabel: {
    id: `health-problems-table.impactAreas`,
    defaultMessage: `Affected capabilities`,
  },
})

interface ProblemsTableProps {
  problems: Problem[]
  intl: IntlShape
  deploymentId: string
}

const ProblemsTable = ({ problems, intl, deploymentId }: ProblemsTableProps) => {
  const { formatMessage } = intl

  const availableImpactAreas = useMemo(
    () =>
      flow(
        // Flatten the problems array by impact area
        flatMap('impactAreas'),
        // Remove all undefined values if any
        compact,
        // Remove duplicate ocurrences
        uniq,
        // Finally, make sure its sorted
        sortBy((a) => a),
      )(problems),
    [problems],
  )

  const columns = [
    {
      field: 'severity',
      name: <FormattedMessage id='health-problems-table.severity' defaultMessage='Severity' />,
      width: '150px',
      sortable: true,
      render: (severity: string) => (
        <EuiBadge color={getSeverityColorMapping(severity)}>{capitalize(severity)}</EuiBadge>
      ),
    },
    {
      field: 'message',
      'data-test-subj': 'health-problem-table-cell-link',
      name: (
        <FormattedMessage id='health-problems-table.description' defaultMessage='Description' />
      ),
      truncateText: true,
      render: (message: ReactNode, problem: Problem) => {
        const hasImpacts = problem.impacts && problem.impacts?.length > 0
        const hasDiagnosis = problem.diagnosis && problem.diagnosis?.length > 0

        if (!hasImpacts && !hasDiagnosis) {
          return message
        }

        return (
          <Link to={deploymentHealthProblemDetailsUrl(deploymentId, problem.id)}>{message}</Link>
        )
      },
    },
    {
      field: 'impactAreas',
      name: (
        <FormattedMessage
          id='health-problems-table.impactAreas'
          defaultMessage='Affected capabilities'
        />
      ),
      width: '300px',
      render: (impactAreas: string[]) => (
        <span data-test-subj='health-problem-table-cell-impactAreas'>
          {impactAreas?.join(', ')}
        </span>
      ),
    },
  ]

  const search: Search = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'severity',
        name: formatMessage(messages.severityFilterLabel),
        multiSelect: false,
        options: Array.from(severityMappings.values()).map((value) => ({
          value,
          name: capitalize(value),
        })),
      },
      {
        type: 'field_value_selection',
        field: 'impactAreas',
        name: formatMessage(messages.impactsFilterLabel),
        multiSelect: false,
        options: availableImpactAreas.map((impactArea) => ({
          value: impactArea,
          name: impactArea,
        })),
      },
    ],
  }

  return (
    <EuiInMemoryTable
      data-test-subj='es-health-problems-table'
      items={problems}
      itemId='id'
      columns={columns}
      search={search}
      pagination={paginationConfig}
      sorting={true}
    />
  )
}

export const HealthProblemsTable = injectIntl(ProblemsTable)
