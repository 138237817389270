/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchMfaDevices, resetMfa } from '../../../actions/mfaDevices'
import { fetchMfaDevicesRequest, getMfaDevices, resetMfaRequest } from '../../../reducers'

import MfaManagement from './MfaManagement'

import type { SaasAuthMfaDeviceResponse } from '../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../types'

type StateProps = {
  mfaDevices?: SaasAuthMfaDeviceResponse[]
  fetchMfaDevicesRequest: AsyncRequestState
  resetMfaRequest: AsyncRequestState
}

type DispatchProps = {
  fetchMfaDevices: () => void
  resetMfa: () => void
}

type ConsumerProps = {
  userId: string
  email: string
}

const mapStateToProps = (state, { userId }: ConsumerProps) => ({
  mfaDevices: getMfaDevices(state, userId),
  fetchMfaDevicesRequest: fetchMfaDevicesRequest(state, userId),
  resetMfaRequest: resetMfaRequest(state, userId),
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps) => ({
  fetchMfaDevices: () => dispatch(fetchMfaDevices(userId)),
  resetMfa: () => dispatch(resetMfa(userId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MfaManagement)
