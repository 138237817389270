/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import ExternalLink from '../ExternalLink'

import type { FunctionComponent } from 'react'

type Props = {
  email: string
}

const UserEmailDashboardLink: FunctionComponent<Props> = ({ email }) => (
  <ExternalLink href={getDashboardHref(email)}>
    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <FormattedMessage
          id='user-email-dashboard-link.dashboard-label'
          defaultMessage='Email logs'
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiIcon type='popout' />
      </EuiFlexItem>
    </EuiFlexGroup>
  </ExternalLink>
)

function getDashboardHref(email: string) {
  return `https://8b5c7323f64e473893c8c3c945ea1b5c.us-east-1.aws.found.io/app/kibana#/dashboard/Mailgun-Overview?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,controlledBy:'1573015764436',disabled:!f,index:'mailgun-events-*',key:event-data.recipient.keyword,negate:!f,params:(query:'${email}'),type:phrase,value:'${email}'),query:(match:(event-data.recipient.keyword:(query:'${email}',type:phrase))))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:6,i:'8a25ab5e-9372-4cef-bf7f-649e93c3d2c3',w:14,x:0,y:0),id:'8d0d5180-0052-11ea-a92f-5d6c47c7aa4b',panelIndex:'8a25ab5e-9372-4cef-bf7f-649e93c3d2c3',type:visualization,version:'7.4.2'),(embeddableConfig:(),gridData:(h:18,i:'1439c7c3-31ea-4f37-99fe-0d70b527601b',w:17,x:14,y:0),id:'1fffb6d0-004f-11ea-a92f-5d6c47c7aa4b',panelIndex:'1439c7c3-31ea-4f37-99fe-0d70b527601b',type:visualization,version:'7.4.2'),(embeddableConfig:(),gridData:(h:18,i:'3175c8ff-00ba-4bf4-8bdf-e9e0b78b27b9',w:17,x:31,y:0),id:'6b6ff590-004e-11ea-a92f-5d6c47c7aa4b',panelIndex:'3175c8ff-00ba-4bf4-8bdf-e9e0b78b27b9',type:visualization,version:'7.4.2'),(embeddableConfig:(),gridData:(h:12,i:ad5b88b9-9e5b-41fb-94c9-f80076c68130,w:14,x:0,y:6),id:'5b646ac0-0051-11ea-a92f-5d6c47c7aa4b',panelIndex:ad5b88b9-9e5b-41fb-94c9-f80076c68130,type:visualization,version:'7.4.2'),(embeddableConfig:(),gridData:(h:16,i:'2c1bdb87-8354-4687-a744-7b07ac3c80db',w:48,x:0,y:18),id:ebf8bf00-0051-11ea-a92f-5d6c47c7aa4b,panelIndex:'2c1bdb87-8354-4687-a744-7b07ac3c80db',type:visualization,version:'7.4.2'),(embeddableConfig:(),gridData:(h:15,i:'465fbe97-1385-4a71-8931-59609c811484',w:48,x:0,y:34),id:'867e7e20-004d-11ea-a92f-5d6c47c7aa4b',panelIndex:'465fbe97-1385-4a71-8931-59609c811484',type:search,version:'7.4.2')),query:(language:kuery,query:''),timeRestore:!f,title:'Mailgun+Overview',viewMode:view)`
}

export default UserEmailDashboardLink
