/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingSpinner } from '@elastic/eui'

import {
  canEnableAutoscaling,
  isAutoscalingEnabledOnGet,
  isEveryResourceStarted,
} from '@/lib/stackDeployments/selectors'
import EnableAutoscalingModal from '@/components/Autoscaling/EnableAutoscalingModal'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from '../../DeploymentInfo/types'

const DeploymentAutoscalingStatus: FunctionComponent<Props> = ({
  deployment,
  deploymentTemplate,
  fetchDeploymentTemplatesRequest,
  esVersion,
}) => {
  if (fetchDeploymentTemplatesRequest.inProgress) {
    return <EuiLoadingSpinner size='s' />
  }

  if (isAutoscalingEnabledOnGet({ deployment })) {
    return <FormattedMessage id='deployment-table.yes' defaultMessage='Yes' />
  }

  if (
    !isAutoscalingEnabledOnGet({ deployment }) &&
    isEveryResourceStarted({ deployment }) &&
    canEnableAutoscaling({ deploymentTemplate, version: esVersion })
  ) {
    return (
      <EnableAutoscalingModal
        deployment={deployment}
        deploymentTemplate={deploymentTemplate}
        isButtonEmpty={true}
        buttonText={<FormattedMessage id='deployment-table.enable' defaultMessage='Enable' />}
      />
    )
  }

  return null
}

export default DeploymentAutoscalingStatus
