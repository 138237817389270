/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { groupBy, sortBy } from 'lodash'
import React, { Fragment } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTextColor } from '@elastic/eui'

import { getZkStatusColor } from './lib/regionHealth'

import type { RegionInfo } from '../../../lib/api/v1/types'
import type { FunctionComponent } from 'react'

type Props = {
  region: RegionInfo
}

const RegionZooKeeperStatus: FunctionComponent<Props> = ({ region }) => {
  const zkNodes = region.zookeeper_states.states
  const zkGroups = groupBy(zkNodes, (zkNode) => getZkStatusColor({ zkNode }))

  const zkGroupKeys = sortBy(Object.keys(zkGroups), [
    (color) => color === `danger`,
    (color) => color === `warning`,
    (color) => color === `success`,
  ])

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
      {zkGroupKeys.map((zkGroupKey, index) => {
        const zkGroup = zkGroups[zkGroupKey]

        return (
          <Fragment key={zkGroupKey}>
            {index === 0 || (
              <EuiFlexItem grow={false}>
                <EuiTextColor color='subdued'>/</EuiTextColor>
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='dot' color={zkGroupKey} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>{zkGroup.length}</EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </Fragment>
        )
      })}
    </EuiFlexGroup>
  )
}

export default RegionZooKeeperStatus
