/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { groupBy, isEmpty, sortBy, values, flatMap } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import { CuiTable } from '../../../cui'
import { getSliderPrettyName, getSliderIconType, getSliderWeight } from '../../../lib/sliders'

import InstanceDeploymentLink from './InstanceDeploymentLink'
import HealthStatus from './HealthStatus'
import InstanceHealth from './InstanceHealth'
import InstanceName from './InstanceName'
import InstanceCapacity from './InstanceCapacity'
import InstanceConfiguration from './InstanceConfiguration'
import InstanceVersion from './InstanceVersion'

import type { CuiTableColumn } from '../../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { InstanceRow } from './types'
import type { AllocatorInstance } from '../../../types'

type Props = {
  intl: IntlShape
  regionId: string
  instances?: AllocatorInstance[]
  unfilteredInstances: AllocatorInstance[]
  selectedInstances: AllocatorInstance[]
  selectInstances: (instances: AllocatorInstance[]) => void
  totalCount?: number
}

const AllocatorInstancesTable: FunctionComponent<Props> = ({
  intl: { formatMessage },
  instances,
  unfilteredInstances: unsortedUnfilteredInstances,
  regionId,
  selectedInstances,
  selectInstances,
  totalCount,
}) => {
  if (isEmpty(unsortedUnfilteredInstances)) {
    return null
  }

  const unfilteredInstances = sortBy(unsortedUnfilteredInstances, [`clusterId`, `instanceName`])

  const clusterGroups = groupBy(unfilteredInstances, `clusterId`)

  const unfilteredRows = unfilteredInstances.slice().map(getInstanceRow)

  // Add cluster header rows
  for (const clusterInstances of values(clusterGroups)) {
    if (clusterInstances.length === 1) {
      continue
    }

    const [firstClusterInstance] = clusterInstances
    const index = unfilteredRows.findIndex((row) => row.instance === firstClusterInstance)

    // cluster header row "takes over" instance row, for selection purposes
    const id = firstClusterInstance.id

    unfilteredRows.splice(index, 0, {
      id,
      instance: firstClusterInstance,
      clusterInstances,
      isClusterHeader: true,
      hasClusterHeader: false,
    })
  }

  const unsortedRows: InstanceRow[] = unfilteredRows.filter(isNotFilteredOutRow)

  const rows = sortBy(unsortedRows, [
    ({ instance }) => instance.clusterHealthy,
    ({ instance }) => instance.healthy,
    ({ instance }) => getSliderWeight(instance.kind),
    ({ instance }) => instance.capacity * -1,
  ])

  const columns: Array<CuiTableColumn<InstanceRow>> = [
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.instance-type'
          defaultMessage='Instance type'
        />
      ),
      render: ({ instance }) => (
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiIcon type={getSliderIconType({ sliderInstanceType: instance.kind })} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            {formatMessage(getSliderPrettyName({ sliderInstanceType: instance.kind }))}
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      sortKey: ({ instance }) => getSliderWeight(instance.kind),
    },
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.instance-healthy'
          defaultMessage='Instance health'
        />
      ),
      render: ({ instance, isClusterHeader }) => (
        <InstanceHealth instance={instance} isClusterHeader={isClusterHeader} />
      ),
      width: `125px`,
    },
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.instance-name'
          defaultMessage='Instance name'
        />
      ),
      render: ({ instance, clusterInstances, isClusterHeader }) => (
        <InstanceName
          instance={instance}
          clusterInstances={clusterInstances}
          isClusterHeader={isClusterHeader}
        />
      ),
      sortKey: ({ instance }) => instance.instanceName,
      width: `165px`,
    },
    {
      label: <FormattedMessage id='allocator-instances-table.capacity' defaultMessage='Capacity' />,
      render: ({ instance, isClusterHeader }) => (
        <InstanceCapacity instance={instance} isClusterHeader={isClusterHeader} />
      ),
      sortKey: ({ instance }) => instance.capacity,
      width: `100px`,
    },
    {
      label: <FormattedMessage id='allocator-instances-table.version' defaultMessage='Version' />,
      render: ({ instance, isClusterHeader }) => (
        <InstanceVersion
          regionId={regionId}
          instance={instance}
          isClusterHeader={isClusterHeader}
        />
      ),
      sortKey: ({ instance }) => instance.status.plans_info?.version,
      width: `100px`,
    },
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.instance-configuration-id'
          defaultMessage='Instance configuration'
        />
      ),
      render: ({ instance, isClusterHeader }) => (
        <InstanceConfiguration
          regionId={regionId}
          instance={instance}
          isClusterHeader={isClusterHeader}
        />
      ),
      sortKey: ({ instance }) => instance.status.instance_configuration_id,
    },
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.deployment-health'
          defaultMessage='Deployment health'
        />
      ),
      render: ({ instance: { plan, clusterHealthy } }) => (
        <HealthStatus plan={plan} healthy={clusterHealthy} />
      ),
      width: `125px`,
    },
    {
      label: (
        <FormattedMessage
          id='allocator-instances-table.deployment-name'
          defaultMessage='Deployment name'
        />
      ),
      render: ({ instance }) => <InstanceDeploymentLink regionId={regionId} instance={instance} />,
      sortKey: ({ instance }) => instance.clusterDisplayName,
    },
  ]

  return (
    <CuiTable<InstanceRow>
      columns={columns}
      rows={rows}
      selectedRows={unfilteredRows.filter((row) =>
        selectedInstances.some((instance) => instance.clusterId === row.instance.clusterId),
      )}
      onSelectionChange={(nextSelectedRows) => {
        const nextSelectedInstances = flatMap(nextSelectedRows, (row) => row.clusterInstances)
        selectInstances(nextSelectedInstances)
      }}
      isSelectableRow={(row) => row.isClusterHeader || !row.hasClusterHeader}
      getRowId={(row) => row.id}
      pageSize={50}
      totalCount={totalCount}
      showMatchCount={true}
      matchType={
        <FormattedMessage id='allocator-instances-table.match-type' defaultMessage='allocator' />
      }
      matchTypePlural={
        <FormattedMessage
          id='allocator-instances-table.match-type-plural'
          defaultMessage='allocators'
        />
      }
    />
  )

  function getInstanceRow(instance: AllocatorInstance): InstanceRow {
    const ourClusterInstances = unfilteredInstances.filter(
      (otherInstance) => otherInstance.clusterId === instance.clusterId,
    )

    const hasClusterHeader = ourClusterInstances.length > 1

    /* 1. The cluster header "takes over" an instance ID, for selection purposes
     * 2. Avoid ID clashes when there's a cluster with multiple instances in the allocator
     */
    const id = hasClusterHeader ? `${instance.id}_unselectable` : instance.id

    // If there's a cluster header, let it take over all the cluster instances
    const clusterInstances = hasClusterHeader ? [] : ourClusterInstances

    return {
      id,
      instance,
      clusterInstances,
      hasClusterHeader,
      isClusterHeader: false,
    }
  }

  function isNotFilteredOutRow(row: InstanceRow): boolean {
    return Boolean(instances && instances.find(matchesInstanceRow))

    function matchesInstanceRow(instance: AllocatorInstance): boolean {
      return row.instance.clusterId === instance.clusterId && row.instance.id === instance.id
    }
  }
}

export default injectIntl(AllocatorInstancesTable)
