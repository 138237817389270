/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchContainerSet } from '../../actions/containerSets'
import { fetchContainerSetRequest, getContainerSet } from '../../reducers'

import ContainerSetOverview from './ContainerSet'

import type { AsyncRequestState } from '../../types'
import type { ContainerSetDetails } from '../../lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  containerSetId: string
  containerSet: ContainerSetDetails | null
  fetchContainerSetRequest: AsyncRequestState
}

type DispatchProps = {
  fetchContainerSet: () => void
}

type QueryParams = { regionId: string; containerSetId: string }

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, containerSetId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  containerSetId,
  containerSet: getContainerSet(state, regionId, containerSetId),
  fetchContainerSetRequest: fetchContainerSetRequest(state, regionId, containerSetId),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId, containerSetId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchContainerSet: () => dispatch(fetchContainerSet({ regionId, containerSetId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerSetOverview)
