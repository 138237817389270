/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiCode } from '@elastic/eui'

import { CuiLink } from '@/cui'
import { hostAllocatorUrl } from '@/lib/urlBuilder'
import { getConfigForKey } from '@/store'

import type { FunctionComponent } from 'react'

const AllocatorLink: FunctionComponent<{ allocatorId: string; regionId: string }> = ({
  allocatorId,
  regionId,
}) => {
  const isAnyAdminconsole = getConfigForKey(`APP_NAME`) === `adminconsole`
  const renderedId = <EuiCode>{allocatorId}</EuiCode>

  if (!isAnyAdminconsole) {
    return renderedId
  }

  return (
    <CuiLink data-test-id='allocator-link' to={hostAllocatorUrl(regionId, allocatorId)}>
      {renderedId}
    </CuiLink>
  )
}

export const AllocatorLinkList: FunctionComponent<{ allocatorIds: string[]; regionId: string }> = ({
  allocatorIds,
  regionId,
}) => (
  <span>
    {allocatorIds
      .map((id) => <AllocatorLink allocatorId={id} regionId={regionId} />)
      .map((link, index) => (
        <Fragment key={index}>
          {index !== 0 && `, `}
          {link}
        </Fragment>
      ))}
  </span>
)

export default AllocatorLink
