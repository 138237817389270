/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { put } from '../../lib/ajax'
import { getLink } from '../../lib/links'
import { SAVE_CLUSTER_ACL } from '../../constants/actions'

import type { ElasticsearchCluster, ElasticsearchId, RegionId, ThunkAction } from '../../types'

// ts-unused-exports:disable-next-line
export const saveClusterAclRequest = (regionId: RegionId, clusterId: ElasticsearchId) => ({
  type: SAVE_CLUSTER_ACL,
  meta: { regionId, clusterId },
})

// ts-unused-exports:disable-next-line
export const saveClusterAclResponse = (
  regionId: RegionId,
  clusterId: ElasticsearchId,
  json: unknown,
) => ({
  type: SAVE_CLUSTER_ACL,
  payload: json,
  meta: { regionId, clusterId },
})

// ts-unused-exports:disable-next-line
export const saveClusterAclError = (
  regionId: RegionId,
  clusterId: ElasticsearchId,
  error: Error,
) => ({
  type: SAVE_CLUSTER_ACL,
  error: true,
  payload: error,
  meta: { regionId, clusterId },
})

function saveClusterAcl(cluster: ElasticsearchCluster, version: string, acl: unknown): ThunkAction {
  return (dispatch) => {
    const url = getLink(cluster, `cluster-acl`, {
      version,
    })
    const { regionId, id } = cluster

    dispatch(saveClusterAclRequest(regionId, id))
    return put(url, acl).then(
      (response) => dispatch(saveClusterAclResponse(regionId, id, response.body)),
      (error) => dispatch(saveClusterAclError(regionId, id, error)),
    )
  }
}

type SecurityConfig = ElasticsearchCluster['security']['config']

export function saveSecurityConfig(
  cluster: ElasticsearchCluster,
  securityConfig: SecurityConfig,
): ThunkAction {
  return (dispatch) => {
    const { security } = cluster
    const newAcl = createAclWithSecurityConfig(securityConfig, security.config.allowAnonymous)
    return dispatch(saveClusterAcl(cluster, String(security.config.version), newAcl))
  }
}

// To update the Security config we must send an entire ACL config
function createAclWithSecurityConfig(
  { users, usersPerRole, roles }: SecurityConfig,
  allowAnonymous: boolean,
) {
  const acl = {
    // This is a hack that we hard code this value. Currently we have to port the v1 response back into the v0.1 payload
    // when editing these values. The v1 response doesn't have this property.
    forward_auth_headers: true,
    shield: {
      allow_anonymous: allowAnonymous,
      esusers: {
        users,
        users_roles: usersPerRole,
        roles,
      },
    },
  }
  return acl
}
