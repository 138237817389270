/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { noop } from 'lodash'

import {
  EuiButton,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiToolTip,
} from '@elastic/eui'

import { CuiPermissibleControl } from '../../../../../../cui'
import Permission from '../../../../../../lib/api/v1/permissions'

import type { IntlShape } from 'react-intl'
import type { User } from '../../../../../../lib/api/v1/types'
import type { AsyncRequestState, RegionId } from '../../../../../../types'

const messages = defineMessages({
  deleteButton: {
    id: `manageUsers.userForm.deleteUserButton`,
    defaultMessage: `Delete`,
  },
  modalTitle: {
    id: `deleteUserButtonModal.title`,
    defaultMessage: `Delete user?`,
  },
  modalConfirm: {
    id: `deleteUserButtonModal.confirm`,
    defaultMessage: `Delete`,
  },
  modalCancel: {
    id: `deleteUserButtonModal.cancel`,
    defaultMessage: `Cancel`,
  },
  disabledTooltip: {
    id: `deleteUserButtonModal.disabledTooltip`,
    defaultMessage: `Current user cannot be deleted`,
  },
})

type DeleteRequestMetaData = { meta: { user_name: string } }

interface Props {
  enabled?: boolean
  deleteUserRequest: AsyncRequestState & DeleteRequestMetaData
  icon?: boolean
  intl: IntlShape
  isCurrentUser: (username: string) => boolean
  regionId: RegionId
  user: User
  deleteUser: (payload: User) => Promise<any>
  onClose?: () => void
}

interface State {
  isModalOpen: boolean
}

class DeleteUserButton extends Component<Props, State> {
  state: State = {
    isModalOpen: false,
  }

  render() {
    return (
      <Fragment>
        {this.renderButton()}
        {this.renderModal()}
      </Fragment>
    )
  }

  renderButton() {
    const {
      deleteUserRequest,
      enabled,
      icon,
      isCurrentUser,
      user,
      intl: { formatMessage },
    } = this.props
    const { inProgress, meta } = deleteUserRequest
    const isDeleting = inProgress && meta && meta.user_name === user.user_name
    const isCurrent = isCurrentUser(user.user_name)
    const isDisabled = !enabled || isCurrent
    let button = (
      <EuiButton
        isDisabled={isDisabled}
        isLoading={isDeleting}
        color='danger'
        onClick={this.showModal}
      >
        {formatMessage(messages.deleteButton)}
      </EuiButton>
    )

    if (icon === true) {
      if (isDeleting) {
        return <EuiLoadingSpinner size='m' />
      }

      button = (
        <EuiButtonIcon
          aria-label={formatMessage(messages.deleteButton)}
          disabled={isDisabled}
          iconType='trash'
          onClick={this.showModal}
          data-test-id='deleteLocalUser'
          color='danger'
        />
      )
    }

    return isCurrent ? (
      <EuiToolTip content={formatMessage(messages.disabledTooltip)}>{button}</EuiToolTip>
    ) : (
      <CuiPermissibleControl permissions={Permission.deleteUser}>{button}</CuiPermissibleControl>
    )
  }

  renderModal() {
    const { isModalOpen } = this.state

    if (!isModalOpen) {
      return null
    }

    const {
      intl: { formatMessage },
    } = this.props
    const { user } = this.props

    return (
      <EuiOverlayMask>
        <EuiConfirmModal
          buttonColor='danger'
          defaultFocusedButton={'confirm'}
          title={formatMessage(messages.modalTitle)}
          cancelButtonText={formatMessage(messages.modalCancel)}
          confirmButtonText={formatMessage(messages.modalConfirm)}
          onCancel={this.closeModal}
          onConfirm={this.confirmAction}
        >
          <FormattedMessage
            id='deleteUserButtonModal.body'
            defaultMessage='This will remove permanently remove access for {user}.'
            values={{ user: <strong>{user.user_name}</strong> }}
          />
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  showModal = () => {
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  confirmAction = () => {
    const { deleteUser, onClose = noop, user } = this.props
    this.closeModal()
    return deleteUser(user).then(onClose)
  }
}

export default injectIntl(DeleteUserButton)
