/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { noop } from 'lodash'

import asyncRequest from '../../../actions/asyncRequests'
import { CREATE_SECURITY_CLUSTER, FETCH_SECURITY_CLUSTER } from '../constants/actions'
import { createSecurityDeploymentUrl, getSecurityDeploymentUrl } from '../../../lib/api/v1/urls'

import type { RegionId, ThunkDispatch } from '../../../types'
import type { SecurityDeploymentCreateRequest } from '../../../lib/api/v1/types'

export function fetchSecurityCluster(regionId: RegionId) {
  return (dispatch: ThunkDispatch) => {
    const url = getSecurityDeploymentUrl({ regionId })

    return (
      dispatch(
        asyncRequest({
          url,
          type: FETCH_SECURITY_CLUSTER,
          meta: { regionId },
          crumbs: [regionId],
          handleUnauthorized: true,
        }),
      )
        // The call returns 404 if a cluster isn't configured, which causes an exception
        // to be thrown in the AJAX code. Swallow exceptions to stop this - the
        // request state will still be available
        .catch(noop)
    )
  }
}

export function createSecurityCluster(regionId: RegionId) {
  return (dispatch: ThunkDispatch) => {
    const url = createSecurityDeploymentUrl({ regionId })

    const payload: SecurityDeploymentCreateRequest = {
      name: 'security',
    }

    return dispatch(
      asyncRequest({
        url,
        payload,
        method: 'POST',
        type: CREATE_SECURITY_CLUSTER,
        meta: { regionId },
        crumbs: [regionId],
      }),
    ).then(() =>
      // The create call returns no payload, so fetch immediately to get something we can work with
      dispatch(fetchSecurityCluster(regionId)),
    )
  }
}
