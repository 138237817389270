/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { updateEsMetadata } from '@/actions/stackDeployments/metadata'

import { setDeploymentResourceMetadataRequest } from '../../../reducers'
import { isFeatureActivated } from '../../../selectors'
import Feature from '../../../lib/feature'

import CpuHardLimit from './CpuHardLimit'

import type { SliderInstanceType } from '@/types'
import type { StateProps, DispatchProps } from './CpuHardLimit'

const mapStateToProps = (state): StateProps => ({
  setDeploymentResourceMetadataRequest: (
    deploymentId: string,
    resourceType: SliderInstanceType,
    resourceRefId: string,
  ) => setDeploymentResourceMetadataRequest(state, deploymentId, resourceType, resourceRefId),
  canToggleCpuHardLimit: isFeatureActivated(state, Feature.toggleCpuHardLimit),
})

const mapDispatchToProps: DispatchProps = {
  updateMetadata: updateEsMetadata,
}

export default connect(mapStateToProps, mapDispatchToProps)(CpuHardLimit)
