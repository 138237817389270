/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import { CuiAlert, withErrorBoundary } from '../../../../cui'
import DangerButton from '../../../DangerButton'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { AsyncRequestState } from '../../../../types'

interface Props extends WrappedComponentProps {
  retryInvoices: () => void
  retryInvoicesRequest: AsyncRequestState
}

const messages = defineMessages({
  retryInvoices: {
    id: `organization.organization-overview.retry-invoices-button.button-text`,
    defaultMessage: `Retry past due invoices`,
  },
})

const RetryInvoicesButton: FunctionComponent<Props> = ({
  intl: { formatMessage },
  retryInvoices,
  retryInvoicesRequest,
}) => {
  const actionText = formatMessage(messages.retryInvoices)

  return (
    <Fragment>
      <DangerButton
        buttonType={EuiButtonEmpty}
        size='s'
        color='primary'
        fill={false}
        onConfirm={retryInvoices}
        spin={retryInvoicesRequest.inProgress}
      >
        {actionText}
      </DangerButton>

      {retryInvoicesRequest.error && (
        <Fragment>
          <EuiSpacer size='m' />

          <CuiAlert type='error'>{retryInvoicesRequest.error}</CuiAlert>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withErrorBoundary(injectIntl(RetryInvoicesButton))
