/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'

import { euiBackgroundColor, transparentize } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

interface WithTheme {
  theme: WithEuiThemeProps['theme']
}

export const defaultFlexGroupStyle = ({ theme }: WithTheme) =>
  css({
    margin: `-${theme.euiTheme.size.base}!important`,
    borderRadius: theme.euiTheme.border.radius.medium,
    padding: theme.euiTheme.size.base,
    backgroundColor: 'transparent',
    gap: theme.euiTheme.size.base,
  })

export const disabledFlexGroupStyle = ({ theme }: WithTheme) =>
  css({
    backgroundColor: transparentize(theme.euiTheme.colors.lightShade, 0.15),
  })

export const selectedFlexGroupStyle = ({ theme }: WithTheme) =>
  css({
    backgroundColor:
      theme.colorMode === 'DARK'
        ? theme.euiTheme.colors.lightShade
        : transparentize(euiBackgroundColor(theme, 'primary'), 0.37),
    border: 0,
  })

export const selectedRoleStyle = ({ theme }: WithTheme) =>
  css({
    borderColor: theme.euiTheme.colors.primary,
  })

export const tableCellTextStyle = css({
  '.euiTableCellContent__text': {
    flexBasis: '100%',
  },
})

export const rolesInnerTitleCss = ({ theme }: WithTheme) =>
  css({
    color: theme.euiTheme.colors.title,
    fontWeight: theme.euiTheme.font.weight.bold,
  })

export const radioPanelWidth = css({
  '.euiRadio.euiRadio--noLabel': {
    top: '35%',
  },
})
