/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { fetchRootIfNeeded } from '../../../../actions/root'
import { fetchCurrentUser, resetFetchCurrentUser } from '../../../../actions/currentUser'
import { fetchEolStatusIfNeeded } from '../../../../actions/eolStatus'
import {
  getCurrentUser,
  fetchRootRequest,
  isEulaAccepted,
  getRoot,
  fetchCurrentUserRequest,
} from '../../../../reducers'
import { getConfig, getConfigForKey, isFeatureActivated } from '../../../../selectors'
import { createDeploymentUrl, deploymentGettingStartedUrl } from '../../../../lib/urlBuilder'
import Feature from '../../../../lib/feature'

import App from './App'

import type { AsyncRequestState, CloudAppType, RootConfig, ThunkDispatch } from '../../../../types'
import type { AppConfig } from '../../../../../config/types'
import type { User } from '../../../../lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

interface StateProps {
  pollingInterval: number
  config: AppConfig
  root: RootConfig
  currentUser: User | null
  requireEula: boolean
  isEulaAccepted: boolean
  fetchCurrentUserRequest: AsyncRequestState
  cloudAppType: CloudAppType
  isRbacEnabled: boolean
  hasRbacPermissions: boolean
  isCreateUrl: boolean
  isGettingStartedUrl: boolean
  fetchRootRequest: AsyncRequestState
}

interface DispatchProps {
  fetchCurrentUser: () => Promise<any>
  fetchRootIfNeeded: (config: AppConfig) => Promise<any>
  resetFetchCurrentUser: () => void
  fetchEolStatusIfNeeded: () => void
}

type ConsumerProps = RouteComponentProps

const mapStateToProps = (state, { location, match: { params } }): StateProps => {
  const { deploymentId } = params

  return {
    requireEula: isFeatureActivated(state, Feature.eula),
    isEulaAccepted: isEulaAccepted(state),
    config: getConfig(state),
    root: getRoot(state),
    currentUser: getCurrentUser(state),
    fetchRootRequest: fetchRootRequest(state),
    pollingInterval: getConfigForKey(state, `POLLING_INTERVAL`),
    fetchCurrentUserRequest: fetchCurrentUserRequest(state),
    cloudAppType: getConfigForKey(state, `CLOUD_UI_APP`),
    hasRbacPermissions: isFeatureActivated(state, Feature.rbacPermissions),
    isRbacEnabled: isFeatureActivated(state, Feature.manageRbac),
    isCreateUrl: location.pathname === createDeploymentUrl(),
    isGettingStartedUrl: location.pathname === deploymentGettingStartedUrl(deploymentId),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchRootIfNeeded: (config: AppConfig) => dispatch(fetchRootIfNeeded(config)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  resetFetchCurrentUser: () => dispatch(resetFetchCurrentUser()),
  fetchEolStatusIfNeeded: () => dispatch(fetchEolStatusIfNeeded()),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps, mapDispatchToProps)(App),
)
