/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchDeployments } from '@/actions/stackDeployments'

import { searchStackDeploymentsRequest, getStackDeploymentsFromSearch } from '../../reducers'

import RedirectToDeploymentResource from './RedirectToDeploymentResource'

import type { StateProps, DispatchProps, ConsumerProps } from './RedirectToDeploymentResource'
import type { ReduxState, ThunkDispatch } from '../../types'

const queryIdPrefix = 'redirect-to-deployment-resource-'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId, resourceId, resourceType, ...splat },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  searchResults: getStackDeploymentsFromSearch(state, `${queryIdPrefix}${resourceId}`),
  searchRequest: searchStackDeploymentsRequest(state, `${queryIdPrefix}${resourceId}`),
  resourceId,
  resourceType,
  splat: Object.values(splat)
    .filter((v) => v)
    .join('/'),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { resourceId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  searchDeployments: (query) =>
    dispatch(searchDeployments({ queryId: `${queryIdPrefix}${resourceId}`, query })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectToDeploymentResource)
