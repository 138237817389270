/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AsyncRequestState } from '@/types'
import type { CostsOverview } from '@/lib/api/v1/types'

export enum Tab {
  Current = 'current',
  Previous = 'previous',
}

export interface ConsumerProps {
  organizationId: string
}

interface FetchAccountCostsOverviewIfNeededParams {
  from?: string
  to?: string
}

export interface DispatchProps {
  fetchAccountCostsOverviewIfNeeded: (arg?: FetchAccountCostsOverviewIfNeededParams) => void
}

export interface StateProps {
  costOverviewCurrentMonth?: CostsOverview
  costOverviewPreviousMonth?: CostsOverview
  costOverviewCurrentMonthRequest: AsyncRequestState
  costOverviewPreviousMonthRequest: AsyncRequestState
}

export type AllProps = StateProps & DispatchProps & ConsumerProps
