/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FETCH_CERTIFICATE_AUTHORITY } from '../../constants/actions'

import type { CertificateAuthority } from '../../lib/api/v1/types'

type FetchAction = {
  type: typeof FETCH_CERTIFICATE_AUTHORITY
  error?: boolean
  payload?: CertificateAuthority
  meta: { deploymentId: string }
}

type Action = FetchAction

export type State = {
  [deploymentId: string]: CertificateAuthority | null
}

const initialState: State = {}

export default function certificateAuthorityReducer(
  state: State = initialState,
  action: Action,
): State {
  if (action.type === FETCH_CERTIFICATE_AUTHORITY) {
    if (!action.error && action.payload) {
      const { deploymentId } = action.meta

      return {
        ...state,
        [deploymentId]: action.payload,
      }
    }
  }

  return state
}

export const getCertificateAuthority = (
  state: State,
  deploymentId: string,
): CertificateAuthority | null => state[deploymentId]
