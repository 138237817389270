/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { WithEuiThemeProps } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'

export type DeploymentRow = {
  id: string
  deploymentName: string
  deploymentRole: string
  selected: boolean
}

export enum Tabs {
  ALL = 'all',
  SPECIFIC = 'specific',
}

export enum DeploymentRoles {
  ADMIN = 'Admin',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
}

export type StateProps = {
  selectedTabId: Tabs
  selectedItems: DeploymentRow[]
  selectedDeploymentRole: DeploymentRoles
  isOrganizationOwnerSelected: boolean
  isOrganizationBillingSelected: boolean
  isDeploymentAccessSelected: boolean
}

export type AllProps = WrappedComponentProps

export interface PropsWithTheme extends AllProps, WithEuiThemeProps {}
