/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt } from '@elastic/eui'

import type { FunctionComponent } from 'react'

export const HealthyDeployment: FunctionComponent = () => (
  <EuiEmptyPrompt
    data-test-subj='health-status-healthy-deployment'
    iconType='checkInCircleFilled'
    iconColor='success'
    title={
      <h2>
        <FormattedMessage
          id='deployment-health-status.is-healthy-title'
          defaultMessage='Your deployment is healthy'
        />
      </h2>
    }
    body={
      <p>
        <FormattedMessage
          id='deployment-summary.is-healthy-subtitle'
          defaultMessage='There are no issues.'
        />
      </p>
    }
  />
)
