/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, find, intersection, remove } from 'lodash'

import { getSupportedSliderInstanceTypes } from '../sliders'
import { lt, satisfies } from '../semver'
import { getAllowedPluginsForVersions } from '../plugins'
import { getMinimumMemoryFromPlan, filterIngestPluginsOnMemory } from '../deployments/plan'
import { getConfigForKey } from '../../store'
import { mergeDeep } from '../immutability-helpers'

import { sanitizeForAutoscaling } from './autoscaling'
import {
  isSizedSliderResourcePayload,
  isUsingNodeRoles,
  getFirstEsCluster,
  getFirstEsClusterFromGet,
  supportsFrozenTier,
  getEsPlan,
} from './selectors'
import { normalizeNodeRoles, setNodeRoleInPlace } from './nodeRoles'
import { ensureCorrectIndexManagementSettings } from './indexManagement'
import { ensureDedicatedMasterAwareTopology } from './dedicatedMasters'

import type {
  DeploymentCreateRequest,
  DeploymentClaimRequest,
  DeploymentTemplateInfoV2,
  DeploymentUpdateRequest,
  ElasticsearchClusterSettings,
  ElasticsearchPayload,
  InstanceConfiguration,
  StackVersionConfig,
  DeploymentSearchResponse,
} from '../api/v1/types'
import type {
  AnyPayload,
  Region,
  SliderInstanceType,
  StackDeploymentCreateRequest,
  StackDeploymentUpsertRequest,
  RegionId,
} from '../../types'
import type { DeepPartial } from '../ts-essentials'

export function getCreatePayload({
  region,
  editorState,
  stackVersions,
  filterIngestPlugins,
}: {
  region: Region
  editorState: StackDeploymentCreateRequest
  stackVersions?: StackVersionConfig[] | null
  filterIngestPlugins?: boolean
}): DeploymentCreateRequest | null {
  const { deployment: editorDeployment, deploymentTemplate } = editorState

  if (!deploymentTemplate) {
    return null
  }

  const instanceConfigurations = deploymentTemplate.instance_configurations!

  const deployment = cloneDeep(editorDeployment)

  if (Array.isArray(deployment.resources!.elasticsearch)) {
    for (const cluster of deployment.resources!.elasticsearch) {
      sanitizeMasterNodeTypes({ region, deploymentTemplate, cluster, instanceConfigurations })
      sanitizeIngest({
        cluster,
        stackVersions: stackVersions!,
        instanceConfigurations,
        filterIngestPlugins,
      })
    }
  }

  // ensure we don't send both ILM and Index Curation settings
  ensureCorrectIndexManagementSettings({
    deployment,
    deploymentTemplate,
  })

  filterUnsizedResources({ deployment })
  filterUnsupportedEsTiers({ deployment })
  normalizeNodeRoles({ deployment })
  removeIllegalProperties({ deployment })
  sanitizeForAutoscaling({ deployment })
  filterObservability({ deployment, deploymentTemplate })

  return deployment
}
export function getClaimDeploymentRequestPayload({
  deployment,
}: {
  deployment: DeploymentCreateRequest
}): DeploymentClaimRequest | null {
  const esResource = getFirstEsCluster({ deployment })

  if (!esResource) {
    return null
  }

  const { region } = esResource
  const esPlan = getEsPlan({ deployment })

  if (!esPlan) {
    return null
  }

  const { deployment_template, elasticsearch } = esPlan
  const hotDataTier = find(esPlan.cluster_topology, { id: 'hot_content' })

  return {
    deployment_template_id: deployment_template?.id || '',
    memory: hotDataTier?.size?.value || 0,
    provider: '',
    region,
    version: elasticsearch.version || '',
    zones: hotDataTier?.zone_count || 0,
    deployment_alias: deployment.name,
  }
}

export function filterUnsizedResources({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): void {
  const { resources } = deployment

  if (!resources) {
    return
  }

  const sliderInstanceTypes = getSupportedSliderInstanceTypes()
  const resourceTypes = intersection(sliderInstanceTypes, Object.keys(resources))

  for (const resourceType of resourceTypes) {
    // ensure we don't submit empty resources
    resources[resourceType] = resources[resourceType].filter((resource) =>
      isSizedSliderResourcePayload({ resource, resourceType }),
    )
  }
}

export function filterUnsupportedEsTiers({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): void {
  const esResource = getFirstEsCluster({ deployment })

  if (!esResource) {
    return
  }

  const esVersion = esResource.plan.elasticsearch.version

  if (!esVersion) {
    return
  }

  if (!supportsFrozenTier({ version: esVersion })) {
    remove(esResource.plan.cluster_topology, ({ id }) => id === `frozen`)
  }
}

function filterObservability({
  deployment,
  deploymentTemplate,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentTemplate: DeploymentTemplateInfoV2
}): void {
  if (!deployment.resources) {
    return
  }

  const esResource = getFirstEsCluster({ deployment })

  if (!esResource) {
    return
  }

  const esVersion = esResource.plan.elasticsearch.version

  if (!esVersion) {
    return
  }

  const integrationsServerSupportedRange = getConfigForKey(
    `INTEGRATIONS_SERVER_SUPPORTED_VERSION_RANGE`,
  )
  const apmSupportedRange = getConfigForKey(`APM_SUPPORTED_VERSION_RANGE`)

  if (!integrationsServerSupportedRange) {
    delete deployment.resources.integrations_server

    return
  }

  if (satisfies(esVersion, integrationsServerSupportedRange)) {
    // If there's overlap between integrations server and APM supported range, check the deployment template
    // to see if it supports integrations server. If it doesn't, then use APM. If it does, then use Integrations Server
    if (
      apmSupportedRange &&
      satisfies(esVersion, apmSupportedRange) &&
      !deploymentTemplate.deployment_template.resources?.integrations_server
    ) {
      delete deployment.resources.integrations_server

      return
    }

    // If apm doesn't have an overlap, then it's not supported and should be deleted
    delete deployment.resources.apm
  } else {
    delete deployment.resources.integrations_server
  }
}

export function removeIllegalProperties({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): void {
  const esResource = getFirstEsCluster({ deployment })

  if (!esResource) {
    return
  }

  // remove node_roles-required sliders if this deployment either isn't using or
  // doesn't support the property
  if (!isUsingNodeRoles({ deployment })) {
    remove(esResource.plan.cluster_topology, ({ id }) => id === `cold`)
    remove(esResource.plan.cluster_topology, ({ id }) => id === `frozen`)
  }

  // remove `topology_element_control`
  esResource.plan.cluster_topology.forEach((topologyElement) => {
    delete topologyElement.topology_element_control
  })
}

function sanitizeMasterNodeTypes({
  region,
  deploymentTemplate,
  cluster,
  instanceConfigurations,
}: {
  region: Region
  deploymentTemplate: DeploymentTemplateInfoV2
  cluster: ElasticsearchPayload
  instanceConfigurations: InstanceConfiguration[]
}) {
  // Sanitize master node types.
  cluster.plan.cluster_topology = ensureDedicatedMasterAwareTopology({
    region,
    deploymentTemplate,
    cluster,
    instanceConfigurations,
    onlySized: false,
  })
}

function sanitizeIngest({
  cluster,
  stackVersions,
  instanceConfigurations,
  filterIngestPlugins,
}: {
  cluster: ElasticsearchPayload
  instanceConfigurations: InstanceConfiguration[]
  stackVersions: StackVersionConfig[]
  filterIngestPlugins?: boolean
}) {
  const { plan } = cluster
  const nodeConfigurations = plan.cluster_topology
  const esVersion = plan.elasticsearch.version

  if (!esVersion) {
    return
  }

  // Ingest plugin versioning awareness
  if (filterIngestPlugins) {
    const minimumMemory = getMinimumMemoryFromPlan(plan, instanceConfigurations)
    const allowedPlugins = getAllowedPluginsForVersions({ plan, versions: stackVersions })

    const plugins = plan.elasticsearch.enabled_built_in_plugins || []

    const nextPlugins = filterIngestPluginsOnMemory({
      plugins,
      allowedPlugins,
      minimumMemory,
      esVersion,
    })

    plan.elasticsearch.enabled_built_in_plugins = nextPlugins
  }

  const isLegacyStack = lt(esVersion, `5.0.0`)

  // Disable `ingest` for clusters <= 2.x, which don't support that node type
  if (isLegacyStack) {
    nodeConfigurations.forEach((topologyElement) => {
      setNodeRoleInPlace({ topologyElement, role: `ingest`, value: false })
    })
  }
}

export function getDeploymentNameSetter({
  onChange,
}: {
  onChange: (
    changes: DeepPartial<StackDeploymentUpsertRequest>,
    settings?: { shallow?: boolean },
  ) => void
}) {
  return (name: string) => onChange({ deployment: { name } })
}

export function getDeploymentVersionSetter({
  editorState,
  onChange,
}: {
  editorState: StackDeploymentUpsertRequest
  onChange: (
    changes: DeepPartial<StackDeploymentUpsertRequest>,
    settings?: { shallow?: boolean },
  ) => void
}) {
  return (version: string) => {
    const nextState = cloneDeep(editorState)

    nextState._joltVersion = version

    onChange(nextState)
  }
}

export function setDeploymentVersion({
  deployment,
  intendedVersion,
  stackVersion,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  intendedVersion: string | null
  stackVersion?: StackVersionConfig | null
}): void {
  const { resources } = deployment

  if (!resources) {
    return
  }

  const sliderInstanceTypes = getSupportedSliderInstanceTypes()
  const resourceTypes = intersection(sliderInstanceTypes, Object.keys(resources))

  for (const resourceType of resourceTypes) {
    const resourcesOfType: AnyPayload[] | undefined = resources[resourceType]

    if (!Array.isArray(resourcesOfType)) {
      continue
    }

    for (const resource of resourcesOfType) {
      setResourceVersion({ resource, resourceType, intendedVersion, stackVersion })
    }
  }
}

function setResourceVersion({
  resource,
  resourceType,
  intendedVersion,
  stackVersion,
}: {
  resource: AnyPayload
  resourceType: SliderInstanceType
  intendedVersion: string | null
  stackVersion?: StackVersionConfig | null
}) {
  if (!resource.plan) {
    return
  }

  if (!resource.plan[resourceType]) {
    resource.plan[resourceType] = {}
  }

  resource.plan[resourceType].version = getVersion()

  if (!Array.isArray(resource.plan.cluster_topology)) {
    return
  }

  /* A version might already be set on individual topology elements,
   * but it should always span the resource
   * we delete the element-specific version while upgrading to ensure consistency
   * https://github.com/elastic/cloud/pull/41764
   * https://github.com/elastic/support-dev-help/issues/8294
   */
  for (const nodeConfiguration of resource.plan.cluster_topology) {
    if (nodeConfiguration[resourceType]) {
      delete nodeConfiguration[resourceType].version
    }
  }

  function getVersion() {
    if (!stackVersion) {
      return intendedVersion // and hope for the best
    }

    const resourceStack = stackVersion[resourceType]

    if (resourceStack) {
      const resourceSpecificVersion = resourceStack.version

      if (resourceSpecificVersion) {
        return resourceSpecificVersion
      }
    }

    const globalStackVersion = stackVersion.version

    if (globalStackVersion) {
      return globalStackVersion
    }

    return intendedVersion // and hope for the best
  }
}

export function changeRestoreFromSnapshot({
  onChange,
  regionId,
  source,
  snapshotName = `__latest_success__`,
  baseDeployment,
}: {
  onChange: (
    changes: DeepPartial<StackDeploymentUpsertRequest>,
    settings?: { shallow?: boolean },
  ) => void
  regionId?: RegionId
  source?: DeploymentSearchResponse | null
  snapshotName?: string
  baseDeployment?: DeploymentCreateRequest
}) {
  const cluster = source ? getFirstEsClusterFromGet({ deployment: source }) : null

  if (!source || !cluster) {
    onChangeRestoreFromSnapshot(null)
    return
  }

  onChangeRestoreFromSnapshot(
    {
      snapshot_name: snapshotName,
      source_cluster_id: cluster.id,
    },
    regionId,
  )

  function onChangeRestoreFromSnapshot(restoreFromSnapshot, regionId?: RegionId) {
    const restoreSnapChanges = {
      resources: {
        elasticsearch: [
          {
            plan: {
              transient: {
                restore_snapshot: restoreFromSnapshot,
              },
            },
          },
        ],
      },
    }

    if (baseDeployment) {
      // if we have a baseDeployment then we want to be amending that one with the restore snaps transient settings
      // and then we need to use shallow to make sure that we just use this whole object when merging
      // with editorState in <CreateStackDeploymentEditorDependencies />. Otherwise we end up losing the baseDeps details
      const mergedDeployment = mergeDeep(baseDeployment, restoreSnapChanges)

      if (regionId) {
        return onChange(
          {
            regionId,
            deployment: mergedDeployment,
          },
          { shallow: true },
        )
      }

      // if regionId isn't defined, then we don't want to be sending it as part of the object because shallow will just replace the existing value
      // with this undefined one
      return onChange(
        {
          deployment: mergedDeployment,
        },
        { shallow: true },
      )
    }

    onChange({
      regionId,
      deployment: restoreSnapChanges,
    })
  }
}

export function setEsSettings({
  onChange,
  settings,
}: {
  onChange: (
    changes: DeepPartial<StackDeploymentUpsertRequest>,
    settings?: { shallow?: boolean },
  ) => void
  settings: DeepPartial<ElasticsearchClusterSettings> | null
}) {
  onChange({
    deployment: {
      resources: {
        elasticsearch: [
          {
            settings: settings === null ? undefined : settings,
          },
        ],
      },
    },
  })
}
