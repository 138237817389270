/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { cloneDeep, map, merge, size } from 'lodash'

import { EuiSelect, EuiSpacer, EuiText } from '@elastic/eui'

import { getSnapshotRepositoryId } from '@/lib/stackDeployments/selectors/snapshots'
import { getFirstEsCluster } from '@/lib/stackDeployments/selectors'

import { CuiAlert } from '../../../../../../cui'
import SectionHeader from '../../SectionHeader'

import type { DeepPartial } from '@/types'
import type {
  DeploymentTemplateRequestBody,
  RepositoryConfig,
  ElasticsearchPayload,
} from '../../../../../../lib/api/v1/types'

export interface Props {
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (template: DeploymentTemplateRequestBody) => void
  snapshotRepositories: { [repositoryName: string]: RepositoryConfig }
}

export default class SnapshotRepository extends Component<Props> {
  render() {
    return (
      <Fragment>
        <SectionHeader
          title={
            <FormattedMessage
              id='deployment-template-snapshot-config.section-title'
              defaultMessage='Snapshots'
            />
          }
        />
        <EuiSpacer size='m' />
        <EuiText>
          <p>
            <FormattedMessage
              id='deployment-template-snapshot-config.section-description'
              defaultMessage='Select the snapshot repository to use by default. During the deployment creation process, they can still choose from the available repositories.'
            />
          </p>
        </EuiText>
        <EuiSpacer />

        {this.renderRepositories()}
      </Fragment>
    )
  }

  renderRepositories() {
    const { snapshotRepositories, template } = this.props

    if (size(snapshotRepositories) === 0) {
      return (
        <Fragment>
          <CuiAlert data-test-id='deploymentTemplateSnapshotConfig-noSnapshotRepos' type='warning'>
            <FormattedMessage
              id='deployment-template-snapshot-config.no-snapshot-repos'
              defaultMessage='You do not have any snapshot repositories set up. You can add one later.'
            />
          </CuiAlert>
        </Fragment>
      )
    }

    const snapshotRepositoryId = getSnapshotRepositoryId({
      deployment: template.deployment_template,
    })

    return (
      <Fragment>
        <EuiSelect
          data-test-id='deploymentTemplateSnapshotConfig-snapshotRepoSelect'
          value={snapshotRepositoryId || ``}
          onChange={(e) => this.updateSnapshotRepository(e)}
          options={[
            { value: ``, text: `` },
            ...map(snapshotRepositories, (repo) => ({
              value: repo.repository_name,
              text: repo.repository_name,
            })),
          ]}
        />
      </Fragment>
    )
  }

  updateSnapshotRepository = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const snapshotRepositoryId = e.target.value

    const template = cloneDeep(this.props.template)
    const resource = getFirstEsCluster({ deployment: template.deployment_template })

    if (!resource) {
      return // sanity
    }

    merge<ElasticsearchPayload, DeepPartial<ElasticsearchPayload>>(resource, {
      settings: {
        snapshot: {
          repository: {
            reference: {
              repository_name: snapshotRepositoryId,
            },
          },
        },
      },
    })

    this.props.updateDeploymentTemplate(template)
  }
}
