/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getAllocator, getAllocatorVacate, vacateAllocatorRequest } from '../../../reducers'

import VacateAllocator from './Vacate'

import type {
  Allocator,
  AsyncRequestState,
  VacateResult as VacateResultType,
  ReduxState,
} from '../../../types'

type StateProps = {
  allocator: Allocator
  vacateResult?: VacateResultType
  vacateAllocatorRequest: AsyncRequestState
}

type DispatchProps = unknown

type ConsumerProps = {
  regionId: string
  hostId: string
}

const mapStateToProps = (state: ReduxState, { regionId, hostId }: ConsumerProps): StateProps => ({
  allocator: getAllocator(state, regionId, hostId)!,
  vacateResult: getAllocatorVacate(state, regionId, hostId),
  vacateAllocatorRequest: vacateAllocatorRequest(state, regionId, hostId),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(VacateAllocator)
