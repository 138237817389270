/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import type { IconType } from '@elastic/eui'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  title: ReactNode
  iconType?: IconType
  flexItems?: ReactNode
  children?: ReactNode
  padContents?: boolean
}

const PageSection: FunctionComponent<Props> = ({
  title,
  iconType,
  flexItems,
  children,
  padContents = true,
}: Props) => (
  <Fragment>
    <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
      {iconType && (
        <EuiFlexItem grow={false}>
          <EuiIcon type={iconType} size='m' />
        </EuiFlexItem>
      )}

      <EuiFlexItem grow={false}>
        <EuiText>
          <h3>{title}</h3>
        </EuiText>
      </EuiFlexItem>

      {flexItems}
    </EuiFlexGroup>

    <EuiHorizontalRule margin='m' />

    {children && (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          {padContents && iconType && (
            <EuiFlexItem grow={false}>
              <EuiIcon type='empty' size='m' />
            </EuiFlexItem>
          )}

          <EuiFlexItem>
            <div>{children}</div>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xxl' />
      </Fragment>
    )}
  </Fragment>
)

export default PageSection
