/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchAllUsers } from '../../../../../actions/localUsers'
import { fetchAllUsersRequest, getCurrentUser, getLocalUsers } from '../../../../../reducers'
import { getSecurityCluster } from '../../../reducers'
import withPolling from '../../../../../lib/withPolling'

import Users from './Users'

import type { Props as OwnProps } from './Users'
import type { RouteComponentProps } from 'react-router'
import type { AsyncRequestState, ReduxState, RegionId, ThunkDispatch } from '../../../../../types'
import type { SecurityDeployment, User } from '../../../../../lib/api/v1/types'

interface StateProps {
  fetchAllUsersRequest: AsyncRequestState
  regionId: RegionId
  users: User[] | null
  currentUser: User | null
  securityCluster: SecurityDeployment
}

interface DispatchProps {
  fetchAllUsers: () => Promise<any>
}

type ConsumerProps = OwnProps & RouteComponentProps

const mapStateToProps: (state: ReduxState, ownProps: ConsumerProps) => StateProps = (
  state,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  fetchAllUsersRequest: fetchAllUsersRequest(state),
  regionId,
  users: getLocalUsers(state),
  currentUser: getCurrentUser(state),
  securityCluster: getSecurityCluster(state, regionId),
})

const pollingComponent = withPolling(Users, ({ fetchAllUsers: onPoll }) => ({
  onPoll,
}))

const mapDispatchToProps: (dispatch: ThunkDispatch) => DispatchProps = (dispatch) => ({
  fetchAllUsers: () => dispatch(fetchAllUsers()),
})

export default connect<StateProps, DispatchProps, RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
