/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual } from 'lodash'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EUI_MODAL_CANCEL_BUTTON,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiToolTip,
} from '@elastic/eui'

import { CuiTable, CuiAlert, CuiLink, CuiPermissibleControl } from '../../../../../../cui'
import Header from '../../../../../../components/Header'
import SpinButton from '../../../../../../components/SpinButton'
import {
  editActiveDirectoryAuthenticationProviderUrl,
  editLdapAuthenticationProviderUrl,
  editSamlAuthenticationProviderUrl,
  manageNativeUsersUrl,
} from '../../../../../../lib/urlBuilder'
import { authProvidersCrumbs } from '../../../../../../lib/crumbBuilder'
import history from '../../../../../../lib/history'
import { ifPermitted } from '../../../../../../lib/requiresPermission'
import Permission from '../../../../../../lib/api/v1/permissions'

import AddProviderButton from './AddProviderButton'

import type { AsyncRequestState, RegionId } from '../../../../../../types'
import type {
  SecurityRealmInfo,
  SecurityRealmInfoList,
  SecurityRealmsReorderRequest,
} from '../../../../../../lib/api/v1/types'
import type { WrappedComponentProps } from 'react-intl'

import '../authProviders.scss'

export interface Props extends WrappedComponentProps {
  deleteActiveDirectorySecurityRealm: (realmId: string) => Promise<any>
  deleteActiveDirectorySecurityRealmRequest: AsyncRequestState
  deleteLdapSecurityRealm: (realmId: string) => Promise<any>
  deleteLdapSecurityRealmRequest: AsyncRequestState
  deleteSamlSecurityRealm: (realmId: string) => Promise<any>
  deleteSamlSecurityRealmRequest: AsyncRequestState
  fetchSecurityRealms: () => Promise<any>
  getSecurityRealmsRequest: AsyncRequestState
  reorderSecurityRealms: (realms: SecurityRealmsReorderRequest) => Promise<any>
  reorderSecurityRealmsRequest: AsyncRequestState
  isPlanChangePending?: boolean
  isSecurityDeploymentDisabled?: boolean
  regionId: RegionId
  securityRealms: SecurityRealmInfoList
}

interface State {
  confirmingDeletion: boolean
  realmToDelete: SecurityRealmInfo | null
  updatingOrder: boolean
  order: string[]
}

const messages = defineMessages({
  title: {
    id: 'external-authentication.list-title',
    defaultMessage: 'Authentication providers',
  },
  order: {
    id: 'external-authentication.table-columns.order',
    defaultMessage: 'Order',
  },
  orderHigher: {
    id: 'external-authentication.table-columns.order.higher',
    defaultMessage: 'Reorder this provider higher in authentication chain',
  },
  orderLower: {
    id: 'external-authentication.table-columns.order.lower',
    defaultMessage: 'Reorder this provider lower in authentication chain',
  },
  orderSave: {
    id: 'external-authentication.table-columns.order.save',
    defaultMessage: 'Save order',
  },
  name: {
    id: 'external-authentication.table-columns.name',
    defaultMessage: 'Profile name',
  },
  type: {
    id: 'external-authentication.table-columns.type',
    defaultMessage: 'Realm type',
  },
  urls: {
    id: 'external-authentication.table-columns.urls',
    defaultMessage: 'URLs',
  },
  actions: {
    id: 'external-authentication.table-columns.actions',
    defaultMessage: 'Actions',
  },
  addAuthenticationSource: {
    id: 'external-authentication.add-authentication-source',
    defaultMessage: 'Add provider',
  },
  editProvider: {
    id: 'external-authentication.edit-provider',
    defaultMessage: 'Edit provider',
  },
  deleteProvider: {
    id: 'external-authentication.delete-provider',
    defaultMessage: 'Delete provider',
  },
  nativeRealmName: {
    id: 'external-authentication.native-realm-name',
    defaultMessage: 'Native',
  },
  introductionText: {
    id: 'external-authentication.introduction-text',
    defaultMessage:
      'Manage your authentication provider profiles and order them. Authentication stops with the first successful match, providing only those roles and permissions to the user.',
  },
  beta: {
    id: `external-authentication.beta-feature`,
    defaultMessage: `Beta`,
  },
  betaDescription: {
    id: `external-authentication.beta-feature.description`,
    defaultMessage: `This feature is not yet GA. Please help us by reporting any issues!`,
  },
})

const modalMessages = defineMessages({
  title: {
    id: 'external-authentication.delete-realm-modal.title',
    defaultMessage: 'Delete authentication provider?',
  },
  cancel: {
    id: 'external-authentication.delete-realm-modal.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'external-authentication.delete-realm-modal.confirm',
    defaultMessage: 'Delete provider',
  },
  body: {
    id: 'external-authentication.delete-realm-modal.body',
    defaultMessage: 'You are about to delete the "{name}" authentication provider.',
  },
})

class ExternalAuthenticationList extends Component<Props, State> {
  state: State = {
    confirmingDeletion: false,
    realmToDelete: null,
    updatingOrder: false,
    order: getInitialOrder(this.props),
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    if (!prevState.updatingOrder) {
      return { order: getInitialOrder(nextProps) }
    }

    return null
  }

  render() {
    const { regionId, isPlanChangePending, isSecurityDeploymentDisabled } = this.props

    return (
      <Fragment>
        <Header
          name={<FormattedMessage {...messages.title} />}
          breadcrumbs={authProvidersCrumbs({ regionId })}
        >
          <CuiPermissibleControl
            permissions={[Permission.createLdapConfiguration, Permission.createSamlConfiguration]}
          >
            <AddProviderButton
              disabled={isPlanChangePending || isSecurityDeploymentDisabled}
              regionId={regionId}
            />
          </CuiPermissibleControl>
        </Header>

        <EuiText>
          <p>
            <FormattedMessage {...messages.introductionText} />
          </p>
        </EuiText>

        <EuiSpacer />

        {this.renderContent()}
        {this.renderModal()}
      </Fragment>
    )
  }

  renderContent() {
    const {
      securityRealms,
      getSecurityRealmsRequest,
      isPlanChangePending,
      reorderSecurityRealmsRequest,
    } = this.props

    const { order, updatingOrder } = this.state

    if (getSecurityRealmsRequest.error) {
      return <CuiAlert type='error'>{getSecurityRealmsRequest.error}</CuiAlert>
    }

    const sortedRealms = order.map((id) => securityRealms.realms.find((realm) => realm.id === id))

    return (
      <Fragment>
        <CuiTable
          data-test-id='external-authentication-table'
          rows={sortedRealms}
          columns={this.getColumns()}
          initialLoading={!securityRealms}
        />

        {!isPlanChangePending && updatingOrder && this.hasOrderChanged() && (
          <Fragment>
            <EuiSpacer size='m' />

            <SpinButton
              size='s'
              onClick={this.onOrderSave}
              spin={reorderSecurityRealmsRequest.inProgress}
              data-test-id='save-external-authentication-order'
            >
              <FormattedMessage {...messages.orderSave} />
            </SpinButton>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderModal() {
    const {
      intl: { formatMessage },
      deleteLdapSecurityRealmRequest,
      deleteSamlSecurityRealmRequest,
    } = this.props

    if (!this.state.confirmingDeletion) {
      return null
    }

    const { realmToDelete } = this.state

    const deleteError = deleteLdapSecurityRealmRequest.error || deleteSamlSecurityRealmRequest.error

    return (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={formatMessage(modalMessages.title)}
          onCancel={() => this.setState({ confirmingDeletion: false, realmToDelete: null })}
          onConfirm={this.onConfirmDelete}
          cancelButtonText={formatMessage(modalMessages.cancel)}
          confirmButtonText={formatMessage(modalMessages.confirm)}
          buttonColor='danger'
          defaultFocusedButton={EUI_MODAL_CANCEL_BUTTON}
        >
          <FormattedMessage {...modalMessages.body} values={{ name: realmToDelete!.name }} />
          {deleteError && (
            <Fragment>
              <EuiSpacer />
              <CuiAlert type='danger'>{deleteError}</CuiAlert>
            </Fragment>
          )}
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  onConfirmDelete = () => {
    const { deleteActiveDirectorySecurityRealm, deleteLdapSecurityRealm, deleteSamlSecurityRealm } =
      this.props
    const { realmToDelete } = this.state

    if (realmToDelete == null) {
      return
    }

    let waitForDelete = Promise.resolve()

    switch (realmToDelete.type) {
      case 'active_directory':
        waitForDelete = deleteActiveDirectorySecurityRealm(realmToDelete.id)
        break

      case 'ldap':
        waitForDelete = deleteLdapSecurityRealm(realmToDelete.id)
        break

      case 'saml':
        waitForDelete = deleteSamlSecurityRealm(realmToDelete.id)
        break

      default:
        break
    }

    return waitForDelete.then(() =>
      this.setState({ confirmingDeletion: false, realmToDelete: null }),
    )
  }

  hasOrderChanged = (): boolean => {
    const {
      securityRealms: { realms },
    } = this.props
    const { order } = this.state
    const realmIds = realms.map(({ id }) => id)

    return !isEqual(order, realmIds)
  }

  onOrderUpdate = (oldPosition: number = 0, newPosition: number = 0) => {
    const { order } = this.state
    order.splice(newPosition, 0, order.splice(oldPosition, 1)[0])

    this.setState({ order, updatingOrder: true })
  }

  onOrderSave = () => {
    const { reorderSecurityRealms } = this.props
    const { order } = this.state

    return reorderSecurityRealms({ realms: order })
  }

  getColumns() {
    const {
      intl: { formatMessage },
      isPlanChangePending,
      isSecurityDeploymentDisabled,
      regionId,
      reorderSecurityRealmsRequest,
    } = this.props

    const { order: orderState } = this.state

    const minOrder = 0
    const maxOrder = orderState.length - 1

    return [
      {
        label: formatMessage(messages.order),
        width: `100px`,
        render: (securityRealm: SecurityRealmInfo) => {
          const order = orderState.indexOf(securityRealm.id)
          const isDisabled = isPlanChangePending || reorderSecurityRealmsRequest.inProgress

          return (
            <Fragment>
              {ifPermitted(
                Permission.reorderSecurityRealms,
                () => (
                  <EuiToolTip position='bottom' content={formatMessage(messages.orderHigher)}>
                    <EuiButtonIcon
                      aria-label={formatMessage(messages.orderHigher)}
                      isDisabled={isDisabled || order === minOrder}
                      iconType='arrowUp'
                      onClick={this.onOrderUpdate.bind(this, order, order - 1)}
                      data-test-id='external-authentication-table-sort-up'
                    />
                  </EuiToolTip>
                ),
                () => (
                  <CuiPermissibleControl permissions={Permission.reorderSecurityRealms}>
                    <EuiButtonIcon
                      aria-label={formatMessage(messages.orderHigher)}
                      isDisabled={true}
                      iconType='arrowUp'
                      data-test-id='external-authentication-table-sort-up'
                    />
                  </CuiPermissibleControl>
                ),
              )}

              {ifPermitted(
                Permission.reorderSecurityRealms,
                () => (
                  <EuiToolTip position='bottom' content={formatMessage(messages.orderLower)}>
                    <EuiButtonIcon
                      aria-label={formatMessage(messages.orderLower)}
                      isDisabled={isDisabled || order === maxOrder}
                      iconType='arrowDown'
                      onClick={this.onOrderUpdate.bind(this, order, order + 1)}
                      data-test-id='external-authentication-table-sort-down'
                    />
                  </EuiToolTip>
                ),
                () => (
                  <CuiPermissibleControl permissions={Permission.reorderSecurityRealms}>
                    <EuiButtonIcon
                      aria-label={formatMessage(messages.orderLower)}
                      isDisabled={true}
                      iconType='arrowDown'
                      data-test-id='external-authentication-table-sort-down'
                    />
                  </CuiPermissibleControl>
                ),
              )}
              <span className='externalAuthList-order'>{order + 1}</span>
            </Fragment>
          )
        },
        sortKey: (securityRealm: SecurityRealmInfo) => securityRealm.order || 0,
      },
      {
        label: formatMessage(messages.name),
        render: (securityRealm: SecurityRealmInfo) => {
          const { name } = securityRealm

          switch (securityRealm.type) {
            case 'native':
              return <CuiLink to={manageNativeUsersUrl(regionId)}>{name}</CuiLink>

            case 'active_directory':
              return (
                <CuiPermissibleControl permissions={Permission.getActiveDirectoryConfiguration}>
                  <CuiLink
                    to={editActiveDirectoryAuthenticationProviderUrl(regionId, securityRealm.id)}
                  >
                    {name}
                  </CuiLink>
                </CuiPermissibleControl>
              )

            case 'ldap':
              return (
                <CuiPermissibleControl permissions={Permission.getLdapConfiguration}>
                  <CuiLink to={editLdapAuthenticationProviderUrl(regionId, securityRealm.id)}>
                    {name}
                  </CuiLink>
                </CuiPermissibleControl>
              )

            case 'saml':
              return (
                <CuiPermissibleControl permissions={Permission.getSamlConfiguration}>
                  <CuiLink to={editSamlAuthenticationProviderUrl(regionId, securityRealm.id)}>
                    {name}
                  </CuiLink>
                </CuiPermissibleControl>
              )

            default:
              return name
          }
        },
        sortKey: `name`,
      },
      {
        width: `130px`,
        label: formatMessage(messages.type),
        render: (securityRealm: SecurityRealmInfo) => {
          switch (securityRealm.type) {
            case 'native':
              return formatMessage(messages.nativeRealmName)

            case 'active_directory':
              // Even Wikipedia's entry on this doesn't translate this term.
              return 'Active Directory'

            case 'ldap':
              return 'LDAP'

            case 'saml':
              return 'SAML'

            default:
              return securityRealm.type
          }
        },
        sortKey: `type`,
      },
      {
        label: formatMessage(messages.urls),
        render: (securityRealm: SecurityRealmInfo) => {
          if (securityRealm.type === 'native') {
            return null
          }

          return (
            <ul className='u-noPadding'>
              {securityRealm.urls
                .slice()
                .sort()
                .map((url, index) => (
                  <li key={index}>{url}</li>
                ))}
            </ul>
          )
        },
      },
      {
        width: '70px',
        mobile: (securityRealm: SecurityRealmInfo) =>
          securityRealm.type === 'native'
            ? null
            : {
                label: formatMessage(messages.actions),
              },
        actions: true,
        render: (securityRealm: SecurityRealmInfo) => {
          if (securityRealm.type === 'native') {
            return null
          }

          const editUrl = getSecurityRealmEditUrl({ regionId, securityRealm })

          return (
            <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <CuiPermissibleControl
                  permissions={[Permission.getLdapConfiguration, Permission.getSamlConfiguration]}
                >
                  <EuiButtonIcon
                    // We don't disable this during a plan change, because it may be useful
                    // to view provider configs while an edit is being applied.
                    key='edit'
                    aria-label={formatMessage(messages.editProvider)}
                    data-test-id='editAuthProvider'
                    disabled={!editUrl}
                    iconType={
                      isPlanChangePending || isSecurityDeploymentDisabled ? 'eye' : 'pencil'
                    }
                    onClick={() => history.push(editUrl!)}
                  />
                </CuiPermissibleControl>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <CuiPermissibleControl
                  permissions={[
                    Permission.deleteLdapConfiguration,
                    Permission.deleteSamlConfiguration,
                  ]}
                >
                  <EuiButtonIcon
                    key='delete'
                    disabled={isPlanChangePending || isSecurityDeploymentDisabled}
                    aria-label={formatMessage(messages.deleteProvider)}
                    color='danger'
                    iconType='trash'
                    onClick={() =>
                      this.setState({ confirmingDeletion: true, realmToDelete: securityRealm })
                    }
                    data-test-id='deleteAuthProvider'
                  />
                </CuiPermissibleControl>
              </EuiFlexItem>
            </EuiFlexGroup>
          )
        },
      },
    ]
  }
}

export default injectIntl(ExternalAuthenticationList)

function getSecurityRealmEditUrl({
  regionId,
  securityRealm,
}: {
  regionId: string
  securityRealm: SecurityRealmInfo
}): string | undefined {
  switch (securityRealm.type) {
    case 'active_directory':
      return editActiveDirectoryAuthenticationProviderUrl(regionId, securityRealm.id)

    case 'ldap':
      return editLdapAuthenticationProviderUrl(regionId, securityRealm.id)

    case 'saml':
      return editSamlAuthenticationProviderUrl(regionId, securityRealm.id)

    default:
      return
  }
}

function getInitialOrder({ securityRealms }: Props): string[] {
  if (securityRealms == null) {
    return []
  }

  const sortedRealms = securityRealms.realms.slice().sort((a, b) => {
    if (a.order! < b.order!) {
      return -1
    }

    return a.order! > b.order! ? 1 : a.name.localeCompare(b.name)
  })

  return sortedRealms.map(({ id }) => id)
}
