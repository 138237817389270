/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import {
  START_CONSTRUCTOR_MAINTENANCE_MODE,
  STOP_CONSTRUCTOR_MAINTENANCE_MODE,
} from '../../constants/actions'
import {
  startConstructorMaintenanceModeUrl,
  stopConstructorMaintenanceModeUrl,
} from '../../lib/api/v1/urls'

export function startConstructorMaintenanceMode(regionId: string, constructorId: string) {
  return asyncRequest({
    type: START_CONSTRUCTOR_MAINTENANCE_MODE,
    method: `POST`,
    url: startConstructorMaintenanceModeUrl({ regionId, constructorId }),
    meta: { regionId, constructorId },
    crumbs: [regionId, constructorId],
  })
}

export function stopConstructorMaintenanceMode(regionId: string, constructorId: string) {
  return asyncRequest({
    type: STOP_CONSTRUCTOR_MAINTENANCE_MODE,
    method: `POST`,
    url: stopConstructorMaintenanceModeUrl({ regionId, constructorId }),
    meta: { regionId, constructorId },
    crumbs: [regionId, constructorId],
  })
}

export * from './loggingSettings'
