/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getConfigForKey } from '@/selectors'
import { saasOrganizationToUserProfile } from '@/lib/profile'
import { getSaasOrganization } from '@/reducers'

import type { CloudAppName, UserProfile, ReduxState } from '@/types'

type GetCommonProfileOverload = {
  (state: ReduxState, organizationId: string): UserProfile | undefined
  (state: ReduxState): UserProfile
}

// this getCommonProfile method returns `profile` object for both AppConsole and UserConsole applications in the same structure
export const getCommonProfile: GetCommonProfileOverload = (state, organizationId?) => {
  const appName: CloudAppName = getConfigForKey(state, 'APP_NAME')

  if (appName === 'adminconsole') {
    const profile = getSaasOrganization(state, organizationId)

    return profile && saasOrganizationToUserProfile(profile)
  }

  return state.profile
}
