/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { isPermitted } from '@/lib/requiresPermission'
import Permission from '@/lib/api/v1/permissions'
import { hostAllocatorUrl } from '@/lib/urlBuilder'

import type { AllocatorSearchResult } from '@/types'
import type { Problem } from '../../problems'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles allocator related health issues.
 */
export function getAllocatorHealthProblems(
  deploymentAllocators?: AllocatorSearchResult[],
): Problem[] {
  const problems: Problem[] = []

  if (!deploymentAllocators || deploymentAllocators.length === 0) {
    return problems
  }

  const unhealthyAllocators = deploymentAllocators.filter(({ healthy }) => !healthy)

  if (unhealthyAllocators.length === 0) {
    return problems
  }

  const allocatorsLinks = unhealthyAllocators.map(({ regionId, id }) =>
    isPermitted(Permission.getAllocator) ? (
      <Link key={id} to={hostAllocatorUrl(regionId, id)}>
        {id}
      </Link>
    ) : (
      id
    ),
  )

  const allocatorsLinksWithCommas = allocatorsLinks.reduce(
    (acc, curr) => [...acc, acc.length > 0 ? ', ' : '', curr],
    [],
  )

  problems.push({
    'data-test-id': `deployment-unhealthy-allocators`,
    kind: `deployment`,
    id: `deployment-unhealthy-allocators`,
    level: `danger`,
    message: (
      <FormattedMessage
        id='deployment-health-problems.unhealthy-allocators'
        defaultMessage='Some instances in this deployment are running on unhealthy allocators: {allocatorsLinks}'
        values={{
          allocatorsLinks: allocatorsLinksWithCommas,
        }}
      />
    ),
  })

  return problems
}
