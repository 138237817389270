/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../../reducers'

import EnsurePremium from './EnsurePremium'

import type { AsyncRequestState, ReduxState, SaasLevel } from '../../../../types'

type StateProps = {
  ensurePremiumRequest: AsyncRequestState
}

type DispatchProps = {
  resetEnsurePremium: () => void
}

type ConsumerProps = {
  organizationId: string
  level: SaasLevel
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  ensurePremiumRequest: callStoredProcedureRequest(state, `ensure_paying_level`),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  resetEnsurePremium: () => {
    dispatch(resetCallStoredProcedureRequest(`ensure_paying_level`))
    return dispatch(clearProcedure(`ensure_paying_level`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EnsurePremium)
