/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { get, isUndefined, sortBy } from 'lodash'

import { isHiddenTemplate } from '../../deploymentTemplates/metadata'
import {
  isTemplateSupportedInPlatform,
  getProductSliderTypes,
  sortSliderTypes,
} from '../../sliders'
import { getTopologiesFromTemplate } from '../../deploymentTemplates/getTopologiesFromTemplate'
import { getConfigForKey } from '../../../store'
import { satisfies } from '../../semver'

import {
  getEsPlanFromGet,
  getFirstEsClusterFromGet,
  getVersion,
  _getPlanInfoFromHistory,
  getSliderPlanFromGet,
} from './fundamentals'
import { getNodeRoles } from './nodeRoles'

import type {
  SliderInstanceType,
  AnyPayload,
  AnyTopologyElement,
  SliderNodeType,
  SliderType,
  StackDeployment,
  VersionNumber,
} from '../../../types'
import type {
  DeploymentTemplateInfoV2,
  InstanceConfiguration,
  DeploymentCreateRequest,
  ElasticsearchPayload,
  KibanaPayload,
  ApmPayload,
  EnterpriseSearchPayload,
  AppSearchPayload,
  ElasticsearchClusterSettings,
  ElasticsearchClusterPlanInfo,
} from '../../api/v1/types'

export function getDeploymentTemplateId({
  deployment,
}: {
  deployment: StackDeployment
}): string | null {
  const esPlan = getEsPlanFromGet({
    deployment,
    state: `best_effort`,
  })

  const deploymentTemplateId = esPlan?.deployment_template?.id

  if (deploymentTemplateId) {
    return deploymentTemplateId
  }

  // If the above method failed, e.g. in the case of a terminated deployment with
  // no effective ES resource, try to find the template ID from the plan history.
  const resource = getFirstEsClusterFromGet({ deployment })

  if (resource == null) {
    return null
  }

  const mustMatch = (planInfo: ElasticsearchClusterPlanInfo) =>
    Boolean(planInfo.plan?.deployment_template?.id)

  // try last healthy plan
  const lastHealthyDeploymentIdPlan = _getPlanInfoFromHistory({
    resource,
    mustMatch,
  }) as ElasticsearchClusterPlanInfo | null

  const lastHealthyDeploymentId = lastHealthyDeploymentIdPlan?.plan?.deployment_template?.id

  if (lastHealthyDeploymentId) {
    return lastHealthyDeploymentId
  }

  // last resort: last unhealthy plan
  const lastUnhealthyDeploymentIdPlan = _getPlanInfoFromHistory({
    resource,
    mustMatch,
    mustHealthy: false,
  }) as ElasticsearchClusterPlanInfo | null

  const lastUnhealthyDeploymentId = lastUnhealthyDeploymentIdPlan?.plan?.deployment_template?.id

  return lastUnhealthyDeploymentId || null
}

function isKibanaSupported({
  deployment,
  deploymentTemplate,
}: {
  deployment?: StackDeployment
  deploymentTemplate?: DeploymentCreateRequest | null
}): boolean {
  if (!deployment) {
    return false // avoid intermittence until we have the deployment
  }

  if (!getDeploymentTemplateId({ deployment })) {
    return true
  }

  return Boolean(deploymentTemplate?.resources?.kibana)
}

function isApmSupported({
  deployment,
  deploymentTemplate,
}: {
  deployment?: StackDeployment
  deploymentTemplate?: DeploymentCreateRequest | null
}): boolean {
  if (!deployment) {
    return false
  }

  const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`

  if (isHeroku) {
    return false
  }

  const version = getVersion({ deployment })

  if (!version) {
    return false
  }

  const apmSupportedRange = getConfigForKey(`APM_SUPPORTED_VERSION_RANGE`)

  if (!apmSupportedRange) {
    return false
  }

  // hide APM if not in the supported version range
  if (!satisfies(version, apmSupportedRange)) {
    return false
  }

  const integrationsServerSupportedRange = getConfigForKey(
    `INTEGRATIONS_SERVER_SUPPORTED_VERSION_RANGE`,
  )

  const apmPlanExists = Boolean(getSliderPlanFromGet({ deployment, sliderInstanceType: `apm` }))

  // hide APM if it's not enabled (or not in terminated state) but Integrations Server is supported
  if (
    !apmPlanExists &&
    integrationsServerSupportedRange &&
    satisfies(version, integrationsServerSupportedRange)
  ) {
    return false
  }

  return Boolean(deploymentTemplate?.resources?.apm)
}

function isIntegrationsServerSupported({
  deployment,
  deploymentTemplate,
}: {
  deployment?: StackDeployment
  deploymentTemplate?: DeploymentCreateRequest | null
}): boolean {
  if (!deployment) {
    return false
  }

  const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`

  if (isHeroku) {
    return false
  }

  const version = getVersion({ deployment })

  if (!version) {
    return false
  }

  const integrationsServerSupportedRange = getConfigForKey(
    `INTEGRATIONS_SERVER_SUPPORTED_VERSION_RANGE`,
  )

  if (!integrationsServerSupportedRange) {
    return false
  }

  // hide integrations_server if not in the supported version range
  if (!satisfies(version, integrationsServerSupportedRange)) {
    return false
  }

  const apmSupportedRange = getConfigForKey(`APM_SUPPORTED_VERSION_RANGE`)

  if (!apmSupportedRange) {
    return false
  }

  const apmPlanExists = Boolean(getSliderPlanFromGet({ deployment, sliderInstanceType: `apm` }))

  // hide Integrations Server if APM is supported AND enabled/terminated but not migrated
  if (apmPlanExists && satisfies(version, apmSupportedRange)) {
    return false
  }

  return Boolean(deploymentTemplate?.resources?.integrations_server)
}

export function isSliderSupportedForDeployment({
  deployment,
  deploymentTemplate,
  sliderInstanceType,
}: {
  deployment?: StackDeployment
  deploymentTemplate?: DeploymentCreateRequest | null
  sliderInstanceType: SliderInstanceType
}): boolean {
  if (sliderInstanceType === `elasticsearch`) {
    return true
  }

  if (sliderInstanceType === `kibana`) {
    return isKibanaSupported({ deployment, deploymentTemplate })
  }

  if (sliderInstanceType === `apm`) {
    return isApmSupported({ deployment, deploymentTemplate })
  }

  if (sliderInstanceType === `integrations_server`) {
    return isIntegrationsServerSupported({ deployment, deploymentTemplate })
  }

  return Boolean(deploymentTemplate?.resources?.[sliderInstanceType])
}

function isApmSupportedForNewDeployment({
  version,
  deploymentTemplate,
}: {
  version: VersionNumber
  deploymentTemplate?: DeploymentCreateRequest | null
}): boolean {
  const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`

  if (isHeroku) {
    return false
  }

  // hide APM if Integrations Server is supported
  if (isIntegrationsServerSupportedForNewDeployment({ version, deploymentTemplate })) {
    return false
  }

  const apmSupportedRange = getConfigForKey(`APM_SUPPORTED_VERSION_RANGE`)

  if (!apmSupportedRange) {
    return false
  }

  // hide APM if not in the supported version range
  if (!satisfies(version, apmSupportedRange)) {
    return false
  }

  return Boolean(deploymentTemplate?.resources?.apm)
}

function isIntegrationsServerSupportedForNewDeployment({
  version,
  deploymentTemplate,
}: {
  version: VersionNumber
  deploymentTemplate?: DeploymentCreateRequest | null
}): boolean {
  const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`

  if (isHeroku) {
    return false
  }

  const integrationsServerSupportedRange = getConfigForKey(
    `INTEGRATIONS_SERVER_SUPPORTED_VERSION_RANGE`,
  )

  if (!integrationsServerSupportedRange) {
    return false
  }

  // hide Integrations Server if not in the supported version range
  if (!satisfies(version, integrationsServerSupportedRange)) {
    return false
  }

  return Boolean(deploymentTemplate?.resources?.integrations_server)
}

export function isSliderSupportedForNewDeployment({
  version,
  deploymentTemplate,
  sliderInstanceType,
}: {
  version: VersionNumber
  deploymentTemplate?: DeploymentCreateRequest | null
  sliderInstanceType: SliderInstanceType
}): boolean {
  if (sliderInstanceType === `elasticsearch`) {
    return true
  }

  if (sliderInstanceType === `apm`) {
    return isApmSupportedForNewDeployment({ version, deploymentTemplate })
  }

  if (sliderInstanceType === `integrations_server`) {
    return isIntegrationsServerSupportedForNewDeployment({ version, deploymentTemplate })
  }

  return Boolean(deploymentTemplate?.resources?.[sliderInstanceType])
}

export function hasNodeType(
  clusterTopology: AnyTopologyElement,
  nodeType: SliderNodeType,
): boolean {
  return get(clusterTopology, [`node_type`, nodeType]) === true
}

export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: `elasticsearch`
}): ElasticsearchPayload | null
export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: `kibana`
}): KibanaPayload | null
export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: `apm`
}): ApmPayload | null
export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: `appsearch`
}): AppSearchPayload | null
export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: `enterprise_search`
}): EnterpriseSearchPayload | null
export function getFirstSliderResourceFromTemplate(args: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: SliderInstanceType
}): AnyPayload | null
export function getFirstSliderResourceFromTemplate({
  deploymentTemplate,
  sliderInstanceType,
}: {
  deploymentTemplate?: DeploymentCreateRequest
  sliderInstanceType: SliderInstanceType
}): AnyPayload | null {
  return deploymentTemplate?.resources?.[sliderInstanceType]?.[0] || null
}

export function getFirstEsResourceFromTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate?: DeploymentCreateRequest
}): ElasticsearchPayload | null {
  return getFirstSliderResourceFromTemplate({
    deploymentTemplate,
    sliderInstanceType: `elasticsearch`,
  })
}

export function getEsSettingsFromTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate?: DeploymentCreateRequest
}): ElasticsearchClusterSettings | null {
  return (
    getFirstSliderResourceFromTemplate({
      deploymentTemplate,
      sliderInstanceType: `elasticsearch`,
    })?.settings || null
  )
}

export function hasMasterEligibleInstance({
  deploymentTemplate,
}: {
  deploymentTemplate: DeploymentCreateRequest
}): boolean {
  const esTopologies = getTopologiesFromTemplate({
    deploymentTemplate,
    sliderInstanceType: `elasticsearch`,
  })

  return esTopologies.some((topologyElement) =>
    getNodeRoles({ topologyElement }).includes(`master`),
  )
}

export function getSupportedDeploymentTemplates(
  deploymentTemplates?: DeploymentTemplateInfoV2[],
): DeploymentTemplateInfoV2[] | undefined {
  if (!Array.isArray(deploymentTemplates)) {
    return deploymentTemplates
  }

  return deploymentTemplates.filter(isTemplateSupportedInPlatform)
}

export function getInstanceConfigurationsFromTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate?: DeploymentTemplateInfoV2
}): InstanceConfiguration[] {
  if (!deploymentTemplate) {
    return []
  }

  const instanceConfigurations = deploymentTemplate.instance_configurations

  if (!instanceConfigurations) {
    return []
  }

  return instanceConfigurations
}

const isSliderInTemplate = function ({
  deploymentTemplate,
  sliderInstanceType,
  sliderNodeType,
}: {
  deploymentTemplate: DeploymentCreateRequest
  sliderInstanceType: SliderInstanceType
  sliderNodeType?: SliderNodeType
}): boolean {
  const topologies = getTopologiesFromTemplate({ deploymentTemplate, sliderInstanceType })

  if (topologies.length === 0) {
    return false
  }

  if (sliderNodeType == null) {
    return true
  }

  return topologies.some((topology) => get(topology, [`node_type`, sliderNodeType]))
}

export function getProductSliderTypesForTemplate(
  deploymentTemplate: DeploymentCreateRequest,
): SliderType[] {
  const supportedTypes = getProductSliderTypes().filter(({ sliderInstanceType, sliderNodeType }) =>
    isSliderInTemplate({ deploymentTemplate, sliderInstanceType, sliderNodeType }),
  )

  return sortSliderTypes(supportedTypes)
}

export function getVisibleTemplates(globalDeploymentTemplates) {
  if (globalDeploymentTemplates == null) {
    return null
  }

  return globalDeploymentTemplates
    .filter((template) => !isHiddenTemplate(template))
    .sort((a, b) => {
      if (!isUndefined(a.order) && !isUndefined(b.order)) {
        return a.order - b.order
      }

      return 0
    })
}

export function getFlaggedVisibleTemplates({ deploymentTemplates, flag }) {
  if (deploymentTemplates == null) {
    return null
  }

  return deploymentTemplates
    .filter((template) => !isHiddenTemplate(template))
    .sort((a, b) => {
      if (b.id === flag) {
        return 1
      }

      if (a.id === flag) {
        return -1
      }

      if (!isUndefined(a.order) && !isUndefined(b.order)) {
        return a.order - b.order
      }

      return 0
    })
}

// Deployment template id's follow the convention of `${id}-v${version}` for versioning
function parseVersionFromTemplateId(templateId: string): number {
  const versionMatch = templateId.match(/\w+-v(\d+)/)
  return versionMatch ? parseInt(versionMatch[1], 10) : 0
}

function stripVersionFromTemplateId(templateId: string): string {
  return templateId.replace(/-v\d+\b/, ``)
}

export function getLatestVersion(
  deploymentTemplates: DeploymentTemplateInfoV2[],
  templateId: string,
): DeploymentTemplateInfoV2 | null {
  const versionlessTemplateId = stripVersionFromTemplateId(templateId)
  const templateVariations = deploymentTemplates.filter(
    ({ id }) => id !== templateId && stripVersionFromTemplateId(id) === versionlessTemplateId,
  )

  if (!templateVariations.length) {
    return null
  }

  const currentVersion = parseVersionFromTemplateId(templateId)
  const newerTemplates = templateVariations.filter(
    ({ id }) => parseVersionFromTemplateId(id) > currentVersion,
  )

  if (!newerTemplates.length) {
    return null
  }

  const [latestVersion] = sortBy(newerTemplates, ({ id }) =>
    parseVersionFromTemplateId(id),
  ).reverse()

  return latestVersion
}

export function isTemplateUpgradeAvailable(
  currentTemplateId: string,
  newTemplateId: string,
): boolean {
  const currentVersion = parseVersionFromTemplateId(currentTemplateId)
  const newerVersion = parseVersionFromTemplateId(newTemplateId)

  return newerVersion > currentVersion
}
