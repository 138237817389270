/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import {
  FETCH_ADMINCONSOLES,
  FETCH_ADMINCONSOLE_LOGGING_SETTINGS,
  PATCH_ADMINCONSOLE_LOGGING_SETTINGS,
} from '../../constants/actions'
import {
  getAdminconsolesUrl,
  getAdminconsoleLoggingSettingsUrl,
  updateAdminconsoleLoggingSettingsUrl,
} from '../../lib/api/v1/urls'

import type { LoggerLevel } from '../../types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

export function fetchAdminconsoles(regionId: string) {
  return asyncRequest({
    type: FETCH_ADMINCONSOLES,
    method: `GET`,
    url: getAdminconsolesUrl({ regionId }),
    crumbs: [regionId],
    meta: { regionId },
  })
}

export function fetchAdminconsoleLoggingSettings({
  regionId,
  adminconsoleId,
}: {
  regionId: string
  adminconsoleId: string
}) {
  return asyncRequest({
    type: FETCH_ADMINCONSOLE_LOGGING_SETTINGS,
    method: `GET`,
    url: getAdminconsoleLoggingSettingsUrl({ regionId, adminconsoleId }),
    crumbs: [regionId, adminconsoleId],
    meta: { regionId, adminconsoleId },
  })
}

export function patchAdminconsoleLoggingSettings({
  regionId,
  adminconsoleId,
  loggingLevels,
}: {
  regionId: string
  adminconsoleId: string
  loggingLevels: PatchLoggingLevels
}) {
  return asyncRequest({
    type: PATCH_ADMINCONSOLE_LOGGING_SETTINGS,
    method: `PATCH`,
    url: updateAdminconsoleLoggingSettingsUrl({ regionId, adminconsoleId }),
    crumbs: [regionId, adminconsoleId],
    meta: { regionId, adminconsoleId },
    payload: { logging_levels: loggingLevels },
  })
}
