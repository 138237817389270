/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import { euiPaletteColorBlind } from '@elastic/eui'

import { ViewBy } from './types'

import type { BucketingStrategy } from './types'

const palette = euiPaletteColorBlind()

export const CapacityColor = palette[0]
export const DataTransferColor = palette[1]
export const StorageColor = palette[2]

export const MapDimensionNameToColor = {
  Capacity: CapacityColor,
  'Data transfer': DataTransferColor,
  Storage: StorageColor,
}

export const ColorKeyForOtherDeployments = 'all-deployments'

export const VisibleDeployments = 5

export const ViewByToBucketingStrategy: Record<ViewBy, BucketingStrategy> = {
  [ViewBy.DAY]: 'daily',
  [ViewBy.MONTH]: 'monthly',
}

export const MinimalStartDate = moment().add(1, 'days').subtract(1, 'year').startOf('day')
