/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiDescribedFormGroup, EuiSpacer } from '@elastic/eui'

import history from '@/lib/history'

import { CuiLink } from '../../../../cui'
import DocLink from '../../../../components/DocLink'
import EnableCrossClusterReplicationCallout from '../../../../components/CrossClusterReplication/EnableCrossClusterReplicationCallout'
import { getRegionId } from '../../../../lib/stackDeployments/selectors'
import {
  createDeploymentTrustRelationshipUrl,
  platformTrustManagementUrl,
} from '../../../../lib/urlBuilder'
import DeploymentTrustRelationshipTable from '../../../../components/DeploymentTrustRelationships/DeploymentTrustRelationshipTable'

import type { AllProps as Props, State } from './types'

class DeploymentTrustManagement extends React.Component<Props, State> {
  componentDidMount(): void {
    this.props.fetchCurrentAccount()
    this.props.fetchTrustRelationships()
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
    this.props.resetFetchTrustRelationships()
  }

  render(): JSX.Element {
    const { deployment } = this.props

    const regionId = getRegionId({ deployment })!

    const title = (
      <h3>
        <FormattedMessage id='deploymentTrustManagement.title' defaultMessage='Trust management' />
      </h3>
    )

    const description = (
      <Fragment>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.description'
            defaultMessage='Configure trust with deployments from this environment and from other Elastic Cloud Enterprise (ECE) environments. They can be configured for cross-cluster search and cross-cluster replication. {docLink}'
            values={{
              docLink: (
                <DocLink link='trustManagement'>
                  <FormattedMessage
                    id='deploymentTrustManagement.description-doclink'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.description-action'
            defaultMessage='Add new environments in {link}.'
            values={{
              link: (
                <CuiLink to={platformTrustManagementUrl(regionId)}>
                  <FormattedMessage
                    id='deploymentTrustManagement.description-action-link'
                    defaultMessage='trust management'
                  />
                </CuiLink>
              ),
            }}
          />
        </p>
      </Fragment>
    )

    return (
      <Fragment>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={title}
          description={description}
          data-test-id='trust-management'
        >
          <div>
            <EnableCrossClusterReplicationCallout deployment={deployment} />
            {this.renderEditButton()}
            <EuiSpacer />
            {this.renderDetails()}
          </div>
        </EuiDescribedFormGroup>
      </Fragment>
    )
  }

  renderEditButton(): JSX.Element {
    const { deployment } = this.props

    return (
      <EuiButton
        size='s'
        onClick={() => history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))}
        data-test-id='add-trusted-environment-button'
      >
        <FormattedMessage
          id='deploymentTrustManagement.addButton'
          defaultMessage='Add trusted environment'
        />
      </EuiButton>
    )
  }

  renderDetails(): JSX.Element {
    const { deployment, trustRelationships } = this.props

    return (
      <DeploymentTrustRelationshipTable
        deployment={deployment}
        trustRelationships={trustRelationships}
      />
    )
  }
}

export default DeploymentTrustManagement
