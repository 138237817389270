/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge } from '@elastic/eui'

import type { FunctionComponent } from 'react'

type Props = {
  currentId: string
  oldId?: string
}

const DeploymentTemplateChange: FunctionComponent<Props> = ({ oldId, currentId }) => {
  const currentIdBadge = <EuiBadge color='hollow'>{currentId}</EuiBadge>

  if (!oldId) {
    return (
      <FormattedMessage
        id='explain-changes.set-deployment-template'
        defaultMessage='Set deployment template to {currentIdBadge}'
        values={{
          currentIdBadge,
        }}
      />
    )
  }

  const oldIdBadge = (
    <EuiBadge color='hollow'>
      <del>{oldId}</del>
    </EuiBadge>
  )
  const explainedDeploymentTemplateChange = (
    <FormattedMessage
      id='explain-changes.migrate-deployment-template'
      defaultMessage='Migrate deployment template from {oldIdBadge} to {currentIdBadge}'
      values={{
        oldIdBadge,
        currentIdBadge,
      }}
    />
  )

  return explainedDeploymentTemplateChange
}

export default DeploymentTemplateChange
