/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiSpacer,
  EuiCheckbox,
  EuiFormHelpText,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
} from '@elastic/eui'

import { CuiButton, CuiAlert, CuiPermissibleControl } from '../../../../cui'
import lightTheme from '../../../../lib/theme/light'
import Permission from '../../../../lib/api/v1/permissions'

import type { Allocator, DeleteAllocatorState } from '../../../../types'

type Props = {
  allocator: Allocator
  deleteAllocatorInformation?: DeleteAllocatorState
  deleteAllocator: (removeInstances: boolean) => void
  close: () => void
}

type State = {
  removeInstances: boolean
}

const { euiBreakpoints } = lightTheme

class DeleteAllocatorModal extends Component<Props, State> {
  state: State = {
    removeInstances: false,
  }

  render() {
    const { close } = this.props

    return (
      <EuiOverlayMask>
        <EuiModal onClose={close} style={{ maxWidth: euiBreakpoints.xl }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='delete-allocator-modal.delete-allocator'
                defaultMessage='Delete allocator'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <div>{this.renderModalBody()}</div>
          </EuiModalBody>

          <EuiModalFooter>
            <div>{this.renderModalFooter()}</div>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderModalBody() {
    const { removeInstances } = this.state

    return (
      <EuiCheckbox
        id='set-allocator-remove-instances'
        label={
          <Fragment>
            <FormattedMessage
              id='allocator-delete-index.remove-instances'
              defaultMessage='Remove instances'
            />

            <EuiFormHelpText>
              <FormattedMessage
                id='allocator-delete-index.remove-all-instances-associated-with-this-allocator'
                defaultMessage='This option removes all instances associated with this allocator.'
              />
            </EuiFormHelpText>
          </Fragment>
        }
        checked={removeInstances}
        onChange={() => this.toggleRemoveInstances()}
      />
    )
  }

  renderModalFooter() {
    const { allocator, deleteAllocatorInformation = {}, deleteAllocator } = this.props
    const { removeInstances } = this.state
    const { connected } = allocator

    return (
      <Fragment>
        <CuiPermissibleControl permissions={Permission.deleteAllocator}>
          <CuiButton
            color='danger'
            onClick={() => deleteAllocator(removeInstances)}
            spin={deleteAllocatorInformation.isDeleting}
            fill={true}
            disabled={connected}
          >
            <FormattedMessage id='delete-allocator.delete' defaultMessage='Delete allocator' />
          </CuiButton>
        </CuiPermissibleControl>

        {deleteAllocatorInformation.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert
              data-test-id='delete-allocator-error'
              type='error'
              details={deleteAllocatorInformation.error}
            >
              <FormattedMessage
                id='allocator-delete.button'
                defaultMessage='Deleting allocator failed'
              />
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  toggleRemoveInstances() {
    this.setState({
      removeInstances: !this.state.removeInstances,
    })
  }
}

export default DeleteAllocatorModal
