/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiPanel, EuiSpacer } from '@elastic/eui'

import { createCuiWizard, withErrorBoundary } from '../../../../cui'
import ApiRequestExample from '../../../ApiRequestExample'
import { createDeploymentTemplateV2Url } from '../../../../lib/api/v1/urls'
import DeploymentTemplateArchitectureViz from '../DeploymentTemplateArchitectureViz'

import { stackFeaturesStepDefinition } from './ConfigureTemplateStackFeaturesStep'
import { indexManagementStepDefinition } from './ConfigureTemplateIndexManagementStep'
import { instancesStepDefinition } from './ConfigureTemplateInstancesStep'

import type { StepOwnProps } from './deploymentTemplateWizardTypes'
import type { AllProps as Props } from './types'

import './deploymentTemplateWizard.scss'

const steps = [instancesStepDefinition, indexManagementStepDefinition, stackFeaturesStepDefinition]

const WizardSteps = createCuiWizard<StepOwnProps>()

class DeploymentTemplateWizard extends Component<Props> {
  componentDidMount() {
    this.props.fetchSnapshotRepositories()
  }

  render() {
    const { isLoading, instanceTypes, instanceConfigurations, prompt } = this.props

    if (isLoading || instanceConfigurations == null || instanceTypes == null) {
      return <EuiLoadingSpinner data-test-id='wizard-loading' />
    }

    return (
      <div>
        {prompt}
        {prompt && <EuiSpacer size='xl' />}
        {this.renderWizard()}
      </div>
    )
  }

  renderWizard() {
    const {
      error,
      isSaving,
      saveButtonLabel,
      regionId,
      template,
      instanceConfigurations,
      updateDeploymentTemplate,
    } = this.props

    const initialStepDefinition = this.getInitialStepDefinition()

    const stepProps = {
      instanceConfigurations: instanceConfigurations!, // not null if we reach this method
      template,
      updateDeploymentTemplate,
      regionId,
    }

    return (
      <EuiFlexGroup>
        <EuiFlexItem className='deploymentTemplateWizard'>
          <EuiPanel paddingSize='l'>
            <WizardSteps
              steps={steps}
              stepProps={stepProps}
              completeButtonLabel={saveButtonLabel}
              completeButtonHelpComponent={
                <ApiRequestExample
                  method='POST'
                  endpoint={createDeploymentTemplateV2Url({ region: regionId })}
                  body={template}
                />
              }
              onComplete={this.props.onSave}
              isBusyCompleting={isSaving}
              completionError={error}
              initialStep={initialStepDefinition}
            />
          </EuiPanel>
        </EuiFlexItem>
        {this.renderArchitecture()}
      </EuiFlexGroup>
    )
  }

  renderArchitecture() {
    return (
      <DeploymentTemplateArchitectureViz
        template={this.props.template}
        instanceConfigurations={this.props.instanceConfigurations}
        render={(className, content) => (
          <EuiFlexItem grow={false} className={className}>
            {content}
          </EuiFlexItem>
        )}
      />
    )
  }

  getInitialStepDefinition() {
    const { initialStep } = this.props

    switch (initialStep) {
      case `indexManagement`:
        return indexManagementStepDefinition
      default:
        return undefined
    }
  }
}

export default withErrorBoundary(DeploymentTemplateWizard)
