/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import {
  EuiCode,
  EuiDescribedFormGroup,
  EuiFieldPassword,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from '@elastic/eui'

import DocLink from '../../../../../../../components/DocLink'
import { CuiFormField } from '../../../../../../../cui/forms'
import { labels, messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

interface Props {
  setFieldValue: (key: string, value: any) => void
}

const BindCredentials: FunctionComponent<Props> = ({ setFieldValue }) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.bindCredentials} />
      </h3>
    }
    description={
      <Fragment>
        <EuiText size='s'>
          <FormattedMessage
            {...sections.bindCredentialsDescription}
            values={{
              learnMore: (
                <DocLink link='ldapProviderBindCredentialsDocLink'>
                  <FormattedMessage {...messages.learnMore} />
                </DocLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='m' />

        <Field name='bind_anonymously'>
          {({ field: { name, onBlur, value }, form: { values } }) => (
            // We have to use setFieldValue because EuiSwitch
            // doesn't call onChange with an event
            <EuiSwitch
              label={<FormattedMessage {...labels.bindAnonymously} />}
              name={name}
              onChange={() => setFieldValue('bind_anonymously', !value)}
              onBlur={onBlur}
              checked={value}
              disabled={values.search_mode === 'template'}
            />
          )}
        </Field>

        <EuiSpacer size='m' />

        <EuiText size='xs'>
          <FormattedMessage {...sections.bindCredentialsTemplateNote} />
        </EuiText>
      </Fragment>
    }
  >
    <CuiFormField
      label={<FormattedMessage {...labels.bindDn} />}
      name='bind_dn'
      helpText={
        <FormattedMessage
          {...labels.bindDnHelpText}
          values={{
            example: <EuiCode>CN=Administrator, CN=Users</EuiCode>,
          }}
        />
      }
      isDisabled={({ bind_anonymously, search_mode }) =>
        search_mode === 'template' || bind_anonymously
      }
    />
    <CuiFormField
      label={<FormattedMessage {...labels.bindPassword} />}
      name='bind_password'
      component={EuiFieldPassword}
      isDisabled={({ bind_anonymously, search_mode }) =>
        search_mode === 'template' || bind_anonymously
      }
    />
  </EuiDescribedFormGroup>
)

export default BindCredentials
