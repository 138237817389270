/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@/cui'

import DangerButton from '../../../DangerButton'

import type { AsyncRequestState } from '@/types'

interface Props {
  username: string
  organizationId: string
  convertToInvoicing: (email: string) => void
  convertToInvoicingRequest: AsyncRequestState
  resetConvertToInvoicing: () => void
}

const messages = defineMessages({
  convertToInvoicing: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.button-text`,
    defaultMessage: `Convert account to invoicing`,
  },
  convertToInvoicingModalTitle: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-title`,
    defaultMessage: `Convert account to invoicing?`,
  },
  convertToInvoicingModalBody: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-message`,
    defaultMessage: `You're about to change account to use invoices. This action cannot be undone from the UI.`,
  },
  convertToInvoicingModalConfirm: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-confirm`,
    defaultMessage: `Yes, change account type`,
  },
  convertToInvoicingModalRequested: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.action-requested`,
    defaultMessage: `Account converted to invoicing.`,
  },
})

class ConvertOrganizationToInvoicingButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetConvertToInvoicing()
  }

  render() {
    const { convertToInvoicing, convertToInvoicingRequest, username } = this.props

    // In a real SaaS deployment that's using Okta SSO, we'll always have an email as the username,
    // but this allows testing in dev environments
    const email = username.includes('@') ? username : username + '@elastic.co'

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          size='s'
          onConfirm={() => convertToInvoicing(email)}
          spin={convertToInvoicingRequest.inProgress}
          modal={{
            title: <FormattedMessage {...messages.convertToInvoicingModalTitle} />,
            body: <FormattedMessage {...messages.convertToInvoicingModalBody} />,
            confirmButtonText: <FormattedMessage {...messages.convertToInvoicingModalConfirm} />,
          }}
        >
          <FormattedMessage {...messages.convertToInvoicing} />
        </DangerButton>

        {convertToInvoicingRequest.error && (
          <Fragment>
            <EuiSpacer size='s' />

            <CuiAlert size='s' type='error' iconType='alert'>
              {convertToInvoicingRequest.error}
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default ConvertOrganizationToInvoicingButton
