/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { callStoredProcedure } from '../../../../../actions/storedProcedures'

import EnsurePremiumButton from './EnsurePremiumButton'

import type { SaasLevel } from '../../../../../types'

interface StateProps {}

type DispatchProps = {
  ensurePremium: () => void
}

type ConsumerProps = {
  organizationId: string
  level: SaasLevel
  ensureLevel: SaasLevel
  spin?: boolean
}

const mapStateToProps = (): StateProps => ({})

const mapDispatchToProps = (
  dispatch,
  { organizationId, ensureLevel }: ConsumerProps,
): DispatchProps => ({
  ensurePremium: () => {
    if (ensureLevel === `standard`) {
      return
    }

    return dispatch(
      callStoredProcedure({
        procedureName: `ensure_paying_level`,
        parameters: [organizationId, ensureLevel],
        organizationId,
      }),
    )
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EnsurePremiumButton)
