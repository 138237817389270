/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTextColor, EuiToolTip } from '@elastic/eui'

import PlatformStatsTile from './'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent, ReactElement, ReactNode } from 'react'

type Values = {
  happy?: number
  sad?: number
}

interface Props {
  intl: IntlShape
  title: ReactElement
  values?: Values | null
  happyWrapper?: (props: { children: ReactElement }) => ReactNode
  sadWrapper?: (props: { children: ReactElement }) => ReactNode
  isLoading?: boolean
}

const messages = defineMessages({
  healthy: {
    id: 'platform-health-stats-tile.healthy',
    defaultMessage: 'Healthy',
  },
  unhealthy: {
    id: 'platform-health-stats-tile.unhealthy',
    defaultMessage: 'Unhealthy',
  },
})

const PlatformHealthStatsTile: FunctionComponent<Props> = ({
  intl: { formatMessage },
  title,
  values,
  happyWrapper = ({ children }) => children,
  sadWrapper = ({ children }) => children,
}) => (
  <PlatformStatsTile
    title={title}
    value={
      values && (
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          {hasHealthy(values) && (
            <EuiFlexItem grow={false}>
              {happyWrapper({
                children: (
                  <EuiToolTip position='right' content={formatMessage(messages.healthy)}>
                    <EuiTextColor color='success'>
                      <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                        <EuiFlexItem grow={false}>{values.happy}</EuiFlexItem>

                        <EuiFlexItem grow={false}>
                          <EuiIcon type='check' />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiTextColor>
                  </EuiToolTip>
                ),
              })}
            </EuiFlexItem>
          )}

          {hasUnhealthy(values) && (
            <EuiFlexItem grow={false}>
              {sadWrapper({
                children: (
                  <EuiToolTip position='right' content={formatMessage(messages.unhealthy)}>
                    <EuiTextColor color='danger'>
                      <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                        <EuiFlexItem grow={false}>{values.sad}</EuiFlexItem>

                        <EuiFlexItem grow={false}>
                          <EuiIcon type='alert' />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiTextColor>
                  </EuiToolTip>
                ),
              })}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      )
    }
  />
)

export default injectIntl(PlatformHealthStatsTile)

function hasHealthy(values: Values): boolean {
  return values.happy != null && values.happy > 0
}

function hasUnhealthy(values: Values): boolean {
  return values.sad != null && values.sad > 0
}
