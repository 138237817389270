/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import {
  getAppSearchToEnterpriseSearchMigrationProgress,
  getCluster,
  getDeploymentTemplate,
  getStackDeployment,
} from '../../../reducers'
import { getVersion } from '../../../reducers/clusters'
import { isFeatureActivated } from '../../../selectors'
import { withStackDeploymentRouteParams } from '../../StackDeploymentEditor'
import Feature from '../../../lib/feature'

import DeploymentOverview from './Overview'

import type { StateProps, DispatchProps, OwnProps } from './Overview'

const mapStateToProps = (
  state: any,
  { regionId, deploymentId, stackDeploymentId }: OwnProps,
): StateProps => {
  const deployment = getCluster(state, regionId, deploymentId!)!
  const { deploymentTemplateId, _raw } = deployment
  const version = getVersion(_raw)

  return {
    deployment,
    deploymentTemplate: getDeploymentTemplate(state, regionId, deploymentTemplateId!, version),
    version,
    hideClusterInsteadOfDelete: isFeatureActivated(state, Feature.hideClusterInsteadOfDelete),
    hideClusterInsteadOfStop: isFeatureActivated(state, Feature.hideClusterInsteadOfStop),
    showNativeMemoryPressure: isFeatureActivated(state, Feature.showNativeMemoryPressure),
    stackDeployment: getStackDeployment(state, stackDeploymentId),
    appSearchToEnterpriseSearchProgress: stackDeploymentId
      ? getAppSearchToEnterpriseSearchMigrationProgress(state, stackDeploymentId)
      : undefined,
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(
    withTransaction(`Deployment overview`, `component`)(DeploymentOverview),
  ),
)
