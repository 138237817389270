/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { Component } from 'react'
import { noop } from 'lodash'
import raf from 'raf'

import type { ReactNode } from 'react'

const cache = new Map()

interface Props {
  children: NonNullable<ReactNode>
}

interface State {
  wait: boolean
}

export function createNonBlockingRenderLoop({
  key,
  concurrencyLevel = 1,
}: {
  key: string
  concurrencyLevel: number
}) {
  if (cache.has(key)) {
    return cache.get(key)
  }

  let slots = concurrencyLevel
  const isBusy = () => slots < 1
  const takeSlot = () => slots--
  const releaseSlot = () => slots++

  class NonBlockingRenderLoop extends Component<Props, State> {
    state: State = {
      wait: true,
    }

    componentDidMount() {
      this.enqueue()
    }

    componentWillUnmount() {
      this.stop()
    }

    render() {
      return this.state.wait ? null : this.props.children
    }

    enqueue() {
      if (isBusy()) {
        this.defer(() => this.enqueue())
      } else {
        takeSlot()
        this.defer(() => this.dequeue())
      }
    }

    dequeue() {
      this.setState({ wait: false })
      releaseSlot()
    }

    defer(fn) {
      raf(() => {
        if (this.defer !== noop) {
          fn()
        }
      })
    }

    stop() {
      this.defer = noop

      if (this.state.wait) {
        releaseSlot()
      }
    }
  }

  cache.set(key, NonBlockingRenderLoop)
  return NonBlockingRenderLoop
}
