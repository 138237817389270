/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiFormRow, EuiButtonGroup } from '@elastic/eui'

import { ChartType } from '../../../types'

import type { FunctionComponent } from 'react'
import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

const ChartTypeToggle: FunctionComponent<Props & WrappedComponentProps> = ({
  onChange,
  isLoading,
  selectedChartType,
  intl: { formatMessage },
}) => {
  const options = [
    {
      id: ChartType.BAR,
      label: formatMessage({
        id: 'billing-usage.filters.chartType.bar',
        defaultMessage: 'Bar chart',
      }),
      iconType: 'visBarVertical',
    },
    {
      id: ChartType.LINE,
      label: formatMessage({
        id: 'billing-usage.filters.chartType.line',
        defaultMessage: 'Line chart',
      }),
      iconType: 'stats',
    },
  ]

  return (
    <EuiFormRow
      fullWidth={true}
      display='row'
      hasEmptyLabelSpace={true}
      style={{ maxWidth: '77px' }}
    >
      <EuiButtonGroup
        legend={formatMessage({
          id: 'billing-usage.filters.chartType',
          defaultMessage: 'Chart Type',
        })}
        options={options}
        idSelected={selectedChartType}
        onChange={(chartType: ChartType) => onChange({ chartType })}
        isIconOnly={true}
        buttonSize='m'
        isDisabled={isLoading}
        isFullWidth={true}
      />
    </EuiFormRow>
  )
}

export default injectIntl(ChartTypeToggle)
