/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getCommonProfile } from '@/reducers/profile'
import { fetchDeploymentsCosts } from '@/actions/billing'
import {
  fetchDeploymentsCostsRequest,
  fetchAccountItemsCostsRequest,
  getAccountDeploymentsCosts,
} from '@/reducers'

import CostAnalysis from './CostAnalysis'

import type { ThunkDispatch, UserProfile } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state, { organizationId }: ConsumerProps): StateProps => ({
  profile: getCommonProfile(state, organizationId) as UserProfile,
  accountCosts: getAccountDeploymentsCosts(state, organizationId),
  fetchDeploymentsCostsRequest: fetchDeploymentsCostsRequest(state),
  fetchAccountItemsCostsRequest: fetchAccountItemsCostsRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchDeploymentsCosts: (args) => dispatch(fetchDeploymentsCosts(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CostAnalysis)
