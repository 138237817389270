/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get, flatMap } from 'lodash'

import {
  makeFilterQueryTransformer,
  transformResourceQuery,
} from '../../StackDeploymentSearch/DeploymentFilterContext/queryParser'

import type { SliderInstanceType } from '../../../types'

export { transformIdQuery } from '../../StackDeploymentSearch/DeploymentFilterContext/queryParser'

export const getFilterQuery = makeFilterQueryTransformer(transformObject)

function transformObject({
  sliderInstanceTypes,
  node,
}: {
  sliderInstanceTypes?: SliderInstanceType[]
  node: any
}) {
  const healthy_configuration = get(node, [`match`, `healthy_configuration`, `query`])

  if (typeof healthy_configuration === `string`) {
    return latestAttemptBoolQuery({
      sliderInstanceTypes,
      path: `healthy`,
      value: healthy_configuration,
    })
  }

  const system_initiated = get(node, [`match`, `system_initiated`, `query`])

  if (typeof system_initiated === `string`) {
    return latestAttemptExistsQuery({
      sliderInstanceTypes,
      path: `source.admin_id`,
      value: system_initiated,
    })
  }

  const source = get(node, [`match`, `source`, `query`])

  if (typeof source === `string`) {
    return latestAttemptAnyTermQuery({
      sliderInstanceTypes,
      path: `source.action`,
      sources: source.split(/\s+/),
    })
  }

  const since = get(node, [`match`, `since`, `query`])

  if (typeof since === `string`) {
    return {
      bool: {
        should: since.split(/\s+/).map((pattern) =>
          latestAttemptRangeQuery({
            sliderInstanceTypes,
            pendingPath: `attempt_start_time`,
            currentPath: `attempt_end_time`,
            query: { gte: parseDateQuery(pattern) },
          }),
        ),
      },
    }
  }

  const until = get(node, [`match`, `until`, `query`])

  if (typeof until === `string`) {
    return {
      bool: {
        should: until.split(/\s+/).map((pattern) =>
          latestAttemptRangeQuery({
            sliderInstanceTypes,
            pendingPath: `attempt_start_time`,
            currentPath: `attempt_end_time`,
            query: { lte: parseDateQuery(pattern) },
          }),
        ),
      },
    }
  }
}

/* we support ES date math:
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#date-math
 */
function parseDateQuery(query) {
  const rshortdiff = /^(\d+)([yMwdhHms])$/

  const shortdiff = query.match(rshortdiff)

  if (shortdiff) {
    const [, amount, unit] = shortdiff

    return `now-${amount}${unit}`
  }

  return query
}

function latestAttemptBoolQuery({ sliderInstanceTypes, path, value }) {
  const condition = value === `y` ? `true` : `false`

  return latestAttemptQuery({
    sliderInstanceTypes,
    pendingClause: ({ nestedPath }) => ({
      bool: {
        should: [
          { match: { [`${nestedPath}.info.plan_info.pending.${path}`]: { query: condition } } },
        ],
      },
    }),
    currentClause: ({ nestedPath }) => ({
      bool: {
        should: [
          { match: { [`${nestedPath}.info.plan_info.current.${path}`]: { query: condition } } },
        ],
      },
    }),
  })
}

function latestAttemptAnyTermQuery({ sliderInstanceTypes, path, sources }) {
  return latestAttemptQuery({
    sliderInstanceTypes,
    pendingClause: ({ nestedPath }) => ({
      bool: {
        should: flatMap(sources, (value) => [
          { term: { [`${nestedPath}.info.plan_info.pending.${path}`]: { value } } },
        ]),
      },
    }),
    currentClause: ({ nestedPath }) => ({
      bool: {
        should: flatMap(sources, (value) => [
          { term: { [`${nestedPath}.info.plan_info.current.${path}`]: { value } } },
        ]),
      },
    }),
  })
}

function latestAttemptExistsQuery({ sliderInstanceTypes, path, value }) {
  const predicate = value === `y` ? `should` : `must_not`

  return latestAttemptQuery({
    sliderInstanceTypes,
    pendingClause: ({ nestedPath }) => ({
      bool: {
        [predicate]: [{ exists: { field: `${nestedPath}.info.plan_info.pending.${path}` } }],
      },
    }),
    currentClause: ({ nestedPath }) => ({
      bool: {
        [predicate]: [{ exists: { field: `${nestedPath}.info.plan_info.current.${path}` } }],
      },
    }),
  })
}

function latestAttemptRangeQuery({ sliderInstanceTypes, pendingPath, currentPath, query }) {
  return latestAttemptQuery({
    sliderInstanceTypes,
    pendingClause: ({ nestedPath }) => ({
      range: { [`${nestedPath}.info.plan_info.pending.${pendingPath}`]: query },
    }),
    currentClause: ({ nestedPath }) => ({
      range: { [`${nestedPath}.info.plan_info.current.${currentPath}`]: query },
    }),
  })
}

/* This function makes it so that queries against pending plans
 * don't try to match on the `current` plan fields.
 *
 * If we didn't do this — when there is a pending plan — we might match part of a
 * query on the `current` bits, and part of it on the `pending` bits.
 *
 * For instance, a query like `pending: y healthy_configuration:y` would match against a
 * pending plan where the last change succeeded, which is not what the user is looking for.
 * (They'd be actually looking for pending plans that haven't failed)
 *
 * Thus, we refine queries to ensure we query:
 *   - the `pending` side only if the `pending` field exists, and — more crucially —
 *   - the `current` side only if the `pending` field doesn't exists
 */
function latestAttemptQuery({ sliderInstanceTypes, pendingClause, currentClause }) {
  return transformResourceQuery({
    sliderInstanceTypes,
    transform: ({ nestedPath }) => {
      const isPending = {
        exists: {
          field: `${nestedPath}.info.plan_info.pending`,
        },
      }

      return {
        bool: {
          should: [
            {
              bool: {
                must: [isPending, pendingClause({ nestedPath })],
              },
            },
            {
              bool: {
                must_not: [isPending],
                must: [currentClause({ nestedPath })],
              },
            },
          ],
        },
      }
    },
  })
}
