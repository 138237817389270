/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchTrustedEnvs } from '@/actions/trustManagement'
import { fetchTrustedEnvsRequest, getTrustedEnvs } from '@/reducers'

import ReusableTrustRelationships from './ReusableTrustRelationships'

import type { ReduxState, AsyncRequestState } from '../../../../types'
import type { ElasticsearchClusterTrustSettings } from '../../../../lib/api/v1/types'

export interface StateProps {
  fetchTrustedEnvsRequest: AsyncRequestState
  trustedEnvs: ElasticsearchClusterTrustSettings
}

export interface DispatchProps {
  fetchTrustedEnvs: () => void
}

export interface ConsumerProps {
  onSelected: (value) => void
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state: ReduxState): StateProps => ({
  fetchTrustedEnvsRequest: fetchTrustedEnvsRequest(state),
  trustedEnvs: getTrustedEnvs(state),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchTrustedEnvs: () => dispatch(fetchTrustedEnvs()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ReusableTrustRelationships)
