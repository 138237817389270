/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniq } from 'lodash'
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiLink } from '@elastic/eui'

import { CuiTable } from '../../../cui'
import RunnerHealth from '../../RunnerHealth'

import RunnerName from './RunnerName'
import RunnerTags from './RunnerTags'
import HostRoles from './HostRoles'
import RunnerLoggingSettingsButton from './RunnerLoggingSettingsButton'

import type { CuiTableColumn, CuiExpansibleTag } from '../../../cui'
import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'
import type { Runner } from '../../../types'

interface Props {
  intl: IntlShape
  runners: Runner[]
  fullWidth?: boolean
  getActiveTags: (field: string) => any[]
  onTagClick: (field: string, tag: CuiExpansibleTag) => void
  onZoneClick: (zoneId: string | undefined) => void
  regionId: string
  totalCount: number
  initialLoading: boolean
}

const messages = defineMessages({
  actions: {
    id: `runners-table.actions`,
    defaultMessage: `Actions`,
  },
})

const RunnersTable: FunctionComponent<Props> = ({
  intl: { formatMessage },
  runners,
  fullWidth,
  getActiveTags,
  onTagClick,
  onZoneClick,
  regionId,
  totalCount,
  initialLoading,
}) => {
  const hostIdColumn: CuiTableColumn<Runner> = {
    label: <FormattedMessage id='runners-table.host' defaultMessage='Host' />,
    render: (runner) => <RunnerName runner={runner} />,
    sortKey: `runner_id`,
    width: `230px`,
  }

  const columns: Array<CuiTableColumn<Runner>> = [
    hostIdColumn,

    {
      id: 'health',
      render: (runner) => <RunnerHealth runner={runner} />,
      label: <FormattedMessage id='runners-table.status' defaultMessage='Host status' />,
      width: `130px`,
    },

    {
      label: <FormattedMessage id='runners-table.zone' defaultMessage='Zone' />,

      // This is explicitly typed because the optional-column code style defeats TS
      render: (runner: Runner) => (
        <EuiLink onClick={() => onZoneClick(runner.zone)}>
          {runner.zone || (
            <FormattedMessage id='runners-table.unknown-zone' defaultMessage='(Unknown)' />
          )}
        </EuiLink>
      ),
      sortKey: `zone`,
      width: `130px`,
    },

    {
      label: <FormattedMessage id='runners-table.roles' defaultMessage='Roles' />,
      render: (runner) => (
        <HostRoles
          getActiveTags={getActiveTags}
          onTagClick={(field, tag) => {
            if (tag === 'allocator') {
              return
            }

            if (tag === 'proxy') {
              return
            }

            onTagClick(field, tag)
          }}
          runner={runner}
          maxInlineTags={5}
        />
      ),
      sortKey: getHostRolesSortKey,
      textOnly: false,
    },

    {
      label: <FormattedMessage id='runners-table.containers' defaultMessage='Containers' />,
      render: (runner) => (
        <RunnerTags
          title={<FormattedMessage id='runners-table.containers' defaultMessage='Containers' />}
          field='container'
          tags={getHostContainers(runner)}
          isActiveTag={(field: string, tagText: string) => getActiveTags(field).includes(tagText)}
          onTagClick={onTagClick}
        />
      ),
      sortKey: getHostContainersSortKey,
      textOnly: false,
    },

    {
      mobile: {
        label: formatMessage(messages.actions),
      },
      render: (runner) => (
        <RunnerLoggingSettingsButton regionId={regionId} runnerId={runner.runner_id} />
      ),
      width: `40px`,
    },
  ]

  return (
    <CuiTable<Runner>
      rows={runners}
      getRowId={(runner) => runner.runner_id}
      fullWidth={fullWidth}
      pageSize={25}
      showMatchCount={true}
      totalCount={totalCount}
      matchType={<FormattedMessage id='runners-table.match-type' defaultMessage='host' />}
      matchTypePlural={
        <FormattedMessage id='runners-table.match-type-plural' defaultMessage='hosts' />
      }
      columns={columns}
      initialSort={hostIdColumn}
      initialLoading={initialLoading}
    />
  )
}

export default injectIntl(RunnersTable)

function getHostRolesSortKey(runner: Runner): string {
  return runner.roles?.map((role) => role.role_name).join(' ') || ''
}

function getHostContainers(runner: Runner): string[] {
  return uniq(runner.containers.map((c) => c.container_name)).sort()
}

function getHostContainersSortKey(runner: Runner): string {
  return getHostContainers(runner).join(' ')
}
