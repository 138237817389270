/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*
  username@domain.tld                                     // up to 256 characters in total
  [a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+                       // username (up to 64 characters)
  @                                                       // @
  [a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?           // domain (up to 95 characters)
  (?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+    // .tld (up to 95 characters)

  user@domain is a valid email but we force domain.tld
  */
export default function validateEmail(email: string): boolean {
  // Fail fast if email length is larger than allowed to avoid expensive regex
  if (!validateEmailLength(email)) {
    return false
  }

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{1,64}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,93}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,93}[a-zA-Z0-9])?)+$/i
  return emailRegex.test(email)
}

export function isElasticStaff(email: string): boolean {
  const emailRegex = /@elastic.co$/i
  return emailRegex.test(email)
}

// ts-unused-exports:disable-next-line
export function validateEmailLength(email: string): boolean {
  return email.length <= 256
}
