/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiSwitch } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'
import type { StackVersionConfig } from '../../../../lib/api/v1/types'
import type { Region } from '../../../../types'

export type StateProps = {
  whitelist: string[] | null
}

export type DispatchProps = {
  addWhitelistedVersion: (args: { versionInfo: StackVersionConfig; region: Region }) => void
  removeWhitelistedVersion: (args: {
    versionQuery?: number
    versionInfo: StackVersionConfig
    region: Region
  }) => void
}

export type ConsumerProps = {
  versionInfo: StackVersionConfig
  region: Region
}

export type Props = WrappedComponentProps & ConsumerProps & DispatchProps & StateProps

const messages = defineMessages({
  switchWhitelisting: {
    id: 'elastic-stack-versions.whitelist-switch',
    defaultMessage: 'Switch whitelisting',
  },
})

class WhitelistSwitch extends Component<Props> {
  render() {
    const {
      intl: { formatMessage },
      versionInfo,
      whitelist,
    } = this.props

    if (!versionInfo.version) {
      return
    }

    if (!whitelist) {
      return
    }

    return (
      <EuiSwitch
        checked={whitelist.includes(versionInfo.version)}
        showLabel={false}
        label={formatMessage(messages.switchWhitelisting)}
        onChange={this.switchWhitelist}
      />
    )
  }

  switchWhitelist = () => {
    const { removeWhitelistedVersion, addWhitelistedVersion, region, whitelist, versionInfo } =
      this.props
    const checked = versionInfo.version && whitelist!.includes(versionInfo.version)

    if (checked) {
      removeWhitelistedVersion({
        region,
        versionInfo,
      })
    } else {
      addWhitelistedVersion({
        region,
        versionInfo,
      })
    }
  }
}

export default injectIntl(WhitelistSwitch)
