/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { EuiDescriptionList, EuiLink, EuiPopover, EuiText } from '@elastic/eui'

import { numericDateTime } from '@/config/dates'
import CuiPrice from '@/cui/formatters/CuiPrice'
import { getConsumptionTypeDisplayName } from '@/lib/billing'

import type { ReactChild, ReactElement, ReactNode } from 'react'
import type { LineItem } from '@/lib/api/v1/types'

import './detailsGridPopover.scss'

interface Props {
  details: LineItem
}

interface State {
  isOpen: boolean
}

class DetailsGridPopover extends PureComponent<Props, State> {
  state = {
    isOpen: false,
  }

  render(): ReactElement {
    const { isOpen } = this.state

    return (
      <EuiPopover
        button={this.renderPopoverButton()}
        panelClassName='prepaid-account-details-popover'
        isOpen={isOpen}
        closePopover={() => this.setState({ isOpen: false })}
      >
        <EuiDescriptionList
          className='prepaid-account-details-popover-list'
          listItems={this.getDetails()}
          style={{ maxWidth: '440px' }}
          type='column'
        />
      </EuiPopover>
    )
  }

  renderPopoverButton(): ReactElement {
    return (
      <EuiLink onClick={this.togglePopover} data-test-id='details-link'>
        <FormattedMessage id='prepaid-account-details-popover.details' defaultMessage='Details' />
      </EuiLink>
    )
  }

  renderDetailsTitle(title: ReactChild): ReactElement {
    return (
      <EuiText size='s' className='prepaid-account-details-popover-title'>
        {title}
      </EuiText>
    )
  }

  renderDetailsDescription(description: ReactChild): ReactElement {
    return (
      <EuiText size='s' className='prepaid-account-details-popover-description'>
        {description}
      </EuiText>
    )
  }

  getDetails(): Array<{
    title: NonNullable<ReactNode>
    description: NonNullable<ReactNode>
  }> {
    const {
      details: {
        active,
        discount,
        ecu_price,
        currency,
        start,
        type,
        reason,
        sales_order_number,
        purchased_balance,
        external_reseller_id,
      },
    } = this.props

    const isCreditItem = type === 'credit'
    const isResellerItem = !!external_reseller_id

    const details = [
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.start-date'
            defaultMessage='Start date'
          />,
        ),
        description: this.renderDetailsDescription(
          <FormattedDate value={start} {...numericDateTime} />,
        ),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.initial-balance'
            defaultMessage='Paid amount'
          />,
        ),
        description: this.renderDetailsDescription(
          <CuiPrice
            data-test-id='purchased-balance'
            value={purchased_balance!}
            currency={currency}
            unit='none'
          />,
        ),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.list-price-at-purchase'
            defaultMessage='List price at purchase'
          />,
        ),
        description: this.renderDetailsDescription(
          <CuiPrice
            data-test-id='purchased-price'
            value={ecu_price!}
            unit='none'
            currency={currency}
          />,
        ),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            data-test-id='discount-rate'
            id='prepaid-account-details-popover.discount-rate'
            defaultMessage='Discount rate'
          />,
        ),
        description: this.renderDetailsDescription(`${discount}%`),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.status' defaultMessage='Status' />,
        ),
        description: this.renderDetailsDescription(this.getItemStatus({ active })),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.id'
            defaultMessage='Sales Order Number'
          />,
        ),
        isVisible: !isCreditItem && Boolean(sales_order_number),
        description: this.renderDetailsDescription(sales_order_number!),
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.type' defaultMessage='Type' />,
        ),
        description: this.renderDetailsDescription(getConsumptionTypeDisplayName(type)),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.reason' defaultMessage='Reason' />,
        ),
        description: this.renderDetailsDescription(reason!),
        isVisible: Boolean(reason),
      },
    ]

    return details.filter(({ isVisible }) => isVisible)
  }

  getItemStatus({ active }: { active: boolean }): ReactElement {
    return active ? (
      <FormattedMessage id='prepaid-account-details-popover.active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='prepaid-account-details-popover.inactive' defaultMessage='Inactive' />
    )
  }

  togglePopover = (): void => {
    this.setState({ isOpen: true })
  }
}

export default DetailsGridPopover
