/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { get } from 'lodash'

import ClauseActions from './ClauseActions'
import ClauseConnector from './ClauseConnector'
import ClauseFields from './ClauseFields'
import ClauseRemover from './ClauseRemover'

import type {
  ClauseConnector as ClauseConnectorType,
  InnerClause,
  OuterClause as OuterClauseType,
} from '../../../../../types/instanceConfigurationTypes'
import type { FunctionComponent } from 'react'

type Props = {
  errorsInClauses: unknown
  isRoot: boolean
  outerClause: OuterClauseType
  appendInnerClause: (outerClause: OuterClauseType, connector: ClauseConnectorType) => void
  deleteOuterClause: (outerClause: OuterClauseType) => void
  deleteInnerClause: (outerClause: OuterClauseType, innerClause: InnerClause) => void
  setOuterClauseConnector: (connector: ClauseConnectorType) => void
  setInnerClauseConnector: (outerClause: OuterClauseType, connector: ClauseConnectorType) => void
  setTagKey: (outerClause: OuterClauseType, index: number, value: string) => void
  setTagValue: (outerClause: OuterClauseType, index: number, value: string) => void
}

const OuterClause: FunctionComponent<Props> = ({
  errorsInClauses,
  isRoot,
  outerClause,
  appendInnerClause,
  deleteOuterClause,
  deleteInnerClause,
  setOuterClauseConnector,
  setInnerClauseConnector,
  setTagKey,
  setTagValue,
}) => {
  const [firstInnerClause, ...otherInnerClauses] = outerClause.innerClauses

  const firstInnerClauseErrors = get(errorsInClauses, [firstInnerClause.id, `fields`])

  return (
    <div>
      <div className='topologyFilterAllocators-filterClause-details'>
        <ClauseConnector
          isRoot={isRoot}
          clause={outerClause}
          setConnector={setOuterClauseConnector}
        />

        <ClauseFields
          errors={firstInnerClauseErrors}
          outerClause={outerClause}
          innerClause={firstInnerClause}
          innerIndex={0}
          setTagKey={setTagKey}
          setTagValue={setTagValue}
        />

        <ClauseRemover outerClause={outerClause} deleteClause={deleteOuterClause} />
      </div>
      <ul className='topologyFilterAllocators-filterClauses'>
        {otherInnerClauses.map((innerClause, i) => {
          const innerIndex = i + 1 // because the first inner is stripped out above
          const innerClauseErrors = get(errorsInClauses, [innerClause.id, `fields`])

          return (
            <li key={innerIndex} className='topologyFilterAllocators-filterClause'>
              <div className='topologyFilterAllocators-filterClause-details'>
                <ClauseConnector
                  clause={innerClause}
                  setConnector={(connector) => setInnerClauseConnector(outerClause, connector)}
                />

                <ClauseFields
                  errors={innerClauseErrors}
                  outerClause={outerClause}
                  innerClause={innerClause}
                  innerIndex={innerIndex}
                  setTagKey={setTagKey}
                  setTagValue={setTagValue}
                />

                <ClauseRemover
                  outerClause={outerClause}
                  innerClause={innerClause}
                  deleteClause={deleteInnerClause}
                />
              </div>
            </li>
          )
        })}
        <li>
          <ClauseActions
            level='inner'
            appendClause={(connector) => appendInnerClause(outerClause, connector)}
          />
        </li>
      </ul>
    </div>
  )
}

export default OuterClause
