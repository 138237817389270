/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { AllocatorInstance } from '../../../types'

type Props = {
  plan: AllocatorInstance['plan']
  healthy: boolean
}

const HealthStatus: FunctionComponent<Props> = ({ plan, healthy }) => {
  if (plan.isPending) {
    return (
      <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon type='dot' color='warning' />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <FormattedMessage
            id='allocator-instances-health-status.pending'
            defaultMessage='Configuring'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  if (!healthy) {
    return (
      <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon type='dot' color='danger' />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <FormattedMessage
            id='allocator-instances-health-status.unhealthy'
            defaultMessage='Unhealthy'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon type='dot' color='success' />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <FormattedMessage id='allocator-instances-health-status.healthy' defaultMessage='Healthy' />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default HealthStatus
