/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withStackDeploymentRouteParams } from '../../StackDeploymentEditor'
import {
  getClusterCredentials,
  fetchResetPasswordStatus,
  getDeploymentTemplate,
  getStackDeployment,
  getStackDeploymentCreateResponse,
} from '../../../reducers'
import { resetPassword } from '../../../actions/clusters'
import { getConfigForKey } from '../../../selectors'
import {
  getGettingStartedType,
  getFirstEsClusterFromGet,
  getDeploymentTemplateId,
  getVersion,
} from '../../../lib/stackDeployments/selectors'
import { getEsCredentialsFromCreateResponse } from '../../../lib/stackDeployments/credentials'

import DeploymentGettingStarted from './DeploymentGettingStarted'

import type { StateProps, ConsumerProps, DispatchProps } from './DeploymentGettingStarted'

const mapStateToProps = (
  state: any,
  { regionId, stackDeploymentId, match }: ConsumerProps,
): StateProps => {
  const stackDeployment = getStackDeployment(state, stackDeploymentId)
  const deploymentTemplateId = getDeploymentTemplateId({ deployment: stackDeployment! })
  const version = getVersion({ deployment: stackDeployment! })
  const instanceType = getGettingStartedType({ deployment: stackDeployment! })
  // If a createResponse exists, that means the deployment is being created, and the credentials
  // are in the response
  const createResponse = getStackDeploymentCreateResponse(state, stackDeployment!.id)
  const credentialsFromCreate = getEsCredentialsFromCreateResponse({ createResponse })
  const esCluster = getFirstEsClusterFromGet({ deployment: stackDeployment! })
  const refId = esCluster?.ref_id || null
  const id = esCluster?.id || null
  // Otherwise, credentials are saved in state with the associated ES cluster
  const credentialsFromState = refId && id ? getClusterCredentials(state, id, refId) : null

  return {
    match,
    deploymentTemplate: getDeploymentTemplate(state, regionId, deploymentTemplateId!, version),
    stackDeployment: getStackDeployment(state, stackDeploymentId),
    instanceType,
    resetPasswordStatus: fetchResetPasswordStatus(state, id, refId),
    credentials: credentialsFromCreate || credentialsFromState,
    isAnyAdminConsole: getConfigForKey(state, `APP_NAME`) === `adminconsole`,
  }
}

const mapDispatchToProps: DispatchProps = {
  resetPassword,
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(DeploymentGettingStarted),
)
