/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGrid, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import { getInstancesWithOverride } from '@/lib/stackDeployments/clusterInstances'

import DeploymentAlias from '../DeploymentAlias'
import DeploymentTags from '../DeploymentTags/DeploymentTags'
import DeploymentVersion from '../DeploymentVersion'
import CcsEditRemoteDeployments from '../CcsEditRemoteDeployments'
import { AppSearchDeploymentPageNotice } from '../../DeprecationNotices/AppsearchNotices'
import StackDeploymentName from '../../StackDeployments/StackDeploymentName'
import StackDeploymentNodesVisualization from '../../StackDeployments/StackDeploymentNodesVisualization'
import ResourceComments from '../../ResourceComments'
import AutoscalingAvailableCallout from '../../Autoscaling/AutoscalingAvailableCallout'
import AutoscalingStatusCallout from '../../Autoscaling/AutoscalingStatusCallout'
import IlmDeploymentMigrationCallout from '../../IlmMigration/IlmDeploymentMigrationCallout'
import FleetAvailableCallout from '../../Fleet/FleetAvailableCallout'
import InstanceOverrideCallout from '../../Deployment/InstanceOverrideCallout'
import AppSearchToEnterpriseSearchMigrationFlyout from '../../AppSearchToEnterpriseSearchMigration/AppSearchToEnterpriseSearchMigrationFlyout'
import { isCrossClusterSearch } from '../../../lib/deployments/ccs'
import {
  canEnableAutoscaling,
  getCloudId,
  getDeploymentTopologyInstances,
  isAutoscalingAvailable,
  isAutoscalingEnabledOnGet,
  isEveryResourceStarted,
} from '../../../lib/stackDeployments/selectors'
import { isSliderInstanceTypeSupportedInTemplate } from '../../../lib/sliders'
import CCSMigrationNotification from '../CCSMigrationNotification'
import ApplicationLinks from '../../StackDeployments/StackDeploymentApplicationLinks'
import DeploymentTemplate from '../../StackDeployments/DeploymentTemplate'

import CloudId from './CloudId'

import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type {
  ElasticsearchCluster,
  StackDeployment,
  AppSearchToEnterpriseSearchMigrationProgress,
} from '../../../types'
import type { DeploymentTemplateInfoV2 } from '../../../lib/api/v1/types'
import type { FunctionComponent } from 'react'

import './overview.scss'

export type StateProps = {
  deployment: ElasticsearchCluster
  deploymentTemplate?: DeploymentTemplateInfoV2
  version: string
  hideClusterInsteadOfDelete: boolean
  hideClusterInsteadOfStop: boolean
  showNativeMemoryPressure: boolean
  stackDeployment?: StackDeployment | null
  appSearchToEnterpriseSearchProgress?: AppSearchToEnterpriseSearchMigrationProgress
}

export interface DispatchProps {}

export type OwnProps = WithStackDeploymentRouteParamsProps

export type Props = StateProps & DispatchProps & OwnProps

const DeploymentOverview: FunctionComponent<Props> = ({
  deployment,
  deploymentTemplate,
  showNativeMemoryPressure,
  version,
  stackDeployment,
  appSearchToEnterpriseSearchProgress,
}) => {
  const { regionId, plan, deploymentTemplateId, isSystemOwned } = deployment

  // tally running ES, Kibana, and APM instances
  const instances = getDeploymentTopologyInstances({ deployment: stackDeployment! })
  const totalInstances = instances.length

  const instancesWithOverride = getInstancesWithOverride({
    instanceSummaries: instances,
    deploymentTemplate: deploymentTemplate!,
  })
  const pendingEs = plan.isPending || plan.waitingForPending

  const ccs = isCrossClusterSearch({
    deploymentTemplate,
    deploymentTemplateId,
    systemOwned: isSystemOwned,
  })

  const cloudId = getCloudId({ deployment: stackDeployment! })

  const supportsAppsearch = isSliderInstanceTypeSupportedInTemplate(`appsearch`, deploymentTemplate)

  const deploymentOverviewKey = `deploymentOverview-${regionId}-${deployment.id}`

  const displayAutoscalingAvailable =
    !isAutoscalingEnabledOnGet({ deployment: stackDeployment! }) &&
    isEveryResourceStarted({ deployment: stackDeployment! }) &&
    canEnableAutoscaling({ deploymentTemplate, version })

  const displayAutoscalingStatus =
    isAutoscalingAvailable(version) && isAutoscalingEnabledOnGet({ deployment: stackDeployment! })

  return (
    <div key={deploymentOverviewKey}>
      <IlmDeploymentMigrationCallout stackDeployment={stackDeployment} />
      <CCSMigrationNotification stackDeployment={stackDeployment} />
      <InstanceOverrideCallout instancesWithOverride={instancesWithOverride} />

      {supportsAppsearch && (
        <Fragment>
          <AppSearchDeploymentPageNotice deployment={stackDeployment!} />
          <EuiSpacer size='m' />
        </Fragment>
      )}

      {appSearchToEnterpriseSearchProgress?.showUI && (
        <AppSearchToEnterpriseSearchMigrationFlyout deployment={stackDeployment!} />
      )}

      {displayAutoscalingAvailable && (
        <Fragment>
          <AutoscalingAvailableCallout
            stackDeployment={stackDeployment!}
            deploymentTemplate={deploymentTemplate}
          />

          <EuiSpacer size='m' />
        </Fragment>
      )}

      {stackDeployment && deploymentTemplate && (
        <FleetAvailableCallout
          deployment={stackDeployment}
          deploymentTemplate={deploymentTemplate}
        />
      )}

      {displayAutoscalingStatus && <AutoscalingStatusCallout stackDeployment={stackDeployment!} />}

      <div>
        {pendingEs || (
          <Fragment>
            <EuiFlexGrid
              columns={3}
              className='overview-grid'
              data-test-id='deployment-overview-es-not-pending'
            >
              <EuiFlexItem>
                <StackDeploymentName deployment={stackDeployment!} />
              </EuiFlexItem>

              <EuiFlexItem>
                <DeploymentAlias deployment={stackDeployment!} />
              </EuiFlexItem>

              <EuiFlexItem>
                {!deployment.isStopped && version && (
                  <Fragment>
                    <EuiSpacer size='xs' />
                    <DeploymentVersion deployment={stackDeployment!} />
                  </Fragment>
                )}
              </EuiFlexItem>
              <EuiFlexItem className='deploymentOverview-Applications'>
                <ApplicationLinks
                  title={
                    <FormattedMessage id='applicationLinks.title' defaultMessage='Applications' />
                  }
                  deployment={stackDeployment!}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <DeploymentTemplate deployment={stackDeployment!} />
              </EuiFlexItem>
              {cloudId && (
                <EuiFlexItem>
                  <CloudId cloudId={cloudId} />
                </EuiFlexItem>
              )}
              <EuiFlexItem>
                <DeploymentTags deployment={stackDeployment!} />
              </EuiFlexItem>
            </EuiFlexGrid>
          </Fragment>
        )}
      </div>

      {ccs && (
        <Fragment>
          <EuiSpacer size='m' />
          <CcsEditRemoteDeployments regionId={regionId} deployment={stackDeployment!} />
        </Fragment>
      )}

      <EuiSpacer size='xl' />

      {totalInstances === 0 || (
        <StackDeploymentNodesVisualization
          title={
            <EuiTitle size='s'>
              <h3 data-test-id='deploymentOverview-zonesAndNodes'>
                <FormattedMessage id='deployment-info.instances' defaultMessage='Instances' />
              </h3>
            </EuiTitle>
          }
          deployment={stackDeployment!}
          showNativeMemoryPressure={showNativeMemoryPressure}
        />
      )}

      {/* eventually, we'll have _deployment level_ comments here,
       * and the ES ones will be relegated to just the ES cluster
       */}
      <ResourceComments
        spacerBefore={true}
        resourceType='elasticsearch'
        regionId={regionId}
        resourceId={deployment.id}
      />
    </div>
  )
}

export default DeploymentOverview
