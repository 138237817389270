/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import prettySize from '../../../lib/prettySize'

import type { FunctionComponent } from 'react'
import type { AllocatorInstance } from '../../../types'

type Props = {
  instance: AllocatorInstance
  isClusterHeader: boolean
}

const InstanceCapacity: FunctionComponent<Props> = ({ instance, isClusterHeader }) => {
  if (isClusterHeader) {
    return null
  }

  return <span>{prettySize(instance.capacity)}</span>
}

export default InstanceCapacity
