/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { SearchFilterConfig } from '@elastic/eui'

import type { IntlShape } from 'react-intl'

const messages = defineMessages({
  connectedLabel: {
    id: `control-plane-filter-context.connected-label`,
    defaultMessage: `Connected`,
  },
  disconnectedLabel: {
    id: `control-plane-filter-context.disconnected-label`,
    defaultMessage: `Disconnected`,
  },
  maintenanceLabel: {
    id: `control-plane-filter-context.maintenance-label`,
    defaultMessage: `Maintenance`,
  },
})

export function getFilters({ intl: { formatMessage } }: { intl: IntlShape }): SearchFilterConfig[] {
  const filters: SearchFilterConfig[] = [
    {
      type: `field_value_toggle_group`,
      field: `connected`,
      items: [
        {
          name: formatMessage(messages.connectedLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.disconnectedLabel),
          value: `n`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `maintenance`,
      items: [
        {
          name: formatMessage(messages.maintenanceLabel),
          value: `y`,
        },
      ],
    },
  ]

  return filters
}
