/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeployment,
  updateDeployment,
  resetUpdateDeployment,
} from '../../../actions/stackDeployments'
import {
  updateStackDeploymentRequest,
  fetchStackDeploymentRequest,
  getStackDeployment,
} from '../../../reducers'
import { withStackDeploymentRouteParams } from '../routing'

import EditStackDeploymentAdvancedPlanEditor from './EditStackDeploymentAdvancedPlanEditor'

import type { WithStackDeploymentRouteParamsProps } from '../routing'
import type { AsyncRequestState } from '../../../types'
import type { DeploymentGetResponse, DeploymentUpdateRequest } from '../../../lib/api/v1/types'

type StateProps = {
  deployment: DeploymentGetResponse | null
  fetchStackDeploymentRequest: AsyncRequestState
  updateStackDeploymentRequest: AsyncRequestState
}

type DispatchProps = {
  fetchDeployment: (params: { deploymentId: string }) => void
  updateDeployment: (params: { deploymentId: string; deployment: DeploymentUpdateRequest }) => void
  resetUpdateDeployment: (deploymentId: string) => void
}

type ForeignConsumerProps = {
  getInitialEditorState?: (regionId: string, deploymentId: string) => DeploymentUpdateRequest | null
}

type ConsumerProps = WithStackDeploymentRouteParamsProps & ForeignConsumerProps

const mapStateToProps = (state, { stackDeploymentId }: ConsumerProps): StateProps => ({
  deployment: getStackDeployment(state, stackDeploymentId),
  fetchStackDeploymentRequest: fetchStackDeploymentRequest(state, stackDeploymentId),
  updateStackDeploymentRequest: updateStackDeploymentRequest(state, stackDeploymentId),
})

const mapDispatchToProps: DispatchProps = {
  fetchDeployment,
  updateDeployment,
  resetUpdateDeployment,
}

export default withStackDeploymentRouteParams<ForeignConsumerProps>(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EditStackDeploymentAdvancedPlanEditor),
)
