/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { BillingSubscriptionLevel } from '@/types'
import type { DeepNullable } from '@/lib/ts-essentials'
import type { UserProfile } from './profile'
import type { Dimension } from '@/lib/api/v1/types'
export interface BillingActivity {
  now: PeriodActivity
  month_so_far: PeriodActivity
  user: ActivityUser
  month_estimate: PeriodActivity
}

type Data = Record<string, any> | null

type Uplifts = Record<BillingSubscriptionLevel, { aggregated_price: number }>

export interface PeriodActivity {
  end: string
  discounts: Discount[]
  prepaid_products: PrepaidProduct[]
  hours: HourActivity[]
  start: string
  product_discounts: any[]
  total: number
  clusters?: Cluster[]
  uplifts?: Uplifts
}

interface Cluster {
  cluster: {
    user_id: number
    created: string
    is_locked?: boolean
    region: string
    txid: number
    cluster_id: string
    is_hidden: boolean
    identifier: null
    data: Data
  }

  plans: Plan[]
}

type ActivityUser = Pick<
  DeepNullable<UserProfile>,
  | 'allow_bundles'
  | 'allow_plugins'
  | 'aws_subscribed'
  | 'domain'
  | 'wants_informational_emails'
  | 'accepted_tos'
  | 'user_id'
  | 'txid'
  | 'prepaid_products'
  | 'is_paying'
  | 'email'
  | 'allow_provisioning_without_payment_established'
  | 'last_modified'
  | 'aws_customer_id'
  | 'data'
  | 'invoicable'
  | 'credit_limit'
  | 'nsid'
  | 'created'
  | 'email_verified'
  | 'level'
  | 'recurly_billing_info'
>

interface Plan {
  product: Product
  availability_zones: number
  capacity: number
  started: string
  region: string
  txid: number
  cluster_id: string
  stopped: string
  data: Data
  history_id: string
}

interface Discount {
  start: string
  end: string
  data: Data
  factor: number
  user?: ActivityUser
}

interface PrepaidProduct {
  start: string
  end: string
  price: number
  product: Product
}

interface HourActivity {
  hours: number
  product: Product
}

interface Product {
  product_id: number
  nsid: null
  created: string
  price?: number
  txid: number
  last_modified: string
  identifier: string
  data: Data
}

// Billing Costs - dimensions

export type CostDimension = Dimension['type']

// Billing Details

// TODO update/add enums when API will have final form

export enum BillingModel {
  PrepaidConsumption = 'prepaid_consumption',
  OnDemand = 'on_demand',
  AnnualOverages = 'annual_overages',
  AnnualOveragesInstallments = 'annual_overages_installments',
  Drawdown = 'drawdown',
  CommittedConsumption = 'committed_consumption',
}

export enum Channel {
  Direct = 'direct',
  AwsMp = 'aws_mp',
  GcpMP = 'gcp_mp',
  AzureMp = 'azure_mp',
  HerokuMp = 'heroku_mp',
}

export enum PaymentMethod {
  CreditCard = 'credit_card',
  PurchaseOrder = 'purchase_order',
  ThirdPartyBilling = 'third_party_billing',
}

export enum PartnerSelling {
  Direct = 'direct',
  Reseller = 'reseller',
}

export enum BillingStatus {
  BalanceDue = 'balance_due',
  GoodStanding = 'good_standing',
  PendingSuspension = 'pending_suspension',
  Suspended = 'suspended',
  Terminated = 'terminated',
}

export interface DeploymentCostsAggregation {
  name: string
  id: string
  capacity: number
  dataTransfer: number
  storage: number
  total: number
}
