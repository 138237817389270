/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { without } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiButton,
  EuiCode,
  EuiButtonIcon,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui'

import { CuiTable } from '../../../cui'

import type { WrappedComponentProps } from 'react-intl'
import type { CuiTableColumn } from '../../../cui'

const messages = defineMessages({
  placeholder: {
    id: 'deploymentTrustManagement.enter-node-names.placeholder',
    defaultMessage: 'Node name',
  },
  addButton: {
    id: 'deploymentTrustManagement.enter-node-names.add-button',
    defaultMessage: 'Add node',
  },
  removeButton: {
    id: 'deploymentTrustManagement.enter-node-names.remove-button',
    defaultMessage: 'Remove {nodeName} from trusted nodes',
  },
  nodeNameHeader: {
    id: 'deploymentTrustManagement.enter-node-names.node-names-header',
    defaultMessage: 'Node name',
  },
})

type Props = WrappedComponentProps & {
  additionalNodeNames: string[]
  onChange: (ids: string[]) => void
  inputStyle?: React.CSSProperties
}

type State = {
  newNodeName: string
}

class EnterNodeNames extends React.Component<Props, State> {
  state: State = {
    newNodeName: ``,
  }

  render(): JSX.Element {
    const {
      intl: { formatMessage },
      additionalNodeNames,
    } = this.props

    const columns: Array<CuiTableColumn<string>> = [
      {
        textOnly: false,
        label: formatMessage(messages.nodeNameHeader),
        render: (nodeName) => <EuiCode>{nodeName}</EuiCode>,
      },
      {
        actions: true,
        render: (nodeName) => (
          <EuiButtonIcon
            aria-label={`${formatMessage(messages.removeButton, {
              nodeName,
            })}`}
            iconType='cross'
            onClick={() => this.remove(nodeName)}
          />
        ),
        align: `right`,
      },
    ]

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem>
            <EuiFormRow>
              <EuiFieldText
                maxLength={32}
                placeholder={formatMessage(messages.placeholder)}
                style={this.props.inputStyle}
                fullWidth={true}
                value={this.state.newNodeName}
                onChange={(e) => this.setState({ newNodeName: e.target.value })}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              color='primary'
              onClick={() => this.add()}
              disabled={this.state.newNodeName.trim().length === 0}
            >
              <FormattedMessage {...messages.addButton} />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {additionalNodeNames.length > 0 && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiTable<string> rows={additionalNodeNames} columns={columns} />
          </Fragment>
        )}
      </Fragment>
    )
  }

  add = () => {
    const { additionalNodeNames } = this.props
    const newNodeName = this.state.newNodeName.trim()

    if (!additionalNodeNames.includes(newNodeName)) {
      this.props.onChange([...additionalNodeNames, newNodeName])
    }

    this.setState({
      newNodeName: ``,
    })
  }

  remove = (nodeName: string) => {
    this.props.onChange(without(this.props.additionalNodeNames, nodeName))
  }
}

export default injectIntl(EnterNodeNames)
