/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'
import { flatMap, uniqBy, sortBy, identity } from 'lodash'

import type { SearchFilterConfig } from '@elastic/eui'
import { EuiBadge } from '@elastic/eui'

import type { IntlShape } from 'react-intl'
import type { Runner } from '../../../types'

const messages = defineMessages({
  zonesLabel: {
    id: `runner-filter-context.zones-label`,
    defaultMessage: `Zones`,
  },
  healthyLabel: {
    id: `runner-filter-context.healthy-label`,
    defaultMessage: `Healthy`,
  },
  unhealthyLabel: {
    id: `runner-filter-context.unhealthy-label`,
    defaultMessage: `Unhealthy`,
  },
  connectedLabel: {
    id: `runner-filter-context.connected-label`,
    defaultMessage: `Connected`,
  },
  disconnectedLabel: {
    id: `runner-filter-context.disconnected-label`,
    defaultMessage: `Disconnected`,
  },
  rolesLabel: {
    id: `runner-filter-context.roles-label`,
    defaultMessage: `Roles`,
  },
  containersLabel: {
    id: `runner-filter-context.containers-label`,
    defaultMessage: `Containers`,
  },
  containersetsLabel: {
    id: `runner-filter-context.containersets-label`,
    defaultMessage: `Container sets`,
  },
})

export function getFilters({
  intl: { formatMessage },
  runners = [],
}: {
  intl: IntlShape
  runners?: Runner[]
}): SearchFilterConfig[] {
  const { zones, roles, containers, containersets } = getOptionLists(runners)

  const filters: SearchFilterConfig[] = [
    {
      name: formatMessage(messages.zonesLabel),
      type: `field_value_selection`,
      field: `zone`,
      multiSelect: `or`,
      options: zones,
    },
    {
      type: `field_value_toggle_group`,
      field: `healthy`,
      items: [
        {
          name: formatMessage(messages.healthyLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          value: `n`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `connected`,
      items: [
        {
          name: formatMessage(messages.connectedLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.disconnectedLabel),
          value: `n`,
        },
      ],
    },
    {
      name: formatMessage(messages.rolesLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `role`,
      multiSelect: `and`,
      options: roles,
    },
    {
      name: formatMessage(messages.containersLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `container`,
      multiSelect: `and`,
      options: containers,
    },
    {
      name: formatMessage(messages.containersetsLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `containerset`,
      multiSelect: `and`,
      options: containersets,
    },
  ]

  return filters
}

function getOptionLists(runners: Runner[]) {
  const zones = uniqBy(
    runners.map((runner) => runner.zone!),
    identity,
  )
    .filter(Boolean)
    .sort()
    .map((zone) => ({
      view: renderBadge(zone),
      value: zone,
    }))

  const roles = sortBy(
    uniqBy(
      flatMap(runners, (runner) => runner.roles.map((r) => r.role_name)),
      identity,
    ),
  ).map((role) => ({
    _role: role,
    view: renderBadge(role),
    value: role,
  }))

  const containers = sortBy(
    uniqBy(
      flatMap(runners, (runner) => runner.containers.map((c) => c.container_name)),
      identity,
    ),
  ).map((container) => ({
    _container: container,
    view: renderBadge(container),
    value: container,
  }))

  const containersets = sortBy(
    uniqBy(
      flatMap(runners, (runner) => runner.containers.map((c) => c.container_set_name)),
      identity,
    ),
  ).map((containerset) => ({
    _containerset: containerset,
    view: renderBadge(containerset),
    value: containerset,
  }))

  return {
    zones,
    roles,
    containers,
    containersets,
  }
}

function renderBadge(value: string) {
  return (
    <div>
      <EuiBadge>{value}</EuiBadge>
    </div>
  )
}
