/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, size } from 'lodash'

import { EuiSpacer, EuiText } from '@elastic/eui'

import { isEmptyQuery, getFilterQueryString, setFilterQueryString } from '../../cui'
import AllocatorCapacityOverviewPanel from '../AllocatorCapacityOverviewPanel'

import AllocatorsTable from './AllocatorsTable'
import AllocatorFilterContext, {
  executeQuery,
  getFilterTags,
  toggleFilterTag,
  toggleField,
} from './AllocatorFilterContext'

import type { AllocatorSearchResult } from '../../types'
import type { MetadataItem } from '../../lib/api/v1/types'
import type { ControlledFilterQuery, OnFilterChangeParams } from '../../cui'

type Props = {
  regionId: string
  allocators?: AllocatorSearchResult[]
}

type State = {
  query: ControlledFilterQuery
  queryResults: AllocatorSearchResult[]
}

class AllocatorsOverview extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `AllocatorsOverview` }),
    queryResults: [],
  }

  render() {
    const { allocators } = this.props
    const { query } = this.state

    return (
      <Fragment>
        <EuiText color='subdued'>
          <FormattedMessage
            id='allocators-overview.description'
            defaultMessage='Allocators are hosts that provide computing resources to Elastic Stack deployments by managing the lifecycle of instances.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <AllocatorFilterContext query={query} onChange={this.onChange} allocators={allocators} />

        {this.renderZoneStats()}
        {this.renderAllocatorsTables()}
      </Fragment>
    )
  }

  renderZoneStats() {
    const { query, queryResults } = this.state

    if (isEmpty(queryResults)) {
      return null
    }

    const isFiltered = !isEmptyQuery(query)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <AllocatorCapacityOverviewPanel allocators={queryResults} isFiltered={isFiltered} />
      </Fragment>
    )
  }

  renderAllocatorsTables() {
    const { allocators: rawAllocators, regionId } = this.props
    const { query, queryResults } = this.state

    const loadedButNoMatches = rawAllocators && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const filterTags = getFilterTags({ query })
    const totalCount = size(rawAllocators)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <AllocatorsTable
          onZoneClick={this.onZoneClick}
          onTagClick={this.onTagClick}
          allocators={queryResults}
          filterTags={filterTags}
          regionId={regionId}
          totalCount={totalCount}
          initialLoading={!rawAllocators}
        />
      </Fragment>
    )
  }

  onTagClick = (tag: MetadataItem) => {
    const { query } = this.state
    const updatedQuery = toggleFilterTag({ query, tag })

    this.setQuery(updatedQuery)
  }

  onZoneClick = (zoneId: string) => {
    const { query } = this.state
    const updatedQuery = toggleField({ query, field: 'zone', value: zoneId })

    this.setQuery(updatedQuery)
  }

  setQuery = (query) => {
    const { allocators } = this.props

    const queryText = query.text
    const queryResults = executeQuery({ query, records: allocators })

    this.onChange({ queryText, queryResults })
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<AllocatorSearchResult>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `AllocatorsOverview`, queryText })
  }
}

export default AllocatorsOverview
