/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { topologyDeploymentTemplateCreateCrumbs } from '@/lib/crumbBuilder'
import { topologyDeploymentTemplatesUrl } from '@/lib/urlBuilder'
import { CuiRouterLinkButton } from '@/cui'
import { createDefaultDeploymentCreateRequest } from '@/lib/deploymentTemplates/deploymentTemplate'
import { withoutSoftDeleted } from '@/lib/instanceConfigurations'
import history from '@/lib/history'

import Header from '../../../Header'
import DeploymentTemplateWizard from '../DeploymentTemplateWizard'
import CreateTemplateModal from '../DeploymentTemplateList/CreateTemplateModal'

import type { DeploymentTemplateRequestBody } from '@/lib/api/v1/types'
import type { AllProps, State } from './types'

export default class DeploymentTemplateCreate extends Component<AllProps, State> {
  state: State = {
    modalState: null,
    template: undefined,
  }

  static getDerivedStateFromProps(nextProps: AllProps, prevState: State): Partial<State> | null {
    if (prevState.template) {
      return null // initialization is done
    }

    const instanceTypes = nextProps.instanceTypes
    const instanceConfigurations = nextProps.instanceConfigurations

    if (!instanceTypes || !instanceConfigurations) {
      return null // pre-reqs for creating a blank template
    }

    return {
      modalState: 'required',
      template: {
        name: '',
        description: '',
        deployment_template: createDefaultDeploymentCreateRequest({
          region: nextProps.regionId,
          instanceConfigurations,
          instanceTypes,
        }),
      },
    }
  }

  componentDidMount(): void {
    this.props.fetchInstanceTypes()
    this.props.fetchInstanceConfigurations()
  }

  render() {
    if (!this.state.template) {
      return null
    }

    return (
      <Fragment>
        {this.renderTitle()}
        {this.renderContent()}
        {this.renderNameModal()}
      </Fragment>
    )
  }

  renderTitle() {
    const { regionId, createDeploymentTemplateRequest } = this.props
    const { template } = this.state

    const pageTitle = (
      <EuiFlexGroup gutterSize='s' alignItems='center'>
        <EuiFlexItem grow={false}>{template?.name}</EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color='text'
            onClick={() => this.setState({ modalState: 'voluntary' })}
            iconType='pencil'
            aria-label={'Next'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )

    return (
      <Header breadcrumbs={topologyDeploymentTemplateCreateCrumbs({ regionId })} name={pageTitle}>
        <CuiRouterLinkButton
          disabled={createDeploymentTemplateRequest.inProgress}
          to={topologyDeploymentTemplatesUrl(regionId)}
        >
          <FormattedMessage id='deployment-template-create.cancel' defaultMessage='Cancel' />
        </CuiRouterLinkButton>
      </Header>
    )
  }

  renderContent() {
    const { regionId, createDeploymentTemplateRequest } = this.props
    const { template } = this.state

    if (!template) {
      return // sanity
    }

    const availableInstanceConfigurations = withoutSoftDeleted(this.props.instanceConfigurations)

    return (
      <div>
        <DeploymentTemplateWizard
          regionId={regionId}
          template={template}
          instanceTypes={this.props.instanceTypes}
          instanceConfigurations={availableInstanceConfigurations}
          isSaving={createDeploymentTemplateRequest.inProgress}
          error={createDeploymentTemplateRequest.error}
          updateDeploymentTemplate={this.updateDeploymentTemplate}
          onSave={this.onSave}
          saveButtonLabel={
            <FormattedMessage
              id='deployment-template-create.save-template'
              defaultMessage='Save and create template'
            />
          }
        />
      </div>
    )
  }

  renderNameModal() {
    if (!this.state.modalState) {
      return
    }

    if (!this.state.template) {
      return //sanity
    }

    return (
      <CreateTemplateModal
        regionId={this.props.regionId}
        template={this.state.template}
        updateDeploymentTemplate={this.updateDeploymentTemplate}
        closeModal={() => {
          this.setState({ modalState: null })
        }}
        onCancel={this.onModalCancel}
      />
    )
  }

  onModalCancel = () => {
    if (this.state.modalState === 'required') {
      history.push(topologyDeploymentTemplatesUrl(this.props.regionId))
    } else {
      this.setState({ modalState: null })
    }
  }

  updateDeploymentTemplate = (template: DeploymentTemplateRequestBody): void => {
    this.setState({ template })
  }

  onSave = () => {
    const { template } = this.state

    if (!template) {
      return // sanity
    }

    this.props.createDeploymentTemplate(template)
  }
}
