/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { FormattedMessage } from 'react-intl'
import React from 'react'

import { EuiButtonIcon, EuiPopover, EuiText } from '@elastic/eui'

import DocLink from '@/components/DocLink'
import { isAutoscaleableTier } from '@/lib/stackDeployments/selectors'

import type { AnyTopologyElement } from '@/types'

type Props = {
  topologyElement: AnyTopologyElement
}

type State = {
  isPopoverOpen: boolean
}

class AutoscalingInfo extends React.Component<Props, State> {
  state: State = {
    isPopoverOpen: false,
  }

  render(): JSX.Element | null {
    const { topologyElement } = this.props

    if (!isAutoscaleableTier({ topologyElement, version: undefined })) {
      return null
    }

    return (
      <EuiPopover
        data-test-subj='configureTemplate.autoscaling.popover'
        ownFocus={false}
        isOpen={this.state.isPopoverOpen}
        button={
          <EuiButtonIcon
            iconType='iInCircle'
            size='s'
            color='text'
            aria-label='Info'
            onClick={() => this.setState({ isPopoverOpen: true })}
          />
        }
        closePopover={() => this.setState({ isPopoverOpen: false })}
      >
        <EuiText style={{ width: 350 }}>
          <FormattedMessage
            id='deploymentTemplateInfrastructure-templateTopologyElement-autoscalingInfo'
            defaultMessage='The default size of a data tier. Users can {docLink} when they create or edit a deployment.'
            values={{
              docLink: (
                <DocLink link='autoscalingUpdateSettingsLink'>
                  <FormattedMessage
                    id='configure-template.autoscaling.popover.doclink'
                    defaultMessage='modify this value'
                  />
                </DocLink>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }
}

export default AutoscalingInfo
