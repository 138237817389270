/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { demoteCoordinator } from '../../../actions/candidates'
import { demoteCoordinatorRequest, getDemoteCoordinator, getRunner } from '../../../reducers'

import DemoteHost from './DemoteHost'

import type {
  AsyncRequestState,
  DemoteRecord,
  ReduxState,
  ThunkDispatch,
  Runner,
} from '../../../types'

interface StateProps {
  runner?: Runner
  demoteCoordinatorState?: DemoteRecord
  demoteCoordinatorRequest: AsyncRequestState
}

interface DispatchProps {
  demoteCoordinator: () => Promise<any>
}

interface ConsumerProps {
  regionId: string
  hostId: string
  onClose: () => void
}

const mapStateToProps = (state: ReduxState, { regionId, hostId }: ConsumerProps): StateProps => ({
  runner: getRunner(state, regionId, hostId),
  demoteCoordinatorState: getDemoteCoordinator(state, regionId, hostId),
  demoteCoordinatorRequest: demoteCoordinatorRequest(state, regionId, hostId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId, hostId }: ConsumerProps,
): DispatchProps => ({
  demoteCoordinator: () => dispatch(demoteCoordinator(regionId, hostId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DemoteHost)
