/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { uniqBy } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import { CuiAlert, CuiTable } from '../../../../cui'
import AllocatorTag from '../AllocatorTag'
import { topologyViewInstanceConfigurationUrl } from '../../../../lib/urlBuilder'
import { getInstancesWithMatchingFilter } from '../../../../lib/instanceConfigurations/instanceConfiguration'

import type { FunctionComponent } from 'react'
import type { RegionId } from '../../../../types'
import type { InstanceConfiguration, MetadataItem } from '../../../../lib/api/v1/types'

type Props = {
  tag: MetadataItem
  regionId: RegionId
  instances: Array<Partial<InstanceConfiguration>>
}

const RemovableAllocatorTagModal: FunctionComponent<Props> = ({ tag, regionId, instances }) => {
  const atRiskInstances = getInstancesWithMatchingFilter({ instances, tag })
  const instanceConfigs = uniqBy(
    atRiskInstances.map((instance) => instance.instanceConfiguration),
    `id`,
  )

  const columns = [
    {
      label: (
        <FormattedMessage
          id='removableAllocatorTagModalBody.table.nodeTypesHeader'
          defaultMessage='Instance types'
        />
      ),
      render: (instanceConfig) => (
        <Link to={topologyViewInstanceConfigurationUrl(regionId, instanceConfig.id)}>
          {instanceConfig.name}
        </Link>
      ),
      sortKey: (instanceConfig) => instanceConfig.name,
    },
    {
      label: (
        <FormattedMessage
          id='removableAllocatorTagModalBody.table.nodeDescriptionsHeader'
          defaultMessage='Instance descriptions'
        />
      ),
      render: (instanceConfig) => instanceConfig.description,
      sortKey: (instanceConfig) => instanceConfig.description,
    },
  ]

  return (
    <Fragment>
      {!atRiskInstances || atRiskInstances.length === 0 ? (
        <CuiAlert type='warning'>
          <FormattedMessage
            id='allocator-tag-modal.default-removal-warning'
            defaultMessage='Tags tell instance configurations where to deploy parts of the Elastic Stack by matching suitable allocators. Before you remove this tag, make sure that some allocators still get matched by other tags, so that there is sufficient capacity available to create or change deployments.'
          />
        </CuiAlert>
      ) : (
        <Fragment>
          <CuiAlert type='warning'>
            <FormattedMessage
              id='allocator-tag-modal.custom-removal-warning'
              defaultMessage="You currently have {formattedAtRisk} { atRiskCount, plural, one {instance} other {instances}} that rely on this {tag} tag to allocate parts of the Elastic Stack to this allocator. If you remove this tag, these instances are at risk of not getting allocated. You can verify that this doesn't happen by making sure that their instance configurations listed below have an allocator filter that match this allocator (or others) with other tags."
              values={{
                tag: <AllocatorTag tag={tag} />,
                formattedAtRisk: <strong>{atRiskInstances.length}</strong>,
                atRiskCount: atRiskInstances.length,
              }}
            />
          </CuiAlert>

          <EuiSpacer size='m' />

          <CuiTable
            rows={instanceConfigs}
            columns={columns}
            getRowId={(instanceConfig) => instanceConfig.id}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default RemovableAllocatorTagModal
