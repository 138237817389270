/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTitle } from '@elastic/eui'

import ClusterSlainNodes from '../SlainEvents'
import ClusterShards from '../ClusterShards'
import SliderAppLinks from '../../StackDeploymentSlider/SliderAppLinks'
import StackDeploymentNodesVisualization from '../../StackDeployments/StackDeploymentNodesVisualization'
import ResourceComments from '../../ResourceComments'
import { isClusterActive } from '../../../lib/clusters'
import { getRecentSlainEvents } from '../../../lib/deployments/events'
import { getFirstEsClusterFromGet, isStopped } from '../../../lib/stackDeployments/selectors'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const ClusterOverview: FunctionComponent<Props> = ({
  deployment,
  cluster,
  saasClusterMetrics,
  showNativeMemoryPressure,
}) => {
  if (!cluster) {
    return null
  }

  const { instances } = cluster

  if (!deployment) {
    return null
  }

  const esResource = getFirstEsClusterFromGet({ deployment })

  if (!esResource) {
    return null
  }

  const isClusterStopped = isStopped({ resource: esResource })
  const slainEvents = getRecentSlainEvents(cluster)

  return (
    <div>
      <SliderAppLinks
        sliderInstanceType='elasticsearch'
        deployment={deployment}
        resource={esResource}
        saasClusterMetrics={saasClusterMetrics}
      />

      <EuiSpacer size='m' />

      {!isClusterStopped && isClusterActive(cluster) && (
        <Fragment>
          <ClusterShards deployment={deployment} />
          <EuiSpacer size='xxl' />
        </Fragment>
      )}

      {instances.record.length === 0 || (
        <StackDeploymentNodesVisualization
          title={
            <EuiTitle size='s'>
              <h3 data-test-id='deploymentOverview-zonesAndNodes'>
                <FormattedMessage id='cluster-info.instances' defaultMessage='Instances' />
              </h3>
            </EuiTitle>
          }
          deployment={deployment}
          sliderInstanceType='elasticsearch'
          showNativeMemoryPressure={showNativeMemoryPressure}
        />
      )}

      <EuiSpacer size='l' />

      {slainEvents.length > 0 && <ClusterSlainNodes events={slainEvents} />}

      <ResourceComments
        spacerBefore={true}
        resourceType='elasticsearch'
        regionId={cluster.regionId}
        resourceId={cluster.id}
      />
    </div>
  )
}

export default ClusterOverview
