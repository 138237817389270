/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiText, EuiSpacer } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'
import { billingUsageUrl } from '@/apps/userconsole/urls'

interface Props {
  deploymentId?: string
  trialStarted: boolean
  trialExpired: boolean
}

const HelperLinks = ({ deploymentId, trialStarted, trialExpired }: Props) => (
  <Fragment>
    <EuiSpacer size='m' />

    {deploymentId && (
      <Fragment>
        <EuiText size='s' data-test-id='trial-summary-modal.link-section.edit-deployment'>
          <h5>
            <Link to={`/deployments/${deploymentId}/edit`}>
              <FormattedMessage
                id='trial-summary-modal.link-section.edit-deployment.title'
                defaultMessage='Edit your deployment'
              />
            </Link>
          </h5>
        </EuiText>

        <EuiSpacer size='xs' />

        <EuiText size='xs'>
          <FormattedMessage
            id='trial-summary-modal.link-section.edit-deployment.details'
            defaultMessage='Adjust the size and configuration of your deployment to control its costs.'
          />
        </EuiText>

        <EuiSpacer size='m' />
      </Fragment>
    )}

    {!deploymentId && !trialExpired && (
      <Fragment>
        <EuiText size='s' data-test-id='trial-summary-modal.link-section.create-deployment'>
          <h5>
            <Link to={`/deployments/create`}>
              <FormattedMessage
                id='trial-summary-modal.link-section.create-deployment.title'
                defaultMessage='Create a deployment'
              />
            </Link>
          </h5>
        </EuiText>

        <EuiSpacer size='xs' />

        <EuiText size='xs'>
          <FormattedMessage
            id='trial-summary-modal.link-section.create-deployment.details'
            defaultMessage='Create a deployment on the cloud platform of your choice and configure it to store, search and analyze your data, at your scale.'
          />
        </EuiText>

        <EuiSpacer size='m' />
      </Fragment>
    )}
    {/* trialStarted means that the user has already created the deployment.
     Usage history will display deployment even if it has been manually removed */}
    {trialStarted && (
      <Fragment>
        <EuiText size='s' data-test-id='trial-summary-modal.link-section.usage-page'>
          <h5>
            <Link to={billingUsageUrl()}>
              <FormattedMessage
                id='trial-summary-modal.link-section.current-usage.title'
                defaultMessage='View current usage'
              />
            </Link>
          </h5>
        </EuiText>

        <EuiSpacer size='xs' />

        <EuiText size='xs'>
          <FormattedMessage
            id='trial-summary-modal.link-section.current-usage.details'
            defaultMessage='See how your current usage would impact the price after your free trial.'
          />
        </EuiText>

        <EuiSpacer size='m' />
      </Fragment>
    )}

    <EuiText size='s' data-test-id='trial-summary-modal.link-section.simulate-price'>
      <h5>
        <ExternalLink showExternalLinkIcon={true} href='https://cloud.elastic.co/pricing'>
          <FormattedMessage
            id='trial-summary-modal.link-section.simulate-price.title'
            defaultMessage='Simulate price'
          />
        </ExternalLink>
      </h5>
    </EuiText>

    <EuiSpacer size='xs' />

    <EuiText size='xs'>
      <FormattedMessage
        id='trial-summary-modal.link-section.simulate-price.description'
        defaultMessage='Use our cost estimator and pricing table to evaluate different scenarios.'
      />
    </EuiText>

    <EuiSpacer size='m' />
  </Fragment>
)

export default HelperLinks
