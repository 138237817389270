/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiToolTip } from '@elastic/eui'

import { CuiButtonEmpty } from '../../../cui'

import DeleteAllocatorModal from './DeleteAllocatorModal'

import type { Allocator } from '../../../types'
import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  allocator: Allocator
}

type State = {
  deletingAllocator: boolean
}

const messages = defineMessages({
  disconnectBeforeDelete: {
    id: 'allocator-delete-button.disconnect-before-delete',
    defaultMessage: 'You must disconnect this allocator before it can be deleted.',
  },
})

class AllocatorDeleteButton extends Component<Props, State> {
  state: State = {
    deletingAllocator: false,
  }

  render() {
    const {
      intl: { formatMessage },
      allocator,
    } = this.props

    const { deletingAllocator } = this.state
    const { connected } = allocator

    const startDeleteButton = (
      <CuiButtonEmpty
        disabled={connected}
        color='danger'
        iconType='trash'
        onClick={() => this.setState({ deletingAllocator: true })}
      >
        <FormattedMessage
          id='allocator-delete-button.delete-allocator'
          defaultMessage='Delete allocator'
        />
      </CuiButtonEmpty>
    )

    return (
      <Fragment>
        {connected ? (
          <EuiToolTip content={formatMessage(messages.disconnectBeforeDelete)} position='bottom'>
            {startDeleteButton}
          </EuiToolTip>
        ) : (
          startDeleteButton
        )}

        {deletingAllocator && (
          <DeleteAllocatorModal
            allocator={allocator}
            close={() => this.setState({ deletingAllocator: false })}
          />
        )}
      </Fragment>
    )
  }
}

export default injectIntl(AllocatorDeleteButton)
