/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchDeployments } from '../../../../actions/stackDeployments'
import {
  searchStackDeploymentsRequest,
  getStackDeploymentsFromSearch,
  getCurrentAccount,
} from '../../../../reducers'
import { getDeploymentsByClusterIdsQuery } from '../../../../lib/deploymentQuery'

import Allowlist from './Allowlist'

import type { ReduxState, ThunkDispatch } from '../../../../types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const nonce = `trustAllowlistDeployments`

const mapStateToProps = (state: ReduxState): StateProps => ({
  allowlistDeployments: getStackDeploymentsFromSearch(state, nonce)?.deployments,
  allowlistDeploymentsRequest: searchStackDeploymentsRequest(state, nonce),
  userOrgId: getCurrentAccount(state)?.id || null,
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchForAllowlistDeployments: (clusterIds) => {
    const query = getDeploymentsByClusterIdsQuery({ clusterIds })
    dispatch(searchDeployments({ queryId: nonce, query }))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Allowlist)
