/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchApiBaseUrlIfNeeded, updateApiBaseUrl } from '../../../actions/apiBaseUrl'
import { fetchApiBaseUrlRequest, updateApiBaseUrlRequest, getApiBaseUrl } from '../../../reducers'
import { getConfigForKey } from '../../../selectors'

import EnsureApiBaseUrl from './EnsureApiBaseUrl'

import type { AsyncRequestState } from '../../../types'

interface StateProps {
  isEceAdminconsole?: boolean
  apiBaseUrl?: string | null
  fetchApiBaseUrlRequest: AsyncRequestState
  updateApiBaseUrlRequest: AsyncRequestState
}

interface DispatchProps {
  fetchApiBaseUrl: () => void
  updateApiBaseUrl: (payload: { apiBaseUrl: string }) => void
}

type ConsumerProps = unknown

const mapStateToProps = (state): StateProps => ({
  isEceAdminconsole: getConfigForKey(state, `CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`,
  apiBaseUrl: getApiBaseUrl(state),
  fetchApiBaseUrlRequest: fetchApiBaseUrlRequest(state),
  updateApiBaseUrlRequest: updateApiBaseUrlRequest(state),
})

const mapDispatchToProps: DispatchProps = {
  fetchApiBaseUrl: fetchApiBaseUrlIfNeeded,
  updateApiBaseUrl,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EnsureApiBaseUrl)
