/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiLoadingContent, EuiSpacer } from '@elastic/eui'

import { isPrepaidConsumptionDirectCustomer } from '@/lib/billingDetails'
import CostAnalysis from '@/components/User/CostAnalysis'

import ErrorCallout from '../ErrorCallout'

import BillingUsageMainPage from './BillingUsageMainPage'

import type { FunctionComponent } from 'react'
import type { AllProps } from './types'

const BillingUsage: FunctionComponent<AllProps> = ({
  organizationId,
  showPrepaidConsumptionUI,
  billingDetails,
}) => {
  const shouldShowLoading = !organizationId || billingDetails.loading

  if (billingDetails.error) {
    return (
      <Fragment>
        <EuiSpacer size='xxl' />
        <ErrorCallout onReload={billingDetails.refetch} />
      </Fragment>
    )
  }

  if (shouldShowLoading) {
    return <EuiLoadingContent />
  }

  if (isPrepaidConsumptionDirectCustomer(billingDetails.data!) && showPrepaidConsumptionUI) {
    return <BillingUsageMainPage organizationId={organizationId} />
  }

  return <CostAnalysis organizationId={organizationId} data-test-id='old-billing-usage-ui' />
}

export default BillingUsage
