/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import ZkLoggingSettingsButton from '../../ZkLoggingSettingsButton'
import { fetchRunnerLoggingSettings, patchRunnerLoggingSettings } from '../../../actions/runners'
import {
  getRunnerLoggingSettings,
  fetchRunnerLoggingSettingsRequest,
  patchRunnerLoggingSettingsRequest,
} from '../../../reducers'

import type { AsyncRequestState, LoggerLevel } from '../../../types'
import type { LoggingSettings } from '../../../lib/api/v1/types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type StateProps = {
  loggingSettings: LoggingSettings | null
  fetchLoggingSettingsRequest: AsyncRequestState
  patchLoggingSettingsRequest: AsyncRequestState
}

type DispatchProps = {
  fetchLoggingSettings: () => void
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
}

type ConsumerProps = {
  regionId: string
  runnerId: string
}

const mapStateToProps = (state, { regionId, runnerId }: ConsumerProps): StateProps => ({
  loggingSettings: getRunnerLoggingSettings(state, regionId, runnerId),
  fetchLoggingSettingsRequest: fetchRunnerLoggingSettingsRequest(state, regionId, runnerId),
  patchLoggingSettingsRequest: patchRunnerLoggingSettingsRequest(state, regionId, runnerId),
})

const mapDispatchToProps = (dispatch, { regionId, runnerId }: ConsumerProps): DispatchProps => ({
  fetchLoggingSettings: () => dispatch(fetchRunnerLoggingSettings({ regionId, runnerId })),
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) =>
    dispatch(patchRunnerLoggingSettings({ regionId, runnerId, ...settings })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ZkLoggingSettingsButton)
