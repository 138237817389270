/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiFileUploadButton, CuiPermissibleControl, CuiButtonEmpty } from '../../../../cui'
import { readJson } from '../../../../lib/fileHelpers'
import Permission from '../../../../lib/api/v1/permissions'

import type { AsyncRequestState } from '../../../../types'
import type { LicenseObject } from '../../../../lib/api/v1/types'

interface Props {
  addLicenseRequest: AsyncRequestState
  regionId: string
  setLicense: (regionId: string, licenseFromFile: LicenseObject) => void
}

interface State {
  file: File | null
}

class UpdateLicense extends Component<Props, State> {
  state: State = {
    file: null,
  }

  render() {
    const { addLicenseRequest } = this.props

    // The duplicated CuiPermissibleControl components are required - one will render the popup,
    // the other disables the button
    return (
      <CuiPermissibleControl permissions={Permission.setLicense}>
        <CuiFileUploadButton disabled={addLicenseRequest.inProgress} onChange={this.uploadFile}>
          <CuiPermissibleControl permissions={[Permission.setLicense]}>
            <CuiButtonEmpty
              iconType='importAction'
              color='primary'
              size='s'
              spin={addLicenseRequest.inProgress}
            >
              <FormattedMessage
                id='upload-license.update-license'
                defaultMessage='Upload new license'
              />
            </CuiButtonEmpty>
          </CuiPermissibleControl>
        </CuiFileUploadButton>
      </CuiPermissibleControl>
    )
  }

  uploadFile = (file: File) => readJson(file).then(this.save)

  save = (licenseFromFile) => {
    const { regionId, setLicense } = this.props

    return setLicense(regionId, licenseFromFile)
  }
}

export default UpdateLicense
