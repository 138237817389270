/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import React from 'react'

import { EuiToolTip } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { MomentInput } from 'moment'

const longDayThisYear = `MMMM Do`
const longDay = `MMMM Do, YYYY`
const shortDayThisYear = `MMM Do`
const shortDay = `MMM Do, YY`

interface Props {
  date: MomentInput
  short?: boolean
}

export const CuiDate: FunctionComponent<Props> = ({ date, short }) => {
  const when = moment(date)
  const timestamp = when.toDate().toISOString()

  return (
    <EuiToolTip position='right' content={timestamp}>
      <span>
        {when.year() === moment.utc().year()
          ? when.format(short ? shortDayThisYear : longDayThisYear)
          : when.format(short ? shortDay : longDay)}
      </span>
    </EuiToolTip>
  )
}
