/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText, EuiSpacer } from '@elastic/eui'

import { hasCpuHardLimit, getClusterMetadata } from '@/lib/stackDeployments/selectors/metadata'
import { getFirstEsRefId } from '@/lib/stackDeployments/selectors'
import { replaceIn } from '@/lib/immutability-helpers'

import { CuiAlert, CuiPermissibleControl } from '../../../cui'
import SpinButton from '../../SpinButton'
import FormGroup from '../../FormGroup'
import Permission from '../../../lib/api/v1/permissions'

import type { AsyncRequestState, SliderInstanceType, StackDeployment } from '../../../types'
import type { FunctionComponent } from 'react'

export type DispatchProps = {
  updateMetadata: ({
    deployment,
    updater,
  }: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta?: { [key: string]: any }
  }) => void
}

export type StateProps = {
  canToggleCpuHardLimit: boolean
  setDeploymentResourceMetadataRequest: (
    deploymentId: string,
    resourceType: SliderInstanceType,
    resourceRefId: string,
  ) => AsyncRequestState
}

export type ConsumerProps = {
  deployment: StackDeployment
}

type Props = ConsumerProps & StateProps & DispatchProps

const setCpuHardLimitType = `set-cpu-hard-limit`

const CpuHardLimit: FunctionComponent<Props> = ({
  canToggleCpuHardLimit,
  deployment,
  updateMetadata,
  setDeploymentResourceMetadataRequest,
}) => {
  if (!canToggleCpuHardLimit) {
    return null
  }

  const { id } = deployment!
  const esRefId = getFirstEsRefId({ deployment })!
  const currentMetadata = getClusterMetadata({ deployment })!
  const currentHardLimit = hasCpuHardLimit({ metadata: currentMetadata })
  const updateRequest = setDeploymentResourceMetadataRequest(id, `elasticsearch`, esRefId)
  const updateRequestIsRelevant = updateRequest.meta.type === setCpuHardLimitType

  const setHardLimit = () => {
    updateMetadata({
      deployment,
      requestMeta: { type: setCpuHardLimitType },
      updater: (metadata) =>
        replaceIn(metadata, [`resources`, `cpu`, `hard_limit`], !currentHardLimit),
    })
  }

  return (
    <Fragment>
      <FormGroup
        label={
          <FormattedMessage
            id='cluster-operations-cpu-hard-limit.title'
            defaultMessage='CPU hard limit'
          />
        }
      >
        <EuiSpacer size='s' />

        <CuiPermissibleControl permissions={Permission.setDeploymentResourceRawMetadata}>
          <SpinButton
            color='primary'
            onClick={setHardLimit}
            spin={updateRequestIsRelevant && updateRequest.inProgress}
          >
            {currentHardLimit ? (
              <FormattedMessage
                id='cluster-operations-cpu-hard-limit.turn-off'
                defaultMessage='Turn off'
              />
            ) : (
              <FormattedMessage
                id='cluster-operations-cpu-hard-limit.turn-on'
                defaultMessage='Turn on'
              />
            )}
          </SpinButton>
        </CuiPermissibleControl>

        <EuiFormHelpText>
          <FormattedMessage
            id='cluster-operations-cpu-hard-limit.description'
            defaultMessage='Turns on a hard limit for CPU resources to reduce resource contention between clusters or turns it off to provide additional resources.'
          />
        </EuiFormHelpText>

        {updateRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{updateRequest.error}</CuiAlert>
          </Fragment>
        )}
      </FormGroup>

      <EuiSpacer />
    </Fragment>
  )
}

export default CpuHardLimit
