/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'

import FlipCard from '../FlipCard'

import FrontSide from './FrontSide'
import BackSide from './BackSide'
import { getCurrentRates } from './utils'

import type { AllProps } from './types'

class UsageRatesFlipCard extends Component<AllProps> {
  componentDidMount() {
    const { fetchAccountItemsCosts } = this.props

    fetchAccountItemsCosts()
  }

  render() {
    return <FlipCard frontSide={FrontSide} backSide={this.getBackSide} />
  }

  getBackSide = ({ flipCard }) => {
    const {
      costOverviewCurrentMonth,
      accountItemsCostsRequest: { inProgress, error },
    } = this.props

    return (
      <BackSide
        loading={inProgress}
        error={error}
        flipCard={flipCard}
        currentRates={getCurrentRates(costOverviewCurrentMonth)}
      />
    )
  }
}

export default UsageRatesFlipCard
