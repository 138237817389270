/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { getClusterMetadata, getFirstEsRefId } from '@/lib/stackDeployments/selectors'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import {
  NEW_TEMP_SHIELD_USER_SUCCESS,
  SET_DEPLOYMENT_RESOURCE_METADATA,
  RESET_TEMP_SHIELD_USER,
} from '../../constants/actions'
import { setDeploymentResourceRawMetadataUrl } from '../../lib/api/v1/urls'

import { fetchDeployment } from './crud'

import type {
  Action,
  SliderInstanceType,
  StackDeployment,
  ThunkAction,
  FoundUser,
} from '../../types'

export function setDeploymentResourceMetadata(params: {
  deploymentId: string
  resourceType: SliderInstanceType
  resourceRefId: string
  version: string | null
  metadata?: unknown
}): ThunkAction {
  return makeRequest(params)
}

export function updateEsMetadata({
  deployment,
  updater,
  requestMeta,
  then,
}: {
  deployment: StackDeployment
  updater: (metadata: { [key: string]: any }) => { [key: string]: any }
  requestMeta?: { [key: string]: any }
  then?: () => Action
}): ThunkAction {
  return (dispatch) => {
    const { id: deploymentId } = deployment
    const esRefId = getFirstEsRefId({ deployment })!
    const currentMetadata = getClusterMetadata({ deployment })!
    const nextMetadata = updater(currentMetadata.raw!)

    const request = dispatch(
      makeRequest({
        deploymentId,
        resourceType: `elasticsearch`,
        resourceRefId: esRefId,
        version: String(currentMetadata.version),
        metadata: nextMetadata,
        requestMeta,
      }),
    )

    if (then) {
      request.then(() => dispatch(then()))
    }

    request.then(() => dispatch(fetchDeployment({ deploymentId })))
    return request
  }
}

export const createTempShieldUserSuccessAction: (user: FoundUser) => Action = (user) => ({
  type: NEW_TEMP_SHIELD_USER_SUCCESS,
  meta: {},
  payload: user,
})

function makeRequest({
  deploymentId,
  resourceType,
  resourceRefId,
  version,
  metadata,
  requestMeta = { deploymentId, resourceType, resourceRefId },
}: {
  deploymentId: string
  resourceType: SliderInstanceType
  resourceRefId: string
  version: string | null
  metadata?: unknown
  requestMeta?: { [key: string]: any }
}): ThunkAction {
  const url = setDeploymentResourceRawMetadataUrl({
    deploymentId,
    resourceKind: resourceType,
    refId: resourceRefId,
    version,
  })

  return asyncRequest({
    type: SET_DEPLOYMENT_RESOURCE_METADATA,
    method: `POST`,
    url,
    payload: metadata,
    meta: requestMeta,
    crumbs: [deploymentId, resourceType, resourceRefId],
  })
}

export const resetTempShieldUser: () => Action<typeof RESET_TEMP_SHIELD_USER> = () => ({
  type: RESET_TEMP_SHIELD_USER,
  meta: {},
})

export const resetSetDeploymentResourceMetadata = (...crumbs: string[]) =>
  resetAsyncRequest(SET_DEPLOYMENT_RESOURCE_METADATA, crumbs)
