/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import DangerButton from '../../DangerButton'
import DeleteSnapshotRepositoryModalBody from '../DeleteSnapshotRepositoryModalBody'

import type { AsyncRequestState, RegionId } from '../../../types'

type Props = {
  regionId: RegionId
  snapshotRepoId: string
  deleteSnapshotRepositoryRequest: AsyncRequestState
  deleteSnapshotRepository: (
    regionId: RegionId,
    snapshotRepoId: string,
    cleanupDeployments: boolean,
  ) => void
}

type State = {
  cleanupDeployments: boolean
}

class DeleteSnapshotRepositoryButton extends Component<Props, State> {
  state: State = {
    cleanupDeployments: true,
  }

  render() {
    const { regionId, deleteSnapshotRepository, snapshotRepoId, deleteSnapshotRepositoryRequest } =
      this.props

    const { cleanupDeployments } = this.state

    return (
      <DangerButton
        size='s'
        data-test-id='delete-snapshot-repo'
        onConfirm={() => deleteSnapshotRepository(regionId, snapshotRepoId, cleanupDeployments)}
        isBusy={deleteSnapshotRepositoryRequest.inProgress}
        modal={{
          title: (
            <FormattedMessage
              id='delete-snapshot-repo.title'
              defaultMessage='Delete {snapshotRepo}?'
              values={{
                snapshotRepo: snapshotRepoId,
              }}
            />
          ),
          body: (
            <DeleteSnapshotRepositoryModalBody
              snapshotRepoId={snapshotRepoId}
              regionId={regionId}
              cleanupDeployments={cleanupDeployments}
              setCleanupDeployments={this.setCleanupDeployments}
            />
          ),
        }}
      >
        <FormattedMessage id='delete-snapshot-repo.delete' defaultMessage='Delete' />
      </DangerButton>
    )
  }

  setCleanupDeployments = (cleanupDeployments) => {
    this.setState({ cleanupDeployments })
  }
}

export default DeleteSnapshotRepositoryButton
