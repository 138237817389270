/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { filter, find, intersectionBy, isEqual } from 'lodash'

import {
  allocatorFilterToClauses,
  getAllocatorFilterPayload,
  removeMalformedClauses,
} from '../../../../lib/allocatorFilters'
import { SIZING_UNIT_MEMORY } from '../../../../lib/instanceConfigurations/multipliers'

import type { InstanceConfiguration, InstanceTypeResource } from '../../../../lib/api/v1/types'
import type { NodeType } from '../../../../types'
import type { FauxInstanceConfiguration } from './instanceConfigurationWizardTypes'

type FindInstanceTypeBoundsParams = {
  instanceTypes: InstanceTypeResource[]
  instanceConfiguration: FauxInstanceConfiguration
}

type DeserializeParams = {
  instanceConfiguration: InstanceConfiguration
}

type SerializeParams = {
  regionId: string
  instanceConfiguration: FauxInstanceConfiguration
}

type Bounds = {
  min: number
  max: number
}

export function findInstanceTypeBounds({
  instanceTypes,
  instanceConfiguration,
}: FindInstanceTypeBoundsParams): Bounds | null {
  if (!instanceTypes || !instanceConfiguration) {
    return null
  }

  // look through instanceTypes (which comes from the backend) to find the correct match for the one that was
  // picked.
  const details = find(instanceTypes, {
    instance_type: instanceConfiguration.instance_type,
  })

  if (!details) {
    return null
  }

  if (details == null || details.node_types == null) {
    // we don't have any node types to think about. Pick the highest version and use those as the min/max values
    const lastIndex = details.compatibility.length - 1
    const highestVersion = details.compatibility[lastIndex]
    return highestVersion.capacity_constraints || null
  }

  // we do have node_types to think about
  // iterate through each of the versions under `compatibility` in `details`
  // find the version for which there is an entry for each of the node_types in `instanceConfiguration`
  // if there is more than 1 version where everything matches, then pick the highest version
  const compatibleVersions = filter(details.compatibility, (version) => {
    const ours = instanceConfiguration.node_types
    const theirs = version.node_types.map((nodeType) => nodeType.node_type)
    const intersectedNodeTypes = intersectionBy(ours, theirs)

    return isEqual(instanceConfiguration.node_types, intersectedNodeTypes)
  })

  if (compatibleVersions.length === 0) {
    return null
  }

  const compatibleVersion = compatibleVersions[compatibleVersions.length - 1]

  // lastly we need to find the min/max numbers
  // For each node_type in the compatibleVersion that was chosen in the previous step, store the min/max

  const constraints = compatibleVersion.node_types
    .filter((nodeType) =>
      instanceConfiguration.node_types!.includes(nodeType.node_type as NodeType),
    )
    .map((nodeType) => nodeType.capacity_constraints)
    .filter(Boolean)

  const mins = constraints.map((constraint) => constraint!.min)
  const maxes = constraints.map((constraint) => constraint!.max)

  return {
    min: Math.max(...mins),
    max: Math.min(...maxes),
  }
}

export function deserializeInstanceConfiguration({
  instanceConfiguration,
}: DeserializeParams): FauxInstanceConfiguration {
  const {
    name,
    description = ``,
    allocator_filter,
    instance_type,
    node_types,
    discrete_sizes: { resource: sizingUnit, sizes: increments, default_size: defaultValue },
    storage_multiplier: ratio,
  } = instanceConfiguration

  return {
    name,
    description,
    clauses: allocatorFilterToClauses(allocator_filter),
    instance_type,
    node_types,
    sizingUnit,
    ratio,
    [`${sizingUnit}Increments`]: increments,
    [`${sizingUnit}DefaultValue`]: defaultValue,
  } as FauxInstanceConfiguration
}

export function serializeInstanceConfiguration({
  regionId,
  instanceConfiguration,
}: SerializeParams): InstanceConfiguration {
  const wellFormedClauses = removeMalformedClauses(instanceConfiguration.clauses)

  return {
    name: instanceConfiguration.name,
    description: instanceConfiguration.description,
    storage_multiplier: instanceConfiguration.ratio,
    instance_type: instanceConfiguration.instance_type,
    node_types: instanceConfiguration.node_types,
    discrete_sizes: {
      sizes:
        instanceConfiguration.sizingUnit === SIZING_UNIT_MEMORY
          ? instanceConfiguration.memoryIncrements
          : instanceConfiguration.storageIncrements,
      default_size:
        instanceConfiguration.sizingUnit === SIZING_UNIT_MEMORY
          ? instanceConfiguration.memoryDefaultValue
          : instanceConfiguration.storageDefaultValue,
      resource: instanceConfiguration.sizingUnit,
    },
    allocator_filter: getAllocatorFilterPayload(regionId, wellFormedClauses),
  } as InstanceConfiguration
}
