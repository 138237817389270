/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import TemplateAutoscalingToggle from '@/components/Topology/DeploymentTemplates/components/Autoscaling/TemplateAutoscalingToggle'

import DeploymentTemplateInfrastructure from '../components/DeploymentInfrastructure/DeploymentTemplateInfrastructure'

import ConfigureTemplateElasticsearchStepErrors, {
  validateElasticsearchStep,
} from './ConfigureTemplateElasticsearchStepErrors'

import type { FunctionComponent } from 'react'
import type { StepDefinition, StepProps } from './deploymentTemplateWizardTypes'

const messages = defineMessages({
  title: {
    id: `configure-template-instances-step.step-title`,
    defaultMessage: `Instances`,
  },
})

const ConfigureTemplateInstancesStep: FunctionComponent<StepProps> = ({
  pristine,
  instanceConfigurations,
  template,
  updateDeploymentTemplate,
}) => (
  <div data-test-id='deploymentTemplateWizard-instancesStep'>
    <EuiTitle>
      <h3>
        <FormattedMessage
          id='configure-template-instances-step.title'
          defaultMessage='Configure instances'
        />
      </h3>
    </EuiTitle>

    <EuiSpacer size='m' />

    <EuiText>
      <p>
        <FormattedMessage
          id='configure-template-instances-step.description'
          defaultMessage='Select and configure the Elastic stack products to include in the template.'
        />
      </p>
    </EuiText>

    <EuiSpacer size='xl' />

    <TemplateAutoscalingToggle
      deploymentTemplate={template}
      updateDeploymentTemplate={updateDeploymentTemplate}
    />

    <EuiSpacer size='xl' />

    <DeploymentTemplateInfrastructure
      deployment={template.deployment_template}
      instanceConfigurations={instanceConfigurations}
      updateDeploymentTemplate={(deployment_template) =>
        updateDeploymentTemplate({ ...template, deployment_template })
      }
    />

    <ConfigureTemplateElasticsearchStepErrors
      pristine={pristine}
      instanceConfigurations={instanceConfigurations}
      template={template}
    />
  </div>
)

export const instancesStepDefinition: StepDefinition = {
  title: messages.title,
  ConfigureStep: ConfigureTemplateInstancesStep,
  validateStep: validateElasticsearchStep,
}
