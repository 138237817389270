/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import RestoreSnapshot from '../../../RestoreSnapshot'
import DeploymentLockGate from '../../../DeploymentLockGate'

import SnapshotDetailsList from './SnapshotDetailsList'

import type { FunctionComponent } from 'react'
import type { AllProps } from './types'

const SnapshotDetails: FunctionComponent<AllProps> = ({
  deployment,
  restore,
  snapshot,
  snapshotStatus,
  fetchSnapshots,
  fetchSnapshotsRequest,
  restoreSnapshotRequest,
  restoreSnapshot,
  resetRestoreSnapshot,
  isInProgress,
}) => {
  if (!deployment || !snapshot) {
    return null
  }

  return (
    <div>
      <SnapshotDetailsList
        deployment={deployment}
        fetchSnapshots={() => fetchSnapshots({ deployment })}
        fetchSnapshotsRequest={fetchSnapshotsRequest}
        snapshot={snapshot}
        snapshotStatus={snapshotStatus}
        isInProgress={isInProgress}
      />

      <DeploymentLockGate>
        <RestoreSnapshot
          snapshot={snapshot != null}
          restore={restore}
          restoreSnapshotRequest={restoreSnapshotRequest}
          restoreSnapshot={(payload) =>
            restoreSnapshot({ deployment, snapshotName: snapshot!.snapshot, payload })
          }
          resetRestoreSnapshot={() => resetRestoreSnapshot(deployment.id)}
        />
      </DeploymentLockGate>
    </div>
  )
}

export default SnapshotDetails
