/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiComboBox, EuiFormRow, EuiSelect, EuiSpacer } from '@elastic/eui'

import { CuiFormSection } from '../../../../../cui'
import { SIZING_UNIT_STORAGE } from '../../../../../lib/instanceConfigurations/multipliers'
import { prettyDecimals } from '../../../../../lib/numbers'
import prettySize from '../../../../../lib/prettySize'

import type { SizingUnit } from '../../../../../lib/instanceConfigurations/multipliers'
import type { ReactNode } from 'react'

const messages = defineMessages({
  ram: {
    id: `instance-configuration-choose-multipliers.memory`,
    defaultMessage: `RAM`,
  },
  storage: {
    id: `instance-configuration-choose-multipliers.storage`,
    defaultMessage: `Storage`,
  },
  availableIncrements: {
    id: `instance-configuration-choose-multipliers.available-increments`,
    defaultMessage: `Available sizes in GB`,
  },
  availableIncrementsHelpText: {
    id: `instance-configuration-choose-multipliers.available-increments-description`,
    defaultMessage: `Specifies the available node size increments for the instance configuration, based on the resource unit type. You can add or remove increments as needed to use your hardware efficiently. When a deployment gets created, the selection dictates the size of each node.`,
  },
  defaultValue: {
    id: `instance-configuration-choose-multipliers.default-value`,
    defaultMessage: `Default size`,
  },
})

type Bounds = {
  min: number
  max: number
}

type Props = {
  ratio: number
  increments: number[]
  onChangeIncrements: (increments: number[]) => void
  bounds: Bounds
  sizingUnit: SizingUnit
  defaultValue: number
  onChangeDefaultValue: (newDefault: number) => void
}

type State = {
  areIncrementsInvalid: boolean
  duplicateIncrementError: ReactNode
}

class SelectIncrementsSubstep extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      areIncrementsInvalid: false,
      duplicateIncrementError: undefined,
    }
  }

  render() {
    const { sizingUnit, ratio, bounds, increments, defaultValue, onChangeDefaultValue } = this.props

    const { areIncrementsInvalid, duplicateIncrementError } = this.state

    const minSize = prettySize(sizingUnit === SIZING_UNIT_STORAGE ? ratio * bounds.min : bounds.min)
    const maxSize = prettySize(sizingUnit === SIZING_UNIT_STORAGE ? ratio * bounds.max : bounds.max)
    const formattedIncrements = increments.map((increment) => ({
      label: prettyDecimals(increment / 1024).toString(),
      value: increment,
    }))

    const incrementErrors: ReactNode[] = []

    if (areIncrementsInvalid) {
      incrementErrors.push(
        <FormattedMessage
          id='instance-configuration-choose-multipliers.increment-range-error'
          defaultMessage='Enter increments as whole numbers between {minSize} and {maxSize}, or as 0.5'
          values={{
            minSize,
            maxSize,
          }}
        />,
      )
    }

    if (duplicateIncrementError) {
      incrementErrors.push(duplicateIncrementError)
    }

    return (
      <Fragment>
        <CuiFormSection help={<FormattedMessage {...messages.availableIncrementsHelpText} />}>
          <EuiFormRow
            data-test-id='available-increment'
            label={<FormattedMessage {...messages.availableIncrements} />}
            isInvalid={incrementErrors.length > 0}
            error={incrementErrors}
          >
            <EuiComboBox
              id='available-increment-combobox'
              aria-label='available-increment-combo'
              noSuggestions={true}
              placeholder={`Specify ${sizingUnit} increments`}
              selectedOptions={formattedIncrements}
              onCreateOption={this.onCreateIncrement}
              onChange={(options) =>
                this.onChangeIncrements(options as unknown as Array<{ value: number }>)
              }
              onSearchChange={this.onChangeIncrementInput}
              isInvalid={areIncrementsInvalid}
            />
          </EuiFormRow>
        </CuiFormSection>

        <EuiSpacer size='l' />

        <EuiFormRow
          data-test-id='default-size-select'
          label={<FormattedMessage {...messages.defaultValue} />}
        >
          <div>
            <EuiSelect
              value={defaultValue}
              onChange={(e) =>
                onChangeDefaultValue(parseInt((e.target as HTMLSelectElement).value, 10))
              }
              options={increments.map((value) => ({
                text: prettySize(value),
                value,
              }))}
            />
          </div>
        </EuiFormRow>
      </Fragment>
    )
  }

  isIncrementValid = (increment: string) => {
    const { sizingUnit, ratio, bounds } = this.props

    if (!increment) {
      return true
    }

    const parsedValue = 1024 * parseFloat(increment)
    return !isNaN(parsedValue) && ensureIncrementIsValid(bounds, parsedValue, sizingUnit, ratio)
  }

  onChangeIncrementInput = (incrementInput: string) => {
    if (!incrementInput) {
      this.setState({
        areIncrementsInvalid: false,
      })

      return
    }

    const parsedValue = 1024 * parseFloat(incrementInput)
    const hasDuplicateIncrement = this.props.increments.includes(parsedValue)

    this.setState({
      areIncrementsInvalid: !this.isIncrementValid(incrementInput),
      duplicateIncrementError: hasDuplicateIncrement ? (
        <FormattedMessage
          id='instance-configuration-choose-multipliers.duplicate-increment-error'
          defaultMessage='You already have an increment of {incrementInput}'
          values={{
            incrementInput,
          }}
        />
      ) : undefined,
    })
  }

  onCreateIncrement = (increment: string) => {
    if (!this.isIncrementValid(increment)) {
      // Return false to explicitly reject the user's input.
      return false
    }

    const parsedIncrement = 1024 * parseFloat(increment)

    // Sort increments in ascending order.
    const newIncrements = [...this.props.increments, parsedIncrement].sort((a, b) => a - b)
    this.props.onChangeIncrements(newIncrements)
  }

  onChangeIncrements = (increments: Array<{ value: number }>) => {
    // This is called when the user deletes an increment.
    const incrementValues = increments.map((increment) => increment.value)
    const newDefault = getNewDefault(incrementValues, this.props.defaultValue)

    this.props.onChangeIncrements(incrementValues)
    this.setState(
      {
        areIncrementsInvalid: false,
      },
      () => {
        if (newDefault) {
          this.props.onChangeDefaultValue(newDefault)
        }
      },
    )
  }
}

export default SelectIncrementsSubstep

function ensureIncrementIsValid(
  bounds: Bounds,
  increment: number,
  sizingUnit: SizingUnit,
  ratio: number,
) {
  let { max, min } = bounds

  if (sizingUnit === SIZING_UNIT_STORAGE) {
    max = max * ratio
    min = min * ratio
  }

  // Allow a special case of half a GB
  return (increment >= min && increment <= max) || increment === 512
}

function ensureDefaultValueIsValid(increments: number[], defaultValue: number) {
  return increments.includes(defaultValue)
}

function getNewDefault(increments: number[], defaultValue: number) {
  const hasNewDefault = !ensureDefaultValueIsValid(increments, defaultValue)

  if (hasNewDefault) {
    return increments[0]
  }
}
