/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import { getDirectTrustRelationshipTrustRestrictionSettings } from '@/lib/stackDeployments/trustRelationships'
import { securityUrl } from '@/lib/urlBuilder'

import type { AllProps } from './types'

class DirectTrustRelationshipInstructions extends React.Component<AllProps> {
  componentDidMount(): void {
    const { deployment, fetchCertificateAuthority } = this.props

    fetchCertificateAuthority(deployment.id)
  }

  render(): JSX.Element | null {
    const { deployment, trustRelationship } = this.props

    return (
      <EuiFlexGroup justifyContent='center'>
        <EuiPanel
          paddingSize='l'
          style={{ maxWidth: `50rem`, paddingLeft: `3rem`, paddingRight: `3rem` }}
        >
          <EuiFlexGroup direction='column' alignItems='center' gutterSize='s'>
            <EuiFlexItem>
              <EuiIcon color='success' type='checkInCircleFilled' size='xxl' />
              <EuiSpacer size='m' />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle size='m'>
                <h1>
                  <FormattedMessage
                    id='directTrustRelationshipInstructions.title'
                    defaultMessage='Environment trusted successfully'
                  />
                </h1>
              </EuiTitle>
              <EuiSpacer size='s' />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle size='xs'>
                <EuiTextColor color='subdued'>
                  <h2 style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage
                      id='directTrustRelationshipInstructions.subtitle'
                      defaultMessage='Next Steps'
                    />
                  </h2>
                </EuiTextColor>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='xxl' />

          <EuiText>
            <p>
              <FormattedMessage
                id='directTrustRelationshipInstructions.intro'
                defaultMessage='The trusted environment also needs to trust this deployment. If trust has not been configured on the trusted environment yet, follow these steps:'
              />
            </p>
          </EuiText>

          <EuiSpacer size='xl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step1.title'
                defaultMessage='Step 1: Download CA certificate'
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step1.body'
              defaultMessage='Download the CA certficate of this deployment. You need it to configure trust with this deployment from your self-managed Elasticsearch cluster.'
            />
          </EuiText>
          <EuiSpacer size='m' />
          {this.renderCertButton()}

          <EuiSpacer size='xxl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step2.title'
                defaultMessage='Step 2: Save trust restriction settings'
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step2.body'
              defaultMessage='Copy or download the following trust restriction settings. You need to add these settings to your self-managed environment configuration, in addition to the CA certificate you downloaded in step 1, to make sure that trust is limited to all or specific cluster nodes of this deployment.'
            />
          </EuiText>
          <EuiSpacer size='m' />
          {this.renderSettingsBox()}

          <EuiSpacer size='xxl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step3.title'
                defaultMessage='Step 3: Configure {environmentName} to trust this deployment'
                values={{
                  environmentName: trustRelationship.name,
                }}
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step3.body'
              defaultMessage='For the trust relationship to be fully functional, it must be bi-lateral. Use the CA certificate and trust restriction settings you just downloaded to configure your self-managed Elasticsearch cluster to trust this deloyment.'
            />
          </EuiText>

          <EuiSpacer size='xl' />

          <EuiFlexGroup direction='column' alignItems='center'>
            <EuiFlexItem>
              <EuiButton fill={true} href={securityUrl(deployment.id)}>
                <FormattedMessage
                  id='directTrustRelationshipInstructions.done'
                  defaultMessage='Done'
                />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexGroup>
    )
  }

  renderCertButton = (): JSX.Element | null => {
    const [cert] = this.props.certificateAuthority?.public_certificates || []

    const text = (
      <FormattedMessage
        id='directTrustRelationshipInstructions.downloadCertButton'
        defaultMessage='Download CA certficate'
      />
    )

    if (!cert) {
      return <EuiButton isLoading={true}>{text}</EuiButton>
    }

    const blob = new window.Blob([cert.pem || ``], {
      type: `application/x-x509-ca-cert;charset=utf-8`,
    })
    const fileName = `${cert.metadata.fingerprint}.crt`
    const contents = window.URL.createObjectURL(blob)

    return (
      <EuiButton download={fileName} href={contents} iconType='download'>
        {text}
      </EuiButton>
    )
  }

  renderSettingsBox = (): JSX.Element => {
    const { trustRelationship, certificateAuthority } = this.props

    const yaml = getDirectTrustRelationshipTrustRestrictionSettings(
      trustRelationship,
      certificateAuthority,
    )

    const blob = new window.Blob([yaml], {
      type: `application/yaml;charset=utf-8`,
    })
    const fileName = `trust.yml`
    const contents = window.URL.createObjectURL(blob)

    return (
      <Fragment>
        <EuiCodeBlock isCopyable={true} paddingSize='m' language='yaml' whiteSpace='pre'>
          {yaml}
        </EuiCodeBlock>
        <EuiSpacer size='m' />
        <EuiButton download={fileName} href={contents} iconType='download'>
          <FormattedMessage
            id='directTrustRelationshipInstructions.downloadYaml'
            defaultMessage='Download trust.yml'
          />
        </EuiButton>
      </Fragment>
    )
  }
}

export default DirectTrustRelationshipInstructions
