/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { get, isEmpty } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiLink,
  EuiCallOut,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert, CuiLink } from '../../cui'
import AllocatorHealth from '../AllocatorHealth'
import RunnerBuild from '../RunnerBuild'
import InstanceCapacitiesViz from '../Allocators/InstanceCapacitiesViz'
import InstanceCapacitiesVizLegend from '../Allocators/InstanceCapacitiesVizLegend'
import DiskUsage from '../DiskUsage'
import { hasAllocatorRole } from '../../lib/hostRoles'
import { activityFeedUrl } from '../../lib/urlBuilder'

import type { Allocator, AsyncRequestState, Runner } from '../../types'

type Props = {
  allocator?: Allocator
  allocatorRequest: AsyncRequestState
  runner: Runner
  startManagingRoles: () => void
}

class AllocatorSummary extends Component<Props> {
  render() {
    return (
      <Fragment>
        {this.renderMissingRoleWarning()}

        {this.renderFetchAllocatorError()}
        {this.renderMoveNodesError()}
        {this.renderMaintenanceWarning()}
        {this.renderMovingNodesWarning()}

        {this.renderSummaryHeading()}
      </Fragment>
    )
  }

  renderSummaryHeading() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const { capacity, instances, regionId } = allocator
    const { used, total } = capacity

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <EuiFlexGroup gutterSize='l'>
          <EuiFlexItem grow={false}>
            <EuiFormLabel>
              <FormattedMessage id='allocator-summary.health' defaultMessage='Health' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <div>
              <AllocatorHealth allocator={allocator} />
            </div>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFormLabel>
              <FormattedMessage id='allocator-summary.build' defaultMessage='Build' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <RunnerBuild build={allocator.build} />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFlexGroup gutterSize='m' justifyContent='spaceBetween' alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiFormLabel>
                  <FormattedMessage
                    id='allocator-summary.capacity'
                    defaultMessage='Allocated RAM capacity'
                  />
                </EuiFormLabel>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <InstanceCapacitiesVizLegend allocators={[allocator]} />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size='s' />

            <InstanceCapacitiesViz
              regionId={regionId}
              instances={instances}
              usedCapacity={used}
              totalCapacity={total}
              largestCapacity={total}
            />

            <DiskUsage available={total - used} total={total} withProgress={false} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderFetchAllocatorError() {
    const { allocatorRequest } = this.props

    if (!allocatorRequest.error) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='error' iconType='cross'>
          {allocatorRequest.error}
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderMoveNodesError() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    if (allocator.instances.length === 0) {
      return null
    }

    if (allocator.connected && allocator.healthy) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='error' iconType='cross'>
          <FormattedMessage
            id='allocator-overview.unhealthy-allocator-move-nodes'
            defaultMessage="This allocator has problems. You can mitigate allocator failures by moving all affected nodes to a healthy allocator. Move all nodes first and then address the cause of the problem separately. (We'd love to help fix the problem, but we don't know what caused it.)"
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderMaintenanceWarning() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    if (!allocator.isInMaintenanceMode) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='warning' iconType='wrench'>
          <FormattedMessage
            id='allocator-summary.maintenance-mode'
            defaultMessage='This allocator is currently under maintenance.'
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderMovingNodesWarning() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const vacatingNodes = allocator.instances.filter(
      (node) => get(node, [`plan`, `allocatorBeingVacated`]) === allocator.id,
    )

    if (isEmpty(vacatingNodes)) {
      return null
    }

    return (
      <Fragment>
        <EuiCallOut
          title={
            <FormattedMessage
              id='allocator-summary.vacating-nodes'
              defaultMessage='Please hold on while we move {amount} {amount, plural, one {instance} other {instances}} off this allocator. {goToActivity}.'
              values={{
                amount: vacatingNodes.length,
                goToActivity: (
                  <CuiLink to={activityFeedUrl()}>
                    <FormattedMessage
                      id='cluster-status.see-activity'
                      defaultMessage='Go to Activity'
                    />
                  </CuiLink>
                ),
              }}
            />
          }
          color='warning'
          iconType='bolt'
          data-test-subj='moving-instances-warning'
        />

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderMissingRoleWarning() {
    const { runner, startManagingRoles } = this.props

    if (hasAllocatorRole({ runner })) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='warning' iconType='cloudStormy'>
          <FormattedMessage
            id='allocator-wrapper.host-not-an-allocator'
            defaultMessage="This host doesn't have the Allocator role. You can add it in {manageRoles}."
            values={{
              manageRoles: (
                <EuiLink onClick={startManagingRoles}>
                  <FormattedMessage
                    id='allocator-wrapper.manage-roles'
                    defaultMessage='Manage roles'
                  />
                </EuiLink>
              ),
            }}
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }
}

export default AllocatorSummary
