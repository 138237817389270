/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { get } from 'lodash'
import { Component } from 'react'

import { withErrorBoundary } from '../../../cui'

import type { ReactElement } from 'react'
import type { AsyncRequestState } from '../../../types'

type Props = {
  isEceAdminconsole?: boolean
  apiBaseUrl?: string | null
  fetchApiBaseUrl: () => void
  fetchApiBaseUrlRequest: AsyncRequestState
  updateApiBaseUrl: (payload: { apiBaseUrl: string }) => void
  updateApiBaseUrlRequest: AsyncRequestState
  children: ReactElement
}

class EnsureApiBaseUrl extends Component<Props> {
  componentDidMount() {
    this.fetchApiBaseUrl()
  }

  componentDidUpdate() {
    this.ensureApiBaseUrl()
  }

  render() {
    return this.props.children
  }

  fetchApiBaseUrl() {
    const { isEceAdminconsole, fetchApiBaseUrl } = this.props

    if (!isEceAdminconsole) {
      return // in ESS this is set through infra configuration
    }

    fetchApiBaseUrl()
  }

  ensureApiBaseUrl() {
    const {
      isEceAdminconsole,
      updateApiBaseUrl,
      fetchApiBaseUrlRequest,
      updateApiBaseUrlRequest,
      apiBaseUrl,
    } = this.props

    if (!isEceAdminconsole) {
      return // in ESS this is set through infra configuration
    }

    if (fetchApiBaseUrlRequest.inProgress) {
      return // fetching
    }

    const fetchWasEmpty = get(fetchApiBaseUrlRequest.error, ['response', 'status']) === 404

    const fetchError = fetchApiBaseUrlRequest.error && !fetchWasEmpty

    if (fetchError) {
      return // fetch error — but not a 404 — best to avoid (404 means not set)
    }

    if (!fetchApiBaseUrlRequest.error && !fetchApiBaseUrlRequest.isDone) {
      return // fetch hasn't finished yet
    }

    if (updateApiBaseUrlRequest.inProgress) {
      return // an update is already in progress
    }

    if (updateApiBaseUrlRequest.error) {
      return // an update was already attempted, but failed
    }

    if (updateApiBaseUrlRequest.isDone) {
      return // an update was already performed
    }

    if (apiBaseUrl === undefined) {
      return // sanity check — ECE API URL has somehow not been set yet
    }

    if (apiBaseUrl !== null) {
      return // ECE API URL is already set
    }

    updateApiBaseUrl({ apiBaseUrl: window.location.origin })
  }
}

export default withErrorBoundary(EnsureApiBaseUrl)
