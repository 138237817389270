/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiLoadingContent, EuiCheckbox, EuiSpacer } from '@elastic/eui'

import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors'
import { deploymentUrl } from '@/lib/urlBuilder'
import { CuiTable, CuiAlert, CuiLink } from '@/cui'

import type { DeploymentSearchResponse, DeploymentsSearchResponse } from '@/lib/api/v1/types'
import type { CuiTableColumn } from '@/cui'
import type { WrappedComponentProps } from 'react-intl'
import type { AsyncRequestState, RegionId } from '@/types'

interface Props extends WrappedComponentProps {
  regionId: RegionId
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  snapshotRepoId: string
  fetchDeploymentsUsingThisSnapshotRepo: (repoId: string) => void
  cleanupDeployments: boolean
  setCleanupDeployments: (cleanupDeployments: boolean) => void
}

const messages = defineMessages({
  deploymentId: {
    id: `delete-snapshot-repo.table.deployment-id`,
    defaultMessage: `Deployment ID`,
  },
  deploymentName: {
    id: `delete-snapshot-repo.table.deployment-name`,
    defaultMessage: `Deployment name`,
  },
  numberOfSnapshots: {
    id: `delete-snapshot-repo.table.number-of-snapshots`,
    defaultMessage: `Number of snapshots`,
  },
  cleanupDeployments: {
    id: `delete-snapshot-repo.cleanup-deployments`,
    defaultMessage: `Remove snapshot repository configuration from {deploymentCount, plural, one {this deployment} other {these deployments}}`,
  },
})

class DeleteSnapshotRepositoryModalBody extends Component<Props> {
  componentDidMount() {
    const { fetchDeploymentsUsingThisSnapshotRepo, snapshotRepoId } = this.props
    fetchDeploymentsUsingThisSnapshotRepo(snapshotRepoId)
  }

  render() {
    const {
      intl: { formatMessage },
      searchResultsRequest,
      searchResults,
      cleanupDeployments,
      setCleanupDeployments,
    } = this.props

    if (searchResultsRequest.error) {
      return <CuiAlert type='error'>{searchResultsRequest.error}</CuiAlert>
    }

    if (searchResultsRequest.inProgress || searchResults == null) {
      return <EuiLoadingContent lines={1} />
    }

    if (searchResults.match_count === 0) {
      return (
        <FormattedMessage
          id='delete-snapshot-repo.no-issues'
          defaultMessage='There are no deployments that store snapshots in this repository.'
        />
      )
    }

    return (
      <Fragment>
        <FormattedMessage
          id='delete-snapshot-repo.found-matches'
          defaultMessage='There {deploymentCount, plural, one {is a deployment} other {are {deploymentCount} deployments}} using this snapshot repository to store snapshots.'
          values={{
            deploymentCount: searchResults.match_count,
          }}
        />

        <EuiSpacer size='s' />

        <FormattedMessage
          id='delete-snapshot-repo.explain-issues'
          defaultMessage='Deleting this repository will not delete existing snapshots. However, new snapshots will not be taken, and any configuration changes will fail. We recommend changing repositories before deleting this one.'
        />

        <EuiSpacer size='s' />

        {this.renderMatchingDeployments(searchResults)}

        <EuiSpacer size='m' />

        <EuiCheckbox
          id='cleanup-snapshot-metadata-from-deployments'
          label={formatMessage(messages.cleanupDeployments, {
            deploymentCount: searchResults.match_count,
          })}
          checked={cleanupDeployments}
          onChange={() => setCleanupDeployments(!cleanupDeployments)}
        />
      </Fragment>
    )
  }

  renderMatchingDeployments(searchResults: DeploymentsSearchResponse) {
    const {
      intl: { formatMessage },
    } = this.props

    const columns: Array<CuiTableColumn<DeploymentSearchResponse>> = [
      {
        label: formatMessage(messages.deploymentId),
        render: ({ id }) => {
          const href = deploymentUrl(id)
          return <CuiLink to={href}>{id.slice(0, 6)}</CuiLink>
        },
        sortKey: `id`,
        width: `300px`,
      },
      {
        label: formatMessage(messages.deploymentName),
        render: ({ name }) => name,
        sortKey: `displayName`,
      },
      {
        label: formatMessage(messages.numberOfSnapshots),
        render: (deployment) =>
          String(getFirstEsClusterFromGet({ deployment })?.info.snapshots.count),
        sortKey: `snapshots.status.totalCount`,
      },
    ]

    return (
      <CuiTable
        columns={columns}
        rows={searchResults.deployments}
        data-test-id='elastic-stack-versions-table'
      />
    )
  }
}

export default injectIntl(DeleteSnapshotRepositoryModalBody)
