/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CuiRouterLinkButton } from '../../../../cui'
import CurrentHourlyRate from '../RatePanel/CurrentHourlyRate'
import { createDeploymentUrl } from '../../../../lib/urlBuilder'
import CustomerOnlyAction from '../../CustomerOnlyAction'

import type { FunctionComponent } from 'react'

import './trialNotStarted.scss'

interface Props {
  isPrepaidConsumptionUser: boolean
}

const TrialNotStarted: FunctionComponent<Props> = ({ isPrepaidConsumptionUser }) => (
  <div className='cost-analysis-trial-not-started' data-test-id='cost-analysis-trial-not-started'>
    <EuiFlexGroup gutterSize='l'>
      <EuiFlexItem>
        <EuiPanel paddingSize='m' className='cost-analysis-get-started-panel'>
          <EuiTitle size='xs'>
            <h2>
              <FormattedMessage
                id='cost-analysis.trial-not-started.no-deployments'
                defaultMessage='Get started with Elastic'
              />
            </h2>
          </EuiTitle>

          <EuiSpacer size='m' />

          <EuiText color='subdued' size='s'>
            <FormattedMessage
              id='cost-analysis.start-trial-info'
              defaultMessage='Start your 14-day trial by creating your first deployment.'
            />
          </EuiText>

          <EuiSpacer size='m' />

          <CustomerOnlyAction
            message={
              <FormattedMessage
                id='cost-analysis.trial-not-started.create-a-deployment-disabled-help'
                defaultMessage='Elastic administrators cannot create deployments on behalf of customers.'
              />
            }
          >
            <CuiRouterLinkButton
              data-test-id='cost-analysis.trial-not-started.create-a-deployment'
              to={createDeploymentUrl()}
            >
              <FormattedMessage
                id='cost-analysis.trial-not-started.create-a-deployment'
                defaultMessage='Create a deployment'
              />
            </CuiRouterLinkButton>
          </CustomerOnlyAction>
        </EuiPanel>
      </EuiFlexItem>

      <EuiFlexItem>
        <CurrentHourlyRate
          isPrepaidConsumptionUser={isPrepaidConsumptionUser}
          info={[
            {
              text: (
                <EuiText color='subdued' size='s'>
                  <FormattedMessage
                    id='cost-analysis.trial-not-started.free-during-trial'
                    defaultMessage='FREE during trial'
                  />
                </EuiText>
              ),
            },
          ]}
          rate={0}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  </div>
)

export default TrialNotStarted
