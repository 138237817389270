/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import AppLoadingRoot from '../../../../components/AppLoadingRoot'
import { hasAnySsoMethod, hasRedirectOnMount, redirectOnMount } from '../../../../lib/auth'

import type { AsyncRequestState } from '../../../../types'
import type { AvailableAuthenticationMethods } from '../../../../lib/api/v1/types'

type Props = {
  location: any
  fetchAuthMethods: () => void
  authMethods: AvailableAuthenticationMethods | null
  newBearerToken: string | null
  redirectAfterLogin: (redirectTo?: string) => () => void
  redirectTo?: string
  fetchAuthMethodsRequest: AsyncRequestState
}

class LoginIndex extends Component<Props> {
  componentDidMount() {
    const {
      fetchAuthMethods,
      authMethods,
      newBearerToken,
      fetchAuthMethodsRequest,
      redirectTo,
      redirectAfterLogin,
    } = this.props

    if (hasRedirectOnMount(newBearerToken)) {
      redirectOnMount({ redirectTo, redirectAfterLogin, newBearerToken })
      return
    }

    if (!authMethods && !fetchAuthMethodsRequest.inProgress) {
      fetchAuthMethods()
    }
  }

  render() {
    const { authMethods, newBearerToken, fetchAuthMethodsRequest, location } = this.props
    const useSso = hasAnySsoMethod(authMethods)

    if (!authMethods || fetchAuthMethodsRequest.inProgress) {
      return <AppLoadingRoot />
    }

    if (hasRedirectOnMount(newBearerToken)) {
      return null
    }

    if (useSso) {
      return <Redirect to={{ pathname: '/login/sso', search: location.search }} />
    }

    return <Redirect to={{ pathname: '/login/basic', search: location.search }} />
  }
}

export default LoginIndex
