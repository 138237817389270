/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchAllowExtraVersionsStatus,
  updateAllowExtraVersionsStatus,
} from '../../../../../actions/allowExtraVersionsStatus'
import {
  fetchAllowExtraVersionsStatusRequest,
  updateAllowExtraVersionsStatusRequest,
  getAllowExtraVersionsStatus,
} from '../../../../../reducers'

import Status from './Status'
import { StateProps, ConsumerProps, DispatchProps } from './types'

const mapStateToProps = (state, { regionId, organizationId }: ConsumerProps) => ({
  allowExtraVersionsStatus: getAllowExtraVersionsStatus(state),
  fetchExtraVersionsStatusRequest: fetchAllowExtraVersionsStatusRequest(
    state,
    regionId,
    organizationId,
  ),
  updateAllowExtraVersionsStatusRequest: updateAllowExtraVersionsStatusRequest(
    state,
    regionId,
    organizationId,
  ),
  regionId,
})

const mapDispatchToProps = (dispatch, { regionId, organizationId }: ConsumerProps) => ({
  fetchAllowExtraVersionsStatus: () =>
    dispatch(fetchAllowExtraVersionsStatus(regionId, organizationId)),
  updateAllowExtraVersionsStatus: (newValue: boolean) =>
    dispatch(updateAllowExtraVersionsStatus(regionId, organizationId, newValue)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Status)
