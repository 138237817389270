/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, size } from 'lodash'

import { EuiSpacer, EuiText } from '@elastic/eui'

import { CuiAlert, getFilterQueryString, setFilterQueryString } from '../../cui'
import schedule from '../../lib/schedule'

import ProxyFilterContext, { executeQuery, toggleField } from './ProxyFilterContext'
import ProxiesTable from './ProxiesTable'

import type { ControlledFilterQuery, OnFilterChangeParams } from '../../cui'
import type { Region, RegionProxies, AsyncRequestState, RegionProxy } from '../../types'

type Props = {
  fetchProxies: (regionId: string) => void
  proxies?: RegionProxies
  proxiesRequest: AsyncRequestState
  region?: Region
  regionId: string
}

type State = {
  query: ControlledFilterQuery
  queryResults: RegionProxy[]
}

class Proxies extends Component<Props, State> {
  state = {
    query: getFilterQueryString({ storageKey: `Proxies` }),
    queryResults: [],
  }

  render() {
    const { proxies, proxiesRequest } = this.props

    if (proxiesRequest.error) {
      return <CuiAlert type='error'>{proxiesRequest.error}</CuiAlert>
    }

    const { query } = this.state

    return (
      <Fragment>
        <EuiText color='subdued'>
          <FormattedMessage
            id='proxies.description'
            defaultMessage='Proxies are hosts that route requests to the instances in Elastic Stack deployments.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <ProxyFilterContext
          query={query}
          onChange={this.onChange}
          proxies={proxies && proxies.proxies}
        />

        {this.renderProxies()}
      </Fragment>
    )
  }

  renderProxies() {
    const { regionId, proxies: rawProxies } = this.props
    const { queryResults } = this.state

    const loadedButNoMatches = rawProxies && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const originalProxies = rawProxies && rawProxies.proxies
    const totalCount = size(originalProxies)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <ProxiesTable
          regionId={regionId}
          proxies={queryResults}
          totalCount={totalCount}
          onZoneClick={this.onZoneClick}
          initialLoading={!rawProxies}
        />
      </Fragment>
    )
  }

  onZoneClick = (zoneId: string) => {
    const { query } = this.state
    const updatedQuery = toggleField({ query, field: 'zone', value: zoneId })

    this.setQuery(updatedQuery)
  }

  setQuery = (query: { text: string }) => {
    const { proxies } = this.props

    const queryText = query.text
    const queryResults = executeQuery({ query, records: proxies && proxies.proxies })

    this.onChange({ queryText, queryResults })
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<RegionProxy>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `Proxies`, queryText })
  }
}

export default schedule(Proxies, ({ fetchProxies, regionId }) => fetchProxies(regionId))
