/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { get } from 'lodash'
import { parse } from 'query-string'

import {
  fetchDeploymentTemplatesRequest,
  getDeploymentTemplate,
  getStackDeployment,
} from '@/reducers'
import {
  getVersion,
  getEsPlanFromGet,
  getDeploymentTemplateId,
} from '@/lib/stackDeployments/selectors'

import { withStackDeploymentRouteParams } from '../routing'

import EditStackDeploymentEditorDependencies from './EditStackDeploymentEditorDependencies'

import type { ReduxState } from '@/types'
import type { StateProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { regionId, location, stackDeploymentId }: ConsumerProps,
): StateProps => {
  const query = parse(location.search.slice(1))
  const stackDeployment = getStackDeployment(state, stackDeploymentId)!
  const version = getVersion({ deployment: stackDeployment })
  const deploymentTemplateId = getDeploymentTemplateId({ deployment: stackDeployment })
  const deploymentTemplate =
    (deploymentTemplateId &&
      version &&
      getDeploymentTemplate(state, regionId, deploymentTemplateId, version)) ||
    null

  const currentEsPlan = getEsPlanFromGet({ deployment: stackDeployment })!
  const pendingEsPlan = getEsPlanFromGet({ deployment: stackDeployment, state: `pending` })!

  const versionPath = [`elasticsearch`, `version`]
  const noCurrentVersion = !get(currentEsPlan, versionPath)
  const noPendingVersion = !get(pendingEsPlan, versionPath)

  // if there is no pending or current plan (as evidenced by a lack of version)
  // then we can't show anything other than the last plan attempt
  const showFailedAttempt =
    String(query.fromAttempt) === `true` || (noCurrentVersion && noPendingVersion)

  // this parameter is enabled on the request when loading the page in <ChromeNavigation />
  const showMaxZones = 'true'

  return {
    stackDeployment,
    deploymentTemplate,
    showFailedAttempt,
    fetchDeploymentTemplatesRequest: fetchDeploymentTemplatesRequest(
      state,
      regionId,
      version,
      showMaxZones,
    ),
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, unknown, ConsumerProps>(mapStateToProps)(
    EditStackDeploymentEditorDependencies,
  ),
)
