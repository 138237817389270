/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { hasReachedEol, isApproachingEol } from '@/lib/eolStatus'
import { getDaysUntilEol } from '@/lib/eolStatus/time'

import EndOfLifeLink from '../EndOfLifeLink'
import messages from '../messages'

import type { FunctionComponent } from 'react'
import type { StackResourceEolStatus } from '@/types/eolStatus'

interface Props {
  resourceEolStatus: StackResourceEolStatus
  showLink?: boolean
}

const EndOfLifeMessage: FunctionComponent<Props> = ({ resourceEolStatus, showLink }) => {
  const { version } = resourceEolStatus
  const eolLink = <EndOfLifeLink showLink={showLink} />
  const moreInfo = <FormattedMessage {...messages.moreInfo} values={{ eolLink }} />

  if (isApproachingEol(resourceEolStatus)) {
    const days = <strong>{getDaysUntilEol(resourceEolStatus)}</strong>

    return (
      <FormattedMessage
        {...messages.approachingEol}
        data-test-id='approaching-eol'
        values={{
          version,
          days,
          moreInfo,
        }}
      />
    )
  }

  if (hasReachedEol(resourceEolStatus)) {
    return (
      <FormattedMessage
        {...messages.reachedEol}
        data-test-id='reached-eol'
        values={{
          version,
          moreInfo,
        }}
      />
    )
  }

  return null
}

export default EndOfLifeMessage
