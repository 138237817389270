/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { DELETE_ALLOCATOR } from '../../constants/actions'
import { del } from '../../lib/ajax'
import history from '../../lib/history'
import { hostsAllocatorsUrl } from '../../lib/urlBuilder'
import { deleteAllocatorUrl } from '../../lib/api/v1/urls'

// ts-unused-exports:disable-next-line
export const deleteAllocatorRequest = (regionId: string, ip: string) => ({
  type: DELETE_ALLOCATOR,
  meta: { regionId, ip },
})

// ts-unused-exports:disable-next-line
export const deleteAllocatorError = (regionId: string, ip: string, error: Error) => ({
  type: DELETE_ALLOCATOR,
  error: true,
  payload: error,
  meta: { regionId, ip },
})

// ts-unused-exports:disable-next-line
export const deleteAllocatorResponse = (regionId: string, ip: string, json: unknown) => ({
  type: DELETE_ALLOCATOR,
  payload: json,
  meta: { regionId, ip },
})

export function deleteAllocator(regionId: string, hostId: string, removeInstances: boolean) {
  return (dispatch) => {
    const url = deleteAllocatorUrl({ regionId, allocatorId: hostId, removeInstances })

    dispatch(deleteAllocatorRequest(regionId, hostId))
    return del(url).then(
      (response) => {
        dispatch(deleteAllocatorResponse(regionId, hostId, response.body))
        return history.push(hostsAllocatorsUrl(regionId))
      },
      (error) => dispatch(deleteAllocatorError(regionId, hostId, error)),
    )
  }
}
