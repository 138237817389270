/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiFlexItem, EuiSpacer, EuiFlexGrid } from '@elastic/eui'

import UsageCard from './components/UsageCard'
import UsageRatesFlipCard from './components/UsageRatesFlipCard'
import PrepaidConsumptionOverviewCard from './components/PrepaidConsumptionOverviewCard'

import type { ReactElement } from 'react'

interface PrepaidConsumptionOverviewProps {
  organizationId: string
}

function PrepaidConsumptionOverview({
  organizationId,
}: PrepaidConsumptionOverviewProps): ReactElement {
  return (
    <Fragment>
      <EuiSpacer size='m' />

      <EuiFlexGrid columns={3} gutterSize='xl'>
        {/* Usage Panel */}
        <EuiFlexItem style={{ minWidth: 342 }}>
          <UsageCard organizationId={organizationId} />
        </EuiFlexItem>

        {/* Overview Panel */}
        <EuiFlexItem style={{ minWidth: 342 }}>
          <PrepaidConsumptionOverviewCard organizationId={organizationId} />
        </EuiFlexItem>

        {/* Usage Rates Panel */}
        <EuiFlexItem style={{ minWidth: 342 }}>
          <UsageRatesFlipCard organizationId={organizationId} />
        </EuiFlexItem>
      </EuiFlexGrid>
    </Fragment>
  )
}

export default PrepaidConsumptionOverview
