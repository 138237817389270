/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  updateTrustRelationship,
  resetUpdateTrustRelationship,
} from '../../../actions/trustManagement'
import { updateTrustRelationshipRequest } from '../../../reducers'

import TrustRelationshipFlyout from './TrustRelationshipFlyout'

import type {
  TrustRelationshipUpdateResponse,
  TrustRelationshipUpdateRequest,
  TrustRelationshipGetResponse,
} from '../../../lib/api/v1/types'
import type { AsyncRequestState, ReduxState, ThunkDispatch } from '../../../types'

type StateProps = {
  onSaveRequest: AsyncRequestState
}

type DispatchProps = {
  onSave: (payload: TrustRelationshipUpdateRequest) => Promise<TrustRelationshipUpdateResponse>
  resetOnClose: () => void
}

type ConsumerProps = {
  regionId: string
  trustRelationship: TrustRelationshipGetResponse
}

const mapStateToProps = (
  state: ReduxState,
  { regionId, trustRelationship: { id } }: ConsumerProps,
): StateProps => ({
  onSaveRequest: updateTrustRelationshipRequest(state, regionId, id),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId, trustRelationship }: ConsumerProps,
): DispatchProps => ({
  onSave: (payload) =>
    dispatch(
      updateTrustRelationship({ regionId, payload, trustRelationshipId: trustRelationship.id }),
    ),
  resetOnClose: () => dispatch(resetUpdateTrustRelationship(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrustRelationshipFlyout)
