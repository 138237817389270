/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  learnMore: {
    id: 'auth-provider-messages.learn-more',
    defaultMessage: 'Learn more ...',
  },
  addLdapProvider: {
    id: 'auth-provider-messages.page-title.add-ldap-provider',
    defaultMessage: 'Add LDAP provider',
  },
  editLdapProvider: {
    id: 'auth-provider-messages.page-title.edit-ldap-provider',
    defaultMessage: 'Edit LDAP provider',
  },
  noSuchLdapRealmId: {
    id: 'auth-provider-messages.errors.no-such-ldap-realm-id',
    defaultMessage: 'No LDAP provider with ID {realmId}',
  },
  addActiveDirectoryProvider: {
    id: 'auth-provider-messages.page-title.add-active-directory-provider',
    defaultMessage: 'Add Active Directory provider',
  },
  editActiveDirectoryProvider: {
    id: 'auth-provider-messages.page-title.edit-active-directory-provider',
    defaultMessage: 'Edit Active Directory provider',
  },
  noSuchActiveDirectoryRealmId: {
    id: 'auth-provider-messages.errors.no-such-active-directory-realm-id',
    defaultMessage: 'No Active Directory provider with ID {realmId}',
  },
  addSamlProvider: {
    id: 'auth-provider-messages.page-title.add-saml-provider',
    defaultMessage: 'Add SAML provider',
  },
  editSamlProvider: {
    id: 'auth-provider-messages.page-title.edit-saml-provider',
    defaultMessage: 'Edit SAML provider',
  },
  noSuchSamlRealmId: {
    id: 'auth-provider-messages.errors.no-such-saml-realm-id',
    defaultMessage: 'No SAML provider with ID {realmId}',
  },
  thereAreProblems: {
    id: 'auth-provider-messages.errors.there-are-problems',
    defaultMessage: 'There are problems with this configuration',
  },
  pleaseCorrectProblems: {
    id: 'auth-provider-messages.errors.please-correct-problems',
    defaultMessage: 'Please correct the problems above before continuing.',
  },
  advancedSettings: {
    id: 'auth-provider-messages.advanced-settings',
    defaultMessage: 'Advanced settings',
  },
  triggersPlanChange: {
    id: 'auth-provider-messages.triggers-plan-change',
    defaultMessage: `Applying your changes might take a while. During this time, you can't make changes to other provider profiles.`,
  },
})

export const loadBalancing = defineMessages({
  failover: {
    id: 'auth-provider-messages.load-balancing.failover',
    defaultMessage: 'Failover',
  },
  dnsFailover: {
    id: 'auth-provider-messages.load-balancing.dns-failover',
    defaultMessage: 'DNS failover',
  },
  roundRobin: {
    id: 'auth-provider-messages.load-balancing.round-robin',
    defaultMessage: 'Round robin',
  },
  dnsRoundRobin: {
    id: 'auth-provider-messages.load-balancing.dns-round-robin',
    defaultMessage: 'DNS round robin',
  },
})

export const scopeValues = defineMessages({
  subTree: {
    id: 'auth-provider-messages.scope.sub-tree',
    defaultMessage: 'Sub-tree',
  },
  oneLevel: {
    id: 'auth-provider-messages.scope.one-level',
    defaultMessage: 'One level',
  },
  base: {
    id: 'auth-provider-messages.scope.base',
    defaultMessage: 'Base',
  },
})

export const roles = defineMessages({
  platformTitle: {
    id: 'auth-provider-messages.roles.platform.title',
    defaultMessage: 'Platform',
  },
  platformAdmin: {
    id: 'auth-provider-messages.roles.platform.admin',
    defaultMessage: 'Platform admin',
  },
  platformViewer: {
    id: 'auth-provider-messages.roles.platform.viewer',
    defaultMessage: 'Platform viewer',
  },
  deploymentsTitle: {
    id: 'auth-provider-messages.roles.deployments.title',
    defaultMessage: 'Deployments',
  },
  deploymentsManager: {
    id: 'auth-provider-messages.roles.deployments.manager',
    defaultMessage: 'Deployments manager',
  },
  deploymentsViewer: {
    id: 'auth-provider-messages.roles.deployments.viewer',
    defaultMessage: 'Deployments viewer',
  },
})

export const sections = defineMessages({
  generalActiveDirectorySettings: {
    id: 'auth-provider-messages.section-titles.general-active-directory-settings',
    defaultMessage: 'General Active Directory settings',
  },
  generalActiveDirectorySettingsDescription: {
    id: 'auth-provider-messages.section-descriptions.general-active-directory-settings',
    defaultMessage:
      'Authentication profile name, Active Directory servers and trusted CA certificates. {learnMore}',
  },
  generalLdapSettings: {
    id: 'auth-provider-messages.section-titles.general-ldap-settings',
    defaultMessage: 'General LDAP settings',
  },
  generalLdapSettingsDescription: {
    id: 'auth-provider-messages.section-descriptions.general-ldap-settings',
    defaultMessage:
      'Authentication profile name, LDAP servers and trusted CA certificates. {learnMore}',
  },
  trustedCaCerts: {
    id: 'auth-provider-messages.section-descriptions.trusted-ca-certs',
    defaultMessage: 'Trusted CA certificates',
  },
  trustedCaCertsDescription: {
    id: 'auth-provider-messages.section-descriptions.trusted-ca-certs-description',
    defaultMessage: 'Add one or more trusted certificate authorities. {learnMore}',
  },
  bindCredentials: {
    id: 'auth-provider-messages.section-titles.bind-credentials',
    defaultMessage: 'Bind credentials',
  },
  bindCredentialsDescription: {
    id: 'auth-provider-messages.section-descriptions.bind-credentials',
    defaultMessage:
      'Required for configuring user search mode. Disabled when configuring template search mode. {learnMore}',
  },
  bindCredentialsWithoutTemplateDescription: {
    id: 'auth-provider-messages.section-descriptions.bind-credentials-without-template',
    defaultMessage: 'Required for configuring user search mode. {learnMore}',
  },
  bindCredentialsTemplateNote: {
    id: 'auth-provider-messages.section-descriptions.bind-credentials-template-note',
    defaultMessage:
      'Bind setting is not used when in template mode. All LDAP operations run as the authenticating user.',
  },
  searchModeSettings: {
    id: 'auth-provider-messages.section-titles.search-more-settings',
    defaultMessage: 'Search mode settings',
  },
  searchModeSettingsDescription: {
    id: 'auth-provider-messages.section-descriptions.search-more-settings',
    defaultMessage:
      'Settings that control how user entries are looked up in LDAP, or how user DNs are constructed with the help of templates. {learnMore}',
  },
  roleMappings: {
    id: 'auth-provider-messages.section-titles.role-mappings',
    defaultMessage: 'Role mappings',
  },
  roleMappingsDescription: {
    id: 'auth-provider-messages.section-descriptions.role-mappings',
    defaultMessage:
      'Role mappings define which roles should be assigned to each user based on their username, groups, or other metadata. {learnMore}',
  },
  groupSearchSettings: {
    id: 'auth-provider-messages.section-titles.group-search-settings',
    defaultMessage: 'Group search settings',
  },
  groupSearchSettingsDescription: {
    id: 'auth-provider-messages.section-descriptions.group-search-settings',
    defaultMessage:
      "Settings that control how a user's LDAP group memberships are resolved. {learnMore}",
  },
  advancedConfiguration: {
    id: 'auth-provider-messages.section-titles.advanced-configuration',
    defaultMessage: 'Advanced configuration',
  },
  advancedConfigurationHelpText: {
    id: 'auth-provider-messages.section-descriptions.advanced-configuration',
    defaultMessage:
      "Use this area to append any custom configuration in YAML such as time out, load balance type, cache TTL etc. All keys are automatically scoped under 'xpack.security.realms.$type.$id:'. {learnMore}",
  },
  testActiveDirectoryAuth: {
    id: 'auth-provider-messages.section-titles.test-active-directory-auth',
    defaultMessage: 'Test Active Directory authentication',
  },
  testActiveDirectoryAuthHelpText: {
    id: 'auth-provider-messages.section-descriptions.test-active-directory-auth',
    defaultMessage:
      'Simulates an authentication attempt based on the username and password provided.',
  },
  testLdapAuth: {
    id: 'auth-provider-messages.section-titles.test-ldap-auth',
    defaultMessage: 'Test LDAP authentication',
  },
  testLdapAuthHelpText: {
    id: 'auth-provider-messages.section-descriptions.test-ldap-auth',
    defaultMessage:
      'Simulates an authentication attempt based on the username and password provided.',
  },
  generalSamlSettings: {
    id: 'auth-provider-messages.section-titles.general-saml-settings',
    defaultMessage: 'General SAML settings',
  },
  generalSamlSettingsDescription: {
    id: 'auth-provider-messages.section-descriptions.general-saml-settings',
    defaultMessage: 'Authentication profile name, URLs and entity IDs. {learnMore}',
  },
  identityProviderMetadataFile: {
    id: 'auth-provider-messages.section-titles.identity-provider-metadata-file',
    defaultMessage: 'Identity provider (IdP) metadata file',
  },
  identityProviderMetadataFileDescription: {
    id: 'auth-provider-messages.section-description.identity-provider-metadata-file',
    defaultMessage: 'Specify the URL for the metadata of the identity provider',
  },
  attributeMappings: {
    id: 'auth-provider-messages.section-titles.attribute-mapping',
    defaultMessage: 'Attribute mapping',
  },
  attributeMappingsDescription: {
    id: 'auth-provider-messages.section-description.attribute-mapping',
    defaultMessage:
      'Map SAML attributes to user properties, which can then be used to map roles. {learnMore}',
  },
  signingConfiguration: {
    id: 'auth-provider-messages.section-titles.signing-configuration',
    defaultMessage: 'Signing configuration',
  },
  signingConfigurationDescription: {
    id: 'auth-provider-messages.section-description.signing-configuration',
    defaultMessage:
      'Add a signing key and a signing certificate to sign outgoing SAML messages. {learnMore}',
  },
  encryptionConfiguration: {
    id: 'auth-provider-messages.section-titles.encryption-configuration',
    defaultMessage: 'Encryption configuration',
  },
  encryptionConfigurationDescription: {
    id: 'auth-provider-messages.section-description.encryption-configuration',
    defaultMessage:
      'Add a encryption key and certificate to configure SAML encryption. {learnMore}',
  },
  sslConfiguration: {
    id: 'auth-provider-messages.section-titles.ssl-configuration',
    defaultMessage: 'SSL configuration',
  },
  sslConfigurationDescription: {
    id: 'auth-provider-messages.section-description.ssl-configuration',
    defaultMessage:
      'Add a trusted SSL certificate authority for validating the certificate of the server that hosts the Identity Provider metadata. {learnMore}',
  },
  userSearchSettings: {
    id: 'auth-provider-messages.section-titles.user-search-settings',
    defaultMessage: 'User search settings',
  },
  userSearchSettingsDescription: {
    id: 'auth-provider-messages.section-description.user-search-settings',
    defaultMessage:
      'Settings that control how user entries are looked up in Active Directory. {learnMore}',
  },
})

export const labels = defineMessages({
  realmId: {
    id: 'auth-provider-messages.labels.realm-id',
    defaultMessage: 'Realm ID',
  },
  realmIdHelpText: {
    id: 'auth-provider-messages.help-text.profile-id',
    defaultMessage: 'Profile ID has to be unique across providers',
  },
  profileName: {
    id: 'auth-provider-messages.labels.profile-name',
    defaultMessage: 'Profile name',
  },
  profileNameHelpText: {
    id: 'auth-provider-messages.help-text.profile-name',
    defaultMessage: 'Profile name has to be unique across providers',
  },
  activeDirectoryServers: {
    id: 'auth-provider-messages.labels.active-directory-servers',
    defaultMessage: 'Server URLs',
  },
  addActiveDirectoryServer: {
    id: 'auth-provider-messages.labels.add-active-directory-server',
    defaultMessage: 'Add server',
  },
  ldapServers: {
    id: 'auth-provider-messages.labels.ldap-servers',
    defaultMessage: 'LDAP servers',
  },
  addLdapServer: {
    id: 'auth-provider-messages.labels.add-ldap-server',
    defaultMessage: 'Add server',
  },
  loadBalancing: {
    id: 'auth-provider-messages.labels.load-balancing',
    defaultMessage: 'Load balancing',
  },
  url: {
    id: 'auth-provider-messages.labels.url',
    defaultMessage: 'URL',
  },
  urlHelpText: {
    id: 'auth-provider-messages.help-text.url',
    defaultMessage: 'Provide path to ZIP files',
  },
  fileUpload: {
    id: 'auth-provider-messages.labels.file-upload',
    defaultMessage: 'File upload',
  },
  fileUploadHelpText: {
    id: 'auth-provider-messages.help-text.file-upload',
    defaultMessage: 'Select or drag-and-drop multiple files',
  },
  bindAnonymously: {
    id: 'auth-provider-messages.labels.bind-anonymously',
    defaultMessage: 'Bind anonymously',
  },
  bindDn: {
    id: 'auth-provider-messages.labels.bind-dn',
    defaultMessage: 'Bind DN',
  },
  bindDnHelpText: {
    id: 'auth-provider-messages.help-text.bind-dn',
    defaultMessage: 'An LDAP distinguished name, e.g. {example}',
  },
  bindPassword: {
    id: 'auth-provider-messages.labels.bind-password',
    defaultMessage: 'Bind password',
  },
  user: {
    id: 'auth-provider-messages.labels.user',
    defaultMessage: 'User',
  },
  template: {
    id: 'auth-provider-messages.labels.template',
    defaultMessage: 'Template',
  },
  templateHelpText: {
    id: 'auth-provider-messages.help-text.template',
    defaultMessage: 'An LDAP distinguished name e.g. {example}',
  },
  baseDnForUsers: {
    id: 'auth-provider-messages.labels.base-dn-for-users',
    defaultMessage: 'Base DN for users',
  },
  baseDnForUsersHelpText: {
    id: 'auth-provider-messages.help-text.base-dn-for-users',
    defaultMessage: 'An LDAP distinguished name, e.g. {exampleBaseDn}',
  },
  baseDnForGroups: {
    id: 'auth-provider-messages.labels.base-dn-for-groups',
    defaultMessage: 'Base DN for groups',
  },
  baseDnForGroupsHelpText: {
    id: 'auth-provider-messages.help-text.base-dn-for-groups',
    defaultMessage: 'An LDAP distinguished name, e.g. {exampleBaseDn}',
  },
  scope: {
    id: 'auth-provider-messages.labels.scope',
    defaultMessage: 'Scope',
  },
  filter: {
    id: 'auth-provider-messages.labels.filter',
    defaultMessage: 'Filter (optional)',
  },
  userFilterHelpText: {
    id: 'auth-provider-messages.help-text.user-filter',
    defaultMessage:
      'Defaults to {defaultValue}, where {placeholder} is substituted with the username provided for the search',
  },
  groupFilterHelpText: {
    id: 'auth-provider-messages.help-text.group-filter',
    defaultMessage: 'The filter to use to lookup a group, e.g. {exampleFilter}',
  },
  userGroupAttribute: {
    id: 'auth-provider-messages.labels.user-group-attribute',
    defaultMessage: 'User group attribute (optional)',
  },
  searchUserGroupAttributeHelpText: {
    id: 'auth-provider-messages.help-text.search-user-group-attribute',
    defaultMessage: 'Defaults to {defaultValue}',
  },
  userDnTemplates: {
    id: 'auth-provider-messages.labels.user-dn-templates',
    defaultMessage: 'User DN templates',
  },
  addTemplate: {
    id: 'auth-provider-messages.labels.add-template',
    defaultMessage: 'Add template',
  },
  groupUserAttribute: {
    id: 'auth-provider-messages.labels.group-user-attribute',
    defaultMessage: 'User attribute (optional)',
  },
  groupHelpAttributeHelpText: {
    id: 'auth-provider-messages.help-text.user-group-attribute',
    defaultMessage:
      'The attribute fetched and provided as a parameter to the filter above. If not set, the user DN is passed into the filter.',
  },
  testProfile: {
    id: 'auth-provider-messages.labels.test-profile',
    defaultMessage: 'Test profile',
  },
  username: {
    id: 'auth-provider-messages.labels.username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'auth-provider-messages.labels.password',
    defaultMessage: 'Password',
  },
  createProfile: {
    id: 'auth-provider-messages.labels.create-profile',
    defaultMessage: 'Create profile',
  },
  saveProfile: {
    id: 'auth-provider-messages.labels.save-profile',
    defaultMessage: 'Save profile',
  },
  cancel: {
    id: 'auth-provider-messages.labels.cancel',
    defaultMessage: 'Cancel',
  },
  bundleUrl: {
    id: 'auth-provider-messages.labels.bundle-url',
    defaultMessage: 'Bundle URL',
  },
  bundleUrlHelpText: {
    id: 'auth-provider-messages.help-text.bundle-url',
    defaultMessage:
      "URL of a ZIP file containing a keystore file 'keystore.ks' inside a folder named 'truststore' that contains the certificates to trust",
  },
  certificateUrlTruststoreType: {
    id: 'auth-provider-messages.labels.certificate-url-truststore-type',
    defaultMessage: 'Truststore type',
  },
  certificateUrlTruststoreTypeHelpText: {
    id: 'auth-provider-messages.help-text.certificate-url-truststore-type',
    defaultMessage: 'The format of the truststore file',
  },
  certificateUrlTruststorePassword: {
    id: 'auth-provider-messages.labels.certificate-url-truststore-password',
    defaultMessage: 'Truststore password',
  },
  certificateUrlTruststorePasswordHelpText: {
    id: 'auth-provider-messages.help-text.certificate-url-truststore-password',
    defaultMessage: 'Password for the bundle URL truststore',
  },
  assertionConsumerServiceUrl: {
    id: 'auth-provider-messages.labels.assertion-consumer-service-url',
    defaultMessage: 'Assertion consumer service URL',
  },
  assertionConsumerServiceUrlHelpText: {
    id: 'auth-provider-messages.help-text.assertion-consumer-service-url',
    defaultMessage: 'URL within ECE that accepts authentication messages from IdP',
  },
  logoutUrl: {
    id: 'auth-provider-messages.labels.logout-url',
    defaultMessage: 'Logout URL',
  },
  logoutUrlHelpText: {
    id: 'auth-provider-messages.help-text.logout-url',
    defaultMessage: 'URL within ECE that accepts logout messages from IdP',
  },
  identityProviderEntityId: {
    id: 'auth-provider-messages.labels.identity-provider-entity-id',
    defaultMessage: 'Identity provider entity ID',
  },
  identityProviderEntityIdHelpText: {
    id: 'auth-provider-messages.help-text.identity-provider-entity-id',
    defaultMessage: 'The entity ID of your SAML identity provider, usually a URI',
  },
  serviceProviderEntityId: {
    id: 'auth-provider-messages.labels.service-provider-entity-id',
    defaultMessage: 'Service provider entity ID',
  },
  serviceProviderEntityIdHelpText: {
    id: 'auth-provider-messages.help-text.service-provider-entity-id',
    defaultMessage: 'The entity ID for the ECE SAML service provider, usually a URI',
  },
  identityProviderMetadataFileHelpText: {
    id: 'auth-provider-messages.help-text.identity-provider-metadata-file',
    defaultMessage: 'The URL to the metadata file needs to be secure (https)',
  },
  hideOptionalAttributes: {
    id: 'auth-provider-messages.labels.hideOptionalAttributes',
    defaultMessage: 'Hide optional attributes',
  },
  signingCertificateUrl: {
    id: 'auth-provider-messages.labels.signing-certificate-url',
    defaultMessage: 'Signing certificate URL',
  },
  signingCertificateUrlHelpText: {
    id: 'auth-provider-messages.help-text.signing-certificate-url',
    defaultMessage: 'Provide path to ZIP file',
  },
  signingCertificateUrlPassword: {
    id: 'auth-provider-messages.labels.signing-certificate-url-password',
    defaultMessage: 'Signing certificate bundle password',
  },
  signingCertificateUrlPasswordHelpText: {
    id: 'auth-provider-messages.help-text.signing-certificate-url-password',
    defaultMessage: 'Password for the signing certificate bundle, if encrypted',
  },
  signedSamlMessageTypes: {
    id: 'auth-provider-messages.labels.signed-saml-message-types',
    defaultMessage: 'Signed SAML message types',
  },
  signedSamlMessageTypesHelpText: {
    id: 'auth-provider-messages.help-text.signed-saml-message-types',
    defaultMessage: 'Select one or more values. For All(*), leave blank.',
  },
  encryptionCertificateUrl: {
    id: 'auth-provider-messages.labels.encryption-certificate-url',
    defaultMessage: 'Encryption certificate URL',
  },
  encryptionCertificateUrlHelpText: {
    id: 'auth-provider-messages.help-text.encryption-certificate-url',
    defaultMessage: 'Provide path to ZIP file',
  },
  encryptionCertificateUrlPassword: {
    id: 'auth-provider-messages.labels.encryption-certificate-url-password',
    defaultMessage: 'Encryption certificate bundle password',
  },
  encryptionCertificateUrlHelpTextPassword: {
    id: 'auth-provider-messages.help-text.encryption-certificate-url-password',
    defaultMessage: 'Password for the encryption certificate bundle, if encrypted',
  },
  sslCertificateUrl: {
    id: 'auth-provider-messages.labels.ssl-certificate-url',
    defaultMessage: 'SSL certificate URL',
  },
  sslCertificateUrlHelpText: {
    id: 'auth-provider-messages.help-text.ssl-certificate-url',
    defaultMessage: 'Provide path to ZIP file',
  },
  sslCertificateUrlTruststoreType: {
    id: 'auth-provider-messages.labels.ssl-certificate-url-type',
    defaultMessage: 'SSL certificate bundle type',
  },
  sslCertificateUrlTruststoreTypeHelpText: {
    id: 'auth-provider-messages.help-text.ssl-certificate-url-type',
    defaultMessage: 'Type of the SSL certificate bundle',
  },
  sslCertificateUrlTruststorePassword: {
    id: 'auth-provider-messages.labels.ssl-certificate-url-password',
    defaultMessage: 'SSL certificate bundle password',
  },
  sslCertificateUrlTruststorePasswordHelpText: {
    id: 'auth-provider-messages.help-text.ssl-certificate-url-password',
    defaultMessage: 'Password for the SSL certificate bundle, if encrypted',
  },
  useSingleLogout: {
    id: 'auth-provider-messages.labels.use-single-logout',
    defaultMessage: 'Use single logout (SLO)',
  },
  enableForceAuthentication: {
    id: 'auth-provider-messages.labels.enable-force-authentication',
    defaultMessage: 'Enable force authentication',
  },
  domainName: {
    id: 'auth-provider-messages.labels.domain-name',
    defaultMessage: 'Domain name',
  },
  activeDirectoryUserFilterHelpText: {
    id: 'auth-provider-messages.help-text.active-directory-user-filter',
    defaultMessage:
      'Specifies an LDAP filter to use to lookup a user given a username. The default filter looks up user objects where the username matches {sAMAccountName} or {userPrincipalName}.',
  },
})

export const validation = defineMessages({
  required: {
    id: 'auth-provider-messages.validation.required',
    defaultMessage: 'This field is required',
  },
  invalidId: {
    id: 'auth-provider-messages.validation.invalid-id',

    // This is an awful message. Save me, Kelly!
    defaultMessage:
      'IDs must start with a letter, followed by alphanumerics, underscores or hyphens',
  },
  bindDnRequired: {
    id: 'auth-provider-messages.validation.bind-dn-required',
    defaultMessage: 'This field is required for non-anonymous binds',
  },
  validUrl: {
    id: 'auth-provider-messages.validation.url',
    defaultMessage: 'This value must be a valid URL',
  },
  validLdapUrl: {
    id: 'auth-provider-messages.validation.ldap-url',
    defaultMessage: 'Expected an {ldap} or {ldaps} URL',
  },
  urlProtocolsMustBeUniform: {
    id: 'auth-provider-messages.validation.ldap-url-protocols-must-be-the-same',
    defaultMessage: 'All URLs must have the same protocol',
  },
  singleUrlWithDnsLoadBalancing: {
    id: 'auth-provider-messages.validation.single-url-with-dns-load-balancing',
    defaultMessage: 'Only a single URL can be provided with DNS or DNS round robin load balancing',
  },
  domainNameWithDnsLoadBalancing: {
    id: 'auth-provider-messages.validation.domain-name-with-dns-load-balancing',
    defaultMessage:
      'Must supply a URL with a domain name to use DNS or DNS round robin load balancing',
  },
  mustSpecifyDefaultRolesOrRules: {
    id: 'auth-provider-messages.validation.must-supply-default-roles-or-rules',
    defaultMessage: 'At least one default role or role-mapping rule must be configured',
  },
  validYaml: {
    id: 'auth-provider-messages.validation.yaml',
    defaultMessage: 'This value must be well-formed YAML',
  },
})

export const roleMappingMessages = defineMessages({
  fieldLabel: {
    id: 'auth-provider-messages.role-mapping.field-label',
    defaultMessage: 'Field',
  },
  actionsLabel: {
    id: 'auth-provider-messages.role-mapping.actions-label',
    defaultMessage: 'Actions',
  },
  dnLabel: {
    id: 'auth-provider-messages.role-mapping.dn-label',
    defaultMessage: 'DN',
  },
  defaultRolesLabel: {
    id: 'auth-provider-messages.role-mapping.default-roles-label',
    defaultMessage: 'Default roles',
  },
  noRulesConfigured: {
    id: 'auth-provider-messages.role-mapping.no-rules-configured',
    defaultMessage: 'No role-mapping rules configured',
  },
  roleLabel: {
    id: 'auth-provider-messages.role-mapping.role-label',
    defaultMessage: 'Roles',
  },
  addRoleMappingRule: {
    id: 'auth-provider-messages.role-mapping.add-role-mapping-rule',
    defaultMessage: 'Add role mapping rule',
  },
  userDnField: {
    id: 'auth-provider-messages.role-mapping.user-dn-field',
    defaultMessage: 'User DN',
  },
  groupDnField: {
    id: 'auth-provider-messages.role-mapping.group-dn-field',
    defaultMessage: 'Group DN',
  },
  dnString: {
    id: 'auth-provider-messages.role-mapping.dn-string',
    defaultMessage: 'DN string',
  },
  editRule: {
    id: 'auth-provider-messages.role-mapping.edit-rule',
    defaultMessage: 'Edit rule',
  },
  deleteRule: {
    id: 'auth-provider-messages.role-mapping.delete-rule',
    defaultMessage: 'Delete rule',
  },
  cancelEditRule: {
    id: 'auth-provider-messages.role-mapping.cancel',
    defaultMessage: 'Cancel',
  },
  addRoleMapping: {
    id: 'auth-provider-messages.role-mapping.add-role-mapping',
    defaultMessage: 'Add role mapping',
  },
  editRoleMapping: {
    id: 'auth-provider-messages.role-mapping.edit-role-mapping',
    defaultMessage: 'Edit role mapping',
  },
  saveRoleMapping: {
    id: 'auth-provider-messages.role-mapping.save-role-mapping',
    defaultMessage: 'Save role mapping',
  },
  editActiveDirectoryRoleMappingDescription: {
    id: 'auth-provider-messages.role-mapping.edit-active-directory-role-mapping-description',
    defaultMessage:
      'Assign roles based on the user DN or group membership of your choice. For more complex settings, please use the role mapping API.',
  },
  editLdapRoleMappingDescription: {
    id: 'auth-provider-messages.role-mapping.edit-ldap-role-mapping-description',
    defaultMessage:
      'Assign roles based on the user DN or group membership of your choice. For more complex settings, please use the role mapping API.',
  },
  attributeLabel: {
    id: 'auth-provider-messages.role-mapping.attribute-label',
    defaultMessage: 'User Property',
  },
  valueLabel: {
    id: 'auth-provider-messages.role-mapping.value-label',
    defaultMessage: 'Value',
  },
  editSamlRoleMappingDescription: {
    id: 'auth-provider-messages.role-mapping.edit-saml-role-mapping-description',
    defaultMessage:
      'Assign roles based on the values of the user properties of your choice. For more complex settings, please use the role mapping API.',
  },
  noRoleMappingRules: {
    id: 'auth-provider-messages.no-role-mapping-rules',
    defaultMessage: 'No role mapping rules',
  },
  createFirstRoleMappingRule: {
    id: 'auth-provider-messages.create-first-role-mapping-rule',
    defaultMessage: "You don't have any role mapping rules yet. Let's create some!",
  },
  defaultRolesHelp: {
    id: 'auth-provider-messages.default-roles-help',
    defaultMessage: 'Default roles are automatically assigned to users from this provider',
  },
})

export const helpText = defineMessages({
  activeDirectoryUrls: {
    id: 'auth-provider-messages.active-directory-url-help',
    defaultMessage:
      'Specify one or more Active Directory URLs of the form of {example}. Multiple URLs can set set, provided you are not using a DNS failover strategy. {ldap} and {ldaps} URL protocols cannot be mixed.',
  },
  ldapUrls: {
    id: 'auth-provider-messages.ldap-url-help',
    defaultMessage:
      'Specify one or more LDAP URLs of the form of {example}. Multiple URLs can set set, provided you are not using a DNS failover strategy. {ldap} and {ldaps} URL protocols cannot be mixed.',
  },
})
