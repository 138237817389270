/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSpacer, EuiText, EuiTextColor, EuiTitle } from '@elastic/eui'

import type { FunctionComponent, ReactElement, ReactNode } from 'react'

import './emptyPrompt.scss'

interface Props {
  title: ReactElement
  addButton: ReactNode
  prompt?: ReactNode
}

export const CuiEmptyPrompt: FunctionComponent<Props> = ({ title, addButton, prompt }) => (
  <div className='cuiEmptyPrompt'>
    <EuiTextColor color='subdued'>
      <EuiTitle>{title}</EuiTitle>
      <EuiSpacer size='s' />
      {prompt && <EuiText grow={true}>{prompt}</EuiText>}
    </EuiTextColor>

    <EuiSpacer />

    {addButton}

    <EuiSpacer />
  </div>
)
