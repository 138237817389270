/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  retryFailedShardAllocations,
  resetRetryFailedShardAllocationsRequest,
} from '../../../actions/stackDeployments/retryFailedShardAllocations'
import { retryFailedShardAllocationsRequest } from '../../../reducers'

import RetryFailedShards from './RetryFailedShards'

import type { ReduxState, ThunkDispatch } from '../../../types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => ({
  retryFailedShardAllocationsRequest: retryFailedShardAllocationsRequest(state, deployment.id),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => ({
  retryFailedShardAllocations: () => dispatch(retryFailedShardAllocations(deployment)),
  resetRetryFailedShardAllocationsRequest: () =>
    dispatch(resetRetryFailedShardAllocationsRequest(deployment.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RetryFailedShards)
