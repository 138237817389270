/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchAccountCostsOverviewIfNeeded } from '@/actions/billing'
import { fetchAccountCostsOverviewRequest, getAccountCostsOverview } from '@/reducers'

import UsageCard from './UsageCard'
import { getPreviousMonthDates } from './utils'

import type { ConsumerProps, StateProps, DispatchProps } from './types'
import type { ThunkDispatch } from '@/types'

const mapStateToProps = (state, { organizationId }: ConsumerProps): StateProps => {
  const { from, to } = getPreviousMonthDates()

  return {
    costOverviewCurrentMonth: getAccountCostsOverview({ state, organizationId }),
    costOverviewPreviousMonth: getAccountCostsOverview({ state, organizationId, from, to }),
    costOverviewCurrentMonthRequest: fetchAccountCostsOverviewRequest(state),
    costOverviewPreviousMonthRequest: fetchAccountCostsOverviewRequest(state, `${from}_${to}`),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  fetchAccountCostsOverviewIfNeeded: (params) =>
    dispatch(fetchAccountCostsOverviewIfNeeded({ organizationId, ...params })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UsageCard)
