/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import {
  EuiText,
  EuiSpacer,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiButtonIcon,
  copyToClipboard,
  EuiToolTip,
} from '@elastic/eui'

import { CuiRouterLinkButton } from '../../../cui'
import DeploymentRegion from '../../StackDeploymentSearch/Deployment/DeploymentRegion'
import Breadcrumbs, { BreadcrumbsContext } from '../../Breadcrumbs'
import PrivacySensitiveContainer from '../../PrivacySensitiveContainer'
import { getDisplayName, getVersion } from '../../../lib/stackDeployments/selectors'
import { userOverviewUrl, organizationOverviewUrl } from '../../../lib/urlBuilder'
import DeploymentLockGate from '../../DeploymentLockGate'
import StackDeploymentAdminActions from '../../StackDeployments/StackDeploymentAdminActions'
import { StackDeploymentHealthBadge } from '../../StackDeploymentHealthProblems/StackDeploymentHealthBadge'

import { getHeaderDefinition } from './headerDefinitions'

import type { Problem } from '../../../lib/healthProblems'
import type { ElasticsearchCluster, StackDeployment } from '../../../types'
import type { RouteComponentProps } from 'react-router-dom'
import type { WrappedComponentProps } from 'react-intl'

import './deploymentHeader.scss'

type QueryParams = {
  regionId?: string
  deploymentId: string
  snapshotName?: string
  trustRelationshipId?: string
  issue: string
}

export type StateProps = {
  lookupSaasUsers: boolean
  hideClusterInsteadOfDelete: boolean
  hideClusterInsteadOfStop: boolean
  match: RouteComponentProps<QueryParams>['match']
  problems: Problem[]
}

export interface DispatchProps {}

export type ConsumerProps = RouteComponentProps<QueryParams, any, any> & {
  deployment: ElasticsearchCluster
  stackDeployment: StackDeployment | null
  shouldShowRegion: boolean
  isOverviewPage?: boolean
  isHealthPage?: boolean
}

export type Props = StateProps & DispatchProps & ConsumerProps & WrappedComponentProps

type State = {
  isHelpOpen: boolean
  isTextCopied: boolean
}

const messages = defineMessages({
  copyId: {
    id: `deployment-summary.deployment-id.copy`,
    defaultMessage: `Copy Deployment ID`,
  },
  idCopied: {
    id: `deployment-summary.deployment-id.copied`,
    defaultMessage: `ID copied to clipboard`,
  },
  aria: {
    id: `deployment-summary.deployment-id.aria-label`,
    defaultMessage: `Copy ID to clipboard`,
  },
})

// An <EuiText size='s'> sets a small line height, but in this case
// it impacts the horizontal alignment of the header.
const regionNameStyle = {
  lineHeight: `1.5rem`,
}

class DeploymentHeader extends React.Component<Props, State> {
  state: State = this.getInitialState()

  getInitialState(): State {
    const { location } = this.props
    const { state } = location

    const isHelpOpen = state ? state.isNew : false

    return {
      isHelpOpen,
      isTextCopied: false,
    }
  }

  render() {
    const {
      match,
      deployment,
      stackDeployment,
      shouldShowRegion,
      hideClusterInsteadOfDelete,
      hideClusterInsteadOfStop,
      isOverviewPage,
      isHealthPage,
      problems,
    } = this.props

    const deploymentDisplayName = stackDeployment
      ? getDisplayName({ deployment: stackDeployment })
      : deployment.displayName

    const version = stackDeployment ? getVersion({ deployment: stackDeployment }) : undefined
    const {
      breadcrumbs,
      iconType,
      title,
      shouldHideRegion,
      hideHorizontalRule,
      component: Component,
    } = getHeaderDefinition({
      match,
      deploymentDisplayName,
      version,
    })

    const stackDeploymentVersion = stackDeployment?.id

    return (
      <React.Fragment>
        <BreadcrumbsContext.Consumer>
          {({ breadcrumbsRef }) => (
            <Breadcrumbs breadcrumbsRef={breadcrumbsRef} breadcrumbs={breadcrumbs} />
          )}
        </BreadcrumbsContext.Consumer>

        {Component ? (
          <Component {...this.props} problems={problems} />
        ) : (
          <EuiPageHeader
            bottomBorder={!hideHorizontalRule}
            pageTitle={
              <span data-test-id='deployment-title'>
                <PrivacySensitiveContainer nativeElementType='span'>
                  {title}
                </PrivacySensitiveContainer>
              </span>
            }
            iconProps={{ size: 'l' }}
            iconType={iconType}
            rightSideGroupProps={{ gutterSize: 'm' }}
            rightSideItems={[
              this.renderUserLink(),
              isOverviewPage && (
                <DeploymentLockGate>
                  <StackDeploymentAdminActions
                    deployment={stackDeployment!}
                    hideClusterInsteadOfDelete={hideClusterInsteadOfDelete}
                    hideClusterInsteadOfStop={hideClusterInsteadOfStop}
                  />
                </DeploymentLockGate>
              ),
              /*
                renderUserLink can return null if lookupSaasUsers is false, when that happens the
                EuiPageHeader will complain if the rightSideItems prop has any null elements, so
                we need to remove it when that happens.
              */
            ].filter((item) => item !== null)}
          >
            {isHealthPage && (
              <StackDeploymentHealthBadge
                deploymentId={stackDeploymentVersion!}
                problems={problems}
                showViewIssuesLink={false}
              />
            )}
            {isOverviewPage && (
              <EuiFlexGroup gutterSize='s' direction='row' alignItems='center' responsive={false}>
                <EuiFlexItem grow={false}>
                  <StackDeploymentHealthBadge
                    problems={problems}
                    deploymentId={stackDeploymentVersion!}
                    showViewIssuesLink={true}
                  />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiText size='s' color='subdued'>
                    {/* Renders the unicode character for interpunct. */}
                    &#183;
                  </EuiText>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  {this.renderRegionIndicator(shouldShowRegion && !shouldHideRegion)}
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiText size='s' color='subdued'>
                    {/* Renders the unicode character for interpunct. */}
                    &#183;
                  </EuiText>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>{this.renderDeploymentId()}</EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiPageHeader>
        )}

        <EuiSpacer size='l' />
      </React.Fragment>
    )
  }

  renderUserLink() {
    const { lookupSaasUsers, deployment } = this.props

    if (!lookupSaasUsers) {
      return null
    }

    const {
      profile: { userId },
      settings: { metadata },
    } = deployment

    const organizationId = metadata?.organization_id

    if (organizationId !== undefined) {
      return (
        <CuiRouterLinkButton
          fill={true}
          to={organizationOverviewUrl(organizationId)}
          iconType='eye'
          data-test-id='organization-link'
        >
          <FormattedMessage
            id='deployment-summary.organization-link'
            defaultMessage='View organization'
          />
        </CuiRouterLinkButton>
      )
    }

    return (
      <EuiFlexItem grow={false}>
        <CuiRouterLinkButton fill={true} to={userOverviewUrl(userId)} iconType='eye'>
          <FormattedMessage id='deployment-summary.user-link' defaultMessage='View user' />
        </CuiRouterLinkButton>
      </EuiFlexItem>
    )
  }

  renderRegionIndicator(shouldShowRegion: boolean) {
    if (!shouldShowRegion) {
      return null
    }

    const { deployment } = this.props

    const { platform, regionId } = deployment

    return (
      <EuiFlexGroup
        gutterSize='s'
        direction='row'
        alignItems='center'
        responsive={false}
        data-test-id='deployment-title-platform'
      >
        <EuiFlexItem grow={false}>
          <EuiIcon type='mapMarker' title={platform.id} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText size='s' color='subdued' style={regionNameStyle}>
            {platform.id.toUpperCase()}
            {' - '}
            <DeploymentRegion regionId={regionId} />
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderDeploymentId() {
    const {
      deployment,
      intl: { formatMessage },
    } = this.props

    return (
      <EuiFlexGroup alignItems='center' gutterSize='s'>
        <EuiFlexItem grow={false}>
          <EuiText size='s' color='subdued' style={regionNameStyle}>
            <FormattedMessage
              id='deployment-summary.deployment-id'
              defaultMessage='Deployment ID'
            />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems='center' gutterSize='xs'>
            <EuiFlexItem grow={false}>
              <EuiText size='s' style={regionNameStyle}>
                {deployment.displayId}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiToolTip
                content={
                  this.state.isTextCopied
                    ? formatMessage(messages.idCopied)
                    : formatMessage(messages.copyId)
                }
              >
                <EuiButtonIcon
                  iconType='copy'
                  onClick={this.onIdCopied}
                  aria-label={formatMessage(messages.aria)}
                />
              </EuiToolTip>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  onIdCopied = () => {
    const { deployment } = this.props
    copyToClipboard(deployment.displayId)
    this.setState({ isTextCopied: true })
  }

  onCloseHelp = () => {
    this.setState({
      isHelpOpen: false,
    })
  }
}

export default injectIntl(DeploymentHeader)
