/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiPermissibleControl, CuiTable } from '../../../cui'
import EditTrustRelationshipFlyout from '../TrustRelationshipFlyout/edit'
import DangerButton from '../../DangerButton'
import Permission from '../../../lib/api/v1/permissions'

import type { CuiTableColumn } from '../../../cui'
import type { FunctionComponent } from 'react'
import type { TrustRelationshipGetResponse } from '../../../lib/api/v1/types'
import type { AsyncRequestState } from '../../../types'

type Props = {
  trustRelationships: TrustRelationshipGetResponse[]
  deleteTrustRelationship: (trustRelationshipId: string) => void
  deleteTrustRelationshipRequest: (trustRelationshipId: string) => AsyncRequestState
  regionId: string
}

const TrustRelationshipTable: FunctionComponent<Props> = ({
  trustRelationships,
  deleteTrustRelationship,
  deleteTrustRelationshipRequest,
  regionId,
}) => {
  const columns: Array<CuiTableColumn<TrustRelationshipGetResponse>> = [
    {
      label: (
        <FormattedMessage
          id='trust-management.trusted-environments-table-name'
          defaultMessage='Environment name'
        />
      ),
      render: ({ name }) => name,
    },
    {
      label: (
        <FormattedMessage
          id='trust-management.trusted-environments-table-environment-id'
          defaultMessage='Environment ID'
        />
      ),
      render: ({ account_ids }) => (account_ids?.length ? account_ids[0] : null),
    },
    {
      label: (
        <FormattedMessage
          id='trust-management.trusted-environments-table-actions'
          defaultMessage='Actions'
        />
      ),
      render: (trustRelationship) => {
        const { name, id } = trustRelationship

        return (
          <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <CuiPermissibleControl permissions={Permission.updateTrustRelationship}>
                <EditTrustRelationshipFlyout
                  regionId={regionId}
                  trustRelationship={trustRelationship}
                />
              </CuiPermissibleControl>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CuiPermissibleControl permissions={Permission.deleteTrustRelationship}>
                <DangerButton
                  buttonType={EuiButtonIcon}
                  isEmpty={true}
                  iconType='trash'
                  spin={deleteTrustRelationshipRequest(id).inProgress}
                  modal={{
                    title: (
                      <FormattedMessage
                        id='trust-management.trusted-environments-confirm-deletion-title'
                        defaultMessage='Delete trust relationship?'
                        values={{ name }}
                      />
                    ),
                    body: (
                      <FormattedMessage
                        id='trust-management.trusted-environments-confirm-deletion-body'
                        defaultMessage='Permanently deletes trust with the environment {name}.'
                        values={{ name: <strong>{name}</strong> }}
                      />
                    ),
                    confirmButtonText: (
                      <FormattedMessage
                        id='trust-management.trusted-environments-confirm-deletion-button'
                        defaultMessage='Delete'
                      />
                    ),
                  }}
                  onConfirm={() => deleteTrustRelationship(id)}
                />
              </CuiPermissibleControl>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
      width: '75px',
      actions: true,
    },
  ]

  return (
    <CuiTable<TrustRelationshipGetResponse>
      rows={trustRelationships}
      getRowId={(trustRelationship) => trustRelationship.id}
      columns={columns}
    />
  )
}

export default TrustRelationshipTable
