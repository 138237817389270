/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiTextColor,
  EuiLink,
} from '@elastic/eui'

import type { FunctionComponent, ReactFragment } from 'react'

export type Props = {
  label: ReactFragment
  contacts: string[] | string
}

const ContactList: FunctionComponent<Props> = ({ label, contacts }) => {
  const parsedContacts = parseRawContacts(contacts)

  return (
    <div>
      <EuiDescriptionList compressed={true}>
        <EuiDescriptionListTitle>{label}</EuiDescriptionListTitle>

        <EuiDescriptionListDescription data-test-id='no-contacts-message'>
          {isEmpty(parsedContacts) ? (
            <EuiTextColor color='subdued'>
              <FormattedMessage
                id='organization.organization-overview.empty-contacts'
                defaultMessage='None'
              />
            </EuiTextColor>
          ) : (
            parsedContacts.map((contact) => (
              <div data-test-id='contact-email' key={contact}>
                <EuiLink href={`mailto:${contact}`}>{contact}</EuiLink>
              </div>
            ))
          )}
        </EuiDescriptionListDescription>
      </EuiDescriptionList>
    </div>
  )
}

export default ContactList

function parseRawContacts(rawContacts?: string[] | string) {
  if (Array.isArray(rawContacts)) {
    return rawContacts
  }

  if (typeof rawContacts === `string`) {
    return rawContacts.split(`,`).map((contact) => contact.trim())
  }

  return []
}
