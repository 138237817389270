/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { camelCase } from 'lodash'

import { EuiText, EuiSpacer } from '@elastic/eui'

import TopologyPanel from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/TopologyPanel'

import ScrollIntoView from '../../../../../ScrollIntoView'
import { getSliderDefinition } from '../../../../../../lib/sliders'
import {
  displayAutoscalingLimitReached,
  getFirstEsClusterFromGet,
  getNodeRoles,
  getSliderPlan,
  getSliderUserSettings,
  getUpsertVersion,
  hasNodeRole,
  isAutoscaleableTier,
  isDedicatedMaster,
  isDedicatedML,
  isFrozen,
} from '../../../../../../lib/stackDeployments/selectors'
import { getDefaultUserSettings } from '../../../../../../lib/stackDeployments/userSettings'
import { hashMatchesNodeConfiguration } from '../../../../../../lib/stackDeployments/topology'
import AutoscalingEditSettings from '../../../../../Autoscaling/AutoscalingEditSettings'

import Messages from './Messages'
import Summary from './Summary'
import NormalizeSizing from './NormalizeSizing'
import SizePicker from './SizePicker'
import ZoneCount from './ZoneCount'
import UserSettings from './UserSettings'
import TopologyPanelRow from './TopologyPanelRow'
import Heading from './Heading'

import type { AnyTopologyElement, SliderInstanceType } from '../../../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type {
  DeploymentCreateRequest,
  DeploymentGetResponse,
  DeploymentUpdateRequest,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '../../../../../../lib/api/v1/types'

export type AllProps = {
  id: string
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentUnderEdit?: DeploymentGetResponse
  sliderInstanceType: SliderInstanceType
  topologyElement: AnyTopologyElement
  templateTopologyElement?: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  onChange: undefined | ((path: string[], value: any) => void) // path is scoped to the topology element
  onPlanChange: undefined | ((path: string[], value: any) => void) // path is scoped to the parent plan
  onlyShowPricingFactors: boolean
  inTrial: boolean
  showTrialThreshold: boolean
  isAutoscalingEnabled?: boolean
  maxZoneCount: number
  dedicatedMasterThreshold?: number
  subscription?: string | null
  maxInstanceCountForEnvironment: number
  showUserSettings: boolean
  maxInstanceCount?: number
  isReadOnly?: boolean
}

type Props = AllProps & WrappedComponentProps

type State = {
  isTitlePopoverOpen: boolean
}

class TopologyElement extends Component<Props, State> {
  state = {
    isTitlePopoverOpen: false,
  }

  render(): JSX.Element {
    const { topologyElement, instanceConfiguration, onlyShowPricingFactors, showUserSettings } =
      this.props

    const headerContent = (
      <React.Fragment>
        <Heading {...this.props} />
        <Messages {...this.props} />
      </React.Fragment>
    )

    return (
      <ScrollIntoView
        whenLocationMatches={(hash) => hashMatchesNodeConfiguration(hash, topologyElement)}
      >
        <div data-test-subj='topologyElement' data-id={instanceConfiguration.name}>
          <TopologyPanel headerContent={headerContent} footerContent={this.renderSummary()}>
            {this.displayAutoscalingSettings() ? this.renderAutoscalingSize() : this.renderSlider()}

            <EuiSpacer size='m' />

            {this.renderAvailabilityZones()}

            {showUserSettings && !onlyShowPricingFactors && this.renderUserSettings()}
          </TopologyPanel>
        </div>
      </ScrollIntoView>
    )
  }

  renderAutoscalingSize(): JSX.Element {
    const { sliderInstanceType, instanceConfiguration, deploymentUnderEdit } = this.props

    // only applicable to ES in practice, but TS needs to be told
    const topologyElement = this.props.topologyElement as ElasticsearchClusterTopologyElement
    const esCluster = deploymentUnderEdit
      ? getFirstEsClusterFromGet({ deployment: deploymentUnderEdit })
      : undefined
    const currentTopologyElement = esCluster?.info.plan_info.current?.plan?.cluster_topology.find(
      ({ id }) => id === topologyElement.id,
    )

    const label = (
      <EuiText size='s'>
        <FormattedMessage
          id='deploymentInfrastructure-topologyElement-sizeLabel'
          defaultMessage='Size per zone'
        />
      </EuiText>
    )

    return (
      <NormalizeSizing
        {...this.props}
        capMaxNodeCount={true}
        autoscalingSizeFilter='constrainToLimits'
        currentSize={currentTopologyElement?.size?.value || 0}
      >
        {(normalizeSizingProps) => {
          const { size } = normalizeSizingProps
          const displayAutoscalingLimitReachedLabel = displayAutoscalingLimitReached({
            size,
            autoscalingMax: topologyElement.autoscaling_max!.value,
            autoscalingMin: topologyElement.autoscaling_min?.value,
          })

          return (
            <TopologyPanelRow
              alignItems='center'
              labelColor={displayAutoscalingLimitReachedLabel ? 'warning' : 'subdued'}
              label={label}
            >
              <AutoscalingEditSettings
                {...normalizeSizingProps}
                sliderInstanceType={sliderInstanceType}
                topologyElement={topologyElement}
                name={instanceConfiguration.name}
                autoscalingMax={topologyElement.autoscaling_max!.value}
                autoscalingMin={topologyElement.autoscaling_min?.value}
                autoscalingPolicyOverrideJson={topologyElement.autoscaling_policy_override_json}
                isMachineLearning={isDedicatedML({ topologyElement })}
                isHotTier={hasNodeRole({ topologyElement, role: 'data_hot' })}
                minimumSizeForElement={topologyElement.topology_element_control?.min.value}
                deploymentUnderEdit={deploymentUnderEdit}
                isFrozen={isFrozen({ topologyElement })}
              />
            </TopologyPanelRow>
          )
        }}
      </NormalizeSizing>
    )
  }

  renderSlider(): JSX.Element {
    const {
      instanceConfiguration,
      inTrial,
      showTrialThreshold,
      maxInstanceCountForEnvironment,
      topologyElement,
      maxInstanceCount,
    } = this.props

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-sizeLabel'
        defaultMessage='Size per zone'
      />
    )

    return (
      <TopologyPanelRow alignItems='baseline' label={label}>
        <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
          {(normalizeSizingProps) => (
            <SizePicker
              {...normalizeSizingProps}
              maxInstanceCount={
                maxInstanceCount
                  ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
                  : maxInstanceCountForEnvironment
              }
              topologyElement={topologyElement}
              sliderInstanceType={instanceConfiguration.instance_type}
              sliderNodeTypes={instanceConfiguration.node_types}
              inTrial={inTrial}
              showTrialThreshold={showTrialThreshold}
              isBlobStorage={isFrozen({ topologyElement })}
            />
          )}
        </NormalizeSizing>
      </TopologyPanelRow>
    )
  }

  renderAvailabilityZones(): JSX.Element {
    const {
      id,
      topologyElement,
      instanceConfiguration,
      onChange,
      inTrial,
      showTrialThreshold,
      maxZoneCount,
    } = this.props

    // Sometimes the default set it outside what is available for certain instance configs
    const defaultZoneCount = Math.min(topologyElement.zone_count || 0, maxZoneCount)

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-availabilityZonesLabel'
        defaultMessage='Availability zones'
      />
    )

    return (
      <TopologyPanelRow label={label} alignItems='center'>
        <ZoneCount
          id={id}
          zoneCount={defaultZoneCount}
          maxZoneCount={maxZoneCount}
          onChange={onChange && ((value) => onChange([`zone_count`], value))}
          sliderInstanceType={instanceConfiguration.instance_type}
          sliderNodeTypes={getNodeRoles({
            topologyElement,
          })}
          isDedicatedMaster={isDedicatedMaster({ topologyElement })}
          inTrial={inTrial}
          showTrialThreshold={showTrialThreshold}
        />
      </TopologyPanelRow>
    )
  }

  renderSummary(): JSX.Element {
    const { instanceConfiguration } = this.props
    const label = (
      <strong>
        <FormattedMessage
          id='deploymentInfrastructure-topologyElement-summaryLabel'
          defaultMessage='Total (size x zone)'
        />
      </strong>
    )
    const topologyElement = this.props.topologyElement as ElasticsearchClusterTopologyElement

    return (
      <TopologyPanelRow label={label}>
        <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
          {(normalizeSizingProps) => (
            <Summary
              {...normalizeSizingProps}
              topologyElement={topologyElement}
              sliderInstanceType={instanceConfiguration.instance_type}
              instanceConfiguraton={instanceConfiguration}
              isMachineLearning={isDedicatedML({ topologyElement })}
              isFrozen={isFrozen({ topologyElement })}
              autoscalingMin={topologyElement.autoscaling_min?.value}
              zoneCount={topologyElement.zone_count!}
            />
          )}
        </NormalizeSizing>
      </TopologyPanelRow>
    )
  }

  renderUserSettings(): JSX.Element | undefined {
    const { deployment, topologyElement, instanceConfiguration, onChange } = this.props

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-userSettingsLabel'
        defaultMessage='User settings'
      />
    )

    const sliderInstanceType = instanceConfiguration.instance_type
    const plan = getSliderPlan({ deployment, sliderInstanceType })

    if (!plan) {
      return // sanity
    }

    const settings = getSliderUserSettings({
      sliderInstanceType,
      plan,
      nodeConfiguration: topologyElement,
    })
    const defaultSettings = getDefaultUserSettings(sliderInstanceType)
    const setSettings =
      onChange &&
      ((value: string) =>
        onChange([instanceConfiguration.instance_type, `user_settings_yaml`], value))

    const {
      messages: { prettyName },
      userSettingsFileName,
    } = getSliderDefinition({ sliderInstanceType })
    const docLink = `${camelCase(instanceConfiguration.instance_type)}UserSettingsDocLink`

    return (
      <Fragment>
        <EuiSpacer size='l' />
        <TopologyPanelRow label={label}>
          <EuiText>
            <UserSettings
              settings={settings == null ? defaultSettings : settings}
              onChange={setSettings}
              isReadOnly={!onChange}
              prettyName={prettyName}
              fileName={userSettingsFileName}
              docLink={docLink}
            />
          </EuiText>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  onClickInfoPopover() {
    this.setState({ isTitlePopoverOpen: !this.state.isTitlePopoverOpen })
  }

  closeInfoPopOver() {
    this.setState({ isTitlePopoverOpen: false })
  }

  displayAutoscalingSettings(): boolean {
    const { isAutoscalingEnabled, topologyElement, deployment } = this.props
    const version = getUpsertVersion({ deployment }) || undefined

    return Boolean(isAutoscalingEnabled) && isAutoscaleableTier({ topologyElement, version })
  }
}

export default injectIntl(TopologyElement)
