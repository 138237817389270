/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiLoadingSpinner, EuiSpacer, EuiText } from '@elastic/eui'

import {
  getFirstEsClusterFromGet,
  getFirstSliderClusterFromGet,
  isEsStopped,
  hasEnabledSnapshots,
  hasSlm,
  isPendingInitialSnapshot,
} from '../../../lib/stackDeployments/selectors'
import { isPermitted } from '../../../lib/requiresPermission'
import Permission from '../../../lib/api/v1/permissions'
import { kibanaSnapshotAndRestoreUrl } from '../../../lib/serviceProviderDeepLinks'

import ClusterSnapshotList from './ClusterSnapshotList'
import ClusterSnapshotNotices from './ClusterSnapshotNotices'
import SlmCallouts from './SlmCallouts'
import SnapshotStatus from './SnapshotStatus'
import SlmSnapshotStatus from './SlmSnapshotStatus'
import SnapshotActions from './SnapshotActions'
import SlmSnapshotActions from './SlmSnapshotActions'
import UpdateSnapshotRepository from './UpdateSnapshotRepository'

import type { FunctionComponent } from 'react'
import type { WithStackDeploymentRouteParamsProps } from '../../StackDeploymentEditor'
import type { ClusterSnapshot, StackDeployment } from '../../../types'
import type { KibanaResourceInfo } from '../../../lib/api/v1/types'

import './clusterSnapshots.scss'

export type StateProps = {
  stackDeployment?: StackDeployment | null
  hasDefaultSnapshotRepository: boolean
  isUserConsole: boolean
  isHeroku: boolean
  snapshotHistory: ClusterSnapshot[] | null
  snapshotSettingsEnabled: boolean
}

export type ConsumerProps = WithStackDeploymentRouteParamsProps

export type AllProps = StateProps & ConsumerProps

const ClusterSnapshots: FunctionComponent<AllProps> = ({
  stackDeployment,
  isUserConsole,
  hasDefaultSnapshotRepository = false,
  snapshotSettingsEnabled,
  regionId,
  deploymentId,
  snapshotHistory,
  isHeroku,
}) => {
  if (stackDeployment == null) {
    return <EuiLoadingSpinner size='m' />
  }

  if (isEsStopped({ deployment: stackDeployment })) {
    return null
  }

  const resource = getFirstEsClusterFromGet({ deployment: stackDeployment })!
  const useSlm = hasSlm({ resource })
  const snapshotsEnabled = hasEnabledSnapshots({ resource })
  const pendingInitialSnapshot = isPendingInitialSnapshot({ resource })

  const manageSnapshotRepositories =
    !isUserConsole &&
    !hasDefaultSnapshotRepository &&
    isPermitted(Permission.getSnapshotRepositories)

  const kibana = getFirstSliderClusterFromGet<KibanaResourceInfo>({
    deployment: stackDeployment,
    sliderInstanceType: `kibana`,
  })

  return (
    <Fragment>
      {snapshotsEnabled && <SlmCallouts />}

      {isUserConsole && <SnapshotsOverviewSaas />}

      {snapshotsEnabled && (
        <Fragment>
          {useSlm ? (
            <SlmSnapshotStatus deployment={stackDeployment} />
          ) : (
            <SnapshotStatus
              stackDeployment={stackDeployment}
              isUserConsole={isUserConsole}
              canEditSettings={!isHeroku && snapshotSettingsEnabled && !pendingInitialSnapshot}
            />
          )}
        </Fragment>
      )}

      <ClusterSnapshotNotices stackDeployment={stackDeployment} isUserConsole={isUserConsole} />

      {useSlm ? (
        <SlmSnapshotActions
          regionId={regionId}
          deploymentId={deploymentId!}
          stackDeployment={stackDeployment}
          snapshotsEnabled={snapshotsEnabled}
          showRestoreSnapshotButton={!isHeroku}
          canManageRepos={manageSnapshotRepositories}
        />
      ) : (
        <Fragment>
          <SnapshotActions
            stackDeployment={stackDeployment}
            snapshotsEnabled={snapshotsEnabled}
            showRestoreSnapshotButton={!isHeroku}
          />

          {manageSnapshotRepositories && <UpdateSnapshotRepository deployment={stackDeployment} />}
        </Fragment>
      )}

      {useSlm ? (
        <Fragment>
          <EuiSpacer size='m' />
          <FormattedMessage
            id='cluster-snapshots.slm-snapshot-list-location'
            defaultMessage='The list of available snapshots has been removed from this page. You can now find it under <link>Snapshot and Restore</link> in Kibana.'
            values={{
              link: (content) => (
                <EuiLink href={kibanaSnapshotAndRestoreUrl({ resource: kibana })}>
                  {content}
                </EuiLink>
              ),
            }}
          />
        </Fragment>
      ) : (
        <ClusterSnapshotList deployment={stackDeployment} snapshotHistory={snapshotHistory} />
      )}
    </Fragment>
  )
}

function SnapshotsOverviewSaas() {
  return (
    <Fragment>
      <EuiText>
        <FormattedMessage
          id='cluster-snapshots.saas-overview'
          defaultMessage='Snapshots are backups of your data that you can restore in the event of an unexpected data loss.'
        />
      </EuiText>

      <EuiSpacer size='m' />
    </Fragment>
  )
}

export default ClusterSnapshots
