/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchDeployments } from '../../actions/stackDeployments'
import { searchAllocators } from '../../actions/allocatorSearch'
import {
  getAllocatorSearchResults,
  getRegion,
  getRegionInfo,
  getStackDeploymentsFromSearch,
  searchAllocatorsRequest,
  searchStackDeploymentsRequest,
} from '../../reducers'
import withPolling from '../../lib/withPolling'
import { getEsQuery } from '../StackDeploymentSearch/DeploymentFilterContext'
import searchDeploymentsQuery from '../StackDeploymentSearch/searchDeploymentsQuery'
import { getConfigForKey } from '../../selectors'
import { getConfigForKey as getConfigForKeyFromState } from '../../store'

import Platform from './Platform'

import type {
  AllocatorSearchResult,
  AsyncRequestState,
  ReduxState,
  Region,
  ThunkDispatch,
} from '../../types'
import type { DeploymentsSearchResponse, SearchRequest, RegionInfo } from '../../lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

interface StateProps {
  allocatorSearchResults?: AllocatorSearchResult[]
  defaultRegionId?: string
  regionInfo: RegionInfo | null
  region?: Region
  regionId: string
  searchAllocatorsRequest: AsyncRequestState
  searchHealthyDeploymentsRequest: AsyncRequestState
  searchHealthyDeploymentsResult: DeploymentsSearchResponse | null
  searchUnhealthyDeploymentsRequest: AsyncRequestState
  searchUnhealthyDeploymentsResult: DeploymentsSearchResponse | null
}

interface DispatchProps {
  fetchAllocators: () => void
  searchHealthyDeployments: () => void
  searchUnhealthyDeployments: () => void
}

type RouteParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<RouteParams>

const allocatorsQuery: SearchRequest = {
  size: 5000,
  query: {
    bool: {
      must_not: [
        {
          bool: {
            must: [
              // weird casts below due to lack of combination type support, https://github.com/elastic/cloud/pull/103751
              { term: { healthy: { value: true as unknown as string } } },
              { term: { connected: { value: false as unknown as string } } },
            ],
          },
        },
      ],
    },
  },
}

const pollingComponent = withPolling(
  Platform,
  ({ fetchAllocators, searchHealthyDeployments, searchUnhealthyDeployments }) => ({
    onPoll: () =>
      Promise.all([fetchAllocators(), searchHealthyDeployments(), searchUnhealthyDeployments()]),
    pollImmediately: [['match', 'params', 'regionId']],
  }),
)

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  allocatorSearchResults: getAllocatorSearchResults(state, regionId, 'allocators'),
  region: getRegion(state, regionId),
  regionId,
  defaultRegionId: getConfigForKey(state, `DEFAULT_REGION`),
  searchAllocatorsRequest: searchAllocatorsRequest(state, regionId, 'allocators'),
  searchHealthyDeploymentsResult: getStackDeploymentsFromSearch(
    state,
    `platform-healthy-deployments-${regionId}`,
  ),
  searchHealthyDeploymentsRequest: searchStackDeploymentsRequest(
    state,
    `platform-healthy-deployments-${regionId}`,
  ),
  searchUnhealthyDeploymentsResult: getStackDeploymentsFromSearch(
    state,
    `platform-unhealthy-deployments-${regionId}`,
  ),
  searchUnhealthyDeploymentsRequest: searchStackDeploymentsRequest(
    state,
    `platform-unhealthy-deployments-${regionId}`,
  ),
  regionInfo: getRegionInfo(state, regionId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => {
  const regionQueryPart = getConfigForKeyFromState(`DEFAULT_REGION`) ? '' : `region:${regionId} `
  const healthyQueryText = regionQueryPart + 'healthy:y stopped:n'
  const unhealthyQueryText = regionQueryPart + 'healthy:n stopped:n'
  const healthyQuery = searchDeploymentsQuery({ esQuery: getEsQuery(healthyQueryText), size: 0 })
  const unhealthyQuery = searchDeploymentsQuery({
    esQuery: getEsQuery(unhealthyQueryText),
    size: 0,
  })

  return {
    fetchAllocators: () =>
      dispatch(searchAllocators({ regionId, queryId: 'allocators', query: allocatorsQuery })),
    searchHealthyDeployments: () =>
      dispatch(
        searchDeployments({
          queryId: `platform-healthy-deployments-${regionId}`,
          query: healthyQuery,
        }),
      ),
    searchUnhealthyDeployments: () =>
      dispatch(
        searchDeployments({
          queryId: `platform-unhealthy-deployments-${regionId}`,
          query: unhealthyQuery,
        }),
      ),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
