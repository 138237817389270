/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import InviteOrganizationMembersFlyout from '.'

import type { OrganizationMemberRow } from '../types'
import type { FunctionComponent } from 'react'

const InviteOrganizationMembersButton: FunctionComponent<{
  organizationId: string
  organizationMemberRows: OrganizationMemberRow[]
}> = ({ organizationId, organizationMemberRows }) => (
  <InviteOrganizationMembersFlyout
    organizationId={organizationId}
    organizationMemberRows={organizationMemberRows}
  >
    {({ openFlyout }) => (
      <EuiButton fill={true} onClick={() => openFlyout()}>
        <FormattedMessage
          id='organization.invite-organization-members-flyout.invite-members'
          defaultMessage='Invite members'
        />
      </EuiButton>
    )}
  </InviteOrganizationMembersFlyout>
)

export default InviteOrganizationMembersButton
