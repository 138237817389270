/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import { getPlanInfo, _getPlanInfoFromHistory, isSizedPlan } from './fundamentals'

import type { SliderInstanceType, AnyResourceInfo, AnyClusterPlanInfo } from '../../../types'
import type { DeploymentResources } from '../../api/v1/types'

type WithResources = {
  resources: DeploymentResources
}

export type PlanAttemptErrorDetails = { [key: string]: string }

export type ParsedPlanAttemptError = {
  message: string
  details: PlanAttemptErrorDetails
  errorType?: string
}

export function isPendingAttempt({ planAttempt }: { planAttempt: AnyClusterPlanInfo }): boolean {
  return !planAttempt.attempt_end_time
}

export function isSuccessfulShutdownAttempt({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): boolean {
  return (
    planAttempt.source?.action === `deployments.shutdown-deployment` && // a shutdown plan...
    !isPendingAttempt({ planAttempt }) && // that completed...
    planAttempt.healthy // successfully
  )
}

export function getDeploymentPlanAttemptId({
  deployment,
  sliderInstanceType,
  planAttempt,
}: {
  deployment: WithResources
  sliderInstanceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
}): number | null {
  const { resources } = deployment
  const [resource] = resources[sliderInstanceType]

  return getPlanAttemptId({
    resource,
    planAttempt,
  })
}

export function getPlanAttemptId({
  resource,
  planAttempt,
}: {
  resource: AnyResourceInfo
  planAttempt: AnyClusterPlanInfo
}): number | null {
  const { plan_attempt_name: historyName } = planAttempt

  if (historyName !== undefined) {
    const historyNumber = parseInt(historyName.split('-')[1], 10)

    if (!isNaN(historyNumber)) {
      return historyNumber
    }
  }

  const {
    history,
    pending: pendingPlanAttempt,
    current: currentPlanAttempt,
  }: {
    history: AnyClusterPlanInfo[]
    pending?: AnyClusterPlanInfo
    current?: AnyClusterPlanInfo
  } = resource.info.plan_info

  if (planAttempt === pendingPlanAttempt || planAttempt === currentPlanAttempt) {
    const historyNumbers = history
      .map(({ plan_attempt_name: name }) => (name ? parseInt(name.split('-')[1], 10) : 0))
      .filter((number) => !isNaN(number))

    const maxHistoryNumber = Math.max(...historyNumbers)

    if (maxHistoryNumber >= 0) {
      return maxHistoryNumber + 1
    }

    return history.length
  }

  const historyIndex = history.indexOf(planAttempt)

  if (historyIndex !== -1) {
    return historyIndex
  }

  return null
}

export function getPlanBeforeAttempt({
  resource,
  planAttempt,
}: {
  resource: AnyResourceInfo
  planAttempt: AnyClusterPlanInfo
}) {
  const planInfo = resource.info.plan_info

  if (planAttempt === planInfo.pending) {
    return (planInfo.current && planInfo.current.plan) || null
  }

  function happenedBefore(otherPlanInfo: AnyClusterPlanInfo): boolean {
    if (planAttempt.attempt_start_time === undefined) {
      return true
    }

    return (
      otherPlanInfo.attempt_start_time !== undefined &&
      moment(otherPlanInfo.attempt_start_time) < moment(planAttempt.attempt_start_time)
    )
  }

  const prevSuccess = _getPlanInfoFromHistory({ resource, mustMatch: happenedBefore })
  return (prevSuccess && prevSuccess.plan) || null
}

export function getLastSizedPlanAttempt<TPlanInfo = AnyClusterPlanInfo>({
  deployment,
  sliderInstanceType,
}: {
  deployment: WithResources
  sliderInstanceType: SliderInstanceType
}): TPlanInfo | null {
  const { resources } = deployment
  const [resource] = resources[sliderInstanceType] as AnyResourceInfo[]

  if (!resource) {
    return null
  }

  const planInfo = _getPlanInfoFromHistory({ resource, mustMatch: hasSizedPlan })

  if (!planInfo) {
    return null
  }

  const castedPlanInfo = planInfo as unknown as TPlanInfo

  return castedPlanInfo

  function hasSizedPlan(planInfo: AnyClusterPlanInfo): boolean {
    return !!planInfo.plan && isSizedPlan(planInfo.plan)
  }
}

export function getLastPlanAttempt<TPlanInfo = AnyClusterPlanInfo>({
  deployment,
  sliderInstanceType,
}: {
  deployment: WithResources
  sliderInstanceType: SliderInstanceType
}): TPlanInfo | null {
  const { resources } = deployment
  const [resource] = resources[sliderInstanceType]

  if (!resource) {
    return null
  }

  const planInfo = getPlanInfo({ resource, state: `last_attempt` })
  return planInfo as any
}

export function parsePlanAttemptError({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): ParsedPlanAttemptError | null {
  const { healthy, error } = planAttempt

  if (healthy || !error) {
    return null
  }

  return { message: error.message, details: error.details, errorType: error.failure_type }
}
