/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchHappySadClusters } from '../../../actions/regionEqualizer'
import { getHappySadClusters } from '../../../reducers'
import { getConfigForKey } from '../../../selectors'
import withPolling from '../../../lib/withPolling'

import ControlPlanesTable from './ControlPlanesTable'

import type { ControlPlane } from '../types'
import type { RegionHappySadClusters, ReduxState, ThunkDispatch } from '../../../types'

type StateProps = {
  happySadClusters?: RegionHappySadClusters
  isElasticCloudEnterprise: boolean
}

type DispatchProps = {
  fetchHappySadClusters: () => void
}

type ConsumerProps = {
  regionId: string
  controlPlanes: ControlPlane[]
  totalCount: number
  initialLoading: boolean
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  happySadClusters: getHappySadClusters(state, regionId),
  isElasticCloudEnterprise:
    getConfigForKey(state, `CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId }: ConsumerProps,
): DispatchProps => ({
  fetchHappySadClusters: () => dispatch(fetchHappySadClusters({ regionId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withPolling(ControlPlanesTable, ({ fetchHappySadClusters: onPoll }) => ({ onPoll })))
