/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import DocLink from '@/components/DocLink'

import {
  isGCPUser,
  isAWSUser,
  isAzureUser,
  isRejectedUsageMarketPlaceUser,
} from '../../lib/marketPlace'

import type { UserProfile } from '../../types'
import type { FunctionComponent } from 'react'

export interface Props {
  profile: UserProfile
}

const UnSubscribedMarketPlaceUserCallout: FunctionComponent<Props> = ({ profile }) => (
  <Fragment>
    <EuiCallOut color='danger' className='stack-deployment-search-unsubscribed-user-callout'>
      {isGCPUser(profile) && getUnSubscribedGCPUserCalloutMessage()}
      {isAWSUser(profile) && getUnSubscribedAWSUserCalloutMessage()}
      {isAzureUser(profile) &&
        !isRejectedUsageMarketPlaceUser(profile) &&
        getUnSubscribedAzureUserCalloutMessage()}
      {isAzureUser(profile) &&
        isRejectedUsageMarketPlaceUser(profile) &&
        getRejectedUsageAzureUserCalloutMessage(
          profile.marketplace_attributes?.azure_usage_event_rejected_reason,
        )}
    </EuiCallOut>
    <EuiSpacer size='m' />
  </Fragment>
)

const getUnSubscribedGCPUserCalloutMessage = () => (
  <FormattedMessage
    id='stack-deployment-search.unsubscribed-gcp-user'
    defaultMessage='The Google Cloud project associated with your Elastic Cloud account has been disconnected. Any active deployments will be deleted soon. To create new deployments you first need to {enable}'
    values={{
      enable: (
        <DocLink link='accountBillingGcpDocLink'>
          <FormattedMessage
            id='stack-deployment-search.unsubscribed-gcp-user-link'
            defaultMessage='re-enable this account'
          />
        </DocLink>
      ),
    }}
  />
)

const getUnSubscribedAWSUserCalloutMessage = () => (
  <FormattedMessage
    id='stack-deployment-search.unsubscribed-aws-user'
    defaultMessage='The AWS Marketplace billing account associated with your Elastic Cloud account has been disconnected. To create a deployment you need to open a new Elastic Cloud account.'
  />
)

const getUnSubscribedAzureUserCalloutMessage = () => (
  <FormattedMessage
    id='stack-deployment-search.unsubscribed-azure-user'
    defaultMessage='The Azure SaaS subscription associated with your Elastic Cloud account has been disconnected. To create a deployment you need to open a new Elastic Cloud account.'
  />
)

const getRejectedUsageAzureUserCalloutMessage = (reason) => (
  <FormattedMessage
    id='stack-deployment-search.rejected-usage-azure-user'
    defaultMessage='Azure rejected billing reports for the SaaS resource with message "{reason}". The account has been disabled and deployments scheduled for termination.'
    values={{ reason }}
  />
)

export default UnSubscribedMarketPlaceUserCallout
