/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { hasCurrent, hasNext, isElasticsearch } from './preconditions'

import type { ElasticsearchClusterPlan } from '@/lib/api/v1/types'
import type { DifferenceCheck } from './types'

export const diffDeploymentTemplate: DifferenceCheck = {
  preconditions: [isElasticsearch, hasCurrent, hasNext],
  check: ({ current, next, sliderInstanceType }) => {
    const currentEs = current as ElasticsearchClusterPlan
    const nextEs = next as ElasticsearchClusterPlan

    const nextId = nextEs.deployment_template?.id

    if (!nextId) {
      return []
    }

    const currentId = currentEs.deployment_template?.id

    if (currentId === nextId) {
      return []
    }

    return [
      {
        type: `deployment-template-migrated`,
        target: sliderInstanceType,
        meta: {
          currentId,
          nextId,
        },
      },
    ]
  },
}
