/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchHeapDumps, startHeapDumpCapture } from '../../actions/heapDumps'
import {
  getStackDeployment,
  getDeploymentHeapDumps,
  fetchHeapDumpsRequest,
  startHeapDumpCaptureRequest,
} from '../../reducers'
import { downloadDeploymentInstanceHeapDumpUrl } from '../../lib/api/v1/urls'
import { getConfigForKey } from '../../selectors'

import ManageHeapDumps from './ManageHeapDumps'

import type {
  AsyncRequestState,
  StackDeployment,
  ReduxState,
  ThunkDispatch,
  SliderInstanceType,
} from '../../types'
import type { HeapDump } from '../../types/heapDump'
import type { RouteComponentProps } from 'react-router'

interface StateProps {
  deployment: StackDeployment
  heapDumps?: HeapDump[]
  fetchHeapDumpsRequest: AsyncRequestState
  startHeapDumpCaptureRequest: (refId: string, instanceId: string) => AsyncRequestState
  getHeapDumpDownloadUrl: (resourceKind: string, refId: string, instanceId: string) => string
  showSupportInstructions?: boolean
}

type RouteParams = { deploymentId }
type ConsumerProps = RouteComponentProps<RouteParams>

interface DispatchProps {
  fetchHeapDumps: (deployment: StackDeployment) => void
  startHeapDumpCapture: (
    deployment: StackDeployment,
    resourceKind: SliderInstanceType,
    refId: string,
    instanceId: string,
  ) => Promise<void>
}

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { deploymentId },
    },
  }: ConsumerProps,
): StateProps => {
  const deployment = getStackDeployment(state, deploymentId)!
  const heapDumps = getDeploymentHeapDumps(state, deploymentId)

  return {
    deployment,
    heapDumps,
    fetchHeapDumpsRequest: fetchHeapDumpsRequest(state, deploymentId),
    startHeapDumpCaptureRequest: (refId: string, instanceId: string) =>
      startHeapDumpCaptureRequest(state, deploymentId, refId, instanceId),
    getHeapDumpDownloadUrl: (resourceKind: string, refId: string, instanceId: string): string =>
      downloadDeploymentInstanceHeapDumpUrl({ deploymentId, resourceKind, refId, instanceId }),
    showSupportInstructions: getConfigForKey(state, `SHOW_SUPPORT_INSTRUCTIONS`),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchHeapDumps: (deployment: StackDeployment) =>
    dispatch(fetchHeapDumps({ deploymentId: deployment.id })),
  startHeapDumpCapture: (
    deployment: StackDeployment,
    resourceKind: SliderInstanceType,
    refId: string,
    instanceId: string,
  ) =>
    dispatch(
      startHeapDumpCapture({ deploymentId: deployment.id, resourceKind, refId, instanceId }),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ManageHeapDumps)
