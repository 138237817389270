/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sum } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiText,
  EuiSpacer,
  EuiTabs,
  EuiTab,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import Header from '../Header'
import DocLink from '../DocLink'
import { countControlPlanes } from '../../lib/hostRoles'
import history from '../../lib/history'
import {
  hostsAllocatorsUrl,
  hostsProxiesUrl,
  hostsControlPlanesUrl,
  hostsUrl,
} from '../../lib/urlBuilder'
import {
  hostsAllocatorsCrumbs,
  hostsProxiesCrumbs,
  hostsControlPlanesCrumbs,
  hostsCrumbs,
} from '../../lib/crumbBuilder'

import ControlPlanes from './ControlPlanes'
import Proxies from './Proxies'
import Allocators from './Allocators'
import HostsOverview from './HostsOverview'

import type { RegionInfo } from '../../lib/api/v1/types'

type HostsType = 'allocators' | 'proxies' | 'control-planes'

type Props = {
  regionId: string
  hostsType?: HostsType
  regionInfo: RegionInfo | null
}

const ALL_HOSTS = undefined

class Hosts extends Component<Props> {
  render() {
    const { regionId, hostsType } = this.props

    const { hostCount, allocatorCount, proxyCount, controlPlaneCount } = this.countRegionHosts()

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='hosts.header' defaultMessage='Hosts' />}
          breadcrumbs={getBreadcrumbs({ regionId, hostsType })}
        />

        <EuiText color='subdued'>
          <FormattedMessage
            id='hosts.description'
            defaultMessage='Hosts perform essential roles in your installation. You assign these roles based on how you want to scale your installation. {learnMore}.'
            values={{
              learnMore: (
                <DocLink link='installNewHosts'>
                  <FormattedMessage
                    id='hosts.install-link'
                    defaultMessage='Learn how to add new hosts'
                  />
                </DocLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='l' />

        <EuiTabs>
          <EuiTab
            onClick={() => history.replace(hostsUrl(regionId))}
            isSelected={hostsType === ALL_HOSTS}
          >
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <FormattedMessage id='hosts.all-hosts-tab' defaultMessage='All hosts' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color='hollow'>{hostCount === null ? '…' : hostCount}</EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostsAllocatorsUrl(regionId))}
            isSelected={hostsType === 'allocators'}
          >
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <FormattedMessage id='hosts.allocators-tab' defaultMessage='Allocators' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color='hollow'>{allocatorCount === null ? '…' : allocatorCount}</EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostsProxiesUrl(regionId))}
            isSelected={hostsType === 'proxies'}
          >
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <FormattedMessage id='hosts.proxies-tab' defaultMessage='Proxies' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color='hollow'>{proxyCount === null ? '…' : proxyCount}</EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostsControlPlanesUrl(regionId))}
            isSelected={hostsType === 'control-planes'}
          >
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <FormattedMessage id='hosts.control-planes-tab' defaultMessage='Control planes' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color='hollow'>
                  {controlPlaneCount === null ? '…' : controlPlaneCount}
                </EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiTab>
        </EuiTabs>

        <EuiSpacer size='l' />

        {this.renderTabContent()}
      </Fragment>
    )
  }

  renderTabContent() {
    const { hostsType, regionId } = this.props

    if (hostsType === ALL_HOSTS) {
      return <HostsOverview regionId={regionId} />
    }

    if (hostsType === 'allocators') {
      return <Allocators regionId={regionId} />
    }

    if (hostsType === 'proxies') {
      return <Proxies regionId={regionId} />
    }

    if (hostsType === 'control-planes') {
      return <ControlPlanes regionId={regionId} />
    }

    return null
  }

  countRegionHosts(): {
    hostCount: number | null
    allocatorCount: number | null
    proxyCount: number | null
    controlPlaneCount: number | null
  } {
    const { regionInfo } = this.props

    if (!regionInfo) {
      return {
        hostCount: null,
        allocatorCount: null,
        proxyCount: null,
        controlPlaneCount: null,
      }
    }

    const controlPlaneCounts = countControlPlanes(regionInfo)

    return {
      hostCount: regionInfo.runners.total_runners,
      allocatorCount: sum(
        regionInfo.allocators.zone_summaries.map((zone) => zone.total_allocators),
      ),
      proxyCount: regionInfo.proxies.proxies_count,
      controlPlaneCount: controlPlaneCounts.happy + controlPlaneCounts.sad,
    }
  }
}

export default Hosts

function getBreadcrumbs({ regionId, hostsType }: { regionId: string; hostsType?: HostsType }) {
  if (hostsType === 'allocators') {
    return hostsAllocatorsCrumbs({ regionId })
  }

  if (hostsType === 'proxies') {
    return hostsProxiesCrumbs({ regionId })
  }

  if (hostsType === 'control-planes') {
    return hostsControlPlanesCrumbs({ regionId })
  }

  return hostsCrumbs({ regionId })
}
