/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { camelCase, cloneDeep, remove, sortBy } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiSpacer,
  EuiLink,
  EuiToolTip,
  EuiCodeBlock,
  EuiPopover,
  EuiButtonIcon,
} from '@elastic/eui'

import { getApmMode, ApmMode } from '@/lib/apmClusters/getApmMode'

import { CuiSliderLogo } from '../../../../../cui'
import {
  getSupportedSliderInstanceTypes,
  getSliderPrettyName,
  getSliderWeight,
  getSliderDefinition,
} from '../../../../../lib/sliders'
import { getInstanceConfigurationById } from '../../../../../lib/instanceConfigurations/instanceConfiguration'
import {
  getPlugins,
  getEsPlan,
  getSliderNodeTypeForTopologyElement,
  supportsFrozenTier,
  supportsNodeRoles,
  isCold,
  isFrozen,
  getDedicatedMasterThresholdFromTemplate,
  isAutoscalingEnabled,
  isSliderSupportedForDeployment,
  isSliderSupportedForNewDeployment,
  getFirstSliderCluster,
  getPlanConfiguration,
  getUserSettingsFromConfiguration,
  hasOnlyPlanLevelSettings,
  getSliderPlan,
} from '../../../../../lib/stackDeployments/selectors'
import { getUserSettingsOverridesAsString } from '../../../../../lib/stackDeployments/selectors/stackDeployment'
import { getDefaultUserSettings } from '../../../../../lib/stackDeployments/userSettings'
import { getTopologiesFromTemplate } from '../../../../../lib/deploymentTemplates/getTopologiesFromTemplate'
import { getCustomPluginsFromPlan } from '../../../../../lib/plugins'
import { isEnabledConfiguration } from '../../../../../lib/deployments/conversion'
import { getConfigForKey } from '../../../../../store'
import { lt } from '../../../../../lib/semver'
import DangerButton from '../../../../DangerButton'

import TopologyElement from './TopologyElement'
import Flyout from './Flyout'
import Plugins from './Plugins'
import Extensions from './Extensions'
import Settings from './Settings'
import UserSettings from './UserSettings'

import type { Props as FlyoutProps } from './Flyout'
import type { AnyTopologyElement, SliderInstanceType } from '../../../../../types'
import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  StackVersionConfig,
  ElasticsearchUserPlugin,
  ElasticsearchUserBundle,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterTopologyElement,
  DeploymentGetResponse,
} from '../../../../../lib/api/v1/types'

import './deploymentInfrastructure.scss'

export interface Props {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentUnderEdit?: DeploymentGetResponse
  // The ECE security cluster carries a reference to a deployment template that
  // is not accessible to the client. Regardless, we still need the instance
  // configurations, so the consumer can alternatively supply only the
  // instance_configurations (sourced via some other avenue than the deployment
  // template) as a subset of the DeploymentTemplateInfoV2 shape.
  templateInfo:
    | DeploymentTemplateInfoV2
    | (Pick<DeploymentTemplateInfoV2, 'instance_configurations'> & {
        deployment_template: undefined
      })
  versionConfig: StackVersionConfig | undefined
  onChange: (topologyElement: AnyTopologyElement) => (path: string[], value: any) => void
  onPlanChange:
    | undefined
    | ((sliderInstanceType: SliderInstanceType, path: string[], value: any) => void)
  onPluginsChange: (options: {
    plugins: string[] | Array<ElasticsearchUserPlugin | ElasticsearchUserBundle>
    path?: string[]
    userInitiated?: boolean
  }) => void
  onScriptingChange: (
    scriptingType: 'inline' | 'stored' | 'file',
    value: boolean | 'on' | 'off' | 'sandbox',
  ) => void
  onlyShowPricingFactors?: boolean
  showTrialThreshold?: boolean
  isHeroku: boolean
  showUserSettings: boolean
}

interface State {
  showFlyout: null | SliderInstanceType
  showOverridesPopover: boolean
}

export default class DeploymentInfrastructure extends Component<Props, State> {
  state = {
    showFlyout: null,
    showOverridesPopover: false,
  }

  render(): JSX.Element {
    return <Fragment>{this.renderResources()}</Fragment>
  }

  renderResources(): JSX.Element {
    const { versionConfig, onlyShowPricingFactors } = this.props

    const sliderInstanceTypes = getSupportedSliderInstanceTypes().filter(
      (sliderInstanceType) => this.getSliderNodeConfigurations(sliderInstanceType)?.length,
    )

    return (
      <Fragment>
        {sliderInstanceTypes.map((sliderInstanceType) => (
          <div
            key={sliderInstanceType}
            data-test-id={`type-${sliderInstanceType === `elasticsearch` ? `es` : `slider`}`}
            data-id={sliderInstanceType}
          >
            <EuiFlexGroup gutterSize='m' alignItems='center' wrap={true} responsive={false}>
              <EuiFlexItem grow={false}>
                <CuiSliderLogo
                  sliderInstanceType={sliderInstanceType}
                  version={versionConfig?.version}
                  size='l'
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiTitle size='s'>
                  <div>
                    <FormattedMessage
                      {...getSliderPrettyName({
                        sliderInstanceType,
                        version: versionConfig?.version,
                      })}
                    />
                  </div>
                </EuiTitle>
              </EuiFlexItem>
              {!onlyShowPricingFactors && (
                <Fragment>
                  <EuiFlexItem grow={true} />
                  <EuiFlexItem grow={false}>
                    {this.renderFlyoutButton({ sliderInstanceType })}
                  </EuiFlexItem>
                </Fragment>
              )}
            </EuiFlexGroup>
            <EuiSpacer size='m' />
            {this.renderTopologyElements(sliderInstanceType)}
            {sliderInstanceType === `elasticsearch`
              ? this.renderElasticsearchFlyout()
              : this.renderSliderFlyout({ sliderInstanceType })}
          </div>
        ))}
      </Fragment>
    )
  }

  renderFlyoutButton({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment, showUserSettings, deploymentUnderEdit } = this.props

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    if (sliderInstanceType === 'integrations_server') {
      return null
    }

    if (sliderInstanceType === `apm`) {
      if (getApmMode({ deployment: deploymentUnderEdit }) === ApmMode.Managed) {
        return null
      }
    }

    const overridesBtn = this.getOverridesButton(sliderInstanceType)

    if (sliderInstanceType === `elasticsearch`) {
      const plan = getEsPlan({ deployment })

      const pluginCount = getPlugins({ deployment }).length
      const extensionCount = plan ? getCustomPluginsFromPlan(plan).length : 0
      const totalCount = pluginCount + extensionCount
      const extensionsTooltipMsg = (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyoutButton-tooltip'
          defaultMessage='Extensions are plugins or bundles that you can add to customize Elasticsearch.'
        />
      )

      return (
        <EuiFlexGroup alignItems='center' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiLink
              data-test-subj='deploymentInfrastructure-elasticsearchFlyoutButton'
              onClick={() => this.setState({ showFlyout: `elasticsearch` })}
            >
              <EuiToolTip content={extensionsTooltipMsg}>
                {showUserSettings && hasOnlyPlanLevelSettings(resource) ? (
                  <FormattedMessage
                    id='deploymentInfrastructure-elasticsearchFlyoutButton-with-userSettings'
                    defaultMessage='Manage user settings and extensions ({totalCount})'
                    values={{ totalCount }}
                  />
                ) : (
                  <FormattedMessage
                    id='deploymentInfrastructure-elasticsearchFlyoutButton-without-userSettings'
                    defaultMessage='Manage settings and extensions ({totalCount})'
                    values={{ totalCount }}
                  />
                )}
              </EuiToolTip>
            </EuiLink>
          </EuiFlexItem>
          {overridesBtn && <EuiFlexItem>{overridesBtn}</EuiFlexItem>}
        </EuiFlexGroup>
      )
    }

    if (!showUserSettings) {
      return null
    }

    if (!hasOnlyPlanLevelSettings(resource)) {
      return null
    }

    return (
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <EuiLink
            data-test-subj='deploymentInfrastructure-sliderFlyoutButton'
            onClick={() => this.setState({ showFlyout: sliderInstanceType })}
          >
            <FormattedMessage
              id='deploymentInfrastructure-sliderFlyoutButton'
              defaultMessage='Edit user settings'
            />
          </EuiLink>
        </EuiFlexItem>
        {overridesBtn && <EuiFlexItem>{overridesBtn}</EuiFlexItem>}
      </EuiFlexGroup>
    )
  }

  renderTopologyElements(sliderInstanceType: SliderInstanceType): JSX.Element[] | null {
    const {
      deployment,
      deploymentUnderEdit,
      templateInfo,
      onChange,
      onPlanChange,
      onlyShowPricingFactors,
      showTrialThreshold,
      showUserSettings,
    } = this.props

    const dedicatedMasterThreshold =
      templateInfo.deployment_template &&
      getDedicatedMasterThresholdFromTemplate({
        deploymentTemplate: templateInfo.deployment_template,
      })

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    const topologyElements = this.getSliderNodeConfigurations(sliderInstanceType)

    return topologyElements.map(({ topologyElement, templateTopologyElement }, i) => {
      const key = `${sliderInstanceType}-${i}`

      const instanceConfiguration = getInstanceConfigurationById(
        templateInfo.instance_configurations,
        topologyElement.instance_configuration_id!,
      )!

      return (
        <TopologyElement
          key={key}
          id={key}
          showUserSettings={showUserSettings && !hasOnlyPlanLevelSettings(resource)}
          deployment={deployment}
          deploymentUnderEdit={deploymentUnderEdit}
          sliderInstanceType={sliderInstanceType}
          topologyElement={topologyElement}
          templateTopologyElement={templateTopologyElement}
          instanceConfiguration={instanceConfiguration}
          onChange={(path, value) => onChange(topologyElement)(path, value)}
          onPlanChange={(path, value) =>
            onPlanChange && onPlanChange(sliderInstanceType, path, value)
          }
          onlyShowPricingFactors={Boolean(onlyShowPricingFactors)}
          showTrialThreshold={Boolean(showTrialThreshold)}
          isAutoscalingEnabled={isAutoscalingEnabled({ deployment })}
          dedicatedMasterThreshold={dedicatedMasterThreshold}
        />
      )
    })
  }

  renderElasticsearchFlyout(): JSX.Element | null {
    const {
      deployment,
      versionConfig,
      onChange,
      onPluginsChange,
      onlyShowPricingFactors,
      showUserSettings,
    } = this.props
    const { showFlyout } = this.state

    if (onlyShowPricingFactors) {
      return null
    }

    if (showFlyout !== `elasticsearch`) {
      return null
    }

    if (!versionConfig) {
      return null
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType: `elasticsearch` })

    if (!resource) {
      return null // sanity
    }

    const title = (
      <FormattedMessage
        id='deploymentInfrastructure-elasticsearchFlyout-title'
        defaultMessage='Elasticsearch user settings and extensions'
      />
    )

    const tabs: FlyoutProps['tabs'] = []

    if (showUserSettings && hasOnlyPlanLevelSettings(resource)) {
      tabs.push({
        id: `userSettings`,
        name: (
          <FormattedMessage
            id='deploymentInfrastructure-elasticsearchFlyout-userSettings-title'
            defaultMessage='User Settings'
          />
        ),
      })
    }

    tabs.push({
      id: `plugins`,
      name: (
        <FormattedMessage
          id='deploymentInfrastructure-elasticsearchFlyout-plugins-title'
          defaultMessage='Extensions'
        />
      ),
    })

    const plan = getEsPlan({ deployment })!
    const { version } = plan.elasticsearch

    // v6+ scripting is not configurable via the UI
    if (version != null && lt(version, `6.0.0`)) {
      tabs.push({
        id: `settings`,
        name: (
          <FormattedMessage
            id='deploymentInfrastructure-elasticsearchFlyout-settings-title'
            defaultMessage='System Settings'
          />
        ),
      })
    }

    return (
      <Flyout title={title} tabs={tabs} onClose={() => this.setState({ showFlyout: null })}>
        {(activeTabId) => {
          switch (activeTabId) {
            case `userSettings`:
              return this.renderUserSettings({ sliderInstanceType: `elasticsearch` })
            case `plugins`:
              return (
                <Fragment>
                  {getConfigForKey(`APP_NAME`) === `userconsole` && (
                    <Extensions deployment={deployment} onPluginsChange={onPluginsChange} />
                  )}
                  <Plugins
                    deployment={deployment}
                    versionConfig={versionConfig}
                    onPluginsChange={onPluginsChange}
                  />
                </Fragment>
              )
            case `settings`:
              return (
                <Settings
                  deployment={deployment}
                  onChange={(topologyElement: AnyTopologyElement, path: string[], value: any) =>
                    onChange(topologyElement)(path, value)
                  }
                />
              )
            default:
              return null
          }
        }}
      </Flyout>
    )
  }

  renderSliderFlyout({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment } = this.props

    if (this.props.onlyShowPricingFactors) {
      return null
    }

    if (this.state.showFlyout !== sliderInstanceType) {
      return null
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    if (!hasOnlyPlanLevelSettings(resource)) {
      return null
    }

    const title = (
      <FormattedMessage
        id='deploymentInfrastructure-sliderFlyout-title'
        defaultMessage='User settings'
      />
    )

    return (
      <Flyout title={title} onClose={() => this.setState({ showFlyout: null })}>
        {() => this.renderUserSettings({ sliderInstanceType })}
      </Flyout>
    )
  }

  renderUserSettings({
    sliderInstanceType,
  }: {
    sliderInstanceType: SliderInstanceType
  }): JSX.Element | null {
    const { deployment, versionConfig, onPlanChange } = this.props

    if (!versionConfig) {
      return null
    }

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return null // sanity
    }

    const settings = getUserSettingsFromConfiguration(getPlanConfiguration(resource))

    const defaultSettings = getDefaultUserSettings(sliderInstanceType)

    const {
      messages: { prettyName },
      userSettingsFileName,
    } = getSliderDefinition({ sliderInstanceType, version: versionConfig.version })
    const docLink = `${camelCase(sliderInstanceType)}UserSettingsDocLink`

    return (
      <UserSettings
        settings={settings == null ? defaultSettings : settings}
        onChange={(value: any) => {
          onPlanChange &&
            onPlanChange(sliderInstanceType, [sliderInstanceType, `user_settings_yaml`], value)
        }}
        prettyName={prettyName}
        fileName={userSettingsFileName}
        docLink={docLink}
      />
    )
  }

  getOverridesButton(sliderInstanceType: SliderInstanceType): JSX.Element | null {
    const { deployment, onPlanChange } = this.props
    const plan = getSliderPlan({ deployment, sliderInstanceType })

    if (plan == null) {
      return null
    }

    const overrides = getUserSettingsOverridesAsString({ plan, sliderInstanceType })

    if (overrides === null) {
      return null
    }

    const prettyName = getSliderPrettyName({ sliderInstanceType }).defaultMessage

    return (
      <EuiFlexGroup alignItems='center' gutterSize='xs'>
        <EuiFlexItem>
          <DangerButton
            color='warning'
            data-test-id='deploymentInfrastructure-clearOverrides-btn'
            fill={false}
            isEmpty={true}
            modal={{
              title: (
                <FormattedMessage
                  id='deploymentInfrastructure-clearOverrides-modalTitle'
                  defaultMessage='Remove administrator overrides?'
                />
              ),
              body: (
                <Fragment>
                  <FormattedMessage
                    id='deploymentInfrastructure-clearOverrides-modalDescription'
                    defaultMessage='Some user settings were overridden by an administrator on your {sliderInstanceType} instances. 
                    Remove these overrides only if you are sure that they are no longer needed. After removing the overrides, save your configuration at the bottom of the Edit page to apply the changes.'
                    values={{
                      sliderInstanceType: prettyName,
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiCodeBlock>{overrides}</EuiCodeBlock>
                </Fragment>
              ),
            }}
            onConfirm={() => this.clearOverrideSettings(onPlanChange, sliderInstanceType)}
          >
            <FormattedMessage
              id='deploymentInfrastructure-clearOverrides'
              defaultMessage='Remove overrides'
            />
          </DangerButton>
        </EuiFlexItem>
        <EuiFlexItem style={{ marginLeft: `-4px` }}>
          <EuiPopover
            button={
              <EuiButtonIcon
                aria-label={`blah`}
                color='primary'
                iconType='iInCircle'
                onClick={() => this.setState({ showOverridesPopover: true })}
              />
            }
            isOpen={this.state.showOverridesPopover}
            closePopover={() => this.setState({ showOverridesPopover: false })}
          >
            <FormattedMessage
              id='deploymentInfrastructure-clearOverrides-description'
              defaultMessage='The following user settings were overridden by an administrator on your {sliderInstanceType} instances.'
              values={{
                sliderInstanceType: prettyName,
              }}
            />
            <EuiSpacer size='s' />
            <EuiCodeBlock>{overrides}</EuiCodeBlock>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  clearOverrideSettings(onPlanChange, sliderInstanceType) {
    if (!onPlanChange) {
      return
    }

    // Instead of keeping track of which one of these was set, we can just null both and let the API take care of it
    onPlanChange(sliderInstanceType, [sliderInstanceType, `user_settings_override_json`], null)
    onPlanChange(sliderInstanceType, [sliderInstanceType, `user_settings_override_yaml`], null)
  }

  getSliderNodeConfigurations = (
    sliderInstanceType: SliderInstanceType,
  ): Array<{
    topologyElement: AnyTopologyElement
    templateTopologyElement?: AnyTopologyElement
  }> => {
    const { deployment, deploymentUnderEdit, templateInfo, versionConfig, isHeroku } = this.props

    // filter out unsupported sliderInstanceTypes
    if (!isSupported()) {
      return []
    }

    const templateTopologyElements = templateInfo.deployment_template
      ? getTopologiesFromTemplate({
          deploymentTemplate: templateInfo.deployment_template,
          sliderInstanceType,
        })
      : []

    // pair the deployment's topologies with the template's
    const topologyElements = getTopologiesFromTemplate({
      deploymentTemplate: deployment,
      sliderInstanceType,
    }).map((topologyElement) => {
      const templateTopologyElement = cloneDeep(
        templateTopologyElements.find(({ id }) => id && id === topologyElement.id),
      )

      // remove unsupported data_roles property from pre-node_roles versions
      if (
        templateTopologyElement &&
        sliderInstanceType === `elasticsearch` &&
        !supportsNodeRoles({ version: versionConfig?.version })
      ) {
        delete (templateTopologyElement as ElasticsearchClusterTopologyElement).node_roles
      }

      return { topologyElement, templateTopologyElement }
    })

    // remove non-applicable stuff from heroku
    if (isHeroku) {
      remove(topologyElements, ({ topologyElement }) => {
        // show anything that's already on (in practice, the hot/content tier,
        // and dedicated masters if the threshold is met)
        if (isEnabledConfiguration(topologyElement)) {
          return false
        }

        // and show kibana so they can *turn* it on
        if (sliderInstanceType === `kibana`) {
          return false
        }

        // but that's it
        return true
      })
    }

    // remove unsupported properties from pre-node_roles versions...
    if (
      sliderInstanceType === `elasticsearch` &&
      !supportsNodeRoles({ version: versionConfig?.version })
    ) {
      // cold and frozen tiers
      remove(topologyElements, ({ topologyElement }) => isCold({ topologyElement }))
      remove(topologyElements, ({ topologyElement }) => isFrozen({ topologyElement }))

      // the node_roles property
      topologyElements.forEach(({ templateTopologyElement }) => {
        if (templateTopologyElement) {
          delete (templateTopologyElement as ElasticsearchClusterTopologyElement).node_roles
        }
      })
    }

    // remove frozen tier from < 7.12
    if (!supportsFrozenTier({ version: versionConfig?.version })) {
      remove(topologyElements, ({ topologyElement }) => isFrozen({ topologyElement }))
    }

    return sortBy(topologyElements, ({ topologyElement }) => {
      const sliderNodeType = getSliderNodeTypeForTopologyElement({
        topologyElement,
      })

      return getSliderWeight(sliderInstanceType, sliderNodeType)
    })

    function isSupported() {
      if (deploymentUnderEdit) {
        return isSliderSupportedForDeployment({
          deployment: deploymentUnderEdit,
          deploymentTemplate: templateInfo.deployment_template,
          sliderInstanceType,
        })
      }

      if (!versionConfig?.version) {
        return false
      }

      return isSliderSupportedForNewDeployment({
        version: versionConfig.version,
        deploymentTemplate: templateInfo.deployment_template,
        sliderInstanceType,
      })
    }
  }
}
