/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../../messages'

import { Tab } from './types'

import type { ReactElement } from 'react'
import type { Dimension } from '@/lib/api/v1/types'

interface UsageRate {
  key: Dimension['type']
  description: ReactElement
  dataTestId: string
}

export const usageRates: UsageRate[] = [
  {
    key: 'capacity',
    description: <FormattedMessage {...messages.capacity} />,
    dataTestId: 'usage-card.capacity',
  },
  {
    key: 'data_in',
    description: <FormattedMessage {...messages.dataTransferIn} />,
    dataTestId: 'usage-card.data_in',
  },
  {
    key: 'data_out',
    description: <FormattedMessage {...messages.dataTransferOut} />,
    dataTestId: 'usage-card.data_out',
  },
  {
    key: 'data_internode',
    description: <FormattedMessage {...messages.dataTransferInternode} />,
    dataTestId: 'usage-card.data_internode',
  },
  {
    key: 'storage_api',
    description: <FormattedMessage {...messages.snapshotApis} />,
    dataTestId: 'usage-card.storage_api',
  },
  {
    key: 'storage_bytes',
    description: <FormattedMessage {...messages.snapshotStorage} />,
    dataTestId: 'usage-card.storage_bytes',
  },
]

export const options = [
  {
    id: Tab.Current,
    label: <FormattedMessage {...messages.currentMonth} />,
  },
  {
    id: Tab.Previous,
    label: <FormattedMessage {...messages.previousMonth} />,
  },
]
