/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'

import { EuiSpacer, EuiPanel } from '@elastic/eui'

import DeploymentUsageTable from '../components/DeploymentUsageTable'
import ErrorCallout from '../components/ErrorCallout'
import CostsChartByDeployment from '../components/CostsChartByDeployment'
import { ViewByToBucketingStrategy } from '../constants'

import type { AllProps } from './types'

import '../styles.scss'

class BillingUsageByDeployment extends Component<AllProps> {
  componentDidMount() {
    this.fetchChartData()
    this.fetchUsageTableData()
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, selectedDeploymentId, viewBy } = this.props
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      selectedDeploymentId: prevSelectedDeploymentId,
      viewBy: prevViewBy,
    } = prevProps

    if (
      !startDate.isSame(prevStartDate) ||
      !endDate.isSame(prevEndDate) ||
      selectedDeploymentId !== prevSelectedDeploymentId
    ) {
      this.fetchChartData()
      this.fetchUsageTableData()
    }

    if (viewBy !== prevViewBy) {
      this.fetchChartData()
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderChart()}

        <EuiSpacer size='l' />

        {this.renderUsageTable()}
      </Fragment>
    )
  }

  renderChart = () => {
    const { costsChartsByDeployment, costsChartsByDeploymentRequest, chartType, viewBy } =
      this.props

    if (costsChartsByDeploymentRequest.error) {
      return <ErrorCallout retry={this.fetchChartData} data-test-id='costs-chart-error' />
    }

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='m'
        hasBorder={true}
        className='costs-chart-panel'
        data-test-id='costs-chart-panel'
      >
        <CostsChartByDeployment
          data={costsChartsByDeployment?.data || []}
          chartType={chartType}
          viewBy={viewBy}
          isLoading={costsChartsByDeploymentRequest.inProgress}
        />
      </EuiPanel>
    )
  }

  renderUsageTable = () => {
    const {
      deploymentFromSearch,
      organizationId,
      startDate,
      endDate,
      itemCosts,
      itemCostsRequest,
      deployment,
      deploymentTemplate,
      deploymentRequest,
      deploymentTemplatesRequest,
    } = this.props

    const hasError = itemCostsRequest.error || deploymentRequest.error
    const isTableLoading =
      itemCostsRequest.inProgress ||
      deploymentRequest.inProgress ||
      deploymentTemplatesRequest.inProgress ||
      !itemCosts ||
      !deployment

    if (hasError) {
      return <ErrorCallout retry={this.fetchUsageTableData} data-test-id='usage-table-error' />
    }

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        className='billing-usage-responsive-panel'
        data-test-id='deployment-usage-table'
      >
        <DeploymentUsageTable
          deploymentFromSearch={deploymentFromSearch}
          organizationId={organizationId}
          startDate={startDate}
          endDate={endDate}
          itemCosts={itemCosts}
          deployment={deployment}
          deploymentTemplate={deploymentTemplate}
          isLoading={isTableLoading}
        />
      </EuiPanel>
    )
  }

  fetchUsageTableData = () => {
    const {
      fetchStackDeployment,
      fetchDeploymentCostsItems,
      fetchDeploymentTemplates,
      startDate,
      endDate,
    } = this.props

    fetchDeploymentCostsItems({ from: startDate, to: endDate, id: 'customRange' })
    fetchStackDeployment()
    fetchDeploymentTemplates()
  }

  fetchChartData = () => {
    const { fetchCostsChartsByDeployment, startDate, endDate, viewBy } = this.props

    fetchCostsChartsByDeployment({
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      bucketingStrategy: ViewByToBucketingStrategy[viewBy],
    })
  }
}

export default BillingUsageByDeployment
