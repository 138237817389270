/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchOrganization,
  fetchOrganizations,
  resetFetchOrganizationRequest,
  resetFetchOrganizationsRequest,
} from '@/actions/organizations'
import Feature from '@/lib/feature'
import { isFeatureActivated } from '@/selectors'
import {
  fetchOrganizationRequest,
  fetchOrganizationsRequest,
  getOrganizations,
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '@/reducers'
import { getDeploymentByIdQuery } from '@/lib/deploymentQuery'
import { searchDeployments } from '@/actions/stackDeployments'
import { resetSearchStackDeploymentsRequest } from '@/actions/stackDeployments/crud'

import Organizations from './Organizations'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ReduxState, ThunkDispatch } from '@/types'

const queryId = `search-organization-by-deployment-id`

const mapStateToProps = (state: ReduxState): StateProps => ({
  organizations: getOrganizations(state),
  deployments: getStackDeploymentsFromSearch(state, queryId)?.deployments || [],
  searchOrganizationByIdRequest: (organizationId) =>
    fetchOrganizationRequest(state, organizationId),
  searchOrganizationsByNameRequest: fetchOrganizationsRequest(state),
  searchOrganizationByDeploymentIdRequest: searchStackDeploymentsRequest(state, queryId),
  resetSearchOrganizationByIdRequest: (organizationId: string) =>
    resetFetchOrganizationRequest(organizationId),
  resetSearchOrganizationsByNameRequest: () => resetFetchOrganizationsRequest(),
  resetSearchOrganizationByDeploymentIdRequest: () => resetSearchStackDeploymentsRequest(queryId),
  lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchOrganizationById: (organizationId: string) =>
    dispatch(fetchOrganization({ organizationId })),
  searchOrganizationsByName: (organizationName: string) =>
    dispatch(fetchOrganizations({ organizationName })),
  searchOrganizationByDeploymentId: (deploymentId: string) =>
    dispatch(searchDeployments({ queryId, query: getDeploymentByIdQuery({ deploymentId }) })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Organizations)
