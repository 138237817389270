/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiStat, EuiToolTip } from '@elastic/eui'
import type { EuiStatProps } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

const minWidth = '100px'

interface Props extends EuiStatProps {
  tooltip: ReactNode
}

const ClusterShardCount: FunctionComponent<Props> = ({
  tooltip,
  title,
  titleColor,
  description,
  ...statProps
}) => {
  const label = (
    <EuiFlexGroup gutterSize='s'>
      <EuiFlexItem grow={false}>{description}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiToolTip content={tooltip}>
          <EuiIcon type='questionInCircle' color='text' />
        </EuiToolTip>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  return (
    <EuiStat
      title={title}
      titleColor={titleColor}
      description={label}
      descriptionElement='div'
      style={{ minWidth }}
      {...statProps}
    />
  )
}

export default ClusterShardCount
