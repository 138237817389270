/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { PureComponent, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiModalFooter,
  EuiText,
  EuiSpacer,
  EuiHorizontalRule,
  EuiImage,
  EuiPanel,
  EuiTitle,
} from '@elastic/eui'

import unpackFeaturesIllustration from '@/files/illustration-feature-packed.svg'
import unpackFeaturesIllustrationDark from '@/files/illustration-feature-packed-dark.svg'
import { isTrialNotStartedUser } from '@/lib/billing'

import UsageSection from './UsageSection'
import HelperLinks from './HelperLinks'
import RemainingTrial from './RemainingTrial'
import SubscribeButton from './SubscribeButton'
import ExtendTrialSection from './ExtendTrialSection'

import type { WrappedComponentProps } from 'react-intl'
import type { UserProfile, Theme, StackDeployment } from '@/types'
import type { DeploymentTemplateInfoV2 } from '@/lib/api/v1/types'

export interface Props {
  closeModal: () => void
  profile: UserProfile
  theme: Theme
  deployment: StackDeployment
  deploymentTemplate: DeploymentTemplateInfoV2
}

class TrialSummaryWithDeployment extends PureComponent<Props & WrappedComponentProps> {
  render() {
    const {
      theme,
      deployment,
      deploymentTemplate,
      profile,
      closeModal,
      intl: { formatMessage },
    } = this.props
    const hasExpiredTrial = profile?.hasExpiredTrial

    return (
      <Fragment>
        <EuiModalHeader>
          <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='none'>
            <EuiFlexItem grow={false}>
              <EuiText size='s' data-test-id='trial-summary-modal.title'>
                <h3>{this.renderModalTitle()}</h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <RemainingTrial profile={profile} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>

        <EuiSpacer size='s' />

        <EuiModalBody css={css({ minHeight: 360 })}>
          <EuiFlexGroup gutterSize='xl' css={css({ height: '100%' })}>
            <EuiFlexItem grow={7}>
              <UsageSection
                deployment={deployment}
                deploymentTemplate={deploymentTemplate}
                level={profile?.level || 'standard'}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiPanel grow={false}>
                <EuiTitle size='xxs'>
                  <h5>
                    <FormattedMessage
                      id='trial-summary-modal.link-section.title'
                      defaultMessage='Adjust and manage price'
                    />
                  </h5>
                </EuiTitle>
                <HelperLinks
                  deploymentId={deployment.id}
                  trialStarted={!isTrialNotStartedUser(profile)}
                  trialExpired={profile?.hasExpiredTrial}
                />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup direction='column'>
            <EuiHorizontalRule margin='l' />

            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='m'>
              <EuiFlexItem grow={false} css={css({ alignItems: 'center' })}>
                <EuiImage
                  src={
                    { dark: unpackFeaturesIllustrationDark, light: unpackFeaturesIllustration }[
                      theme
                    ]
                  }
                  width={62}
                  height={62}
                  alt={formatMessage({
                    id: 'trial-summary-modal.features-box.illustration',
                    defaultMessage: 'An illustration of a packed box with features.',
                  })}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText size='s' color='subdued'>
                  <FormattedMessage
                    id='trial-summary-modal.promo-text'
                    defaultMessage='Love what you’re using? Want access to all the features? Subscribe now.'
                  />
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <SubscribeButton onSendBillingDetailsSuccess={closeModal} />
              </EuiFlexItem>
            </EuiFlexGroup>

            {hasExpiredTrial && (
              <div css={css({ textAlign: 'center' })}>
                <EuiHorizontalRule margin='l' />
                <ExtendTrialSection profile={profile} onTrialExtensionSuccess={closeModal} />
              </div>
            )}
          </EuiFlexGroup>
        </EuiModalFooter>
      </Fragment>
    )
  }

  renderModalTitle = (): JSX.Element => {
    const { profile } = this.props
    const hasExpiredTrial = profile?.hasExpiredTrial

    if (hasExpiredTrial) {
      return (
        <FormattedMessage
          id='trial-summary-modal.expired-trial-title'
          defaultMessage='Expired trial summary'
        />
      )
    }

    return (
      <FormattedMessage
        id='trial-summary-modal.active-trial-title'
        defaultMessage='Trial summary'
      />
    )
  }
}

export default injectIntl(TrialSummaryWithDeployment)
