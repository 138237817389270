/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { uploadStackPackRequest } from '../../../../reducers'
import {
  fetchVersions,
  uploadStackPack,
  resetUploadStackPackRequest,
} from '../../../../actions/elasticStack'

import UploadStackPackButton from './UploadStackPackButton'

import type { AsyncRequestState } from '../../../../types'

type StateProps = {
  uploadStackPackRequest: AsyncRequestState
}

type DispatchProps = {
  uploadStackPack: (regionId: string, stackPack: File) => void
  resetUploadStackPackRequest: (regionId: string) => void
  fetchVersions: (regionId: string) => void
}

type ConsumerProps = {
  regionId: string
}

const mapStateToProps = (state, { regionId }: ConsumerProps): StateProps => ({
  uploadStackPackRequest: uploadStackPackRequest(state, regionId),
})

const mapDispatchToProps: DispatchProps = {
  uploadStackPack,
  resetUploadStackPackRequest,
  fetchVersions,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadStackPackButton)
