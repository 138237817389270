/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiTextColor } from '@elastic/eui'

import {
  isOwnAccountRelationship,
  getTrustRelationshipDisplayName,
} from '@/lib/stackDeployments/trustRelationships'

import type { AllProps } from './types'

const EnvironmentName: React.FunctionComponent<AllProps> = ({
  currentAccount,
  trustRelationship,
  environmentTrustRelationships,
}) => {
  const displayName = getTrustRelationshipDisplayName(
    trustRelationship,
    environmentTrustRelationships || [],
  )

  const yourOrgSuffix = currentAccount &&
    isOwnAccountRelationship(trustRelationship, currentAccount.id) && (
      <EuiTextColor color='subdued' style={{ whiteSpace: `nowrap` }}>
        <FormattedMessage
          id='deploymentTrustManagement.table.ownOrganization'
          defaultMessage='(your organization)'
        />
      </EuiTextColor>
    )

  return (
    <EuiText size='s'>
      {displayName} {yourOrgSuffix}
    </EuiText>
  )
}

export default EnvironmentName
