/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  approachingEolTitle: {
    id: `eol-version-approaching-title`,
    defaultMessage: `Deployment version about to reach end-of-life`,
  },
  approachingEol: {
    id: `eol-version-approaching`,
    defaultMessage: `Elastic Stack version {version} is about to reach end-of-life in {days} { days, plural, one {day} other {days} }. We recommend upgrading to a newer version to ensure we can continue and give you the best experience. {moreInfo}`,
  },
  reachedEolTitle: {
    id: `eol-version-reached-title`,
    defaultMessage: `Deployment version reached end-of-life`,
  },
  reachedEol: {
    id: `eol-version-reached`,
    defaultMessage: `Elastic Stack version {version} has reached end-of-life. You should upgrade your deployment to a newer version to ensure we can continue and offer you the best support experience. {moreInfo}`,
  },
  platformVersionReachedEol: {
    id: `eol-version-platform-warning`,
    defaultMessage: `Elastic Stack version {version} is no longer maintained and will not be supported on ECE 3.0. You must upgrade your deployment to Elastic Stack version 6.0 or above before upgrading to ECE 3.0, and we strongly recommend upgrading to a maintained version. More information is available on the Elastic products {eolLink} page.`,
  },
  moreInfo: {
    id: `eol-version-more-info`,
    defaultMessage: `More information is available on the Elastic products {eolLink} page.`,
  },
  linkText: {
    id: `eol-version.link-text`,
    defaultMessage: `end-of-life`,
  },
})
