/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getConfigForKey } from '../../../store'
import { satisfies } from '../../semver'

import { getEsPlanFromGet } from './fundamentals'
import { isDedicatedML, isData, isFrozen, supportsFrozenTierAutoscaling } from './nodeRoles'
import { getEsPlan } from './stackDeployment'

import type {
  DeploymentUpdateRequest,
  DeploymentCreateRequest,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
  TopologySize,
} from '../../api/v1/types'
import type { StackDeployment, AnyClusterPlanInfo, AnyTopologyElement } from '../../../types'

export function isAutoscalingAvailable(version: string): boolean {
  const minimumAutoscalingVersion = getConfigForKey('MINIMUM_AUTOSCALING_VERSION')

  return satisfies(version, `>=${minimumAutoscalingVersion}`)
}

export function canEnableAutoscaling({
  deploymentTemplate,
  version,
}: {
  deploymentTemplate?: DeploymentTemplateInfoV2
  version: string
}): boolean {
  if (!isAutoscalingAvailable(version)) {
    // If it's an unsupported version, we don't need to consult the template
    return false
  }

  if (!deploymentTemplate) {
    // If the deployment template isn't loaded yet, we can't be sure
    return false
  }

  return isAutoscalingSupportedInTemplate({
    deploymentTemplate: deploymentTemplate.deployment_template,
  })
}

export function isAutoscaleableTier({
  topologyElement,
  version,
}: {
  topologyElement: AnyTopologyElement
  version?: string
}): boolean {
  if (isDedicatedML({ topologyElement })) {
    return true
  }

  if (!isData({ topologyElement })) {
    return false
  }

  if (isFrozen({ topologyElement })) {
    const esTopologyElement = topologyElement as ElasticsearchClusterTopologyElement

    if (esTopologyElement.autoscaling_max === undefined) {
      return false
    }

    return supportsFrozenTierAutoscaling({ version })
  }

  return true
}

export function isAutoscalingSupportedInTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate: DeploymentCreateRequest
}): boolean {
  const supportedAtDeploymentLevel =
    typeof deploymentTemplate.settings?.autoscaling_enabled === 'boolean'
  const plan = getEsPlan({ deployment: deploymentTemplate })
  const supportedAtEsLevel = typeof plan?.autoscaling_enabled === 'boolean'

  return supportedAtDeploymentLevel || supportedAtEsLevel
}

export function isAutoscalingEnabledOnGet({
  deployment,
}: {
  deployment: StackDeployment
}): boolean {
  if (deployment.settings?.autoscaling_enabled !== undefined) {
    return deployment.settings.autoscaling_enabled
  }

  const plan = getEsPlanFromGet({ deployment })
  return Boolean(plan?.autoscaling_enabled)
}

export function isAutoscalingEnabled({
  deployment,
}: {
  deployment: DeploymentUpdateRequest | DeploymentCreateRequest
}): boolean {
  if (deployment.settings?.autoscaling_enabled !== undefined) {
    return deployment.settings.autoscaling_enabled
  }

  const plan = getEsPlan({ deployment })
  return Boolean(plan?.autoscaling_enabled)
}

export function getMaxedOutCapacityTopologyElements({
  deployment,
}: {
  deployment: StackDeployment
}): ElasticsearchClusterTopologyElement[] {
  const maxedOutCapacityTopologyElements: ElasticsearchClusterTopologyElement[] = []

  const plan = getEsPlanFromGet({ deployment })

  if (!plan || !plan.cluster_topology) {
    return maxedOutCapacityTopologyElements
  }

  plan.cluster_topology.forEach((topologyElement) => {
    if (!topologyElement.autoscaling_max || !topologyElement.size) {
      return
    }

    if (isDedicatedML({ topologyElement })) {
      if (topologyElement.autoscaling_max.value === topologyElement.autoscaling_min!.value) {
        return
      }
    }

    if (topologyElement.autoscaling_max.value === 0 && topologyElement.size.value === 0) {
      return
    }

    if (topologyElement.autoscaling_max.value <= topologyElement.size.value) {
      maxedOutCapacityTopologyElements.push(topologyElement)
    }
  })

  return maxedOutCapacityTopologyElements
}

export function displayAutoscalingLimitReached({
  size,
  autoscalingMax,
  autoscalingMin,
}: {
  size: number
  autoscalingMax: number
  autoscalingMin?: number
}): boolean {
  if (!isFinite(size)) {
    return false
  }

  if (autoscalingMax === 0 && size === 0) {
    return false
  }

  if (autoscalingMin === autoscalingMax) {
    return false
  }

  if (size >= autoscalingMax) {
    return true
  }

  return false
}

export function isAutoscalingGeneratedPlanAttempt({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): boolean {
  return planAttempt.source?.facilitator === `autoscaling`
}

export function isAutoscalingTerminationPlanAttempt({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): boolean {
  return planAttempt.source?.action === `terminate-deployment`
}

export function getAutoscalingMaxForInstanceTemplate({
  instanceConfiguration,
  resource,
}: {
  instanceConfiguration: InstanceConfiguration
  resource: 'memory' | 'storage'
}): TopologySize {
  const maximumSize =
    instanceConfiguration.discrete_sizes.sizes[
      instanceConfiguration.discrete_sizes.sizes.length - 1
    ]
  const defaultSize = instanceConfiguration.discrete_sizes.default_size

  return {
    resource,
    value: maximumSize || defaultSize,
  }
}

export function isTopologyConfiguredForAutoscaling(topologyElements: AnyTopologyElement[]) {
  return (topologyElements as ElasticsearchClusterTopologyElement[]).some(
    (topologyElement) =>
      typeof topologyElement.autoscaling_min?.value === 'number' ||
      typeof topologyElement.autoscaling_max?.value === 'number',
  )
}
