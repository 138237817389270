/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import { redirectAfterLogin, fetchAuthMethods, loginAndRedirect } from '../../../../../actions/auth'
import { fetchAuthMethodsRequest, getLoginRequest, getAuthMethods } from '../../../../../reducers'
import { isFeatureActivated } from '../../../../../selectors'
import Feature from '../../../../../lib/feature'
import { getBearerToken } from '../../../../../lib/auth'

import BasicLogin from './BasicLogin'

import type { UserLogin, AdminLogin } from './BasicLogin'
import type { RouteComponentProps } from 'react-router'
import type { AsyncRequestState, ReduxState, ThunkAction } from '../../../../../types'
import type { AvailableAuthenticationMethods } from '../../../../../lib/api/v1/types'

type StateProps = {
  redirectTo?: string
  newBearerToken: string | null
  loginRequest: AsyncRequestState
  authMethods: AvailableAuthenticationMethods | null
  fetchAuthMethodsRequest: AsyncRequestState
}

type DispatchProps = {
  loginAndRedirect: (args: {
    redirectTo?: string
    oktaRedirectUrl?: string
    credentials: UserLogin | AdminLogin
  }) => ThunkAction<Promise<any>>
  redirectAfterLogin: (redirectTo: string) => () => void
  fetchAuthMethods: () => void
}

type QueryStringParameters = {
  redirectTo?: string
  fromURI?: string
}

type ConsumerProps = RouteComponentProps<QueryStringParameters>

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { hash } = location
  const { redirectTo } = parse(location.search.slice(1))
  const basicAuthProxyPassEnabled = isFeatureActivated(state, Feature.basicAuthProxyPass)
  const newBearerToken = basicAuthProxyPassEnabled ? getBearerToken(hash) : null

  return {
    redirectTo: typeof redirectTo === `string` ? redirectTo : undefined,
    newBearerToken,
    loginRequest: getLoginRequest(state),
    authMethods: getAuthMethods(state),
    fetchAuthMethodsRequest: fetchAuthMethodsRequest(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  loginAndRedirect,
  redirectAfterLogin,
  fetchAuthMethods,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(BasicLogin)
