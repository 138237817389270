/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import { DELETE_EMAIL_SUPPRESSIONS } from '../../constants/actions'

type EmailDomain = 'transactional' | 'watchers'

// do not use `deleteSaasUserEmailSuppressionsUrl` from `lib/api/v1/urls.ts`
// because of its incorrect path generation (missing `saas` part)
// we are replacing some paths we get from swagger because of some legacy incorrect url
// coming from the python layer
const deleteSaasUserEmailSuppressionsUrl = ({
  emailDomain,
  userId,
}: {
  emailDomain: string
  userId: string
}): string => `api/v1/saas/users/${userId}/email/${emailDomain}/suppressions`

export function deleteEmailSuppressions(userId: string, emailDomain: EmailDomain) {
  const url = deleteSaasUserEmailSuppressionsUrl({
    emailDomain,
    userId,
  })

  return asyncRequest({
    type: DELETE_EMAIL_SUPPRESSIONS,
    method: `DELETE`,
    url,
    meta: { userId, emailDomain },
    crumbs: [userId, emailDomain],
  })
}
