/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchOrganizationMemberships } from '@/actions/organizations'
import { getProfile } from '@/apps/userconsole/reducers'
import { fetchOrganizationMembershipsRequest, getOrganizationMembers } from '@/reducers'

import DeploymentActivityTable from './DeploymentActivityTable'

import type { AsyncRequestState, ReduxState } from '@/types'
import type { OrganizationMembership } from '@/lib/api/v1/types'

type StateProps = {
  organizationId: string | undefined
  organizationMembers: OrganizationMembership[]
  fetchOrganizationMembersRequest: ({
    organizationId,
  }: {
    organizationId: string
  }) => AsyncRequestState
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const profile = getProfile(state)
  const organizationId = profile?.organization_id

  return {
    organizationId,
    organizationMembers: getOrganizationMembers(state, organizationId),
    fetchOrganizationMembersRequest: ({ organizationId: id }) =>
      fetchOrganizationMembershipsRequest(state, id),
  }
}

interface DispatchProps {
  fetchOrganizationMemberships: ({ organizationId }: { organizationId: string }) => void
}

const mapDispatchToProps: DispatchProps = {
  fetchOrganizationMemberships,
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentActivityTable)
