/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiBadge,
  EuiContextMenuPanel,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiLoadingSpinner,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiButton } from '../../../cui'
import AllocatorVacate from '../Vacate'
import AllocatorSummary from '../Summary'
import AllocatorMaintenanceModeButton from '../MaintenanceModeButton'
import AllocatorDeleteButton from '../AllocatorDeleteButton'
import AllocatorTags from '../AllocatorTags'
import { AllocatorExternalLinksList } from '../../StackDeployments/ResourceExternalLinks/ExternalLinksList'
import ResourceComments from '../../ResourceComments'

import type { Allocator, AsyncRequestState, Runner } from '../../../types'

export interface Props {
  allocator?: Allocator
  allocatorRequest: AsyncRequestState
  runner: Runner
  startManagingRoles: () => void
}

type State = {
  managingAllocator: boolean
}

class AllocatorOverview extends Component<Props, State> {
  state: State = {
    managingAllocator: false,
  }

  render() {
    const { allocator, allocatorRequest, runner, startManagingRoles } = this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='l' alignItems='center' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiTitle>
              <h3>
                <FormattedMessage id='allocator-overview.title' defaultMessage='Allocator' />
              </h3>
            </EuiTitle>
          </EuiFlexItem>

          {this.renderMainAllocatorActions()}
        </EuiFlexGroup>

        <EuiSpacer size='s' />

        <EuiText color='subdued'>
          <FormattedMessage
            id='allocator-overview.description'
            defaultMessage='Provides computing resources to Elastic Stack deployments by managing the lifecycle of instances.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <AllocatorSummary
          allocator={allocator}
          allocatorRequest={allocatorRequest}
          runner={runner}
          startManagingRoles={startManagingRoles}
        />

        {this.renderAllocatorBits()}
      </Fragment>
    )
  }

  renderMainAllocatorActions() {
    const { allocator, allocatorRequest } = this.props
    const { managingAllocator } = this.state

    if (allocatorRequest.error) {
      return null
    }

    const loading = !allocator

    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          {loading && (
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size='m' />
            </EuiFlexItem>
          )}

          <EuiFlexItem grow={false}>
            <EuiPopover
              id='popover'
              button={
                <CuiButton
                  iconType='arrowDown'
                  iconSide='right'
                  onClick={this.openManageAllocatorMenu}
                  disabled={loading}
                >
                  <FormattedMessage
                    id='allocator-overview.manage-allocator'
                    defaultMessage='Manage allocator'
                  />
                </CuiButton>
              }
              isOpen={managingAllocator}
              closePopover={this.closeManageAllocatorMenu}
              anchorPosition='downRight'
              panelPaddingSize='none'
            >
              {allocator && (
                <EuiContextMenuPanel>
                  <EuiFlexGroup gutterSize='none' alignItems='flexStart' direction='column'>
                    <EuiFlexItem grow={false}>
                      <AllocatorMaintenanceModeButton allocator={allocator} />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                      <AllocatorDeleteButton allocator={allocator} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiContextMenuPanel>
              )}
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  renderAllocatorBits() {
    const { allocator, allocatorRequest } = this.props

    if (allocatorRequest.error) {
      return null
    }

    if (!allocator) {
      return <EuiLoadingContent />
    }

    return (
      <Fragment>
        {this.renderExternalLinks()}

        <EuiSpacer size='l' />

        {this.renderInstancesSection()}

        <EuiSpacer size='l' />

        {this.renderAttributes()}

        <EuiSpacer size='l' />

        {this.renderComments()}
      </Fragment>
    )
  }

  renderExternalLinks() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const { externalLinks } = allocator

    if (externalLinks.length === 0) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <EuiTitle size='s'>
          <h5>
            <FormattedMessage id='allocator-external-links' defaultMessage='External links' />
          </h5>
        </EuiTitle>

        <EuiSpacer size='s' />

        <AllocatorExternalLinksList links={externalLinks} />
      </Fragment>
    )
  }

  renderInstancesSection() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const { regionId, id } = allocator

    return <AllocatorVacate regionId={regionId} hostId={id} />
  }

  renderAttributes() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='flexStart'>
          <EuiFlexItem>
            <AllocatorTags allocator={allocator} />
          </EuiFlexItem>

          <EuiFlexItem>{this.renderAllocatorFeatures()}</EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderAllocatorFeatures() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const { attributes } = allocator
    const { features = [] } = attributes
    const allocatorFeatures = features.filter(Boolean)

    return (
      <div>
        <EuiTitle size='s'>
          <h5>
            <FormattedMessage
              id='allocator-overview.features-label'
              defaultMessage='Allocator features'
            />
          </h5>
        </EuiTitle>

        <EuiSpacer size='m' />

        {isEmpty(allocatorFeatures) ? (
          <FormattedMessage
            id='allocator-overview.allocator-has-no-features'
            defaultMessage='This allocator has no special features.'
          />
        ) : (
          <EuiFlexGrid gutterSize='s'>
            {allocatorFeatures.map((feature) => (
              <EuiFlexItem key={feature} grow={false}>
                <EuiBadge color='hollow'>{feature}</EuiBadge>
              </EuiFlexItem>
            ))}
          </EuiFlexGrid>
        )}
      </div>
    )
  }

  renderComments() {
    const { allocator } = this.props

    if (!allocator) {
      return null
    }

    const { regionId, id } = allocator

    return (
      <Fragment>
        <EuiTitle size='s'>
          <h5>
            <FormattedMessage id='allocator-overview.comments-label' defaultMessage='Comments' />
          </h5>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText size='s' color='subdued'>
          <FormattedMessage
            id='allocator-overview.comments-description'
            defaultMessage='Leave notes for yourself for later or for other system administrators.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        <ResourceComments
          spacerBefore={true}
          resourceType='allocator'
          regionId={regionId}
          resourceId={id}
        />
      </Fragment>
    )
  }

  openManageAllocatorMenu = () => this.setState({ managingAllocator: true })

  closeManageAllocatorMenu = () => this.setState({ managingAllocator: false })
}

export default AllocatorOverview
