/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTitle } from '@elastic/eui'

import DocLink from '@/components/DocLink'

import LeaveOrganizationButton from './LeaveOrganizationButton'
import InviteOrganizationMembersButton from './InviteOrganizationMembersFlyout/InviteOrganizationMembersButton'

import type { OrganizationMemberRow } from './types'
import type { FunctionComponent } from 'react'
import type { Organization } from '../../../lib/api/v1/types'

const EmptyOrganizationMembers: FunctionComponent<{
  organization?: Organization
  organizationMemberRows: OrganizationMemberRow[]
}> = ({ organization, organizationMemberRows }) => (
  <EuiPanel>
    <EuiFlexGroup direction='column'>
      <EuiFlexItem>
        <EuiTitle size='m'>
          <h2>
            <FormattedMessage
              id='organization.invite-organization-members-flyout.invite-members'
              defaultMessage='Invite members'
            />
          </h2>
        </EuiTitle>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText>
          <FormattedMessage
            id='organization.organization-members.invite-members-description'
            defaultMessage='Let them join your organization, share your resources, and start exploring Elastic Cloud together. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='organizationsDocLink'>
                  <FormattedMessage
                    id='organization.organization-members.learn-more'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        </EuiText>
      </EuiFlexItem>

      {organization && (
        <EuiFlexItem>
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}>
              <InviteOrganizationMembersButton
                organizationId={organization.id}
                organizationMemberRows={organizationMemberRows}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <LeaveOrganizationButton organization={organization} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  </EuiPanel>
)

export default EmptyOrganizationMembers
