/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiText } from '@elastic/eui'

import { CuiHelpTipIcon } from '../../../cui'
import { getSliderPrettyName } from '../../../lib/sliders'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { AllocatorInstance } from '../../../types'

type Props = {
  intl: IntlShape
  instance: AllocatorInstance
  clusterInstances: AllocatorInstance[]
  isClusterHeader: boolean
}

const messages = defineMessages({
  multipleNodesHelp: {
    id: `allocator-vacate-nodes-table.multiple-nodes-help-aria`,
    defaultMessage: `Learn more about moving instances out of an allocator`,
  },
})

const InstanceName: FunctionComponent<Props> = ({
  intl: { formatMessage },
  instance,
  clusterInstances,
  isClusterHeader,
}) => {
  const sliderPrettyName = (
    <FormattedMessage {...getSliderPrettyName({ sliderInstanceType: instance.kind })} />
  )

  if (isClusterHeader) {
    return (
      <EuiText color='subdued' size='xs'>
        <em>
          <FormattedMessage
            id='allocator-vacate-nodes-table.cluster-nodes'
            defaultMessage='This deployment has {amount} {sliderPrettyName} instances on this allocator'
            values={{
              amount: clusterInstances.length,
              sliderPrettyName,
            }}
          />
        </em>

        {` `}

        <CuiHelpTipIcon
          aria-label={formatMessage(messages.multipleNodesHelp)}
          textColor='subdued'
          textSize='xs'
        >
          <FormattedMessage
            id='allocator-vacate-nodes-table.multiple-nodes-help'
            defaultMessage='The entire {sliderPrettyName} cluster, instead of individual instances, gets moved to ensure it remains in a consistent state.'
            values={{ sliderPrettyName }}
          />
        </CuiHelpTipIcon>
      </EuiText>
    )
  }

  return <Fragment>{instance.instanceName}</Fragment>
}

export default injectIntl(InstanceName)
