/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

interface ToBillingPeriodFormat {
  periodStartDate: string
  periodEndDate: string
}

export function toBillingPeriodFormat({
  periodStartDate,
  periodEndDate,
}: ToBillingPeriodFormat): string {
  const startDate = moment.utc(periodStartDate)
  const endDate = moment.utc(periodEndDate)

  if (startDate.isSame(endDate, 'year')) {
    return `${startDate.format('MMM')} ${startDate.format('DD')} – ${endDate.format('ll')}`
  }

  return `${startDate.format('ll')} – ${endDate.format('ll')}`
}

export function getFullYear(date: string): number {
  return new Date(date).getFullYear()
}
