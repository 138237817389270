/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type {
  ClauseConnector as ClauseConnectorType,
  InnerClause,
  OuterClause,
} from '../../../../../types/instanceConfigurationTypes'

type Props = {
  isRoot?: boolean
  clause: OuterClause | InnerClause
  setConnector: (connector: ClauseConnectorType) => void
}

const ClauseConnector: FunctionComponent<Props> = ({ isRoot, clause, setConnector }) => {
  const opposite = {
    and: `or` as ClauseConnectorType,
    or: `and` as ClauseConnectorType,
  }

  return (
    <div className='topologyFilterAllocators-filterClause-connector'>
      {isRoot ? (
        <FormattedMessage
          id='instance-configuration-filter-allocators.scope'
          defaultMessage='Scope'
        />
      ) : (
        <EuiLink onClick={() => setConnector(opposite[clause.connector])}>
          {clause.connector === `and` && (
            <FormattedMessage
              id='instance-configuration-filter-allocators.and'
              defaultMessage='And'
            />
          )}

          {clause.connector === `or` && (
            <FormattedMessage
              id='instance-configuration-filter-allocators.or'
              defaultMessage='Or'
            />
          )}
        </EuiLink>
      )}
    </div>
  )
}

export default ClauseConnector
