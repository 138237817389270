/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { BoolQuery, QueryContainer, SearchRequest } from '../api/v1/types'
import type { RegionId } from '../../types'
import type { InnerClause, OuterClause } from '../../types/instanceConfigurationTypes'

export function getAllocatorFilterPayload(
  _regionId: RegionId,
  clauses: InnerClause[] | OuterClause[],
): QueryContainer | null {
  const complexQuery = getComplexQuery(clauses)

  if (Object.keys(complexQuery).length === 0) {
    return null
  }

  return {
    bool: complexQuery,
  }
}

export function clausesToAllocatorFilterPayload(
  _regionId: RegionId,
  clauses: InnerClause[] | OuterClause[],
): SearchRequest {
  return {
    size: 1000,
    query: {
      bool: {
        ...getComplexQuery(clauses),
      },
    },
  }
}

function getComplexQuery(clauses: InnerClause[] | OuterClause[]): BoolQuery {
  if (!clauses.length) {
    return {}
  }

  const { bool } = getComplexQueryOuterClauses(clauses)

  return bool!
}

function getComplexQueryOuterClauses(clauses: InnerClause[] | OuterClause[]) {
  return toComplexQueryClauses(clauses, (outerClause: OuterClause) =>
    toComplexQueryClauses(outerClause.innerClauses, (innerClause: InnerClause) => ({
      nested: {
        path: `metadata`,
        query: getInnerQuery(innerClause),
      },
    })),
  )
}

function toComplexQueryClauses(
  clauses: InnerClause[] | OuterClause[],
  mapper: (obj: InnerClause | OuterClause) => QueryContainer,
): QueryContainer {
  const conditions = {
    or: `should`,
    and: `must`,
  }

  const query = { bool: {} }

  for (const clause of clauses) {
    const { connector } = clause
    const conditional = conditions[connector]

    if (!conditional) {
      continue
    }

    let boolConditional = query.bool[conditional]

    if (boolConditional == null) {
      boolConditional = query.bool[conditional] = []
    }

    boolConditional.push(mapper(clause))
  }

  return query
}

function getInnerQuery({ key, value }: InnerClause): QueryContainer {
  if (value === undefined || value === ``) {
    return { term: { 'metadata.key': { value: key } } }
  }

  return {
    bool: {
      must: [
        { term: { 'metadata.key': { value: key } } },
        { term: { 'metadata.value.keyword': { value } } },
      ],
    },
  }
}
