/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { keys } from 'lodash'

import { EuiLoadingContent, EuiTitle, EuiText, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../../cui'

import PlatformVisualizationZone from './PlatformVisualizationZone'
import { combinePlatformData } from './combinePlatformData'

import type {
  AllocatorSearchResult,
  AsyncRequestState,
  Region,
  RegionId,
  RegionProxies,
  Runner,
} from '../../../types'

export interface Props {
  allocatorSearchResults?: AllocatorSearchResult[]
  fetchProxies: () => Promise<any>
  fetchRunners: () => Promise<any>
  proxies?: RegionProxies
  proxiesRequest: AsyncRequestState
  region?: Region
  regionId: RegionId
  runnerSearchResults?: Runner[]
  searchAllocatorsRequest: AsyncRequestState
  searchRunnersRequest: AsyncRequestState
}

export default class InfrastructureVisualization extends Component<Props> {
  render() {
    return (
      <div>
        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='platform-visualization.title'
              defaultMessage='Role distribution'
            />
          </h3>
        </EuiTitle>

        <EuiText color='subdued' size='s'>
          <FormattedMessage
            id='platform-visualization.description'
            defaultMessage='Hosts and their assigned roles by zones. Can be filtered by health and/or by role.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const {
      allocatorSearchResults,
      proxies,
      proxiesRequest,
      region,
      runnerSearchResults,
      searchAllocatorsRequest,
      searchRunnersRequest,
    } = this.props

    if (!region) {
      return (
        <div data-test-id='fetching-region'>
          <EuiLoadingContent />
        </div>
      )
    }

    if (!allocatorSearchResults) {
      if (searchAllocatorsRequest.error) {
        return (
          <div data-test-id='error-fetching-allocators'>
            <CuiAlert type='error'>{searchAllocatorsRequest.error}</CuiAlert>
          </div>
        )
      }

      return (
        <div data-test-id='fetching-allocators'>
          <EuiLoadingContent />
        </div>
      )
    }

    if (!runnerSearchResults) {
      if (searchRunnersRequest.error) {
        return (
          <div data-test-id='error-fetching-runners'>
            <CuiAlert type='error'>{searchRunnersRequest.error}</CuiAlert>
          </div>
        )
      }

      return (
        <div data-test-id='fetching-runners'>
          <EuiLoadingContent />
        </div>
      )
    }

    if (!proxies) {
      if (proxiesRequest.error) {
        return (
          <div data-test-id='error-fetching-proxies'>
            <CuiAlert type='error'>{proxiesRequest.error}</CuiAlert>
          </div>
        )
      }

      return (
        <div data-test-id='fetching-proxies'>
          <EuiLoadingContent />
        </div>
      )
    }

    return this.renderVisualization()
  }

  renderVisualization() {
    const { runnerSearchResults, proxies, allocatorSearchResults, region, regionId } = this.props

    const data = combinePlatformData(
      runnerSearchResults || [],
      proxies ? proxies.proxies : [],
      allocatorSearchResults || [],
      region ? region.coordinators.coordinators : {},
    )

    // For some weird legacy reason we sometimes get a zone called 'undefined'. Remove it.
    const zones = keys(data)
      .filter((key) => key !== `undefined`)
      .sort()

    return (
      <div className='platformVisualization-vizContainer'>
        {zones.map((zone) => (
          <PlatformVisualizationZone
            key={zone}
            regionId={regionId}
            zoneId={zone}
            zone={data[zone]}
          />
        ))}
      </div>
    )
  }
}
