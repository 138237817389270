/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchLicenseRequest, getLicense, getRegion, getTlsCertificate } from '../../../reducers'
import { getRunnerSearchResults } from '../../../apps/adminconsole/reducers'

import PlatformHealthProblems from './PlatformHealthProblems'

import type { ReduxState } from '../../../types/redux'
import type { AsyncRequestState, License, Region, Runner } from '../../../types'
import type { TlsPublicCertChain } from '../../../lib/api/v1/types'

interface StateProps {
  fetchLicenseRequest: AsyncRequestState
  license?: License | null
  region?: Region
  runners: Runner[]
  uiTlsChain?: TlsPublicCertChain
  proxyTlsChain?: TlsPublicCertChain
  internalCasTlsCerts?: TlsPublicCertChain
}

interface DispatchProps {}

interface ConsumerProps {
  regionId: string
}

const mapStateToProps: (state: ReduxState, props: ConsumerProps) => StateProps = (
  state,
  { regionId },
) => ({
  fetchLicenseRequest: fetchLicenseRequest(state, regionId),
  license: getLicense(state, regionId),
  region: getRegion(state, regionId),
  runners: getRunnerSearchResults(state, regionId, 'runners'),
  uiTlsChain: getTlsCertificate(state, regionId, 'ui'),
  proxyTlsChain: getTlsCertificate(state, regionId, 'proxy'),
  internalCasTlsCerts: getTlsCertificate(state, regionId, 'internalca'),
})

const mapDispatchToProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PlatformHealthProblems)
