/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiComboBox, EuiFormRow } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

const DeploymentSelect: FunctionComponent<Props & WrappedComponentProps> = ({
  onChange,
  isLoading,
  options = [],
  selectedOption,
  intl: { formatMessage },
}) => (
  <EuiFormRow
    fullWidth={true}
    label={formatMessage({
      id: 'billing-usage.filters.deployments',
      defaultMessage: 'Deployments',
    })}
  >
    <EuiComboBox
      aria-label={formatMessage({
        id: 'billing-usage.filters.deployments',
        defaultMessage: 'Deployments',
      })}
      placeholder={formatMessage({
        id: 'billing-usage.filters.all-deployments',
        defaultMessage: 'Deployments (All)',
      })}
      options={options}
      selectedOptions={selectedOption ? [selectedOption] : []}
      singleSelection={{ asPlainText: true }}
      onChange={(values) => onChange({ selectedDeploymentId: values[0].value })}
      isClearable={false}
      isDisabled={isLoading}
      isLoading={isLoading}
      fullWidth={true}
      style={{ maxWidth: '450px' }}
    />
  </EuiFormRow>
)

export default injectIntl(DeploymentSelect)
