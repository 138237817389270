/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import { CuiTable } from '../../../cui'
import {
  getAccountTrustRelationships,
  getDirectTrustRelationships,
  getExternalTrustRelationships,
  getTrustRelationshipId,
} from '../../../lib/stackDeployments/trustRelationships'
import { getTargetEnvironmentType } from '../helpers'

import Allowlist from './Allowlist'
import DeploymentTrustRelationshipEditButton from './EditButton'
import DeploymentTrustRelationshipDeleteButton from './DeleteButton'
import EnvironmentName from './EnvironmentName'

import type { AnyTrustRelationship } from '@/types'
import type { CuiTableColumn } from '../../../cui'
import type { AllProps as Props } from './types'

class DeploymentTrustRelationshipTable extends Component<Props> {
  componentWillUnmount(): void {
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element | null {
    const { deployment, trustRelationships } = this.props

    const orgIdColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.environmentNameHeader'
          defaultMessage='Environment name'
        />
      ),
      render: (trustRelationship) => (
        <EnvironmentName
          trustRelationship={trustRelationship}
          environmentTrustRelationships={trustRelationships}
        />
      ),
    }

    const targetEnvironmentColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.targetEnvironmentHeader'
          defaultMessage='Environment type'
        />
      ),
      render: (trustRelationship) => (
        <EuiText size='s'>
          {getTargetEnvironmentType(trustRelationship) === 'ess' && (
            <FormattedMessage
              id='deploymentTrustManagement.table.ess'
              defaultMessage='Elastic Cloud'
            />
          )}
          {getTargetEnvironmentType(trustRelationship) === 'ece' && (
            <FormattedMessage
              id='deploymentTrustManagement.table.ece'
              defaultMessage='Elastic Cloud Enterprise'
            />
          )}
          {getTargetEnvironmentType(trustRelationship) === 'self-managed' && (
            <FormattedMessage
              id='deploymentTrustManagement.table.selfManaged'
              defaultMessage='Self-managed'
            />
          )}
        </EuiText>
      ),
    }

    const allowlistColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.allowlistHeader'
          defaultMessage='Trusted deployments'
        />
      ),
      render: (trustRelationship) => <Allowlist trustRelationship={trustRelationship} />,
    }

    const actionsColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.actionsHeader'
          defaultMessage='Actions'
        />
      ),
      actions: true,
      width: `75px`,
      render: (trustRelationship) => (
        <EuiFlexGroup gutterSize='xs'>
          <EuiFlexItem>
            <DeploymentTrustRelationshipEditButton
              deployment={deployment}
              trustRelationship={trustRelationship}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DeploymentTrustRelationshipDeleteButton
              deployment={deployment}
              trustRelationship={trustRelationship}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    }

    const columns: Array<CuiTableColumn<AnyTrustRelationship>> = [
      orgIdColumn,
      targetEnvironmentColumn,
      allowlistColumn,
      actionsColumn,
    ]

    const rows = [
      ...getAccountTrustRelationships({ deployment }),
      ...getExternalTrustRelationships({ deployment }),
      ...getDirectTrustRelationships({ deployment }),
    ]

    return (
      <CuiTable<AnyTrustRelationship>
        tableLayout='auto'
        rows={rows}
        getRowId={(trustRelationship) => getTrustRelationshipId({ trustRelationship })}
        columns={columns}
      />
    )
  }
}

export default DeploymentTrustRelationshipTable
