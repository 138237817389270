/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { doesSliderInstanceTypeHaveNodeTypes } from '../../../../../lib/sliders'

import type { StepProps } from '../instanceConfigurationWizardTypes'
import type { FunctionComponent } from 'react'

enum InstanceTypeValidationErrors {
  NO_INSTANCE_TYPE = 'NO_INSTANCE_TYPE',
  NO_NODE_TYPE_BUT_REQUIRED = 'NO_NODE_TYPE_BUT_REQUIRED',
}

const ChooseInstanceTypesStepErrors: FunctionComponent<StepProps> = (props) => {
  const { pristine } = props

  if (pristine) {
    return null
  }

  const errors = validateInstanceTypes(props)

  if (isEmpty(errors)) {
    return null
  }

  return (
    <Fragment>
      <EuiSpacer size='m' />

      {errors.includes(InstanceTypeValidationErrors.NO_INSTANCE_TYPE) && (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut
            title={
              <FormattedMessage
                id='instance-configuration-choose-node-types.requires-instance-type'
                defaultMessage='You forgot to choose an instance type'
              />
            }
            color='danger'
            iconType='cross'
          />
        </Fragment>
      )}

      {errors.includes(InstanceTypeValidationErrors.NO_NODE_TYPE_BUT_REQUIRED) && (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut
            title={
              <FormattedMessage
                id='instance-configuration-choose-node-types.requires-node-type'
                defaultMessage='You forgot to choose a node type'
              />
            }
            color='danger'
            iconType='cross'
          />
        </Fragment>
      )}

      <EuiSpacer size='m' />
    </Fragment>
  )
}

export function validateInstanceTypes({
  instanceConfiguration,
}: StepProps): InstanceTypeValidationErrors[] {
  const errors = [] as InstanceTypeValidationErrors[]

  const { instance_type: instanceType, node_types: nodeTypes } = instanceConfiguration

  if (!instanceType) {
    errors.push(InstanceTypeValidationErrors.NO_INSTANCE_TYPE)

    return errors
  }

  const missingMandatoryNodeType =
    doesSliderInstanceTypeHaveNodeTypes(instanceType) && isEmpty(nodeTypes)

  if (missingMandatoryNodeType) {
    errors.push(InstanceTypeValidationErrors.NO_NODE_TYPE_BUT_REQUIRED)
  }

  return errors
}

export default ChooseInstanceTypesStepErrors
