/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { compact, uniq, flatten } from 'lodash'
import React from 'react'
import { defineMessages } from 'react-intl'

import { EuiBadge } from '@elastic/eui'
import type { FieldValueOptionType, SearchFilterConfig } from '@elastic/eui'

import { excludeSchemalessFields } from '@/cui'
import { rcompare } from '@/lib/semver'
import { getRegionIds } from '@/lib/regionEqualizer'

import { getSchema } from './schema'

import type { DeploymentFilterContextProps as Props } from './types'

const messages = defineMessages({
  loading: {
    id: `deployment-filter-context.loading`,
    defaultMessage: `Loading …`,
  },
  healthLabel: {
    id: `deployment-filter-context.health-label`,
    defaultMessage: `Health`,
  },
  healthyLabel: {
    id: `deployment-filter-context.healthy-label`,
    defaultMessage: `Healthy`,
  },
  unhealthyLabel: {
    id: `deployment-filter-context.unhealthy-label`,
    defaultMessage: `Unhealthy`,
  },
  lockedLabel: {
    id: `deployment-filter-context.locked-label`,
    defaultMessage: `Locked`,
  },
  systemOwnedLabel: {
    id: `deployment-filter-context.system-owned-label`,
    defaultMessage: `System owned`,
  },
  healthyConfigurationLabel: {
    id: `deployment-filter-context.healthy-configuration-label`,
    defaultMessage: `Healthy configuration change`,
  },
  unhealthyConfigurationLabel: {
    id: `deployment-filter-context.unhealthy-configuration-label`,
    defaultMessage: `Unhealthy configuration change`,
  },
  healthyMastersLabel: {
    id: `deployment-filter-context.healthy-masters-label`,
    defaultMessage: `Healthy masters`,
  },
  unhealthyMastersLabel: {
    id: `deployment-filter-context.unhealthy-masters-label`,
    defaultMessage: `Unhealthy masters`,
  },
  healthySnapshotLabel: {
    id: `deployment-filter-context.healthy-snapshot-label`,
    defaultMessage: `Healthy snapshot (any)`,
  },
  unhealthySnapshotLabel: {
    id: `deployment-filter-context.unhealthy-snapshot-label`,
    defaultMessage: `Unhealthy snapshot (any)`,
  },
  healthySnapshotLatestLabel: {
    id: `deployment-filter-context.healthy-snapshot-latest-label`,
    defaultMessage: `Healthy snapshot (latest)`,
  },
  unhealthySnapshotLatestLabel: {
    id: `deployment-filter-context.unhealthy-snapshot-latest-label`,
    defaultMessage: `Unhealthy snapshot (latest)`,
  },
  enabledSnapshotLabel: {
    id: `deployment-filter-context.enabled-snapshot-label`,
    defaultMessage: `Enabled snapshots`,
  },
  disabledSnapshotLabel: {
    id: `deployment-filter-context.disabled-snapshot-label`,
    defaultMessage: `Disabled snapshots`,
  },
  pendingPlanLabel: {
    id: `deployment-filter-context.pending-plan-label`,
    defaultMessage: `Pending configuration change`,
  },
  noPendingPlanLabel: {
    id: `deployment-filter-context.no-pending-plan-label`,
    defaultMessage: `No pending configuration change`,
  },
  maintenanceLabel: {
    id: `deployment-filter-context.maintenance-label`,
    defaultMessage: `Maintenance mode`,
  },
  noMaintenanceLabel: {
    id: `deployment-filter-context.not-maintenance-label`,
    defaultMessage: `Not maintenance mode`,
  },
  runningLabel: {
    id: `deployment-filter-context.running-label`,
    defaultMessage: `Running`,
  },
  stoppedLabel: {
    id: `deployment-filter-context.stopped-label`,
    defaultMessage: `Stopped`,
  },
  hiddenLabel: {
    id: `deployment-filter-context.hidden-label`,
    defaultMessage: `Hidden`,
  },
  notHiddenLabel: {
    id: `deployment-filter-context.not-hidden-label`,
    defaultMessage: `Not hidden`,
  },
  regionLabel: {
    id: `deployment-filter-context.region-label`,
    defaultMessage: `Region`,
  },
  versionLabel: {
    id: `deployment-filter-context.version-label`,
    defaultMessage: `Version`,
  },
  stackLabel: {
    id: `deployment-filter-context.stack-label`,
    defaultMessage: `Stack`,
  },
  configurationLabel: {
    id: `deployment-filter-context.configuration-label`,
    defaultMessage: `Configuration`,
  },
  subscriptionLabel: {
    id: `deployment-filter-context.subscription-label`,
    defaultMessage: `Subscription`,
  },
  standardLabel: {
    id: `deployment-filter-context.standard-label`,
    defaultMessage: `Standard`,
  },
  goldLabel: {
    id: `deployment-filter-context.gold-label`,
    defaultMessage: `Gold`,
  },
  platinumLabel: {
    id: `deployment-filter-context.platinum-label`,
    defaultMessage: `Platinum`,
  },
  enterpriseLabel: {
    id: `deployment-filter-context.enterprise-label`,
    defaultMessage: `Enterprise`,
  },
})

type SlimFieldValueOptionType = Pick<FieldValueOptionType, 'value' | 'view'>

let regions: SlimFieldValueOptionType[] | null = null
let versions: FieldValueOptionType[] | null = null

export function getFilters({
  intl: { formatMessage },
  fetchRegionList,
  fetchVersions,
}: Props): SearchFilterConfig[] {
  const filters: NonNullable<SearchFilterConfig[]> = [
    {
      name: formatMessage(messages.healthLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      multiSelect: false,
      autoClose: false,
      options: [
        {
          name: formatMessage(messages.healthyLabel),
          field: `healthy`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthyConfigurationLabel),
          field: `healthy_configuration`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthyMastersLabel),
          field: `healthy_masters`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthySnapshotLabel),
          field: `healthy_snapshot`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthySnapshotLatestLabel),
          field: `healthy_snapshot_latest`,
          value: `y`,
        },
        {
          name: formatMessage(messages.enabledSnapshotLabel),
          field: `enabled_snapshots`,
          value: `y`,
        },
        {
          name: formatMessage(messages.lockedLabel),
          field: `locked`,
          value: `y`,
        },
        {
          name: formatMessage(messages.noMaintenanceLabel),
          field: `maintenance`,
          value: `n`,
        },
        {
          name: formatMessage(messages.noPendingPlanLabel),
          field: `pending`,
          value: `n`,
        },
        {
          name: formatMessage(messages.runningLabel),
          field: `stopped`,
          value: `n`,
        },
        {
          name: formatMessage(messages.notHiddenLabel),
          field: `hidden`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          field: `healthy`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyConfigurationLabel),
          field: `healthy_configuration`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyMastersLabel),
          field: `healthy_masters`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthySnapshotLabel),
          field: `healthy_snapshot`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthySnapshotLatestLabel),
          field: `healthy_snapshot_latest`,
          value: `n`,
        },
        {
          name: formatMessage(messages.disabledSnapshotLabel),
          field: `enabled_snapshots`,
          value: `n`,
        },
        {
          name: formatMessage(messages.maintenanceLabel),
          field: `maintenance`,
          value: `y`,
        },
        {
          name: formatMessage(messages.pendingPlanLabel),
          field: `pending`,
          value: `y`,
        },
        {
          name: formatMessage(messages.stoppedLabel),
          field: `stopped`,
          value: `y`,
        },
        {
          name: formatMessage(messages.hiddenLabel),
          field: `hidden`,
          value: `y`,
        },
        {
          name: formatMessage(messages.systemOwnedLabel),
          field: `system`,
          value: `y`,
        },
      ],
    },
    {
      name: formatMessage(messages.versionLabel),
      type: `field_value_selection`,
      field: `version`,
      multiSelect: `or`,
      options: () => loadVersions({ fetchRegionList, fetchVersions }),
    },
  ]

  const { schema } = getSchema()

  /* Remove fields that aren't in the schema declaration,
   * such as `locked`, `system`, `organization`, `hidden`, or `subscription` in ECE & userconsole
   */
  return excludeSchemalessFields({ filters, schema })
}

function loadRegions({
  fetchRegionList,
}: Pick<Props, 'fetchRegionList'>): Promise<FieldValueOptionType[]> {
  if (regions) {
    // the EUI cache mechanism doesn't work as expected
    return Promise.resolve(regions)
  }

  return fetchRegionList().then((actionResult) => {
    if (actionResult.error || !actionResult.payload) {
      return Promise.reject()
    }

    setRegions(getRegionIds(actionResult))

    // `regions` guaranteed by the preceding setRegions()
    return regions!
  })
}

function setRegions(regionIds: string[]): void {
  regions = regionIds.map((regionId) => ({
    view: renderBadge(regionId),
    value: regionId,
  }))
}

function loadVersions({
  fetchRegionList,
  fetchVersions,
}: Pick<Props, 'fetchRegionList' | 'fetchVersions'>): Promise<FieldValueOptionType[]> {
  if (versions) {
    // the EUI cache mechanism doesn't work as expected
    return Promise.resolve(versions)
  }

  return loadRegions({ fetchRegionList }).then(getVersions)

  function getVersions() {
    // `regions` guaranteed by the preceding loadRegions()
    const regionQueries = regions!.map((region) => ({ id: region.value }))

    return Promise.all(
      regionQueries.map((region) =>
        fetchVersions(region).then((actionResult) => {
          if (actionResult.error || !actionResult.payload) {
            return Promise.reject()
          }

          const { stacks } = actionResult.payload
          const regionVersions = stacks.map((stack) => stack.version)

          return regionVersions
        }),
      ),
    ).then((results) => {
      const merged = compact(flatten(results))
      const sorted = merged.sort(rcompare)
      const unique = uniq(sorted)

      versions = unique.map((version) => ({
        view: renderBadge(version),
        value: version,
      }))

      return versions
    })
  }
}

function renderBadge(value: string | null): JSX.Element {
  return (
    <div>
      <EuiBadge>{value}</EuiBadge>
    </div>
  )
}
