/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find } from 'lodash'

import { isTopologySized } from '../deployments/deployment'
import { isTopologyConfiguredForAutoscaling } from '../stackDeployments/selectors/autoscaling'

import type { AnyTopologyElement } from '@/types'
import type { ElasticsearchClusterTopologyElement, InstanceConfiguration } from '../api/v1/types'

export type IndexHalflife = {
  amount: number
  type: 'hours' | 'days' | 'weeks' | 'months'
}

type DataConfigurationParams = {
  dataNodeConfigurations: ElasticsearchClusterTopologyElement[]
  instanceConfigurations: InstanceConfiguration[]
}

export function getCurationConfigurationOptions({
  dataNodeConfigurations,
  instanceConfigurations,
}: DataConfigurationParams): Array<{
  id: string
  name: string
}> {
  const nonEmptyDataConfigurations = dataNodeConfigurations.filter(isNotEmpty)

  return nonEmptyDataConfigurations.map((nodeConfiguration) => {
    const { instance_configuration_id } = nodeConfiguration

    const instanceConfiguration = find(instanceConfigurations, {
      id: instance_configuration_id,
    })

    const { id = ``, name = `` } = instanceConfiguration || {}

    return {
      id,
      name,
    }
  })
}

/* Here, we check whether we *could* configure index curation,
   It is assumed that later validation ensures the user picks
   different from/to configurations and at least one non-empty
   index pattern.
*/
export function couldHaveCuration({
  dataNodeConfigurations,
  instanceConfigurations,
}: DataConfigurationParams): boolean {
  const options = getCurationConfigurationOptions({
    dataNodeConfigurations,
    instanceConfigurations,
  })

  return options.length >= 2
}

function isNotEmpty(nodeConfiguration: AnyTopologyElement): boolean {
  return (
    isTopologySized([nodeConfiguration]) || isTopologyConfiguredForAutoscaling([nodeConfiguration])
  )
}

export function getIndexHalflifeFromSeconds(seconds: number): IndexHalflife {
  const minutes = seconds / 60
  const hours = Math.max(minutes / 60, 1)
  const days = hours / 24

  const months = days / 30

  if (months % 1 === 0) {
    return {
      amount: months,
      type: `months`,
    }
  }

  const weeks = days / 7

  if (weeks % 1 === 0) {
    return {
      amount: weeks,
      type: `weeks`,
    }
  }

  if (days % 1 === 0) {
    return {
      amount: days,
      type: `days`,
    }
  }

  return {
    amount: Math.ceil(hours),
    type: `hours`,
  }
}
