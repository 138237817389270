/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

import { fetchAuthMethods, redirectAfterLogin } from '../../../../../actions/auth'
import { getLoginRequest, getAuthMethods, fetchAuthMethodsRequest } from '../../../../../reducers'
import { isFeatureActivated } from '../../../../../selectors'
import Feature from '../../../../../lib/feature'
import { getBearerToken } from '../../../../../lib/auth'

import SsoBasedLogin from './SsoBasedLogin'

import type { AsyncRequestState } from '../../../../../types'
import type { AvailableAuthenticationMethods } from '../../../../../lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  redirectTo?: string
  loginRequest: AsyncRequestState
  authMethods: AvailableAuthenticationMethods | null
  location: RouteComponentProps['location']
  fetchAuthMethodsRequest: AsyncRequestState
  newBearerToken: string | null
}

type DispatchProps = {
  fetchAuthMethods: () => void
  redirectAfterLogin: (redirectTo: string) => () => void
}

type ConsumerProps = {
  location: RouteComponentProps['location']
}

const mapStateToProps = (state, { location }: ConsumerProps): StateProps => {
  const { hash } = location
  const { redirectTo } = parse(location.search.slice(1))
  const basicAuthProxyPassEnabled = isFeatureActivated(state, Feature.basicAuthProxyPass)
  const newBearerToken = basicAuthProxyPassEnabled ? getBearerToken(hash) : null

  return {
    redirectTo: typeof redirectTo === `string` ? redirectTo : undefined,
    authMethods: getAuthMethods(state),
    loginRequest: getLoginRequest(state),
    location,
    fetchAuthMethodsRequest: fetchAuthMethodsRequest(state),
    newBearerToken,
  }
}

const mapDispatchToProps = {
  fetchAuthMethods,
  redirectAfterLogin,
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(SsoBasedLogin),
)
