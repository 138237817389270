/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../reducers'

import RequestDeletionButton from './RequestDeletionButton'

import type { AsyncRequestState } from '../../../types'

type StateProps = {
  requestDeletionRequest: AsyncRequestState
}

type DispatchProps = {
  requestDeletion: () => void
  resetRequestDeletion: () => void
}

type ConsumerProps = {
  userId: string
  email: string
}

const mapStateToProps = (state): StateProps => ({
  requestDeletionRequest: callStoredProcedureRequest(state, `set_delete_request`),
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps): DispatchProps => ({
  requestDeletion: () =>
    dispatch(
      callStoredProcedure({
        procedureName: `set_delete_request`,
        parameters: [userId],
        userId,
      }),
    ),
  resetRequestDeletion: () => {
    dispatch(resetCallStoredProcedureRequest(`set_delete_request`))
    return dispatch(clearProcedure(`set_delete_request`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RequestDeletionButton)
