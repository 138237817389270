/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { noop } from 'lodash'
import { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { jsx } from '@emotion/react'

import {
  EuiCheckableCard,
  EuiIconTip,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiSwitch,
  EuiTabbedContent,
  EuiText,
  EuiTextColor,
  EuiPanel,
  EuiSuperSelect,
  withEuiTheme,
} from '@elastic/eui'

import { CuiTable, CuiLink, CuiFilterContext } from '../../../cui'
import { deploymentUrl } from '../../../lib/urlBuilder'

import { DeploymentRoles, Tabs } from './types'
import {
  defaultFlexGroupStyle,
  disabledFlexGroupStyle,
  selectedFlexGroupStyle,
  selectedRoleStyle,
  tableCellTextStyle,
  rolesInnerTitleCss,
  radioPanelWidth,
} from './styles'

import type { CuiTableColumn } from '../../../cui'
import type { PropsWithTheme, StateProps, DeploymentRow } from './types'

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `users.role-assignment-form.deployment-access.deployments-search.placeholder`,
    defaultMessage: `Search deployment`,
  },
})

class RoleAssignmentForm extends Component<PropsWithTheme, StateProps> {
  state: StateProps = this.getInitialState()

  getInitialState(): StateProps {
    return {
      selectedTabId: Tabs.ALL,
      selectedItems: [],
      selectedDeploymentRole: DeploymentRoles.ADMIN,
      isOrganizationOwnerSelected: false,
      isOrganizationBillingSelected: false,
      isDeploymentAccessSelected: false,
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderOrganizationOwnerPanel()}
        <EuiSpacer size='m' />
        {this.renderOrganizationBillingPanel()}
        <EuiSpacer size='m' />
        {this.renderDeploymentAccessPanel()}
      </Fragment>
    )
  }

  renderOrganizationOwnerPanel(): JSX.Element {
    const { isOrganizationOwnerSelected } = this.state
    const { theme } = this.props

    return (
      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        css={isOrganizationOwnerSelected && selectedRoleStyle({ theme })}
      >
        <EuiFlexGroup
          gutterSize='none'
          css={[
            defaultFlexGroupStyle({ theme }),
            isOrganizationOwnerSelected && selectedFlexGroupStyle({ theme }),
          ]}
          justifyContent='spaceBetween'
        >
          <EuiFlexItem grow={false}>
            <EuiText size='m'>
              <h5>
                <FormattedMessage
                  id='roles-assignment-form.organization-owner'
                  defaultMessage='Organization owner'
                />
              </h5>
              <EuiTextColor color='subdued'>
                <EuiText size='s'>
                  <FormattedMessage
                    id='roles-assignment-form.organization-owner-description'
                    defaultMessage='Highest role within the organization. Can manage members, access all features and all deployments'
                  />
                </EuiText>
              </EuiTextColor>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1} style={{ alignItems: `start`, alignSelf: `center` }}>
            <EuiSwitch
              checked={isOrganizationOwnerSelected}
              onChange={this.onChangeOrganizationOwner}
              label=''
              showLabel={false}
              data-test-id='organization-owner-switch'
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    )
  }

  renderOrganizationBillingPanel(): JSX.Element {
    const { isOrganizationOwnerSelected, isOrganizationBillingSelected } = this.state
    const { theme } = this.props

    return (
      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        css={
          isOrganizationBillingSelected &&
          !isOrganizationOwnerSelected &&
          selectedRoleStyle({ theme })
        }
      >
        <EuiFlexGroup
          gutterSize='none'
          css={[
            defaultFlexGroupStyle({ theme }),
            isOrganizationBillingSelected && selectedFlexGroupStyle({ theme }),
            isOrganizationOwnerSelected && disabledFlexGroupStyle({ theme }),
          ]}
          justifyContent='spaceBetween'
        >
          <EuiFlexItem grow={10}>
            <EuiText size='m'>
              <h5>
                <FormattedMessage
                  id='roles-assignment-form.organization-billing'
                  defaultMessage='Billing and subscription'
                />
              </h5>
              <EuiTextColor color='subdued'>
                <EuiText size='s'>
                  <FormattedMessage
                    id='roles-assignment-form.organization-billing-description'
                    defaultMessage='Access to all invoices and payment methods. Can make subscription changes.'
                  />
                </EuiText>
              </EuiTextColor>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1} style={{ alignItems: `start`, alignSelf: `center` }}>
            <EuiSwitch
              checked={isOrganizationBillingSelected}
              onChange={this.onChangeOrganizationBilling}
              label=''
              showLabel={false}
              disabled={isOrganizationOwnerSelected}
              data-test-id='organization-billing-switch'
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    )
  }

  renderDeploymentAccessPanel(): JSX.Element {
    const { isDeploymentAccessSelected, isOrganizationOwnerSelected } = this.state
    const { theme } = this.props

    return (
      <EuiPanel
        hasShadow={false}
        hasBorder={true}
        css={
          isDeploymentAccessSelected && !isOrganizationOwnerSelected && selectedRoleStyle({ theme })
        }
      >
        <EuiFlexGroup
          gutterSize='none'
          css={[
            defaultFlexGroupStyle({ theme }),
            isDeploymentAccessSelected && selectedFlexGroupStyle({ theme }),
            isOrganizationOwnerSelected && disabledFlexGroupStyle({ theme }),
          ]}
          justifyContent='spaceBetween'
        >
          <EuiFlexItem grow={10}>
            <EuiText size='m'>
              <h5>
                <FormattedMessage
                  id='roles-assignment-form.deployment-access'
                  defaultMessage='Deployment access'
                />
              </h5>
              <EuiTextColor color='subdued'>
                <EuiText size='s'>
                  <FormattedMessage
                    id='roles-assignment-form.deployment-access-description'
                    defaultMessage='Grant access to one or more deployments and assign specific deployment roles.'
                  />
                </EuiText>
              </EuiTextColor>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1} style={{ alignItems: `start`, alignSelf: `center` }}>
            <EuiSwitch
              data-test-id='organization-deployment-access-switch'
              checked={isDeploymentAccessSelected}
              onChange={this.onChangeDeploymentAccess}
              label=''
              showLabel={false}
              disabled={isOrganizationOwnerSelected}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        {isDeploymentAccessSelected &&
          !isOrganizationOwnerSelected &&
          this.renderDeploymentAccessTabbedContent()}
      </EuiPanel>
    )
  }

  renderDeploymentAccessTabbedContent(): JSX.Element {
    const tabs = this.setupTabs()

    return (
      <Fragment>
        <EuiSpacer size='xl' />
        <EuiTabbedContent
          data-test-id='deployment-access-tabbed-content'
          className='deployment-admin-tags'
          tabs={tabs}
          initialSelectedTab={tabs[0]}
        />
      </Fragment>
    )
  }

  renderAdminPanel(): JSX.Element {
    const label = (
      <EuiText size='m'>
        <h5>
          <FormattedMessage
            id='roles-assignment-form.deployment-access.admin'
            defaultMessage='Admin'
          />
        </h5>
        <EuiTextColor color='subdued'>
          <EuiText size='s'>
            <FormattedMessage
              id='roles-assignment-form.deployment-access.admin-description'
              defaultMessage='Can create, edit, view, and delete data. Can manage the configuration of a deployment.'
            />
          </EuiText>
        </EuiTextColor>
      </EuiText>
    )

    return (
      <EuiCheckableCard
        css={radioPanelWidth}
        id='1'
        label={label}
        name='admin'
        value={DeploymentRoles.ADMIN}
        checked={this.isDeploymentRoleSelected(DeploymentRoles.ADMIN)}
        onChange={() => this.setState({ selectedDeploymentRole: DeploymentRoles.ADMIN })}
      />
    )
  }

  renderEditPanel(): JSX.Element {
    const label = (
      <EuiText size='m'>
        <h5>
          <FormattedMessage
            id='roles-assignment-form.deployment-access.editor'
            defaultMessage='Editor'
          />
        </h5>
        <EuiTextColor color='subdued'>
          <EuiText size='s'>
            <FormattedMessage
              id='roles-assignment-form.deployment-access.editor-description'
              defaultMessage='Can create, edit, view and delete component.'
            />
          </EuiText>
        </EuiTextColor>
      </EuiText>
    )

    return (
      <EuiCheckableCard
        css={radioPanelWidth}
        id='2'
        label={label}
        name='editor'
        value={DeploymentRoles.EDITOR}
        checked={this.isDeploymentRoleSelected(DeploymentRoles.EDITOR)}
        onChange={() => this.setState({ selectedDeploymentRole: DeploymentRoles.EDITOR })}
      />
    )
  }

  renderViewerPanel(): JSX.Element {
    const label = (
      <EuiText size='m'>
        <h5>
          <FormattedMessage
            id='roles-assignment-form.deployment-access.viewer'
            defaultMessage='Viewer'
          />
        </h5>
        <EuiTextColor color='subdued'>
          <EuiText size='s'>
            <FormattedMessage
              id='roles-assignment-form.deployment-access.viewer-description'
              defaultMessage='Can view and operate on existing content'
            />
          </EuiText>
        </EuiTextColor>
      </EuiText>
    )

    return (
      <EuiCheckableCard
        css={radioPanelWidth}
        id='3'
        label={label}
        name='viewer'
        value={DeploymentRoles.VIEWER}
        checked={this.isDeploymentRoleSelected(DeploymentRoles.VIEWER)}
        onChange={() => this.setState({ selectedDeploymentRole: DeploymentRoles.VIEWER })}
      />
    )
  }

  onChangeOrganizationOwner = (): void => {
    this.setState((prevState) => ({
      isOrganizationOwnerSelected: !prevState.isOrganizationOwnerSelected,
      isOrganizationBillingSelected: false,
      isDeploymentAccessSelected: false,
    }))
  }

  onChangeOrganizationBilling = (): void => {
    this.setState((prevState) => ({
      isOrganizationBillingSelected: !prevState.isOrganizationBillingSelected,
    }))
  }

  onChangeDeploymentAccess = (): void => {
    this.setState((prevState) => ({
      isDeploymentAccessSelected: !prevState.isDeploymentAccessSelected,
    }))
  }

  setUpOptions() {
    return [
      {
        value: DeploymentRoles.ADMIN,
        inputDisplay: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.admin'
            defaultMessage='Admin'
          />
        ),
        dropdownDisplay: (
          <Fragment>
            <strong>
              <FormattedMessage
                id='roles-assignment-form.deployment-access.admin'
                defaultMessage='Admin'
              />
            </strong>
            <EuiText size='s' color='subdued'>
              <FormattedMessage
                id='roles-assignment-form.specific-deployments.admin-option-description-first'
                defaultMessage='Can create, edit, view, and delete data.'
              />
              <EuiSpacer />
              <FormattedMessage
                id='roles-assignment-form.specific-deployments.admin-option-description-second'
                defaultMessage='Can manage the configuration of a deployment.'
              />
            </EuiText>
          </Fragment>
        ),
      },
      {
        value: DeploymentRoles.EDITOR,
        inputDisplay: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.editor'
            defaultMessage='Editor'
          />
        ),
        dropdownDisplay: (
          <Fragment>
            <strong>
              <FormattedMessage
                id='roles-assignment-form.deployment-access.editor'
                defaultMessage='Editor'
              />
            </strong>
            <EuiText size='s' color='subdued'>
              <FormattedMessage
                id='roles-assignment-form.specific-deployments.editor-option-description'
                defaultMessage='Can create, edit, view, or delete data.'
              />
            </EuiText>
          </Fragment>
        ),
      },
      {
        value: DeploymentRoles.VIEWER,
        inputDisplay: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.viewer'
            defaultMessage='Viewer'
          />
        ),
        dropdownDisplay: (
          <Fragment>
            <strong>
              <FormattedMessage
                id='roles-assignment-form.deployment-access.viewer'
                defaultMessage='Viewer'
              />
            </strong>
            <EuiText size='s' color='subdued'>
              <FormattedMessage
                id='roles-assignment-form.specific-deployments.viewer-option-description'
                defaultMessage='Can view and operate on existing data.'
              />
            </EuiText>
          </Fragment>
        ),
      },
    ]
  }

  setupTabs() {
    const { selectedItems } = this.state
    const {
      intl: { formatMessage },
      theme,
    } = this.props

    const searchBarPlaceholder = formatMessage(messages.searchBarPlaceholder)

    const columns = this.setUpColumns()

    const items = [
      { id: '0', deploymentName: 'analytics cluster', deploymentRole: 'admin', selected: false },
      { id: '1', deploymentName: 'production cluster', deploymentRole: 'editor', selected: false },
      { id: '2', deploymentName: 'observability', deploymentRole: 'viewer', selected: true },
      { id: '3', deploymentName: 'monitoring', deploymentRole: 'editor', selected: false },
    ]

    return [
      {
        id: 'all',
        name: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.all-deployments-tab'
            defaultMessage='All deployments'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xl' />
            <EuiText size='s'>
              <EuiFlexGroup gutterSize='s' responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiText size='s' css={rolesInnerTitleCss({ theme })}>
                    <FormattedMessage
                      id='roles-assignment-form.deployment-access.inner-title'
                      defaultMessage='Role for all deployments'
                    />
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiIconTip
                    position='right'
                    content={formatMessage({
                      id: 'roles-assignment-form.deployment-access.icon-tip',
                      defaultMessage:
                        'This role gets applied to all existing deployments, as well as deployments that get created in the future.',
                    })}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiText>
            <EuiSpacer size='s' />
            {this.renderAdminPanel()}
            <EuiSpacer size='m' />
            {this.renderEditPanel()}
            <EuiSpacer size='m' />
            {this.renderViewerPanel()}
          </Fragment>
        ),
      },
      {
        id: 'specific',
        name: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.specific-deployments-tab'
            defaultMessage='Specific deployments'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiFilterContext query={''} onChange={noop} placeholder={searchBarPlaceholder} />
            <EuiSpacer size='m' />
            <CuiTable<DeploymentRow>
              rows={items}
              getRowId={(row) => row.id}
              columns={columns}
              selectedRows={selectedItems}
              onSelectionChange={(selectedItems) => this.setState({ selectedItems })}
              css={tableCellTextStyle}
            />
          </Fragment>
        ),
      },
    ]
  }

  setUpColumns(): Array<CuiTableColumn<DeploymentRow>> {
    const options = this.setUpOptions()

    return [
      {
        label: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.specific-deployment-name'
            defaultMessage='Deployment name'
          />
        ),
        align: 'left',
        render: (deployment) => (
          <CuiLink to={deploymentUrl(deployment.id)}>
            <FormattedMessage
              id='roles-assignment-form.deployment-access.specific-deployment-link'
              defaultMessage='{deploymentName}'
              values={{ deploymentName: deployment.deploymentName }}
            />
          </CuiLink>
        ),
        truncateText: false,
        width: '130px',
      },
      {
        label: (
          <FormattedMessage
            id='roles-assignment-form.deployment-access.specific-deployment-role'
            defaultMessage='Deployment role'
          />
        ),
        render: () => (
          <EuiSuperSelect
            options={options}
            valueOfSelected={options[0].value}
            itemLayoutAlign='top'
            fullWidth={true}
          />
        ),
        truncateText: false,
        width: '220px',
      },
    ]
  }

  isDeploymentRoleSelected = (role: DeploymentRoles): boolean =>
    this.state.selectedDeploymentRole === role
}

export default withEuiTheme(injectIntl(RoleAssignmentForm))
