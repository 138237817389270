/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@/cui'

import DangerButton from '../../../DangerButton'

import type { AllProps } from './types'

const messages = defineMessages({
  convertToDirect: {
    id: `organization.organization-overview.convert-reseller-to-direct`,
    defaultMessage: `Convert account from reseller to direct`,
  },
  convertToDirectModalBody: {
    id: `organization.organization-overview.convert-reseller-to-direct.message`,
    defaultMessage: `You're about to convert account ({id}) from reseller to direct. This will take effect at the end of the current billing period.`,
  },
  convertToDirectModalConfirm: {
    id: `organization.organization-overview.convert-reseller-to-direct.confirm`,
    defaultMessage: `Convert account`,
  },
})

class ConvertResellerToDirectButton extends Component<AllProps> {
  componentWillUnmount() {
    this.props.resetConvertToDirect()
  }

  render() {
    const { convertToDirect, convertToDirectRequest } = this.props

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          size='s'
          onConfirm={convertToDirect}
          spin={convertToDirectRequest.inProgress}
          modal={{
            title: <FormattedMessage {...messages.convertToDirect} />,
            body: (
              <FormattedMessage
                {...messages.convertToDirectModalBody}
                values={{ id: this.props.organizationId }}
              />
            ),
            confirmButtonText: <FormattedMessage {...messages.convertToDirectModalConfirm} />,
          }}
        >
          <span data-test-id='convert-reseller-to-direct'>
            <FormattedMessage {...messages.convertToDirect} />
          </span>
        </DangerButton>

        {convertToDirectRequest.error && (
          <Fragment>
            <EuiSpacer size='s' />

            <CuiAlert size='s' type='error' iconType='alert'>
              {convertToDirectRequest.error}
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default ConvertResellerToDirectButton
