/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchDeploymentTemplateRequest,
  fetchInstanceConfigurationsRequest,
  getDeploymentTemplate,
  getInstanceConfigurations,
  getInstanceTypes,
  updateDeploymentTemplateRequest,
} from '@/reducers'
import { fetchInstanceConfigurations } from '@/actions/topology/instanceConfigurations'
import {
  fetchDeploymentTemplate,
  updateDeploymentTemplate,
} from '@/actions/topology/deploymentTemplates'
import { fetchInstanceTypes } from '@/actions/topology/fetchInstanceTypes'

import DeploymentTemplateEdit from './DeploymentTemplateEdit'

import type { DeploymentTemplateInfoV2 } from '@/lib/api/v1/types'
import type { ReduxState, ThunkDispatch } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId, templateId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  instanceTypes: getInstanceTypes(state, regionId),
  instanceConfigurations: getInstanceConfigurations(state, regionId),
  template: getDeploymentTemplate(state, regionId, templateId, null),
  fetchDeploymentTemplateRequest: fetchDeploymentTemplateRequest(state, regionId, templateId),
  fetchInstanceConfigurationsRequest: fetchInstanceConfigurationsRequest(state, regionId),
  updateDeploymentTemplateRequest: updateDeploymentTemplateRequest(state, regionId, templateId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId, templateId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchDeploymentTemplate: () =>
    dispatch(fetchDeploymentTemplate(regionId, templateId, null, { showMaxZones: true })),
  updateDeploymentTemplate: (deploymentTemplate: DeploymentTemplateInfoV2) =>
    dispatch(updateDeploymentTemplate(regionId, deploymentTemplate)),
  fetchInstanceTypes: () => dispatch(fetchInstanceTypes(regionId)),
  fetchInstanceConfigurations: () =>
    dispatch(fetchInstanceConfigurations(regionId, { showMaxZones: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTemplateEdit)
