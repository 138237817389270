/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexItem } from '@elastic/eui'

import { isFeatureActivated } from '@/store'
import Feature from '@/lib/feature'

import CreditCardModalButton from '..'

interface Props {
  onSendBillingDetailsSuccess: () => void
  fill?: boolean
}

const SubscribeButton = ({ onSendBillingDetailsSuccess, fill = true }: Props): JSX.Element => {
  const isGovCloud = isFeatureActivated(Feature.hideIrrelevantSectionsFromGovCloud)

  if (isGovCloud) {
    return (
      <EuiButton
        fill={true}
        data-test-id='contact-us-button'
        href='mailto:gov-onboarding@elastic.co'
      >
        <FormattedMessage id='trial-summary-modal.contact-us' defaultMessage='Contact us' />
      </EuiButton>
    )
  }

  return (
    <EuiFlexItem grow={true}>
      <CreditCardModalButton
        data-test-id='subscribe-button'
        type='full'
        fill={fill}
        onSendBillingDetailsSuccess={onSendBillingDetailsSuccess}
      >
        <FormattedMessage id='trial-summary-modal.subscribe' defaultMessage='Subscribe' />
      </CreditCardModalButton>
    </EuiFlexItem>
  )
}

export default SubscribeButton
