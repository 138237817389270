/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiSelect,
  EuiButtonEmpty,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiSteps,
  EuiText,
  EuiLink,
  EuiTabbedContent,
} from '@elastic/eui'

import history from '@/lib/history'
import { platformTrustManagementUrl, securityUrl } from '@/lib/urlBuilder'
import { getDeploymentSettingsFromGet, getRegionId } from '@/lib/stackDeployments/selectors'

import { CuiAlert } from '../../../cui'
import {
  createUpdateTrustRequestFromGetResponse,
  getTrustLevelFromRelationship,
  getTrustRelationshipFromDeployment,
  getTrustRelationshipId,
  isExternalRelationship,
} from '../../../lib/stackDeployments/trustRelationships'
import EnterOtherAccountRemoteDeployments from '../components/EnterOtherAccountRemoteDeployments'
import TrustLevelSelector from '../components/TrustLevelSelector'
import { flattenTrustedEnvs } from '../helpers'

import type { WrappedComponentProps } from 'react-intl'
import type { TrustLevel } from '@/lib/stackDeployments/selectors/crossClusterReplication'
import type {
  ExternalTrustRelationship,
  TrustRelationshipGetResponse,
} from '../../../lib/api/v1/types'
import type { AllProps } from './types'

interface State {
  trustLevel: TrustLevel
  trustedEnvironmentId: string
  trustedClusterIds: string[]
}

const messages = defineMessages({
  environmentStepTitle: {
    id: 'deploymentTrustManagement.external.stepTitles.environment',
    defaultMessage: 'Select an Elastic Cloud Enterprise environment',
  },
  deploymentsStepTitle: {
    id: 'deploymentTrustManagement.external.stepTitles.deployments',
    defaultMessage: 'Select trusted deployments',
  },
  environmentLabel: {
    id: 'deploymentTrustManagement.external.selectEnvironmentLabel',
    defaultMessage: 'Select ECE environment',
  },
})

class ManageExternalTrustRelationship extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = this.createInitialState()

  componentDidMount(): void {
    const { previouslyUsedEnv, fetchTrustedEnvs, trustedEnvs, fetchTrustRelationships } = this.props
    fetchTrustRelationships()

    if (previouslyUsedEnv) {
      fetchTrustedEnvs()

      if (trustedEnvs && !isEmpty(trustedEnvs)) {
        this.setPreviouslyUsedEnvDetails()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { previouslyUsedEnv, trustedEnvs } = this.props

    if (previouslyUsedEnv && isEmpty(prevProps.trustedEnvs) && !isEmpty(trustedEnvs)) {
      // We're using a template, and the trustedEnvs were loaded in
      this.setPreviouslyUsedEnvDetails()
    }
  }

  componentWillUnmount(): void {
    this.props.resetFetchTrustRelationships()
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element {
    return this.isEditing() ? this.renderEditPage() : this.renderCreatePage()
  }

  renderCreatePage(): JSX.Element {
    return (
      <Fragment>
        {this.renderForm()}
        {this.renderError()}
        {this.renderButtons()}
      </Fragment>
    )
  }

  renderForm(): JSX.Element {
    const {
      intl: { formatMessage },
      previouslyUsedEnv,
    } = this.props

    if (previouslyUsedEnv) {
      return this.renderDeploymentsFields()
    }

    return (
      <EuiSteps
        steps={[
          {
            title: formatMessage(messages.environmentStepTitle),
            children: this.renderEnvironmentFields(),
          },
          {
            title: formatMessage(messages.deploymentsStepTitle),
            children: this.renderDeploymentsFields(),
          },
        ]}
      />
    )
  }

  renderEditPage(): JSX.Element {
    const { deployment } = this.props

    const tabs = [
      {
        id: 'trust',
        name: (
          <FormattedMessage
            id='deploymentTrustManagement.tabs.trust'
            defaultMessage='Trust level'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xl' />
            {this.renderDeploymentsFields()}
            <EuiSpacer size='l' />
            {this.renderError()}
            {this.renderButtons()}
          </Fragment>
        ),
      },
      {
        id: 'environment',
        name: (
          <FormattedMessage
            id='deploymentTrustManagement.tabs.environment'
            defaultMessage='Environment settings'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xl' />

            <EuiText size='s'>
              <FormattedMessage
                id='deploymentTrustManagement.direct.eceEnvironmentSettings'
                defaultMessage='Environment settings are currently located under Platform > Trust Management > <link>Trusted environments</link>'
                values={{
                  link: (content) => (
                    <EuiLink href={platformTrustManagementUrl(getRegionId({ deployment })!)}>
                      {content}
                    </EuiLink>
                  ),
                }}
              />
            </EuiText>
          </Fragment>
        ),
      },
    ]

    return <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} autoFocus='selected' />
  }

  renderEnvironmentFields(): JSX.Element | null {
    const {
      intl: { formatMessage },
    } = this.props

    const { trustedEnvironmentId } = this.state

    const options = this.getTrustRelationshipOptionsForDeployment().map(({ id, name }) => ({
      value: id,
      text: name,
    }))

    if (options.length) {
      options.unshift({ value: ``, text: `` })
    }

    return (
      <Fragment>
        <EuiFormRow aria-label={formatMessage(messages.environmentLabel)}>
          <EuiSelect
            data-test-id='select-trusted-environment'
            options={options}
            value={trustedEnvironmentId}
            onChange={({ target: { value } }) => {
              this.setState({ trustedEnvironmentId: value })
            }}
          />
        </EuiFormRow>
        <EuiSpacer size='m' />
        <EuiText size='s' style={{ maxWidth: `40rem` }}>
          If the Elastic Cloud Enterprise environment you are looking for is not in this list, you
          or a platform administrator must first configure it in Platform {'>'} Trust Management.
        </EuiText>
      </Fragment>
    )
  }

  renderDeploymentsFields(): JSX.Element {
    const { trustLevel, trustedClusterIds } = this.state

    return (
      <Fragment>
        <EuiFormRow>
          <TrustLevelSelector
            trustLevel={trustLevel}
            onChange={(trustLevel) => {
              this.setState({ trustLevel })
            }}
          />
        </EuiFormRow>

        {trustLevel === `specific` && (
          <Fragment>
            <EuiSpacer />

            <EnterOtherAccountRemoteDeployments
              trustedClusterIds={trustedClusterIds}
              onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderError(): JSX.Element | null {
    const { updateStackDeploymentRequest } = this.props

    if (!updateStackDeploymentRequest.error) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <CuiAlert type='danger' data-test-id='update-deployment-request-error'>
          {updateStackDeploymentRequest.error}
        </CuiAlert>
      </EuiFlexItem>
    )
  }

  renderButtons(): JSX.Element {
    const { deployment, updateStackDeploymentRequest } = this.props

    const isDisabled =
      !this.state.trustedEnvironmentId ||
      (this.state.trustLevel === 'specific' && this.state.trustedClusterIds.length === 0)

    return (
      <Fragment>
        <EuiSpacer size='l' />
        <EuiFlexGroup justifyContent='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiButton
              type='button'
              data-test-id='save-trust-relationship-button'
              disabled={isDisabled}
              onClick={() => this.onSave()}
              isLoading={updateStackDeploymentRequest.inProgress}
              fill={true}
            >
              {this.isEditing() ? (
                <FormattedMessage
                  id='deploymentTrustManagement.external.submitButton.edit'
                  defaultMessage='Update trust'
                />
              ) : (
                <FormattedMessage
                  id='deploymentTrustManagement.external.submitButton.create'
                  defaultMessage='Create trust'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
              <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  isEditing(): boolean {
    return Boolean(this.props.match.params.trustRelationshipId)
  }

  createInitialState(): State {
    const {
      deployment,
      match: {
        params: { trustRelationshipId },
      },
    } = this.props

    if (trustRelationshipId) {
      const trustRelationship = getTrustRelationshipFromDeployment({
        deployment,
        trustRelationshipType: 'external',
        trustRelationshipId,
      })

      if (trustRelationship) {
        return {
          trustLevel: getTrustLevelFromRelationship(trustRelationship),
          trustedEnvironmentId: getTrustRelationshipId({ trustRelationship }),
          trustedClusterIds: trustRelationship.trust_allowlist || [],
        }
      }
    }

    return {
      trustLevel: `all`,
      trustedEnvironmentId: ``,
      trustedClusterIds: [],
    }
  }

  getTrustRelationshipOptionsForDeployment(): TrustRelationshipGetResponse[] {
    const { deployment, trustRelationships } = this.props

    const settings = getDeploymentSettingsFromGet({ deployment })
    const externalTrustRelationships = settings?.trust?.external || []

    // We only display options that aren't already trusted by default,
    // and ones that aren't already configured for this deployment.
    return trustRelationships.filter(
      (trustRelationship) =>
        !trustRelationship.trust_by_default &&
        !trustRelationship.local &&
        externalTrustRelationships.every(
          ({ trust_relationship_id }) => trust_relationship_id !== trustRelationship.id,
        ),
    )
  }

  getTrustRelationshipFromState(): ExternalTrustRelationship {
    const { trustedEnvironmentId, trustLevel, trustedClusterIds } = this.state

    const trustFields = {
      trust_relationship_id: trustedEnvironmentId,
      trust_all: trustLevel === `all`,
    }

    const optionalFields = trustLevel === `specific` ? { trust_allowlist: trustedClusterIds } : {}

    return { ...trustFields, ...optionalFields }
  }

  onSave(): void {
    const { deployment, updateStackDeployment } = this.props

    const trustRelationship = this.getTrustRelationshipFromState()

    const payload = createUpdateTrustRequestFromGetResponse({
      deployment,
      trustRelationships: [trustRelationship],
      type: `external`,
    })

    updateStackDeployment(payload).then(() => {
      history.push(securityUrl(deployment.id))
    })
  }

  setPreviouslyUsedEnvDetails() {
    const { trustedEnvs, previouslyUsedEnv } = this.props
    const flatEnvs = flattenTrustedEnvs(trustedEnvs)
    const previouslyUsedEnvObj = flatEnvs.find((env) => env.name === previouslyUsedEnv)

    if (!previouslyUsedEnvObj) {
      return
    }

    if (!isExternalRelationship(previouslyUsedEnvObj)) {
      return
    }

    this.setState({
      trustedEnvironmentId: previouslyUsedEnvObj.trust_relationship_id,
      trustLevel: `all`,
      trustedClusterIds: [],
    })
  }
}

export default injectIntl(ManageExternalTrustRelationship)
