/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import { CuiLink } from '../../../cui'
import { regionUrl } from '../../../lib/urlBuilder'

import { getOverallStatusColor } from './lib/regionHealth'

import type { RegionInfo } from '../../../lib/api/v1/types'
import type { FunctionComponent } from 'react'

type Props = {
  region: RegionInfo
}

const RegionOverallStatus: FunctionComponent<Props> = ({ region }) => (
  <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
    <EuiFlexItem grow={false}>
      <EuiIcon type='dot' color={getOverallStatusColor({ region })} />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <CuiLink to={regionUrl(region.region_id)}>{region.region_id}</CuiLink>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default RegionOverallStatus
