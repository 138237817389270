/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getFirstRefId } from '@/lib/stackDeployments/selectors'

import {
  CLEAR_API_CONSOLE_HISTORY,
  SET_API_CONSOLE_HISTORY,
  SET_API_CONSOLE_REQUEST,
} from '../../constants/actions'
import { queryDeploymentProxy } from '../deploymentProxy'

import type { ConsoleRequestState } from '../../reducers/apiConsole'
import type { StackDeployment } from '../../types'

export function queryDeploymentProxyForConsole(
  deployment: StackDeployment,
  request: ConsoleRequestState,
) {
  const { path, method, body } = request

  const { id: deploymentId } = deployment
  const refId = getFirstRefId({ deployment, sliderInstanceType: 'elasticsearch' })

  return queryDeploymentProxy({
    deploymentId,
    refId: refId!,
    resourceKind: 'elasticsearch',
    method,
    path,
    body,
    meta: {
      isConsoleRequest: true,
    },
  })
}

export function setAPIConsoleRequest(request: ConsoleRequestState, deployment: StackDeployment) {
  const { id: deploymentId } = deployment
  return {
    type: SET_API_CONSOLE_REQUEST,
    meta: { deploymentId },
    payload: request,
  }
}

export function clearAPIConsoleHistory() {
  return {
    type: CLEAR_API_CONSOLE_HISTORY,
  }
}

export function setAPIConsoleRequestHistory(history: ConsoleRequestState[]) {
  return {
    type: SET_API_CONSOLE_HISTORY,
    payload: history,
  }
}
