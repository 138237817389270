/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { BillingModel, Channel, PartnerSelling } from '@/types/billing'

import type { OrganizationBillingDetails } from './api/v1/types'

export function isPrepaidConsumptionCustomer({
  billing_model,
}: OrganizationBillingDetails): boolean {
  return billing_model === BillingModel.PrepaidConsumption
}

export function isPrepaidConsumptionDirectCustomer({
  billing_model,
  channel,
}: OrganizationBillingDetails): boolean {
  return billing_model === BillingModel.PrepaidConsumption && channel === Channel.Direct
}

export function isPrepaidConsumptionDirectResellerCustomer({
  billing_model,
  channel,
  partner_selling,
}: OrganizationBillingDetails): boolean {
  return (
    billing_model === BillingModel.PrepaidConsumption &&
    channel === Channel.Direct &&
    partner_selling === PartnerSelling.Reseller
  )
}
