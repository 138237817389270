/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchContainerSets } from '../../actions/containerSets'
import { fetchContainerSetsRequest, getContainerSets } from '../../reducers'

import ContainerSets from './ContainerSets'

import type { AsyncRequestState } from '../../types'
import type { ContainerSetDetails } from '../../lib/api/v1/types'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  containerSets: ContainerSetDetails[] | null
  fetchContainerSetsRequest: AsyncRequestState
}

type DispatchProps = {
  fetchContainerSets: () => void
}

type QueryParams = { regionId: string }

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  containerSets: getContainerSets(state, regionId),
  fetchContainerSetsRequest: fetchContainerSetsRequest(state, regionId),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchContainerSets: () => dispatch(fetchContainerSets({ regionId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerSets)
