/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiText } from '@elastic/eui'

import type { FunctionComponent } from 'react'

interface Props {
  query: string
}

const FilterQueryExpression: FunctionComponent<Props> = ({ query }) => {
  const content = isEmpty(query) ? (
    <FormattedMessage
      data-test-id='filter-query-expression-empty'
      id='topology-node-configuration-filter-query-expression.empty'
      defaultMessage='No filtering will be applied. All allocators will match.'
    />
  ) : (
    <div data-test-id='filter-query-expression'>{query}</div>
  )

  return (
    <EuiPanel>
      <EuiText color='subdued'>{content}</EuiText>
    </EuiPanel>
  )
}

export default FilterQueryExpression
