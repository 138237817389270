/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getProfile } from '@/apps/userconsole/reducers'
import { getConfigForKey } from '@/selectors'
import {
  fetchOrganizationRequest,
  fetchOrganizationInvitationsRequest,
  fetchOrganizationMembershipsRequest,
  getOrganization,
  getOrganizationInvitations,
  getOrganizationMembers,
} from '@/reducers'
import {
  fetchOrganizationIfNeeded,
  fetchOrganizationInvitations,
  fetchOrganizationMemberships,
} from '@/actions/organizations'

import OrganizationMembers from './OrganizationMembers'
import { isCurrentUserLastOrganizationMember } from './lib'

import type { ConsumerProps, StateProps, DispatchProps, OrganizationMemberRow } from './types'
import type { ReduxState, ThunkDispatch } from '@/types'

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => {
  const invitationRows: OrganizationMemberRow[] = getOrganizationInvitations(
    state,
    organizationId,
  ).map(({ token: id, email, created_at: addedDate, expired }) => ({
    id,
    email,
    addedDate,
    isInvitation: true,
    status: expired ? 'expired' : 'pending',
  }))

  const activeMemberRows: OrganizationMemberRow[] = getOrganizationMembers(
    state,
    organizationId,
  ).map(({ user_id: id, email = '', member_since: addedDate }) => ({
    id,
    email,
    addedDate,
    isInvitation: false,
    status: 'active',
  }))

  const organizationMemberRows = invitationRows
    .concat(activeMemberRows)
    .sort(({ email: email1 }, { email: email2 }) => email1.localeCompare(email2))

  const profile = getProfile(state)

  return {
    profile,
    organization: getOrganization(state, organizationId),
    organizationMemberRows,
    showEmptyOrganizationMembers: isCurrentUserLastOrganizationMember(
      profile,
      activeMemberRows,
      invitationRows,
    ),
    isAdminconsole: getConfigForKey(state, `APP_NAME`) === `adminconsole`,
    fetchOrganizationRequest: fetchOrganizationRequest(state, organizationId),
    fetchOrganizationInvitationsRequest: fetchOrganizationInvitationsRequest(state, organizationId),
    fetchOrganizationMembersRequest: fetchOrganizationMembershipsRequest(state, organizationId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  fetchOrganizationIfNeeded: () => dispatch(fetchOrganizationIfNeeded({ organizationId })),
  fetchOrganizationMembers: () =>
    Promise.all([
      dispatch(fetchOrganizationInvitations({ organizationId })),
      dispatch(fetchOrganizationMemberships({ organizationId })),
    ]),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMembers)
