/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { withErrorBoundary } from '../../../../cui'
import { DisabledDataTierWarning } from '../DisabledDataTierWarning'
import { getEsPlan, getEsPlanFromGet } from '../../../../lib/stackDeployments/selectors'

import explainChanges from './explainChanges'

import type { AllProps } from './types'

class DeploymentConfigurationChangeExplain extends Component<AllProps> {
  componentDidMount() {
    this.props.fetchInstanceConfigurations()
  }

  render() {
    const { deployment, deploymentUnderEdit, instanceConfigurations, pruneOrphans } = this.props

    if (!instanceConfigurations) {
      return null
    }

    const explanations = explainChanges({
      newDeployment: deployment,
      oldDeployment: deploymentUnderEdit,
      instanceConfigurations,
      pruneOrphans,
    })

    if (explanations.length === 0) {
      return null
    }

    const oldEsPlan = getEsPlanFromGet({
      deployment: deploymentUnderEdit,
    })
    const newEsPlan = getEsPlan({
      deployment,
    })

    return (
      <Fragment>
        <DisabledDataTierWarning
          current={oldEsPlan}
          next={newEsPlan}
          pruneOrphans={pruneOrphans}
          instanceConfigurations={instanceConfigurations}
        />
        <div>
          {explanations.map((explanation) => (
            <EuiFlexGroup key={explanation.id} gutterSize='s'>
              <EuiFlexItem grow={false}>
                <div
                  data-test-id={explanation['data-test-id']}
                  data-test-explanation-id={explanation.id}
                  data-test-params={explanation.testParams}
                >
                  {explanation.message}
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          ))}
        </div>
      </Fragment>
    )
  }
}

export default withErrorBoundary(DeploymentConfigurationChangeExplain)
