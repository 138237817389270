/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import messages from '../messages'

import HardwareSummary from './HardwareSummary'

import type { FunctionComponent } from 'react'
import type { ChangeSummaryProps as Props } from './types'

const ChangeSummary: FunctionComponent<Props> = ({ exact, partial }): JSX.Element => {
  if (exact) {
    return (
      <EuiText size='s' color='subdued'>
        <FormattedMessage {...messages.noChanges} />
      </EuiText>
    )
  }

  if (partial) {
    return <HardwareSummary instance={partial} />
  }

  return (
    <EuiText size='s' color='subdued'>
      <FormattedMessage {...messages.removedInstance} />
    </EuiText>
  )
}

export default ChangeSummary
