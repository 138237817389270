/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import {
  CuiFilterContext,
  createQueryExecutor,
  getFieldValues,
  toggleFieldValue,
} from '../../../cui'

import { getQueryModel, schema, defaultFields } from './schema'
import { getFilters } from './filters'
import { serializeTag, deserializeTag } from './helpers'

import type { DeserializedTag } from './helpers'
import type { AllocatorSearchResult } from '../../../types'
import type { ControlledFilterQuery } from '../../../cui'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

import './allocatorFilterContext.scss'

type Props = {
  intl: IntlShape
  query: ControlledFilterQuery
  onChange: (q: any) => void
  allocators?: AllocatorSearchResult[]
  includeFeatureFilter: boolean
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `allocator-filter-context.placeholder`,
    defaultMessage: 'e.g.: {query}',
  },
  emptyMessage: {
    id: `allocator-filter-context.no-matches`,
    defaultMessage: `No allocators match your query`,
  },
})

export const executeQuery = createQueryExecutor({
  defaultFields,
  getQueryModel,
})

const AllocatorFilterContext: FunctionComponent<Props> = ({
  intl,
  query,
  onChange,
  allocators,
  includeFeatureFilter,
}) => {
  const { formatMessage } = intl

  const filters = getFilters({
    intl,
    allocators,
    includeFeatureFilter,
  })

  const placeholder = formatMessage(messages.searchBarPlaceholder, {
    query: `healthy:y fill<30 available>=64`,
  })

  const emptyMessage = formatMessage(messages.emptyMessage)

  return (
    <CuiFilterContext
      query={query}
      onChange={onChange}
      records={allocators}
      schema={schema}
      filters={filters}
      executeQuery={executeQuery}
      placeholder={placeholder}
      emptyMessage={emptyMessage}
    />
  )
}

export function getFilterTags({ query }: { query: ControlledFilterQuery }) {
  return getFieldValues({ query, schema, field: `tag` }).map(deserializeTag)
}

export function toggleFilterTag({
  query,
  tag,
}: {
  query: ControlledFilterQuery
  tag: DeserializedTag
}) {
  return toggleField({
    query,
    field: `tag`,
    value: serializeTag(tag),
  })
}

export function toggleField({
  query,
  field,
  value,
}: {
  query: ControlledFilterQuery
  field: string
  value: string
}) {
  return toggleFieldValue({
    query,
    schema,
    field,
    value,
  })
}

export default injectIntl(AllocatorFilterContext)
