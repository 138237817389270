/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { filter, find, isEmpty, map, mapValues, groupBy, xor, isNull, cloneDeep } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiCode,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
  EuiListGroupItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
} from '@elastic/eui'

import { isSizedSliderResource } from '@/lib/stackDeployments/selectors/fundamentals'

import { CuiAlert, withErrorBoundary } from '../../../../../cui'
import DocLink from '../../../../DocLink'
import StackDeploymentUpdateDryRunWarnings from '../../../../StackDeploymentUpdateDryRunWarnings'
import StackDeploymentUpdateDryRunWarningCheck from '../../../../StackDeploymentUpdateDryRunWarningCheck'
import EsVersion from '../../../../DeploymentConfigure/EsVersion'
import UpgradeAssistant from '../../../../DeploymentConfigure/UpgradeAssistant'
import { gte, gt, lt, satisfies, rcompare } from '../../../../../lib/semver'
import { getIncompatibleCustomPlugins } from '../../../../../lib/plugins'
import { getSliderPrettyName } from '../../../../../lib/sliders'
import { getReadOnlyCallout } from '../../../../../lib/sliders/upgrades'
import {
  createUpdateRequestFromGetResponse,
  enrichDeploymentFromTemplate,
  getHighestSliderVersion,
  getLowestSliderVersion,
  getSliderInstancesTypeRequiringUpgrade,
  getSliderVersion,
  getUpdateRequestWithPluginsAndTransforms,
  hasMismatchingVersions,
  isMajorVersionChange,
  setDeploymentVersion,
  showFleetWarningOnUpgrade,
  validateUpdateRequest,
  isUpgradeAssistantSupported,
} from '../../../../../lib/stackDeployments'
import { promoteConfigurationEntriesToPlan } from '../../../../../lib/stackDeployments/configuration'
import {
  getEsPlan,
  getFirstAvailableSliderClusterFromGet,
  getUpsertVersion,
  getVersion,
  isSystemOwned,
  isUsingNodeRoles,
} from '../../../../../lib/stackDeployments/selectors'
import {
  hasAnyUserSettingsInDeployment,
  hasOverridesPreventingUpgrade,
} from '../../../../../lib/stackDeployments/userSettings'
import {
  checkForIncompatibilities,
  isSliderDeprecated,
} from '../../../../../lib/deployments/upgrades'
import { formatAsSentence } from '../../../../../lib/string'
import { replaceIn } from '../../../../../lib/immutability-helpers'
import { MUST_UPGRADE_TO_5_6_FIRST } from '../../../../../constants/errors'
import { getConfigForKey } from '../../../../../store'
import lightTheme from '../../../../../lib/theme/light'

import VerifyPlugins from './VerifyPlugins'
import ReviewYamlSettings from './ReviewYamlSettings'
import ConfirmUpgrade from './ConfirmUpgrade'
import ClearUserSettingOverrides from './ClearUserSettingsOverrides'

import type { ReactNode } from 'react'
import type { StackVersionConfig, DeploymentUpdateRequest } from '@/lib/api/v1/types'
import type {
  AsyncRequestError,
  SliderInstanceType,
  UnavailableVersionUpgrades,
  WellKnownSliderInstanceType,
} from '@/types'
import type { Props, State } from './types'

const { euiBreakpoints } = lightTheme

/*
 * As requested in https://github.com/elastic/cloud/issues/85251, Kibana has
 * some stuff in 7.14+ that makes ILM less of a pain point when migrating to
 * node_roles. So default to migrating to node_roles when upgrading to that
 * version, stopping at 8.0 when node_type isn't supported anymore and the
 * matter will be forced.
 */
const DEFAULT_MIGRATE_TO_NODE_ROLES_RANGE = '^7.14.0'
const REQUIRED_MIGRATE_TO_NODE_ROLES_RANGE = '>=7.17.0'

interface DryRunParams {
  dryRunCheckPassed: boolean
}

class DeploymentVersionUpgradeModalClass extends Component<Props, State> {
  state: State = {
    editorState: this.getInitialEditorState(),
    showYamlModal: false,
    showConfirmationModal: false,
    disableUpgradeButton: null,
    selectedPluginUrls: [],
    migrateToNodeRoles: true,
  }

  componentDidMount(): void {
    const { fetchExtensions } = this.props
    const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

    // ECE doesn't support extensions, and SaaS adminconsoles doesn't get user-scoped extensions
    if (isUserConsole) {
      fetchExtensions()
    }
  }

  componentWillUnmount(): void {
    const { resetUpdateDeployment } = this.props
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    resetUpdateDeployment(deploymentUnderEdit.id)
  }

  render(): ReactNode {
    const { onCancel } = this.props
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    return (
      <Fragment>
        <EuiOverlayMask>
          <EuiModal
            onClose={onCancel}
            style={{ width: euiBreakpoints.m }}
            data-test-id='deployment-version-upgrade-modal'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <FormattedMessage
                  id='upgradable-deployment-version.upgrade-modal-title'
                  defaultMessage='Upgrade deployment'
                />
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiErrorBoundary>{this.renderUpgradeModalBody()}</EuiErrorBoundary>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiFlexGroup
                gutterSize='s'
                justifyContent='flexEnd'
                alignItems='center'
                responsive={false}
              >
                <EuiFlexItem grow={true}>{this.renderNodeRolesMigrationCheckbox()}</EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty data-test-id='cancelUpgrade-btn' onClick={onCancel}>
                    <FormattedMessage
                      id='upgradable-deployment-version.cancel-upgrade'
                      defaultMessage='Cancel'
                    />
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <StackDeploymentUpdateDryRunWarningCheck deploymentId={deploymentUnderEdit.id}>
                  {({ dryRunCheckPassed }) => (
                    <Fragment>{this.renderUpgradeButton({ dryRunCheckPassed })}</Fragment>
                  )}
                </StackDeploymentUpdateDryRunWarningCheck>
              </EuiFlexGroup>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>

        {this.renderReviewYamlSettingsModal()}
        {this.renderConfirmationModal()}
      </Fragment>
    )
  }

  renderUpgradeModalBody(): ReactNode {
    const { updateStackDeploymentRequest, intl } = this.props
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    const availableVersions = this.getAvailableVersions()
    const unavailableVersionUpgrades = this.getUnavailableVersionUpgrades(availableVersions)

    const lowestVersion = getLowestSliderVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })

    return (
      <Fragment>
        <EsVersion
          lastVersion={lowestVersion}
          availableVersions={availableVersions}
          unavailableVersionUpgrades={unavailableVersionUpgrades}
          version={pendingVersion}
          onUpdate={(newVersion) => this.updateVersion(newVersion)}
          checkVersionDisabled={(version) =>
            checkForIncompatibilities({ deployment: deploymentUnderEdit, version, intl })
          }
        />

        {this.renderFleetAdditionWarning()}
        {this.renderSelfServiceUpgradeErrors()}
        {this.renderVersionErrors()}
        {this.renderUpgradeAssistantDetails()}
        {this.renderDryRunValidationWarnings()}
        {this.renderIncompatiblePluginWarnings()}
        {this.renderIncompatibilityNotices()}
        <ClearUserSettingOverrides deployment={deployment} updatePlan={this.updateSliderPlan} />

        {updateStackDeploymentRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{updateStackDeploymentRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderVersionErrors(): ReactNode {
    const {
      editorState: { deployment },
    } = this.state

    if (!this.isUpgradeAvailable()) {
      return null
    }

    const error = this.getVersionError()

    if (error == null) {
      return null
    }

    const pendingVersion = getUpsertVersion({ deployment })
    const message = getVersionErrorMessage(error, pendingVersion)

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut title={getGenericErrorTitle()} color='danger'>
          <p>{message}</p>

          <p>
            <FormattedMessage
              id='deployment-configure-es-version.recommend-upgrade-stack-guide'
              defaultMessage='We highly recommend reading the {upgradeStackGuide} before continuing.'
              values={{
                upgradeStackGuide: (
                  <DocLink link='upgradingDocLink'>
                    <FormattedMessage
                      id='deployment-configure-es-version.stack-upgrade-guide'
                      defaultMessage='stack upgrade guide'
                    />
                  </DocLink>
                ),
              }}
            />
          </p>
        </EuiCallOut>
      </Fragment>
    )
  }

  renderFleetAdditionWarning(): ReactNode {
    const { deploymentTemplate } = this.props
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    if (!deploymentTemplate) {
      return null
    }

    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })

    const show = showFleetWarningOnUpgrade({
      deployment: deploymentUnderEdit,
      deploymentTemplate,
      fromVersion: version,
      toVersion: pendingVersion,
    })

    if (!show) {
      return null
    }

    const title = (
      <FormattedMessage
        id='fleet-addition.title'
        defaultMessage="We're adding Fleet to your APM instance"
      />
    )

    const body = (
      <Fragment>
        <FormattedMessage
          id='fleet-addition.body'
          defaultMessage='<p>Fleet helps you manage all your Elastic Agents, get their status, make updates, and add integrations with just a few clicks.</p><p><strong>If your APM instance uses over 300MB of memory as shown in the Stack Monitoring app, we recommend increasing the size before upgrading.</strong> If needed, just edit your deployment and add capacity to APM & Fleet. <learnMoreLink>Learn more about the APM & Fleet upgrade.</learnMoreLink></p>'
          values={{
            p: (content) => <p>{content}</p>,
            strong: (content) => <strong>{content}</strong>,
            learnMoreLink: (content) => <DocLink link='fleetOverview'>{content}</DocLink>,
          }}
        />
      </Fragment>
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut title={title}>{body}</EuiCallOut>
      </Fragment>
    )
  }

  renderSelfServiceUpgradeErrors(): ReactNode {
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    const plan = getEsPlan({ deployment })
    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })

    const majorVersionChange = isMajorVersionChange(version, pendingVersion)

    if (!majorVersionChange) {
      return null
    }

    /* The upgrade can't be self-serviced because users
     * can't change `user_settings_override_yaml` themselves.
     */
    const securityRealmOverrides = hasOverridesPreventingUpgrade({ plan, version })

    if (securityRealmOverrides) {
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut title={getGenericErrorTitle()} color='danger'>
            <FormattedMessage
              id='upgradable-deployment-version.confirm-to-perform-major-version-upgrade-override'
              defaultMessage='The existing settings do not allow for a self-service upgrade. Contact support for assistance to complete the upgrade.'
            />
          </EuiCallOut>
        </Fragment>
      )
    }

    return null
  }

  renderIncompatiblePluginWarnings(): ReactNode {
    const { extensions } = this.props
    const {
      editorState: { deployment, deploymentUnderEdit },
      selectedPluginUrls,
    } = this.state

    const plan = getEsPlan({ deployment })

    if (!plan) {
      return null
    }

    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })
    const unsafeUserBundles = getIncompatibleCustomPlugins(version, pendingVersion, plan)

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <VerifyPlugins
          plan={plan}
          selectedPluginUrls={selectedPluginUrls}
          togglePlugin={this.togglePlugin.bind(this)}
          unsafeBundles={unsafeUserBundles}
          extensions={extensions || []}
          setDisabledUpgradeButton={this.setDisabledUpgradeButton.bind(this)}
        />
      </Fragment>
    )
  }

  renderUpgradeAssistantDetails(): ReactNode {
    const {
      editorState: { deploymentUnderEdit, deployment },
    } = this.state

    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })

    if (!version || !pendingVersion) {
      return null
    }

    const majorVersionChange = isMajorVersionChange(version, pendingVersion)

    if (!majorVersionChange) {
      return null
    }

    return <UpgradeAssistant deployment={deploymentUnderEdit} pendingVersion={pendingVersion} />
  }

  renderIncompatibilityNotices(): ReactNode {
    const {
      intl: { formatMessage },
    } = this.props
    const {
      editorState: { deploymentUnderEdit, deployment },
    } = this.state

    const pendingVersion = getUpsertVersion({ deployment })

    // The read only API the migration wizard uses first became available in 7.6.0
    if (this.isAppSearchDeprecated() && pendingVersion && gte(pendingVersion, `7.6.0`)) {
      const { startAppSearchToEnterpriseSearchMigration, onCancel } = this.props
      return (
        <Fragment>
          <EuiSpacer size='m' />

          <EuiCallOut
            title={
              <FormattedMessage
                id='upgradable-deployment-version.appsearch-limit'
                defaultMessage='App Search will become Enterprise Search. To upgrade to Elastic Stack 7.7.0 and above, deployments using App Search instances must be migrated.'
              />
            }
            data-test-id='upgradable-deployment-version-appsearch-limit'
          >
            <EuiButton
              onClick={() => {
                startAppSearchToEnterpriseSearchMigration({ deployment: deploymentUnderEdit })
                onCancel()
              }}
            >
              <FormattedMessage
                id='upgradable-deployment-version.appsearch-start-migration'
                defaultMessage='Start migration'
              />
            </EuiButton>
          </EuiCallOut>
        </Fragment>
      )
    }

    const resourceUsageWarning = this.renderResourceUsageWarnings()

    if (resourceUsageWarning) {
      return resourceUsageWarning
    }

    const readOnlyCallout = getReadOnlyCallout({ deployment: deploymentUnderEdit })

    if (readOnlyCallout) {
      return (
        <Fragment>
          <EuiSpacer size='m' />
          {readOnlyCallout}
        </Fragment>
      )
    }

    if (hasMismatchingVersions({ deployment: deploymentUnderEdit })) {
      const highestExistingVersion = getHighestSliderVersion({ deployment: deploymentUnderEdit })
      const mismatchingSliderNames = getSliderInstancesTypeRequiringUpgrade({
        deployment: deploymentUnderEdit,
      }).map((sliderInstanceType) => {
        const version = getSliderVersion({ deployment: deploymentUnderEdit, sliderInstanceType })
        return formatMessage(getSliderPrettyName({ sliderInstanceType, version }))
      })
      const formattedSliderNames = formatAsSentence(mismatchingSliderNames)

      if (formattedSliderNames) {
        return (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut
              color='primary'
              iconType='questionInCircle'
              data-test-id='upgradable-deployment-version-version-mismatch'
              title={
                <FormattedMessage
                  id='deployment-upgrades.incompatible-version-mismatch-title'
                  defaultMessage='Upgrade {formattedSliderNames} to {highestExistingVersion}'
                  values={{
                    highestExistingVersion,
                    formattedSliderNames,
                  }}
                />
              }
            >
              <FormattedMessage
                id='deployment-upgrades.incompatible-version-mismatch-description'
                defaultMessage='{formattedSliderNames} {sliderCount, plural, one {needs} other {need}} to be at {highestExistingVersion} before upgrading your deployment to a newer version.'
                values={{
                  highestExistingVersion,
                  formattedSliderNames,
                  sliderCount: mismatchingSliderNames.length,
                }}
              />
            </EuiCallOut>
          </Fragment>
        )
      }
    }

    return null
  }

  renderDryRunValidationWarnings(): ReactNode {
    const {
      editorState: { deploymentUnderEdit },
      showYamlModal,
    } = this.state

    // We also display feedback from the dry run within the yaml modal
    if (!this.isUpgradeAvailable() || showYamlModal) {
      return null
    }

    const deploymentUpdateRequest = this.getUpdateRequest()

    return (
      <StackDeploymentUpdateDryRunWarnings
        deploymentId={deploymentUnderEdit.id}
        deployment={deploymentUpdateRequest}
        spacerBefore={true}
      />
    )
  }

  renderResourceUsageWarnings(): ReactNode {
    const {
      editorState: { deploymentUnderEdit, deployment },
    } = this.state
    const sliderInstanceType: SliderInstanceType = `enterprise_search`
    const versionThreshold = `7.12.0`

    const resource = getFirstAvailableSliderClusterFromGet({
      deployment: deploymentUnderEdit,
      sliderInstanceType,
    })

    if (!resource) {
      return null
    }

    const hasEnterpriseSearch = isSizedSliderResource({ resource })

    if (!hasEnterpriseSearch) {
      return null
    }

    const currentVersion = getVersion({ deployment: deploymentUnderEdit })

    if (isNull(currentVersion) || gte(currentVersion, versionThreshold)) {
      return null
    }

    const pendingVersion = getUpsertVersion({ deployment })

    if (isNull(pendingVersion) || lt(pendingVersion, versionThreshold)) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut
          color='warning'
          data-test-subj='ent-search-storage-usage-increase'
          title={
            <FormattedMessage
              id='ent-search-storage-usage-increase.title'
              defaultMessage='This upgrade requires a migration'
            />
          }
        >
          <EuiListGroup maxWidth={false} style={{ margin: 0, padding: 0 }}>
            <EuiListGroupItem
              size='s'
              wrapText={true}
              label={
                <FormattedMessage
                  id='ent-search-storage-usage-increase.body'
                  defaultMessage='This version of {sliderPrettyName} brings an updated and optimized data structure, and temporarily requires additional storage capacity during the upgrade phase. Once completed, certain indices can be purged, resulting in a smaller, more efficient deployment. Proceeding will set {sliderPrettyName} into read-only mode for the duration of the upgrade process. {docLink}'
                  values={{
                    sliderPrettyName: (
                      <FormattedMessage
                        {...getSliderPrettyName({ sliderInstanceType, version: currentVersion })}
                      />
                    ),
                    docLink: (
                      <DocLink link='entSearchStorageIncrease'>
                        <FormattedMessage
                          id='ent-search-storage-usage-increase.learnmore'
                          defaultMessage='Learn more about the improvements, migration process and data removal steps'
                        />
                      </DocLink>
                    ),
                  }}
                />
              }
            />
          </EuiListGroup>
        </EuiCallOut>
      </Fragment>
    )
  }

  renderUpgradeButton({ dryRunCheckPassed }: DryRunParams) {
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })
    const majorVersionChange = isMajorVersionChange(version, pendingVersion)
    const disabled = this.isUpgradeButtonDisabled({ dryRunCheckPassed })
    const isFetchingDependencies = this.isFetchingDependencies()

    if (majorVersionChange) {
      const validationInProgress = this.isFetchingMajorVersionUpgradeDependencies()
      const needsReview = this.isReviewRequired({ dryRunCheckPassed })

      // We skip while validationInProgress to avoid flickers between "Review user settings" & "Upgrade"
      if (!validationInProgress && needsReview) {
        return (
          <EuiFlexItem grow={false}>
            <EuiButton
              data-test-id='upgradable-deployment-version-majorVersionBtn'
              fill={true}
              color='primary'
              disabled={disabled}
              isLoading={isFetchingDependencies}
              onClick={this.onOpenYamlSettings}
            >
              <FormattedMessage
                id='upgradable-deployment-version.review-user-settings-before-upgrade-deployment'
                data-test-id='upgrade-deployment-with-review'
                defaultMessage='Review user settings'
              />
            </EuiButton>
          </EuiFlexItem>
        )
      }

      return (
        <EuiFlexItem grow={false}>
          <EuiButton
            data-test-id='upgradable-deployment-version-majorVersionBtn'
            fill={true}
            color='primary'
            disabled={disabled}
            isLoading={isFetchingDependencies || validationInProgress}
            onClick={this.onOpenConfirmationModal}
          >
            <FormattedMessage
              id='upgradable-deployment-version.upgrade-deployment'
              defaultMessage='Upgrade'
            />
          </EuiButton>
        </EuiFlexItem>
      )
    }

    return (
      <EuiFlexItem grow={false}>
        <EuiButton
          data-test-id='upgradable-deployment-version-minorVersionBtn'
          disabled={disabled}
          isLoading={isFetchingDependencies}
          color='primary'
          fill={true}
          onClick={this.onOpenConfirmationModal}
        >
          <FormattedMessage
            id='upgradable-deployment-version.upgrade-deployment'
            defaultMessage='Upgrade'
          />
        </EuiButton>
      </EuiFlexItem>
    )
  }

  renderConfirmationModal(): ReactNode {
    const { updateStackDeploymentRequest } = this.props

    const {
      editorState: { deployment },
      showConfirmationModal,
    } = this.state

    if (!showConfirmationModal) {
      return null
    }

    const pendingVersion = getUpsertVersion({ deployment })

    if (!pendingVersion) {
      return null // sanity
    }

    return (
      <ConfirmUpgrade
        deploymentUpdateRequest={updateStackDeploymentRequest}
        version={pendingVersion}
        onCancel={this.onCloseConfirmationModal}
        onConfirm={this.onSave}
      />
    )
  }

  renderReviewYamlSettingsModal(): ReactNode {
    const { updateStackDeploymentRequest } = this.props

    const {
      editorState: { deployment, deploymentUnderEdit },
      showYamlModal,
    } = this.state

    const version = getVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })
    const majorVersionChange = isMajorVersionChange(version, pendingVersion)

    if (!showYamlModal) {
      return null
    }

    if (!version || !pendingVersion || !majorVersionChange) {
      return null
    }

    const enrichedDeployment = this.enrichDeploymentWithNodeRolesIfRequired()

    return (
      <ReviewYamlSettings
        deploymentUpdateRequest={updateStackDeploymentRequest}
        deploymentUnderEdit={deploymentUnderEdit}
        deployment={enrichedDeployment}
        version={pendingVersion}
        updatePlan={this.updateSliderPlan}
        onClose={this.onCloseYamlSettings}
        onSave={this.onSave}
      />
    )
  }

  renderNodeRolesMigrationCheckbox(): ReactNode {
    const { migrateToNodeRoles } = this.state

    if (!this.canMigrateToNodeRoles()) {
      return null
    }

    const isRequired = this.requireMigrationToNodeRoles()

    const label = (
      <FormattedMessage
        id='deployment-configure-es-version.migrate-to-node-roles'
        defaultMessage='Migrate node_type to node_roles{required}. {docLink}'
        values={{
          required: isRequired ? (
            <Fragment>
              {` `}
              <FormattedMessage
                id='deployment-configure-es-version.migrate-to-node-roles.required'
                defaultMessage='(required from version 8.0)'
              />
            </Fragment>
          ) : null,
          docLink: (
            <DocLink link='nodeTypesDocLink'>
              <FormattedMessage
                id='deployment-configure-es-version.migrate-to-node-roles.doc-link'
                defaultMessage='Learn more'
              />
            </DocLink>
          ),
        }}
      />
    )

    return (
      <EuiCheckbox
        id='deployment-configure-es-version-migrate-to-node-roles'
        checked={this.isMigratingToNodeRoles()}
        disabled={isRequired}
        label={label}
        onChange={() => this.setState({ migrateToNodeRoles: !migrateToNodeRoles })}
      />
    )
  }

  getInitialEditorState(): State['editorState'] {
    const { deployment, deploymentTemplate } = this.props

    const editorState = {
      deploymentUnderEdit: deployment,
      deployment: createUpdateRequestFromGetResponse({
        deployment,
        deploymentTemplate,
      }),
    }

    if (hasMismatchingVersions({ deployment })) {
      const intendedVersion = getVersion({ deployment })
      // set the initial version to the esVersion
      // as all other versions will be disabled.
      setDeploymentVersion({
        deployment: editorState.deployment,
        intendedVersion,
      })
    }

    // relocate topology-level settings to plan-level as we arrive; we'll also
    // do it again on save in case it applies after editing
    promoteConfigurationEntriesToPlan({ deployment: editorState.deployment })

    return editorState
  }

  getVersionError(): AsyncRequestError | undefined {
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    const pendingVersion = getUpsertVersion({ deployment })
    const previousVersion = getVersion({ deployment: deploymentUnderEdit })

    // Needs to upgrade to 5.6.0 to use the cloud upgrade assistant
    if (
      !isUpgradeAssistantSupported(previousVersion) &&
      isUpgradeAssistantSupported(pendingVersion) &&
      isMajorVersionChange(previousVersion, pendingVersion)
    ) {
      return MUST_UPGRADE_TO_5_6_FIRST
    }

    const { updateStackDeploymentRequest } = this.props

    return updateStackDeploymentRequest.error
  }

  isUpgradeButtonDisabled = ({ dryRunCheckPassed }: DryRunParams): boolean => {
    const {
      editorState: { deployment, deploymentUnderEdit },
      disableUpgradeButton,
    } = this.state

    const plan = getEsPlan({ deployment })
    const version = getVersion({ deployment: deploymentUnderEdit })
    const lowestVersion = getLowestSliderVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })
    const majorVersionChange = isMajorVersionChange(version, pendingVersion)
    const loading = this.isFetchingDependencies()
    const hasUserSettings = hasAnyUserSettingsInDeployment({ deployment: deploymentUnderEdit })

    const validationErrors = validateUpdateRequest({
      deploymentUnderEdit,
      deployment,
    })

    if (loading) {
      return true
    }

    if (disableUpgradeButton) {
      return true
    }

    if (!version && !lowestVersion) {
      return true
    }

    if (lowestVersion === pendingVersion) {
      return true
    }

    if (this.isAppSearchDeprecated(pendingVersion)) {
      return true
    }

    if (!isEmpty(validationErrors)) {
      return true
    }

    if (majorVersionChange) {
      if (this.isFetchingMajorVersionUpgradeDependencies()) {
        return true
      }

      // if the user has 'xpack.security.authc.realms' `user_settings_override_yaml`
      // they can't update the settings themselves, don't show any dialog
      if (hasOverridesPreventingUpgrade({ plan, version })) {
        return true
      }

      if (!this.isReadyForUpgrade() && !hasUserSettings) {
        return true
      }
    }

    if (!dryRunCheckPassed && !hasUserSettings) {
      return true
    }

    return false
  }

  isFetchingDependencies(): boolean {
    const { isLoadingRegion, extensions } = this.props
    const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

    if (isLoadingRegion) {
      return true
    }

    if (isUserConsole && !extensions) {
      return true
    }

    return false
  }

  isFetchingMajorVersionUpgradeDependencies(): boolean {
    const { updateStackDeploymentDryRunRequest, fetchUpgradeAssistantStatusRequest } = this.props

    return [updateStackDeploymentDryRunRequest, fetchUpgradeAssistantStatusRequest].some(
      ({ inProgress }) => inProgress,
    )
  }

  onCloseYamlSettings = (): void => this.setState({ showYamlModal: false })

  onOpenYamlSettings = (): void => this.setState({ showYamlModal: true })

  onCloseConfirmationModal = (): void => this.setState({ showConfirmationModal: false })

  onOpenConfirmationModal = (): void => this.setState({ showConfirmationModal: true })

  onSave = (): void => {
    const { fetchDeployment, updateDeployment, redirectOnUpgrade = true, onCancel } = this.props
    const {
      editorState: {
        deploymentUnderEdit: { id: deploymentId },
      },
    } = this.state

    const deployment = this.getUpdateRequest()

    updateDeployment({
      deploymentId,
      deployment,
      redirect: redirectOnUpgrade,
      dryRun: false,
    })
      .then(() => {
        fetchDeployment({ deploymentId })
      })
      .finally(() => {
        this.onCloseConfirmationModal()
        onCancel()
      })
  }

  updateSliderPlan = (
    sliderInstanceType: WellKnownSliderInstanceType,
    path: string | string[],
    value: any,
  ): void => {
    const { editorState } = this.state

    const updatedEditorState = replaceIn(
      editorState,
      ['deployment', 'resources', sliderInstanceType, '0', 'plan', ...path],
      value,
    )

    this.setState({ editorState: updatedEditorState })
  }

  getUpdateRequest(): DeploymentUpdateRequest {
    const { extensions } = this.props
    const {
      editorState: { deploymentUnderEdit },
      selectedPluginUrls,
    } = this.state

    const deployment = this.enrichDeploymentWithNodeRolesIfRequired()

    const sanitizedDeployment = getUpdateRequestWithPluginsAndTransforms({
      updateRequest: deployment,
      deploymentUnderEdit,
      extensions: extensions || [],
      selectedPluginUrls,
    })

    // relocate topology-level settings to plan-level as we leave in case they
    // weren't moved at the start of editing but can be moved now
    promoteConfigurationEntriesToPlan({ deployment: sanitizedDeployment })

    return sanitizedDeployment
  }

  enrichDeploymentWithNodeRolesIfRequired() {
    const { deploymentTemplate } = this.props
    const {
      editorState: { deployment },
    } = this.state

    const forceNodeRoles = this.canMigrateToNodeRoles() && this.isMigratingToNodeRoles()

    if (!deploymentTemplate || !forceNodeRoles) {
      return deployment
    }

    // we're possibly mutating this to add node_roles, so make a copy
    const clonedDeployment = cloneDeep(deployment)
    const removeNodeTypes = this.requireMigrationToNodeRoles()

    enrichDeploymentFromTemplate({
      deployment: clonedDeployment,
      deploymentTemplate: deploymentTemplate.deployment_template,
      forceNodeRoles: true,
      removeNodeTypes,
    })

    return clonedDeployment
  }

  updateVersion(newVersion: string): void {
    const { versionStacks } = this.props
    const { editorState } = this.state

    // We're mutating editorState below, so lets make a copy
    const clonedEditorState = cloneDeep(editorState)
    const stackVersion = find(versionStacks, { version: newVersion })

    setDeploymentVersion({
      deployment: clonedEditorState.deployment,
      intendedVersion: newVersion,
      stackVersion,
    })

    this.setState({
      editorState: clonedEditorState,
    })
  }

  isReviewRequired = ({ dryRunCheckPassed }: DryRunParams): boolean => {
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    if (dryRunCheckPassed && this.isReadyForUpgrade()) {
      return false
    }

    // If the dry run or upgrade assistant failed, allow the users to try updating their settings when present
    return hasAnyUserSettingsInDeployment({ deployment: deploymentUnderEdit })
  }

  isReadyForUpgrade(): boolean {
    const { upgradeAssistantStatus } = this.props

    if (!upgradeAssistantStatus) {
      return true // If the assistant isn't available, we don't block upgrades
    }

    return upgradeAssistantStatus.ready_for_upgrade
  }

  setDisabledUpgradeButton = (value: boolean): void =>
    this.setState({ disableUpgradeButton: value })

  isAppSearchDeprecated(version?: string | null): boolean {
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    const availableVersions = version ? [version] : this.getAvailableVersions()

    return isSliderDeprecated(deploymentUnderEdit, `appsearch`, availableVersions)
  }

  getAvailableVersions = (): string[] => {
    const { availableVersions } = this.props
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    if (!availableVersions) {
      return []
    }

    // temporary fix, still waiting for the API to provide upgrade available for system owned deployment
    // see https://github.com/elastic/cloud/issues/31782
    return isSystemOwned({ deployment: deploymentUnderEdit })
      ? filter(availableVersions, (version) => satisfies(version, `<7`))
      : availableVersions
  }

  getUnavailableVersionUpgrades = (availableVersions: string[]): UnavailableVersionUpgrades => {
    const { versionStacks } = this.props
    const {
      editorState: { deploymentUnderEdit },
    } = this.state

    const version = getVersion({ deployment: deploymentUnderEdit })

    if (!version) {
      return {}
    }

    if (!versionStacks) {
      return {}
    }

    const unavailableVersionStacks: StackVersionConfig[] =
      versionStacks.filter(isUnavailableVersion)

    const upgradableVersions = unavailableVersionStacks.filter(isUpgradableFromGreaterVersion)
    const upgradableGroups = groupBy(upgradableVersions, `min_upgradable_from`)

    const unavailableVersionUpgrades = mapValues(upgradableGroups, (g) =>
      map(g, (versionStack) => versionStack.version!).sort(rcompare),
    )

    return unavailableVersionUpgrades

    function isUnavailableVersion(versionStack: StackVersionConfig): boolean {
      if (!versionStack.version) {
        return false
      }

      const available = availableVersions.includes(versionStack.version)

      if (available) {
        return false
      }

      const isLaterVersion = gt(versionStack.version, version!)

      return isLaterVersion
    }

    function isUpgradableFromGreaterVersion(versionStack: StackVersionConfig): boolean {
      const upgradableFrom = versionStack.min_upgradable_from

      // pre-release versions can't be upgraded into, thus no `min_upgradable_from`
      if (!upgradableFrom) {
        return false
      }

      const upgradableFromGreaterVersion = gt(upgradableFrom, version!)

      return upgradableFromGreaterVersion
    }
  }

  isUpgradeAvailable(): boolean {
    const {
      editorState: { deployment, deploymentUnderEdit },
    } = this.state

    // If a deployment is partially upgraded, we pick the lowest
    // found version found in each resource to upgrade from.
    const lowestVersion = getLowestSliderVersion({ deployment: deploymentUnderEdit })
    const pendingVersion = getUpsertVersion({ deployment })

    if (!pendingVersion || pendingVersion === lowestVersion) {
      return false
    }

    return true
  }

  togglePlugin(url: string): void {
    const { selectedPluginUrls } = this.state
    this.setState({ selectedPluginUrls: xor(selectedPluginUrls, [url]) })
  }

  canMigrateToNodeRoles(): boolean {
    const {
      editorState: { deployment },
    } = this.state

    // can't migrate to if we're already on node roles
    if (isUsingNodeRoles({ deployment })) {
      return false
    }

    // we NEED to migrate to node roles
    if (this.requireMigrationToNodeRoles()) {
      return true
    }

    // we SHOULD migrate to node roles
    return this.isPendingVersionWithinRange(DEFAULT_MIGRATE_TO_NODE_ROLES_RANGE)
  }

  requireMigrationToNodeRoles(): boolean {
    return this.isPendingVersionWithinRange(REQUIRED_MIGRATE_TO_NODE_ROLES_RANGE)
  }

  isPendingVersionWithinRange(range: string): boolean {
    const {
      editorState: { deployment },
    } = this.state

    const pendingVersion = getUpsertVersion({ deployment })

    if (!pendingVersion) {
      return false
    }

    if (satisfies(pendingVersion, range)) {
      return true
    }

    return false
  }

  isMigratingToNodeRoles(): boolean {
    const { migrateToNodeRoles } = this.state

    // If a migration is required, we short circuit here and always have it selected
    if (this.requireMigrationToNodeRoles()) {
      return true
    }

    return migrateToNodeRoles
  }
}

export default withErrorBoundary(injectIntl(DeploymentVersionUpgradeModalClass))

function getGenericErrorTitle(): ReactNode {
  return (
    <FormattedMessage
      id='deployment-upgrades.upgrade-unavailable'
      defaultMessage='Upgrade unavailable'
    />
  )
}

function getVersionErrorMessage(
  error: string | Error | undefined,
  version: string | null,
): ReactNode {
  if (error === MUST_UPGRADE_TO_5_6_FIRST) {
    return (
      <FormattedMessage
        data-test-id='must-upgrade-to-5-6'
        id='deployment-configure-es-version.must-upgrade-to-5-6'
        defaultMessage='You need to upgrade to 5.6 before you can upgrade to { version }'
        values={{ version }}
      />
    )
  }

  if (version) {
    return (
      <FormattedMessage
        data-test-id='cannot-upgrade-gave-up'
        id='deployment-configure-es-version.something-is-wrong'
        defaultMessage='There is a problem upgrading to {version}: {error}'
        values={{
          version,
          error: <EuiCode>{String(error)}</EuiCode>,
        }}
      />
    )
  }

  return null
}
