/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  CREATE_SAML_SECURITY_REALM,
  DELETE_SAML_SECURITY_REALM,
  FETCH_SAML_SETTINGS,
  UPDATE_SAML_SECURITY_REALM,
} from '../constants/actions'
import asyncRequest from '../../../actions/asyncRequests'
import {
  createSamlConfigurationUrl,
  deleteSamlConfigurationUrl,
  getSamlConfigurationUrl,
  updateSamlConfigurationUrl,
} from '../../../lib/api/v1/urls'

import type { RegionId } from '../../../types'
import type { SamlSettings } from '../../../lib/api/v1/types'

export function fetchSamlSecurityRealm(regionId: RegionId, realmId: string) {
  const url = getSamlConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: FETCH_SAML_SETTINGS,
    url,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function createSamlSecurityRealm(regionId: RegionId, payload: SamlSettings) {
  const url = createSamlConfigurationUrl({ regionId })

  return asyncRequest({
    type: CREATE_SAML_SECURITY_REALM,
    method: 'POST',
    url,
    payload,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export function updateSamlSecurityRealm(
  regionId: RegionId,
  realmId: string,
  payload: SamlSettings,
) {
  const url = updateSamlConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: UPDATE_SAML_SECURITY_REALM,
    method: 'PUT',
    url,
    payload,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function deleteSamlSecurityRealm(regionId: RegionId, realmId: string) {
  const url = deleteSamlConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: DELETE_SAML_SECURITY_REALM,
    method: 'DELETE',
    url,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}
