/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual, sortBy } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButton,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiCopy,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { stringifyTag } from '@/lib/tags'
import { CuiTable, withErrorBoundary, CuiAlert, addToast, parseError } from '@/cui'

import SpinButton from '../../../SpinButton'
import DeploymentTag from '../DeploymentTag'
import { messages } from '../messages'

import type { CuiTableColumn } from '@/cui'
import type { MetadataItem } from '@/lib/api/v1/types'
import type { AllProps as Props, State, Error, TagValidationError } from './types'

const MAX_TAGS = 18
const MAX_TAG_KEY_LENGTH = 27
const MAX_TAG_VALUE_LENGTH = 99

class DeploymentTagsFlyout extends Component<Props, State> {
  state: State = this.initState()

  currentTagKeyRef: HTMLInputElement | null

  render() {
    const {
      updateDeploymentTagsRequest: { error },
    } = this.props
    const { showCancelConfirmModal } = this.state

    return (
      <EuiFlyout maxWidth='32rem' size='l' ownFocus={true} onClose={this.onCancel}>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage id='deployment-tags.flyout.title' defaultMessage='Tags' />
            </h2>
          </EuiTitle>

          <EuiSpacer size='s' />

          <EuiText color='subdued' size='s'>
            <FormattedMessage
              id='deployment-tags.flyout.subtitle'
              defaultMessage='Tags make deployments easier to find. Use them to provide additional metadata about a deployment and its usage.'
            />
          </EuiText>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          {this.renderBody()}

          {showCancelConfirmModal && this.renderConfirmModal()}

          {error && (
            <EuiFlexItem grow={false}>
              <CuiAlert type='danger'>{error}</CuiAlert>
            </EuiFlexItem>
          )}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>{this.renderFooter()}</EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderBody() {
    const { currentTagKey, currentTagValue, tags, errors } = this.state
    const {
      intl: { formatMessage },
    } = this.props

    const error = this.getTagValidationError()

    return (
      <Fragment>
        <EuiForm>
          <EuiFormRow isInvalid={!!error} error={error} fullWidth={true}>
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={4}>
                <EuiFieldText
                  inputRef={(inputRef) => {
                    this.currentTagKeyRef = inputRef
                  }}
                  autoFocus={true}
                  isInvalid={!!errors.key_max_length || !!errors.invalid_key}
                  placeholder={formatMessage(messages.keyPlaceholder)}
                  onChange={this.onTagKeyChange}
                  onKeyDown={this.onKeyDown}
                  value={currentTagKey}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={4}>
                <EuiFieldText
                  isInvalid={!!errors.value_max_length || !!errors.invalid_value}
                  placeholder={formatMessage(messages.valuePlaceholder)}
                  onChange={this.onTagValueChange}
                  onKeyDown={this.onKeyDown}
                  value={currentTagValue}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={3} style={{ alignSelf: `right` }}>
                <EuiButton isDisabled={!this.canAddTags()} onClick={this.onAddTag}>
                  <FormattedMessage
                    id='deployment-tags.flyout.add-tag-button'
                    defaultMessage='Add'
                  />
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiForm>

        <EuiSpacer size='l' />

        {tags.length > 0 && (
          <CuiTable<MetadataItem>
            data-test-id='deployment-tags.flyout.table'
            rows={tags}
            columns={this.getTagsGridColumns()}
          />
        )}
      </Fragment>
    )
  }

  renderConfirmModal() {
    const {
      onClose,
      intl: { formatMessage },
      updateDeploymentTagsRequest: { inProgress },
    } = this.props

    return (
      <EuiConfirmModal
        title={formatMessage(messages.confirmModalTitle)}
        onCancel={onClose}
        onConfirm={this.onSaveTags}
        cancelButtonText={formatMessage(messages.confirmModalCancelButtonText)}
        confirmButtonText={formatMessage(messages.confirmModalConfirmButtonText)}
        defaultFocusedButton='confirm'
        isLoading={inProgress}
      >
        <FormattedMessage
          id='deployment-tags.flyout.confirm-message'
          defaultMessage='Some changes have not been saved and will be lost.'
        />
      </EuiConfirmModal>
    )
  }

  renderFooter() {
    const {
      updateDeploymentTagsRequest: { inProgress },
    } = this.props

    return (
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={this.onCancel} flush='left'>
            <FormattedMessage id='deployment-tags.flyout.cancel' defaultMessage='Cancel' />
          </EuiButtonEmpty>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <SpinButton fill={true} spin={inProgress} onClick={this.onSaveTags}>
            <FormattedMessage id='deployment-tags.flyout.send-tags' defaultMessage='Save' />
          </SpinButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  initState(): State {
    return {
      tags: this.props.tags,
      currentTagKey: '',
      currentTagValue: '',
      errors: {},
      showCancelConfirmModal: false,
    }
  }

  getTagsGridColumns(): Array<CuiTableColumn<MetadataItem>> {
    return [
      {
        label: (
          <FormattedMessage id='deployment-tags.flyout.table-tag-column' defaultMessage='Tag' />
        ),
        render: (tag) => <DeploymentTag tag={tag} />,
        width: '190px',
      },
      {
        label: (
          <FormattedMessage
            id='deployment-tags.flyout.table-actions-column'
            defaultMessage='Actions'
          />
        ),
        render: (tag) => {
          const {
            intl: { formatMessage },
          } = this.props
          const copyButton = (
            <EuiCopy textToCopy={stringifyTag(tag)}>
              {(copy) => (
                <EuiButtonIcon
                  iconType='copyClipboard'
                  onClick={copy}
                  aria-label={formatMessage(messages.copyClipboardTag)}
                />
              )}
            </EuiCopy>
          )

          const deleteButton = (
            <EuiButtonIcon
              iconType='trash'
              color='danger'
              aria-label={formatMessage(messages.copyClipboardTag)}
              onClick={() => this.onRemoveTag(tag)}
            />
          )

          return (
            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>{copyButton}</EuiFlexItem>

              <EuiFlexItem grow={false}>{deleteButton}</EuiFlexItem>
            </EuiFlexGroup>
          )
        },
        actions: true,
        width: '40px',
      },
    ]
  }

  canAddTags = (): boolean => {
    const { currentTagKey, currentTagValue } = this.state

    return (
      this.getTagValidationError() === null &&
      currentTagKey.trim() !== '' &&
      currentTagValue.trim() !== ''
    )
  }

  onTagKeyChange = (e): void => {
    const inputFieldText = e.target.value

    this.setState({
      currentTagKey: inputFieldText,
      errors: this.validateTag({ key: inputFieldText }),
    })
  }

  onTagValueChange = (e): void => {
    const inputFieldText = e.target.value

    this.setState({
      currentTagValue: inputFieldText,
      errors: this.validateTag({ value: inputFieldText }),
    })
  }

  onKeyDown = (e) => {
    if (e.code === 'Enter' && this.canAddTags()) {
      this.onAddTag()
    }
  }

  onAddTag = (): void => {
    const { currentTagKey, currentTagValue } = this.state

    this.setState((prevState) => ({
      tags: prevState.tags.concat({ key: currentTagKey, value: currentTagValue }),
      currentTagKey: '',
      currentTagValue: '',
    }))

    this.currentTagKeyRef?.focus()
  }

  onSaveTags = (): void => {
    const { updateDeploymentTags, onClose } = this.props
    const { tags } = this.state

    updateDeploymentTags(tags)
      .then(() => onClose())
      .catch((error) =>
        addToast({
          family: 'deployment-tags.flyout',
          color: 'danger',
          title: (
            <FormattedMessage
              id='deployment-tags.flyout.failure'
              defaultMessage='Could not save tags'
            />
          ),
          text: parseError(error),
        }),
      )
  }

  onRemoveTag = (removedTag: MetadataItem) => {
    this.setState((prevState) => ({
      tags: prevState.tags.filter(
        (tag) => !(tag.key === removedTag.key && tag.value === removedTag.value),
      ),
    }))
  }

  onCancel = () => {
    const showCancelConfirmModal = this.tagsHaveChanged()

    if (showCancelConfirmModal) {
      this.setState({
        showCancelConfirmModal,
      })

      return
    }

    this.props.onClose()
  }

  validateTag = ({
    key = this.state.currentTagKey,
    value = this.state.currentTagValue,
  }: {
    key?: string
    value?: string
  }): Error => {
    const { tags } = this.state

    const errors: Error = {}

    if (key.length > MAX_TAG_KEY_LENGTH) {
      errors.key_max_length = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.key-length-error'
          defaultMessage="The key can't exceed 27 characters."
        />
      )
    }

    if (value.length > MAX_TAG_VALUE_LENGTH) {
      errors.value_max_length = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.value-length-error'
          defaultMessage="The value can't exceed 99 characters."
        />
      )
    }

    if (tags.length === MAX_TAGS) {
      errors.max_tags = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.max-tags-error'
          defaultMessage='You have reached the limit number of tags for this deployment.'
        />
      )
    }

    if (
      tags.some(
        ({ key: currentKey, value: currentValue }) => currentKey === key && currentValue === value,
      )
    ) {
      errors.has_duplicates = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.duplicate-error'
          defaultMessage='This tag already exists. Enter a different tag.'
        />
      )
    }

    if (key.includes(':')) {
      errors.invalid_key = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.key-invalid-error'
          defaultMessage='The key must not contain colons.'
        />
      )
    }

    if (key.includes(' ')) {
      errors.invalid_key = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.white-spaces-key-error'
          defaultMessage='The key must not contain spaces.'
        />
      )
    }

    if (value.includes(' ')) {
      errors.invalid_value = (
        <FormattedMessage
          id='deployment-tags.flyout.validations.white-spaces-value-error'
          defaultMessage='The value must not contain spaces.'
        />
      )
    }

    return errors
  }

  getTagValidationError = (): TagValidationError => {
    const [error = null] = Object.values(this.state.errors)

    return error
  }

  tagsHaveChanged = (): boolean =>
    !isEqual(sortBy(this.props.tags, ['key', 'value']), sortBy(this.state.tags, ['key', 'value']))
}

export default withErrorBoundary(injectIntl(DeploymentTagsFlyout))
