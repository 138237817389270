/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { capitalize, uniq } from 'lodash'

import { DEPLOYMENT_HEALTH_API_ID } from '@/constants/health'

import { severityMappings } from '../../problems'

import type { Problem } from '../../problems'
import type { DeploymentHealth, HealthIndicator, ResourceHealth } from '@/lib/api/v1/types'

function isHealthy(status: DeploymentHealth['status']): boolean {
  return capitalize(status) === 'Green'
}

function isCritical(status: DeploymentHealth['status']): boolean {
  return capitalize(status) === 'Red'
}

/**
 * Converts responses from the deployment health API into Problems, which can be presented in the StackDeploymentHealthProblems
 * component. The response from this API should eventually replace the majority of Problems presented in this component.
 * However, at this time it only covers a subset of Elasticsearch related problems.
 *
 * @see {@link getDeploymentHealthUrl} for more information on the Deployment Health API
 * @param {DeploymentHealth} health - the deployment health API response, representing the current health of a deployment
 */
export function getProblemsFromDeploymentHealthApi(health?: DeploymentHealth | null): Problem[] {
  if (!health || isHealthy(health.status)) {
    return []
  }

  return getDeploymentHealthApiProblems(health)
}

function getDeploymentHealthApiProblems(health: DeploymentHealth): Problem[] {
  // Currently, we only consume the elasticsearch resource
  if (!health) {
    return []
  }

  const resourceTypes = [
    'elasticsearch',
    'kibana',
    'apm',
    'integrations_server',
    'appsearch',
    'enterprise_search',
  ]
  const problems: Problem[] = []

  for (const resource of resourceTypes) {
    if (health[resource]) {
      problems.push(...getDeploymentProblemsFromResource(health[resource]))
    }
  }

  return problems
}

function getDeploymentProblemsFromResource(resource: ResourceHealth): Problem[] {
  if (!resource.indicators) {
    return []
  }

  return (
    Object.entries(resource.indicators || [])
      // We only need the indicator for filtering the data out, but
      // we need the object entries key,value pair for the mapping.
      .filter(([, indicator]) => !isHealthy(indicator.status))
      .map(mapIndicatorToProblem)
  )
}

const capitalizeAcronyms = (impactArea: string) => {
  if (impactArea === 'ilm') {
    return 'ILM'
  }

  return capitalize(impactArea)
}

function mapIndicatorToProblem([indicatorName, indicator]: [string, HealthIndicator]): Problem {
  return {
    'data-test-id': indicatorName,
    kind: DEPLOYMENT_HEALTH_API_ID,
    id: indicatorName,
    level: isCritical(indicator.status) ? `danger` : `warning`,
    message: indicator.symptom,
    severity: severityMappings.get(indicator.status.toLowerCase()),
    impacts: indicator.impacts,
    diagnosis: indicator.diagnosis,
    impactAreas: uniq(indicator.impacts?.flatMap((impact) => impact.impact_areas)).map(
      capitalizeAcronyms,
    ),
  }
}
