/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { defineMessages } from 'react-intl'

import ExternalLink from '@/components/ExternalLink'

import type { SliderInstanceDefinition } from '../types'

const definition: SliderInstanceDefinition = {
  messages: defineMessages({
    prettyName: {
      id: `sliders.integrationsServer.prettyName`,
      defaultMessage: `Integrations Server`,
    },
    description: {
      id: `sliders.integrationsServer.description`,
      defaultMessage: `Integrations Server connects observability and security data from Elastic Agents and APM to Elasticsearch. Prepackaged integrations are available for a wide array of popular services and platforms. To see the full list, go to <integrationsLink>Elastic Integrations</integrationsLink>.`,
      values: {
        integrationsLink: (content) => (
          <ExternalLink
            showExternalLinkIcon={true}
            href={`https://docs.elastic.co/en/integrations`}
            className='docLink'
          >
            {content}
          </ExternalLink>
        ),
      },
    },
    instanceConfigurationDescription: {
      id: `sliders.integrationsServer.instanceConfigurationDescription`,
      defaultMessage: `Integrations Server connects observability and security data from Elastic Agents and APM to Elasticsearch. Prepackaged integrations are available for a wide array of popular services and platforms. To see the full list, go to <integrationsLink>Elastic Integrations</integrationsLink>.`,
      values: {
        integrationsLink: (content) => (
          <ExternalLink
            showExternalLinkIcon={true}
            href={`https://docs.elastic.co/en/integrations`}
            className='docLink'
          >
            {content}
          </ExternalLink>
        ),
      },
    },
  }),
  iconType: `logoObservability`,
  trialLimit: {
    memorySize: 1024,
    zoneCount: 1,
  },
  userSettingsFileName: ``,
}

export default definition
