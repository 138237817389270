/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiBadge } from '@elastic/eui'

import EOLVersionWarningBadge from '@/components/EndOfLifeWarnings/EndOfLifeVersionWarningBadge'

import Permission from '../../../lib/api/v1/permissions'
import { getSupportedSliderInstanceTypes } from '../../../lib/sliders'
import { elasticStackVersionUrl } from '../../../lib/urlBuilder'
import { rcompare, valid } from '../../../lib/semver'
import { CuiLink, CuiPermissibleControl, CuiTable, CuiSliderLogo } from '../../../cui'
import DangerButton from '../../DangerButton'

import WhitelistSwitch from './WhitelistSwitch'

import type { Region, AsyncRequestState, VersionNumber, RegionId } from '../../../types'
import type { StackVersionConfig } from '../../../lib/api/v1/types'
import type { FunctionComponent } from 'react'

import './elasticStackVersions.scss'

interface Props {
  region?: Region
  versions?: StackVersionConfig[]
  fetchVersions: (regionId: string) => Promise<any>
  deleteVersion: () => Promise<any>
  getDeleteVersionRequest: (regionId: RegionId, version: VersionNumber) => AsyncRequestState
  isWhitelistingEnabled: boolean
}

const VersionTable: FunctionComponent<Props> = ({
  versions,
  region,
  fetchVersions,
  deleteVersion,
  getDeleteVersionRequest,
  isWhitelistingEnabled,
}) => {
  const sortedVersions = versions && versions.sort(compareVersions)

  const statelessSliderInstanceTypes = getSupportedSliderInstanceTypes().filter(
    (sliderInstanceType) => sliderInstanceType !== `elasticsearch`,
  )

  const columns = [
    {
      label: (
        <FormattedMessage
          id='region-admin-versions-table.elasticsearch-version'
          defaultMessage='Elasticsearch version'
        />
      ),
      render: (version) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <EsVersionLink region={region!} version={version} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EOLVersionWarningBadge version={version.version} sliderInstanceType='elasticsearch' />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      sortKey: `version`,
      width: `220px`,
    },

    {
      label: (
        <FormattedMessage id='region-admin-versions-table.includes' defaultMessage='Includes' />
      ),
      render: (stackPack) => (
        <EuiFlexGroup justifyContent='flexStart' gutterSize='m'>
          {statelessSliderInstanceTypes
            .filter((sliderInstanceType) => stackPack[sliderInstanceType] != null)
            .map((sliderInstanceType) => {
              const logo = (
                <CuiSliderLogo
                  key={sliderInstanceType}
                  sliderInstanceType={sliderInstanceType}
                  version={stackPack.version}
                  showTooltip={true}
                />
              )
              const content =
                stackPack[sliderInstanceType].version !== stackPack.elasticsearch!.version ? (
                  <EuiFlexGroup justifyContent='flexStart' gutterSize='xs'>
                    <EuiFlexItem grow={false}>{logo}</EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiBadge color='hollow'>v{stackPack[sliderInstanceType].version}</EuiBadge>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  logo
                )
              return (
                <EuiFlexItem key={sliderInstanceType} grow={false}>
                  {content}
                </EuiFlexItem>
              )
            })}
        </EuiFlexGroup>
      ),
    },

    ...(isWhitelistingEnabled
      ? [
          {
            label: (
              <FormattedMessage
                id='region-admin-versions-table.whitelisted'
                defaultMessage='Whitelisted'
              />
            ),
            render: (version) => <WhitelistSwitch versionInfo={version} region={region!} />,
          },
        ]
      : []),

    {
      label: <FormattedMessage id='region-admin-versions-table.actions' defaultMessage='Actions' />,
      id: `deleteButton`,
      className: `elasticStackVersions-deleteButton`,
      render: (version) => (
        <DeleteVersionButton
          region={region!}
          version={version}
          fetchVersions={fetchVersions}
          deleteVersion={deleteVersion}
          deleteVersionRequest={getDeleteVersionRequest(region!.id, version.version)}
        />
      ),
      width: `150px`,
    },
  ]

  return (
    <CuiTable
      data-test-id='elastic-stack-versions-table'
      rows={sortedVersions}
      getRowId={(stackVersionConfig) => stackVersionConfig.version}
      columns={columns}
      emptyMessage={
        <FormattedMessage
          id='region-admin-versions-table.no-versions-yet'
          defaultMessage='No Elastic Stack versions yet!'
        />
      }
      initialLoading={!region}
    />
  )
}

function DeleteVersionButton({
  region,
  version,
  fetchVersions,
  deleteVersion,
  deleteVersionRequest,
}) {
  const onClick = () => deleteVersion(version.version, region).then(() => fetchVersions(region.id))

  return (
    <CuiPermissibleControl permissions={Permission.deleteVersionStack}>
      <DangerButton
        data-test-id='elastic-stack-delete-stack'
        size='s'
        color='danger'
        onConfirm={onClick}
        modal={{
          title: (
            <FormattedMessage
              id='delete-stack-version.delete'
              defaultMessage='Delete Elastic Stack version'
            />
          ),
          body: (
            <FormattedMessage
              id='delete-stack-version.confirm-to-delete'
              defaultMessage='Delete Elastic Stack version { version }?'
              values={{
                version: version.version,
              }}
            />
          ),
        }}
        isBusy={deleteVersionRequest.inProgress}
      >
        <span>
          <FormattedMessage
            id='region-admin-versions-table.delete-version'
            defaultMessage='Delete'
          />
        </span>
      </DangerButton>
    </CuiPermissibleControl>
  )
}

export default VersionTable

function EsVersionLink({ region, version }) {
  return (
    <CuiLink to={elasticStackVersionUrl(region.id, version.version)}>
      <EuiText size='m'>v{version.version}</EuiText>
    </CuiLink>
  )
}

function compareVersions(a, b) {
  if (a.version == null || !valid(a.version!) || b.version == null || !valid(b.version)) {
    // if we can't compare the order just keep it as it is
    return 1
  }

  const compare = rcompare(a.version!, b.version)

  return compare
}
