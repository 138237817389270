/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { pick } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import ProductAndDtsCosts from '../ProductAndDtsCosts'
import DeploymentCostsGrid from '../DeploymentCostsGrid'
import { getTimeRange, hasSelectedDateRangeChanged } from '../lib'
import { isPrepaidConsumptionCustomer } from '../../../../lib/billing'

import CostsGridIntro from './CostsGridIntro'

import type {
  AsyncRequestState,
  UserProfile,
  DeploymentItemsCosts,
  SelectedItems as SelectedFilterItems,
  TimePeriod,
} from '../../../../types'
import type { ReactElement } from 'react'

interface Props {
  onClickDeploymentName: (selectedDeploymentId: string) => void
  profile: UserProfile
  selectedFilterItems: SelectedFilterItems
  timePeriod: TimePeriod
  fetchAccountItemsCostsRequest: AsyncRequestState
  deploymentItemsCosts: DeploymentItemsCosts
  fetchAccountItemsCosts: ({
    organizationId,
    timePeriod,
  }: {
    organizationId: string
    timePeriod: TimePeriod
  }) => any
  resetFetchAccountItemsCostsRequest: () => void
}

class CostsGrid extends PureComponent<Props> {
  private hasPendingFetchAccountItemsCostsRequest: boolean = false

  componentDidMount(): void {
    this.fetchDeploymentCostItemsIfNeeded()
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      fetchAccountItemsCostsRequest,
      resetFetchAccountItemsCostsRequest,
      selectedFilterItems: { selectedViewByOption },
    } = this.props
    const {
      selectedFilterItems: { selectedViewByOption: prevSelectedViewByOption },
    } = prevProps

    if (
      selectedViewByOption === 'deployment' &&
      prevSelectedViewByOption === 'product' &&
      fetchAccountItemsCostsRequest.error
    ) {
      resetFetchAccountItemsCostsRequest()
    }

    this.fetchDeploymentCostItemsIfNeeded(prevProps)
  }

  render(): ReactElement {
    const {
      fetchAccountItemsCostsRequest,
      onClickDeploymentName,
      profile,
      selectedFilterItems,
      timePeriod,
    } = this.props
    const { selectedDeployments } = selectedFilterItems
    const isProductView = this.isProductView()
    const isPrepaidConsumptionUser = isPrepaidConsumptionCustomer(profile)

    return (
      <Fragment>
        <CostsGridIntro profile={profile} timePeriod={timePeriod} />

        <EuiSpacer size='m' />

        {isProductView ? (
          <ProductAndDtsCosts
            isPrepaidConsumptionUser={isPrepaidConsumptionUser}
            selectedFilterItems={selectedFilterItems}
            timePeriod={timePeriod}
            fetchRequest={fetchAccountItemsCostsRequest}
            organizationId={profile.organization_id!}
          />
        ) : (
          <DeploymentCostsGrid
            isPrepaidConsumptionUser={isPrepaidConsumptionUser}
            filterBy={selectedDeployments}
            onClickDeploymentName={onClickDeploymentName}
            organizationId={profile.organization_id!}
          />
        )}
      </Fragment>
    )
  }

  isProductView(): boolean {
    const { selectedFilterItems } = this.props
    return selectedFilterItems.selectedViewByOption === 'product'
  }

  shouldFetchAccountItemsCosts(prevProps?: Props): boolean {
    const { fetchAccountItemsCostsRequest, deploymentItemsCosts, selectedFilterItems } = this.props
    const prevSelectedFilterItems = prevProps && prevProps.selectedFilterItems

    if (!deploymentItemsCosts) {
      return !fetchAccountItemsCostsRequest.inProgress && !fetchAccountItemsCostsRequest.error
    }

    if (!prevSelectedFilterItems) {
      return true
    }

    return (
      this.hasPendingFetchAccountItemsCostsRequest ||
      hasSelectedDateRangeChanged({
        selectedDateRange: pick(selectedFilterItems, ['selectedStartDate', 'selectedEndDate']),
        prevSelectedDateRange: pick(prevSelectedFilterItems, [
          'selectedStartDate',
          'selectedEndDate',
        ]),
      })
    )
  }

  fetchDeploymentCostItemsIfNeeded = (prevProps?: Props): void => {
    if (this.shouldFetchAccountItemsCosts(prevProps)) {
      this.fetchAccountItemsCosts()
    }
  }

  fetchAccountItemsCosts(): void {
    const {
      fetchAccountItemsCosts,
      deploymentItemsCosts,
      timePeriod,
      profile: { organization_id },
    } = this.props

    if (!this.isProductView()) {
      this.hasPendingFetchAccountItemsCostsRequest =
        !deploymentItemsCosts || timePeriod.id !== deploymentItemsCosts.timePeriod.id
      return
    }

    fetchAccountItemsCosts({
      organizationId: organization_id!,
      timePeriod: { ...getTimeRange({ timePeriod }), id: timePeriod!.id },
    })

    this.hasPendingFetchAccountItemsCostsRequest = false
  }
}

export default CostsGrid
