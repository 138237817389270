/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import asyncRequest from '../asyncRequests'
import {
  FETCH_CONSTRUCTOR_LOGGING_SETTINGS,
  PATCH_CONSTRUCTOR_LOGGING_SETTINGS,
} from '../../constants/actions'
import {
  getConstructorLoggingSettingsUrl,
  updateConstructorLoggingSettingsUrl,
} from '../../lib/api/v1/urls'

import type { LoggerLevel } from '../../types'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

export function fetchConstructorLoggingSettings({
  regionId,
  constructorId,
}: {
  regionId: string
  constructorId: string
}) {
  return asyncRequest({
    type: FETCH_CONSTRUCTOR_LOGGING_SETTINGS,
    method: `GET`,
    url: getConstructorLoggingSettingsUrl({ regionId, constructorId }),
    meta: { regionId, constructorId },
    crumbs: [regionId, constructorId],
  })
}

export function patchConstructorLoggingSettings({
  regionId,
  constructorId,
  loggingLevels,
}: {
  regionId: string
  constructorId: string
  loggingLevels: PatchLoggingLevels
}) {
  return asyncRequest({
    type: PATCH_CONSTRUCTOR_LOGGING_SETTINGS,
    method: `PATCH`,
    url: updateConstructorLoggingSettingsUrl({ regionId, constructorId }),
    meta: { regionId, constructorId },
    crumbs: [regionId, constructorId],
    payload: { logging_levels: loggingLevels },
  })
}
