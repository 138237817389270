/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { deleteVersionStackUrl } from '../../lib/api/v1/urls'
import asyncRequest from '../asyncRequests'
import { DELETE_STACK_VERSION } from '../../constants/actions'

import type { Region } from '../../types'

export function deleteVersion(version: string, region: Region) {
  const regionId = region.id

  const url = deleteVersionStackUrl({ version, regionId })

  return asyncRequest({
    type: DELETE_STACK_VERSION,
    method: `DELETE`,
    url,
    meta: { regionId, version },
    crumbs: [regionId, version],
  })
}
