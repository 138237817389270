/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { PureComponent } from 'react'
import { isEmpty } from 'lodash'
import { css, jsx } from '@emotion/react'

import { EuiFlexGroup, EuiModal, EuiLoadingSpinner } from '@elastic/eui'

import {
  getRegionId,
  getVersion,
  getInstanceConfigurationsFromTemplate,
  getNodeConfigurationsFromStackDeployment,
} from '@/lib/stackDeployments/selectors'

import TrialSummaryWithDeployment from './TrialSummaryWithDeployment'
import TrialSummaryWithoutDeploymentOrTemplate from './TrialSummaryWithoutDeploymentOrTemplate'

import type { AllProps } from './types'

class TrialSummaryModal extends PureComponent<AllProps> {
  componentDidMount() {
    const { fetchDeployments } = this.props

    fetchDeployments()
  }

  componentDidUpdate(prevProps: AllProps) {
    const { deployment, deploymentFromSearch, fetchDeploymentTemplates, fetchDeploymentDetails } =
      this.props
    const deploymentFromSearchHasBeenFetched =
      !prevProps.deploymentFromSearch && deploymentFromSearch
    const deploymentDetailsHasBeenFetched = !prevProps.deployment && deployment

    if (deploymentFromSearchHasBeenFetched) {
      fetchDeploymentDetails(deploymentFromSearch.id)
    }

    if (deploymentDetailsHasBeenFetched) {
      const regionId = getRegionId({ deployment })
      const stackVersion = getVersion({ deployment })

      fetchDeploymentTemplates({
        regionId,
        stackVersion,
      })
    }
  }

  render() {
    const { closeModal } = this.props

    return (
      <EuiModal
        onClose={closeModal}
        css={css({ width: 675, padding: 20 })}
        data-test-id='trial-summary-modal'
      >
        {this.renderModalBody()}
      </EuiModal>
    )
  }

  renderModalBody = () => {
    const {
      closeModal,
      deploymentRequest,
      deploymentsRequest,
      deploymentTemplatesRequest,
      theme,
      deployment,
      deploymentTemplate,
      profile,
    } = this.props

    const isFetchingData =
      deploymentsRequest.inProgress ||
      deploymentTemplatesRequest.inProgress ||
      (deploymentRequest.inProgress && !deployment)

    if (isFetchingData || !profile) {
      return (
        <EuiFlexGroup alignItems='center' justifyContent='center' css={css({ minHeight: 360 })}>
          <EuiLoadingSpinner size='xl' />
        </EuiFlexGroup>
      )
    }

    const hasNoNodeConfigurations =
      deployment && isEmpty(getNodeConfigurationsFromStackDeployment(deployment))
    const hasNoInstanceConfigurations =
      deploymentTemplate && isEmpty(getInstanceConfigurationsFromTemplate({ deploymentTemplate }))

    if (
      !deployment ||
      !deploymentTemplate ||
      hasNoNodeConfigurations ||
      hasNoInstanceConfigurations
    ) {
      return (
        <TrialSummaryWithoutDeploymentOrTemplate
          closeModal={closeModal}
          profile={profile}
          theme={theme}
          deployment={deployment}
        />
      )
    }

    return (
      <TrialSummaryWithDeployment
        closeModal={closeModal}
        profile={profile}
        theme={theme}
        deployment={deployment}
        deploymentTemplate={deploymentTemplate}
      />
    )
  }
}

export default TrialSummaryModal
