/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiTitle } from '@elastic/eui'

import type { FunctionComponent } from 'react'

export type Props = {
  description?: string
}

const ConfigurationSummary: FunctionComponent<Props> = ({ description }) => {
  if (!description) {
    return null
  }

  return (
    <Fragment>
      <EuiTitle size='m'>
        <h2>
          <FormattedMessage
            id='instance-configuration-summary.title'
            defaultMessage='Description'
          />
        </h2>
      </EuiTitle>

      <EuiText>
        <p data-test-subj='description'>{description}</p>
      </EuiText>
    </Fragment>
  )
}

export default ConfigurationSummary
