/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import history from '@/lib/history'
import { manageDeploymentTrustRelationshipUrl } from '@/lib/urlBuilder'

import { CuiButtonIcon } from '../../../cui'

import type { WrappedComponentProps } from 'react-intl'
import type { AnyTrustRelationship, StackDeployment } from '@/types'

type Props = {
  deployment: StackDeployment
  trustRelationship: AnyTrustRelationship
}

const DeploymentTrustRelationshipEditButton: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({ deployment, trustRelationship, intl: { formatMessage } }) => (
  <CuiButtonIcon
    iconType='pencil'
    aria-label={formatMessage({ id: 'edit' })}
    onClick={() =>
      history.push(manageDeploymentTrustRelationshipUrl(deployment.id, trustRelationship))
    }
  />
)

export default injectIntl(DeploymentTrustRelationshipEditButton)
