/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'url'

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, uniq } from 'lodash'

import { EuiCode } from '@elastic/eui'

import { validation, validation as messages } from '../authProviderMessages'
import { checkIp } from '../../../../../../lib/ip'
import { isValidYaml } from '../../../../../../lib/yaml'

import type { ActiveDirectoryProviderFormShape } from './ActiveDirectoryProviderForm'
import type { FormikErrors } from 'formik'
import type { IntlShape } from 'react-intl'

const validateActiveDirectoryForm =
  ({ formatMessage }: IntlShape) =>
  (form: ActiveDirectoryProviderFormShape) => {
    const errors: FormikErrors<ActiveDirectoryProviderFormShape> = {}

    const required = formatMessage(messages.required)

    if (isEmpty(form.id)) {
      errors.id = required
    } else {
      // Start with a letter, then zero or more alphanumerics, underscores or hyphens
      if (!/^[a-zA-Z][-\w]*$/.test(form.id)) {
        errors.id = formatMessage(messages.invalidId)
      }
    }

    if (isEmpty(form.name)) {
      errors.name = required
    }

    let serverError = false

    for (let i = 0; i < form.urls.length; i++) {
      if (isEmpty(form.urls[i])) {
        errors[`urls.${i}`] = required
        serverError = true
      } else {
        const { hostname, protocol } = parse(form.urls[i])

        if (!protocol || !hostname) {
          errors[`urls.${i}`] = formatMessage(messages.validUrl)
          serverError = true
        } else if (protocol !== 'ldap:' && protocol !== 'ldaps:') {
          errors[`urls.${i}`] = (
            <FormattedMessage
              {...messages.validLdapUrl}
              values={{
                ldap: <EuiCode>ldap:</EuiCode>,
                ldaps: <EuiCode>ldaps:</EuiCode>,
              }}
            />
          )
          serverError = true
        }
      }
    }

    if (form.load_balancing === 'dns_failover' || form.load_balancing === 'dns_round_robin') {
      if (form.urls.length > 1) {
        errors.load_balancing = formatMessage(messages.singleUrlWithDnsLoadBalancing)
      } else {
        const { hostname } = parse(form.urls[0])

        if (!hostname || checkIp(hostname)) {
          errors.load_balancing = formatMessage(messages.domainNameWithDnsLoadBalancing)
        }
      }
    }

    if (!serverError) {
      const protocols = uniq(form.urls.map((url) => parse(url).protocol))

      if (protocols.length > 1) {
        errors[`urls.${form.urls.length - 1}`] = formatMessage(validation.urlProtocolsMustBeUniform)
      }
    }

    if (isEmpty(form.domain_name)) {
      errors.domain_name = required
    }

    if (!isEmpty(form.trusted_ca_cert_url)) {
      const parsedUrl = parse(form.trusted_ca_cert_url)

      if (!parsedUrl.protocol || !parsedUrl.hostname) {
        errors.trusted_ca_cert_url = formatMessage(messages.validUrl)
      }
    }

    if (!form.bind_anonymously) {
      if (isEmpty(form.bind_dn)) {
        errors.bind_dn = formatMessage(messages.bindDnRequired)
      }

      if (isEmpty(form.bind_password)) {
        errors.bind_password = formatMessage(messages.bindDnRequired)
      }
    }

    if (isEmpty(form.user_search.base_dn)) {
      errors['user_search.base_dn'] = required
    }

    if (isEmpty(form.group_search.base_dn)) {
      errors['group_search.base_dn'] = required
    }

    if (isEmpty(form.role_mappings.default_roles) && isEmpty(form.role_mappings.rules)) {
      const rolesMessage = formatMessage(messages.mustSpecifyDefaultRolesOrRules)
      errors[`role_mappings.default_roles`] = rolesMessage
      errors[`role_mappings.rules`] = rolesMessage
    }

    if (!isValidYaml(form.advanced_settings_yaml)) {
      errors.advanced_settings_yaml = formatMessage(messages.validYaml)
    }

    return errors
  }

export default validateActiveDirectoryForm
