/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { isFeatureActivated } from '@/store'
import Feature from '@/lib/feature'
import withBillingDetails from '@/lib/withBillingDetails'
import { getCommonProfile } from '@/reducers/profile'

import BillingUsage from './BillingUsage'

import type { ReduxState } from '@/types'
import type { StateProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => ({
  organizationId: organizationId || getCommonProfile(state)?.organization_id,
  showPrepaidConsumptionUI: isFeatureActivated(Feature.billingPrepaidConsumptionUI),
})

export default connect<StateProps, null, ConsumerProps>(mapStateToProps)(
  withBillingDetails(BillingUsage),
)
