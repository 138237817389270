/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiText, EuiSpacer, EuiButton } from '@elastic/eui'

import { addToast, CuiAlert } from '@/cui'
import ExternalLink from '@/components/ExternalLink'
import { getActiveItems, getExpiredItems, getFutureItems, isActiveItem } from '@/lib/billing'
import PrepaidCreditActivationModal from '@/components/PrepaidCreditActivationModal'
import { contactUs } from '@/apps/userconsole/urls'
import EssDocLink from '@/components/DocLink/EssDocLink'
import { getDocLink } from '@/components/DocLink'

import PrepaidBalancePanel from './PrepaidBalancePanel'
import PrepaidsTable from './PrepaidsTable'

import type { ReactElement } from 'react'
import type { AllProps, FilterState } from './types'
import type { LineItem } from '@/lib/api/v1/types'
import type { WrappedComponentProps } from 'react-intl'
import type { OnSubmitProps } from '@/components/PrepaidCreditActivationModal/types'

import './prepaidAccountDetailsPanel.scss'

const messages = defineMessages({
  emptyActivationCodeError: {
    id: 'code-activation.empty-code-error',
    defaultMessage: 'Activation code required, check your email.',
  },
  invalidActivationCodeError: {
    id: 'code-activation.invalid-code-error',
    defaultMessage: 'Invalid code. Verify code and contact support, if needed.',
  },
  submittedActivationCodeMessage: {
    id: 'code-activation.submitted-message',
    defaultMessage: 'Your credits have been added to your account.',
  },
})

interface State {
  filter: FilterState
  isAddActivationCodeModalOpen: boolean
  activationCodeFormError?: string
  activationCodeGeneralError: boolean
}

class PrepaidAccountDetailsPanel extends PureComponent<AllProps & WrappedComponentProps, State> {
  state = {
    filter: 'active' as FilterState,
    isAddActivationCodeModalOpen: false,
    activationCodeFormError: undefined, // code activation error is displayed under input
    activationCodeGeneralError: false, // general error is displayed in Callout under form
  }

  componentDidMount(): void {
    const {
      fetchPrepaidBalanceLineItemsIfNeeded,
      profile: { organization_id },
    } = this.props

    fetchPrepaidBalanceLineItemsIfNeeded({ organizationId: organization_id! })
  }

  render(): ReactElement {
    const { fetchPrepaidBalanceLineItemsRequest, prepaidBalanceLineItems, hasBorder, title } =
      this.props
    const { filter } = this.state
    const showEmptyState =
      fetchPrepaidBalanceLineItemsRequest.isDone && !prepaidBalanceLineItems?.length

    if (fetchPrepaidBalanceLineItemsRequest.error) {
      return (
        <PrepaidBalancePanel title={title} hasBorder={hasBorder}>
          <CuiAlert type='error'>{fetchPrepaidBalanceLineItemsRequest.error}</CuiAlert>

          {this.renderCodeActivationButton()}
        </PrepaidBalancePanel>
      )
    }

    if (showEmptyState) {
      return (
        <PrepaidBalancePanel title={title} hasBorder={hasBorder}>
          <EuiText size='s' className='eui-textCenter' data-test-id='empty-state-no-results'>
            <FormattedMessage
              id='billing-details-summary.empty-grid-body'
              defaultMessage='Add Elastic Consumption Unit credits to your organization with an activation code. If you need help, {contactUs}.'
              values={{
                contactUs: (
                  <ExternalLink href={contactUs}>
                    <FormattedMessage
                      id='billing-details-summary.subscription-level.contact-us-link'
                      defaultMessage='contact us'
                    />
                  </ExternalLink>
                ),
              }}
            />
          </EuiText>

          {this.renderCodeActivationButton()}
        </PrepaidBalancePanel>
      )
    }

    return (
      <PrepaidBalancePanel
        filter={filter}
        onFilterChange={this.setFilterState}
        hasBorder={hasBorder}
        title={title}
      >
        <PrepaidsTable
          prepaidBalanceLineItems={prepaidBalanceLineItems}
          filter={filter}
          rowClass={this.getRowClass}
          rows={this.getFilteredItems()}
          initialLoading={
            !prepaidBalanceLineItems || fetchPrepaidBalanceLineItemsRequest.inProgress
          }
        />

        {this.renderCodeActivationButton()}
      </PrepaidBalancePanel>
    )
  }

  renderCodeActivationButton = (): JSX.Element | null => {
    const { isEnhancedProvisioningEnabled, activateLineItemsRequest } = this.props
    const { isAddActivationCodeModalOpen, activationCodeFormError, activationCodeGeneralError } =
      this.state

    if (!isEnhancedProvisioningEnabled) {
      return null
    }

    return (
      <Fragment>
        <div className='eui-textCenter'>
          <EuiSpacer size='xl' />

          <EuiButton onClick={this.openCodeActivationModal} data-test-id='addActivationCodeButton'>
            <FormattedMessage
              id='prepaid-balance-panel.add-activation-code'
              defaultMessage='Add ECU credits'
            />
          </EuiButton>

          <EuiSpacer size='s' />

          <EuiText size='xs' className='eui-textCenter' data-test-id='creditActivationDocLink'>
            <EssDocLink {...getDocLink('billingPrepaidConsumptionModel', 'ess')}>
              <FormattedMessage
                id='prepaid-balance-panel.explore-pre-paid-options-link'
                defaultMessage='Explore prepaid consumption options.'
              />
            </EssDocLink>
          </EuiText>
        </div>

        {isAddActivationCodeModalOpen && (
          <PrepaidCreditActivationModal
            isSubmitted={activateLineItemsRequest.isDone}
            onClose={this.closeCodeActivationModal}
            onSubmit={this.submitActivationCode}
            isLoading={activateLineItemsRequest.inProgress}
            inputError={activationCodeFormError}
            generalError={activationCodeGeneralError}
            onCodeChange={this.resetCodeActivationError} // we want to clean errors when input change
            data-test-id='creditActivationModal'
          />
        )}
      </Fragment>
    )
  }

  getRowClass = (lineItem: LineItem): string => {
    const { filter } = this.state
    const activeClass = 'prepaid-account-details-active-item'
    const inactiveClass = 'prepaid-account-details-inactive-item'

    if (filter !== 'all') {
      return activeClass
    }

    return isActiveItem(lineItem, new Date()) ? activeClass : inactiveClass
  }

  getFilteredItems(): LineItem[] {
    const { prepaidBalanceLineItems, dateReference = new Date() } = this.props
    const { filter } = this.state

    if (!prepaidBalanceLineItems) {
      return []
    }

    if (filter === 'active') {
      return getActiveItems(prepaidBalanceLineItems, dateReference)
    }

    if (filter === 'expired') {
      return getExpiredItems(prepaidBalanceLineItems, dateReference)
    }

    if (filter === 'future') {
      return getFutureItems(prepaidBalanceLineItems, dateReference)
    }

    return prepaidBalanceLineItems
  }

  setFilterState = (filter: FilterState): void => {
    this.setState({ filter })
  }

  openCodeActivationModal = (): void => {
    this.setState({ isAddActivationCodeModalOpen: true })
  }

  closeCodeActivationModal = (): void => {
    const { resetActivateLineItemsRequest } = this.props

    this.resetCodeActivationError()

    resetActivateLineItemsRequest()

    this.setState({ isAddActivationCodeModalOpen: false })
  }

  resetCodeActivationError = () => {
    const { activationCodeFormError, activationCodeGeneralError } = this.state

    if (activationCodeFormError) {
      this.setState({ activationCodeFormError: undefined })
    }

    if (activationCodeGeneralError) {
      this.setState({ activationCodeGeneralError: false })
    }
  }

  submitActivationCode = async ({
    activation_code,
    subscription_level,
  }: OnSubmitProps): Promise<void> => {
    const {
      activateLineItems,
      profile: { organization_id },
      intl: { formatMessage },
      fetchPrepaidBalanceLineItems,
    } = this.props

    // Validation before request - Code cannot be empty, If it is empty, do not send the request
    if (!activation_code) {
      this.setState({
        activationCodeFormError: formatMessage(messages.emptyActivationCodeError),
      })

      return
    }

    try {
      // Reset all errors everytime we re-submit form
      this.resetCodeActivationError()

      await activateLineItems({
        organization_id: organization_id!,
        activation_code,
        subscription_level,
      })

      // We need to refetch line-items list
      fetchPrepaidBalanceLineItems({ organizationId: organization_id! })

      addToast({
        color: 'success',
        dataTestSubj: 'code-activation.submitted-message',
        title: formatMessage(messages.submittedActivationCodeMessage),
      })
    } catch (err) {
      const status = err?.response?.status

      // Based on Swagger definition
      // 400 - Invalid code
      if (status === 400) {
        this.setState({
          activationCodeFormError: formatMessage(messages.invalidActivationCodeError),
        })

        return
      }

      // Any other error - lets display general error
      this.setState({ activationCodeGeneralError: true })
    }
  }
}

export default injectIntl(PrepaidAccountDetailsPanel)
