/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import ExternalLink from '@/components/ExternalLink'

import messages from './messages'

import type { FunctionComponent } from 'react'

interface Props {
  showLink?: boolean
}

const EndOfLifeLink: FunctionComponent<Props> = ({ showLink }) => {
  const linkText = <FormattedMessage {...messages.linkText} />
  const eolLink = showLink ? (
    <ExternalLink href='https://www.elastic.co/support/eol'>{linkText}</ExternalLink>
  ) : (
    linkText
  )

  return eolLink
}

export default EndOfLifeLink
