/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingContent, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../cui'

import type { ReactNode } from 'react'
import type { AsyncRequestState, Runner as RunnerType } from '../../types'

interface Props {
  fetchRunner: () => Promise<any>
  fetchCoordinatorCandidates: () => Promise<any>
  runner?: RunnerType
  runnerRequest: AsyncRequestState
  children?: ReactNode
  spacerBefore?: boolean
  spacerAfter?: boolean
}

export default class Runner extends Component<Props> {
  render() {
    const { children, runner, runnerRequest } = this.props

    if (runnerRequest.error) {
      return this.renderWrappedInSpacers(
        <CuiAlert type='error' details={runnerRequest.error}>
          <FormattedMessage
            id='runner-index.fetching-host-failed'
            defaultMessage='Fetching host failed'
          />
        </CuiAlert>,
      )
    }

    if (!runner) {
      return this.renderWrappedInSpacers(<EuiLoadingContent />)
    }

    if (runnerRequest.inProgress && !runner) {
      return this.renderWrappedInSpacers(<EuiLoadingContent />)
    }

    if (!children) {
      return null
    }

    return this.renderWrappedInSpacers(children)
  }

  renderWrappedInSpacers(content) {
    const { spacerBefore, spacerAfter } = this.props

    return (
      <Fragment>
        {spacerBefore && <EuiSpacer size='m' />}
        {content}
        {spacerAfter && <EuiSpacer size='m' />}
      </Fragment>
    )
  }
}
