/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

type OnFormReady = (autoFillDetected: boolean) => void

function autoFillDetected(inputFields: HTMLInputElement[]): boolean {
  return inputFields.some((input) => {
    const inputStyle = window.getComputedStyle(input, null)
    return !!input.value || inputStyle.getPropertyValue('appearance') === 'menulist-button'
  })
}

function detectAutoFill(form: HTMLFormElement, onMutation: OnFormReady): void {
  const formElements: any = form.elements
  const usernameInput = formElements.email
  const passwordInput = formElements.password
  const observer = new MutationObserver((_mutations, me) => {
    onMutation(autoFillDetected([usernameInput, passwordInput]))
    me.disconnect()
    return
  })

  observer.observe(form, {
    childList: true,
    subtree: true,
  })
}

export function initializeAuthForm({
  form,
  onFormReady,
}: {
  form: HTMLFormElement | null
  onFormReady: OnFormReady
}): void {
  if (form !== null) {
    const formElements: any = form.elements
    const usernameInput = formElements.email
    detectAutoFill(form, onFormReady)
    usernameInput.focus()
  }
}
