/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../../../reducers'

import ExtendTrial from './ExtendTrial'

import type { AsyncRequestState } from '../../../../../types'

type StateProps = {
  extendTrialRequest: AsyncRequestState
}

type DispatchProps = {
  extendTrial: (date: string) => Promise<any>
  resetExtendTrial: () => void
}

type ConsumerProps = {
  organizationId: string
  expirationDate: Date
}

const mapStateToProps = (state): StateProps => ({
  extendTrialRequest: callStoredProcedureRequest(state, `extend_organization_trial`),
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  extendTrial: (expirationDate) =>
    dispatch(
      callStoredProcedure({
        procedureName: `extend_organization_trial`,
        parameters: [organizationId, String(expirationDate)],
        organizationId,
      }),
    ),
  resetExtendTrial: () => {
    dispatch(resetCallStoredProcedureRequest(`extend_organization_trial`))
    return dispatch(clearProcedure(`extend_organization_trial`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtendTrial)
