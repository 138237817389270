/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPagination,
  EuiPopover,
} from '@elastic/eui'

import type { ReactElement } from 'react'
import type { PaginationProps } from '@/cui/Table/types'

interface Props extends PaginationProps {
  billingYears: number[]
  onFilterByYear: (selectedYear?: number) => void
}

interface State {
  isPopoverOpen: boolean
  selectedYear?: number
}

class BillingTablePagination extends PureComponent<Props, State> {
  state = {
    isPopoverOpen: false,
    selectedYear: undefined,
  }

  render(): ReactElement {
    const { activePage, billingYears, onChangePage, pageCount } = this.props
    const { isPopoverOpen } = this.state

    return (
      <EuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiPopover
            id='billing-table-pagination.filter-popover'
            button={this.renderButton()}
            isOpen={isPopoverOpen}
            closePopover={this.closePopover}
            panelPaddingSize='none'
            anchorPosition='downLeft'
          >
            <EuiContextMenuPanel size='s' items={this.renderBillingYears(billingYears)} />
          </EuiPopover>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiPagination pageCount={pageCount} activePage={activePage} onPageClick={onChangePage} />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderButton(): ReactElement {
    const { billingYears } = this.props
    const selectedYear = billingYears.length === 1 ? billingYears[0] : this.state.selectedYear

    return (
      <EuiButtonEmpty
        size='xs'
        color='text'
        iconType='arrowDown'
        iconSide='right'
        onClick={this.togglePopover}
      >
        <FormattedMessage
          id='billing-table-pagination.button-label'
          defaultMessage='Billing year: {selectedYear}'
          values={{ selectedYear: selectedYear || this.renderNoBillingYearSelectedLabel() }}
        />
      </EuiButtonEmpty>
    )
  }

  renderBillingYears(billingYears: number[]): JSX.Element[] {
    const years = billingYears.map((year) => this.renderBillingYearContextMenuItem(year))

    if (billingYears.length === 1) {
      return years
    }

    return [this.renderBillingYearContextMenuItem()].concat(years)
  }

  renderBillingYearContextMenuItem(year?: number): JSX.Element {
    const { selectedYear } = this.state

    if (!year) {
      return (
        <EuiContextMenuItem
          key='all'
          icon={!selectedYear ? 'check' : 'empty'}
          onClick={() => this.onSelectBillingYear()}
        >
          {this.renderNoBillingYearSelectedLabel()}
        </EuiContextMenuItem>
      )
    }

    return (
      <EuiContextMenuItem
        key={year}
        icon={selectedYear === year ? 'check' : 'empty'}
        onClick={() => this.onSelectBillingYear(year)}
      >
        {year}
      </EuiContextMenuItem>
    )
  }

  renderNoBillingYearSelectedLabel(): ReactElement {
    return <FormattedMessage id='billing-table-pagination.selected-year.all' defaultMessage='All' />
  }

  onSelectBillingYear(selectedYear?: number): void {
    const { onFilterByYear } = this.props

    this.closePopover()
    this.setState({ selectedYear }, () => onFilterByYear(selectedYear))
  }

  togglePopover = (): void => {
    this.setState((prevState: State) => ({
      isPopoverOpen: !prevState.isPopoverOpen,
    }))
  }

  closePopover = (): void => {
    this.setState({ isPopoverOpen: false })
  }
}

export default BillingTablePagination
