/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { searchDeployments } from '@/actions/stackDeployments'
import { getStackDeploymentsFromSearch, searchStackDeploymentsRequest } from '@/reducers'
import { getDeploymentByIdQuery } from '@/lib/deploymentQuery'

import MonitoringDeployment from './MonitoringDeployment'

import type { RegionId } from '@/types'
import type { StateProps, DispatchProps } from './MonitoringDeployment'

const queryId = `view-template-monitorer`

const mapStateToProps = (state): StateProps => ({
  searchMonitoringDeploymentRequest: searchStackDeploymentsRequest(state, queryId),
  searchResults: getStackDeploymentsFromSearch(state, queryId),
})

const mapDispatchToProps: DispatchProps = {
  searchMonitoringDeployment: (deploymentId: string, regionId: RegionId) => {
    const query = getDeploymentByIdQuery({ deploymentId })
    query.query?.bool?.filter?.push({
      nested: {
        path: `resources.elasticsearch`,
        query: {
          term: {
            'resources.elasticsearch.region': {
              value: regionId,
            },
          },
        },
      },
    })

    return searchDeployments({
      queryId,
      query,
    })
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringDeployment)
