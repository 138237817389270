/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiCallOut } from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'
import { CuiLink } from '@/cui'
import { supportUrl } from '@/lib/urlBuilder'

import { PriceDisclaimerProps as Props } from '../types'

const PriceDisclaimer: FunctionComponent<Props> = ({ hourlyRate, autoscalingEnabled }) => {
  if (!hourlyRate || hourlyRate === 0) {
    return (
      <EuiCallOut
        data-test-id='price-unavailable'
        color='warning'
        size='s'
        title={
          <FormattedMessage
            id='pricing.no-pricing'
            defaultMessage="Pricing for this combination isn't available right now. Please {contactSupport} and let us know."
            values={{
              contactSupport: (
                <CuiLink to={supportUrl()}>
                  <FormattedMessage
                    id='pricing.no-pricing.contact-support'
                    defaultMessage='contact support'
                  />
                </CuiLink>
              ),
            }}
          />
        }
      />
    )
  }

  return (
    <div className='pricing-disclaimer'>
      <EuiText size='xs' color='subdued'>
        <FormattedMessage
          id='deployment-configure-footer.small-price-disclaimer'
          defaultMessage='Does not include {dataTransfer} fees.'
          values={{
            dataTransfer: (
              <ExternalLink
                showExternalLinkIcon={true}
                href='https://www.elastic.co/blog/elasticsearch-service-data-transfer-and-snapshot-storage-pricing'
              >
                <FormattedMessage
                  id='settings-summary-pricing.data-transfer'
                  defaultMessage='data transfer and snapshot storage'
                />
              </ExternalLink>
            ),
          }}
        />
      </EuiText>

      <EuiText data-test-id='autoscaling-disclaimer' size='xs' color='subdued'>
        <FormattedMessage
          id='deployment-configure-footer.autoscaling-disclaimer'
          defaultMessage='Autoscaling can change size and price. Autoscaling is {autoscalingEnabled}.'
          values={{
            autoscalingEnabled: autoscalingEnabled ? (
              <FormattedMessage
                id='deployment-configure-footer.autoscaling-disclaimer.autoscaling-enabled'
                defaultMessage='enabled'
              />
            ) : (
              <FormattedMessage
                id='deployment-configure-footer.autoscaling-disclaimer.autoscaling-disabled'
                defaultMessage='disabled'
              />
            ),
          }}
        />
      </EuiText>
    </div>
  )
}

export default PriceDisclaimer
