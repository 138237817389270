/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getRegion, fetchDeploymentDomainNameRequest } from '../../reducers'
import { getConfigForKey, isFeatureActivated } from '../../selectors'
import Feature from '../../lib/feature'

import RegionSettings from './RegionSettings'

import type { Region, ReduxState, AsyncRequestState } from '../../types'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  experimentalFeatures: boolean
  isEceAdminconsole: boolean
  region?: Region
  regionId: string
  fetchDeploymentDomainNameRequest: AsyncRequestState
}

type DispatchProps = unknown

type QueryParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => {
  const isEceAdminconsole =
    getConfigForKey(state, `CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`

  return {
    experimentalFeatures: isFeatureActivated(state, Feature.experimentalSettings),
    isEceAdminconsole,
    region: getRegion(state, regionId),
    regionId,
    fetchDeploymentDomainNameRequest: fetchDeploymentDomainNameRequest(state, regionId),
  }
}

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RegionSettings)
