/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import * as d3Hexbin from 'd3-hexbin'

export function calculateHexGrid(data: any[]) {
  const initialSquareWidth = 300
  const initialSquareHeight = 300

  // The number of columns and rows of the heatmap
  const mapBounds = _determineRowsAndColumns(data.length)
  const radius = _determineHexRadius(mapBounds, initialSquareWidth, initialSquareHeight)

  // Set the new height and width of the SVG based on the max possible
  const width = mapBounds.columns * radius * Math.sqrt(3)
  const height = mapBounds.rows * 1.5 * radius + 0.5 * radius

  // Set the hexagon radius
  const hexbin = d3Hexbin.hexbin().radius(radius)

  // Calculate the center positions of each hexagon in the grid based on the number of rows and columns
  const points: Array<[number, number]> = []

  for (let i = 0; i < mapBounds.rows; i++) {
    for (let j = 0; j < mapBounds.columns; j++) {
      points.push(hexPoint(radius, i, j))
    }
  }

  // Since not all hexagons can necessarily fit into a grid, we have a reminder number of hexagons. We draw these
  // to the bottom row of the grid as a new line
  for (let k = 0; k < mapBounds.remainder; k++) {
    points.push(hexPoint(radius, mapBounds.rows, k))
  }

  const bin = hexbin(points)
  const hexagon = hexbin.hexagon()

  return {
    _points: points,
    _radius: radius,
    width,
    height,
    draw,
  }

  function draw(item) {
    const correlatedIndex = data.indexOf(item)
    const { x, y } = bin[correlatedIndex]
    const path = `M${x},${y}${hexagon}`

    return path
  }
}

/**
  Uses **highly complex** :) algorithms to determine the optimal number of columns for each data size. The smaller the dataset
  the smaller the number of columns and vice versa. This allows us to make the individual hexagons bigger for smaller
  datasets and smaller for bigger datasets.

  Based on the number of columns, it is easy to determine the number of rows required to fit all the data, but since a
  grid cannot handle a non rectangle directly, we always have a remainder of hexs that we'll need to draw separately.

  We return the number of columns, rows and the remainder, ie the number of hexagons that don't fit into the grid
*/

// ts-unused-exports:disable-next-line
export function _determineRowsAndColumns(dataLength) {
  const columns = getColumns(dataLength)
  const rows = Math.floor(dataLength / columns)
  const remainder = dataLength - columns * rows

  return {
    columns,
    rows,
    remainder,
  }
}

function getColumns(dataLength) {
  if (dataLength > 500) {
    return 30
  }

  if (dataLength > 200) {
    return 20
  }

  if (dataLength > 130) {
    return 15
  }

  if (dataLength > 70) {
    return 10
  }

  if (dataLength > 50) {
    return 7
  }

  return 5
}

/**
  Determine the x,y coordinates of each hexagon
*/
function hexPoint(radius, i, j): [number, number] {
  return [radius * j * 1.75, radius * i * 1.5]
}

/**
  Determines the radius of each hexagon in the grid.

  The calculation for maxBasedOnWidth and maxBasedOnHeight comes from this blog post: https://www.visualcinnamon.com/2013/07/self-organizing-maps-creating-hexagonal.html
  After that calucalation we make sure that it fits in between our bounds of min/max for the radius
*/
// ts-unused-exports:disable-next-line
export function _determineHexRadius({ rows, columns }, width, height) {
  const maxHexRadius = 30
  const minHexRadious = 10

  const maxBasedOnWidth = width / ((columns + 0.5) * Math.sqrt(3))
  const maxBasedOnHeight = height / ((rows + 1 / 3) * 1.5)
  const calculatedHexRadius = Math.min(maxBasedOnWidth, maxBasedOnHeight)

  return Math.floor(Math.max(Math.min(calculatedHexRadius, maxHexRadius), minHexRadious))
}
