/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { css } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'

import ConfettiAnimationSVG from '@/files/confetti-animation.svg'
import ConfettiAnimationReducedMotionSVG from '@/files/confetti-animation-reduced-motion.svg'

interface WithTheme {
  theme: WithEuiThemeProps['theme']
}

export const ModalTitleCss = css({
  margin: '0 auto',
})

export const ModalCss = ({
  theme,
  changeSubscriptionModal,
  isModalAnimating,
}: WithTheme & { changeSubscriptionModal: boolean; isModalAnimating: boolean }) =>
  css({
    // We need to make the width larger to fit the select subscription flow
    // but can be the same size for everything else. There is also a transition
    // to make this smoother
    width: changeSubscriptionModal ? theme.euiTheme.breakpoint.l : '400px',
    transition: 'width 500ms ease-in-out',

    [`@media(min-width: ${theme.euiTheme.breakpoint.m}px)`]: {
      maxHeight: 690,
    },

    ...(isModalAnimating && {
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'top -10px center',
      backgroundImage: `url("${ConfettiAnimationSVG}")`,

      ['@media (prefers-reduced-motion)']: {
        backgroundImage: `url("${ConfettiAnimationReducedMotionSVG}")`,
      },
    }),
  })
