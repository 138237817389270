/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import PrivacySensitiveContainer from '@/components/PrivacySensitiveContainer'

import { CuiButtonIcon } from '../../../../cui'
import messages, { formatMessageWithValues } from '../messages'
import { removeMember } from '../lib'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

const RemoveMemberButton: FunctionComponent<Props> = ({
  intl,
  isCurrentMember,
  isLastMember,
  organizationMemberRow: { id, email },
  organizationName,
  deleteOrganizationMembership,
  deleteOrganizationMembershipRequest,
}) => {
  const {
    modal: {
      lastMember: { body: lastMemberBody },
    },
    toast,
  } = messages

  const modalMessages = isCurrentMember ? messages.modal.currentMember : messages.modal.member
  const toastMessages = isCurrentMember ? toast.currentMember : toast.member

  const values = {
    organizationName,
    email,
  }

  return (
    <CuiButtonIcon
      aria-label='remove member'
      spin={deleteOrganizationMembershipRequest({ id }).inProgress}
      onClick={() =>
        removeMember({
          intl,
          deleteOrganizationMember: deleteOrganizationMembership({ id, isCurrentMember }),
          successMessage: toastMessages.success,
          failureMessage: toastMessages.failure,
          values,
        })
      }
      confirm={true}
      confirmModalProps={{
        title: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({ intl, message: modalMessages.title, values })}
          </PrivacySensitiveContainer>
        ),
        body: (
          <PrivacySensitiveContainer>
            {formatMessageWithValues({
              intl,
              message: isLastMember ? lastMemberBody : modalMessages.body,
              values,
            })}
          </PrivacySensitiveContainer>
        ),
        confirm: formatMessageWithValues({ intl, message: modalMessages.confirm, values }),
      }}
      iconType='cross'
      data-test-id='remove-member-button'
    />
  )
}

export default injectIntl(RemoveMemberButton)
