/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { get } from 'lodash'

import adminconsoleReducers from '../apps/adminconsole/reducers'
import userconsoleReducers from '../apps/userconsole/reducers'
import history from '../lib/history'
import { getConfigForKeyFrom } from '../store'

import adminconsoleLoggingSettings, * as fromAdminconsoleLoggingSettings from './adminconsoleLoggingSettings'
import adminconsoles, * as fromAdminconsoles from './adminconsoles'
import allocatorLoggingSettings, * as fromAllocatorLoggingSettings from './allocatorLoggingSettings'
import allocators, * as fromAllocators from './allocators'
import allocatorSearch, * as fromAllocatorSearch from './allocatorSearch'
import apiBaseUrl, * as fromApiBaseUrl from './apiBaseUrl'
import apiKeys, * as fromApiKeys from './apiKeys'
import apms, * as fromApms from './apms'
import apmTokens from './apms/apmTokens'
import appSearchToEnterpriseSearchMigrationProgress, * as fromAppSearchToEnterpriseSearchMigrationProgress from './appSearchToEnterpriseSearchMigrationProgress'
import asyncRequests, * as fromAsyncRequests from './asyncRequests'
import auth, * as fromAuth from './auth'
import authMethods from './authMethods'
import billing, * as fromBilling from './billing'
import blogs, * as fromBlogs from './blogs'
import blueprint, * as fromBlueprint from './blueprint'
import ccsEligibleRemotes, * as fromCcsEligibleRemotes from './ccsEligibleRemotes'
import ccsSettings, * as fromCcsSettings from './ccsSettings'
import cloudStatus, * as fromStatus from './status'
import apiConsole, * as fromAPIConsole from './apiConsole'
import clusterDiagnosticBundles, * as fromClusterDiagnosticBundles from './clusterDiagnosticBundles'
import clusterHealth, * as fromClusterHealth from './clusters/clusterHealth'
import clusterKeystore, * as fromClusterKeystore from './clusterKeystore'
import clusterLogs, * as fromClusterLogs from './clusterLogs'
import clusterRecoveryInfo, * as fromRecoveryInfo from './clusters/recoveryInfo'
import clusters, * as fromClusters from './clusters'
import clustersCredentials, * as fromClustersCredentials from './clusters/clustersCredentials'
import clusterSnapshots, * as fromClusterSnapshots from './clusterSnapshots'
import config from './config'
import constructorLoggingSettings, * as fromConstructorLoggingSettings from './constructorLoggingSettings'
import containerSets, * as fromContainerSets from './containerSets'
import coordinatorDemotions, * as fromCoordinatorDemotions from './runners/demoteCoordinator'
import coordinators, * as fromCoordinators from './runners/fetchCoordinators'
import currentAccount, * as fromCurrentAccount from './currentAccount'
import currentUser, * as fromCurrentUser from './currentUser'
import deleteAllocator, * as fromDeleteAllocator from './allocators/deleteAllocator'
import deploymentAlias, * as fromDeploymentAlias from './deploymentAlias'
import deploymentDomainName, * as fromDeploymentDomainName from './deploymentDomainName'
import deploymentExtensions, * as fromDeploymentExtensions from './deploymentExtensions'
import deploymentHeapDumps, * as fromDeploymentHeapDumps from './deploymentHeapDumps'
import deploymentHealth, * as fromDeploymentHealth from './deploymentHealth'
import deploymentProxy, * as fromDeploymentProxy from './deploymentProxy'
import deploymentTemplates, * as fromDeploymentTemplates from './deploymentTemplates'
import eolStatus, * as fromEolStatus from './eolStatus'
import eula, * as fromEula from './eula'
import feeds, * as fromFeeds from './feeds'
import globalDeploymentTemplates, * as fromGlobalDeploymentTemplates from './globalDeploymentTemplates'
import happySadClusters, * as fromHappySadClusters from './happySadClusters'
import instanceConfigurations, * as fromInstanceConfigurations from './instanceConfigurations'
import instanceTypes, * as fromInstanceTypes from './instanceTypes'
import licenses, * as fromLicenses from './licenses'
import localUsers, * as fromLocalUsers from './localUsers'
import managedApiKeys, * as fromManagedApiKeys from './apiKeys/managedApiKeys'
import mfaDevices, * as fromMfaDevices from './mfaDevices'
import newTempShieldUser from './clusters/newTempShieldUser'
import nodeStats, * as fromNodeStats from './nodeStats'
import notificationMessages from './notificationMessages'
import organizations, * as fromOrganizations from './organizations'
import pendingTemplate from './pendingTemplate'
import phoneHomeConfig, * as fromPhoneHomeConfig from './phoneHomeConfig'
import phoneHomeDisabled, * as fromPhoneHomeDisabled from './phoneHomeDisabled'
import planActivityDetail, * as fromPlanActivityDetails from './planActivityDetail'
import platform, * as fromPlatform from './platform'
import providers, * as fromProviders from './providers'
import proxies, * as fromProxies from './proxies'
import regions, * as fromRegions from './regions'
import resourceComments, * as fromResourceComments from './resourceComments'
import root from './root'
import runnerLoggingSettings, * as fromRunnerLoggingSettings from './runnerLoggingSettings'
import runners, * as fromRunners from './runners'
import saasOrganizations, * as fromSaasOrganizations from './saasOrganizations'
import saasUsers, * as fromSaasUsers from './saasUsers'
import saveClusterAcl, * as fromSaveClusterAcl from './clusters/saveClusterAcl'
import searchForAnything, * as fromSearchForAnything from './searchForAnything'
import snapshotRepositories, * as fromSnapshotRepositories from './snapshotRepositories'
import snapshotStatus, * as fromSnapshotStatus from './snapshotStatus'
import stackDeploymentDryRuns, * as fromStackDeploymentDryRuns from './stackDeploymentDryRuns'
import stackDeploymentTemplateMigration, * as fromStackDeploymentTemplateMigration from './stackDeploymentTemplateMigration'
import stackDeployments, * as fromStackDeployments from './stackDeployments'
import stackDeploymentSearches, * as fromStackDeploymentSearches from './stackDeploymentSearches'
import storedProcedures, * as fromStoredProcedures from './storedProcedures'
import theme from './theme'
import tls, * as fromTls from './tls'
import trafficFilters, * as fromTrafficFilters from './trafficFilters'
import trustManagement, * as fromTrustManagement from './trustManagement'
import trustedEnvs, * as fromTrustedEnvs from './trustedEnvs'
import upgradeAssistantStatus, * as fromUpgradeAssistantStatus from './upgradeAssistantStatus'
import user, * as fromUser from './user'
import vacate, * as fromVacate from './vacate'
import vacateValidate, * as fromVacateValidate from './vacateValidate'
import vacateEsClusters, * as fromVacateEsClusters from './vacateEsClusters'
import versions, * as fromElasticStackVersions from './elasticStack'
import certificateAuthority, * as fromCertificateAuthority from './certificateAuthority'
import allowExtraVersionsStatus, * as fromAllowExtraVersionsStatus from './allowExtraVersionsStatus'

import type {
  ApmId,
  ReduxState,
  ElasticsearchId,
  RegionId,
  VersionNumber,
  CloudAppConfig,
  SliderInstanceType,
  WellKnownSliderInstanceType,
} from '../types'
import type Permission from '../lib/api/v1/permissions'
import type { GetCostsOverviewUrlParams } from '@/lib/api/v1/urls'

// I don't think we ever intend on enforcing the rule in this file...
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export function getReducers(config: CloudAppConfig) {
  // @ts-ignore I think the problem here is that we haven't typed all the actions...?
  return combineReducers<ReduxState>({
    ...getCommonReducers(),
    ...getAppSpecificReducers(config),
  })
}

function getCommonReducers() {
  const router = connectRouter(history)

  return {
    adminconsoleLoggingSettings,
    adminconsoles,
    allocatorLoggingSettings,
    allocators,
    allocatorSearch,
    apiBaseUrl,
    apiConsole,
    apiKeys,
    apms,
    apmTokens,
    appSearchToEnterpriseSearchMigrationProgress,
    asyncRequests,
    auth,
    authMethods,
    billing,
    blogs,
    blueprint,
    ccsEligibleRemotes,
    ccsSettings,
    cloudStatus,
    clusterDiagnosticBundles,
    clusterHealth,
    clusterKeystore,
    clusterLogs,
    clusterRecoveryInfo,
    clusters,
    clustersCredentials,
    clusterSnapshots,
    config,
    constructorLoggingSettings,
    containerSets,
    coordinatorDemotions,
    coordinators,
    currentAccount,
    currentUser,
    deleteAllocator,
    deploymentAlias,
    deploymentDomainName,
    deploymentExtensions,
    deploymentHeapDumps,
    deploymentProxy,
    deploymentTemplates,
    eolStatus,
    deploymentHealth,
    eula,
    feeds,
    globalDeploymentTemplates,
    happySadClusters,
    instanceConfigurations,
    instanceTypes,
    licenses,
    localUsers,
    managedApiKeys,
    mfaDevices,
    newTempShieldUser,
    nodeStats,
    notificationMessages,
    organizations,
    pendingTemplate,
    phoneHomeConfig,
    phoneHomeDisabled,
    planActivityDetail,
    platform,
    providers,
    proxies,
    regions,
    resourceComments,
    root,
    router,
    runnerLoggingSettings,
    runners,
    saasOrganizations,
    saasUsers,
    saveClusterAcl,
    searchForAnything,
    snapshotRepositories,
    snapshotStatus,
    stackDeploymentDryRuns,
    stackDeploymentTemplateMigration,
    stackDeployments,
    stackDeploymentSearches,
    storedProcedures,
    theme,
    tls,
    trafficFilters,
    trustManagement,
    trustedEnvs,
    upgradeAssistantStatus,
    user,
    vacate,
    vacateValidate,
    vacateEsClusters,
    versions,
    certificateAuthority,
    allowExtraVersionsStatus,
  }
}

function getAppSpecificReducers(config: CloudAppConfig) {
  if (getConfigForKeyFrom(config, `APP_NAME`) === `userconsole`) {
    return userconsoleReducers
  }

  return adminconsoleReducers // SaaS adminconsole and Cloud Enterprise
}

export {
  acceptEulaRequest,
  acceptOrganizationInvitationRequest,
  addAllocatorTagRequest,
  addLicenseRequest,
  addWhitelistedVersionRequest,
  callStoredProcedureRequest,
  cancelDeploymentResourcePlanRequest,
  captureThreadDumpRequest,
  createDeploymentExtensionRequest,
  createDeploymentTemplateRequest,
  createInstanceConfigurationRequest,
  createMarketplaceUserRequest,
  createPendingSaasUserRequest,
  createResourceCommentRequest,
  createSaasUserRequest,
  createSecretRequest,
  createStackDeploymentRequest,
  createTrafficFilterRulesetAssociationRequest,
  createTrafficFilterRulesetRequest,
  createTrustRelationshipRequest,
  createUserRequest,
  deleteDeploymentExtensionRequest,
  deleteDeploymentTemplateRequest,
  deleteEmailSuppressionsRequest,
  deleteInstanceConfigurationRequest,
  deleteResourceCommentRequest,
  deleteRunnerRequest,
  deleteSnapshotRepositoryRequest,
  deleteStackDeploymentRequest,
  deleteStackDeploymentResourceRequest,
  deleteStackVersionRequest,
  deleteTrafficFilterRulesetAssociationRequest,
  deleteTrafficFilterRulesetRequest,
  deleteTrustRelationshipRequest,
  deleteUserRequest,
  demoteCoordinatorRequest,
  deploymentDomainAliasEditAccessRequest,
  downloadClusterDiagnosticBundleRequest,
  downloadClusterLogsRequest,
  enableCrossClusterReplicationRequest,
  enableSlmRequest,
  executeSlmPolicyRequest,
  extendTrialRequest,
  fetchAccountCostsOverviewRequest,
  fetchAccountItemsCostsRequest,
  fetchAdminconsoleLoggingSettingsRequest,
  fetchAdminconsolesRequest,
  fetchAllUsersRequest,
  fetchAllocatorLoggingSettingsRequest,
  fetchApiBaseUrlRequest,
  fetchApiKeysRequest,
  fetchAuthMethodsRequest,
  fetchBlogsRequest,
  fetchBlueprintRolesRequest,
  fetchCcsEligibleRemotesRequest,
  fetchCcsSettingsRequest,
  fetchCloudStatusRequest,
  fetchClusterHealthRequest,
  fetchConstructorLoggingSettingsRequest,
  fetchContainerRequest,
  fetchContainerSetRequest,
  fetchContainerSetsRequest,
  fetchCurrentAccountRequest,
  fetchCurrentUserRequest,
  fetchDeploymentCostItemsRequest,
  fetchDeploymentDomainAliasEditAccessRequest,
  fetchDeploymentDomainNameRequest,
  fetchDeploymentExtensionRequest,
  fetchDeploymentExtensionsRequest,
  fetchDeploymentHealthRequest,
  fetchDeploymentTemplateRequest,
  fetchDeploymentTemplatesRequest,
  fetchDeploymentsCostsRequest,
  fetchElasticSearchServicePricesRequest,
  fetchEolStatusRequest,
  fetchFeedRequest,
  fetchHeapDumpsRequest,
  fetchInstanceConfigurationRequest,
  fetchInstanceConfigurationsRequest,
  fetchLicenseRequest,
  fetchManagedApiKeysRequest,
  fetchMfaDevicesRequest,
  fetchOrganizationBillingDetailsRequest,
  fetchCostsChartsRequest,
  fetchCostsChartsByDeploymentRequest,
  fetchOrganizationInvitationRequest,
  fetchOrganizationInvitationsRequest,
  fetchOrganizationMembershipsRequest,
  fetchOrganizationPrepaidsRequest,
  fetchOrganizationRequest,
  fetchOrganizationsRequest,
  fetchPlanActivityDetailRequest,
  fetchPlatformRequest,
  fetchPrepaidBalanceLineItemsRequest,
  fetchProvidersRequest,
  fetchProxiesRequest,
  fetchRegionListRequest,
  fetchRegionRequest,
  fetchResetPasswordStatus,
  fetchResourceCommentsRequest,
  fetchRootRequest,
  fetchRunnerLoggingSettingsRequest,
  fetchRunnerRequest,
  fetchSaasUserRequest,
  fetchSnapshotRepositoriesRequest,
  fetchSnapshotRepositoryRequest,
  fetchSnapshotsRequest,
  fetchStackDeploymentRequest,
  fetchTlsCertificateRequest,
  fetchTrafficFilterRulesetsRequest,
  fetchTrustRelationshipsRequest,
  fetchTrustedEnvsRequest,
  fetchUpcomingEventsRequest,
  fetchUpgradeAssistantStatusRequest,
  fetchVersionRequest,
  fetchVersionsRequest,
  fetchWhitelistedVersionRequest,
  generateApiKeyRequest,
  generateResetPasswordLinkRequest,
  getAllocatorRequest,
  getLoginRequest,
  getPhoneHomeConfigRequest,
  getSubmitMfaResponseRequest,
  herokuAuthHandshakeRequest,
  migrateDeploymentTemplateRequest,
  patchAdminconsoleLoggingSettingsRequest,
  patchAllocatorLoggingSettingsRequest,
  patchConstructorLoggingSettingsRequest,
  patchRunnerLoggingSettingsRequest,
  queryDeploymentProxyRequest,
  removeAllocatorTagRequest,
  removeLicenseRequest,
  removeWhitelistedVersionRequest,
  resetMfaRequest,
  restartStackDeploymentEsResourceRequest,
  restartStackDeploymentResourceRequest,
  restoreSnapshotRequest,
  restoreStackDeploymentRequest,
  retryFailedShardAllocationsRequest,
  revokeApiKeyRequest,
  revokeApiKeysRequest,
  saveClusterPlanRequest,
  searchAllocatorsRequest,
  searchRunnersRequest,
  searchStackDeploymentsRequest,
  setAllocatorMaintenanceModeRequest,
  setAppSearchReadOnlyModeRequest,
  setDeploymentMonitoringRequest,
  setDeploymentResourceMetadataRequest,
  setDiskQuotaRequest,
  setInstanceCapacityRequest,
  shutdownStackDeploymentRequest,
  startConstructorMaintenanceModeRequest,
  startHeapDumpCaptureRequest,
  stopConstructorMaintenanceModeRequest,
  stopDeploymentMonitoringRequest,
  subscribeMarketplaceUserRequest,
  takeSnapshotRequest,
  updateApiBaseUrlRequest,
  updateCcsSettingsRequest,
  updateCurrentAccountRequest,
  updateCurrentUserRequest,
  updateDeploymentDomainAliasEditAccessRequest,
  updateDeploymentDomainAliasRequest,
  updateDeploymentDomainNameRequest,
  updateDeploymentExtensionRequest,
  updateDeploymentTagsRequest,
  updateDeploymentTemplateRequest,
  updateInstanceConfigurationRequest,
  updateKibanaPlanRequest,
  updateResourceCommentRequest,
  updateRolesRequest,
  updateSaasUserProfileRequest,
  updateStackDeploymentDryRunRequest,
  updateStackDeploymentRequest,
  updateTrafficFilterRulesetRequest,
  updateTrustRelationshipRequest,
  updateUserRequest,
  uploadDeploymentExtensionRequest,
  uploadStackPackRequest,
  uploadTlsCertificateRequest,
  upsertOrganizationInvitationsRequest,
  upsertSnapshotRepositoryRequest,
  vacateAllocatorRequest,
  vacateAllocatorValidateRequest,
  vacateEsClusterRequest,
  vacateEsClusterValidateRequest,
  activateLineItemsRequest,
  fetchCertificateAuthorityRequest,
  fetchAllowExtraVersionsStatusRequest,
  updateAllowExtraVersionsStatusRequest,
} from './asyncRequests/registry'

export const getRoot = (state: ReduxState) => state.root
export const getNewTempShieldUser = (state: ReduxState) => state.newTempShieldUser
export const getTheme = (state: ReduxState) => state.theme
export const getNotificationsState = (state: ReduxState, notificationType) =>
  state.notificationMessages[notificationType]

export const getStoredProcedure = (state: ReduxState, storedProcedureId) =>
  fromStoredProcedures.getStoredProcedure(state.storedProcedures, storedProcedureId)

export const getRegionIds = (state: ReduxState) => fromRegions.getRegionIds(state.regions)

export const getRegion = (state: ReduxState, regionId: RegionId) =>
  fromRegions.getRegion(state.regions, regionId)

export const getAllocator = (state: ReduxState, regionId: RegionId, allocatorId: string) =>
  fromAllocators.getAllocator(state.allocators, regionId, allocatorId)

export const getAdminconsoles = (state: ReduxState, regionId: string) =>
  fromAdminconsoles.getAdminconsoles(state.adminconsoles, regionId)

export const getAdminconsoleLoggingSettings = (
  state: ReduxState,
  regionId: string,
  adminconsoleId: string,
) =>
  fromAdminconsoleLoggingSettings.getAdminconsoleLoggingSettings(
    state.adminconsoleLoggingSettings,
    regionId,
    adminconsoleId,
  )

export const getRunnerLoggingSettings = (state: ReduxState, regionId: string, runnerId: string) =>
  fromRunnerLoggingSettings.getRunnerLoggingSettings(
    state.runnerLoggingSettings,
    regionId,
    runnerId,
  )

export const getAllocatorLoggingSettings = (
  state: ReduxState,
  regionId: string,
  allocatorId: string,
) =>
  fromAllocatorLoggingSettings.getAllocatorLoggingSettings(
    state.allocatorLoggingSettings,
    regionId,
    allocatorId,
  )

export const getPlanActivityDetail = (
  state: ReduxState,
  deploymentId: string | undefined,
  kind: string,
  refId: string,
  attemptId?: string,
) =>
  fromPlanActivityDetails.getPlanActivityDetail(
    state.planActivityDetail,
    deploymentId,
    kind,
    refId,
    attemptId,
  )

export const getConstructorLoggingSettings = (
  state: ReduxState,
  regionId: string,
  constructorId: string,
) =>
  fromConstructorLoggingSettings.getConstructorLoggingSettings(
    state.constructorLoggingSettings,
    regionId,
    constructorId,
  )

export const getRoles = (state: ReduxState, regionId: RegionId) =>
  fromBlueprint.getRoles(state.blueprint.roles, regionId)

export const getCluster = (state: ReduxState, regionId: RegionId, clusterId: ElasticsearchId) =>
  fromClusters.getCluster(state.clusters, regionId, clusterId)

export const getClusterSnapshots = (state: ReduxState, deploymentId: string) =>
  fromClusterSnapshots.getClusterSnapshots(state.clusterSnapshots, deploymentId)

export const getClusterSnapshotByName = (
  state: ReduxState,
  deploymentId: string,
  snapshotName: string,
) =>
  fromClusterSnapshots.getClusterSnapshotByName(state.clusterSnapshots, deploymentId, snapshotName)

export const getSnapshotRestore = (state: ReduxState, deploymentId: string) =>
  fromClusterSnapshots.getSnapshotRestore(state.clusterSnapshots, deploymentId)

export const getClusterCredentials = (state: ReduxState, id: string, refId: string) =>
  fromClustersCredentials.getClusterCredentials(state.clustersCredentials, id, refId)

export const getResourceComments = (
  state: ReduxState,
  regionId: RegionId,
  resourceType: string,
  resourceId: string,
) =>
  fromResourceComments.getResourceComments(
    state.resourceComments,
    regionId,
    resourceType,
    resourceId,
  )

export const getDeploymentHeapDumps = (state: ReduxState, deploymentId: string) =>
  fromDeploymentHeapDumps.getDeploymentHeapDumps(state.deploymentHeapDumps, deploymentId)

export const getLicense = (state: ReduxState, regionId: RegionId) =>
  fromLicenses.getLicense(state.licenses, regionId)

export const getClusterRecoveryInfo = (state: ReduxState, deploymentId: string) =>
  fromRecoveryInfo.getRecoveryInfo(state.clusterRecoveryInfo, deploymentId)

export const getClusterHealth = (state: ReduxState, deploymentId: string) =>
  fromClusterHealth.getClusterHealth(state.clusterHealth, deploymentId)

export const getDeploymentProxyResponse = (state: ReduxState, deploymentId: string) =>
  fromDeploymentProxy.getDeploymentProxyResponse(state.deploymentProxy, deploymentId)

export const getDeploymentProxyRequest = (state: ReduxState, deploymentId: string) =>
  fromAPIConsole.getAPIConsoleRequest(state.apiConsole, deploymentId)

export const getDeploymentProxyRequestHistory = (state: ReduxState) =>
  fromAPIConsole.getAPIConsoleRequestHistory(state.apiConsole)

export const getApm = (state: ReduxState, regionId: RegionId, apmId: ApmId) =>
  fromApms.getApm(state.apms, regionId, apmId)

export const getSaveClusterAclInformation = (
  state: ReduxState,
  regionId: RegionId,
  clusterId: ElasticsearchId,
) => fromSaveClusterAcl.getSaveClusterAclInformation(state.saveClusterAcl, regionId, clusterId)

export const getFetchRecoveryInfoRequest = (state: ReduxState, deploymentId: string) =>
  getAsyncRequestStatus(state, deploymentId)

export const getDeleteAllocatorInformation = (
  state: ReduxState,
  regionId: RegionId,
  allocatorId: string,
) => fromDeleteAllocator.getDeleteAllocatorInformation(state.deleteAllocator, regionId, allocatorId)

export const getRunner = (state: ReduxState, regionId: RegionId, runnerId: string) =>
  fromRunners.getRunner(state.runners, regionId, runnerId)

export const getDemoteCoordinator = (state: ReduxState, regionId: RegionId, runnerId: string) =>
  fromCoordinatorDemotions.getDemoteCoordinator(state.coordinatorDemotions, regionId, runnerId)

export const getCoordinatorById = (state: ReduxState, regionId: RegionId, runnerId: string) =>
  fromCoordinators.getCoordinatorById(state.coordinators, regionId, runnerId)

export const getVersionStacks = (state: ReduxState, regionId: RegionId) =>
  fromElasticStackVersions.getVersionStacks(state.versions, regionId)

export const getActiveVersionStacks = (state: ReduxState, regionId: RegionId) =>
  fromElasticStackVersions.getActiveVersionStacks(state.versions, regionId)

export const getVersionStack = (state: ReduxState, regionId: RegionId, version: string) =>
  fromElasticStackVersions.getVersionStack(state.versions, regionId, version)

export const getVersionWhitelist = (state: ReduxState, regionId: RegionId) =>
  fromElasticStackVersions.getVersionWhitelist(state.versions, regionId)

export const getProxies = (state: ReduxState, regionId: RegionId) =>
  fromProxies.getProxies(state.proxies, regionId)

export const getRegionConstructors = (state: ReduxState, regionId: RegionId) =>
  fromRegions.getRegionConstructors(state.regions, regionId)

export const getAllocatorVacate = (state: ReduxState, regionId: RegionId, allocatorId) =>
  fromVacate.getAllocatorVacate(state.vacate, regionId, allocatorId)

export const getAllocatorVacateValidate = (
  state: ReduxState,
  regionId: RegionId,
  allocatorId: string,
) => fromVacateValidate.getAllocatorVacateValidate(state.vacateValidate, regionId, allocatorId)

export function getEsClusterVacateValidate(
  state: ReduxState,
  regionId: string,
  clusterId: string,
  instanceIds: string[],
) {
  return fromVacateEsClusters.getEsClusterVacateValidate(
    state.vacateEsClusters,
    regionId,
    clusterId,
    instanceIds,
  )
}

export const getSearchForAnythingById = (state: ReduxState, id: string) =>
  fromSearchForAnything.getSearchForAnythingById(state.searchForAnything, id)

export const isEulaAccepted = (state: ReduxState) => fromEula.isAccepted(state.eula)

export const isPhoneHomeEnabled = (state: ReduxState) =>
  fromPhoneHomeConfig.isEnabled(state.phoneHomeConfig)

export const isPhoneHomeDisabled = (state: ReduxState) =>
  fromPhoneHomeDisabled.isPhoneHomeDisabled(state.phoneHomeDisabled)

export const getMfa = (state: ReduxState) => fromAuth.getMfa(state.auth)

export const getMfaDevices = (state: ReduxState, userId: string) =>
  fromMfaDevices.getMfaDevices(state.mfaDevices, userId)

export const getUser = (state: ReduxState) => fromUser.getUser(state.user)

export const getClusterDiagnosticBundle = (
  state: ReduxState,
  deploymentId: string,
  resourceKind: SliderInstanceType,
) =>
  fromClusterDiagnosticBundles.getClusterDiagnosticBundle(
    state.clusterDiagnosticBundles,
    deploymentId,
    resourceKind,
  )

export const getClusterLogs = (state: ReduxState, regionId: RegionId, clusterId: ElasticsearchId) =>
  fromClusterLogs.getClusterLogs(state.clusterLogs, regionId, clusterId)

export const getNodeStatsForInstance = (
  state: ReduxState,
  deploymentId: string,
  instanceName: string,
) => fromNodeStats.getNodeStatsForInstance(state.nodeStats, deploymentId, instanceName)

export const getSnapshotRepositories = (state: ReduxState, regionId) =>
  fromSnapshotRepositories.getByRegion(state.snapshotRepositories, regionId)

export const getSnapshotRepository = (
  state: ReduxState,
  regionId: RegionId,
  repositoryId: string,
) => fromSnapshotRepositories.getById(state.snapshotRepositories, regionId, repositoryId)

export const getPlatformOverview = (state: ReduxState) =>
  fromPlatform.getPlatformOverview(state.platform)

export const getRegionInfo = (state: ReduxState, regionId: string) =>
  fromPlatform.getRegionInfo(state.platform, regionId)

export const getPlatformByRegion = (state: ReduxState, regionId: string) =>
  fromPlatform.getPlatformByRegion(state.platform, regionId)

export const getTlsCertificate = (state: ReduxState, regionId: RegionId, certificateTypeId) =>
  fromTls.getById(state.tls, regionId, certificateTypeId)

const getAsyncRequestStatus = (state: ReduxState, id) =>
  fromAsyncRequests.getAsyncRequest(state.asyncRequests, id)

export const getBuildTagMismatch = (state: ReduxState) =>
  fromAsyncRequests.getBuildTagMismatch(state.asyncRequests)

export const getAllocatorSearchResults = (state: ReduxState, regionId: RegionId, queryId: string) =>
  fromAllocatorSearch.getResults(state.allocatorSearch, regionId, queryId)

export const getInstanceTypes = (state: ReduxState, regionId) =>
  fromInstanceTypes.getInstanceTypes(state.instanceTypes, regionId)

export const getInstanceConfigurations = (state: ReduxState, regionId) =>
  fromInstanceConfigurations.getInstanceConfigurations(state.instanceConfigurations, regionId)

export const getInstanceConfiguration = (state: ReduxState, regionId: RegionId, id) =>
  fromInstanceConfigurations.getInstanceConfiguration(state.instanceConfigurations, regionId, id)

export const getDeploymentTemplates = (
  state: ReduxState,
  regionId: RegionId,
  stackVersion: VersionNumber | null,
) =>
  fromDeploymentTemplates.getDeploymentTemplates(state.deploymentTemplates, regionId, stackVersion)

export const getDeploymentTemplate = (
  state: ReduxState,
  regionId: RegionId,
  templateId: string,
  stackVersion: VersionNumber | null = null,
) =>
  fromDeploymentTemplates.getDeploymentTemplate(
    state.deploymentTemplates,
    regionId,
    templateId,
    stackVersion,
  )

export const getSaasUsers = (state: ReduxState) => fromSaasUsers.getSaasUsers(state.saasUsers)

export const getSaasUser = (state: ReduxState, userId: string) =>
  fromSaasUsers.getSaasUser(state.saasUsers, userId)

export const getSnapshotStatus = (state: ReduxState, deploymentId: string, snapshotId: string) =>
  fromSnapshotStatus.getSnapshotStatus(state.snapshotStatus, deploymentId, snapshotId)

export const getLocalUsers = (state: ReduxState) => fromLocalUsers.getUsers(state.localUsers)

export const getCcsSettings = (state: ReduxState, deploymentId: string) =>
  fromCcsSettings.getCcsSettings(state.ccsSettings, deploymentId)

export const getCcsEligibleRemotes = (state: ReduxState, version: string) =>
  fromCcsEligibleRemotes.getCcsEligibleRemotes(state.ccsEligibleRemotes, version)

export const getCurrentUser = (state: ReduxState) =>
  fromCurrentUser.getCurrentUser(state.currentUser)

export const getCurrentUserUsername = (state: ReduxState) =>
  fromCurrentUser.getCurrentUserUsername(state.currentUser)

export const isCurrentUser = (state: ReduxState, username: string) =>
  fromCurrentUser.isCurrentUser(state.currentUser, username)

export const currentUserHasPermission = (state: ReduxState, permissions: Permission[]) =>
  fromCurrentUser.hasPermission(state.currentUser, permissions)

export const getApiKeys = (state: ReduxState) => fromApiKeys.getApiKeys(state.apiKeys)

export const getManagedApiKeys = (state: ReduxState) =>
  fromManagedApiKeys.getManagedApiKeys(state.managedApiKeys)

export const getStackDeployment = (state: ReduxState, deploymentId) =>
  fromStackDeployments.getStackDeployment(state.stackDeployments, deploymentId)

export const getUpdateDeploymentDryRunResult = (state: ReduxState, deploymentId: string) =>
  fromStackDeploymentDryRuns.getUpdateDeploymentDryRunResult(
    state.stackDeploymentDryRuns,
    deploymentId,
  )

export const getProviders = (state) => fromProviders.getProviders(state.providers)
export const getProvidersNames = (state) => fromProviders.getProvidersNames(state.providers)

export const getRegionIdsByProvider = (state: ReduxState, providerId) =>
  fromProviders.getRegionIdsByProvider(state.providers, providerId)

export const getRegionsByProvider = (state: ReduxState, providerId) =>
  fromProviders.getRegionsByProvider(state.providers, providerId)

export const getProviderIdByRegion = (state: ReduxState, regionId) =>
  fromProviders.getProviderIdByRegion(state.providers, regionId)

export const getRegionName = (state: ReduxState, regionId) =>
  fromProviders.getRegionName(state.providers, regionId)

export const getDefaultRegionId = (state: ReduxState): RegionId => {
  const defaultProvider = fromProviders.getDefaultProvider(state.providers)
  const defaultRegionId =
    get(defaultProvider, [`regions`, `0`, `identifier`]) || get(getRegionIds(state), [`0`])

  return defaultRegionId
}

export const getStackDeploymentCreateResponse = (state: ReduxState, deploymentId) =>
  fromStackDeployments.getStackDeploymentCreateResponse(state.stackDeployments, deploymentId)

export const getDeletedStackDeploymentIds = (state) =>
  fromStackDeployments.getDeletedStackDeploymentIds(state.stackDeployments)

export const getStackDeploymentsFromSearch = (state, queryId) =>
  fromStackDeploymentSearches.getStackDeploymentsFromSearch(state.stackDeploymentSearches, queryId)

export const getCloudStatus = (state: ReduxState) => fromStatus.getCloudStatus(state.cloudStatus)

export const getAccountDeploymentsCosts = (state: ReduxState, organizationId: string) =>
  fromBilling.getAccountDeploymentsCosts(state.billing, organizationId)

export const getDeploymentItemsCosts = (state: ReduxState, organizationId: string) =>
  fromBilling.getDeploymentItemsCosts(state.billing, organizationId)

export const getDeploymentItemsCostsByDeployment = (state: ReduxState, organizationId: string) =>
  fromBilling.getDeploymentItemsCostsByDeployment(state.billing, organizationId)

interface GetAccountCostsOverviewParams extends GetCostsOverviewUrlParams {
  state: ReduxState
}

export const getAccountCostsOverview = ({ state, ...restProps }: GetAccountCostsOverviewParams) =>
  fromBilling.getAccountCostsOverview({ state: state.billing, ...restProps })

export const getPrepaidBalanceLineItems = (state: ReduxState, organizationId: string) =>
  fromBilling.getPrepaidBalanceLineItems(state.billing, organizationId)

export const getBillingHistory = (state: ReduxState, organizationId: string) =>
  fromBilling.getBillingHistory(state.billing, organizationId)

export const getOrganizationPrepaids = (state: ReduxState, organizationId: string) =>
  fromBilling.getOrganizationPrepaids(state.billing, organizationId)

export const getBlogs = (state: ReduxState) => fromBlogs.getBlogs(state.blogs)
export const getUpcomingEvents = (state: ReduxState) => fromBlogs.getUpcomingEvents(state.blogs)

export const getFeed = (state: ReduxState, feed, version, cropAt) =>
  fromFeeds.getOrderedFeed(state.feeds, feed, version, cropAt)

export const getAuthMethods = (state: ReduxState) => state.authMethods

export const getContainerSets = (state: ReduxState, regionId: string) =>
  fromContainerSets.getContainerSets(state.containerSets, regionId)

export const getContainerSet = (state: ReduxState, regionId: string, containerSetId: string) =>
  fromContainerSets.getContainerSet(state.containerSets, regionId, containerSetId)

export const getContainer = (
  state: ReduxState,
  regionId: string,
  containerSetId: string,
  containerId: string,
) => fromContainerSets.getContainer(state.containerSets, regionId, containerSetId, containerId)

export const getTrafficFilterRulesets = (state: ReduxState, regionId?: string) =>
  fromTrafficFilters.getTrafficFilterRulesets(state.trafficFilters, regionId)

export function getKeystore(state: ReduxState, deploymentId: string, refId: string) {
  return fromClusterKeystore.getKeystore(state.clusterKeystore, deploymentId, refId)
}

export function getAppSearchToEnterpriseSearchMigrationProgress(
  state: ReduxState,
  deploymentId: string,
) {
  return fromAppSearchToEnterpriseSearchMigrationProgress.getAppSearchToEnterpriseSearchMigrationProgress(
    state.appSearchToEnterpriseSearchMigrationProgress,
    deploymentId,
  )
}

export function getDeploymentExtensions(state: ReduxState) {
  return fromDeploymentExtensions.getDeploymentExtensions(state.deploymentExtensions)
}

export function getDeploymentExtension(state: ReduxState, extensionId: string) {
  return fromDeploymentExtensions.getDeploymentExtension(state.deploymentExtensions, extensionId)
}
export const getGlobalDeploymentTemplates = (state: ReduxState) =>
  fromGlobalDeploymentTemplates.getGlobalDeploymentTemplates(state.globalDeploymentTemplates)

export function getUpgradeAssistantStatus(state: ReduxState, deploymentId: string) {
  return fromUpgradeAssistantStatus.getUpgradeAssistantStatus(
    state.upgradeAssistantStatus,
    deploymentId,
  )
}

export function getHappySadClusters(state: ReduxState, regionId: string) {
  return fromHappySadClusters.getHappySadClusters(state.happySadClusters, regionId)
}

export function getApiBaseUrl(state: ReduxState) {
  return fromApiBaseUrl.getApiBaseUrl(state.apiBaseUrl)
}

export function deploymentAliasEditAccess(state: ReduxState) {
  return fromDeploymentAlias.deploymentAliasEditAccess(state.deploymentAlias)
}

export function getDeploymentDomainName(state: ReduxState, regionId: string) {
  return fromDeploymentDomainName.getDeploymentDomainName(state.deploymentDomainName, regionId)
}

export function getTrustRelationships(state: ReduxState, regionId: string) {
  return fromTrustManagement.getTrustRelationships(state.trustManagement, regionId)
}

export function getTrustRelationshipsWithoutLocal(state: ReduxState, regionId: string) {
  return fromTrustManagement.getTrustRelationshipsWithoutLocal(state.trustManagement, regionId)
}

export function getTrustedEnvs(state: ReduxState) {
  return fromTrustedEnvs.getTrustedEnvs(state.trustedEnvs)
}

export function getLocalTrustRelationship(state: ReduxState, regionId: string) {
  return fromTrustManagement.getLocalTrustRelationship(state.trustManagement, regionId)
}

export function getCurrentAccount(state: ReduxState) {
  return fromCurrentAccount.getCurrentAccount(state.currentAccount)
}

export function getOrganizations(state: ReduxState) {
  return fromOrganizations.getOrganizations(state.organizations)
}

export function getOrganization(state: ReduxState, organizationId: string) {
  return fromOrganizations.getOrganization(state.organizations, organizationId)
}

export function getOrganizationInvitation(state: ReduxState, invitationToken: string) {
  return fromOrganizations.getOrganizationInvitation(state.organizations, invitationToken)
}

export function getOrganizationInvitations(state: ReduxState, organizationId: string) {
  return fromOrganizations.getOrganizationInvitations(state.organizations, organizationId)
}

export function getOrganizationMembers(state: ReduxState, organizationId: string | undefined) {
  return fromOrganizations.getOrganizationMembers(state.organizations, organizationId)
}

export function getSaasOrganization(state: ReduxState, organizationId: string) {
  return fromSaasOrganizations.getSaasOrganization(state.saasOrganizations, organizationId)
}

export function getEolStatus(state: ReduxState) {
  return fromEolStatus.getEolStatus(state.eolStatus)
}

export const getDeploymentHealth = (state: ReduxState, deploymentId: string) =>
  fromDeploymentHealth.getDeploymentHealth(state.deploymentHealth, deploymentId)

export function getResourceEolStatus(
  state: ReduxState,
  sliderInstanceType: WellKnownSliderInstanceType,
) {
  return fromEolStatus.getResourceEolStatus(state.eolStatus, sliderInstanceType)
}

export const getOrganizationBillingDetails = (state: ReduxState, organizationId: string) =>
  fromBilling.getOrganizationBillingDetails(state.billing, organizationId)

export const getMarketplaceBillingDetails = (state: ReduxState, organizationId: string) =>
  fromBilling.getMarketplaceBillingDetails(state.billing, organizationId)

export const getMigratedDeploymentPayload = (
  state: ReduxState,
  deploymentId: string,
  templateId: string,
) =>
  fromStackDeploymentTemplateMigration.getMigratedDeploymentPayload(
    state.stackDeploymentTemplateMigration,
    deploymentId,
    templateId,
  )

export const getCostsCharts = (state: ReduxState, organizationId: string) =>
  fromBilling.getCostsCharts(state.billing, organizationId)

export const getCostsChartsByDeployment = (
  state: ReduxState,
  organizationId: string,
  deploymentId: string,
) => fromBilling.getCostsChartsByDeployment(state.billing, organizationId, deploymentId)

export function getCertificateAuthority(state: ReduxState, deploymentId) {
  return fromCertificateAuthority.getCertificateAuthority(state.certificateAuthority, deploymentId)
}
export const getAllowExtraVersionsStatus = (state: ReduxState) =>
  fromAllowExtraVersionsStatus.getAllowExtraVersionsStatus(state.allowExtraVersionsStatus)
