/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { validation } from './authProviderMessages'

import type { RoleMappingRule as AdRoleMappingRule } from './activeDirectory/ActiveDirectoryProviderForm'
import type { RoleMappingRule as LdapRoleMappingRule } from './ldap/LdapProviderForm'
import type { FormikErrors } from 'formik'
import type { IntlShape } from 'react-intl'
import type { RoleMappingRule as SamlRoleMappingRule } from './saml/SamlProviderForm'

type RoleMappingRule = LdapRoleMappingRule | SamlRoleMappingRule | AdRoleMappingRule

export function validateRoleMappingRule(
  formatMessage: IntlShape['formatMessage'],
): (values: RoleMappingRule) => FormikErrors<RoleMappingRule> {
  return (values: RoleMappingRule) => {
    const errors: FormikErrors<RoleMappingRule> = {}

    if (values.value.trim().length === 0) {
      errors.value = formatMessage(validation.required)
    }

    if (values.roles.length === 0) {
      errors.roles = [formatMessage(validation.required)]
    }

    return errors
  }
}
