/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'

import { EuiIconTip } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

const messages = defineMessages({
  estimatedUsageHelp: {
    id: `user-capacity-usage.estimated-usage-help`,
    defaultMessage: `Due to limitations, the allocation usage indicator is currently illustrative, approximative, and only meant to provide a rough idea of what the customer's capacity usage looks like.`,
  },
})

const OrganizationCapacityUsageToolTipIcon: FunctionComponent<WrappedComponentProps> = ({
  intl: { formatMessage },
}) => (
  <EuiIconTip
    aria-label={formatMessage(messages.estimatedUsageHelp)}
    content={formatMessage(messages.estimatedUsageHelp)}
    type='iInCircle'
  />
)

export default injectIntl(OrganizationCapacityUsageToolTipIcon)
