/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { cloneDeep, merge } from 'lodash'

import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { getCuration, getCurationIndexPatterns } from '@/lib/stackDeployments/indexManagement'
import { getEsPlan, getFirstEsCluster } from '@/lib/stackDeployments/selectors'

import DangerButton from '../../DangerButton'

import IndexCurationTargets from './IndexCurationTargets'
import IndexCurationPatterns from './IndexCurationPatterns'
import IndexCurationAddPatternButton from './IndexCurationAddPatternButton'

import type { DeepPartial } from '@/types'
import type {
  ClusterCurationSpec,
  DeploymentTemplateRequestBody,
  ElasticsearchClusterPlan,
  ElasticsearchCuration,
  ElasticsearchPayload,
} from '@/lib/api/v1/types'

import './indexCurationSettings.scss'

type Props = {
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (template: DeploymentTemplateRequestBody) => void
  curationConfigurationOptions: Array<{ id: string; name: string }>
}

type State = {
  isEditing: boolean
}

class IndexCurationSettings extends Component<Props, State> {
  state: State = {
    isEditing: Boolean(
      getCuration({
        deployment: this.props.template.deployment_template,
      })?.from_instance_configuration_id ||
        getCuration({
          deployment: this.props.template.deployment_template,
        })?.to_instance_configuration_id,
    ),
  }

  render() {
    const { isEditing } = this.state

    return (
      <div data-test-id='index-curation-settings' className='indexCurationSettings'>
        <EuiTitle size={'s'}>
          <label htmlFor='selectCuration'>
            <h5>
              <FormattedMessage
                id='index-curation-settings.title'
                defaultMessage='Index curation'
              />
              <EuiBadge className='indexCurationSettings-deprecated' color='warning'>
                <FormattedMessage
                  id='index-curation-settings.deprecated'
                  defaultMessage='Deprecated'
                />
              </EuiBadge>
            </h5>
          </label>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiTitle size='xxxs' textTransform='uppercase' className='ilmSettings-label'>
          <h5>
            <FormattedMessage
              id='index-curation-settings.stack-version'
              defaultMessage='Any stack version'
            />
          </h5>
        </EuiTitle>

        <EuiText>
          <p>
            <FormattedMessage
              id='index-curation-settings.description'
              defaultMessage='Create the index patterns for new indices on hot nodes and then specify when they should automatically move to warm nodes.'
            />
          </p>
        </EuiText>

        <EuiSpacer />

        {isEditing ? (
          this.renderContent()
        ) : (
          <EuiButton color='success' size='s' onClick={() => this.setState({ isEditing: true })}>
            <FormattedMessage
              id='index-lifecycle-management-settings.configure'
              defaultMessage='Configure'
            />
          </EuiButton>
        )}
      </div>
    )
  }

  renderContent() {
    const { template, curationConfigurationOptions } = this.props

    const indexPatterns = getCurationIndexPatterns({
      deployment: template.deployment_template,
    })
    const curation = getCuration({
      deployment: template.deployment_template,
    })

    return (
      <Fragment>
        <IndexCurationTargets
          curation={curation}
          setCuration={this.setCuration}
          curationConfigurationOptions={curationConfigurationOptions}
        />

        <EuiSpacer />

        <IndexCurationPatterns
          indexPatterns={indexPatterns}
          setIndexPatterns={this.setIndexPatterns}
        />

        <EuiSpacer />

        <EuiFlexGroup gutterSize='m'>
          <EuiFlexItem grow={false}>
            <IndexCurationAddPatternButton onAdd={this.onAddPattern} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        <DangerButton
          size='s'
          buttonType={EuiButtonEmpty}
          onConfirm={this.removeConfiguration}
          color='danger'
          modal={{
            title: (
              <FormattedMessage
                id='index-curation-settings.remove-config.title'
                defaultMessage='Remove index curation?'
              />
            ),
            confirmButtonText: (
              <FormattedMessage
                id='index-curation-settings.remove-config.button-text'
                defaultMessage='Remove index curation'
              />
            ),
            body: (
              <FormattedMessage
                id='index-curation-settings.remove-config.body'
                defaultMessage="Removes the configuration that moves indices between data nodes. Deployments with this template can't use index curation."
              />
            ),
          }}
        >
          <FormattedMessage
            id='index-lifecycle-management-settings.remove-configuration'
            defaultMessage='Remove configuration'
          />
        </DangerButton>
      </Fragment>
    )
  }

  onAddPattern = () => {
    const { template } = this.props

    const indexPatterns = getCurationIndexPatterns({
      deployment: template.deployment_template,
    })

    const newIndexPattern = {
      index_pattern: `*`,
      trigger_interval_seconds: 60 * 60 * 24,
    }

    const updatedIndexPatterns = indexPatterns.concat(newIndexPattern)

    this.setIndexPatterns(updatedIndexPatterns)
  }

  setIndexPatterns = (patterns: ClusterCurationSpec[] | undefined) => {
    const template = cloneDeep(this.props.template)
    const resource = getFirstEsCluster({ deployment: template.deployment_template })

    if (!resource) {
      return // sanity
    }

    merge<ElasticsearchPayload, DeepPartial<ElasticsearchPayload>>(resource, {
      settings: {
        curation: {
          specs: patterns,
        },
      },
    })

    this.props.updateDeploymentTemplate(template)
  }

  removeConfiguration = () => {
    this.setIndexPatterns(undefined)
    this.setCuration(undefined)
    this.setState({ isEditing: false })
  }

  setCuration = (curation: ElasticsearchCuration | undefined) => {
    const template = cloneDeep(this.props.template)
    const plan = getEsPlan({ deployment: template.deployment_template })

    if (!plan) {
      return // sanity
    }

    merge<ElasticsearchClusterPlan, DeepPartial<ElasticsearchClusterPlan>>(plan, {
      elasticsearch: {
        curation,
      },
    })

    this.props.updateDeploymentTemplate(template)
  }
}

export default IndexCurationSettings
