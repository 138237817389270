/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiCode,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert, CuiButton, CuiButtonEmpty } from '../../../cui'
import { isDirectorHost } from '../../../lib/hostRoles'

import type { AsyncRequestState, DemoteRecord, Runner } from '../../../types'

interface Props {
  regionId: string
  hostId: string
  demoteCoordinatorState?: DemoteRecord
  demoteCoordinatorRequest: AsyncRequestState
  demoteCoordinator: () => Promise<any>
  runner?: Runner
  onClose: () => void
}

class DemoteHost extends Component<Props> {
  render() {
    const { runner, onClose } = this.props

    return (
      <EuiOverlayMask>
        <EuiModal onClose={onClose}>
          <EuiModalHeader>
            <EuiTitle>
              <h3>
                <FormattedMessage id='demote-host.title' defaultMessage='Demote host?' />
              </h3>
            </EuiTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>
              <FormattedMessage
                id='demote-host.description'
                defaultMessage='This operation removes the host from the system along with all of its roles. The host will no longer manage its current instances.'
              />
            </EuiText>

            {isDirectorHost({ runner }) && (
              <Fragment>
                <EuiSpacer size='m' />

                <EuiCallOut
                  title={
                    <FormattedMessage
                      id='demote-host.director-restrictions'
                      defaultMessage='Update host roles first'
                    />
                  }
                  color='warning'
                >
                  <FormattedMessage
                    id='demote-runner.explanation'
                    defaultMessage='Before you can demote a director, the {director} role has to be removed from the host.'
                    values={{ director: <EuiCode>director</EuiCode> }}
                  />
                </EuiCallOut>
              </Fragment>
            )}
          </EuiModalBody>

          <EuiModalFooter>{this.renderModalFooter()}</EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderModalFooter() {
    const { runner, demoteCoordinatorState, demoteCoordinator, demoteCoordinatorRequest, onClose } =
      this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <CuiButtonEmpty onClick={() => onClose()}>
              <FormattedMessage id='demote-host.cancel' defaultMessage='Cancel' />
            </CuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiButton
              color='danger'
              disabled={!runner || isDirectorHost({ runner })}
              onClick={() => demoteCoordinator()}
              spin={demoteCoordinatorRequest.inProgress}
              fill={true}
            >
              <FormattedMessage id='demote-host.demote' defaultMessage='Demote' />
            </CuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {demoteCoordinatorRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{demoteCoordinatorRequest.error}</CuiAlert>
          </Fragment>
        )}

        {demoteCoordinatorState != null && demoteCoordinatorState.isDemoted && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='info'>
              <FormattedMessage
                id='demote-runner.demote-success'
                defaultMessage='Host was successfully demoted.'
              />
            </CuiAlert>
          </Fragment>
        )}

        {demoteCoordinatorState != null && demoteCoordinatorState.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{demoteCoordinatorState.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default DemoteHost
