/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTextColor, EuiFormHelpText } from '@elastic/eui'

import { CuiCodeEditor } from '../../cui'
import SpinButton from '../SpinButton'
import stringify from '../../lib/stringify'

import type { ReactNode } from 'react'

type Props = {
  initialValue: unknown | null
  save: (value: unknown | null) => void
  isSaving: boolean
  readonly?: boolean
  readonlyHelpText?: ReactNode
  message: ReactNode | null
  ['data-test-id']?: string
}

type State = {
  _initialValue: unknown | null
  value: string
  isValidJson: boolean
}

class PureJsonEditor extends Component<Props, State> {
  state: State = {
    _initialValue: this.props.initialValue,
    value: stringify(this.props.initialValue),
    isValidJson: true,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    const { initialValue: nextInitialValue } = nextProps
    const { _initialValue: prevInitialValue } = prevState

    const hasChanged = !isEqual(prevInitialValue, nextInitialValue)

    if (hasChanged) {
      return {
        _initialValue: nextInitialValue,
        value: stringify(nextInitialValue),
      }
    }

    return null
  }

  render() {
    const {
      isSaving,
      message,
      readonly,
      readonlyHelpText,
      'data-test-id': dataTestSubj,
    } = this.props

    const { value, isValidJson } = this.state

    return (
      <div data-test-id={dataTestSubj}>
        <CuiCodeEditor
          isReadOnly={readonly}
          language='json'
          value={value}
          onChange={this.handleChange}
        />

        <EuiSpacer size='m' />

        <div>
          <SpinButton
            data-test-subj='pure-json-editor-save'
            color='primary'
            fill={true}
            disabled={readonly || !isValidJson || isSaving}
            spin={isSaving}
            onClick={this.onSave}
          >
            <FormattedMessage id='pure-json-editor.save' defaultMessage='Save' />
          </SpinButton>

          {readonly && readonlyHelpText && <EuiFormHelpText>{readonlyHelpText}</EuiFormHelpText>}

          {isValidJson || (
            <EuiFormHelpText>
              <EuiTextColor color='warning'>
                <FormattedMessage
                  id='pure-json-editor.json-not-valid'
                  defaultMessage='Please adjust your input into valid JSON.'
                />
              </EuiTextColor>
            </EuiFormHelpText>
          )}
        </div>

        {message && (
          <Fragment>
            <EuiSpacer size='m' />

            {message}
          </Fragment>
        )}
      </div>
    )
  }

  handleChange = (value) => {
    const parsed = parseJSON(value)
    const isValidJson = parsed != null

    this.setState({ value, isValidJson })
  }

  onSave = () => {
    const { save } = this.props
    const { value } = this.state
    save(parseJSON(value))
  }
}

function parseJSON(jsonString) {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return null
  }
}

export default PureJsonEditor
