/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { forEach } from 'lodash'

import { EuiLoadingContent, EuiPanel, EuiSuperSelect, EuiText, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@/cui'

import { envTypes, flattenTrustedEnvs, getTargetEnvironmentType } from '../../helpers'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './'
import type { AnyTrustRelationship } from '@/types'

type State = {
  selected: any | null
}

class ReusableTrustRelationships extends React.Component<AllProps & WrappedComponentProps, State> {
  state = {
    selected: ``,
  }

  componentDidMount() {
    const { fetchTrustedEnvs } = this.props
    fetchTrustedEnvs()
  }

  render() {
    return (
      <EuiPanel color='plain' hasBorder={true}>
        <EuiText size='s'>
          <FormattedMessage
            id='reusable-trust-relationships.description'
            defaultMessage='Select an environment already trusted by other deployments in this organization.'
          />
          <EuiSpacer size='s' />
        </EuiText>
        {this.renderContent()}
      </EuiPanel>
    )
  }

  renderContent() {
    const { fetchTrustedEnvsRequest, trustedEnvs } = this.props

    if (fetchTrustedEnvsRequest.error) {
      return (
        <CuiAlert type='danger' data-test-id='reusable-trust-relationship.error'>
          {fetchTrustedEnvsRequest.error}
        </CuiAlert>
      )
    }

    if (fetchTrustedEnvsRequest.inProgress || !trustedEnvs) {
      return <EuiLoadingContent />
    }

    const selectOptions = this.buildSelectOptions()

    return (
      <EuiSuperSelect
        options={selectOptions}
        hasDividers={true}
        itemLayoutAlign='top'
        valueOfSelected={this.state.selected}
        onChange={(value) => this.onChange(value)}
      />
    )
  }

  onChange(value) {
    const { onSelected } = this.props
    this.setState({ selected: value })

    const flatTrustedEnvs = flattenTrustedEnvs(this.props.trustedEnvs)

    const fullTrustedEnvObj: AnyTrustRelationship | undefined = flatTrustedEnvs.find(
      (env) => env.name === value,
    )
    onSelected(fullTrustedEnvObj)
  }

  buildSelectOptions() {
    let selectOptions = []
    const { trustedEnvs } = this.props

    forEach(envTypes, (type) => {
      selectOptions = selectOptions.concat(this.buildSelectOptionsPerType(type, trustedEnvs))
    })

    return selectOptions
  }

  buildSelectOptionsPerType(type, trustedEnvs) {
    if (!trustedEnvs[type]) {
      return []
    }

    return trustedEnvs[type].map((env) => {
      if (!env.name) {
        // this shouldn't happen as all "nameless" items should be given a default name by the API, but still a good sanity
        env.name = `Untitled`
      }

      return {
        value: env.name,
        inputDisplay: env.name,
        dropdownDisplay: (
          <Fragment>
            <strong>{env.name}</strong>
            <EuiText size='s' color='subdued'>
              <p>{this.getEnvType(env)}</p>
            </EuiText>
          </Fragment>
        ),
      }
    })
  }

  getEnvType(env) {
    const type = getTargetEnvironmentType(env)

    switch (type) {
      case 'ece':
        return `Elastic Cloud Enterprise`
      case 'ess':
        return `Elastic Cloud`
      case `self-managed`:
        return `Self-managed`
      default:
        return `Elastic Cloud`
    }
  }
}

export default injectIntl(ReusableTrustRelationships)
